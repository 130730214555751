import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as useLocation } from "react-router-dom";
import Loading from "../../../common/loading";
import { AuthContext } from "../../../base/auth";
import { Alert } from 'reactstrap';

const ChangeCompanyWithLink = ({ history }) => {
  const { base } = useContext(AuthContext);
  const useQuery = (name) => {
    const query = new URLSearchParams(useLocation().search);
    const result = query.get(name);
    return result;
  };
  const [companyID] = useState(useQuery("comp"));
  const [response,setResponse] = useState("")
  console.log({companyID})
  useEffect(() => {
    const unsubscribe = async () => {
      const res = await base.changeCurrentComp({ companyID });

      if(res === true) {
        history.push("/")
      } else {
        setResponse(res)
      }

    };
    unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyID]);
  if(response) return <> <Alert color="warning"> {response}</Alert> </>

  return <Loading />;
};

export default ChangeCompanyWithLink;


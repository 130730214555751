import React, { useContext } from "react";
import { AuthContext } from "../../base/auth";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import * as ROUTES from "../../base/routes";
import ProductList from "./productList";
import ProductEdit from "./productEdit";
import ProductNew from "./productNew";
import { Button } from "reactstrap";
import useDbData from '../../common/hooks/useDbData';

const Product = ({ history }) => {
  let { path } = useRouteMatch();
  const { currentCompany, superAdmin } = useContext(AuthContext);
  const { products,addProducts } = useDbData({
    collectionName: "products",
  });

  console.log(products);

  return (
    <>
      <h4 className="mt-3">Market</h4>

      {superAdmin && (
        <Button
          color="primary"
          onClick={() => history.push(ROUTES.PRODUCT_NEW)}
        >
          {" "}
          Yeni{" "}
        </Button>
      )}

      <Switch>
        <Route exact path={`${path}`}>
          <ProductList data={products} history={history} />
        </Route>
        <Route path={ROUTES.PRODUCT_EDIT}>
          <ProductEdit history={history} />
        </Route>
        <Route path={ROUTES.PRODUCT_NEW} >
          <ProductNew history={history} addData={addProducts}  />
        </Route>
      </Switch>
    </>
  );
};

export default Product;

import React, { useEffect, useState } from "react";

import Base from "./baseClass";

import Loading from "../common/loading";
import Logo from '../common/Logo';

export const AuthContext = React.createContext();
const base = new Base();

export const AuthProvider = ({ children }) => {
  const [pending, setPending] = useState(true);
  //userData
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [userType, setUserType] = useState(null);
  const [superAdmin, setSuperAdmin] = useState(false);
  // console.log({currentUser})
  //console.log({ superAdmin }, { userType });

  useEffect(() => {
    const unsubscribe = base.auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
      // user.getIdToken(true).then(()=> idTokenResult => {
      //   console.log(idTokenResult)
      // })
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchClaims = async (user) => {
      user.getIdToken(true).then(() => {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            setUserType(idTokenResult.claims.currentCompany);
            // console.log(idTokenResult.claims)
            setSuperAdmin(idTokenResult.claims.superAdmin || false);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };
    if (currentUser) fetchClaims(currentUser);
  }, [currentUser, userData]);

  // userData db den alınması
  useEffect(() => {
    if (currentUser === null) {
      setUserData({});
      setCompanies([]);
      setCurrentCompany([]);
    }
    const unsubscribeFromAuth = async () => {
      await base.getUserData((data) => {
        setUserData(data);
      });
    };
    unsubscribeFromAuth();
    return () => {
      //unsubscribe the listener here
      unsubscribeFromAuth();
    };
  }, [currentUser]);

  // company data alınması
  // Firma Bilgilerinin ALınması
  useEffect(() => {
    if (!userData?.currentCompany) return;
    const unsubscribe = async () => {
      // currentCompanyyi dinle
      await base.getCurrentCompanyData(userData, (data) => {
        setCurrentCompany(data);
      });
    };
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
  }, [userData]);

  if (pending)
    return (
      <>

        <Logo
          className=" mx-auto d-block my-4 "
          height= "35px "
        />
        <Loading />
      </>
    );

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userData,
        companies,
        setCompanies,
        currentCompany,
        userType,
        superAdmin,
        base,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useContext } from "react";
import { AuthContext } from "../../base/auth";
import * as ROUTES from "../../base/routes";

import { AiOutlineCaretDown, AiOutlineUser } from "react-icons/ai";
import CompanyList from "./companyList";

const ProfileMenu = ({ history }) => {
  const { currentUser, currentCompany, base, userData } =
    useContext(AuthContext);

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const displayName = currentUser.displayName;
  const title = userData?.currentCompany ? (
    <>
      {currentCompany?.companyName
        ? currentCompany?.companyName
        : currentCompany.createdUser}
    </>
  ) : (
    "Firma Seçili Değil"
  );

  return (
    <>
      <ProfileMenuClosed
        displayName={displayName}
        title={title}
        toggle={toggle}
        dropdownOpen={dropdownOpen}
      />
      {dropdownOpen && (
        <ProfileMenuOpen
          displayName={displayName}
          title={title}
          toggle={toggle}
          dropdownOpen={dropdownOpen}
          base={base}
          history={history}
        />
      )}
    </>
  );
};

export default ProfileMenu;

const ProfileMenuClosed = ({ displayName, title, dropdownOpen, toggle }) => {

  return (
    <div className={!dropdownOpen ? "row my-1" : " row my-1 "}>
      <div className="col pointer" onClick={() => toggle()}>
        <div className="row  ">
          <div className="col">
            <div className="row text-right">
              <div className="col">{displayName}</div>
            </div>
            <div className="row text-right">
              <div className="col">
                <small>{title}</small>
              </div>
            </div>
          </div>

          <div className="row align-items-center  mr-0">
            <div className="col ">
              <AiOutlineCaretDown />
              <AiOutlineUser size="30" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

const ProfileMenuOpen = ({ displayName, title, toggle, base, history }) => {
  return (
    <div className=" alwaysShow row ml-0 shadow-sm main text-dark position-absolute profileMenu">
      <div className="col">
        <div className="row  pointer " onClick={() => toggle()}>
          <div className="col">
            <div className="row text-right">
              <div className="col">{displayName}</div>
            </div>
            <div className="row text-right">
              <div className="col">
                <small>{title}</small>
              </div>
            </div>
          </div>

          <div className="row align-items-center  mr-0">
            <div className="col ">
              <AiOutlineCaretDown />
              <AiOutlineUser size="30" />
            </div>
          </div>
        </div>
        {/* alt menüleri buraya koy */}
        <hr className="mb-1" />

         <CompanyList toggle={toggle}/> 
        <div className="row mt-2">
          <div className="col">
            <p
              className="pointer hoverBg my-1"
              onClick={() => {
                history.push(ROUTES.PROFILE);
                toggle();
              }}
            >
              Profil
            </p>
            <p className="pointer hoverBg " onClick={() => base.doSignOut()}>
              Çıkış
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

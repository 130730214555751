import React, { useState, useEffect } from "react";

import { ListGroup, ListGroupItem, Card } from "reactstrap";

const Acordion = ({
  cardClassName,
  listGroupItemColor,
  title,
  activeTask,
  setActiveTask,
  listGroupIndex,
  children,
}) => {
  const handleActiveTask = (i) => {
    if (activeTask === i) {
      setActiveTask(null);
      return;
    }
    setActiveTask(i);
  };
  return (
    <ListGroup>
      <Card className={cardClassName}>
        <ListGroupItem
          color={listGroupItemColor}
          onClick={() => handleActiveTask(listGroupIndex)}
        >
          {title}
        </ListGroupItem>
        {activeTask === listGroupIndex && children}
      </Card>
    </ListGroup>
  );
};

export default Acordion;

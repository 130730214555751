import React, { useState, useEffect } from "react";

import { Row, Button, Col, Alert, Breadcrumb, BreadcrumbItem} from "reactstrap";

import { AuthContext } from "../../base/auth";
import currency from "currency.js";

import * as ROUTES from "../../base/routes";
import InputA from "../../common/inputA";
import Loading from "../../common/loading";

const CompanyCapital = ({ history, setStep, partners, aplicationData }) => {
  const { userData, currentCompany, base } = React.useContext(AuthContext);

  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });
  const minCapital =
    currentCompany.companyType === "Anonim Şirket" ? 50000 : 10000;

  const [onlyPartners, setOnlyPartners] = React.useState([]);

  const [pending, setPending] = React.useState(true);
  const [compCapital, setCompCapital] = useState(0);
  const [formData, setFormdata] = useState({});

  const [partnersCapital, setPartnersCapital] = useState(0);
  const [partnersShare, setPartnersShare] = useState(0);
  const [capitalAlert, setCapitalAlert] = useState("");

  React.useEffect(() => {
    if (!pending) return;
    setOnlyPartners(partners.filter((x) => x.partner));
  }, [currentCompany, partners, pending]);

  React.useEffect(() => {
    if (aplicationData?.companyCapital) {
      setCompCapital(aplicationData.companyCapital);
    }
  }, [aplicationData.companyCapital]);

  React.useEffect(() => {
    if (!pending) return;
    if (onlyPartners.length === 0) return;

    const initualFormValue = {
      sumCapital: {
        value: aplicationData?.companyCapital || tl(minCapital).format(),
        error: "",
      },
    };
    const partnersArray = [...onlyPartners];

    partnersArray.forEach((partner) => {
      let dbShare = 0;
      let dbValue = 0;
      if (typeof partner?.capital?.value !== "undefined") {
        dbShare = partner.capital.share;
        dbValue = tl(partner.capital.value).format();
      }

      const value =
        dbValue || tl(minCapital).divide(partnersArray.length).format();
      const share = dbShare || 100 / partnersArray.length;

      initualFormValue[`capital${partner.id}`] = {
        value,
        error: "",
        share,
      };
    });

    const dbCapital = currentCompany?.capital?.compCapital;
    if (dbCapital) {
      initualFormValue.sumCapital.value = dbCapital;
    }

    setFormdata(initualFormValue);
    setPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners, onlyPartners]);

  useEffect(() => {
    if (typeof formData.sumCapital === "undefined") return;
    let currentCapital = tl(formData.sumCapital.value).value;
    setCompCapital(currentCapital);
  }, [formData]);

  const handleSave = async () => {
    const data = { ...formData };

    const array = [...onlyPartners];

    array.map((partner) => {
      const value = data[`capital${partner.id}`].value;
      const share = data[`capital${partner.id}`].share;
      partner.capital = { value, share };
      return partner;
    });

    for await (const manager of array) {
      await base.addNewPartner({
        companyID: userData.currentCompany,
        data: manager,
      });
    }
    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyCapital: Number(compCapital) },
    });

    //base.updateCompanyToDb({
    //   companyID: userData.currentCompany,
    //   updateKeys: { capital: capitalObj },
    // });
    base.syncStep({ newStep: 11, currentCompany, history });
  };

  useEffect(() => {
    if (typeof formData.sumCapital === "undefined") return;

    const onlyPartners = [...partners];
    const partnersArray = onlyPartners.filter((x) => x.partner);

    let sumPartnersCapital = 0;
    let sumPartnersShare = 0;
    for (let i = 0; i < partnersArray.length; i++) {
      sumPartnersCapital += tl(
        formData[`capital${partnersArray[i]?.id}`]?.value
      ).value;
      sumPartnersShare += Number(
        formData[`capital${partnersArray[i]?.id}`]?.share
      );
    }
    if (
      tl(sumPartnersCapital).value.toFixed(0) !==
      tl(formData.sumCapital.value).value.toFixed(0)
    ) {
      setCapitalAlert("Sermayenin Tamamını Ortaklara Dağıtmalısınız.")
    }
    setPartnersCapital(sumPartnersCapital.toFixed(0));
    setPartnersShare(sumPartnersShare);
  }, [formData, partners]);

  useEffect(() => {
    const remainingShare = 100 - partnersShare;

    if (compCapital >= minCapital) setCapitalAlert("");
    if (compCapital < minCapital) {
      setCapitalAlert(
        `Toplam Sermaye ${tl(minCapital).format()} den küçük olamaz`
      );
    }
    if (remainingShare > 0) {
      setCapitalAlert(
        `Şirket sermayesi ortaklara dağıtılmalıdır. Kalan oran %${tl(
          remainingShare
        ).format()} `
      );
    }
    if (remainingShare < 0) {
      setCapitalAlert(
        `Ortakların toplam sermaye oranı %100 e eşit olmalı Fazla Tutar % ${tl(
          Math.abs(remainingShare)
        ).format()} `
      );
    }
  }, [compCapital, minCapital, partnersCapital, partnersShare]);

  useEffect(() => {
    if (partners.length === 0) return;
    if (typeof formData.capital1 === "undefined") return;

    const data = { ...formData };
    for (let i = 1; i < partners.length; i++) {
      const share = data[`capital${i}`].share;
      data[`capital${i}`].value = tl(compCapital)
        .multiply(share)
        .divide(100)
        .format();
    }

    setFormdata(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compCapital, formData, partners.length]);

  React.useEffect(() => {
    if (currentCompany?.companyType.includes("Şahıs")) {
      console.log("şahıs");
      history.push(ROUTES.COMSUBJECT);
    }
  }, [currentCompany, history]);

  if (pending) return <Loading />;

  return (
    <>
       <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Sermayenin Belirlenmesi
            </BreadcrumbItem>
          </Breadcrumb>
      <p>
        {currentCompany.companyType} şirketler için minimum sermaye tutarı{" "}
        {tl(minCapital).format()}
      </p>
      <InputA
        type="text"
        inputType="money"
        label="Şirketin Toplam Sermaye Tutarı"
        name="sumCapital"
        formValue={formData}
        setFormValue={setFormdata}
        minValue={minCapital}
      />
      <p>
        Dağıtılan Sermaye :{tl(partnersCapital).format()} /{" "}
        {tl(compCapital).format()}
      </p>

      <p>Sermaye dağılımı</p>
      {onlyPartners.map((partner, index) => (
        <ul key={partner.id} className="my-1 list-group ">
          <RangeInput
            partner={partner}
            index={index}
            formData={formData}
            setFormdata={setFormdata}
            compCapital={compCapital}
            tl={tl}
            partnersShare={partnersShare}
          />
        </ul>
      ))}
      <Alert color="danger" className={capitalAlert ? null : "d-none"}>
        {capitalAlert}
      </Alert>

      <Button
        className="my-3"
        onClick={() => history.push(ROUTES.AUTHORITIES)}
        color="secondary"
      >
        {" "}
        Geri{" "}
      </Button>
      <Button
        className="m-3"
        disabled={capitalAlert}
        onClick={() => handleSave()}
        color="primary"
      >
        {" "}
        Kaydet{" "}
      </Button>
    </>
  );
};

export default CompanyCapital;

const RangeInput = ({
  partner,
  index,
  formData,
  setFormdata,
  compCapital,
  tl,
  partnersShare,
}) => {
  return (
    <li className={"list-group-item list-group-item-action"}>
      <Row>
        <Col md={12}>
          <p>
            {`${index + 1}. Ortak ${partner.name} ${
              partner.surName
            } payına düşen sermaye`}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div class="input-group mb-3 ">
            <input
              type="number"
              name="partnerCap"
              step="0.01"
              className="form-control "
              value={Number(formData[`capital${partner.id}`]?.share).toFixed(2)}
              onChange={(e) => {
                const data = { ...formData };
                data[`capital${partner.id}`].share = e.target.value;
                data[`capital${partner.id}`].value = tl(compCapital)
                  .multiply(e.target.value)
                  .divide(100)
                  .format();
                setFormdata(data);
              }}
            ></input>
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div class="input-group mb-3 ">
            <input
              type="text"
              name="partnerCap"
              step="0.01"
              className="form-control "
              disabled="true"
              value={formData[`capital${partner.id}`]?.value}
            ></input>

            <div class="input-group-append">
              <span class="input-group-text">TL</span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <Button
            className={partnersShare === 100 ? "d-none" : null}
            onClick={() => {
              const remainingShare = 100 - partnersShare;
              const data = { ...formData };
              let oldValue = Number(data[`capital${partner.id}`].share);
              let newValue = oldValue + remainingShare;
              if (newValue < 0.01) newValue = 0.01;
              data[`capital${partner.id}`].share = newValue;
              data[`capital${partner.id}`].value = tl(compCapital)
                .multiply(data[`capital${partner.id}`].share)
                .divide(100)
                .format();
              setFormdata(data);
            }}
          >
            Eşitle{" "}
          </Button>
        </Col>
        <Col md={12}>
          <input
            type="range"
            //step="0.01"
            className="form-range w-100"
            id="customRange1"
            value={Number(formData[`capital${partner.id}`]?.share).toFixed(0)}
            onChange={(e) => {
              const data = { ...formData };
              data[`capital${partner.id}`].share = Number(e.target.value);
              data[`capital${partner.id}`].value = tl(compCapital)
                .multiply(e.target.value)
                .divide(100)
                .format();
              setFormdata(data);
            }}
          ></input>
        </Col>
      </Row>
    </li>
  );
};

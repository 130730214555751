import React, { useState, useEffect, useContext } from "react";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
//import { RiDeleteBin6Line } from "react-icons/ri";
import * as ROUTES from "../../base/routes";
import { AuthContext } from "../../base/auth";
import FormSelect from "../../common/inputSelect";
import FormInput from "../../common/input";

const Address = ({ history, setStep, aplicationData }) => {
  const { userData, currentCompany, base } = useContext(AuthContext);

  const virtualAddress = {
    "İşyeri Kiralayacağım": {
      adress: "",
      il: "",
      ilce: "",
      tur: "",
      adresNo: "",
      fiyat: "",
    },
    "Evimi Şirket Adresi Göstereceğim": {
      adress: "",
      il: "",
      ilce: "",
      tur: "",
      adresNo: "",
      fiyat: "",
    },
    "Sanal Ofis - KADIKÖY - Kozyatağı": {
      adress:
        "19 MAYIS MAH. TURABOĞLU SK. HAMDIYE YAZGAN IS MERKEZI BLOK NO: 4 İÇ KAPI NO: 2",
      il: "İSTANBUL",
      ilce: "KADIKÖY",
      tur: "Merkaz",
      adresNo: "1757833759",
      fiyat: "170",
    },
  };

  const [adress, setAdress] = useState("");
  const [il, setIl] = useState("");
  const [ilce, setIlce] = useState("");
  const [tur] = useState("Merkez");
  const [adressNo, setAdressNo] = useState("");
  const [fiyat, setFiyat] = useState("");

  const [isVirtualAdres, setIsVirtualAdres] = useState(
    Object.keys(virtualAddress)[0]
  );

  useEffect(() => {
    if (!aplicationData) return;
    if (!aplicationData.adress) return;
    const data = aplicationData?.adress;
    if (typeof data === "undefined") return;
    setAdress(data.adress);
    setIl(data.il);
    setIlce(data.ilce);
    //setTur(data.tur)
    //setAdressNo(data.adressNo)
  }, [aplicationData]);

  const handleSave = async () => {
  
    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { adress: { adress, ilce, il, tur, adressNo } },
    });
    await base.syncStep({ newStep: 13, currentCompany, history });
  };

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!adress) return;
    const isDisabled =
      adress.length < 15 || il.length === 0 || ilce.length === 0;
    setDisabled(isDisabled);
  }, [adress, il, ilce]);

  useEffect(() => {
    if (!isVirtualAdres) return;
    const selectedAdress = virtualAddress?.[isVirtualAdres];
    setAdress(selectedAdress.adress);
    setIl(selectedAdress.il);
    setIlce(selectedAdress.ilce);
    setFiyat(selectedAdress.fiyat);
    setAdressNo(selectedAdress.adresNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVirtualAdres]);

  return (
    <Row>
      <Col md={6}>
        <Breadcrumb className="" tag="nav" listTag="div">
          <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Adres Bilgileri
          </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <FormSelect
            options={Object.keys(virtualAddress)}
            onChange={(e) => setIsVirtualAdres(e.target.value)}
            label="Aderes Seçimi"
          />
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="il">İl</Label>
              <Input
                type="text"
                name="il"
                id="il"
                value={il}
                onChange={(e) => {
                  setIl(e.target.value);
                }}
                disabled={isVirtualAdres.includes("Sanal")}
                placeholder=""
              />

              <Label for="il">İlçe</Label>
              <Input
                type="text"
                name="ilce"
                id="ilce"
                value={ilce}
                disabled={isVirtualAdres.includes("Sanal")}
                onChange={(e) => {
                  setIlce(e.target.value);
                }}
                placeholder=""
              />

              <Label for="companySubject">Açık adres</Label>
              <Input
                type="textarea"
                name="adress"
                id="adress"
                disabled={isVirtualAdres.includes("Sanal")}
                value={adress}
                onChange={(e) => {
                  setAdress(e.target.value);
                }}
              />
            </FormGroup>

            <br />
            <Button
              className="my-3"
              onClick={() => history.push(ROUTES.COMSUBJECT)}
              color="secondary"
            >
              {" "}
              Geri{" "}
            </Button>
            <Button
              className="m-3"
              disabled={disabled}
              onClick={() => handleSave()}
              color="primary"
            >
              {" "}
              Kaydet{" "}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col>
        <p>
          Sanal ofislerimizden birini şirket merkezi olarak gösterebilir veya
          kiraladığınız bir adresi şirket merkezi olarak bildirebilirsiniz.{" "}
        </p>
        {isVirtualAdres !== Object.keys(virtualAddress)[0] &&
        isVirtualAdres !== Object.keys(virtualAddress)[1] ? (
          <>
            {isVirtualAdres}
            <br />
            {`Stopaj yok`}
            <br />
            <Alert>{`Toplam Maliyet Aylık ${fiyat} TL KDV hariç`}</Alert>
            <p>
              Sanal ofisiniz ile kira kontratı imzalamanız için sizinle
              iletişime geçilecektir.
            </p>
          </>
        ) : (
          <KiraHesaplama virtualAddress={virtualAddress} />
        )}
      </Col>
    </Row>
  );
};

export default Address;

const KiraHesaplama = ({ virtualAddress }) => {
  const [netKira, setNetKira] = useState(0);
  const [stopaj, setStopaj] = useState(0);

  useEffect(() => {
    const stopajOran = 0.2;
    const brut = netKira / (1 - stopajOran);
    setStopaj(brut - netKira);
  }, [netKira]);

  const sanalOfisFiyat = virtualAddress[Object.keys(virtualAddress)[2]].fiyat;

  return (
    <>
      <FormInput
        name="kira"
        onChange={(e) => setNetKira(Number(e.target.value.replace(",", ".")))}
        label="Ödeyeceğinz Aylık Kira Tutarını Giriniz"
        onKeyDown={(e) => {
          if (e.keyCode === 8 || e.keyCode === 188) return;
          !/[0-9]/.test(e.key) && e.preventDefault();
        }}
      />
      {netKira !== 0 && (
        <>
          {" "}
          <p className="m-3">Ödenecek aylık stopaj tutarı: {stopaj}</p>
          <Alert color="warning">
            Toplam mailyet: {stopaj + netKira} TL
          </Alert>{" "}
          {stopaj + netKira > sanalOfisFiyat ? (
            <Alert>
              {" "}
              Aylık {stopaj + netKira - sanalOfisFiyat}TL tasarruf etmek için{" "}
              {sanalOfisFiyat}TL'den başlayan fiyatlar ile sanal ofislerimizden
              birini tercih edebilirsiniz.{" "}
            </Alert>
          ) : null}
        </>
      )}
    </>
  );
};

import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { withRouter, Redirect } from "react-router";

import { AuthContext } from "../../base/auth";
import Logo from "../../common/Logo";
import * as ROUTES from "../../base/routes";

//import { capSentence } from "../../common/functions";
import Loading from "../../common/loading";

const test = window.location.hostname.includes("localhost");

const SignUp = ({ history }) => {
  const { currentUser, base } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [displayName, setdisplayName] = useState("");

  const firstRender = useRef(true);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (test) {
      setEmail("deneme@me.com");
      setdisplayName("ali veli");
      setPassword("111111");
      setPassword2("111111");
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // it's no longer the first render
      return; // skip the code below
    }
    if (
      email.length > 5 &&
      password.length > 5 &&
      displayName.length > 5 &&
      password === password2
    ) {
      setDisabled(false);
    }
  }, [email, password, password2, displayName]);

  const handleSignUp = useCallback(
    async (event) => {
      //event.preventDefault();
      //const { email, password, displayName } = event.target.elements;
      try {
        setDisabled(true);
        // setLoading(true);
        //setLoading(true);
        const user = await base.doCreateUserWithEmailAndPassword(
          email,
          password,
          displayName
        );
        if(user) base.doCreateDB({ displayName })
        // await base.setCurrentCompanyDB({
        //   uid: res.uid,
        //   companyID: res.companyID,
        // });
        // await base.createNewCompany({ displayName: displayName });
        setDisabled(false);
        // setLoading(false);
      } catch (error) {
        alert(error);
        setDisabled(false);
        setLoading(false);
      }
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, password, displayName, history]
  );

  if (loading) {
    return (
      <>
        <Loading />
        <p className="text-center">
          Veri tabanı hazırlanıyor. Bu işlem bir dakika kadar sürebilir. Lütfen
          bekleyiniz...
        </p>
      </>
    );
  }

  if (currentUser) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <div className="text-center col-md-3 container mt-5 beyaz">
      <div className="form-signin" onSubmit={handleSignUp}>
        <Logo className="App-logo rounded mx-auto d-block" height="75px" />
        <h1 className="h3 mb-3 font-weight-normal">Kaydolun</h1>
        <p className="mt-2"></p>
        <label htmlFor="inputEmail" className="sr-only">
          Kullanıcı Adı
        </label>
        <input
          onChange={(e) => setdisplayName(e.target.value)}
          className="my-2 form-control"
          name="displayName"
          type="displayName"
          placeholder="Ad Soyad"
          value={displayName}
          autoFocus
          required
        />
        <label htmlFor="inputEmail" className="sr-only">
          Email address
        </label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className="my-2 form-control"
          name="email"
          type="email"
          placeholder="e-Posta"
          value={email}
          autoFocus
          required
        />
        <label htmlFor="inputPassword" className="sr-only">
          Password
        </label>
        <input
          onChange={(e) => setPassword(e.target.value)}
          className="my-2 form-control"
          name="password"
          type="password"
          placeholder="Parola"
          value={password}
          required
        />
        <input
          onChange={(e) => setPassword2(e.target.value)}
          className="my-2 form-control"
          name="password2"
          type="password"
          placeholder="Parolayı Doğrula"
          value={password2}
          required
        />
        <div className="checkbox mb-3"></div>
        <button
          disabled={disabled}
          className="btn btn-lg btn-primary btn-block"
          onClick={handleSignUp}
        >
          Kaydol
        </button>
        {/* <button
          className="btn btn-lg btn-primary btn-block"
          onClick={base.doSignInWithGoogle}
        >
          Google ile Kaydol
        </button> */}
        <p onClick={(e) => history.push(ROUTES.LOGIN)} className="my-2 pointer">
          <u>Hesabınız varsa giriş yapın</u>
        </p>
        <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
      </div>
    </div>
  );
};

export default withRouter(SignUp);

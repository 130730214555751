import React, { useEffect } from 'react';

import { Label, Input } from 'reactstrap';
import currency from 'currency.js'

/*
<InputA
type="text"
inputType="number"
label="Cep Telefonu"
name="cellPhone"
formValue={formData}
setFormValue={setFormdata}
maxLength={11}
minLength={11}
/>
*/



const InputA = ({
    type = "text",
    inputType = "text",
    label,
    name,
    formValue,
    setFormValue,
    placeHolder = `${label} Giriniz`,
    disabled = false,
    maxLength = 200,
    minLength = 1,
    maxValue = 0,
    minValue = 0,
    required = true
}) => {
    const tl = value => currency(value, { symbol: "", separator: ".", decimal: "," });

    useEffect(() => {
        const formValueObj = { ...formValue }
        formValueObj[name].required = required
        setFormValue(formValueObj)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [required])

    const handleBlur = (e) => {
        if (inputType !== "money") return
        const updateValue = (inputError = "") => {
            const formValueObj = { ...formValue }
            formValueObj[e.target.name].value = tl(e.target.value).format()
            formValueObj[e.target.name].error = inputError
            formValueObj[e.target.name].required = required
            setFormValue(formValueObj)
        }

        updateValue()

    }

    const handleOnChange = (e) => {
        const updateValue = (inputError = "") => {
            const formValueObj = { ...formValue }
            formValueObj[e.target.name].value = e.target.value
            formValueObj[e.target.name].error = inputError
            formValueObj[e.target.name].required = required
            // inputType number ise sadece numaraları kabul eder
            if (inputType === "number") {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                    setFormValue(formValueObj)
                }
            } else {
                setFormValue(formValueObj)
            }
        }


        if (type === "email") {
            if (!validateEmail(e.target.value)) {
                updateValue("Geçerli bir e-Posta adresi girin")
                return
            }
        }
        if (e.target.value === "") {
            updateValue("1")
            return
        }
        if (e.target.value.length > maxLength) {
            updateValue(`Maks ${maxLength} karatker.`)
            return
        }
        if (e.target.value.length < minLength) {
            updateValue(`Min ${minLength} karakter.`)
            return
        }

        if (minValue > 0) {
            if (e.target.value < minValue) {
                updateValue(`${minValue} den büyük olmalı.`)
                return
            }
        }
        updateValue()
    }


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const errorMesage = formValue[name]?.error


    return (
        < >
            <Label for={label}>{label}</Label>
            <Input
                className={errorMesage !== "" ? "border-danger" : null}
                type={type}
                name={name}
                id={name}
                placeholder={placeHolder}
                value={formValue[name].value}
                onChange={(e) => handleOnChange(e)}
                minLength={minLength}
                maxLength={maxLength}
                disabled={disabled}
                onBlur={(e) => handleBlur(e)}
            />

            <p className="my-1 text-danger" >
                {errorMesage === "" || errorMesage === "1" ? "" : errorMesage}
            </p>
        </>
    );
}

export default InputA;

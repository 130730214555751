import React from "react";

import { Row, Col, Button } from "reactstrap";


const Investment = ({ nextPage, handleChange }) => {

  const nextStep = async (num) => {
    handleChange(num);
    nextPage()
    // await base.syncStep({ newStep: 3, currentCompany, history });
  };
  return (
    <>
      <Row className="text-center mt-5">
        <Col>
          <h3>Yatırım</h3>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <p>Firmanıza yatırım almayı planlıyor musunuz?</p>
        </Col>
      </Row>
      <div className="text-center mt-5 mx-auto">
  
          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(3)}
          >
            Evet
          </Button>
    
  
          <Button
            color="secondary"
            className="mx-2 my-1"
            onClick={() => nextStep(1)}
          >
            Hayır
          </Button>
    
      </div>
    </>
  );
};

export default Investment;

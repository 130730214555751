import React, { useState, useEffect, useContext } from "react";
import { Col, Card, CardTitle, CardText, Button, Row } from "reactstrap";

import InputDropdownA from "../../../common/inputDropdownA";
import InputA from "../../../common/inputA";
import AddFile from "./addFile";
import { AuthContext } from "../../../base/auth";
import ListFiles from "../../../common/listFile";
import Documents from "../../establish/documents";

const EditEstablishTask = ({ selectedItem, establishTasks, files }) => {
  const { userData, base, currentCompany } = useContext(AuthContext);
  const initualFormValue = {
    durum: { value: "", error: "" },
    cost: { value: "", error: "" },
    order: { value: "", error: "" },
    not: { value: "", error: "" },
    mailMessage: { value: "", error: "" },
    nextTaskInfo: { value: "", error: "" },
  };
  const [formValue, setFormValue] = useState(initualFormValue);
  const [savedFiles, setSavedFiles] = useState([]);
  const [mailLog, setMailLog] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!establishTasks) return;
    if (typeof selectedItem !== "number") return;
    const array = [...establishTasks];

    const newForm = { ...formValue };
    newForm.durum.value = array[selectedItem].status;
    newForm.not.value = array[selectedItem]?.note || "";
    newForm.cost.value = array[selectedItem]?.cost || 0;
    newForm.order.value = array[selectedItem]?.order || "";
    newForm.mailMessage.value = array[selectedItem]?.mailMessage || "";
    newForm.nextTaskInfo.value = array[selectedItem]?.nextTaskInfo || "";
    setMailLog(array[selectedItem]?.mailLog || []);
    setFormValue(newForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishTasks, selectedItem, userData.currentCompany]);

  const colors = {
    Bekliyor: "warning",
    Gerekmiyor: "secondary",
    İşlemde: "info",
    Tamamlandı: "success",
  };

  const handleSave = async (log) => {
    const newData = [...establishTasks];
    const obj = newData[selectedItem];
    obj.status = formValue.durum.value;
    obj.note = formValue.not.value;
    obj.color = colors[formValue.durum.value];
    obj.mailMessage = formValue.mailMessage.value;
    obj.nextTaskInfo = formValue.nextTaskInfo.value;
    obj.cost = Number(formValue.cost.value);
    obj.order = formValue.order.value;
    obj.mailLog = log || mailLog;

    const id = newData[selectedItem].id;

    await base.updateEstablishTaskToDb({
      companyID: userData.currentCompany,
      updateKeys: { [id]: obj },
    });
  };

  const getEmails = async () => {
    const users = currentCompany?.users;
    const emails = [];
    for (const key in users) {
      emails.push(users[key].email);
    }
    return emails;
  };

  const getFiles = async () => {
    const fileIDarray = [];
    const currentAttachments = establishTasks?.[selectedItem]?.attachments;
    if (!currentAttachments) return [];
    const filteredFile = files.filter((file) => {
      return currentAttachments.some((att) => att === file.category);
    });
    filteredFile.forEach((file) => fileIDarray.push(file.id));
    return fileIDarray;
  };

  const handleSendMail = async () => {
    setDisabled(true);
    const toplamAdim = establishTasks.length;
    const adim = Number(establishTasks?.[selectedItem].id);
    const kalanAdim = toplamAdim - adim;
    const adimBilgi = `${adim}/${toplamAdim}`;

    const fileIDarray = await getFiles();
    const emails = await getEmails();

    const message =
      establishTasks?.[selectedItem]?.mailMessage || "Dosyalarınız ektedir";
    const nextTaskInfo = establishTasks?.[selectedItem]?.nextTaskInfo || "";
    const title = adim + " " + establishTasks?.[selectedItem]?.task;
    const objData = {
      email: emails.toString(),
      companyID: userData.currentCompany,
      subject: `${adimBilgi} Şirket Kuruluş İşlemleri`,
      fileID: fileIDarray,
      mailMessage: establishTasks?.[selectedItem]?.mailMessage || "",
      mailBody: ` 
      <h2>${title}</h2>
      <p> ${establishTasks?.[selectedItem]?.note || ""}  </p>
      <br />
      <p> ${message}  </p>
      <p> ${nextTaskInfo ? `Sıradaki İşlem : ${nextTaskInfo}` : ""}  </p>
      <p> Tüm işlemlerin tamamlanmasına ${kalanAdim} adım kaldı.</p>
      `,
    };

    const res = await base.callFunction({
      objData,
      functionName: "sendInformationMail",
    });
    const date = new Date().toLocaleString();
    const logs = [...mailLog];
    logs.push(`${res} - ${date}`);
    setMailLog(logs);
    await handleSave(logs);
    setDisabled(false);
  };

  return (
    <Col md={4} className="mt-3">
      <Card body className={selectedItem !== null ? null : "d-none"}>
        <CardTitle tag="h5">{establishTasks?.[selectedItem]?.task}</CardTitle>
        <CardText>Durum: {establishTasks?.[selectedItem]?.status}</CardText>
        <CardText>{establishTasks?.[selectedItem]?.descripsion}</CardText>
        <InputDropdownA
          label="durum"
          name="durum"
          formValue={formValue}
          setFormValue={setFormValue}
          options={["Bekliyor", "Gerekmiyor", "İşlemde", "Tamamlandı"]}
        />
        <InputA
          label="Not"
          name="not"
          type="textarea"
          formValue={formValue}
          setFormValue={setFormValue}
        />

      <div className="row">
      <div className="col">
       <InputA
          label="Maliyet"
          name="cost"
          type="number"
          formValue={formValue}
          setFormValue={setFormValue}
        />
       </div>

        <div className="col">
        <InputDropdownA
          label="Yapılacak"
          name="order"
          formValue={formValue}
          setFormValue={setFormValue}
          options={["Evet", "Hayır"]}
        />
        </div>
      </div>

        <ListFiles
          companyID={userData.currentCompany}
          attachments={establishTasks?.[selectedItem]?.attachments}
          savedFiles={savedFiles}
          setSavedFiles={setSavedFiles}
          files={files}
          hiddenDelButton={false}
          establishTasks={establishTasks}
          selectedItem={selectedItem}
        />
        <AddFile
          options={establishTasks?.[selectedItem]?.attachments}
          savedFiles={savedFiles}
          setSavedFiles={setSavedFiles}
        />
        <InputA
          label="Mail Mesajı"
          name="mailMessage"
          type="textarea"
          formValue={formValue}
          setFormValue={setFormValue}
        />
        <InputA
          label="Sonraki Aşama"
          name="nextTaskInfo"
          type="textarea"
          formValue={formValue}
          setFormValue={setFormValue}
        />
        <Row>
          <Col>
            <Button onClick={() => handleSave()}>Kaydet</Button>
          </Col>
          <Col>
            <Button
              disabled={
                establishTasks?.[selectedItem]?.status !== "Tamamlandı" ||
                disabled
              }
              onClick={() => handleSendMail()}
            >
              Mail gönder
            </Button>
          </Col>
        </Row>
      </Card>
      {mailLog ? (
        <Card body className={selectedItem !== null ? null : "d-none"}>
          <Row>
            <Col>
              {mailLog.map((log, index) => (
                <p key={index}>{log}</p>
              ))}
            </Col>
          </Row>
        </Card>
      ) : null}
      <Card body className={files.length === 0 && "d-none"}>
            <Documents files={files} />
          </Card>
    </Col>
  );
};

export default EditEstablishTask;

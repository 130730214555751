import React from "react";

const TableBody = ({ columns, tableData }) => {
  const createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  const getProp = (object, keys, defaultVal) => {
    keys = Array.isArray(keys) ? keys : keys.split(".");
    object = object[keys[0]];
    if (object && keys.length > 1) {
      return getProp(object, keys.slice(1));
    }
    return object === undefined ? defaultVal : object;
  };

  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return getProp(item, column.path, "");
  };

  return (
    <tbody className="">
      {tableData.map((item) => (
        <tr key={item._id}>
          {columns.map((column) => (
            <td key={createKey(item, column)}>{renderCell(item, column)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

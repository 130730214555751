import React from "react";
import { Label, Input, FormFeedback, Col, FormText } from "reactstrap";

const FormInput = ({ name, label, error, helpMessage, ...rest }) => {
  return (
    <Col >
      <Label className="" for={name}>
        {label}
      </Label>
      <Input  {...rest} name={name} id={name} invalid={error} />
      {helpMessage && <FormText>{helpMessage}</FormText>}
      {error && <FormFeedback tooltip> {error}</FormFeedback>}
    </Col>
  );
};

export default FormInput;



 

import React, { useState, useEffect } from "react";
import currency from "currency.js";

const costs = {
  "Şahıs Firması": [
    { costTitle: "Ticaret Odası Tescil", cost: 0 },
    { costTitle: "Noter", cost: 0 },
    { costTitle: "Muhasebe Programı (yıllık)", cost: 935 },
    { costTitle: "Mali Mühür", cost: 217.12 },
    { costTitle: "Hizmet Bedeli", cost: 413 },
  ],
  "Limited/Anonim Şirket": [
    { costTitle: "Ticaret Odası Tescil", cost: 1500 },
    { costTitle: "Noter", cost: 200 },
    { costTitle: "Muhasebe Programı (yıllık)", cost: 935 },
    { costTitle: "Mali Mühür", cost: 342.2 },
    { costTitle: "Hizmet Bedeli", cost: 944 },
  ],
};

const product = [
  {
    costTitle: "Ticaret Odası Tescil",
    to: "kurum",
    cost: 0,
    costKurum: 1500,
  },
  { costTitle: "Noter", to: "kurum", cost: 0, costKurum: 200 },
  { costTitle: "Muhasebe Programı (yıllık)", to: "partner", cost: 935 },
  { costTitle: "Mali Mühür", to: "kurum", cost: 217.12, costKurum: 342.2 },
  { costTitle: "Hizmet Bedeli", to: null, cost: 450, costKurum: 950 },
  { costTitle: "Web sitesi + Domain + e-posta", to: "partner", cost: 1060 },
  { costTitle: "Logo Tasarımı", to: "partner", cost: 550 },
  { costTitle: "Kartvizit", to: "partner", cost: 350 },
  { costTitle: "Kaşe", to: "partner", cost: 40 },
  { costTitle: "Marka Tescili", to: "partner", cost: 1400 },
  { costTitle: "Sabit Telefon Numarası", to: "partner", cost: 413 },
];

const Cost = ({ companyType }) => {
  const [sum, setSum] = useState(0);
  const [costArray, setCostArray] = useState([]);

  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });

  useEffect(() => {
    if (!companyType) return;
    let array = [];
    if (companyType.includes("Şahıs")) {
      array.push(costs["Şahıs Firması"]);
    } else {
      array.push(costs["Limited/Anonim Şirket"]);
    }
    setCostArray(...array);
  }, [companyType]);

  useEffect(() => {
    if (costArray.length === 0) return;
    let total = 0;
    costArray.forEach((item) => {
      if (item.cost > 0) {
        total += item.cost;
      }
      setSum(total);
    });
  });

  if (costArray.length === 0) return null;

  return (
    <>
      {" "}
      <p>{companyType} Tahmini Kuruluş Maliyetleri</p>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Tür</th>
            <th scope="col">Tutar</th>
          </tr>
        </thead>
        <tbody>
          {costArray.map((x, i) => (
            <tr key={i}>
              <td className="">{x.costTitle}</td>
              <td className="text-right">{tl(x.cost).format()}</td>
            </tr>
          ))}
          <tr>
            {" "}
            <td className="">Toplam Maliyet</td>
            <td className="text-right">
              <strong>{tl(sum).format()}</strong>
            </td>
          </tr>
        </tbody>
      </table>{" "}
    </>
  );
};

export default Cost;

import React from "react";
import { Button, Alert , Breadcrumb, BreadcrumbItem} from "reactstrap";

import * as ROUTES from "../../base/routes";
//import InputA from '../../common/inputA';
import InputDropdownA from "../../common/inputDropdownA";
import { AuthContext } from "../../base/auth";
import Loading from "../../common/loading";

const Representation = ({ history, setStep, partners }) => {
  const { currentCompany, userData, base } = React.useContext(AuthContext);

  const [managers, setManagers] = React.useState([]);

  React.useEffect(() => {
    if (!partners) return;
    setManagers(partners.filter((x) => x.manager));
  }, [currentCompany, partners]);

  const [pending, setPending] = React.useState(true);
  const [formData, setFormdata] = React.useState({});

  React.useEffect(() => {
    if (managers.length === 0) return;
    const initualFormValue = {};

    managers.forEach((manager) => {
      initualFormValue[`authType${manager.id}`] = {
        value: "",
        error: "",
      };
      initualFormValue[`authTime${manager.id}`] = {
        value: "",
        error: "",
      };
      initualFormValue[`manageWith${manager.id}`] = {};
    });
    const newManagersArray = [...managers];
    newManagersArray.map((manager, index) => {
      const authType = manager.authType ? manager.authType : "";
      const authTime = manager.authTime ? manager.authTime : "";
      const manageWith =
        Object.keys(manager.manageWith).length !== 0 ? manager.manageWith : {};
        initualFormValue[`authType${manager.id}`].value = authType;
        initualFormValue[`authTime${manager.id}`].value = authTime;
        initualFormValue[`manageWith${manager.id}`] = manageWith;
      return manager;
    });

    setFormdata(initualFormValue);
    setPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers]);


  const [validation, setValidation] = React.useState(false);

  const handleSave = async () => {
    setValidation("");
    if (managers.legth === 0) return;

    const newManagersArray = [...managers];

    newManagersArray.map((manager) => {
      const authType = formData[`authType${manager.id}`]?.value;
      const authTime = formData[`authTime${manager.id}`]?.value;
      const manageWith = formData[`manageWith${manager.id}`];

      if (manager.manager && (authType === "" || authTime === "")) {
        return setValidation(`${manager.name} için tüm alanları doldurunuz`);
      }

      if (
        manager.manager &&
        authType === "Diğer yetkililer ile birlikte imza atar" &&
        Object.keys(manageWith).length === 0
      ) {
        return setValidation(
          `${manager.name}'in birlikte imza atacağı kişileri seçin`
        );
      }
      manager.authType = authType;
      manager.authTime = authTime;
      manager.manageWith = manageWith;
      return manager;
    });
    for await (const manager of managers) {
      await base.addNewPartner({companyID:userData.currentCompany,data:manager})
    }
    // base.updateCompanyToDb({
    //   companyID: userData.currentCompany,
    //   updateKeys: { partner: partners },
    // });
    base.syncStep({ newStep: 10, currentCompany, history });
  };

  React.useEffect(() => {
    if (!currentCompany?.companyType) return;
    if (currentCompany?.companyType.includes("Şahıs")) {
      history.push(ROUTES.COMCAPITAL);
    }
  }, [currentCompany, history]);
  // const test = true
  if (!formData) return <Loading />;
  if (managers.length === 0) return <Loading />;
  if (pending) return <Loading />;

  let signOptionsLTD = [
    "Tek Başına İmza Atabilir",
    "Diğer yetkililer ile birlikte imza atar",
  ];
  let signOptionsAS = [
    "Tek Başına İmza Atabilir",
    "Diğer yetkililer ile birlikte imza atar",
  ];

  if (managers.length === 1) {
    signOptionsLTD = ["Tek Başına İmza Atabilir"];
    signOptionsAS = ["Tek Başına İmza Atabilir"];
  }

  const signOptions =
    currentCompany.companyType === "Anonim Şirket"
      ? signOptionsAS
      : signOptionsLTD;

  if (partners.length === 0) return;

  return (
    <>
       <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              İmza Yetkilileri
            </BreadcrumbItem>
          </Breadcrumb>
      <p>Yöneticilerin imza yetkilerini belirleyiniz.</p>
      {managers.map((partner, index) => (
        <div key={partner.id} className="my-1 pointer">
          <div className={partner.manager ? "" : ""}>
            {partner.partner
              ? `${index + 1}. Ortak ve Yönetici `
              : `${index + 1}. Ortak Olmayan Yönetici `}

            <strong>
              {partner?.name} {partner?.surName}
            </strong>

            <InputDropdownA
              label="Yetki Şekli"
              name={`authType${partner.id}`}
              formValue={formData}
              setFormValue={setFormdata}
              options={signOptions}
            />
            {formData[`authType${partner.id}`].value ===
            "Diğer yetkililer ile birlikte imza atar" ? (
              <List
                partners={partners}
                formData={formData}
                setFormdata={setFormdata}
                listid={index}
                manageWith={formData[`manageWith${partner.id}`]}
                partnerNameOnList={`${partner.name} ${partner.surName}`}
              />
            ) : null}
            <InputDropdownA
              label="Yetki Süresi"
              name={`authTime${partner.id}`}
              formValue={formData}
              setFormValue={setFormdata}
              options={[
                "6 ay",
                "1 yıl",
                "2 yıl",
                "3 yıl",
                "5 yıl",
                "Aksi Karar Alınana Kadar",
              ]}
            />
            <hr />
          </div>
        </div>
      ))}
      {validation ? <Alert color="warning"> {validation} </Alert> : null}
      <Button
        className="my-3"
        onClick={() => history.push(ROUTES.AUTHORITIES)}
        color="secondary"
      >
        {" "}
        Geri{" "}
      </Button>
      <Button className="m-3" onClick={() => handleSave()} color="primary">
        {" "}
        Kaydet{" "}
      </Button>
    </>
  );
};
export default Representation;

const List = ({
  partners,
  listid,
  formData,
  setFormdata,
  manageWith,
  partnerNameOnList,
}) => {
  const managers = partners.filter((x) => x.manager);
  const managersFilter = managers.filter((x) => x.id !== listid);

  const handleChangeManageWith = ({ id, name }) => {
    const obj = { ...formData };

    const findCurrentId = manageWith[id];
    if (!findCurrentId)
      obj[`manageWith${listid}`] = { [id]: { id, name }, ...manageWith };
    if (findCurrentId) delete obj[`manageWith${listid}`][id];
    setFormdata(obj);
  };

  return (
    <>
      {managersFilter.map((partner) => (
        <ul
          key={partner.id}
          className="my-1 list-group pointer"
          onClick={() =>
            handleChangeManageWith({ id: partner.id, name: partner.name })
          }
        >
          <li
            className={
              manageWith[partner.id]
                ? "list-group-item list-group-item-action  list-group-item-primary py-2"
                : "list-group-item list-group-item-action py-1"
            }
          >
            {/* <small>{partnerNameOnList}</small> */}

            {/* {partner.partner ?
                            ` Ortak `
                            :
                            ` Ortak Olmayan Yönetici `} */}

            <strong></strong>
            <span
              className={
                manageWith[partner.id]
                  ? "badge bg-light text-dark "
                  : "badge bg-warning text-dark"
              }
            >
              {manageWith[partner.id]
                ? `${partner.name} ${partner.surName} İle aynı anda imza atar`
                : `${partner.name} ${partner.surName} Olmadan imza atar`}
            </span>
          </li>
        </ul>
      ))}
    </>
  );
};

import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

import { withRouter } from "react-router";
import { AuthContext } from "../../base/auth";
import Logo from "../../common/Logo";
import * as ROUTES from "../../base/routes";
import "./signIn.css";

const Sign = ({ history, eMail, userName }) => {
  const { base } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [disabledEmailInput, setDisabledEmailInput] = useState(false);
  const [userExsist, setUserExsist] = useState(null);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [displayName, setdisplayName] = useState("");
  const [message, setMessage] = useState({
    text: "",
    style: "d-none",
    role: "",
    click: false,
  });
  const [disabled, setDisabled] = useState(true);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // it's no longer the first render
      return; // skip the code below
    }
    if (eMail) setDisabledEmailInput(true);
    if (userExsist === true) {
      if (password.length > 5) {
        setDisabled(false);
      }
    }
    if (userExsist === false) {
      if (
        email.length > 5 &&
        password.length > 5 &&
        displayName.length > 5 &&
        password === password2
      ) {
        setDisabled(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, password2, displayName]);

  useEffect(() => {
    if (eMail) setEmail(eMail);
    if (userName) setdisplayName(userName);
  }, [eMail, userName]);

  const checkEmail = async () => {
    setDisabled(true);
    const checkEmailfromDB = await base.checkEmailExist({ email });
    setUserExsist(checkEmailfromDB);
    console.log(checkEmailfromDB);
    setDisabled(false);
  };

  //   if (currentUser) {
  //     return <Redirect to={ROUTES.HOME} />;
  //   }

  const handleSignUp = useCallback(
    async (event) => {
      //event.preventDefault();
      //const { email, password, displayName } = event.target.elements;
      try {
        await base.doCreateUserWithEmailAndPassword(
          email,
          password,
          displayName
        );
        //history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, password, displayName]
  );

  const handleLogin = useCallback(async () => {
    //event.preventDefault();
    //const { email, password } = event.target.elements;
    try {
      await base.doSignInWithEmailAndPassword(email, password);

      //history.push("/");
    } catch (error) {
      setMessage({
        text: "Hatalı e posta veya parola. Parolanızı mi unuttunuz ?",
        style: "alert alert-warning pointer",
        role: "alert",
        click: true,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  const forgetPassword = () => {
    base
      .doPasswordReset(email)
      .then(function () {
        setMessage({
          text: "e Posta Gönderildi. Gelen ve Önemsiz e posta klasörünüzü ediniz.",
          style: "alert alert-warning",
          role: "alert",
          click: false,
        });
      })
      .catch(function (error) {
        setMessage({
          text: error.message,
          style: "alert alert-warning",
          role: "alert",
          click: false,
        });
      });
  };

  return (
    <div className="text-center col-md-3 container mt-5 beyaz">
      <>
        <Logo className="App-logo rounded mx-auto d-block" height="75px" />
        <h1 className="h3 mb-3 font-weight-normal">Giriş Yapın</h1>
        <p className="mt-2">
          Yeni Şirket Kur'a e-posta ve parolanız ile giriş yapabilirsiniz.
        </p>

        <label htmlFor="inputEmail" className="sr-only">
          Email address
        </label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className="my-2 form-control"
          name="email"
          type="email"
          placeholder="e-Posta"
          value={email}
          autoFocus
          required
          disabled={disabledEmailInput}
        />
        <div
          onClick={message.click ? (e) => forgetPassword() : null}
          className={message.style}
          role={message.role}
        >
          <u>{message.text}</u>
        </div>

        {userExsist === null ? (
          <button
            disabled={false}
            className="btn btn-lg btn-primary btn-block"
            onClick={() => {
              checkEmail();
            }}
          >
            Giriş
          </button>
        ) : null}

        {userExsist === true ? (
          <>
            {" "}
            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="my-2 form-control"
              name="password"
              type="password"
              placeholder="Parola"
              value={password}
              required
            />
            <button
              disabled={disabled}
              className="btn btn-lg btn-primary btn-block"
              onClick={() => {
                handleLogin();
              }}
            >
              Giriş
            </button>
          </>
        ) : null}

        {userExsist === false ? (
          <>
            <label htmlFor="inputEmail" className="sr-only">
              Kullanıcı Adı
            </label>
            <input
              onChange={(e) => setdisplayName(e.target.value)}
              className="my-2 form-control"
              name="displayName"
              type="displayName"
              placeholder="Ad Soyad"
              value={displayName}
              autoFocus
              required
            />
            <label htmlFor="inputEmail" className="sr-only">
              Email address
            </label>

            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="my-2 form-control"
              name="password"
              type="password"
              placeholder="Parola"
              value={password}
              required
            />
            <input
              onChange={(e) => setPassword2(e.target.value)}
              className="my-2 form-control"
              name="password2"
              type="password"
              placeholder="Parolayı Doğrula"
              value={password2}
              required
            />
            <button
              disabled={disabled}
              className="btn btn-lg btn-primary btn-block"
              onClick={() => {
                handleSignUp();
              }}
            >
              Kaydol
            </button>
          </>
        ) : null}

        <p
          onClick={(e) => history.push(ROUTES.SIGNUP)}
          className="my-2 pointer"
        >
          {/* <u>Hesabınız yoksa yeni bir hesap açın</u> */}
        </p>
        <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
      </>
    </div>
  );
};

export default withRouter(Sign);

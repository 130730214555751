import React from "react";

const Loading = () => {
  return (
    <div className="text-center col-md-3 container mt-5 ">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;

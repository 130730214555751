import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../base/auth";
import InputDropdownA from "../../../common/inputDropdownA";
import { Col, Row, Button, Alert } from "reactstrap";
import UploadFile from "../../../common/uploadFile";

const AddFile = ({ options }) => {
  const { base, userData } = useContext(AuthContext);
  const [page, setPage] = useState("Belge Turu");

  const initualFormValue = {
    fileType: { value: "", error: "" },
  };

  const handleCancel = () => {
    setFormValue(initualFormValue);
    // setBase64(null);
    setPage("Belge Turu");
  };

  const [formValue, setFormValue] = useState(initualFormValue);

  useEffect(() => {
    if (formValue.fileType.value.length > 0) {
      setPage("Dosya Yükle");
    }
  }, [formValue]);

  if (!options) return null;

  if (page === "Dosya Yükle")
    return (
      <>
        <hr />
        <Alert color="warning">{formValue.fileType.value} yüklüyorsunuz</Alert>
        <UploadFile
          handleCancel={handleCancel}
          category={formValue.fileType.value}
        />
        <hr />
      </>
    );

  return (
    <>
      <hr />
      <InputDropdownA
        label="Belge Ekleyin"
        name="fileType"
        formValue={formValue}
        setFormValue={setFormValue}
        options={options}
      />
      <hr />
    </>
  );
};

export default AddFile;

import React, { useEffect } from 'react';

import { Row, Button, Form, FormGroup, Label, Input, Col, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Alert } from 'reactstrap';

const InputDropdownA = ({
    type = "text",
    inputType = "text",
    label,
    name,
    formValue,
    setFormValue,
    placeHolder = `${label} Giriniz`,
    disabled = false,
    maxLength = 200,
    minLength = 1,
    required = true,
    options
}) => {

    useEffect(() => {
        const formValueObj = { ...formValue }
        formValueObj[name].required = required
        setFormValue(formValueObj)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [required])


    const handleOnChange = (e) => {
        const updateValue = (inputError = "") => {
            const formValueObj = { ...formValue }
            if (!formValueObj[e.target.name]) return

            formValueObj[e.target.name].value = e.target.value
            formValueObj[e.target.name].error = inputError
            formValueObj[e.target.name].required = required
            setFormValue(formValueObj)

        }
        if (e.target.value === "") {
            updateValue("1")
            return
        }
        updateValue()
    }

    //const errorMesage = formValue[name]?.error

    return (
        <Col className="my-3">
            <Row >
                <UncontrolledDropdown  >
                    <DropdownToggle caret >
                        {label}
                    </DropdownToggle  >
                    <DropdownMenu onClick={(e) => handleOnChange(e)}  >
                        {options.map(option => (
                            <DropdownItem name={name} value={option}>{option}</DropdownItem>

                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <span className="m-2" >  {formValue[name].value}</span>
            </Row>
        </Col>
    );
}

export default InputDropdownA;

import React, { useState, useEffect } from "react";
import useDbData from "../../common/hooks/useDbData";
import currency from "currency.js";
import { Badge, Alert, Card } from "reactstrap";
import Documents from "./documents";

const CompanyInfo = ({ files }) => {
  const { partners } = useDbData({
    collectionName: "partners",
    docID: "",
    query: "",
    listener: false,
  });
  const { data } = useDbData({
    collectionName: "data",
    docID: "",
    query: "",
    listener: false,
  });

  const [aplicationData, setAplicationData] = useState([]);
  const [registeredData, setRegisteredData] = useState([]);

  useEffect(() => {
    setAplicationData(data.find((x) => x.id === "aplicationData"));
    setRegisteredData(data.find((x) => x.id === "registeredData"));
  }, [data]);

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          {!registeredData?.unvan ? (
            <Alert color="warning">Tescil Bekleniyor...</Alert>
          ) : (
            <RegInfos
              registeredData={registeredData}
              aplicationData={aplicationData}
              partners={partners}
            />
          )}
        </div>
        <div className="col">
          <Card body className={files.length === 0 && "d-none"}>
            <Documents files={files} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default CompanyInfo;

const RegInfos = ({ registeredData, aplicationData, partners }) => {
  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });

  return (
    <>
      <h4>Tescilli Şirket Bilgileri:</h4>
      <ul>
        <li>
          Ünvan: <strong>{registeredData?.unvan}</strong>
        </li>
        <li>
          Vergi Dairesi - No: {registeredData?.vergiDairesi} -{" "}
          <strong>{registeredData?.vergiNo}</strong>
        </li>
        <li>Mersis No: {registeredData?.mersisNo}</li>
      </ul>
      <ul>
        <li>Adres: {registeredData?.adres}</li>
        <li>ePosta: {registeredData?.ePosta}</li>
        <li>Telefon: {registeredData?.telefon}</li>
      </ul>
      <ul>
        <li>Ticaret Sicil Müdürlüğü: {registeredData?.sicilMudurlugu}</li>
        <li>Sicil No: {registeredData?.sicilNo}</li>
        <li>Kuruluş Tarihi: {registeredData?.kurulusT}</li>
      </ul>
      <hr />
      <small>
        <ul>
          {aplicationData?.nace.map((n) =>
            n.main ? (
              <li key={n.kod}>
                <Badge color="success">Ana Faaliyet</Badge>{" "}
                <strong>
                  {n.kod} - {n.tanim}
                </strong>
              </li>
            ) : (
              <li key={n.kod}>
                {n.kod} - {n.tanim}
              </li>
            )
          )}
        </ul>
      </small>
      <h5>Ortak Bilgileri:</h5>
      {partners.length > 0 &&
        partners.map((part) => (
          <small>
            <ul key={part.id}>
              <li>
                {part.name} {part.surName} - {part.partner ? <>Ortak</> : null}{" "}
                <ul>
                  <li>
                    {part.eMail} - {part.cellPhone} {part.cellPhoneOperator}
                  </li>

                  <li>
                    TC: {part.idNumberTC} - Kimlik Seri: {part.idSerial}{" "}
                  </li>
                  <li>
                    Doğum Tarihi: {part.dateOfBirth} - yaşı: {part?.age}{" "}
                  </li>
                  <li>Doğum Yeri: {part.placeOfBirth} </li>

                  {part.capital ? (
                    <li>
                      sermaye : {part.capital.value}-TL %
                      {tl(part.capital.share).format()}{" "}
                    </li>
                  ) : null}

                  {part.manager ? (
                    <li>
                      Yetki : {part.authType} - {part.authTime}
                      <br />
                      {part.manageWithText ? (
                        <span> {part.manageWithText} ile birlikte</span>
                      ) : null}
                    </li>
                  ) : null}
                </ul>
              </li>
            </ul>
            <hr />
          </small>
        ))}
    </>
  );
};

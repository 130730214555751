import React, { useState, useEffect, useContext } from "react";
import { Button, ButtonGroup, Col, Row ,Alert } from "reactstrap";
import InputA from "../../common/inputA";
import { AuthContext } from "../../base/auth";

const CompRegInfo = ({ history }) => {
  const { base, currentCompany, userData } = useContext(AuthContext);
  const initualFormValue = {
    mersis: { value: "", error: "" },
    unvan: { value: "", error: "" },
    kurulusT: { value: "", error: "" },
    mersisNo: { value: "", error: "" },
    vergiDairesi: { value: "", error: "" },
    vergiNo: { value: "", error: "" },
    sicilNo: { value: "", error: "" },
    sicilMudurlugu: { value: "", error: "" },
    adres: { value: "", error: "" },
    telefon: { value: "", error: "" },
    ePosta: { value: "", error: "" },
    tckn: { value: "", error: "" },
  };

  // validation: value dolu error boş olmalı
  const [formData, setFormdata] = useState(initualFormValue);
  const [validation, setValidation] = useState(false);
  const [data, setData] = useState({});
  const [mailLog, setMailLog] = useState([]);
  const [girisTuru, setGirisTuru] = useState(1);
  const [ivdMessage, seIvdMessage] = useState(null);

  const veriAl = (metinF) => {
    const parca = (metin, ilkKelime, sonKelime) => {
      let ilk = metin.search(ilkKelime);
      ilk += ilkKelime.length;
      let son = metin.search(sonKelime);
      let parca = metin.slice(ilk, son);
      return parca;
    };

    let unvan = parca(metinF, "Unvan", "Kuruluş Tarihi");
    let kurulusT = parca(metinF, "Kuruluş Tarihi", "Firma Durumu");
    let mersisNo = parca(metinF, "Mersis No", "Vergi Dairesi");
    let vergiDairesi = parca(metinF, "Vergi Dairesi / No", "VERGİ DAİRESİ");
    let vergiNo = parca(metinF, "DAİRESİ /", "Ticaret Sicil No");
    let sicilNo = parca(metinF, "Dosya No", "Firma Türü");
    let sicilMudurlugu = parca(metinF, "Müdürlüğü", "Şehir");
    let adres = parca(metinF, "Adres Bilgisi", "Elektronik Tebligat Adresi");
    const obj = {
      unvan,
      kurulusT,
      mersisNo,
      vergiDairesi,
      vergiNo,
      sicilNo,
      sicilMudurlugu,
      adres,
    };
    return obj;
  };

  useEffect(() => {
    const formObj = { ...formData };
    if (formData.mersis.value.length === 0) return;
    const firmaVerileri = veriAl(formData.mersis.value);
    formObj.unvan.value = firmaVerileri.unvan;
    formObj.kurulusT.value = firmaVerileri.kurulusT;
    formObj.mersisNo.value = firmaVerileri.mersisNo;
    formObj.vergiDairesi.value = firmaVerileri.vergiDairesi;
    formObj.vergiNo.value = firmaVerileri.vergiNo;
    formObj.sicilNo.value = firmaVerileri.sicilNo;
    formObj.sicilMudurlugu.value = firmaVerileri.sicilMudurlugu;
    formObj.adres.value = firmaVerileri.adres;

    setFormdata(formObj);
  }, [formData.mersis.value]);

  // burada datayı db den oku.
  useEffect(() => {
    const getData = async () => {
      const data = await base.getRegisterData({ companyID: currentCompany.id });
      setData(data);
    };
    getData();
  }, [currentCompany]);

  // form verisini güncelle

  useEffect(() => {
    if (!data.vergiNo) return;
    const obj = { ...formData };
    obj.mersis.value = data.mersis;
    obj.unvan.value = data.unvan;
    obj.kurulusT.value = data.kurulusT;
    obj.mersisNo.value = data.mersisNo;
    obj.vergiDairesi.value = data.vergiDairesi;
    obj.vergiNo.value = data.vergiNo;
    obj.sicilNo.value = data.sicilNo;
    obj.sicilMudurlugu.value = data.sicilMudurlugu;
    obj.adres.value = data.adres;
    obj.telefon.value = data.telefon;
    obj.ePosta.value = data.ePosta;
    setFormdata(obj);
  }, [data]);

  const handleSave = async (logs) => {
    if (formData.mersis.value)
      await base.syncStep({ newStep: 17, currentCompany, history });
    // await base.updateCompanyToDb({
    //   companyID: userData.currentCompany,
    //   updateKeys: { registeredInformation: true },
    // });
    const obj = {};
    obj.mersis = formData.mersis.value;
    obj.unvan = formData.unvan.value;
    obj.kurulusT = formData.kurulusT.value;
    obj.mersisNo = formData.mersisNo.value;
    obj.vergiDairesi = formData.vergiDairesi.value;
    obj.vergiNo = formData.vergiNo.value;
    obj.sicilNo = formData.sicilNo.value;
    obj.sicilMudurlugu = formData.sicilMudurlugu.value;
    obj.adres = formData.adres.value;
    obj.telefon = formData.telefon.value;
    obj.ePosta = formData.ePosta.value;
    obj.mailLog = logs || mailLog;
    await base.setRegisteredDataToDb({
      companyID: userData.currentCompany,
      data: obj,
    });
  };

  const handleGetDataFromTaxOffice = async () => {
    try {
      seIvdMessage(null);
      const objData = {
        vergiNo: formData["vergiNo"].value,
        vergiDairesi: formData["vergiDairesi"].value,
      };
      const data = await base.callFunction({
        objData,
        functionName: "vergiNoCheck",
      });

      if (!data.vkn) {
        seIvdMessage("veri yok");
        return;
      }
      const obj = { ...formData };
      obj.mersis.value = "";
      obj.unvan.value = data.unvan || data.adsoyad;
      obj.kurulusT.value = data.isebaslamatarihi;
      obj.mersisNo.value = "";
      obj.vergiDairesi.value = data.vdadi;
      obj.vergiNo.value = data.vkn;
      obj.sicilNo.value = "";
      obj.sicilMudurlugu.value = "";
      obj.adres.value = data.adres;
      // obj.telefon.value = data.telefon;
      // obj.ePosta.value = data.ePosta;
      setFormdata(obj);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMail = async () => {
    const fileIDarray = [];
    //files.forEach((file) => fileIDarray.push(file.id));
    const emails = [];
    const users = currentCompany?.users;

    for (const key in users) {
      emails.push(users[key].email);
    }

    const objData = {
      email: emails.toString(),
      //email: '',
      companyID: userData.currentCompany,
      subject: "Firma Bilgileriniz",
      fileID: [],

      mailBody: ` 
      <p>Ünvan:${data.unvan}</p>
      <p>Vergi Dairesi - No:${data.vergiDairesi} - ${data.vergiNo}</p>
      <p>Mersis No::${data.mersisNo}</p>
      <br>
      <p>Adres: ${data.adres}</p>
      <p>ePosta: ${data.ePosta}</p>
      <p>Telefon: ${data.telefon}</p>
      <br>
      <p>Ticaret Sicil Müdürlüğü:${data.sicilMudurlugu}</p>
      <p>Sicil No:${data.sicilNo}</p>
      <p>Kuruluş Tarihi:${data.kurulusT}</p>
      <hr>
      <p>Yukarıdaki bilgiler ile şirketiniz adına tüm işlemleri yapabilirsiniz.</p>
      <p>Artık şirketinize fatura düzenleyecek satıcılar ile paylaşabilirsiniz.</p>
      <p>Kira kontratınızı bu bilgiler ile hazırlayabilirsiniz.</p>
      `,
    };

    const res = await base.callFunction({
      objData,
      functionName: "sendInformationMail",
    });
    const date = new Date().toLocaleString();
    const logs = [...mailLog];
    logs.push(`${res} - ${date}`);
    setMailLog(logs);
    await handleSave(logs);
  };

  return (
    <Col md={5}>
      <Row>
        <ButtonGroup className="m-3">
          <Button
            color="primary"
            onClick={() => setGirisTuru(1)}
            active={girisTuru === 1}
          >
            Firma Bilgilerini Gir
          </Button>
          <Button
            color="primary"
            onClick={() => setGirisTuru(2)}
            active={girisTuru === 2}
          >
            Mersisten Yapıştır
          </Button>
          <Button
            color="primary"
            onClick={() => setGirisTuru(3)}
            active={girisTuru === 2}
          >
            Vergi Dairesinden Çek
          </Button>
        </ButtonGroup>
      </Row>
      {girisTuru === 2 ? (
        <>
          <InputA
            type="textarea"
            inputType="text"
            label="Mersisten Yapıştır"
            name="mersis"
            placeholder="M"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={500}
            minLength={10}
          />

          <ul>
            <li>Ünvan : {formData.unvan.value}</li>
            <li>Kuruluş Tarihi: {formData.kurulusT.value}</li>
            <li>Mersis No: {formData.mersisNo.value}</li>
            <li>Vergi Daires: {formData.vergiDairesi.value}</li>
            <li>Vergi No: {formData.vergiNo.value}</li>
            <li>Ticaret Sicil no: {formData.sicilNo.value}</li>
            <li>Ticaret Sicil Müdürlüğü: {formData.sicilMudurlugu.value}</li>
            <li>Adres: {formData.adres.value}</li>
          </ul>
        </>
      ) : null}

      {girisTuru === 3 ? (
        <>
          <InputA
            type="text"
            inputType="text"
            label="Vergi Dairesi"
            name="vergiDairesi"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={50}
            minLength={3}
          />
          <InputA
            type="text"
            inputType="text"
            label="Vergi Numarası"
            name="vergiNo"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={11}
            minLength={10}
          />
          {ivdMessage && <Alert color="warning">{ivdMessage}</Alert>}
          <Button onClick={() => handleGetDataFromTaxOffice()}>Sorgula</Button>

          <ul>
            <li>Ünvan : {formData.unvan.value}</li>
            <li>Kuruluş Tarihi: {formData.kurulusT.value}</li>
            <li>Mersis No: {formData.mersisNo.value}</li>
            <li>Vergi Daires: {formData.vergiDairesi.value}</li>
            <li>Vergi No: {formData.vergiNo.value}</li>
            <li>Ticaret Sicil no: {formData.sicilNo.value}</li>
            <li>Ticaret Sicil Müdürlüğü: {formData.sicilMudurlugu.value}</li>
            <li>Adres: {formData.adres.value}</li>
          </ul>
        </>
      ) : null}

      {girisTuru === 1 ? (
        <>
          <InputA
            type="text"
            inputType="text"
            label="Ünvan"
            name="unvan"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={500}
            minLength={10}
          />
          <InputA
            type="text"
            inputType="text"
            label="Kuruluş Tarihi"
            name="kurulusT"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={500}
            minLength={10}
          />
          <InputA
            type="text"
            inputType="text"
            label="Mersis No"
            name="mersisNo"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={16}
            minLength={16}
          />
          <InputA
            type="text"
            inputType="text"
            label="Vergi Dairesi"
            name="vergiDairesi"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={50}
            minLength={3}
          />
          <InputA
            type="text"
            inputType="text"
            label="Vergi Numarası"
            name="vergiNo"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={10}
            minLength={10}
          />
          <InputA
            type="text"
            inputType="text"
            label="Ticaret Sicil No"
            name="sicilNo"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={25}
            minLength={5}
          />
          <InputA
            type="text"
            inputType="text"
            label="Ticaret Sicil Müdürlüğü"
            name="sicilMudurlugu"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={100}
            minLength={5}
          />
          <InputA
            type="text"
            inputType="text"
            label="Adres"
            name="adres"
            formValue={formData}
            setFormValue={setFormdata}
            maxLength={500}
            minLength={10}
          />
        </>
      ) : null}
      <InputA
        type="text"
        inputType="text"
        label="Telefon"
        name="telefon"
        formValue={formData}
        setFormValue={setFormdata}
        maxLength={12}
        minLength={10}
      />
      <InputA
        type="email"
        inputType="text"
        label="ePosta"
        name="ePosta"
        formValue={formData}
        setFormValue={setFormdata}
        maxLength={500}
        minLength={5}
      />
      <Button
        onClick={(e) => {
          handleSave(formData);
        }}
      >
        Kaydet
      </Button>

      <Button
        onClick={(e) => {
          handleSendMail();
        }}
      >
        e-Posta Gönder
      </Button>
    </Col>
  );
};

export default CompRegInfo;

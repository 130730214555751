import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Button,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import * as ROUTES from "../../base/routes";
import { AuthContext } from "../../base/auth";
import { nace } from "../../common/nace";
import SearchBar from "../../common/searchBar/searchBar";

const Nace = ({ history, setStep, aplicationData }) => {
  const { userData, currentCompany, base } = React.useContext(AuthContext);
  const [subjectArray, setSubjectArray] = useState([]);

  //const [chosenNace, setChosen] = useState([]);

  const [page, setPage] = useState(0);

  const [addedItems, setAddedItems] = useState([]);

  useEffect(() => {
    if (!userData) return;
    if (!aplicationData?.purposeSubject) return;
    const subject = aplicationData?.purposeSubject;
    setSubjectArray(subject);
    setAddedItems(aplicationData?.nace);
  }, [aplicationData, userData]);

  const handleMain = (obj) => {
    const array = [...addedItems];
    array.map((x) => {
      x.main = false;
      return x;
    });
    const index = array.findIndex((x) => x.kod === obj.kod);
    array[index].main = true;
    setAddedItems(array);
  };

  const handleSave = async () => {
    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { nace: [...addedItems] },
    });

    base.syncStep({ newStep: 12, currentCompany, history });
  };
  const handleNext = async () => {
    base.syncStep({ newStep: 12, currentCompany, history });
  };

  const handleDelete = (naceKodu) => {
    const items = [...addedItems];
    const filtered = items.filter((item) => item.kod !== naceKodu);
    setAddedItems(filtered);
  };

  // searchBar Select
  const handleChangeSearch = (selectedItem) => {
    const items = [...addedItems];
    items.push(selectedItem);
    const unique = [...new Set(items)];
    const shorted = unique.sort((a, b) => a.kod - b.kod);

    setAddedItems(shorted);
  };

  if (page === 1)
    return (
      <NaceMain
        addedItems={addedItems}
        handleMain={handleMain}
        handleSave={handleSave}
        setPage={setPage}
      />
    );

  console.log(addedItems);

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb className="" tag="nav" listTag="div">
          <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Nace Kodu
          </BreadcrumbItem>
        </Breadcrumb>
        {
          <ul>
            {subjectArray.map((sub, index) => (
              <Row>
                <li className=" mx-2" key={index} value={index}>
                  {sub}
                </li>
              </Row>
            ))}
          </ul>
        }

        <Row>
          <Col md={6} className="mx-3">
            <SearchBar
              data={nace}
              property={"tanim"}
              propertyArray={["kod", "tanim"]}
              newRecord={false}
              handleChange={handleChangeSearch}
              label="Nace Tanımı Arayın"
            />
          </Col>
        </Row>

        <Badge color={addedItems.length ? "success" : "danger"}>
          {addedItems.length} nace kodu seçildi
        </Badge>

        <Row>
          <ul className="m-2 list-group">
            {addedItems.map((n, i) => (
              <li key={n["kod"]}>
                <small>
                  {n["kod"]} -{n["tanim"]}
                </small>{" "}
                <Badge color="danger" onClick={() => handleDelete(n["kod"])}>
                  sil
                </Badge>
              </li>
            ))}
          </ul>
        </Row>
        <br />
        <Button color="warning" onClick={() => handleNext()}>
          Bu Adımı Atla (ekibimiz bu aşamada size destek olacak)
        </Button>
        <br />
        <Button
          className="my-3"
          onClick={() => history.push(ROUTES.COMSUBJECT)}
          color="secondary"
        >
          {" "}
          Geri{" "}
        </Button>

        <Button
          className="m-3"
          disabled={addedItems.length === 0}
          onClick={() => setPage(1)}
          color="primary"
        >
          {" "}
          ileri{" "}
        </Button>
      </Col>
    </Row>
  );
};

export default Nace;

const NaceMain = ({ addedItems, handleMain, handleSave, setPage }) => {
  return (
    <>
      <Row>
        <Col>
          <p className="m-2">Ana Faaliyet Kodunuzu Seçiniz</p>

          <ul className="m-2 list-group pointer">
            {addedItems.map((n, i) => (
              <li
                onClick={() =>
                  handleMain({
                    kod: n.kod,
                    tanim: n.tanim,
                  })
                }
                className={
                  n.main
                    ? "list-group-item list-group-item-action  list-group-item-primary py-2"
                    : "list-group-item list-group-item-action py-1"
                }
                key={n.kod}
              >
                <small>
                  {n.kod} -{n.tanim}
                </small>
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row className="m-3">
        <Button className="my-3" onClick={() => setPage(0)} color="secondary">
          {" "}
          Geri{" "}
        </Button>
        <Button
          className="m-3"
          disabled={!addedItems.find((x) => x.main)}
          onClick={() => handleSave()}
          color="primary"
        >
          {" "}
          Kaydet{" "}
        </Button>
      </Row>
    </>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../base/auth";
import {
  Progress,
} from "reactstrap";
import EstablishTask from "./establishTask";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import Documents from './documents';
import * as ROUTES from "../../base/routes"
import CompanyInfo from './companyRegInfo';
import useDbData from '../../common/hooks/useDbData';
import IvdPasswordInfo from '../../common/ivdPassword';
import Loading from '../../common/loading';

const Establish = ({history}) => {
  let { path } = useRouteMatch();
  const { currentCompany ,base,userData} = useContext(AuthContext);
  // const [selectedItem, setSelectedItem] = useState(null);

  const [establishTasks, setEstablishTasks] = useState([]);

  useEffect(() => {
  
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getEstablishTaskData(
        { companyID: userData.currentCompany },
        (data) => {
          if (!data) return
          let array = []
          for (const item in data) {
            if ( item !== "id") array.push(data[item])
          }
          const sort = array.sort((a,b) => a.id - b.id)
          setEstablishTasks(sort);
        }
      );
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, userData.currentCompany]);

  const { files} = useDbData({
    collectionName: "files",
    docID: "",
    query: "",
    listener:true
  });

  const tamamlananGorev =establishTasks.filter(x => {
    return  x.status === "Tamamlandı" || x.status === "Gerekmiyor" 
  }).length

  const toplamGorev = establishTasks.length
  const kalanGorev = toplamGorev - tamamlananGorev

  if(!currentCompany?.companyType) return <Loading />;
  
  return (
    <>
      <h4 className="mt-3">{currentCompany?.companyType} Kuruluş İşlemleri</h4>
      <Progress
        className="my-3"
        value={tamamlananGorev}
        max={toplamGorev}
      />

      {kalanGorev > 0 && <p>{kalanGorev} adım kaldı.</p>}

      <Switch>
        <Route exact path={`${path}`}>
          <EstablishTask establishTasks={establishTasks} files={files}  />
        </Route>
        <Route exact path={ROUTES.DOCUMENTS}>
          <Documents files={files}  />
        </Route>
        <Route exact path={ROUTES.COMPREGINFO}>
          <CompanyInfo  files={files}  />
        </Route>
        <Route exact path={ROUTES.IVDPASSWD}>
          <IvdPasswordInfo history={history} sahis={currentCompany?.companyType.includes("Şahıs")}  />
        </Route>
        
      </Switch>
    </>
  );
};

export default Establish;

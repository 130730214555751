import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../base/auth";

import currency from "currency.js";

import Documents from "./documents";
import { Badge, Col, Row, Alert, Card } from "reactstrap";

import TaskDetail from "./taskDetail";
import ActionIcon from "./actionIcon";
import Loading from "../../common/loading";
import { FaShoppingCart } from "react-icons/fa";
import Acordion from "../../common/acordion";
import IvdPasswordInfo from '../../common/ivdPassword';



const EstablishTask = ({ establishTasks, files }) => {
  const { currentCompany, userData } = useContext(AuthContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isStart, setIsStart] = useState(false);
  const [activeTask, setActiveTask] = useState(null);
  const [savedFiles, setSavedFiles] = useState([]);
  const [redirectIVD, setRedirectIVD] = useState(false);

  useEffect(() => {
    if (currentCompany.status > 15) setIsStart(true);
  }, [currentCompany]);

  useEffect(() => {
    if (!establishTasks?.[0]) return;
    const index = establishTasks.find((x) =>
      x.task.includes("İnteraktif vergi")
    );
    if (index.status === "İşlemde") {
      setRedirectIVD(true);
    } else {
      setRedirectIVD(false);
    }
  }, [establishTasks]);

  console.log(redirectIVD)

  if (!isStart)
    return (
      <>
        {/* <Alert color="warning">
          İşlemlerinizin başladığına dair e-posta alacaksınız. Lütfen önemsiz
          posta klasörünüzü de kontrol edin..{" "}
        </Alert> */}
        <Alert color="warning">
          Bilgileriniz ekibimiz tarafından kontrol ediliyor. Bu aşama biraz
          zaman alabilir. İşlemler başladığında bilgilendirileceksiniz.{" "}
        </Alert>
      </>
    );

  if (!establishTasks) return <Loading />;
  if (redirectIVD) return <IvdPasswordInfo />;

  return (
    <>
      <Row>
        <Col md={8} className="mt-3">
          {establishTasks.map((x, i) => (
            <Acordion
              activeTask={activeTask}
              setActiveTask={setActiveTask}
              cardClassName={
                x.status === "Gerekmiyor"
                  ? "border-light d-none"
                  : "border-light"
              }
              listGroupItemColor={x.color}
              title={
                <>
                  <Badge className="mr-2" color={x.color}>
                    {x.status}
                  </Badge>
                  {x?.category === "market" && (
                    <FaShoppingCart className="mx-2" />
                  )}
                  {x.task}

                  <span className="ml-3">
                    {x?.actions &&
                      x.status !== "Gerekmiyor" &&
                      x.actions.map((item) => {
                        return <ActionIcon icon={item} index={i} />;
                      })}
                  </span>
                </>
              }
              listGroupIndex={i}
              conditions={x.status !== "Gerekmiyor"}
            >
              <TaskDetail
                userData={userData}
                savedFiles={savedFiles}
                setSavedFiles={setSavedFiles}
                files={files}
                establishTasks={establishTasks}
                hiddenDelButton={true}
                item={x}
              />
            </Acordion>
          ))}
        </Col>

        <Col md={4} className="mt-3">
          <Card body>
            <Cost establishTasks={establishTasks} />
          </Card>
          <Card body className={files.length === 0 && "d-none"}>
            <Documents files={files} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EstablishTask;

const Cost = ({ establishTasks }) => {
  const [sum, setSum] = useState(0);

  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });

  useEffect(() => {
    let total = 0;
    establishTasks.forEach((item) => {
      if (item.order && item.cost > 0 && item.status !== "Gerekmiyor") {
        total += item.cost;
      }
      setSum(total);
    });
  });
  return (
    <>
      {" "}
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Tür</th>
            <th scope="col">Tutar</th>
          </tr>
        </thead>
        <tbody>
          {establishTasks.map(
            (x, i) =>
              (x.order === true || x.order === "Evet") &&
              x.status !== "Gerekmiyor" && (
                <tr key={i}>
                  <td className="">{x.costTitle}</td>
                  <td className="text-right">{tl(x.cost).format()}</td>
                </tr>
              )
          )}
          <tr>
            {" "}
            <td className="">Toplam Maliyet (KDV Dahil)</td>
            <td className="text-right">
              <strong>{tl(sum).format()}</strong>
            </td>
          </tr>
        </tbody>
      </table>{" "}
    </>
  );
};

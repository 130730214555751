import React from "react";
import {
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
} from "reactstrap";

import { AuthContext } from "../../../base/auth";

import * as ROUTES from "../../../base/routes";
import InputA from "../../../common/inputA";
import ModalA from "../../../common/modal";

const InvitationEdit = ({ history, selectedItem, margeUserData }) => {
  // sil
  // düzenle
  // hesap sahibi yap

  const { base } = React.useContext(AuthContext);

  const initualFormValue = {
    eMail: { value: selectedItem.toEmail, error: "" },
    displayName: { value: selectedItem.toDisplayName, error: "" },
  };

  const [formData, setFormdata] = React.useState(initualFormValue);

  const [setValidation] = React.useState(false);

  React.useEffect(() => {
    const formdata = Object.values(formData).every(
      (item) => (item.value !== "" && item.error === "") || !item.required
    );
    setValidation(formdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleUpdateToDB = async () => {
    const data = {
      toEmail: formData.eMail.value,
      toDisplayName: formData.displayName.value,
    };
    const ref = base.firestore.collection("invitation").doc(selectedItem.id);

    await base.updateDataToDB({ ref, data });
    margeUserData();
    history.push(ROUTES.USERS);
  };

  const handleDeleteFromDB = async () => {
    const ref = base.firestore.collection("invitation").doc(selectedItem.id);

    await base.deleteDataFromDB({ ref });
    margeUserData();
    history.push(ROUTES.USERS);
  };

  return (
    <>
      <Breadcrumb className="my-3" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem
          className="pointer"
          tag="span"
          onClick={() => history.push(ROUTES.USERS)}
        >
          Kulanıcılar
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Kullanıcı Daveti Düzenle
        </BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Button
          className="m-3"
          disabled={false}
          onClick={() => handleUpdateToDB()}
        >
          Kaydet
        </Button>

        <ModalA
          className="m-3"
          agree={handleDeleteFromDB}
          buttonLabel="Sil"
          buttonContinue="Daveti İptal Et"
          buttonCancel="Vazgeç"
          title="Kullanıcı Sil"
          text={`Dikkat Bu işlem geri alınamaz. Davetiye iptal edilecek ve kullanıcı verileri silinecek. `}
        />
      </Row>

      <Col md="6">
        <Alert color="warning"> Kullanıcı daveti henüz onaylamamış. </Alert>
        <InputA
          type="text"
          inputType="text"
          label="Kullanıcı Adı Soyadı"
          name="displayName"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={11}
          minLength={5}
        />
        <InputA
          type="email"
          inputType="text"
          label="e-Posta"
          name="eMail"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={150}
          minLength={1}
        />
      </Col>
    </>
  );
};

export default InvitationEdit;

import React from "react";
import { Button, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

// import { AuthContext } from "../../../base/auth";

import * as ROUTES from "../../../base/routes";
import InputA from "../../../common/inputA";

const UserEdit = ({
  history,
  selectedItem,
}) => {
  // sil
  // düzenle
  // hesap sahibi yap

  const initualFormValue = {
    eMail: { value: selectedItem.email, error: "" },
    displayName: { value: selectedItem.displayName, error: "" },
  };

  const [formData, setFormdata] = React.useState(initualFormValue);

  const [ validation,setValidation] = React.useState(false);

  React.useEffect(() => {
    const formdata = Object.values(formData).every(
      (item) => (item.value !== "" && item.error === "") || !item.required
    );
    setValidation(formdata);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSaveToDB = async () => {
    console.log("sil");
  };

  return (
    <>
      <Breadcrumb className="my-3" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem
          className="pointer"
          tag="span"
          onClick={() => history.push(ROUTES.USERS)}
        >
          Kulanıcılar
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Kullanıcı Düzenle
        </BreadcrumbItem>
      </Breadcrumb>
      {/* <Button className="m-3" disabled={!validation} onClick={() => handleSaveToDB()}>Kaydet</Button> */}
      <Button
        className="m-3"
        color="danger"
        disabled={
          selectedItem.role === "owner" || selectedItem.role === "admin"
        }
        onClick={() => handleSaveToDB()}
      >
        Sil
      </Button>

      <Col md="6">
        <InputA
          type="text"
          inputType="text"
          label="Kullanıcı Adı Soyadı"
          name="displayName"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={11}
          minLength={5}
          disabled={true}
        />
        <InputA
          type="email"
          inputType="text"
          label="e-Posta"
          name="eMail"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={150}
          minLength={1}
          disabled={true}
        />
      </Col>
    </>
  );
};

export default UserEdit;

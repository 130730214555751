import React, { useState, useEffect } from "react";
import TableHead from "./tableHead";
import TableBody from "./tableBody";
import Loading from "../loading";

const Table = ({
  data,
  sortColumn,
  initualComuns = {},
  className = "table table-hover table-sm",
  // ilk sıralama için key properties ver.
  firstSort = initualComuns[0].key
}) => {
  const [tableData, setTableData] = useState([]);
  const [columns] = useState(initualComuns);
  const [sortColumns, setSortColumns] = useState(sortColumn);

  useEffect(() => {
    if (data.length === 0) return;

    const sortedData = data.sort((a, b) => {
      let first = a?.[firstSort] || "0";
      first = first.toString().toLocaleLowerCase().trim() || 0;
      let second = b?.[firstSort] || "0";
      second = second.toString().toLocaleLowerCase().trim() || 0;
      return first.localeCompare(second);
    });
    setTableData(sortedData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOnSort = (key) => {
    const sort = (direction, key) => {
      const array = [...tableData];

      setTableData(
        array.sort((a, b) => {
          let first = a?.[key] || "0";
          first = first.toString().toLocaleLowerCase().trim() || 0;
          let second = b?.[key] || "0";
          second = second.toString().toLocaleLowerCase().trim() || 0;

          if (direction === "desc") return second.localeCompare(first);
          else return first.localeCompare(second);
        })
      );
    };
    const obj = { ...sortColumns };
    if (obj.path === key) obj.order = obj.order === "asc" ? "desc" : "asc";
    else {
      obj.path = key;
      obj.order = "asc";
    }
    sort(obj.order, key);
    setSortColumns(obj);
  };

  if (!data.length) return <Loading />;
  return (
    <table className={className}>
      <TableHead
        columns={columns}
        sortColumns={sortColumns}
        handleOnSort={handleOnSort}
      />
      <TableBody columns={columns} tableData={tableData} />
    </table>
  );
};

export default Table;

import React from "react";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loading from "./loading";

const ModalAlert = ({ title, text, toggle, modal, setModal }) => {
  //const [modal, setModal] = useState(false);

  // const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="text-center" toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="text-center">
          {text}
          <Loading />
        </ModalBody>

        <ModalFooter>yenisirketkur.com</ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAlert;

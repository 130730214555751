import React, { useState,  useContext } from "react";

import ModalAlert from "../../common/modalAlert";
import useDbData from "../../common/hooks/useDbData";
import { AuthContext } from "../../base/auth";
import Loading from "../../common/loading";
import { sortAZ } from "../../common/functions";

const filterQuery = [
  {
    property: `status`,
    operator: "<",
    value: 20,
  },
];


const CompanyList = ({toggle}) => {
  const [modal, setModal] = useState(false);

  const { base, userData, superAdmin } = useContext(AuthContext);


  const { companies } = useDbData({
    collectionName: "companies",
    docID: "",
    //query: filter ? filterQuery : null,
    queryArray: superAdmin && filterQuery,
    listener: false,
  });

  const handleChangeCurrentCompany = async ({ userData, id }) => {
    setModal(true);
    await base.changeCurrentComp({ companyID: id });
    setModal(false);
    toggle()
  };
  if (!companies || companies.length === 0) return <Loading />;

  const filteredComp = companies.filter(x => x.id !== userData.currentCompany)

  const sortedList = sortAZ(filteredComp, "companyName");

  return (
    <div className="row  overflow-auto shadow-sm " style={{ height: "200px" }}>
      <ModalAlert
        setModal={setModal}
        modal={modal}
        //title={"Firma değiştiriliyor"}
        text={"Firma değiştiriliyor. Lütfen bekleyin"}
      />
      <div className="col">
      <small>  <strong>Firma değiştir</strong></small>
        {sortedList.map((comp, key) => {
          return (
            <small>
              <p
                key={key}
                className="hoverBg pointer mb-2"
                onClick={() =>
                  handleChangeCurrentCompany({ userData, id: comp.id })
                }
              >
                {comp.companyName || comp.createdUser}
              </p>
            </small>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyList;

import React from "react";

import { Row, Col, Button } from "reactstrap";



const Partnership = ({ handleChange, nextPage }) => {

  const nextStep = async (num) => {
    handleChange(num);
    nextPage()
    // await base.syncStep({ newStep: 2, currentCompany, history });
  };

  return (
    <>
      <Row className="text-center mt-5">
        <Col>
          <h3>Firma Ortaklık Yapısını Belirleme</h3>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <p>Firmanızda sizinle birlikte kaç ortak olacak?</p>
        </Col>
      </Row>
      <div className="text-center mt-5 mx-auto">
 
          <Button
            color="secondary"
            className="mx-2 my-1"
            onClick={() => nextStep(1)}
          >
            {" "}
            Yanlızca Bir kişi (Sadece Ben){" "}
          </Button>

 
          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(2)}
          >
            2- 50 Ortak
          </Button>

 
          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(3)}
          >
            51 ortak ve daha fazla
          </Button>

      </div>
    </>
  );
};

export default Partnership;

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalA = ({
  buttonLabel,
  buttonColor = "danger",
  title,
  text,
  agree,
  className,
  buttonCancel,
  buttonContinue,
  selectedItem,
  disabled=false
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleContinue = () => {
      console.log(selectedItem)
    agree(selectedItem);
    toggle();
  };

  const handleCancel = () => {
    toggle();
  };

  return (
    <div>
      <Button  disabled={disabled} color={buttonColor} onClick={toggle} className={className}>
        {buttonLabel}{" "}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleContinue()}>
            {buttonContinue}
          </Button>{" "}
          <Button color="secondary" onClick={() => handleCancel()}>
            {buttonCancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalA;

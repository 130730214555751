export const capSentence = (text) => {
    let wordsArray = text.toLowerCase().split(" ");
    let capsArray = [];

    wordsArray.forEach((word) => {
        capsArray.push(word[0].toUpperCase() + word.slice(1));
    });

    return capsArray.join(" ");
};

export const sortAZ = (array, property) => {
    const result = array.sort((a, b) => {
      let first = a?.[property] || "0";
      first = first.toString().toLocaleLowerCase().trim() || 0;
      let second = b?.[property] || "0";
      second = second.toString().toLocaleLowerCase().trim() || 0;
      return first.localeCompare(second);
    });
    return result
  };
import React from "react";
import { Label, Input, FormFeedback, Col, FormText } from "reactstrap";

const CheckBox = ({ name, label, error, helpMessage, ...rest }) => {
  return (
    <Col >
      <Label check className="ml-4" for={name}>
     
     
      <Input type="checkbox"   {...rest} name={name} id={name} invalid={error} />
      {label}
      </Label>
      {helpMessage && <FormText>{helpMessage}</FormText>}
      {error && <FormFeedback tooltip> {error}</FormFeedback>}
    </Col>
  );
};

export default CheckBox;


  
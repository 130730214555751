export const nace = [
    {
        "kod": "001000",
        "tanim": "POTANSİYEL MÜKELLEF (İTHALAT)"
    },
    {
        "kod": "002100",
        "tanim": "POTANSİYEL MÜKELLEF (YÖNETİM KURULU BAŞKANI)"
    },
    {
        "kod": "002200",
        "tanim": "POTANSİYEL MÜKELLEF (YÖNETİM KURULU ÜYESİ)"
    },
    {
        "kod": "002300",
        "tanim": "POTANSİYEL MÜKELLEF (GENEL MÜDÜR,ŞİRKET MÜDÜRÜ, TEMSİLCİ)"
    },
    {
        "kod": "002400",
        "tanim": "POTANSİYEL MÜKELLEF (ŞİRKET ORTAĞI)"
    },
    {
        "kod": "002500",
        "tanim": "POTANSİYEL MÜKELLEF (YENİ KURULACAK SERMAYE ŞİRKETLERİ)"
    },
    {
        "kod": "003100",
        "tanim": "POTANSİYEL MÜKELLEF(TAŞIT ALAN)"
    },
    {
        "kod": "003200",
        "tanim": "POTANSİYEL MÜKELLEF (TAŞIT SATAN)"
    },
    {
        "kod": "004100",
        "tanim": "POTANSİYEL MÜKELLEF (GAYRİMENKUL ALAN)"
    },
    {
        "kod": "004200",
        "tanim": "POTANSİYEL MÜKELLEF(GAYRİMENKUL SATAN)"
    },
    {
        "kod": "004300",
        "tanim": "POTANSİYEL MÜKELLEF (BİNA İNŞA ETTİRİP TAPUYA TESCİL ETTİREN)"
    },
    {
        "kod": "004400",
        "tanim": "POTANSİYEL MÜKELLEF (LEHİNE İPOTEK TESCİL ETTİREN )"
    },
    {
        "kod": "009000",
        "tanim": "POTANSİYEL MÜKELLEF (DİĞER GRUPLARA GİRMEYENLER)"
    },
    {
        "kod": "011107",
        "tanim": "BAKLAGİLLERİN YETİŞTİRİLMESİ (FASULYE (TAZE VE KURU), BAKLA, NOHUT, MERCİMEK, ACI BAKLA, BEZELYE, ARAKA VB.)"
    },
    {
        "kod": "011112",
        "tanim": "TAHIL YETİŞTİRİCİLİĞİ (BUĞDAY, DANE MISIR, SÜPÜRGE DARISI, ARPA, ÇAVDAR, YULAF, DARI, KUŞ YEMİ VB.) (PİRİNÇ HARİÇ)"
    },
    {
        "kod": "011114",
        "tanim": "YAĞLI TOHUM YETİŞTİRİCİLİĞİ [SOYA FASULYESİ, YER FISTIĞI, PAMUK ÇEKİRDEĞİ, KENE OTU ÇEKİRDEĞİ (HİNT YAĞI ÇEKİRDEĞİ), KETEN TOHUMU, HARDAL TOHUMU, NİJER TOHUMU, KOLZA, ASPİR TOHUMU, SUSAM TOHUMU, AYÇİÇEĞİ TOHUMU VB.]"
    },
    {
        "kod": "011214",
        "tanim": "ÇELTİK (KABUKLU PİRİNÇ) YETİŞTİRİLMESİ"
    },
    {
        "kod": "011317",
        "tanim": "ŞEKER PANCARI YETİŞTİRİLMESİ"
    },
    {
        "kod": "011318",
        "tanim": "YENİLEBİLİR KÖK VE YUMRULARIN YETİŞTİRİCİLİĞİ (PATATES, TATLI PATATES, MANYOK, HİNT YER ELMASI, VB.)"
    },
    {
        "kod": "011319",
        "tanim": "DİĞER SEBZE TOHUMLARININ YETİŞTİRİCİLİĞİ (ŞEKER PANCARI TOHUMU DAHİL, DİĞER PANCAR TOHUMLARI HARİÇ)"
    },
    {
        "kod": "011320",
        "tanim": "MEYVESİ YENEN SEBZELERİN YETİŞTİRİLMESİ (HIYAR, KORNİŞON, SİVRİ VE DOLMALIK BİBER, KAVUN, KARPUZ, KABAKGİL TÜRLERİ, DOMATES, BİBER, PATLICAN VB.)"
    },
    {
        "kod": "011321",
        "tanim": "MANTAR VE YER MANTARLARI (DOMALAN) YETİŞTİRİLMESİ"
    },
    {
        "kod": "011322",
        "tanim": "KÖKLERİ, SOĞANLARI, YUMRULARI TÜKETİLEN SEBZELERİN YETİŞTİRİLMESİ (HAVUÇ, ŞALGAM, SARIMSAK, SOĞAN, ARPACIK SOĞAN, PIRASA VE DİĞER BENZER SEBZELER)"
    },
    {
        "kod": "011323",
        "tanim": "YAPRAKLI VEYA SAPLI SEBZELERİN YETİŞTİRİLMESİ (ENGİNAR, KUŞKONMAZ, LAHANA, KARNABAHAR VE BROKOLİ, MARUL VE HİNDİBA, ISPANAK VB.)"
    },
    {
        "kod": "011401",
        "tanim": "ŞEKER KAMIŞI YETİŞTİRİLMESİ"
    },
    {
        "kod": "011501",
        "tanim": "TÜTÜN YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "011602",
        "tanim": "PAMUK YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "011690",
        "tanim": "DİĞER LİFLİ BİTKİLERİN YETİŞTİRİLMESİ (KETEN, KENEVİR, JÜT VS.)"
    },
    {
        "kod": "011901",
        "tanim": "HAYVAN YEMİ BİTKİLERİNİN YETİŞTİRİCİLİĞİ (SARI ŞALGAM, MANGOLDLAR, YEMLİK KÖKLERİ, YONCA, KORUNGA, YEMLİK MISIR VE DİĞER OTLAR İLE BUNLARIN TOHUMLARI VE PANCAR TOHUMLARI DAHİL, ŞEKER PANCARI TOHUMLARI HARİÇ)"
    },
    {
        "kod": "011902",
        "tanim": "ÇİÇEK YETİŞTİRİCİLİĞİ (LALE, KASIMPATI, ZAMBAK, GÜL VB. İLE BUNLARIN TOHUMLARI)"
    },
    {
        "kod": "011990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ TEK YILLIK DİĞER BİTKİSEL ÜRÜNLERİN YETİŞTİRİLMESİ"
    },
    {
        "kod": "012105",
        "tanim": "ÜZÜM YETİŞTİRİCİLİĞİ (ŞARAPLIK, SOFRALIK VE DİĞER ÜZÜMLER)"
    },
    {
        "kod": "012205",
        "tanim": "TROPİKAL VE SUBTROPİKAL MEYVELERİN YETİŞTİRİCİLİĞİ (MUZ, HURMA, İNCİR, AVOKADO, MANGO VB.)"
    },
    {
        "kod": "012302",
        "tanim": "TURUNÇGİLLERİN YETİŞTİRİLMESİ (GREYFURT, LİMON, MİSKET LİMONU, PORTAKAL, MANDALİNA VB.)"
    },
    {
        "kod": "012404",
        "tanim": "YUMUŞAK VEYA SERT ÇEKİRDEKLİ MEYVELERİN YETİŞTİRİLMESİ (ELMA, KAYISI, KİRAZ, AYVA, ERİK VB.) (TURUNÇGİLLER VE ÜZÜM HARİÇ)"
    },
    {
        "kod": "012508",
        "tanim": "DİĞER AĞAÇ VE ÇALI (ÇOK YILLIK BİTKİLERİN) MEYVELERİNİN VE SERT KABUKLU MEYVELERİN (YABAN MERSİNİ, KUŞ ÜZÜMÜ, KESTANE, FISTIK, ÇİLEK, AHUDUDU, CEVİZ, KEÇİBOYNUZU VB. (FINDIK HARİÇ)) YETİŞTİRİLMESİ"
    },
    {
        "kod": "012509",
        "tanim": "FINDIK YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "012602",
        "tanim": "ZEYTİN YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "012690",
        "tanim": "DİĞER YAĞLI MEYVELERİN YETİŞTİRİCİLİĞİ (HİNDİSTAN CEVİZİ, HURMA PALMİYELERİ VB.) (ZEYTİN HARİÇ)"
    },
    {
        "kod": "012702",
        "tanim": "ÇAY YETİŞTİRİCİLİĞİ (SİYAH ÇAY, YEŞİL ÇAY, PARAGUAY ÇAYI VB.)"
    },
    {
        "kod": "012790",
        "tanim": "İÇECEK ÜRETİMİNDE KULLANILAN DİĞER BİTKİSEL ÜRÜNLERİN YETİŞTİRİCİLİĞİ (KAHVE, KAKAO, VB.) (ÇAY YETİŞTİRİCİLİĞİ HARİÇ)"
    },
    {
        "kod": "012801",
        "tanim": "BAHARATLIK, AROMATİK (ITIRLI), UYUŞTURUCU NİTELİKTE VE ECZACILIKLA İLGİLİ BİTKİSEL ÜRÜNLERİN (ANASON, MUSKAT, TARÇIN, KARANFİL, ZENCEFİL, VANİLYA, BEYAZ VEYA KARA BİBER, IHLAMUR, ADAÇAYI VB.) YETİŞTİRİLMESİ"
    },
    {
        "kod": "012901",
        "tanim": "KAUÇUK AĞACI, YILBAŞI AĞACI, ÖRGÜ, DOLGU VE TABAKLAMA YAPMAK İÇİN KULLANILAN BİTKİSEL ÜRÜNLER VB. UZUN ÖMÜRLÜ BİTKİSEL ÜRÜNLERİN YETİŞTİRİLMESİ"
    },
    {
        "kod": "013003",
        "tanim": "DİKİM İÇİN SEBZE FİDESİ, MEYVE FİDANI VB. YETİŞTİRİLMESİ"
    },
    {
        "kod": "013004",
        "tanim": "DİKİM İÇİN ÇİÇEK VE DİĞER BİTKİLERİN YETİŞTİRİLMESİ (DEKORATİF AMAÇLARLA BİTKİ VE ÇİM YETİŞTİRİLMESİ DAHİL, SEBZE FİDESİ, MEYVE FİDANI HARİÇ)"
    },
    {
        "kod": "014131",
        "tanim": "SÜTÜ SAĞILAN BÜYÜK BAŞ HAYVAN YETİŞTİRİCİLİĞİ (SÜTÜ İÇİN İNEK VE MANDA YETİŞTİRİCİLİĞİ)"
    },
    {
        "kod": "014209",
        "tanim": "DİĞER SIĞIR VE MANDA YETİŞTİRİCİLİĞİ (SÜTÜ İÇİN YETİŞTİRİLENLER HARİÇ)"
    },
    {
        "kod": "014301",
        "tanim": "AT VE AT BENZERİ DİĞER HAYVAN YETİŞTİRİCİLİĞİ (EŞEK, KATIR VEYA BARDO VB.)"
    },
    {
        "kod": "014401",
        "tanim": "DEVE YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "014501",
        "tanim": "KOYUN VE KEÇİ (DAVAR) YETİŞTİRİCİLİĞİ (İŞLENMEMİŞ SÜT, KIL, TİFTİK, YAPAĞI, YÜN VB. ÜRETİMİ DAHİL)"
    },
    {
        "kod": "014601",
        "tanim": "DOMUZ YETİŞTİRİCİLİĞİ"
    },
    {
        "kod": "014701",
        "tanim": "KÜMES HAYVANLARININ YETİŞTİRİLMESİ (TAVUK, HİNDİ, ÖRDEK, KAZ VE BEÇ TAVUĞU VB.)"
    },
    {
        "kod": "014702",
        "tanim": "KULUÇKAHANELERİN FAALİYETLERİ"
    },
    {
        "kod": "014703",
        "tanim": "KÜMES HAYVANLARINDAN YUMURTA ÜRETİLMESİ"
    },
    {
        "kod": "014901",
        "tanim": "ARICILIK, BAL VE BAL MUMU ÜRETİLMESİ (ARI SÜTÜ DAHİL)"
    },
    {
        "kod": "014902",
        "tanim": "İPEKBÖCEĞİ YETİŞTİRİCİLİĞİ VE KOZA ÜRETİMİ"
    },
    {
        "kod": "014903",
        "tanim": "EVCİL HAYVANLARIN YETİŞTİRİLMESİ VE ÜRETİLMESİ (BALIK HARİÇ) (KEDİ, KÖPEK, KUŞLAR, HAMSTERLER VB.)"
    },
    {
        "kod": "014905",
        "tanim": "DEVE KUŞLARININ YETİŞTİRİLMESİ"
    },
    {
        "kod": "014990",
        "tanim": "YARI EVCİLLEŞTİRİLMİŞ VEYA DİĞER CANLI HAYVANLARIN YETİŞTİRİLMESİ VE ÜRETİLMESİ (DİĞER KUŞLAR (KÜMES HAYVANLARI HARİÇ), BÖCEKLER, TAVŞANLAR VE DİĞER KÜRK HAYVANLARI, SALYANGOZ, SOLUCAN ÇİFTLİKLERİ, SÜRÜNGEN ÇİFTLİKLERİ, HAYVAN EMBRİYOSU VB.)"
    },
    {
        "kod": "015006",
        "tanim": "KARMA ÇİFTÇİLİK (BİTKİSEL VEYA HAYVANSAL ÜRETİM KONUSUNDA UZMANLAŞMA OLMAKSIZIN ÜRETİM)"
    },
    {
        "kod": "016101",
        "tanim": "BİTKİSEL ÜRETİMİ DESTEKLEYİCİ GÜBRELEME, TARLANIN SÜRÜLMESİ, EKİLMESİ, ÇAPALAMA İLE MEYVECİLİKLE İLGİLİ BUDAMA VB. FAALİYETLER (ÇİÇEK YETİŞTİRİCİLİĞİNİ DESTEKLEYİCİ FAALİYETLER İLE HAVA YOLUYLA YAPILAN GÜBRELEME HARİÇ)"
    },
    {
        "kod": "016102",
        "tanim": "BİTKİSEL ÜRETİMİ DESTEKLEYİCİ MAHSULÜN HASAT VE HARMANLANMASI, BİÇİLMESİ, BALYALANMASI, BİÇERDÖVER İŞLETİLMESİ VB. FAALİYETLER"
    },
    {
        "kod": "016103",
        "tanim": "BİTKİSEL ÜRETİMİ DESTEKLEYİCİ TARIMSAL AMAÇLI SULAMA FAALİYETLERİ"
    },
    {
        "kod": "016104",
        "tanim": "BİTKİSEL ÜRETİMİ DESTEKLEYİCİ İLAÇLAMA VE ZİRAİ MÜCADELE FAALİYETLERİ (ZARARLI OTLARIN İMHASI DAHİL, HAVA YOLUYLA YAPILANLAR HARİÇ)"
    },
    {
        "kod": "016105",
        "tanim": "ÇİÇEK YETİŞTİRİCİLİĞİNİ DESTEKLEYİCİ GÜBRELEME, TARLANIN SÜRÜLMESİ, EKİLMESİ, BAKIMI, TOPLAMA VB. İLE İLGİLİ FAALİYETLER (HAVA YOLUYLA YAPILAN GÜBRELEME HARİÇ)"
    },
    {
        "kod": "016106",
        "tanim": "HAVA YOLUYLA YAPILAN BİTKİSEL ÜRETİMİ DESTEKLEYİCİ GÜBRELEME, İLAÇLAMA VE ZİRAİ MÜCADELE FAALİYETLERİ (ZARARLI OTLARIN İMHASI DAHİL)"
    },
    {
        "kod": "016201",
        "tanim": "HAYVAN ÜRETİMİNİ DESTEKLEYİCİ OLARAK SÜRÜLERİN GÜDÜLMESİ, BAŞKALARINA AİT HAYVANLARIN BESLENMESİ, KÜMESLERİN TEMİZLENMESİ, KIRKMA, SAĞMA, BARINAK SAĞLAMA, NALBANTLIK VB. FAALİYETLER"
    },
    {
        "kod": "016202",
        "tanim": "HAYVAN ÜRETİMİNİ DESTEKLEYİCİ OLARAK SÜRÜ TESTİ, KÜMES HAYVANLARININ KISIRLAŞTIRILMASI, YAPAY DÖLLEME, VB. FAALİYETLER (KULUÇKAHANELERDEKİ FAALİYETLER DAHİL)"
    },
    {
        "kod": "016301",
        "tanim": "HASAT SONRASI DİĞER ÜRÜNLERİN AYIKLANMASI VE TEMİZLENMESİ İLE İLGİLİ FAALİYETLER (PAMUĞUN ÇIRÇIRLANMASI VE NİŞASTALI KÖK ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "016302",
        "tanim": "SERT KABUKLU ÜRÜNLERİN KABUKLARININ KIRILMASI VE TEMİZLENMESİ İLE İLGİLİ FAALİYETLER"
    },
    {
        "kod": "016303",
        "tanim": "HAŞHAŞ VB. ÜRÜNLERİN SÜRTME, EZME VE TEMİZLENMESİ İLE İLGİLİ FAALİYETLER"
    },
    {
        "kod": "016304",
        "tanim": "MISIR VB. ÜRÜNLERİN TANELENMESİ VE TEMİZLENMESİ İLE İLGİLİ FAALİYETLER"
    },
    {
        "kod": "016305",
        "tanim": "TÜTÜNÜN SINIFLANDIRILMASI, BALYALANMASI VB. HİZMETLER"
    },
    {
        "kod": "016306",
        "tanim": "NİŞASTALI KÖK ÜRÜNLERİNİN AYIKLANMASI VE TEMİZLENMESİ (PATATES VB.)"
    },
    {
        "kod": "016307",
        "tanim": "ÇIRÇIRLAMA FAALİYETİ"
    },
    {
        "kod": "016390",
        "tanim": "HASAT SONRASI BİTKİSEL ÜRÜNLER İLE İLGİLİ DİĞER FAALİYETLER"
    },
    {
        "kod": "016401",
        "tanim": "ÜRETİM AMAÇLI TOHUM İŞLEME HİZMETLERİ (VERNELİZASYON İŞLEMLERİ DAHİL)"
    },
    {
        "kod": "017001",
        "tanim": "TİCARİ OLMAYAN AV HAYVANI VE YABANİ HAYVAN AVLAMA VE YAKALAMA FAALİYETLERİ (YENİLMESİ, KÜRKLERİ, DERİLERİ, ARAŞTIRMALARDA KULLANILMALARI VB. AMAÇLAR İÇİN) (BALIKÇILIK HARİÇ)"
    },
    {
        "kod": "017002",
        "tanim": "TİCARİ OLAN AV HAYVANI VE YABANİ HAYVAN AVLAMA VE YAKALAMA FAALİYETLERİ (YENİLMESİ, KÜRKLERİ, DERİLERİ, ARAŞTIRMALARDA KULLANILMALARI VB. AMAÇLAR İÇİN) (BALIKÇILIK HARİÇ)"
    },
    {
        "kod": "021001",
        "tanim": "BALTALIK OLARAK İŞLETİLEN ORMANLARIN YETİŞTİRİLMESİ (KAĞITLIK VE YAKACAK ODUN ÜRETİMİNE YÖNELİK OLANLAR DAHİL)"
    },
    {
        "kod": "021002",
        "tanim": "ORMAN YETİŞTİRMEK İÇİN FİDAN VE TOHUM ÜRETİMİ"
    },
    {
        "kod": "021003",
        "tanim": "ORMAN AĞAÇLARININ YETİŞTİRİLMESİ (BALTALIK ORMANLARIN YETİŞTİRİLMESİ HARİÇ)"
    },
    {
        "kod": "022001",
        "tanim": "ENDÜSTRİYEL VE YAKACAK ODUN ÜRETİMİ (GELENEKSEL YÖNTEMLERLE ODUN KÖMÜRÜ ÜRETİMİ DAHİL)"
    },
    {
        "kod": "023001",
        "tanim": "AĞAÇ DIŞINDAKİ YABANİ OLARAK YETİŞEN ÜRÜNLERİNİN TOPLANMASI (MANTAR MEŞESİNİN KABUĞU, KÖK, KOZALAK, BALSAM, LAK VE REÇİNE, MEŞE PALAMUDU, AT KESTANESİ, YOSUN VE LİKENLER, YABANİ ÇİÇEK, YABANİ MEYVE, YENİLEBİLİR MANTAR VB.)"
    },
    {
        "kod": "024001",
        "tanim": "ORMANDA AĞAÇLARIN KESİLMESİ, DALLARINDAN TEMİZLENMESİ, SOYULMASI VB. DESTEKLEYİCİ FAALİYETLER"
    },
    {
        "kod": "024002",
        "tanim": "ORMANDA KESİLMİŞ VE TEMİZLENMİŞ AĞAÇLARIN TAŞINMASI, İSTİFLENMESİ VE YÜKLENMESİ FAALİYETLERİ"
    },
    {
        "kod": "024003",
        "tanim": "ORMANDA SİLVİKÜLTÜREL HİZMET FAALİYETLERİ (SEYRELTİLMESİ, BUDANMASI, REPİKAJ VB.)"
    },
    {
        "kod": "024004",
        "tanim": "ORMANI ZARARLILARA (BÖCEK VE HASTALIKLAR) KARŞI KORUMA FAALİYETLERİ"
    },
    {
        "kod": "024005",
        "tanim": "ORMANI YANGIN VE KAÇAK KESİME (İZİNSİZ KESİM) KARŞI KORUMA FAALİYETLERİ"
    },
    {
        "kod": "024006",
        "tanim": "ORMANI KORUMA VE BAKIMI AMAÇLI ORMAN YOLU YAPIMI VE BAKIMI FAALİYETLERİ"
    },
    {
        "kod": "024007",
        "tanim": "DİĞER ORMANCILIK HİZMET FAALİYETLERİ (ORMANCILIK ENVANTERLERİ, ORMAN İŞLETMESİ, ORMAN İDARESİ DANIŞMANLIK HİZMETLERİ, ORMAN (BAKIMI, VERİMİ, VB.) İLE İLGİLİ ARAŞTIRMA GELİŞTİRME, VB.)"
    },
    {
        "kod": "031101",
        "tanim": "DENİZ VE KIYI SULARINDA YAPILAN BALIKÇILIK (GIRGIR BALIKÇILIĞI, DALYANCILIK DAHİL)"
    },
    {
        "kod": "031102",
        "tanim": "DENİZ KABUKLULARININ (MİDYE, ISTAKOZ VB.), YUMUŞAKÇALARIN, DİĞER DENİZ CANLILARI VE ÜRÜNLERİNİN TOPLANMASI (SEDEF, DOĞAL İNCİ, SÜNGER, MERCAN, DENİZ YOSUNU, VB.)"
    },
    {
        "kod": "031201",
        "tanim": "TATLI SULARDA (IRMAK,GÖL) YAPILAN BALIKÇILIK (ALABALIK, SAZAN, YAYIN VB.)"
    },
    {
        "kod": "032101",
        "tanim": "DENİZDE YAPILAN BALIK YETİŞTİRİCİLİĞİ (ÇİPURA, KARAGÖZ, KEFAL VB. YETİŞTİRİCİLİĞİ İLE KÜLTÜR BALIĞI, BALIK YUMURTASI VE YAVRUSU DAHİL)"
    },
    {
        "kod": "032102",
        "tanim": "DENİZDE YAPILAN DİĞER SU ÜRÜNLERİ YETİŞTİRİCİLİĞİ (MİDYE, İSTİRİDYE, ISTAKOZ, KARİDES, EKLEMBACAKLILAR, KABUKLULAR, DENİZ YOSUNLARI VB.) (BALIK HARİÇ)"
    },
    {
        "kod": "032201",
        "tanim": "TATLI SULARDA YAPILAN BALIK YETİŞTİRİCİLİĞİ (SÜS BALIĞI, KÜLTÜR BALIĞI, BALIK YUMURTASI VE YAVRUSU DAHİL)"
    },
    {
        "kod": "032202",
        "tanim": "TATLISU ÜRÜNLERİ YETİŞTİRİCİLİĞİ (YUMUŞAKÇALAR, KABUKLULAR, KURBAĞALAR VB.) (BALIK HARİÇ)"
    },
    {
        "kod": "051001",
        "tanim": "TAŞ KÖMÜRÜ MADENCİLİĞİ"
    },
    {
        "kod": "052001",
        "tanim": "LİNYİT MADENCİLİĞİ"
    },
    {
        "kod": "061001",
        "tanim": "HAM PETROLÜN ÇIKARILMASI"
    },
    {
        "kod": "062001",
        "tanim": "DOĞALGAZ ÇIKARILMASI (MADENCİLİĞİ)"
    },
    {
        "kod": "071001",
        "tanim": "DEMİR CEVHERİ MADENCİLİĞİ (SİNTERLENMİŞ DEMİR CEVHERİ ÜRETİMİ DAHİL)"
    },
    {
        "kod": "072101",
        "tanim": "KATRAN VE ZİFT İHTİVA EDEN CEVHERLERDEN URANYUM METALİNİN AYRIŞTIRILMASI"
    },
    {
        "kod": "072102",
        "tanim": "KATRAN VE ZİFT İHTİVA EDEN CEVHERLERDEN TORYUM METALİNİN AYRIŞTIRILMASI"
    },
    {
        "kod": "072103",
        "tanim": "URANYUM MADENCİLİĞİ"
    },
    {
        "kod": "072104",
        "tanim": "TORYUM MADENCİLİĞİ"
    },
    {
        "kod": "072105",
        "tanim": "SARI PASTA (U3O8) İMALATI (URANYUM CEVHERİNDEN ELDE EDİLEN)"
    },
    {
        "kod": "072901",
        "tanim": "ALTIN, GÜMÜŞ, PLATİN GİBİ DEĞERLİ METAL MADENCİLİĞİ"
    },
    {
        "kod": "072902",
        "tanim": "ALÜMİNYUM MADENCİLİĞİ"
    },
    {
        "kod": "072903",
        "tanim": "BAKIR MADENCİLİĞİ"
    },
    {
        "kod": "072904",
        "tanim": "NİKEL MADENCİLİĞİ"
    },
    {
        "kod": "072905",
        "tanim": "KURŞUN, ÇİNKO VE KALAY MADENCİLİĞİ"
    },
    {
        "kod": "072906",
        "tanim": "KROM MADENCİLİĞİ"
    },
    {
        "kod": "072907",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DEMİR DIŞI DİĞER METAL CEVHERLERİ MADENCİLİĞİ (CIVA, MANGANEZ, KOBALT, MOLİBDEN, TANTAL, VANADYUM VB.) (DEĞERLİ METALLER, DEMİR, BAKIR, KURŞUN, ÇİNKO, ALÜMİNYUM, KALAY, KROM, NİKEL HARİÇ)"
    },
    {
        "kod": "081101",
        "tanim": "MERMER OCAKÇILIĞI (TRAVERTEN DAHİL)"
    },
    {
        "kod": "081102",
        "tanim": "GRANİT OCAKÇILIĞI"
    },
    {
        "kod": "081103",
        "tanim": "YAPI TAŞLARI OCAKÇILIĞI"
    },
    {
        "kod": "081104",
        "tanim": "SÜSLEME VE YAPI TAŞLARININ KIRILMASI VE KABACA KESİLMESİ"
    },
    {
        "kod": "081105",
        "tanim": "DOLOMİT VE KAYAĞAN TAŞI (ARDUVAZ / KAYRAKTAŞI) OCAKÇILIĞI"
    },
    {
        "kod": "081106",
        "tanim": "KİREÇTAŞI (KALKER) OCAKÇILIĞI (KİREÇTAŞININ KIRILMASI VE PARÇALANMASI DAHİL)"
    },
    {
        "kod": "081107",
        "tanim": "TEBEŞİR, ALÇITAŞI VE ANHİDRİT OCAKÇILIĞI (ÇIKARMA, PARÇALAMA, PİŞİRME İŞLEMİ DAHİL)"
    },
    {
        "kod": "081201",
        "tanim": "ÇAKIL VE KUM OCAKÇILIĞI (TAŞLARIN KIRILMASI İLE KİL VE KAOLİN MADENCİLİĞİ HARİÇ)"
    },
    {
        "kod": "081202",
        "tanim": "ÇAKIL TAŞLARININ KIRILMASI VE PARÇALANMASI"
    },
    {
        "kod": "081203",
        "tanim": "KİL, REFRAKTER KİL VE KAOLİN MADENCİLİĞİ İLE BENTONİT, ANDALUZİT, SİYANİT, SİLİMANİT, MULİT, ŞAMOT VEYA DİNAS TOPRAKLARI ÇIKARIMI"
    },
    {
        "kod": "089101",
        "tanim": "KİMYASAL VE GÜBRELEME AMAÇLI MİNERAL MADENCİLİĞİ (AZOT, POTASYUM, FOSFOR, FOSFAT, NİTRAT, BARİT, BARYUM, PİRİT, VB.) (BOR, KÜKÜRT MADENCİLİĞİ HARİÇ)"
    },
    {
        "kod": "089102",
        "tanim": "BOR MİNERALLERİ MADENCİLİĞİ"
    },
    {
        "kod": "089103",
        "tanim": "KÜKÜRT MADENCİLİĞİ (OCAKÇILIĞI)"
    },
    {
        "kod": "089104",
        "tanim": "GUANO MADENCİLİĞİ (KUŞ GÜBRESİ, GÜHERÇİLE DAHİL)"
    },
    {
        "kod": "089105",
        "tanim": "KEHRİBAR, OLTU TAŞI VE LÜLETAŞI OCAKÇILIĞI"
    },
    {
        "kod": "089201",
        "tanim": "TURBA ÇIKARILMASI VE TOPLANMASI"
    },
    {
        "kod": "089301",
        "tanim": "TUZ OCAKÇILIĞI (DENİZ, GÖL, KAYA, KAYNAK), TUZUN ELENMESİ VE KIRILMASI DAHİL"
    },
    {
        "kod": "089901",
        "tanim": "AŞINDIRICI (TÖRPÜLEYİCİ) MATERYALLER (ZIMPARA), AMYANT, SİLİSLİ FOSİL ARTIKLAR, ARSENİK CEVHERLERİ, SABUNTAŞI (TALK) VE FELDİSPAT MADENCİLİĞİ (KUARTZ, MİKA, ŞİST, TALK, SİLİS, SÜNGER TAŞI, ASBEST, DOĞAL KORİNDON VB.)"
    },
    {
        "kod": "089902",
        "tanim": "DOĞAL ASFALT, ASFALTİT, ASFALTLI TAŞ (DOĞAL KATI ZİFT) VE BİTÜM MADENCİLİĞİ"
    },
    {
        "kod": "089903",
        "tanim": "KIYMETLİ VE YARI KIYMETLİ TAŞLARIN (YAKUT, ZÜMRÜT, SAFİR, KALSEDON VB.) OCAKÇILIĞI (KEHRİBAR, OLTU TAŞI, LÜLE TAŞI VE ELMAS HARİÇ)"
    },
    {
        "kod": "089904",
        "tanim": "GRAFİT OCAKÇILIĞI"
    },
    {
        "kod": "089905",
        "tanim": "ELMAS (ENDÜSTRİ ELMASLARI DAHİL) MADENCİLİĞİ"
    },
    {
        "kod": "089990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER MADENCİLİK VE TAŞOCAKÇILIĞI"
    },
    {
        "kod": "091001",
        "tanim": "DOĞALGAZIN SIVILAŞTIRILMASI VE GAZ HALİNE GETİRİLMESİ (MADEN ALANINDA GERÇEKLEŞTİRİLENLER)"
    },
    {
        "kod": "091002",
        "tanim": "PETROL VE GAZ ÇIKARIMIYLA İLGİLİ SONDAJ HİZMETLERİ (TETKİK, ARAŞTIRMA HİZMETLERİ, JEOLOJİK GÖZLEMLER, KUYU ÇALIŞTIRILMASI VE KAPATILMASI İLE TEST AMAÇLI SONDAJ FAALİYETLERİ VB. DAHİL)"
    },
    {
        "kod": "091003",
        "tanim": "PETROL VE GAZ ÇIKARIMI İLE İLGİLİ VİNÇ VE SONDAJ KULESİ KURMA, ONARIM, SÖKME VB. HİZMET FAALİYETLERİ"
    },
    {
        "kod": "099001",
        "tanim": "MADENCİLİK VE TAŞ OCAKÇILIĞINI DESTEKLEYİCİ DİĞER HİZMET FAALİYETLERİ (TETKİK, ARAŞTIRMA HİZMETLERİ, JEOLOJİK GÖZLEMLER, BOŞALTMA, POMPALAMA HİZMETLERİ) (TEST AMAÇLI SONDAJ FAALİYETLERİ İLE PETROL VE DOĞALGAZ İÇİN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "099002",
        "tanim": "MADENCİLİK VE TAŞ OCAKÇILIĞINI DESTEKLEYİCİ TEST AMAÇLI SONDAJ FAALİYETLERİ (PETROL VE DOĞALGAZ İÇİN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "101101",
        "tanim": "SIĞIR, KOYUN, KEÇİ VB. HAYVANLARIN KESİMİ VE KESİM SIRASINDAKİ ETİN İŞLENMESİ (MEZBAHACILIK) (TAZE, SOĞUTULMUŞ VEYA DONDURULMUŞ OLARAK SAKLANMASI DAHİL)"
    },
    {
        "kod": "101201",
        "tanim": "KÜMES HAYVANLARI ETLERİNİN ÜRETİMİ (TAZE VEYA DONDURULMUŞ) (YENİLEBİLİR SAKATATLARI DAHİL)"
    },
    {
        "kod": "101202",
        "tanim": "KÜMES HAYVANLARININ KESİLMESİ, TEMİZLENMESİ VEYA PAKETLENMESİ İŞİ İLE UĞRAŞAN MEZBAHALARIN FAALİYETLERİ"
    },
    {
        "kod": "101203",
        "tanim": "KÜMES HAYVANLARININ YAĞLARININ SOFRA YAĞINA ÇEVRİLMESİ"
    },
    {
        "kod": "101204",
        "tanim": "KUŞ TÜYÜ VE İNCE KUŞ TÜYÜ İMALATI (DERİLERİ DAHİL)"
    },
    {
        "kod": "101301",
        "tanim": "ET VE KÜMES HAYVANLARI ETLERİNDEN ÜRETİLEN PİŞMEMİŞ KÖFTE VB. ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "101302",
        "tanim": "ET VE KÜMES HAYVANLARI ETLERİNDEN ÜRETİLEN SOSİS, SALAM, SUCUK, PASTIRMA, KAVURMA ET, KONSERVE ET, SALAMURA ET, JAMBON VB. TUZLANMIŞ, KURUTULMUŞ VEYA TÜTSÜLENMİŞ ÜRÜNLERİN İMALATI (YEMEK OLANLAR HARİÇ)"
    },
    {
        "kod": "101303",
        "tanim": "ET VE SAKATAT UNLARI İMALATI (ET VE KÜMES HAYVANLARI ETLERİNDEN ÜRETİLEN)"
    },
    {
        "kod": "101304",
        "tanim": "SIĞIR, KOYUN, KEÇİ VB. HAYVANLARIN SAKATAT VE YAĞLARINDAN YENİLEBİLİR ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "102003",
        "tanim": "BALIKLARIN, KABUKLU DENİZ HAYVANLARININ VE YUMUŞAKÇALARIN İŞLENMESİ VE SAKLANMASI (DONDURULMASI, KURUTULMASI, PİŞİRİLMESİ, TÜTSÜLENMESİ, TUZLANMASI, SALAMURA EDİLMESİ, KONSERVELENMESİ VB. FAALİYETLER)"
    },
    {
        "kod": "102004",
        "tanim": "BALIK, KABUKLU DENİZ HAYVANI VE YUMUŞAKÇA ÜRÜNLERİNİN ÜRETİMİ (BALIK FİLETOSU, BALIK YUMURTASI, HAVYAR, HAVYAR YERİNE KULLANILAN ÜRÜNLER VB.)"
    },
    {
        "kod": "102005",
        "tanim": "BALIK UNLARI, KABA UNLARI VE PELETLERİNİN ÜRETİLMESİ (İNSAN TÜKETİMİ İÇİN)"
    },
    {
        "kod": "102006",
        "tanim": "BALIĞIN SADECE İŞLENMESİ VE SAKLANMASIYLA İLGİLİ FAALİYET GÖSTEREN TEKNE VE GEMİLERİN FAALİYETLERİ"
    },
    {
        "kod": "102007",
        "tanim": "PİŞİRİLMEMİŞ BALIK YEMEKLERİ İMALATI (MAYALANMIŞ BALIK, BALIK HAMURU, BALIK KÖFTESİ VB.)"
    },
    {
        "kod": "102008",
        "tanim": "BALIKLARIN, KABUKLULARIN, YUMUŞAKÇALARIN VEYA DİĞER SU OMURGASIZLARININ UNLARI, KABA UNLARI VE PELETLERİNİN ÜRETİMİ (İNSAN TÜKETİMİNE UYGUN OLMAYAN) İLE BUNLARIN DİĞER YENİLEMEYEN ÜRÜNLERİNİN ÜRETİMİ"
    },
    {
        "kod": "103101",
        "tanim": "PATATESİN İŞLENMESİ VE SAKLANMASI (DONDURULMUŞ, KURUTULMUŞ, SUYU ÇIKARTILMIŞ, EZİLMİŞ PATATES İMALATI) (SOYULMASI DAHİL)"
    },
    {
        "kod": "103102",
        "tanim": "PATATES CİPSİ, PATATES ÇEREZİ, PATATES UNU VE KABA UNLARININ İMALATI"
    },
    {
        "kod": "103201",
        "tanim": "KATKISIZ SEBZE VE MEYVE SULARI İMALATI (ŞALGAM SUYU, DOMATES SUYU, HAVUÇ SUYU, PORTAKAL SUYU, ELMA SUYU, KAYISI SUYU VB.)"
    },
    {
        "kod": "103202",
        "tanim": "KONSANTRE MEYVE VE SEBZE SUYU İMALATI"
    },
    {
        "kod": "103901",
        "tanim": "SEBZE VE MEYVE KONSERVESİ İMALATI (SALÇA, DOMATES PÜRESİ DAHİL, PATATESTEN OLANLAR HARİÇ)"
    },
    {
        "kod": "103902",
        "tanim": "KAVRULMUŞ, TUZLANMIŞ VB. ŞEKİLDE İŞLEM GÖRMÜŞ SERT KABUKLU YEMİŞLER İLE BU MEYVELERİN PÜRE VE EZMELERİNİN İMALATI (PİŞİRİLEREK YAPILANLAR)"
    },
    {
        "kod": "103903",
        "tanim": "MEYVE VE SEBZELERDEN JÖLE, PEKMEZ, MARMELAT, REÇEL VB. İMALATI (PESTİL İMALATI DAHİL)"
    },
    {
        "kod": "103904",
        "tanim": "TUZLU SU, SİRKE, SİRKELİ SU, YAĞ VEYA DİĞER KORUYUCU ÇÖZELTİLERLE KORUNARAK SAKLANAN SEBZE VE MEYVELERİN İMALATI (TURŞU, SALAMURA YAPRAK, SOFRALIK ZEYTİN VB. DAHİL)"
    },
    {
        "kod": "103905",
        "tanim": "DONDURULMUŞ VEYA KURUTULMUŞ MEYVE VE SEBZELERİN İMALATI (KURU KAYISI, KURU ÜZÜM, KURU BAMYA, KURU BİBER VB.)"
    },
    {
        "kod": "103906",
        "tanim": "LEBLEBİ İMALATI İLE KAVRULMUŞ ÇEKİRDEK, YERFISTIĞI VB. ÜRETİMİ (SERT KABUKLULAR HARİÇ)"
    },
    {
        "kod": "103907",
        "tanim": "SUSAMIN İŞLENMESİ VE TAHİN İMALATI"
    },
    {
        "kod": "103990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ MEYVE VE SEBZELERİN BAŞKA YÖNTEMLERLE İŞLENMESİ VE SAKLANMASI (KESİLMİŞ VE PAKETLENMİŞ OLANLAR DAHİL)"
    },
    {
        "kod": "104101",
        "tanim": "AYÇİÇEK YAĞI İMALATI"
    },
    {
        "kod": "104102",
        "tanim": "BİTKİSEL SIVI YAĞ (YENİLEBİLEN) İMALATI (SOYA, SUSAM, HAŞHAŞ, PAMUK, FINDIK, KOLZA, HARDAL VB. YAĞLAR) (ZEYTİN YAĞI, AYÇİÇEĞİ YAĞI VE MISIR YAĞI HARİÇ)"
    },
    {
        "kod": "104103",
        "tanim": "BEZİRYAĞI İMALATI"
    },
    {
        "kod": "104105",
        "tanim": "PRİNA YAĞI İMALATI (DİĞER KÜSPELERDEN ELDE EDİLEN YAĞLAR DAHİL) (MISIR YAĞI HARİÇ)"
    },
    {
        "kod": "104106",
        "tanim": "KAKAO YAĞI, BADEM YAĞI, KEKİK YAĞI, DEFNE YAĞI, HURMA ÇEKİRDEĞİ VEYA BABASSU YAĞI, KETEN TOHUMU YAĞI, HİNT YAĞI, TUNG YAĞI VE DİĞER BENZER YAĞLARIN İMALATI (BEZİR YAĞI HARİÇ)"
    },
    {
        "kod": "104107",
        "tanim": "ZEYTİNYAĞI İMALATI (SAF, SIZMA, RAFİNE)"
    },
    {
        "kod": "104110",
        "tanim": "BALIK VE DENİZ MEMELİLERİNDEN YAĞ ELDE EDİLMESİ"
    },
    {
        "kod": "104111",
        "tanim": "DOMUZ DON YAĞI (STEARİN), DOMUZ SIVI YAĞI, OLEOSTARİN, OLEOİL VE YENİLEMEYEN SIVI DON YAĞI (TALLOW OİL) İLE DİĞER HAYVANSAL KATI VE SIVI YAĞLARIN İMALATI (İŞLENMEMİŞ)"
    },
    {
        "kod": "104201",
        "tanim": "MARGARİN, KARIŞIK YEMEKLİK VE SOFRALIK KATI YAĞLARIN İMALATI"
    },
    {
        "kod": "105101",
        "tanim": "SÜT İMALATI, İŞLENMİŞ (PASTÖRİZE EDİLMİŞ, STERİLİZE EDİLMİŞ, HOMOJENLEŞTİRİLMİŞ VE/VEYA YÜKSEK ISIDAN GEÇİRİLMİŞ) (KATI VEYA TOZ HALDE SÜT HARİÇ)"
    },
    {
        "kod": "105102",
        "tanim": "PEYNİR, LOR VE ÇÖKELEK İMALATI"
    },
    {
        "kod": "105103",
        "tanim": "SÜT TOZU, PEYNİR ÖZÜ (KAZEİN), SÜT ŞEKERİ (LAKTOZ) VE PEYNİR ALTI SUYU (KESİLMİŞ SÜTÜN SUYU) İMALATI (KATI VEYA TOZ HALDE SÜT, KREMA DAHİL)"
    },
    {
        "kod": "105104",
        "tanim": "SÜT TEMELLİ HAFİF İÇECEKLERİN İMALATI (KEFİR, SALEP VB.)"
    },
    {
        "kod": "105105",
        "tanim": "SÜTTEN YAPILAN DİĞER ÜRÜNLERİN İMALATI (TEREYAĞI, YOĞURT, AYRAN, KAYMAK, KREMA, VB.) (KREM ŞANTİ DAHİL) (KATI VEYA TOZ HALDE KREMA HARİÇ)"
    },
    {
        "kod": "105201",
        "tanim": "DONDURMA İMALATI (SADE, SEBZELİ, MEYVELİ VB.)"
    },
    {
        "kod": "105202",
        "tanim": "ŞERBETLİ DİĞER YENİLEBİLEN BUZLU GIDALARIN İMALATI"
    },
    {
        "kod": "106101",
        "tanim": "KAHVALTILIK TAHIL ÜRÜNLERİ İLE DİĞER TANELİ TAHIL ÜRÜNLERİNİN İMALATI (BUĞDAY, YULAF, MISIR, ÇAVDAR VB. EZMELERİ İLE MISIR GEVREĞİ VE PATLAMIŞ MISIR DAHİL)"
    },
    {
        "kod": "106102",
        "tanim": "TAHILLARIN ÖĞÜTÜLMESİ VE UN İMALATI (MISIR UNU, KEPEK, RAZMOL DAHİL, PİRİNÇ UNU HARİÇ)"
    },
    {
        "kod": "106105",
        "tanim": "PİRİNÇ, PİRİNÇ EZMESİ VE PİRİNÇ UNU İMALATI (ÇELTİK FABRİKASI VE ÜRÜNLERİ DAHİL)"
    },
    {
        "kod": "106106",
        "tanim": "İRMİK İMALATI"
    },
    {
        "kod": "106107",
        "tanim": "ÖN PİŞİRME YAPILMIŞ VEYA BAŞKA ŞEKİLDE HAZIRLANMIŞ TANE HALDE HUBUBAT İMALATI (BULGUR DAHİL, FAKAT MISIR HARİÇ)"
    },
    {
        "kod": "106108",
        "tanim": "SEBZELERİN VE BAKLAGİLLERİN ÖĞÜTÜLMESİ VE SEBZE UNU İLE EZMELERİNİN İMALATI (KARIŞIMLARI İLE HAZIR KARIŞTIRILMIŞ SEBZE UNLARI DAHİL) (PİŞİRİLEREK YAPILANLAR HARİÇ)"
    },
    {
        "kod": "106109",
        "tanim": "FIRINCILIK ÜRÜNLERİNİN İMALATINDA KULLANILAN HAMUR VE UN KARIŞIMLARININ İMALATI (SEBZE UN KARIŞIMLARI HARİÇ)"
    },
    {
        "kod": "106110",
        "tanim": "DÖVÜLMÜŞ DİĞER TAHIL ÜRÜNLERİNİN İMALATI (KEŞKEKLİK BUĞDAY VB. DAHİL) (BULGUR VE İRMİK HARİÇ)"
    },
    {
        "kod": "106201",
        "tanim": "NİŞASTA İMALATI (BUĞDAY, PİRİNÇ, PATATES, MISIR, MANYOK VB. ÜRÜNLERDEN)"
    },
    {
        "kod": "106202",
        "tanim": "GLİKOZ, GLİKOZ ŞURUBU, FRUKTOZ, MALTOZ, İNULİN, VB. İMALATI (İNVERT ŞEKER DAHİL)"
    },
    {
        "kod": "106204",
        "tanim": "YAŞ MISIRIN ÖĞÜTÜLMESİ"
    },
    {
        "kod": "106205",
        "tanim": "GLÜTEN İMALATI"
    },
    {
        "kod": "106206",
        "tanim": "MISIR YAĞI İMALATI"
    },
    {
        "kod": "107101",
        "tanim": "TAZE PASTANE ÜRÜNLERİ İMALATI (YAŞ PASTA, KURU PASTA, POĞAÇA, KEK, BÖREK, PAY, TURTA, WAFFLES VB.)"
    },
    {
        "kod": "107102",
        "tanim": "FIRIN ÜRÜNLERİ İMALATI (EKMEK, PİDE, SİMİT, VB. DAHİL, TAZE PASTANE ÜRÜNLERİNİN İMALATI HARİÇ)"
    },
    {
        "kod": "107103",
        "tanim": "HAMUR TATLILARI İMALATI (TATLANDIRILMIŞ KADAYIF, LOKMA TATLISI, BAKLAVA VB.)"
    },
    {
        "kod": "107201",
        "tanim": "PEKSİMET, BİSKÜVİ, GOFRET, DONDURMA KÜLAHI, KAĞIT HELVA VB. ÜRÜNLERİN İMALATI (ÇİKOLATA KAPLI OLANLAR DAHİL)"
    },
    {
        "kod": "107202",
        "tanim": "TATLI VEYA TUZLU HAFİF DAYANIKLI FIRIN VE PASTANE ÜRÜNLERİNİN İMALATI (KURABİYELER, KRAKERLER, GALETA, GEVREK HALKALAR VB.)"
    },
    {
        "kod": "107203",
        "tanim": "TATLANDIRILMAMIŞ DAYANIKLI HAMUR TATLILARI İMALATI (PİŞİRİLMİŞ OLSUN OLMASIN TATLANDIRILMAMIŞ KADAYIF, BAKLAVA VB.) (YUFKA İMALATI DAHİL)"
    },
    {
        "kod": "107303",
        "tanim": "MAKARNA, ŞEHRİYE, KUSKUS VE BENZERİ MAMULLERİN İMALATI (DOLDURULMUŞ VEYA DONDURULMUŞ OLANLAR DAHİL)"
    },
    {
        "kod": "108101",
        "tanim": "ŞEKER KAMIŞINDAN, PANCARDAN, PALMİYEDEN, AKÇA AĞAÇTAN ŞEKER (SAKKAROZ) VE ŞEKER ÜRÜNLERİ İMALATI VEYA BUNLARIN RAFİNE EDİLMESİ (SIVI ŞEKER VE MELAS ÜRETİMİ DAHİL)"
    },
    {
        "kod": "108103",
        "tanim": "AKÇAAĞAÇ ŞURUBU İMALATI"
    },
    {
        "kod": "108201",
        "tanim": "ÇİKOLATA VE KAKAO İÇEREN ŞEKERLEMELERİN İMALATI (BEYAZ ÇİKOLATA VE SÜRÜLEREK YENİLEBİLEN KAKAOLU ÜRÜNLER HARİÇ)"
    },
    {
        "kod": "108202",
        "tanim": "ŞEKERLEMELERİN VE ŞEKER PASTİLLERİNİN İMALATI (BONBON ŞEKERİ VB.) (KAKAOLU ŞEKERLEMELER HARİÇ)"
    },
    {
        "kod": "108203",
        "tanim": "SÜRÜLEREK YENEBİLEN KAKAOLU ÜRÜNLER İMALATI"
    },
    {
        "kod": "108204",
        "tanim": "LOKUM, PİŞMANİYE, HELVA, KARAMEL, KOZ HELVA, FONDAN, BEYAZ ÇİKOLATA VB. İMALATI (TAHİN HELVASI DAHİL)"
    },
    {
        "kod": "108205",
        "tanim": "CİKLET İMALATI (SAKIZ)"
    },
    {
        "kod": "108206",
        "tanim": "SERT KABUKLU MEYVE, MEYVE KABUĞU VE DİĞER BİTKİ PARÇALARINDAN ŞEKERLEME İMALATI (MEYAN KÖKÜ HÜLASALARI DAHİL)"
    },
    {
        "kod": "108207",
        "tanim": "KAKAO TOZU, KAKAO EZMESİ/HAMURU VE KAKAO YAĞI İMALATI"
    },
    {
        "kod": "108301",
        "tanim": "ÇAY ÜRÜNLERİ İMALATI (SİYAH ÇAY, YEŞİL ÇAY VE POŞET ÇAY İLE ÇAY EKSTRELERİ, ESANSLARI VE KONSANTRELERİ)"
    },
    {
        "kod": "108302",
        "tanim": "KAHVE ÜRÜNLERİ İMALATI (ÇEKİLMİŞ KAHVE, ERİTİLEBİLİR KAHVE İLE KAHVE EKSTRE, ESANS VE KONSANTRELERİ)"
    },
    {
        "kod": "108303",
        "tanim": "BİTKİSEL ÇAYLARIN İMALATI (NANE, YABAN OTU, PAPATYA, IHLAMUR, KUŞBURNU VB. ÇAYLAR)."
    },
    {
        "kod": "108304",
        "tanim": "KAHVE İÇEREN VE KAHVE YERİNE GEÇEBİLECEK ÜRÜNLERİN İMALATI (ŞEKER, SÜT VB. KARIŞTIRILMIŞ ÜRÜNLER DAHİL)"
    },
    {
        "kod": "108401",
        "tanim": "BAHARAT İMALATI (KARABİBER, KIRMIZI TOZ/PUL BİBER, HARDAL UNU, TARÇIN, YENİBAHAR, DAMLA SAKIZI, BAHARAT KARIŞIMLARI VB.) (İŞLENMİŞ)"
    },
    {
        "kod": "108402",
        "tanim": "SİRKE VE SİRKE İKAMELERİNİN İMALATI"
    },
    {
        "kod": "108403",
        "tanim": "SOS VE ÇEŞNİLERİN İMALATI (SOYA SOSU, KETÇAP, MAYONEZ, HARDAL SOSU, ÇEMEN, MANGO ÇEŞNİSİ VB.) (BAHARAT, SİRKE VE SALÇA HARİÇ)"
    },
    {
        "kod": "108405",
        "tanim": "GIDA TUZU İMALATI"
    },
    {
        "kod": "108501",
        "tanim": "HAZIR YEMEK İMALATI (VAKUMLA PAKETLENMİŞ VEYA KORUNMUŞ OLANLAR) (LOKANTA VE CATERİNG HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "108601",
        "tanim": "BEBEK VE ÇOCUKLARIN BESLENMESİNDE KULLANILAN MÜSTAHZARLARIN İMALATI (BEBEK MAMALARI, PUDİNGLERİ VB.)"
    },
    {
        "kod": "108602",
        "tanim": "HASTALAR İÇİN VEYA DİYET AMAÇLI HAZIRLANAN HOMOJENİZE GIDA MÜSTAHZARLARININ İMALATI (GLÜTEN İÇERMEYEN GIDA MADDELERİ, SODYUM İÇERMEYEN TUZLAR VB. GIDALAR)"
    },
    {
        "kod": "108603",
        "tanim": "BESİN YÖNÜNDEN ZENGİNLEŞTİRİLMİŞ SPORCU YİYECEKLERİNİN İMALATI"
    },
    {
        "kod": "108901",
        "tanim": "HAZIR ÇORBA İLE HAZIR ET SUYU, BALIK SUYU, TAVUK SUYU VE KONSANTRELERİNİN İMALATI"
    },
    {
        "kod": "108902",
        "tanim": "MAYA VE KABARTMA TOZU İMALATI (BİRA MAYASI DAHİL)"
    },
    {
        "kod": "108904",
        "tanim": "SUNİ BAL, KARAMELA, KABUKSUZ YUMURTA, YUMURTA ALBÜMİNİ VB. İMALATI"
    },
    {
        "kod": "108905",
        "tanim": "BİTKİ ÖZSU VE EKSTRELERİ İLE PEPTİK MADDELER, MÜSİLAJ VE KIVAM ARTTIRICI MADDELERİN İMALATI (KOLA KONSANTRESİ, MALT ÖZÜ, MEYAN BALI DAHİL)"
    },
    {
        "kod": "108906",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ÇEŞİTLİ GIDA ÜRÜNLERİ İMALATI (ÇABUK BOZULAN HAZIR GIDALAR, PEYNİR FONDÜLERİ, ŞEKER ŞURUPLARI VB. DAHİL)"
    },
    {
        "kod": "109101",
        "tanim": "ÇİFTLİK HAYVANLARI İÇİN HAZIR YEM İMALATI"
    },
    {
        "kod": "109201",
        "tanim": "EV HAYVANLARI İÇİN HAZIR GIDA İMALATI (KEDİ VE KÖPEK MAMALARI, KUŞ VE BALIK YEMLERİ VB.)"
    },
    {
        "kod": "110101",
        "tanim": "DAMITILMIŞ ALKOLLÜ İÇECEKLERİN İMALATI (VİSKİ, BRENDİ, CİN, LİKÖR, RAKI, VOTKA, KANYAK VB.)"
    },
    {
        "kod": "110102",
        "tanim": "DAMITILMIŞ ALKOLLÜ İÇECEKLERLE KARIŞTIRILMIŞ İÇKİ İMALATI"
    },
    {
        "kod": "110103",
        "tanim": "ETİL ALKOL ÜRETİMİ (DOĞAL ÖZELLİKLERİ DEĞİŞTİRİLMEMİŞ/TAĞYİR EDİLMEMİŞ, ALKOL DERECESİ <%80)"
    },
    {
        "kod": "110201",
        "tanim": "ÜZÜMDEN ŞARAP, KÖPÜKLÜ ŞARAP, ŞAMPANYA VB. ÜRETİMİ"
    },
    {
        "kod": "110202",
        "tanim": "ÜZÜM ŞIRASI İMALATI"
    },
    {
        "kod": "110301",
        "tanim": "ELMA ŞARABI VE DİĞER MEYVE ŞARAPLARININ İMALATI"
    },
    {
        "kod": "110402",
        "tanim": "DİĞER DAMITILMAMIŞ MAYALI İÇECEKLERİN İMALATI (VERMUT VE BENZERİ İÇKİLER DAHİL)"
    },
    {
        "kod": "110501",
        "tanim": "BİRA İMALATI"
    },
    {
        "kod": "110601",
        "tanim": "MALT İMALATI"
    },
    {
        "kod": "110701",
        "tanim": "DOĞAL VEYA SUNİ MADEN SULARININ ÜRETİMİ (TATLANDIRILMIŞ VE AROMALANDIRILMIŞ OLANLAR DAHİL)"
    },
    {
        "kod": "110702",
        "tanim": "DİĞER ALKOLSÜZ İÇECEKLERİN ÜRETİMİ (LİMONATA, GAZOZ, KOLALI İÇECEKLER, MEYVELİ İÇECEKLER, TONİK, BUZLU ÇAY VB. İÇECEKLER) (İÇME SUYU VE MADEN SULARI HARİÇ)"
    },
    {
        "kod": "110703",
        "tanim": "İÇME SUYU ÜRETİMİ (ŞİŞELENMİŞ, GAZSIZ, TATLANDIRILMAMIŞ VE AROMALANDIRILMAMIŞ)"
    },
    {
        "kod": "110704",
        "tanim": "BOZA İMALATI"
    },
    {
        "kod": "120004",
        "tanim": "TÜTÜN ÜRÜNLERİ İMALATI"
    },
    {
        "kod": "131003",
        "tanim": "DOĞAL PAMUK ELYAFININ İMALATI (KARDELENMESİ, TARAKLANMASI, VB.)"
    },
    {
        "kod": "131005",
        "tanim": "DOĞAL YÜN VE TİFTİK ELYAFININ İMALATI (KARDELENMESİ, TARAKLANMASI, YÜN YAĞININ GİDERİLMESİ, KARBONİZE EDİLMESİ VE YAPAĞININ BOYANMASI VB.)"
    },
    {
        "kod": "131006",
        "tanim": "DOĞAL JÜT, KETEN VE DİĞER BİTKİSEL TEKSTİL ELYAFLARININ İMALATI (KARDELENMESİ, TARAKLANMASI VB.) (PAMUK HARİÇ)"
    },
    {
        "kod": "131008",
        "tanim": "İPEĞİN KOZADAN AYRILMASI VE SARILMASI"
    },
    {
        "kod": "131009",
        "tanim": "SENTETİK VEYA SUNİ DEVAMSIZ ELYAFIN KARDELENMESİ VE TARAKLANMASI"
    },
    {
        "kod": "131010",
        "tanim": "DOĞAL İPEĞİN BÜKÜLMESİ VE İPLİK HALİNE GETİRİLMESİ"
    },
    {
        "kod": "131012",
        "tanim": "PAMUK ELYAFININ BÜKÜLMESİ VE İPLİK HALİNE GETİRİLMESİ"
    },
    {
        "kod": "131013",
        "tanim": "YÜN VE TİFTİK ELYAFININ BÜKÜLMESİ VE İPLİK HALİNE GETİRİLMESİ"
    },
    {
        "kod": "131014",
        "tanim": "JÜT, KETEN VE DİĞER BİTKİSEL TEKSTİL ELYAFLARININ BÜKÜLMESİ VE İPLİK HALİNE GETİRİLMESİ (PAMUK HARİÇ)"
    },
    {
        "kod": "131015",
        "tanim": "SUNİ VE SENTETİK ELYAFLARIN BÜKÜLMESİ VE İPLİK HALİNE GETİRİLMESİ (FİLAMENT İPLİĞİ VE SUNİ İPEK ELYAFI İMALATI HARİÇ)"
    },
    {
        "kod": "132014",
        "tanim": "KOT KUMAŞI İMALATI"
    },
    {
        "kod": "132016",
        "tanim": "PAMUKLU DOKUMA KUMAŞ İMALATI (HAVLU, PELUŞ, VB. İLMEĞİ KESİLMEMİŞ KUMAŞLAR İLE KOT, KADİFE VE TAFTİNG KUMAŞLAR HARİÇ)"
    },
    {
        "kod": "132017",
        "tanim": "DOĞAL KIL VE YÜNDEN DOKUMA KUMAŞ İMALATI"
    },
    {
        "kod": "132019",
        "tanim": "DOĞAL İPEKTEN KUMAŞ İMALATI"
    },
    {
        "kod": "132020",
        "tanim": "KETEN, RAMİ, KENEVİR, JÜT ELYAFLARI İLE DİĞER BİTKİSEL TEKSTİL ELYAFLARINDAN DOKUMA KUMAŞ İMALATI (PAMUK HARİÇ)"
    },
    {
        "kod": "132021",
        "tanim": "HAVLI, ŞÖNİL, HAVLU, PELÜŞ, TIRTIL VE BENZERİ İLMEĞİ KESİLMEMİŞ DOKUMA KUMAŞLAR İLE TAFTİNG KUMAŞ İMALATI"
    },
    {
        "kod": "132022",
        "tanim": "SUNİ VE SENTETİK FİLAMENTLERDEN VE DEVAMSIZ ELYAFLARDAN DOKUMA KUMAŞ İMALATI  (HAVLU, PELUŞ, VB. İLMEĞİ KESİLMEMİŞ KUMAŞLAR İLE KADİFE VE TAFTİNG KUMAŞLAR HARİÇ)"
    },
    {
        "kod": "132023",
        "tanim": "DOKUMA YOLUYLA İMİTASYON (TAKLİT) KÜRK KUMAŞ İMALATI"
    },
    {
        "kod": "132024",
        "tanim": "CAM ELYAFINDAN DOKUMA KUMAŞ İMALATI (CAM ELYAFINDAN DAR KUMAŞLAR DAHİL)"
    },
    {
        "kod": "133001",
        "tanim": "KUMAŞ VE TEKSTİL ÜRÜNLERİNİ AĞARTMA VE BOYAMA HİZMETLERİ (GİYİM EŞYASI DAHİL)"
    },
    {
        "kod": "133002",
        "tanim": "TEKSTİL ELYAF VE İPLİKLERİNİ AĞARTMA VE BOYAMA HİZMETLERİ (KASARLAMA DAHİL)"
    },
    {
        "kod": "133003",
        "tanim": "KUMAŞ VE TEKSTİL ÜRÜNLERİNE BASKI YAPILMASI HİZMETLERİ (GİYİM EŞYASI DAHİL)"
    },
    {
        "kod": "133004",
        "tanim": "KUMAŞ VE TEKSTİL ÜRÜNLERİNE İLİŞKİN DİĞER BİTİRME HİZMETLERİ (APRELEME, PLİSELEME, SANFORLAMA, VB. DAHİL)"
    },
    {
        "kod": "139101",
        "tanim": "ÖRGÜ VE TIĞ İŞİ KUMAŞLARIN İMALATI (PENYE VE HAVLI KUMAŞLAR İLE RASCHEL VEYA BENZERİ MAKİNELER İLE ÖRÜLEN TÜL, PERDE, VB. ÖRGÜ VEYA TIĞ İLE ÖRÜLMÜŞ ÜRÜNLER DAHİL)"
    },
    {
        "kod": "139102",
        "tanim": "ÖRME YOLUYLA İMİTASYON (TAKLİT) KÜRK KUMAŞI İMALATI"
    },
    {
        "kod": "139201",
        "tanim": "YATAK ÖRTÜ TAKIMLARI, YATAK ÇARŞAFLARI, YASTIK KILIFLARI, MASA ÖRTÜSÜ İLE TUVALET VE MUTFAKTA KULLANILAN ÖRTÜLERİN İMALATI (EL VE YÜZ HAVLULARI DAHİL)"
    },
    {
        "kod": "139202",
        "tanim": "YORGAN, KUŞTÜYÜ YORGAN, MİNDER, PUF, YASTIK, HALI YASTIK, UYKU TULUMU VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "139203",
        "tanim": "PERDELERİN VE İÇ STORLARIN, PERDE VEYA YATAK SAÇAKLARININ, FARBELALARININ VE MALZEMELERİNİN İMALATI (GİPÜR, TÜL PERDE VE KALIN PERDELER DAHİL)"
    },
    {
        "kod": "139204",
        "tanim": "TEKSTİLDEN YER BEZİ, BULAŞIK BEZİ, TOZ BEZİ VB. TEMİZLİK BEZLERİ İMALATI"
    },
    {
        "kod": "139205",
        "tanim": "BATTANİYE İMALATI"
    },
    {
        "kod": "139206",
        "tanim": "TEKSTİLDEN ÇUVAL, TORBA, ÇANTA VE BENZERLERİNİN İMALATI (EŞYA PAKETLEME AMACIYLA KULLANILANLAR)"
    },
    {
        "kod": "139207",
        "tanim": "CAN YELEĞİ VE CAN KURTARAN SİMİDİ İMALATI"
    },
    {
        "kod": "139208",
        "tanim": "PARAŞÜT (YÖNLENDİRİLEBİLEN PARAŞÜTLER DAHİL) VE ROTOŞÜT İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "139209",
        "tanim": "BAYRAK, SANCAK VE FLAMA İMALATI"
    },
    {
        "kod": "139210",
        "tanim": "TEKSTİLDEN ÖRTÜ VE KILIF İMALATI (ARABA, MAKİNE, MOBİLYA VB. İÇİN)"
    },
    {
        "kod": "139211",
        "tanim": "BRANDA, TENTE, STOR (GÜNEŞLİK), YELKEN, ÇADIR VE KAMP MALZEMELERİ İMALATI (ŞİŞME YATAKLAR DAHİL)"
    },
    {
        "kod": "139301",
        "tanim": "HALI (DUVAR HALISI DAHİL) VE KİLİM İMALATI (PASPAS, YOLLUK VE BENZERİ TEKSTİL YER KAPLAMALARI DAHİL)"
    },
    {
        "kod": "139302",
        "tanim": "HALI, KİLİM VB. İÇİN ÇÖZGÜCÜLÜK, HALI OYMACILIĞI VB. FAALİYETLER"
    },
    {
        "kod": "139402",
        "tanim": "AĞ VE AĞ ÜRÜNLERİ İMALATI, SİCİM, KINNAP, HALAT VEYA URGANDAN (BALIK AĞI, YÜK BOŞALTMA AĞLARI, VB.)"
    },
    {
        "kod": "139403",
        "tanim": "SİCİM, URGAN, HALAT, KORDON VE BENZERLERİ İMALATI (KAUÇUK VEYA PLASTİK EMDİRİLMİŞ, KAPLANMIŞ OLANLAR DAHİL)"
    },
    {
        "kod": "139501",
        "tanim": "DOKUSUZ KUMAŞLAR İLE BUNLARDAN YAPILAN ÜRÜNLERİN İMALATI (GİYİM EŞYASI HARİÇ)"
    },
    {
        "kod": "139601",
        "tanim": "DOKUNABİLİR İPLİKLERDEN METALİZE İPLİK VE METALİZE GİPE İPLİK İLE BUNLARDAN DOKUMA KUMAŞ İMALATI (GİYİM VE DÖŞEMECİLİKTE KULLANILAN)"
    },
    {
        "kod": "139602",
        "tanim": "TEKSTİL MALZEMELERİNDEN PARÇA HALİNDE KORDONLAR; İŞLEME YAPILMAMIŞ ŞERİTÇİ EŞYASI VE BENZERİ SÜS EŞYALARININ İMALATI"
    },
    {
        "kod": "139603",
        "tanim": "DAR DOKUMA KUMAŞLARIN İMALATI (ETİKET, ARMA VE DİĞER BENZERİ EŞYALAR HARİÇ)"
    },
    {
        "kod": "139604",
        "tanim": "TEKSTİL MALZEMELERİNDEN DOKUMA ETİKET, ROZET, ARMA VE DİĞER BENZERİ EŞYALARIN İMALATI"
    },
    {
        "kod": "139605",
        "tanim": "TEKNİK KULLANIM AMAÇLI TEKSTİL ÜRÜNLERİ VE EŞYALARI İMALATI (FİTİL, LÜKS LAMBASI GÖMLEĞİ, TEKSTİL MALZEMESİNDEN HORTUMLAR, TAŞIMA VEYA KONVEYÖR BANTLARI, ELEK BEZİ VE SÜZGEÇ BEZİ DAHİL)"
    },
    {
        "kod": "139606",
        "tanim": "KORD BEZİ İMALATI"
    },
    {
        "kod": "139607",
        "tanim": "TEKSTİLLE KAPLANMIŞ KAUÇUK İPLİK VEYA KORDON İLE KAUÇUK VEYA PLASTİKLE KAPLANMIŞ VEYA EMDİRİLMİŞ TEKSTİLDEN İPLİK VEYA ŞERİTLER VE BUNLARDAN YAPILMIŞ MENSUCAT İMALATI"
    },
    {
        "kod": "139608",
        "tanim": "KAPLANMIŞ VEYA EMDİRİLMİŞ TEKSTİL KUMAŞLARININ İMALATI (CİLT KAPAĞI İÇİN MENSUCAT, MÜHENDİS MUŞAMBASI, TİYATRO DEKORLARI, TUVAL VB. DAHİL)"
    },
    {
        "kod": "139902",
        "tanim": "TÜL VE DİĞER AĞ KUMAŞLARIN (DOKUMA, ÖRGÜ (TRİKO) VEYA TIĞ İŞİ (KROŞE) OLANLAR HARİÇ) İMALATI İLE OYA, DANTEL VE NAKIŞ İMALATI (YAKA, FİSTO YAKA, LEZ, APLİK, MOTİF, KAPİTONE ÜRÜNLERİ VB. DAHİL)"
    },
    {
        "kod": "139903",
        "tanim": "KEÇE, BASINÇLI HASSAS GİYSİ DOKUMALARI, TEKSTİLDEN AYAKKABI BAĞI, PUDRA PONPONU VB. İMALATI"
    },
    {
        "kod": "139904",
        "tanim": "TEKSTİL KIRPINTISI İMALATI (YATAK, YORGAN, YASTIK, ŞİLTE VE BENZERİ DOLDURMAK İÇİN)"
    },
    {
        "kod": "139906",
        "tanim": "GİPE İPLİK VE ŞERİTLERİN, ŞÖNİL İPLİKLERİN, ŞENET İPLİKLERİN İMALATI (METALİZE OLANLAR İLE GİPE LASTİKLER HARİÇ)"
    },
    {
        "kod": "141105",
        "tanim": "DERİ GİYİM EŞYASI İMALATI (DERİ KARIŞIMLI OLANLAR DAHİL, AYAKKABI HARİÇ)"
    },
    {
        "kod": "141207",
        "tanim": "ENDÜSTRİYEL İŞ GİYSİSİ (İŞ ÖNLÜKLERİ, İŞ ELBİSELERİ, İŞ TULUMLARI, VB.) İMALATI (DİKİŞSİZ PLASTİK OLANLAR İLE ATEŞE DAYANIKLI VE KORUYUCU GÜVENLİK KIYAFETLERİ HARİÇ)"
    },
    {
        "kod": "141208",
        "tanim": "MESLEKİ KIYAFET İMALATI (RESMİ VE ÖZEL ÜNİFORMA VB. İLE OKUL ÖNLÜKLERİ DAHİL, ENDÜSTRİYEL İŞ GİYSİLERİ HARİÇ)"
    },
    {
        "kod": "141304",
        "tanim": "DIŞ GİYİM EŞYASI İMALATI, DOKUMA, ÖRME (TRİKOTAJ) VE TIĞ İŞİ (KROŞE), VB. KUMAŞTAN OLANLAR (KABAN, PALTO, CEKET, PANTOLON, TAKIM ELBİSE, DÖPİYES, ANORAK, YAĞMURLUK, GECE KIYAFETLERİ VB.) (İŞ GİYSİLERİ VE TERZİLERİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "141305",
        "tanim": "SİPARİŞE GÖRE ÖLÇÜ ALINARAK DIŞ GİYİM EŞYASI İMALATI, DOKUMA, ÖRGÜ (TRİKO) VE TIĞ İŞİ (KROŞE), VB. KUMAŞTAN OLANLAR (TERZİLERİN FAALİYETLERİ) (GİYİM EŞYASI TAMİRİ İLE GÖMLEK İMALATI HARİÇ)"
    },
    {
        "kod": "141306",
        "tanim": "SAHNE VE GÖSTERİ ELBİSELERİ İMALATI, DOKUMA, ÖRGÜ (TRİKO) VE TIĞ İŞİ (KROŞE), VB. KUMAŞTAN OLANLAR"
    },
    {
        "kod": "141307",
        "tanim": "GELİNLİK İMALATI"
    },
    {
        "kod": "141401",
        "tanim": "GÖMLEK, TİŞÖRT, BLUZ, VB. CEKET ALTINA GİYİLEBİLEN GİYİM EŞYASI İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141402",
        "tanim": "GECELİK, SABAHLIK, PİJAMA, BORNOZ VE ROPDÖŞAMBIR İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141403",
        "tanim": "ATLET, FANİLA, KÜLOT, SLİP, İÇ ETEK, KOMBİNEZON, JÜP, JÜPON, SÜTYEN, KORSE VB. İÇ ÇAMAŞIRI İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141404",
        "tanim": "ÇORAP BAĞLARI, JARTİYER, PANTOLON ASKILARI VE BENZERİ İÇ GİYİM AKSESUARLARI İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141901",
        "tanim": "SPOR VE ANTRENMAN GİYSİLERİ, KAYAK KIYAFETLERİ, YÜZME KIYAFETLERİ VB. İMALATI (MAYO, BİKİNİ DAHİL) (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141902",
        "tanim": "YAZMA, TÜLBENT, EŞARP, VB. İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN)"
    },
    {
        "kod": "141904",
        "tanim": "ELDİVEN, KEMER, ŞAL, PAPYON, KRAVAT, SAÇ FİLELERİ, KUMAŞ MENDİL, ATKI, FULAR, DUVAK VB. GİYSİ AKSESUARLARI İMALATI (DERİDEN, DOKUSUZ KUMAŞTAN VEYA DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN) (BEBEKLER İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "141905",
        "tanim": "BEBEK GİYİM EŞYASI VE AKSESUARLARI İMALATI (DOKUMA, ÖRGÜ VEYA TIĞ İŞİ KUMAŞTAN) (TABANSIZ PANDUF DAHİL)"
    },
    {
        "kod": "141907",
        "tanim": "ŞAPKA, KEP, BAŞLIK, KASKET, TABLA VE EL MANŞONLARI İLE BUNLARIN PARÇALARININ İMALATI (KÜRKTEN ŞAPKA VE BAŞLIKLAR DAHİL, BEBEKLER İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "141908",
        "tanim": "GİYİM EŞYASI İMALATI (KEÇEDEN VEYA DİĞER DOKUSUZ KUMAŞTAN YA DA EMDİRİLMİŞ VEYA KAPLANMIŞ TEKSTİL KUMAŞINDAN OLANLAR)"
    },
    {
        "kod": "142004",
        "tanim": "POST, KÜRK VEYA KÜRKLÜ DERİDEN YAPILMIŞ EŞYA VE PARÇALARIN İMALATI (GİYİM EŞYASI VE GİYSİ AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "142005",
        "tanim": "POST, KÜRK VEYA KÜRKLÜ DERİDEN YAPILMIŞ GİYİM EŞYASI VE GİYSİ AKSESUARLARI İMALATI (KÜRKTEN ŞAPKA, BAŞLIK VE ELDİVEN HARİÇ)"
    },
    {
        "kod": "143101",
        "tanim": "ÇORAP İMALATI (ÖRME VE TIĞ İŞİ OLAN KÜLOTLU ÇORAP, TAYT ÇORAP, KISA KADIN ÇORABI, ERKEK ÇORABI, PATİK VE DİĞER ÇORAPLAR)"
    },
    {
        "kod": "143901",
        "tanim": "ÖRGÜ (TRİKO) VE TIĞ İŞİ (KROŞE) DİĞER GİYİM EŞYASI İMALATI (DOĞRUDAN SÜVETER, KAZAK, HIRKA, YELEK, VB. ŞEKİLLERDE ÜRETİLENLER)"
    },
    {
        "kod": "151110",
        "tanim": "DERİ VE KÜRKLÜ DERİ İMALATI (KÜRKÜN VE DERİNİN TABAKLANMASI, SEPİLENMESİ, BOYANMASI, CİLALANMASI VE İŞLENMESİ)(İŞLENMİŞ DERİNİN BAŞKA İŞLEMLERE TABİ TUTULMAKSIZIN YALNIZCA TAMBURDA ÜTÜLENMESİ VE KURUTULMASI HARİÇ)"
    },
    {
        "kod": "151111",
        "tanim": "KÜRKLÜ DERİNİN VE POSTLARIN KAZINARAK TEMİZLENMESİ, KIRKILMASI, TÜYLERİNİN YOLUNMASI VE AĞARTILMASI (POSTLU DERİLERİN TERBİYESİ DAHİL)"
    },
    {
        "kod": "151113",
        "tanim": "DERİ VE KÖSELE ESASLI TERKİP İLE ELDE EDİLEN LEVHA, YAPRAK, ŞERİT DERİ VE KÖSELE İMALATI"
    },
    {
        "kod": "151114",
        "tanim": "İŞLENMİŞ DERİNİN BAŞKA İŞLEMLERE TABİ TUTULMAKSIZIN YALNIZCA TAMBURDA ÜTÜLENMESİ VE KURUTULMASI"
    },
    {
        "kod": "151207",
        "tanim": "DERİ, KÖSELE, KARMA DERİ VE DİĞER MALZEMELERDEN BAVUL, EL ÇANTASI, CÜZDAN, OKUL ÇANTASI, EVRAK ÇANTASI, DERİDEN SİGARALIK, DERİ AYAKKABI BAĞI, KİŞİSEL BAKIM, DİKİŞ, VB. AMAÇLI SEYAHAT SETİ, VB. ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "151208",
        "tanim": "DERİDEN VEYA DİĞER MALZEMELERDEN SARAÇLIK VE KOŞUM TAKIMI İMALATI (KAMÇI, SEMER, EYER, TASMA KAYIŞI, HEYBE, VB.)"
    },
    {
        "kod": "151209",
        "tanim": "DERİ SAAT KAYIŞI İMALATI"
    },
    {
        "kod": "151210",
        "tanim": "PLASTİK VEYA KAUÇUK SAAT KAYIŞI İMALATI"
    },
    {
        "kod": "151211",
        "tanim": "KUMAŞ VE DİĞER MALZEMELERDEN SAAT KAYIŞI İMALATI (METAL OLANLAR HARİÇ)"
    },
    {
        "kod": "151212",
        "tanim": "TABİİ/TERKİP YOLUYLA ELDE EDİLEN DERİ VE KÖSELEDEN TAŞIMA VE KONVEYÖR BANTLARI İMALATI"
    },
    {
        "kod": "152015",
        "tanim": "DERİDEN AYAKKABI, MES, BOT, ÇİZME, POSTAL, TERLİK, VB. İMALATI (TAMAMIYLA TEKSTİLDEN OLANLAR İLE ORTOPEDİK AYAKKABI VE KAYAK AYAKKABISI HARİÇ)"
    },
    {
        "kod": "152017",
        "tanim": "PLASTİK VEYA KAUÇUKTAN AYAKKABI, BOT, ÇİZME, POSTAL, TERLİK, VB. İMALATI (TAMAMIYLA TEKSTİLDEN OLANLAR İLE ORTOPEDİK AYAKKABI VE KAYAK AYAKKABISI HARİÇ)"
    },
    {
        "kod": "152018",
        "tanim": "TEKSTİLDEN VE DİĞER MALZEMELERDEN AYAKKABI, MES, BOT, ÇİZME, POSTAL, TERLİK, VB. İMALATI (DERİ VE PLASTİK OLANLAR İLE TAMAMIYLA TEKSTİLDEN OLANLAR, ORTOPEDİK AYAKKABI VE KAYAK AYAKKABISI HARİÇ)"
    },
    {
        "kod": "152019",
        "tanim": "AYAKKABILARIN DERİ KISIMLARININ VE AYAKKABI PARÇALARININ (KAUÇUK, PLASTİK VE AHŞAP PARÇALAR HARİÇ) İMALATI (ÜST VE ALT PARÇALARI, TOPUKLAR, VB. İMALATI İLE SAYACILIK FAALİYETLERİ DAHİL)"
    },
    {
        "kod": "161001",
        "tanim": "KERESTE İMALATI (AĞAÇLARIN BİÇİLMESİ, PLANYALANMASI, RENDELENMESİ VE ŞEKİLLENDİRİLMESİ FAALİYETLERİ)"
    },
    {
        "kod": "161002",
        "tanim": "AHŞAP DEMİR YOLU VEYA TRAMVAY TRAVERSİ İMALATI"
    },
    {
        "kod": "161003",
        "tanim": "AĞAÇ YÜNÜ, AĞAÇ UNU, AĞAÇ TALAŞI, AĞAÇ YONGA İMALATI"
    },
    {
        "kod": "161005",
        "tanim": "AHŞAP DÖŞEMELERİN VE YER DÖŞEMELERİNİN İMALATI (BİRLEŞTİRİLEBİLİR PARKELER HARİÇ)"
    },
    {
        "kod": "161006",
        "tanim": "TOMRUK VE KERESTELERİN KURUTULMASI, EMPRENYE EDİLMESİ VEYA KİMYASAL İŞLEMDEN GEÇİRİLMESİ HİZMETLERİ (BAŞKALARININ ADINA OLANLAR)"
    },
    {
        "kod": "162101",
        "tanim": "AHŞAP, BAMBU VE DİĞER ODUNSU MALZEMELERDEN KAPLAMALIK PLAKA, LEVHA, VB. İMALATI (YAPRAK HALDE) (PRESLENMEMİŞ)"
    },
    {
        "kod": "162102",
        "tanim": "SIKIŞTIRILMIŞ LİF, TAHTA VE TABAKALARDAN KONTRPLAK, MDF, SUNTA, VB. LEVHA İMALATI"
    },
    {
        "kod": "162201",
        "tanim": "BİRLEŞTİRİLEBİLİR AHŞAP PARKE YER DÖŞEMELERİNİN İMALATI (LAMİNE VE LAMİNAT PARKELER HARİÇ)"
    },
    {
        "kod": "162301",
        "tanim": "AHŞAP PENCERE, KAPI VE BUNLARIN KASALARI VE EŞİKLERİ İLE AHŞAP MERDİVEN, TIRABZAN, VERANDA, PARMAKLIK VB. İMALATI"
    },
    {
        "kod": "162302",
        "tanim": "AHŞAP PREFABRİK YAPILAR VE AHŞAP TAŞINABİLİR EVLERİN İMALATI"
    },
    {
        "kod": "162390",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ İNŞAAT DOĞRAMA VE MARANGOZLUK ÜRÜNLERİ (AHŞAPTAN KİRİŞ, KALAS, PAYANDA, BETON KALIBI, ÇATI PADAVRASI, VB.) İMALATI"
    },
    {
        "kod": "162401",
        "tanim": "KUTU, SANDIK, FIÇI VE BENZERİ AHŞAP AMBALAJ MALZEME İMALATI"
    },
    {
        "kod": "162402",
        "tanim": "PALET, KUTU PALET VE DİĞER AHŞAP YÜKLEME TABLALARI İMALATI"
    },
    {
        "kod": "162403",
        "tanim": "AHŞAP KABLO MAKARASI, BOBİN, TAKOZ, VB. İMALATI"
    },
    {
        "kod": "162901",
        "tanim": "AHŞAP MUTFAK VE SOFRA EŞYASI İMALATI (KAŞIK, KEPÇE, SPATULA, BARDAK, HAVAN, HAVAN ELİ, TEPSİ VB.)"
    },
    {
        "kod": "162902",
        "tanim": "DOĞAL MANTAR (KABACA KÖŞELENDİRİLMİŞ VEYA BLOK, LEVHA VB. HALDE), EZİLMİŞ VEYA GRANÜL HALİNE GETİRİLMİŞ MANTAR İLE DOĞAL MANTAR VEYA AGLOMERA MANTAR ÜRÜNLERİNİN İMALATI (MANTARDAN YER DÖŞEMELERİ, MAKARA, TIPA VE TIKAÇ DAHİL)"
    },
    {
        "kod": "162903",
        "tanim": "SEDEF KAKMA AHŞAP İŞLERİ, KAKMA İLE SÜSLÜ AHŞAP EŞYALAR, MÜCEVHER İÇİN VEYA ÇATAL-KAŞIK TAKIMI VE BENZERİ EŞYALAR İÇİN AHŞAP KUTULAR, AHŞAP BİBLO, HEYKEL VE DİĞER SÜSLERİN İMALATI "
    },
    {
        "kod": "162904",
        "tanim": "AHŞAPTAN İŞ ALETLERİ, ALET GÖVDELERİ, ALET SAPLARI, SÜPÜRGE VEYA FIRÇA GÖVDELERİ İLE SAPLARI, AYAKKABI KALIPLARI, AHŞAP MANDAL, ELBİSE VE ŞAPKA ASKILARI İMALATI"
    },
    {
        "kod": "162905",
        "tanim": "AHŞAP ÇERÇEVE (TABLO, FOTOĞRAF, AYNA VE BENZERİ NESNELER İÇİN) VE AHŞAPTAN DİĞER EŞYALARIN İMALATI (PANOLAR, TUVAL İÇİN ÇERÇEVELER, İP VB. İÇİN MAKARALAR, AYAKKABININ AHŞAP TOPUK VE TABANLARI, ARI KOVANLARI, KÖPEK KULÜBELERİ DAHİL)"
    },
    {
        "kod": "162907",
        "tanim": "HASIR VEYA DİĞER ÖRME MALZEMESİNDEN (KAMIŞ, SAZ, SAMAN VB.) EŞYALARIN İMALATI İLE SEPET TÜRÜ VE HASIR İŞİ EŞYALARIN İMALATI"
    },
    {
        "kod": "162990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER AĞAÇ ÜRÜNLERİ İLE ENERJİ İÇİN YAKIT KÜTÜKLERİ VE PELETLERİNİN İMALATI (KARBONLAŞTIRILMAMIŞ OLANLAR)"
    },
    {
        "kod": "171108",
        "tanim": "KAĞIT HAMURU İMALATI"
    },
    {
        "kod": "171207",
        "tanim": "KAĞIT VE MUKAVVA İMALATI (DAHA İLERİ SANAYİ İŞLEMLERİ İÇİN RULO VEYA TABAKA HALİNDE) (ZİFTLİ, LAMİNE, KAPLANMIŞ VE EMPRENYE EDİLMİŞ OLANLAR İLE KREPON VE KIRIŞIK KAĞITLAR DAHİL)"
    },
    {
        "kod": "172110",
        "tanim": "BÜROLARDA, DÜKKANLARDA VE BENZERİ YERLERDE KULLANILAN KAĞIT EVRAK TASNİF KUTULARI, MEKTUP KUTULARI VE BENZERİ EŞYALARIN İMALATI"
    },
    {
        "kod": "172111",
        "tanim": "KAĞIT VE KARTONDAN TORBA VE ÇANTA İMALATI (KESE KAĞIDI DAHİL)"
    },
    {
        "kod": "172112",
        "tanim": "KAĞIT VEYA MUKAVVADAN KOLİ, KUTU VE BENZERİ MUHAFAZALARIN İMALATI"
    },
    {
        "kod": "172113",
        "tanim": "OLUKLU KAĞIT VE OLUKLU MUKAVVA İMALATI (RULO VEYA TABAKA HALİNDE)"
    },
    {
        "kod": "172202",
        "tanim": "KULLANIMA HAZIR TUVALET KAĞIDI, KAĞIT MENDİL, TEMİZLİK VEYA YÜZ TEMİZLEME İÇİN KAĞIT MENDİL VE HAVLULAR İLE MASA ÖRTÜSÜ VE PEÇETELERİN İMALATI (KAĞIT HAMURUNDAN, KAĞITTAN, SELÜLOZ VATKADAN VEYA SELÜLOZ LİFLİ AĞLARDAN YAPILMIŞ)"
    },
    {
        "kod": "172203",
        "tanim": "KAĞIT VEYA MUKAVVADAN YAPILMIŞ TEPSİ, TABAK, KASE, BARDAK VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "172204",
        "tanim": "HİJYENİK HAVLU VE TAMPONLAR, KADIN BAĞI, PEDLER, BEBEK BEZLERİ VB. HİJYENİK ÜRÜNLER İLE GİYİM EŞYASI VE GİYSİ AKSESUARLARININ İMALATI (KAĞIT HAMURUNDAN, KAĞITTAN, SELÜLOZ VATKADAN VEYA SELÜLOZ LİFLİ AĞLARDAN YAPILMIŞ)"
    },
    {
        "kod": "172304",
        "tanim": "KULLANIMA HAZIR KARBON KAĞIDI, KENDİNDEN KOPYALI KAĞIT VE DİĞER KOPYALAMA VEYA TRANSFER KAĞITLARI, MUMLU TEKSİR KAĞIDI, KAĞITTAN OFSET TABAKALAR İLE TUTKALLI VEYA YAPIŞKANLI KAĞITLARIN İMALATI"
    },
    {
        "kod": "172306",
        "tanim": "KAĞIT VEYA MUKAVVADAN ANA NİTELİĞİ BİLGİ İÇERMEYEN EĞİTİM VE TİCARİ KIRTASİYE MALZEMELERİ İMALATI (AJANDALAR, DEFTERLER, SİCİL DEFTERLERİ, MUHASEBE DEFTERLERİ, CİLTLER, KAYIT FORMLARI VE DİĞER BENZERİ KIRTASİYE ÜRÜNLERİ)"
    },
    {
        "kod": "172307",
        "tanim": "KAĞIT VEYA MUKAVVADAN DOSYA, PORTFÖY DOSYA, KLASÖR VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "172308",
        "tanim": "KULLANIMA HAZIR BASIM VE YAZIM KAĞIDI İLE DİĞER KAĞIT VE MUKAVVALARIN İMALATI (BASILI OLANLAR HARİÇ)"
    },
    {
        "kod": "172309",
        "tanim": "BASKISIZ ZARF, MEKTUP KARTI, YAZIŞMA KARTI VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "172402",
        "tanim": "DUVAR KAĞIDI VE BENZERİ DUVAR KAPLAMALARININ İMALATI (TEKSTİL DUVAR KAPLAMALARI HARİÇ)"
    },
    {
        "kod": "172403",
        "tanim": "TEKSTİL DUVAR KAPLAMALARININ İMALATI"
    },
    {
        "kod": "172901",
        "tanim": "KAĞIT VEYA MUKAVVADAN ETİKETLERİN İMALATI"
    },
    {
        "kod": "172902",
        "tanim": "FİLTRE KAĞIDI, KARTONLARI VE MUKAVVALARI, KAĞIT HAMURUNDAN FİLTRE EDİCİ BLOK VE LEVHALAR İLE KALIPLANMIŞ YA DA SIKIŞTIRILMIŞ EŞYALARIN İMALATI (KAĞIT VEYA KARTON ESASLI CONTALAR VE RONDELALAR DAHİL)"
    },
    {
        "kod": "172903",
        "tanim": "SİGARA KAĞIDI, KAĞIT VE MUKAVVADAN BOBİN, MAKARA, MASURA, YUMURTA VİYOLÜ VE BENZERİ KAĞIT, MUKAVVA VEYA KAĞIT HAMURUNDAN DESTEKLER İLE KAĞITTAN HEDİYELİK VE SÜS EŞYALARI İMALATI"
    },
    {
        "kod": "172904",
        "tanim": "JAKAR MAKİNELERİNDE KULLANMAK İÇİN KAĞIT VE MUKAVVADAN KARTLAR İLE KAYDEDİCİ CİHAZLARA MAHSUS DİYAGRAM KAĞITLARI İMALATI (BOBİN, TABAKA/DİSK HALİNDE)"
    },
    {
        "kod": "181101",
        "tanim": "GAZETELERİN, DERGİLERİN VE SÜRELİ YAYINLARIN BASIM HİZMETLERİ (HAFTADA DÖRT VEYA DAHA FAZLA YAYINLANANLAR)"
    },
    {
        "kod": "181201",
        "tanim": "ÇIKARTMA, TAKVİM, TİCARİ KATALOG, TANITIM BROŞÜRÜ, POSTER, SATIŞ BÜLTENİ, KARTPOSTAL, DAVETİYE VE TEBRİK KARTLARI, YILLIK, REHBER, RESİM, ÇİZİM VE BOYAMA KİTAPLARI, ÇİZGİ ROMAN VB. BASIM HİZMETLERİ"
    },
    {
        "kod": "181202",
        "tanim": "GAZETELERİN, DERGİLERİN VE SÜRELİ YAYINLARIN BASIM HİZMETLERİ (HAFTADA DÖRT KEREDEN DAHA AZ YAYINLANANLAR)"
    },
    {
        "kod": "181203",
        "tanim": "ANSİKLOPEDİ, SÖZLÜK, KİTAP, KİTAPÇIK, MÜZİK ESERLERİ VE MÜZİK EL YAZMALARI, ATLAS, HARİTA VB. BASIM HİZMETLERİ"
    },
    {
        "kod": "181204",
        "tanim": "RÖPRODÜKSİYON BASIMI (BİR SANAT ESERİNİN ASLINI BOZMADAN BASILMASI)"
    },
    {
        "kod": "181205",
        "tanim": "SERİGRAFİ FAALİYETLERİ"
    },
    {
        "kod": "181206",
        "tanim": "POSTA PULU, DAMGA PULU, MATBU BELGELER, TAPU SENETLERİ, AKILLI KART, ÇEK DEFTERLERİ, KAĞIT PARA VE DİĞER DEĞERLİ KAĞITLARIN VE BENZERLERİNİN BASIM HİZMETLERİ"
    },
    {
        "kod": "181207",
        "tanim": "PLASTİK, CAM, METAL, AĞAÇ VE SERAMİK ÜSTÜNE BASKI HİZMETLERİ"
    },
    {
        "kod": "181301",
        "tanim": "BASIMDA KULLANMAK ÜZERE BASKI KLİŞELERİ YA DA SİLİNDİRLERİ İLE DİĞER BASIM UNSURLARININ ÜRETİLMESİ (KLİŞECİLİK VB.) İLE MİZANPAJ, DİZGİ, TABAKA YAPIM HİZMETLERİ, GRAVÜR BASKI İÇİN SİLİNDİRLERİN KAZINMASI VEYA ASİTLE AŞINDIRILMASI VB. HİZMETLER"
    },
    {
        "kod": "181302",
        "tanim": "BASIM ÖNCESİ BİLGİSAYAR DESTEKLİ HİZMETLER (BİLGİSAYAR DESTEKLİ SAYFA TASARIMI İLE SAYDAM, ASETAT, REPROGRAFİK SUNUM ARAÇLARI VE DİĞER SAYISAL SUNUM ORTAMLARI, TASLAKLAR, PLANLAR VB. BASKI ÜRÜNLERİNİN TASARLANMASI) (MASA ÜSTÜ YAYIMCILIK DAHİL)"
    },
    {
        "kod": "181401",
        "tanim": "CİLTÇİLİK VE İLGİLİ HİZMETLER/MÜCELLİTLİK (KATLAMA, BİRLEŞTİRME, DİKME, YAPIŞTIRMA, KESME, KAPAK TAKMA GİBİ İŞLEMLER İLE DAMGALAMA, BRAİLLE ALFABESİ KOPYALAMA VB. HİZMETLER)"
    },
    {
        "kod": "182002",
        "tanim": "SES VE GÖRÜNTÜ KAYITLARININ ÇOĞALTILMASI HİZMETLERİ (CD'LERİN, DVD'LERİN, KASETLERİN VE BENZERLERİNİN ASIL (MASTER) KOPYALARINDAN ÇOĞALTILMASI)"
    },
    {
        "kod": "182003",
        "tanim": "YAZILIMLARIN ÇOĞALTILMASI HİZMETLERİ (CD, KASET VB. ORTAMLARDAKİ BİLGİSAYAR YAZILIMLARININ VE VERİLERİN ASIL (MASTER) KOPYALARINDAN ÇOĞALTILMASI)"
    },
    {
        "kod": "191010",
        "tanim": "LİNYİT VE TURBADAN KOK FIRINI ÜRÜNLERİNİN İMALATI (KOK VE YARI KOK KÖMÜRÜ, KARNİ KÖMÜRÜ, KATRAN, ZİFT VE ZİFT KOKU VB. ÜRÜNLERİN İMALATI İLE KOK KÖMÜRÜNÜN TOPAK HALİNE GETİRİLMESİ DAHİL)"
    },
    {
        "kod": "191011",
        "tanim": "TAŞKÖMÜRÜNDEN KOK FIRINI ÜRÜNLERİNİN İMALATI (KOK VE YARI KOK KÖMÜRÜ, KARNİ KÖMÜRÜ, KATRAN, ZİFT VE ZİFT KOKU VB. ÜRÜNLERİN İMALATI İLE KOK KÖMÜRÜNÜN TOPAK HALİNE GETİRİLMESİ DAHİL)"
    },
    {
        "kod": "192012",
        "tanim": "TURBA, LİNYİT VE TAŞ KÖMÜRÜ BRİKETLERİ İMALATI (KÖMÜR TOZUNDAN BASINÇLA ELDE EDİLEN YAKIT)"
    },
    {
        "kod": "192015",
        "tanim": "PETROL TÜREVİ YAKITLARIN,  PETROL GAZLARI VE DİĞER HİDROKARBONLARIN İMALATI"
    },
    {
        "kod": "192016",
        "tanim": "PETROLDEN MADENİ YAĞLARIN (YAĞLAMA VE MAKİNE YAĞLARI) İMALATI (GRES YAĞI DAHİL)"
    },
    {
        "kod": "192017",
        "tanim": "VAZELİN, PARAFİN MUMU, PETROL MUMU, PETROL KOKU, PETROL BİTÜMENİ VE DİĞER PETROL ÜRÜNLERİNİN İMALATI"
    },
    {
        "kod": "192019",
        "tanim": "AĞIRLIK İTİBARİYLE %70 VEYA DAHA FAZLA ORANDA PETROL YAĞLARI VEYA BİTÜMENLİ YAĞLARDAN ELDE EDİLEN DİĞER KARIŞIMLARIN ÜRETİMİ (%70 PETROL YAĞI İLE KARIŞTIRILMIŞ BİYODİZELDEN ÜRÜNLER DAHİL, MADENİ YAĞLAR HARİÇ)"
    },
    {
        "kod": "201101",
        "tanim": "SANAYİ GAZLARI İMALATI (HİDROJEN, ASAL GAZLAR, AZOT, OKSİJEN, KARBONDİOKSİT VE AMETALLERİN DİĞER İNORGANİK OKSİJEN BİLEŞİKLERİ, SOĞUTUCU-DONDURUCU GAZLAR İLE HAVA GİBİ SIVI VEYA SIKIŞTIRILMIŞ İNORGANİK SANAYİ GAZLARI VE TIBBİ GAZLAR)"
    },
    {
        "kod": "201201",
        "tanim": "BOYA MADDELERİ VE PİGMENT İMALATI (BİRİNCİL FORMDA VEYA KONSANTRE OLARAK HERHANGİ BİR KAYNAKTAN) (HAZIR BOYALAR HARİÇ)"
    },
    {
        "kod": "201202",
        "tanim": "TABAKLAMA EKSTRELERİ, BİTKİSEL KÖKENLİ; TANENLER VE TUZLARI, ETERLERİ, ESTERLERİ VE DİĞER TÜREVLERİ; BİTKİSEL VEYA HAYVANSAL KÖKENLİ   RENKLENDİRME MADDELERİNİN İMALATI"
    },
    {
        "kod": "201302",
        "tanim": "METALİK HALOJENLER, HİPOKLORİTLER, KLORATLAR VE PERKLORATLARIN İMALATI (ÇAMAŞIR SUYU DAHİL)"
    },
    {
        "kod": "201303",
        "tanim": "SÜLFİDLER (SÜLFÜRLER), SÜLFATLAR, FOSFİNATLAR, FOSFONATLAR, FOSFATLAR VE NİTRATLARIN İMALATI (ŞAP DAHİL)"
    },
    {
        "kod": "201304",
        "tanim": "KARBONATLARIN İMALATI (SODYUM, KALSİYUM VE DİĞERLERİ) (ÇAMAŞIR SODASI DAHİL)"
    },
    {
        "kod": "201306",
        "tanim": "URANYUM, PLÜTONYUM VE TORYUM CEVHERLERİNİN ZENGİNLEŞTİRİLMESİ (NÜKLEER REAKTÖRLER İÇİN YAKIT KARTUŞLARI DAHİL)"
    },
    {
        "kod": "201307",
        "tanim": "DİĞER METAL TUZLARI VE TEMEL İNORGANİK KİMYASALLARIN İMALATI (İZOTOPLAR VE BUNLARIN BİLEŞİKLERİ, OKSOMETALİK/PEROKSOMETALİK ASİTLERİN TUZLARI, SİYANÜRLER, BORATLAR, HİDROJEN PEROKSİT, KÜKÜRT, KAVRULMUŞ DEMİR PİRİTLER, PİEZO-ELEKTRİK KUVARSI VB.)"
    },
    {
        "kod": "201390",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ KİMYASAL ELEMENTLER, İNORGANİK ASİTLER VE BİLEŞİKLERİN İMALATI (KLOR, İYOT, FLOR, BOR, SİLİSYUM, FOSFOR, ARSENİK GİBİ METALOİDLER, SKANDİUM, CIVA, OKSİTLER, HİDROKSİTLER, HİDROJEN KLORÜR VB.)"
    },
    {
        "kod": "201401",
        "tanim": "TEMEL ORGANİK KİMYASALLARIN İMALATI (HİDROKARBONLAR, ALKOLLER, ASİTLER, ALDEHİTLER, KETONLAR, SENTETİK GLİSERİN, AZOT FONKSİYONLU BİLEŞİKLER VB.) (ETİL ALKOL, SİTRİK ASİT DAHİL)"
    },
    {
        "kod": "201404",
        "tanim": "ODUNUN VE KÖMÜR KATRANININ DAMITILMASI (ODUN KREOZOTU, ODUN NAFTASI, BİTKİSEL ZİFT, BENZOL, TOLUOL, FENOL, NAFTALİN VB.)"
    },
    {
        "kod": "201405",
        "tanim": "TABİİ REÇİNE VE TÜREVLERİ, KOLOFANLAR,  KOLOFANIN MODİFİKASYON VE ESTERİFİKASYON ÜRÜNLERİ,   ÇAM TEREBENTİN VE ÇAM TEREBENTİN ESANSLARI İLE DOĞAL ZAMK İMALATI"
    },
    {
        "kod": "201501",
        "tanim": "FOSFATLI VEYA POTASYUMLU GÜBRELER, İKİ (AZOT VE FOSFOR VEYA FOSFOR VE POTASYUM) VEYA ÜÇ BESİN MADDESİ (AZOT, FOSFOR VE POTASYUM) İÇEREN GÜBRELER, SODYUM NİTRAT İLE DİĞER KİMYASAL VE MİNERAL GÜBRELERİN İMALATI"
    },
    {
        "kod": "201502",
        "tanim": "BİLEŞİK AZOTLU ÜRÜNLERİN İMALATI (NİTRİK ASİT, SÜLFONİTRİK ASİT, SAF AMONYAK, AMONYUM KLORÜR (NİŞADIR), AMONYUM KARBONAT, NİTRİTLER, POTASYUM NİTRATLAR VB.) (GÜBRELER HARİÇ)"
    },
    {
        "kod": "201601",
        "tanim": "BİRİNCİL FORMDA POLİAMİTLER, ÜRE REÇİNELERİ, MELAMİN REÇİNELERİ, VB. PLASTİK HAMMADDELERİN İMALATI"
    },
    {
        "kod": "201602",
        "tanim": "BİRİNCİL FORMDA ALKİD REÇİNE, POLYESTER REÇİNE, EPOKSİ REÇİNE, POLİASETAL, POLİKARBONAT İLE DİĞER POLİETER VE POLYESTER İMALATI"
    },
    {
        "kod": "201603",
        "tanim": "BİRİNCİL FORMDA POLİMERLERİN İMALATI (ETİLEN, PROPİLEN, STİREN, VİNİL KLORÜR, VİNİL ASETAT, VİNİL ESTERLERİ, AKRİLİK VB. POLİMERLERİ İLE SERTLEŞTİRİLMİŞ PROTEİNLER, DOĞAL KAUÇUĞUN KİMYASAL TÜREVLERİ DAHİL)"
    },
    {
        "kod": "201604",
        "tanim": "BİRİNCİL FORMDA SİLİKON VE POLİMER ESASLI İYON DEĞİŞTİRİCİLERİ İMALATI"
    },
    {
        "kod": "201605",
        "tanim": "BİRİNCİL FORMDA DİĞER AMİNO REÇİNELER, FENOLİK REÇİNELER, POLİÜRETANLAR, POLİTERPENLER, POLİSÜLFÜRLER, SELÜLOZ VE KİMYASAL TÜREVLERİ İLE DİĞER PETROL REÇİNELERİ İMALATI"
    },
    {
        "kod": "201701",
        "tanim": "BİRİNCİL FORMDA SENTETİK KAUÇUK İMALATI"
    },
    {
        "kod": "202011",
        "tanim": "BÖCEK İLACI, KEMİRGEN İLACI, KÜF VE MANTAR İLACI, YABANCI OTLA MÜCADELE İLACI İMALATI"
    },
    {
        "kod": "202012",
        "tanim": "DEZENFEKTAN İMALATI (TARIMSAL VE DİĞER KULLANIMLAR İÇİN) (HİJYENİK MADDELER, BAKTERİOSTATLAR VE STERİLİZE EDİCİLER DAHİL)"
    },
    {
        "kod": "202013",
        "tanim": "ÇİMLENMEYİ ÖNLEYİCİ VE BİTKİ GELİŞİMİNİ DÜZENLEYİCİ ÜRÜN İMALATI"
    },
    {
        "kod": "202014",
        "tanim": "DİĞER ZİRAİ KİMYASAL ÜRÜNLERİN İMALATI (GÜBRE VE AZOTLU BİLEŞİK İMALATI HARİÇ)"
    },
    {
        "kod": "203011",
        "tanim": "BOYA VE VERNİKLER, AKRİLİK VE VİNİL POLİMER ESASLI OLANLARIN (SULU ORTAMDA DAĞILANLAR, ÇÖZÜLENLER VE ÇÖZELTİLER) İMALATI"
    },
    {
        "kod": "203012",
        "tanim": "MACUN İMALATI (DOLGU, CAM, SIVAMA İÇİN OLANLAR İLE ÜSTÜBEÇ, VB. DAHİL)"
    },
    {
        "kod": "203013",
        "tanim": "DİĞER BOYA, VERNİK VE İLGİLİ ÜRÜNLERİN İMALATI. (RENK AYARLAYICILAR, MATBAA MÜREKKEPLERİ, SOLVENTLER, İNCELTİCİLER (TİNER))"
    },
    {
        "kod": "203014",
        "tanim": "BOYA VE VERNİKLER, POLYESTER, AKRİLİK VE VİNİL POLİMER ESASLI OLANLARIN (SUSUZ ORTAMDA DAĞILANLAR, ÇÖZÜLENLER VE ÇÖZELTİLER) İMALATI"
    },
    {
        "kod": "203015",
        "tanim": "HAZIR BOYA PİGMENTLERİ, MATLAŞTIRICILAR (OPAKLAŞTIRICI) VE RENKLENDİRİCİLER, CAMLAŞTIRILABİLİR EMAY VE SIRLAR, ASTARLAR, CAM FİRİT, SIVI CİLALAR VE BENZERLERİNİN İMALATI "
    },
    {
        "kod": "203016",
        "tanim": "BOYA MÜSTAHZARLARI HAZIR KURUTUCU MADDELERİNİN İMALATI"
    },
    {
        "kod": "203017",
        "tanim": "ELEKTROSTATİK TOZ BOYA İMALATI"
    },
    {
        "kod": "204101",
        "tanim": "KAPALI ALANLAR İÇİN KOKULU MÜSTAHZARLAR VE KOKU GİDERİCİLER İLE SUNİ MUMLARIN İMALATI (KİŞİSEL KULLANIM İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "204103",
        "tanim": "HAM GLİSERİN (GLİSEROL) İMALATI"
    },
    {
        "kod": "204104",
        "tanim": "SABUN, YIKAMA VE TEMİZLEME MÜSTAHZARLARI (DETERJANLAR) İLE SABUN OLARAK KULLANILAN MÜSTAHZARLAR İMALATI (KİŞİSEL BAKIM İÇİN OLANLAR İLE OVALAMA TOZ VE KREMLERİ HARİÇ)"
    },
    {
        "kod": "204106",
        "tanim": "CİLA, KREM VE OVALAMA KREM VE TOZLARININ İMALATI (AYAKKABI, MOBİLYA, YER DÖŞEMESİ, KAPORTA, CAM, METAL VB. İÇİN)"
    },
    {
        "kod": "204201",
        "tanim": "AĞIZ VEYA DİŞ BAKIM ÜRÜNLERİ İMALATI (DİŞ MACUNU, VB. İLE TAKMA DİŞLERİ AĞIZDA SABİT TUTMAYA YARAYAN MACUN VE TOZLAR İLE DİŞ TEMİZLEME İPLİKLERİ DAHİL)"
    },
    {
        "kod": "204202",
        "tanim": "KOLONYA İMALATI"
    },
    {
        "kod": "204203",
        "tanim": "PARFÜM VE KOKU VERİCİ DİĞER SIVI ÜRÜN, MANİKÜR/PEDİKÜR MÜSTAHZARI, GÜNEŞ KORUYUCU ÜRÜNLER, DUDAK VE GÖZ MAKYAJI ÜRÜNÜ, BANYO TUZU, KOZMETİK VEYA KİŞİSEL BAKIM AMAÇLI PUDRA, SABUN VE ORGANİK YÜZEY AKTİF MÜSTAHZARI, DEODORANT, VB. İMALATI (KOLONYA HARİÇ)"
    },
    {
        "kod": "204204",
        "tanim": "ŞAMPUAN, SAÇ KREMİ, SAÇ SPREYİ, JÖLE, SAÇ DÜZLEŞTİRME VE PERMA ÜRÜNLERİ, SAÇ LOSYONLARI, SAÇ BOYALARI, VB. İMALATI"
    },
    {
        "kod": "205121",
        "tanim": "BARUT, VB. İTİCİ TOZLARIN İMALATI"
    },
    {
        "kod": "205122",
        "tanim": "HAZIR PATLAYICILAR, EMNİYET FİTİLLERİ, ÇARPMA KAPSÜLLERİ, İNFİLAK FİTİLLERİ, ATEŞLEYİCİLER, DİNAMİT, ELEKTRİKLİ KAPSÜLLER, HAVAİ FİŞEKLER, SİS İŞARETLERİ, İŞARET FİŞEKLERİ, VB. PATLAYICI VEYA PİROTEKNİK MALZEME İMALATI (BARUT HARİÇ)"
    },
    {
        "kod": "205123",
        "tanim": "KİBRİT İMALATI"
    },
    {
        "kod": "205205",
        "tanim": "TUTKAL İMALATI (KAZEİN ESASLI, HAYVANSAL ESASLI, NİŞASTA ESASLI, KAUÇUK ESASLI, PLASTİK ESASLI, POLİMER ESASLI VB. OLANLAR)"
    },
    {
        "kod": "205302",
        "tanim": "UÇUCU YAĞLARIN İMALATI"
    },
    {
        "kod": "205901",
        "tanim": "FOTOGRAFİK LEVHA VE FİLMLERİN (HASSASLAŞTIRILMIŞ, IŞIĞA MARUZ KALMAMIŞ OLANLAR), ANINDA BASKILANAN FİLMLERİN, FOTOĞRAFÇILIKTA KULLANILAN KİMYASAL MÜSTAHZARLARIN VE KARIŞIMSIZ (SAF) ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "205903",
        "tanim": "AKTİF KARBON İMALATI"
    },
    {
        "kod": "205904",
        "tanim": "YAĞLAMA MÜSTAHZARLARI (HİDROLİK FREN SIVILARI DAHİL), VURUNTU ÖNLEYİCİ MÜSTAHZARLAR İLE KATKI MADDELERİ VE ANTİFRİZLERİN İMALATI"
    },
    {
        "kod": "205905",
        "tanim": "YAZIM VE ÇİZİM MÜREKKEPLERİ VE DİĞER MÜREKKEPLERİN İMALATI (MATBAA MÜREKKEBİ İMALATI HARİÇ)"
    },
    {
        "kod": "205906",
        "tanim": "PEPTONLAR, DİĞER PROTEİN MADDELERİ VE BUNLARIN TÜREVLERİNİN VE DERİ TOZLARININ İMALATI"
    },
    {
        "kod": "205907",
        "tanim": "LABORATUVAR İÇİN HAZIR KÜLTÜR ORTAMLARI, MODEL HAMURLARI, KOMPOZİT DİYAGNOSTİK REAKTİFLER VEYA LABORATUVAR REAKTİFLERİ İMALATI"
    },
    {
        "kod": "205908",
        "tanim": "ELEKTRONİKTE KULLANILAN MACUN KIVAMINDA (DOPE EDİLMİŞ) OLAN KİMYASAL ELEMENTLER İLE BİLEŞİKLERİN İMALATI"
    },
    {
        "kod": "205909",
        "tanim": "BİTİRME (APRELEME DAHİL) MADDELERİ, BOYA HAMMADDESİ VE BENZERİ ÜRÜNLERİN SABİTLENMESİNİ VEYA BOYAYICILIĞINI HIZLANDIRAN BOYA TAŞIYICI MADDELERİN İMALATI"
    },
    {
        "kod": "205910",
        "tanim": "DEKAPAJ (TEMİZLEME) MÜSTAHZARLARI, ERİTKENLER, HAZIR VULKANİZASYON HIZLANDIRICI MADDELER, KAUÇUK VEYA PLASTİKLER İÇİN PLASTİKLEŞTİRİCİ BİLEŞİKLER VE STABİLİZATÖRLER, BAŞKA YERDE SINIFLANDIRILMAMIŞ KATALİTİK MÜSTAHZARLAR İMALATI"
    },
    {
        "kod": "205911",
        "tanim": "JELATİN VE JELATİN TÜREVLERİ İLE SÜT ALBÜMİNLERİNİN İMALATI. (GIDA ENDÜSTRİSİNDE KULLANILAN JELATİNLER VE SÜT ALBÜMİNLERİ HARİÇ)"
    },
    {
        "kod": "205912",
        "tanim": "KİMYASAL OLARAK DEĞİŞTİRİLMİŞ VEYA YENİLEMEYEN HAYVANSAL VEYA BİTKİSEL KATI VE SIVI YAĞLAR VE YAĞ KARIŞIMLARININ İMALATI (LİNOKSİN, TEKNİK VE SANAYİ AMAÇLI BİTKİSEL SABİT SIVI YAĞLAR, SANAYİDE KULLANILAN SIVI YAĞLAR, VB.)"
    },
    {
        "kod": "205913",
        "tanim": "BİYODİZEL, VB. BİYOYAKIT İMALATI (BİTKİSEL VEYA HAYVANSAL YAĞLARDAN ELDE EDİLEN UZUN ZİNCİRLİ YAĞ ASİTLERİNİN MONO ALKİL ESTERLERİ) (%70 VEYA DAHA FAZLA PETROL YAĞI İLE KARIŞTIRILMIŞ BİYODİZELDEN ÜRÜNLER HARİÇ)"
    },
    {
        "kod": "205914",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER KİMYASAL ÜRÜNLERİN İMALATI (VAKUM TÜPLERİ İÇİN EMİCİLER, PİROLİNYİTLER, KAZAN TAŞI ÖNLEYİCİ BİLEŞİKLER, YAĞ EMÜLSİYONLAŞTIRICILARI, DÖKÜMHANELERDE KULLANILAN YARDIMCI KİMYASAL ÜRÜNLER VE  HAZIR BAĞLAYICILAR, VB.)"
    },
    {
        "kod": "205915",
        "tanim": "YANGIN SÖNDÜRÜCÜ MÜSTAHZARLARI VE DOLUM MALZEMELERİ İMALATI"
    },
    {
        "kod": "205916",
        "tanim": "JELATİN VE SÜT ALBÜMİNLERİNİN İMALATI. (YALNIZCA GIDA ENDÜSTRİSİNDE KULLANILANLAR)"
    },
    {
        "kod": "206001",
        "tanim": "KARDELENMEMİŞ VE TARANMAMIŞ SUNİ VE SENTETİK ELYAF İMALATI"
    },
    {
        "kod": "206002",
        "tanim": "SENTETİK FİLAMENT İPLİĞİ VE SENTETİK MONOFİLAMENTLERİN, ŞERİTLERİN VE BENZERLERİNİN İMALATI (POLİAMİDDEN VE POLYESTERDEN YÜKSEK MUKAVEMETLİ FİLAMENT İPLİKLER DAHİL) (BÜKÜLÜ, KATLI VE TEKSTÜRİZE OLANLAR HARİÇ)"
    },
    {
        "kod": "211001",
        "tanim": "TEMEL ECZACILIK ÜRÜNLERİNİN İMALATI (ANTİBİYOTİK, VİTAMİN, SALİSİLİK ASİT GİBİ İLAÇLARIN İMALATINDA FARMAKOLOJİK ÖZELLİKLERİNDEN YARARLANMAK ÜZERE TIBBİ OLARAK ETKEN MADDELER İLE KAN ÜRÜNLERİNİN, SALGI BEZİ VE EKSTRELERİN, HORMONLARIN VB. İMALATI)"
    },
    {
        "kod": "212001",
        "tanim": "ECZACILIĞA İLİŞKİN TIBBİ İLAÇLARIN İMALATI (ANTİBİYOTİK İÇEREN TIBBİ İLAÇLAR, AĞRI KESİCİLER, HORMON İÇEREN TIBBİ İLAÇLAR VB.)"
    },
    {
        "kod": "212002",
        "tanim": "YAPIŞKANLI BANDAJLAR, KATKÜTLER VE BENZERİ TIBBİ MALZEMELERİN ÜRETİMİ (STERİL CERRAHİ KATGÜTLER, ECZACILIK MADDELERİ İLE BİRLİKTE KULLANILAN TAMPONLAR, HİDROFİL PAMUK, GAZLI BEZ, SARGI BEZİ VB.)"
    },
    {
        "kod": "212003",
        "tanim": "HAYVAN SAĞLIĞINA İLİŞKİN TIBBİ İLAÇLARIN İMALATI"
    },
    {
        "kod": "212004",
        "tanim": "DİĞER ECZACILIK MÜSTAHZARLARININ İMALATI (ANTİSERUMLAR, PANZEHİRLER, AŞILAR, HORMON VE SPERMİSİT ESASLI KİMYASAL KONTRASEPTİK MÜSTAHZARLAR, DİYAGNOSTİK REAKTİFLERİ VE DİĞER ECZACILIK MÜSTAHZARLARI) (HAYVAN SAĞLIĞI İÇİN OLANLAR DAHİL)"
    },
    {
        "kod": "221117",
        "tanim": "KAUÇUKTAN İÇ LASTİKLERİN İMALATI (DIŞ LASTİKLER İÇİN DEĞİŞEBİLİR SIRTLAR, KOLONLAR VE ŞERİTLERİN İMALATI DAHİL)"
    },
    {
        "kod": "221118",
        "tanim": "KAUÇUKTAN DIŞ LASTİK İMALATI (MOTOSİKLETLER, BİSİKLETLER, OTOMOBİLLER, OTOBÜSLER, KAMYONLAR, HAVA TAŞITLARI, TRAKTÖRLER VE DİĞER ARAÇ VE DONANIMLAR İÇİN) (DOLGU VEYA ALÇAK BASINÇLI LASTİKLER DAHİL)"
    },
    {
        "kod": "221119",
        "tanim": "LASTİK TEKERLEKLERİNİN YENİDEN İŞLENMESİ VE SIRT GEÇİRİLMESİ (LASTİĞİN KAPLANMASI)"
    },
    {
        "kod": "221901",
        "tanim": "KAUÇUKTAN HİJYENİK VE ECZACILIK ÜRÜNLERİNİN İMALATI (PREZERVATİFLER, EMZİKLER, HİJYENİK ELDİVENLER VB. DAHİL)"
    },
    {
        "kod": "221902",
        "tanim": "KAUÇUKTAN TÜP, BORU VE HORTUMLARIN İMALATI (VULKANİZE KAUÇUKTAN)"
    },
    {
        "kod": "221903",
        "tanim": "KAUÇUKTAN GİYİM EŞYASI VE GİYSİ AKSESUARLARININ İMALATI (GİYSİLER, ELDİVENLER VB.)"
    },
    {
        "kod": "221904",
        "tanim": "KAUÇUKTAN SÜPÜRGELERİN VE FIRÇALARIN İMALATI"
    },
    {
        "kod": "221905",
        "tanim": "KAUÇUK AYAKKABI/BOT TABANLARI VE AYAKKABI/BOTLARIN DİĞER KAUÇUK PARÇALARININ İMALATI"
    },
    {
        "kod": "221906",
        "tanim": "KAUÇUKTAN YER DÖŞEMELERİ VE PASPASLARIN İMALATI"
    },
    {
        "kod": "221907",
        "tanim": "KAUÇUK KAPLANMIŞ, EMDİRİLMİŞ, SIVANMIŞ VE LAMİNE EDİLMİŞ TEKSTİL KUMAŞLARININ İMALATI, ANA BİLEŞENİ KAUÇUK OLANLAR (KORD BEZİ HARİÇ)"
    },
    {
        "kod": "221908",
        "tanim": "KAUÇUKTAN PAKET LASTİĞİ, TÜTÜN KESESİ, CAM SİLECEKLERİ, TARİH ISTAMPALARI İÇİN KARAKTERLER, TAPALAR, LAVABO POMPALARI, ŞİŞELER İÇİN TIPA VE HALKALAR İLE SERT KAUÇUKTAN DİĞER ÇEŞİTLİ EŞYALARIN İMALATI"
    },
    {
        "kod": "221909",
        "tanim": "KAUÇUKTAN KONVEYÖR BANTLARI VE TAŞIMA KAYIŞLARININ İMALATI"
    },
    {
        "kod": "221910",
        "tanim": "REJENERE KAUÇUK İMALATI, BİRİNCİL FORMDA VEYA LEVHA, TABAKA VEYA ŞERİT HALİNDE"
    },
    {
        "kod": "221912",
        "tanim": "KAUÇUKTAN SİLGİ, RONDELA, CONTA, TEKNE VEYA İSKELE USTURMAÇALARI, GÖZENEKLİ VULKANİZE KAUÇUKTAN TEKNİK İŞLERDE KULLANILAN DİĞER EŞYALAR İLE DEMİRYOLU, KARA YOLU TAŞITLARI VE DİĞER ARAÇLAR İÇİN KALIPLANMIŞ PARÇALARIN İMALATI"
    },
    {
        "kod": "221913",
        "tanim": "VULKANİZE EDİLMİŞ (KÜKÜRTLE SERTLEŞTİRİLMİŞ) KAUÇUK İMALATI (İP, KORDON, LEVHA, TABAKA, ŞERİT, ÇUBUK VE PROFİL HALİNDE)"
    },
    {
        "kod": "222103",
        "tanim": "PLASTİKTEN MAMUL HALDE TÜP, BORU, HORTUM VE BUNLARIN BAĞLANTI ELEMANLARININ İMALATI (SUNİ BAĞIRSAKLAR DAHİL)"
    },
    {
        "kod": "222104",
        "tanim": "PLASTİKTEN YARI MAMUL HALDE PROFİL, ÇUBUK, TABAKA, LEVHA, BLOK, FİLM, FOLYO, ŞERİT, VB. İLE MONOFİLAMENT İMALATI (NAYLON BRANDALAR DAHİL)"
    },
    {
        "kod": "222243",
        "tanim": "PLASTİK POŞET, ÇÖP TORBASI, ÇANTA, TORBA, ÇUVAL, FİLE, SANDIK, KUTU, KASA, DAMACANA, ŞİŞE, BİDON, MAKARA, MASURA, BOBİN, TIPA, KAPAK, KAPSÜL VB. PAKETLEME MALZEMELERİNİN İMALATI (İDRAR TORBASI DAHİL)"
    },
    {
        "kod": "222303",
        "tanim": "PLASTİKTEN DEPO, TANK, FIÇI VE BENZERİ KAPLARIN İMALATI"
    },
    {
        "kod": "222304",
        "tanim": "PLASTİKTEN PREFABRİK YAPILARIN İMALATI"
    },
    {
        "kod": "222305",
        "tanim": "VİNİL, LİNOLYUM (MUŞAMBA) GİBİ ESNEK YER KAPLAMALARI İLE PLASTİK ZEMİN, DUVAR VE TAVAN KAPLAMALARININ İMALATI (DUVAR KAĞIDI HARİÇ)"
    },
    {
        "kod": "222306",
        "tanim": "PLASTİKTEN MERDİVEN, MERDİVEN KORKULUĞU, PANJUR, GÜNEŞLİK, JALUZİ, STOR, VB. EŞYA İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "222307",
        "tanim": "PLASTİKTEN BANYO KÜVETLERİ, LAVABOLAR, KLOZET KAPAKLARI, OTURAKLARI VE REZERVUARLARI İLE BENZERİ SIHHİ ÜRÜNLERİN İMALATI (KALICI TESİSAT İÇİN KULLANILAN MONTAJ VE BAĞLANTI PARÇALARI DAHİL)"
    },
    {
        "kod": "222308",
        "tanim": "PLASTİKTEN/PVC'DEN KAPI, PENCERE, BUNLARIN KASALARI, PERVAZLARI, KAPI EŞİKLERİ, VB. İMALATI"
    },
    {
        "kod": "222390",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ PLASTİK İNŞAAT MALZEMELERİNİN İMALATI (PLASTİK SUNİ TAŞ-MERMERİT İMALATI)"
    },
    {
        "kod": "222901",
        "tanim": "PLASTİKTEN SOFRA, MUTFAK, BANYODA KULLANILAN EŞYA (SİLİKON KEK KALIBI, LEĞEN, TAS, KOVA VB.) VE DİĞER EV EŞYASI İMALATI"
    },
    {
        "kod": "222902",
        "tanim": "PLASTİKTEN DİKİŞSİZ GİYİM EŞYASI VE GİYSİ AKSESUARLARININ İMALATI (ELDİVEN DAHİL)"
    },
    {
        "kod": "222903",
        "tanim": "PLASTİKTEN BÜRO VE OKUL MALZEMELERİNİN İMALATI"
    },
    {
        "kod": "222904",
        "tanim": "AYAKKABI VE TERLİKLERİN PLASTİK PARÇALARININ İMALATI (PLASTİK AYAKKABI KALIBI İMALATI DAHİL)"
    },
    {
        "kod": "222905",
        "tanim": "MAKİNE, MOBİLYA, KAPORTA, EL ALETLERİ VE BENZERLERİNİN PLASTİKTEN BAĞLANTI PARÇALARI, PLASTİKTEN TAŞIYICI BANTLARIN VE KONVEYÖR BANTLARININ İMALATI"
    },
    {
        "kod": "222906",
        "tanim": "PLASTİK BAŞLIK (KORUMA AMAÇLI OLANLAR HARİÇ), İZOLASYON BAĞLANTI PARÇALARI İLE LAMBALARIN, AYDINLATMA EKİPMANLARININ, IŞIKLI TABELALARIN, VB.NİN BAŞKA YERDE SINIFLANDIRILMAMIŞ PLASTİK KISIMLARININ İMALATI"
    },
    {
        "kod": "222907",
        "tanim": "PLASTİKTEN MANDAL, ASKI, SÜNGER, SABUNLUK, TARAK, BİGUDİ, TOKA, SAÇ FİRKETESİ, BONCUK, BİBLO, HEYKELCİK VE DİĞER EŞYALAR İLE MAMUL HALDEKİ KENDİNDEN YAPIŞKANLI LEVHA, ŞERİT VB. ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "222990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER PLASTİK ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "231101",
        "tanim": "LEVHA VEYA TABAKA HALİNDE DÜZ CAM İMALATI (TELLİ, BUZLU CAM, RENKLİ VEYA BOYALI DÜZ CAM DAHİL) (DÖKÜLMÜŞ, HADDELENMİŞ, ÇEKİLMİŞ, ÜFLENMİŞ, FLOAT, YÜZEYİ PARLATILMIŞ VEYA CİLALANMIŞ ANCAK BAŞKA ŞEKİLDE İŞLENMEMİŞ OLANLAR)"
    },
    {
        "kod": "231201",
        "tanim": "CAM AYNA İMALATI (TAŞITLAR İÇİN DİKİZ AYNALARI DAHİL)"
    },
    {
        "kod": "231202",
        "tanim": "SERTLEŞTİRİLMİŞ EMNİYET CAMI VE TEMPERLİ DÜZ CAM İMALATI (OTO CAMI DAHİL)"
    },
    {
        "kod": "231203",
        "tanim": "ÇOK KATLI YALITIM CAMLARI İMALATI"
    },
    {
        "kod": "231204",
        "tanim": "LEVHA VEYA TABAKA HALİNDE İŞLENMİŞ CAM İMALATI (KAVİSLENDİRİLMİŞ, KENARLARI İŞLENMİŞ, GRAVÜR YAPILMIŞ, DELİNMİŞ, EMAYLANMIŞ/SIRLANMIŞ VEYA BAŞKA BİR ŞEKİLDE İŞLENMİŞ, FAKAT ÇERÇEVELENMEMİŞ VEYA MONTE EDİLMEMİŞ OLANLAR) (OPTİK CAMLAR DAHİL)"
    },
    {
        "kod": "231301",
        "tanim": "CAMDAN ŞİŞE, KAVANOZ VE DİĞER MUHAFAZA KAPLARI, BARDAKLAR, TERMOS VE DİĞER VAKUMLU KAPLARIN CAMDAN YAPILMIŞ İÇ YÜZEYLERİ İLE CAMDAN SOFRA VE MUTFAK EŞYALARI İMALATI (AMPULLER HARİÇ)"
    },
    {
        "kod": "231302",
        "tanim": "TUVALET, BANYO, BÜRO, İÇ DEKORASYON, VB. AMAÇLARLA KULLANILAN CAM VE KRİSTAL EŞYA İMALATI (CAMDAN BİBLO, BONCUK VB. KÜÇÜK CAM EŞYALAR HARİÇ)"
    },
    {
        "kod": "231401",
        "tanim": "CAM ELYAFI İMALATI (CAM YÜNÜ VE BUNLARDAN YAPILMIŞ DOKUMA DIŞI ÜRÜNLER DAHİL)"
    },
    {
        "kod": "231901",
        "tanim": "SIKIŞTIRILMIŞ VEYA KALIPLANMIŞ CAMDAN DÖŞEME BLOKLARI, TUĞLALAR, KAROLAR VE DİĞER ÜRÜNLER, KURŞUNLU LAMBALAR VE BENZERLERİ, BLOK, PLAKA VEYA BENZER ŞEKİLLERDEKİ GÖZENEKLİ, KÖPÜKLÜ CAMLARIN İMALATI (VİTRAY CAM HARİÇ)"
    },
    {
        "kod": "231902",
        "tanim": "DUVAR SAATİ, KOL SAATİ VEYA GÖZLÜK İÇİN CAMLAR (BOMBELİ, KAVİSLİ, İÇİ OYUK VB. ŞEKİLDE FAKAT, OPTİK AÇIDAN İŞLENMEMİŞ) İLE BU TÜR CAMLARIN İMALATI İÇİN KULLANILAN İÇİ BOŞ KÜRE VE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "231903",
        "tanim": "CAM ZARFLAR (AÇIK) VE BUNLARIN CAM PARÇALARININ İMALATI (ELEKTRİK AMPULLERİ, ELEKTRİK LAMBALARI, KATOT-IŞINLI TÜPLER VB. İÇİN KULLANILAN)"
    },
    {
        "kod": "231904",
        "tanim": "KÜÇÜK CAM EŞYA İMALATI (BİBLO, VB. SÜS EŞYASI, BONCUKLAR, İMİTASYON İNCİLER/TAŞLAR, İMİTASYON MÜCEVHERLER, VB. DAHİL)"
    },
    {
        "kod": "231905",
        "tanim": "LAMBA VE AYDINLATMA TEÇHİZATININ, IŞIKLI İŞARETLERİN, İSİM TABELALARININ VB.NİN CAM PARÇALARININ İMALATI (CAM TABELALARIN İMALATI DAHİL)"
    },
    {
        "kod": "231906",
        "tanim": "LABORATUVAR, HİJYEN VEYA ECZACILIK İLE İLGİLİ CAM EŞYALAR İLE CAM AMPULLERİN (SERUM AMPULLERİ) İMALATI (AMBALAJLAMA VE TAŞIMADA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "231907",
        "tanim": "CAMDAN ELEKTRİK İZOLASYON MALZEMESİ İMALATI"
    },
    {
        "kod": "231908",
        "tanim": "VİTRAY CAM İMALATI"
    },
    {
        "kod": "231990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER CAM ÜRÜNLERİN İMALATI VE İŞLENMESİ (DÜZ CAMDAN YAPILMIŞ AKVARYUMLARIN İMALATI DAHİL)"
    },
    {
        "kod": "232016",
        "tanim": "SİLİSLİ SÜZME TOPRAKTAN (KİZELGUR) ISI YALITIMLI SERAMİK ÜRÜNLER İLE ATEŞE DAYANIKLI BRİKET, BLOK, TUĞLA, ATEŞ TUĞLASI, VB. ATEŞE DAYANIKLI SERAMİK YAPI ÜRÜNLERİ İMALATI"
    },
    {
        "kod": "232017",
        "tanim": "ATEŞE DAYANIKLI İMBİKLER, DAMITMA KABI, ERİTME POTASI, VANA UCU, TÜP, BORU, DÖKÜM POTALARI, MUFL OCAĞI, PÜSKÜRTME TÜPLERİ VB. SERAMİK ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "232018",
        "tanim": "ATEŞE DAYANIKLI ÇİMENTO, ÇAMUR, HARÇ, BETON VB. İMALATI"
    },
    {
        "kod": "233101",
        "tanim": "SERAMİK KARO VE KALDIRIM TAŞI İMALATI (MOZAİK TAŞI VE MOZAİK KÜPLERİ DAHİL) (ATEŞE DAYANIKLI OLANLAR HARİÇ)"
    },
    {
        "kod": "233201",
        "tanim": "FIRINLANMIŞ, ATEŞE DAYANIKLI OLMAYAN KİL VE TOPRAKTAN BACA KÜNKLERİ VE BAŞLIKLARI, ŞÖMİNE VE BACA BORULARI, OLUKLAR VE BAĞLANTI PARÇALARI İLE TUĞLA, KİREMİT, KARO VB. İNŞAAT MALZEMELERİ İMALATI (SERAMİKTEN OLUKLAR, BORULAR VE BAĞLANTI PARÇALARI DAHİL)"
    },
    {
        "kod": "234101",
        "tanim": "SERAMİK VEYA PORSELENDEN SOFRA TAKIMLARI (TABAK, BARDAK, FİNCAN, VB.) VE DİĞER EV VE TUVALET EŞYASININ İMALATI (ÇİNİDEN OLANLAR VE SIHHİ ÜRÜNLER HARİÇ)"
    },
    {
        "kod": "234102",
        "tanim": "SERAMİK VE PORSELENDEN HEYKELCİK, VAZO, BİBLO, VB. SÜS EŞYASI İMALATI (OYUNCAKLAR HARİÇ)"
    },
    {
        "kod": "234103",
        "tanim": "ÇİNİDEN SOFRA TAKIMI, EV, TUVALET VE SÜS EŞYASI İMALATI (ÇİNİCİLİK) (ÇİNİ DEKORU DAHİL)"
    },
    {
        "kod": "234104",
        "tanim": "TOPRAKTAN GÜVEÇ, ÇANAK, ÇÖMLEK, KÜP, VAZO, VB. EŞYALAR İLE TOPRAKTAN HEYKEL VB. SÜS VE DEKORATİF EŞYA İMALATI (PORSELEN VE ÇİNİDEN OLANLAR İLE MALLARIN AMBALAJLANMASI VE TAŞINMASI İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "234201",
        "tanim": "SERAMİK SIHHİ ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "234301",
        "tanim": "SERAMİK YALITKANLARIN (İZOLATÖRLERİN) VE YALITKAN BAĞLANTI PARÇALARININ İMALATI"
    },
    {
        "kod": "234401",
        "tanim": "DİĞER TEKNİK SERAMİK ÜRÜNLERİN İMALATI (LABORATUVAR, KİMYASAL VE DİĞER TEKNİK ALANLARDA KULLANILAN SERAMİKTEN ÜRÜNLER) (ATEŞE DAYANIKLI SERAMİK ÜRÜNLER HARİÇ)"
    },
    {
        "kod": "234901",
        "tanim": "TARIMSAL AMAÇLI OLANLAR İLE MALLARIN TAŞINMASI YA DA AMBALAJLANMASI İÇİN KULLANILAN SERAMİK ÜRÜNLERİN İMALATI (SERAMİK ÇÖMLEKLER, KAVANOZLAR, VB. İLE YALAKLAR, TEKNELER VB.)"
    },
    {
        "kod": "234902",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ YAPI İŞLERİNDE KULLANILMAYAN DİĞER SERAMİK EŞYALARIN İMALATI (DEKORATİF AMAÇLI OLMAYAN SERAMİK SAKSILAR DAHİL)"
    },
    {
        "kod": "235101",
        "tanim": "ÇİMENTO İMALATI (ÇİMENTO KLİNKERİ, PORTLAND, ALÜMİNYUMLU ÇİMENTO (BOKSİT ÇİMENTOSU), CÜRUF ÇİMENTO, SÜPER FOSFAT ÇİMENTOLAR VE BENZERİ SUYA DAYANIKLI ÇİMENTOLAR)"
    },
    {
        "kod": "235201",
        "tanim": "SÖNMEMİŞ KİREÇ, SÖNMÜŞ KİREÇ VE SUYA DAYANIKLI KİREÇ İMALATI"
    },
    {
        "kod": "235202",
        "tanim": "SÖNMÜŞ ALÇITAŞINDAN YA DA SÖNMÜŞ SÜLFATTAN ALÇI İMALATI"
    },
    {
        "kod": "235203",
        "tanim": "YANMIŞ (KALSİNE EDİLMİŞ) VEYA AGLOMERA EDİLMİŞ DOLOMİT İMALATI"
    },
    {
        "kod": "236101",
        "tanim": "ÇİMENTODAN, BETONDAN VEYA SUNİ TAŞTAN PREFABRİK YAPI ELEMANLARI İMALATI (GAZ BETONDAN VE KİREÇ TAŞINDAN OLANLAR DAHİL)"
    },
    {
        "kod": "236102",
        "tanim": "ÇİMENTODAN, BETONDAN VEYA SUNİ TAŞTAN KARO, DÖŞEME TAŞI, KİREMİT, TUĞLA, BORU, VB. İNŞAAT AMAÇLI ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "236103",
        "tanim": "BETONDAN YAPILMIŞ PREFABRİK YAPILARIN İMALATI"
    },
    {
        "kod": "236201",
        "tanim": "İNŞAAT AMAÇLI ALÇI ÜRÜNLERİN İMALATI (KARTONPİYER, LEVHALAR, PANOLAR, PANELLER, VB.)"
    },
    {
        "kod": "236301",
        "tanim": "HAZIR BETON İMALATI"
    },
    {
        "kod": "236401",
        "tanim": "TOZ HARÇ İMALATI"
    },
    {
        "kod": "236502",
        "tanim": "LİF VE ÇİMENTO KARIŞIMLI ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "236901",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ALÇI VE ALÇI ESASLI BİLEŞENLERDEN ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "236902",
        "tanim": "BETON, ÇİMENTO YA DA SUNİ TAŞTAN YAPILMIŞ DİĞER ÜRÜNLERİN İMALATI (HEYKEL, ALÇAK VE YÜKSEK KABARTMA, VAZO, ÇİÇEK SAKSISI, MİMARİ SÜSLER, BAHÇE SÜSLERİ, VB.)"
    },
    {
        "kod": "237001",
        "tanim": "TAŞ VE MERMERİN KESİLMESİ, ŞEKİL VERİLMESİ VE BİTİRİLMESİ (DOĞAL TAŞLARDAN, MERMERDEN, SU MERMERİNDEN, TRAVERTENDEN, KAYAĞANTAŞINDAN LEVHA/TABAKA, KURNA, LAVABO, KARO, KALDIRIM TAŞI, YAPI TAŞI, MEZAR TAŞI, VB. İMALATI DAHİL, SÜS EŞYASI HARİÇ)"
    },
    {
        "kod": "237002",
        "tanim": "DOĞAL TAŞLARDAN, MERMERDEN, SU MERMERİNDEN, TRAVERTENDEN, KAYAĞANTAŞINDAN SÜS EŞYASI İMALATI (LÜLETAŞI, KEHRİBAR, VB.NDEN OLANLAR DAHİL)"
    },
    {
        "kod": "239101",
        "tanim": "AŞINDIRICI ÜRÜNLERİN İMALATI (DEĞİRMEN TAŞLARI, BİLEĞİ TAŞI, ZIMPARA TAŞI,V.B.) (DOKUMA TEKSTİL KUMAŞLARINA, KAĞIT VE MUKAVVAYA TUTTURULMUŞ ZIMPARALAR HARİÇ.)"
    },
    {
        "kod": "239102",
        "tanim": "DOKUMA TEKSTİL KUMAŞLARINA, KAĞIT VE MUKAVVAYA TUTTURULMUŞ OLAN ZIMPARALARIN İMALATI"
    },
    {
        "kod": "239901",
        "tanim": "ASFALTTAN VE BENZERİ MALZEMELERDEN YAPILAN ÜRÜNLERİN İMALATI (ÇATI YAPIMINDA VEYA SU YALITIMINDA KULLANILAN BİTÜM ESASLI KEÇELER DAHİL)"
    },
    {
        "kod": "239902",
        "tanim": "MİNERAL SES/ISI İZOLASYON MALZEMELERİNİN İMALATI (CÜRUF YÜNLERİ, TAŞ YÜNÜ, MADENİ YÜNLER, PUL PUL AYRILMIŞ VERMİKULİT, GENLEŞTİRİLMİŞ KİL, SOĞUK TANDİŞ PLAKASI, VB. ISI VE SES YALITIM MALZEMELERİ)"
    },
    {
        "kod": "239903",
        "tanim": "İŞLENMİŞ ASBEST (AMYANT) LİFLERİ, ASBEST VE MAGNEZYUM KARBONAT ESASLI KARIŞIMLAR, BU KARIŞIMLARDAN VEYA ASBESTTEN YAPILAN ÜRÜNLER, FREN, DEBRİYAJ VE BENZERLERİ İÇİN MONTE EDİLMEMİŞ SÜRTÜNME MALZEMELERİ (FREN BALATASI VB.) İMALATI"
    },
    {
        "kod": "239904",
        "tanim": "İŞLENMİŞ MİKA VE MİKADAN ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "239905",
        "tanim": "BİTÜMLÜ KARIŞIMLARIN İMALATI (DOĞAL VEYA SUNİ TAŞTAN MALZEMELER İLE BİR BAĞLAYICI OLARAK BİTÜM, DOĞAL ASFALT VEYA İLGİLİ MADDELERİN KARIŞTIRILMASIYLA ELDE EDİLENLER)"
    },
    {
        "kod": "239907",
        "tanim": "AMYANTLI KAĞIT İMALATI"
    },
    {
        "kod": "239909",
        "tanim": "SUNİ KORİNDON İMALATI"
    },
    {
        "kod": "239990",
        "tanim": "DİĞER METAL DIŞI MİNERALLERDEN (TURBADAN, GRAFİTTEN, VB. MONTE EDİLMEMİŞ) ÜRÜNLERİN İMALATI (KARBON ELYAFI DAHİL, ELEKTRİK AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "241001",
        "tanim": "HAM ÇELİK ÜRETİLMESİ (KÜTÜK VEYA DİĞER BİRİNCİL FORMLARDA YA DA YARI MAMUL ÇELİK ÜRÜNLER HALİNDE)"
    },
    {
        "kod": "241002",
        "tanim": "ÇELİKTEN AÇIK PROFİL İMALATI (SICAK HADDELEME, SICAK ÇEKME VEYA KALIPTAN ÇEKME İŞLEMLERİNDEN DAHA İLERİ İŞLEM GÖRMEMİŞ) "
    },
    {
        "kod": "241003",
        "tanim": "DEMİR VE ÇELİKTEN SICAK VEYA SOĞUK ÇEKİLMİŞ YASSI HADDE ÜRÜNLERİ İMALATI (DEMİR VEYA ÇELİK ALAŞIMLI LEVHA, ŞERİT, SAC, TENEKE SAC, VB. DAHİL)"
    },
    {
        "kod": "241005",
        "tanim": "SICAK HADDELENMİŞ DEMİR VEYA ÇELİKTEN BAR VE ÇUBUKLARIN ÜRETİLMESİ (İNŞAAT DEMİRİ DAHİL)"
    },
    {
        "kod": "241006",
        "tanim": "DEMİR VEYA ÇELİK GRANÜL VE DEMİR TOZU ÜRETİLMESİ"
    },
    {
        "kod": "241007",
        "tanim": "DEMİR YA DA ÇELİK HURDALARIN YENİDEN ERİTİLMESİ"
    },
    {
        "kod": "241008",
        "tanim": "DEMİR CEVHERİNİN DOĞRUDAN İNDİRGENMESİYLE ELDE EDİLEN DEMİRLİ ÜRÜNLER VE DİĞER SÜNGER DEMİR ÜRÜNLERİNİN İMALATI İLE ELEKTROLİZ VEYA DİĞER KİMYASAL YÖNTEMLERLE İSTİSNAİ SAFLIKTA DEMİR ÜRETİLMESİ"
    },
    {
        "kod": "241009",
        "tanim": "ÇELİKTEN DEMİR YOLU VE TRAMVAY YOLU YAPIM MALZEMESİ (BİRLEŞTİRİLMEMİŞ RAYLAR İLE RAY DONANIMI, AKSAMI, VB.) İLE LEVHA KAZIKLARI (PALPLANŞ) VE KAYNAKLI AÇIK PROFİL İMALATI"
    },
    {
        "kod": "241010",
        "tanim": "PİK DEMİR VE MANGANEZLİ DÖKME DEMİR (AYNALI DEMİR/SPİEGELEİSEN) ÜRETİMİ (KÜLÇE, BLOK, VEYA DİĞER BİRİNCİL FORMLARDA)"
    },
    {
        "kod": "241012",
        "tanim": "FERRO ALAŞIMLARIN İMALATI (FERRO MANGANEZ, FERRO SİLİSYUM, FERRO SİLİKO MANGANEZ, FERRO KROM VE DİĞERLERİ)"
    },
    {
        "kod": "242009",
        "tanim": "ÇELİKTEN/DEMİRDEN YAPILMIŞ TÜP, BORU, İÇİ BOŞ PROFİLLER VE İLGİLİ BAĞLANTI PARÇALARININ İMALATI (SICAK ÇEKİLMİŞ VEYA SICAK HADDELENMİŞ)"
    },
    {
        "kod": "242010",
        "tanim": "ÇELİKTEN/DEMİRDEN YAPILMIŞ TÜP, BORU, İÇİ BOŞ PROFİLLER VE İLGİLİ BAĞLANTI PARÇALARININ İMALATI (SOĞUK ÇEKİLMİŞ VEYA SOĞUK HADDELENMİŞ)"
    },
    {
        "kod": "243101",
        "tanim": "ÇELİK BARLARIN VE İÇİ DOLU PROFİLLERİN SOĞUK ÇEKME YÖNTEMİYLE İMALATI"
    },
    {
        "kod": "243201",
        "tanim": "ÇELİK DAR ŞERİTLERİN SOĞUK HADDE YÖNTEMİYLE İMALATI (GENİŞLİĞİ < 600 MM OLAN)"
    },
    {
        "kod": "243301",
        "tanim": "AÇIK PROFİLLERİN, NERVÜRLÜ LEVHALARIN VE SANDVİÇ PANELLERİN SOĞUK ŞEKİLLENDİRME VEYA KATLAMA YÖNTEMİYLE İMALATI"
    },
    {
        "kod": "243401",
        "tanim": "ÇELİK TELLERİN SOĞUK ÇEKME YÖNTEMİYLE İMALATI"
    },
    {
        "kod": "244116",
        "tanim": "ALTIN İMALATI (İŞLENMEMİŞ, YARI İŞLENMİŞ, TOZ HALDE) İLE GÜMÜŞ VEYA ADİ METALLERİN ALTINLA KAPLANMASI VEYA GİYDİRİLMESİ"
    },
    {
        "kod": "244117",
        "tanim": "GÜMÜŞ İMALATI (İŞLENMEMİŞ, YARI İŞLENMİŞ, TOZ HALDE) İLE ADİ METALLERİN GÜMÜŞLE GİYDİRİLMESİ"
    },
    {
        "kod": "244118",
        "tanim": "PLATİN İMALATI (İŞLENMEMİŞ, YARI İŞLENMİŞ, TOZ HALDE) İLE ALTIN, GÜMÜŞ VEYA ADİ METALLERİN PLATİNLE KAPLANMASI VEYA GİYDİRİLMESİ (PALADYUM, RODYUM, OSMİYUM VE RUTENYUM İMALATI İLE PLATİN KATALİZÖR İMALATI DAHİL)"
    },
    {
        "kod": "244119",
        "tanim": "DEĞERLİ METAL ALAŞIMLARININ İMALATI"
    },
    {
        "kod": "244216",
        "tanim": "ALÜMİNYUM FOLYO İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244217",
        "tanim": "ALÜMİNYUM İMALATI (İŞLENMEMİŞ HALDE)"
    },
    {
        "kod": "244218",
        "tanim": "ALÜMİNYUM SAC, LEVHA, TABAKA, ŞERİT İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244220",
        "tanim": "ALÜMİNYUM OKSİT İMALATI (SUNİ KORİNDON HARİÇ) (ALÜMİNA)"
    },
    {
        "kod": "244221",
        "tanim": "ALÜMİNYUM BAR, ÇUBUK, TEL VE PROFİL, TÜP, BORU VE BAĞLANTI PARÇALARI İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244301",
        "tanim": "KURŞUN TABAKA, LEVHA, ŞERİT, FOLYO, KURŞUN TOZU VE PULU İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244302",
        "tanim": "KURŞUN İMALATI (İŞLENMEMİŞ)"
    },
    {
        "kod": "244304",
        "tanim": "KALAY BAR, ÇUBUK, PROFİL, TEL, VB. İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244305",
        "tanim": "KALAY İMALATI (İŞLENMEMİŞ HALDE)"
    },
    {
        "kod": "244306",
        "tanim": "ÇİNKO İMALATI (İŞLENMEMİŞ HALDE)"
    },
    {
        "kod": "244307",
        "tanim": "ÇİNKO BAR, ÇUBUK, PROFİL, TEL VB. İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244308",
        "tanim": "ÇİNKO SAC, TABAKA, LEVHA, ŞERİT, FOLYO, ÇİNKO TOZLARI, VB. İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244401",
        "tanim": "BAKIR, BAKIR MATI, BAKIR TOZU, SEMENTE BAKIR, BAKIR ANOTU İLE BAKIR VE BAKIR ALAŞIMLARININ İMALATI"
    },
    {
        "kod": "244403",
        "tanim": "BAKIR SAC, TABAKA, LEVHA, ŞERİT, FOLYO İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244404",
        "tanim": "BAKIRIN ÇEKİLMESİ VE HADDELENMESİ İLE TÜP, BORU, BUNLARIN BAĞLANTI ELEMANLARI, BAR, ÇUBUK, TEL VE PROFİL İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "244501",
        "tanim": "MADEN CEVHERLERİNDEN YA DA OKSİTLERDEN İŞLENMEMİŞ KROM, MANGANEZ, NİKEL, TUNGSTEN, MOLİBDEN, TANTALUM, KOBALT, BİZMUT, TİTANYUM, ZİRKONYUM, BERİLYUM, GERMANYUM VB. İMALATI (ALAŞIMLARI DAHİL)"
    },
    {
        "kod": "244502",
        "tanim": "KROM, MANGANEZ, TUNGSTEN, MOLİBDEN, TANTALUM, KOBALT, BİZMUT, TİTANYUM, ZİRKONYUM, BERİLYUM, GERMANYUM VB. DEMİR DIŞI METALLERDEN YAPILAN ÜRÜNLERİN İMALATI (SERMETLER VE DİĞER ARA ÜRÜNLER DAHİL, NİKELDEN OLANLAR HARİÇ)"
    },
    {
        "kod": "244506",
        "tanim": "NİKEL MATLARI, NİKEL OKSİT SİNTERLERİ VE DİĞER ARA ÜRÜNLERİ İLE NİKEL BAR, ÇUBUK, PROFİL, TEL, LEVHA, ŞERİT, FOLYO, TÜP, BORU VE BAĞLANTI PARÇALARI İMALATI"
    },
    {
        "kod": "244601",
        "tanim": "URANYUM VE RADYUMLU MADEN CEVHERLERİNDEN VEYA DİĞER CEVHERLERDEN METALİK URANYUM ÜRETİMİ, URANYUMUN ERGİTİLMESİ VE RAFİNE EDİLMESİ (ZENGİNLEŞTİRİLMİŞ PLUTONYUM, URANYUM, TORYUM İLE BUNLARIN BİLEŞİKLERİNİN İMALATI HARİÇ)"
    },
    {
        "kod": "245113",
        "tanim": "DEMİR DÖKÜM (YARI MAMUL DEMİR ÜRÜNLERİN DÖKÜMÜ, GRİ DEMİR DÖKÜMÜ, KÜRESEL GRAFİT DEMİR DÖKÜMÜ, DÖVÜLEBİLİR DÖKME DEMİR ÜRÜNLERİ DÖKÜMÜ, TÜPLER, BORULAR VE İÇİ BOŞ PROFİLLER İLE DÖKME DEMİRDEN TÜP VE BORULAR İLE BUNLARIN BAĞLANTI PARÇALARININ İMALATI)"
    },
    {
        "kod": "245220",
        "tanim": "ÇELİK DÖKÜMÜ"
    },
    {
        "kod": "245301",
        "tanim": "HAFİF METALLERİN DÖKÜMÜ (ALÜMİNYUM, MAGNEZYUM, TİTANYUM, ÇİNKO VB.DEN YARI MAMUL ÜRÜNLERİN DÖKÜMÜ İLE DÖKME HAFİF METALLERİN DÖKÜMÜ)"
    },
    {
        "kod": "245401",
        "tanim": "DEMİR DIŞI AĞIR METALLERİN DÖKÜMÜ (BAKIR VB.)"
    },
    {
        "kod": "245402",
        "tanim": "DEĞERLİ METALLERİN DÖKÜMÜ"
    },
    {
        "kod": "251106",
        "tanim": "İNŞAAT VE İNŞAATIN PARÇALARI İÇİN METAL ÇATI YADA İSKELETLERİN İMALATI. (KULELER, DİREKLER, DESTEKLER, KÖPRÜLER, V.B.) (KEPENK VE YANGIN MERDİVENİ İLE PREFABRİK YAPILAR HARİÇ)"
    },
    {
        "kod": "251107",
        "tanim": "METALDEN KEPENK VE YANGIN MERDİVENİ İMALATI"
    },
    {
        "kod": "251108",
        "tanim": "METALDEN PREFABRİK YAPI İMALATI"
    },
    {
        "kod": "251204",
        "tanim": "ALÜMİNYUM KAPI, PENCERE, BUNLARIN KASALARI, KAPI EŞİĞİ, PANJUR, VB. İMALATI"
    },
    {
        "kod": "251205",
        "tanim": "ÇELİK KAPI, PENCERE, BUNLARIN KASALARI, KAPI EŞİĞİ, PANJUR, VB. İMALATI"
    },
    {
        "kod": "251206",
        "tanim": "DEMİR KAPI, PENCERE, BUNLARIN KASALARI, KAPI EŞİĞİ, PANJUR, VB. İMALATI (BAHÇE KAPILARI DAHİL)"
    },
    {
        "kod": "252110",
        "tanim": "MERKEZİ ISITMA RADYATÖRLERİ İMALATI (ELEKTRİKLİ RADYATÖRLER İLE DÖKÜM OLANLAR HARİÇ)"
    },
    {
        "kod": "252111",
        "tanim": "MERKEZİ ISITMA KAZANLARI (BOYLER) İMALATI (KOMBİ, KAT KALORİFERİ VE DİĞER MERKEZİ ISITMA KAZANLARI) (BUHAR JENERATÖRLERİ VE KIZGIN SU ÜRETEN KAZANLAR HARİÇ)"
    },
    {
        "kod": "252112",
        "tanim": "MERKEZİ ISITMA RADYATÖRLERİ İMALATI, DÖKÜM OLANLAR (ELEKTRİKLİ RADYATÖRLER HARİÇ)"
    },
    {
        "kod": "252901",
        "tanim": "SIKIŞTIRILMIŞ VEYA SIVILAŞTIRILMIŞ GAZ İÇİN KULLANILAN METAL KONTEYNERLERİN İMALATI"
    },
    {
        "kod": "252902",
        "tanim": "METALDEN REZERVUARLAR, TANKLAR, FIÇILAR VE BENZERİ KAPASİTESİ > 300 LİTRE OLAN KONTEYNERLERİN İMALATI (SIKIŞTIRILMIŞ VEYA SIVILAŞTIRILMIŞ GAZLAR İÇİN OLANLAR İLE MEKANİK VEYA TERMAL EKİPMANLI OLANLAR HARİÇ)"
    },
    {
        "kod": "253001",
        "tanim": "BUHAR ÜRETİM KAZANLARI (BUHAR JENERATÖRÜ), KIZGIN SU KAZANLARI (BOYLER) VE BUNLARIN PARÇALARI İLE KAZANLAR (BOYLERLER) İÇİN YARDIMCI ÜNİTELER VE BUHAR VEYA DİĞER BUHAR GÜÇ ÜNİTELERİ İÇİN KONDANSATÖR İMALATI"
    },
    {
        "kod": "253002",
        "tanim": "NÜKLEER REAKTÖRLER VE NÜKLEER REAKTÖR PARÇASI İMALATI (İZOTOP AYIRICILAR HARİÇ)"
    },
    {
        "kod": "254001",
        "tanim": "TABANCA, REVOLVER (ALTIPATLAR), AV TÜFEĞİ, HAVALI TABANCA, COP, VB. ASKERİ AMAÇLI OLMAYAN ATEŞLİ SİLAHLAR VE BENZERİ ALETLERİN VE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "254002",
        "tanim": "ASKERİ SİLAH VE BUNLARIN PARÇALARININ İMALATI (BÜYÜK TOPLAR, SAVAŞ ARAÇLARI, FÜZEATARLAR, TORPİL KOVANLARI, AĞIR MAKİNELİ TÜFEKLER, VB.)"
    },
    {
        "kod": "254003",
        "tanim": "BOMBA, FÜZE VE BENZERİ SAVAŞ GEREÇLERİ, FİŞEKLER, DİĞER MERMİ VE MÜHİMMATLAR İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "255001",
        "tanim": "METALLERİN DÖVÜLMESİ, PRESLENMESİ, BASKILANMASI VE DAMGALANMASI"
    },
    {
        "kod": "255002",
        "tanim": "TOZ METALÜRJİSİ"
    },
    {
        "kod": "256101",
        "tanim": "METALLERİN ISIL İŞLEM VE ANODLAMA, SERTLEŞTİRME, VERNİKLEME, VB. YÜZEY İŞLEMLERİ, ELEKTROLİZ, ÇİNKOYLA GALVANİZLEME VEYA KİMYASAL İŞLEMLERLE METALİK KAPLAMA (KALAY VE NİKEL KAPLAMA HARİÇ) VE PLASTİK, TEFLON, VB. METAL DIŞI MALZEMELERLE KAPLAMA FAALİYETİ"
    },
    {
        "kod": "256102",
        "tanim": "METALLERİN KALAY İLE KAPLANMASI (KALAYCILIK) FAALİYETİ"
    },
    {
        "kod": "256103",
        "tanim": "METALLERİN NİKEL İLE KAPLANMASI (NİKELAJCILIK) FAALİYETİ"
    },
    {
        "kod": "256201",
        "tanim": "CNC OKSİJEN, CNC PLAZMA, CNC SU JETİ VB. MAKİNELERİNİN KULLANILMASI YOLUYLA METALLERİN KESİLMESİ VEYA ÜZERLERİNİN YAZILMASI"
    },
    {
        "kod": "256202",
        "tanim": "METALLERİN MAKİNEDE İŞLENMESİ (TORNA TESFİYE İŞLERİ, METAL PARÇALARI DELME, TORNALAMA, FREZELEME, RENDELEME, PARLATMA, OLUK AÇMA, PERDAHLAMA, BİRLEŞTİRME, KAYNAK YAPMA VB. FAALİYETLER) (METALLERİN LAZERLE KESİLMESİ HARİÇ)"
    },
    {
        "kod": "256203",
        "tanim": "LAZER IŞINLARININ KULLANILMASI YOLUYLA METALLERİN KESİLMESİ VEYA ÜZERLERİNİN YAZILMASI"
    },
    {
        "kod": "257101",
        "tanim": "KAŞIK, ÇATAL, KEPÇE, KEVGİR, SERVİS SPATULASI, ŞEKER MAŞASI VE BENZERİ MUTFAK GEREÇLERİ, SOFRA TAKIMLARI, ÇATAL BIÇAK TAKIMLARI İMALATI (BALIK BIÇAKLARI, KAHVALTI VE MEYVE BIÇAKLARI DAHİL FAKAT, SOFRA BIÇAKLARI HARİÇ)"
    },
    {
        "kod": "257102",
        "tanim": "SOFRA BIÇAKLARI (BALIK BIÇAKLARI, KAHVALTI VE MEYVE BIÇAKLARI HARİÇ), BUDAMA BIÇAKLARI, SUSTALI BIÇAKLAR, SATIR, VB. BIÇAKLAR (MAKİNELER İÇİN OLANLAR HARİÇ) İLE TERZİ MAKASLARI, VB. MAKASLAR VE BUNLARIN AĞIZLARININ İMALATI"
    },
    {
        "kod": "257103",
        "tanim": "KILIÇ, PALA, KASATURA, MIZRAK, SÜNGÜ, AVCI BIÇAĞI VE BENZERİ SİLAHLAR İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "257104",
        "tanim": "MANİKÜR VEYA PEDİKÜR SETLERİ VE ALETLERİ, KAĞIT BIÇAKLARI, MEKTUP AÇACAKLARI, KALEMTIRAŞLAR VE BUNLARIN BIÇAKLARI, KIRMA, YARMA VE KIYMA BIÇAKLARI, SAÇ KESME VE HAYVAN KIRKMA MAKİNE VE ALETLERİ İLE BENZERİ ELEKTRİKSİZ KESİCİ ALETLERİN İMALATI"
    },
    {
        "kod": "257105",
        "tanim": "TIRAŞ BIÇAKLARI, USTURALAR İLE JİLETLER VE TIRAŞ MAKİNELERİNİN BIÇAKLARININ İMALATI"
    },
    {
        "kod": "257201",
        "tanim": "ASMA KİLİT, KİLİT, ANAHTAR, MENTEŞE, OTOMATİK KAPI KAPAYICILARI, KİLİTLİ KLİPSLER, BAĞLANTI TAKOZU, ASKILIKLAR, BULAŞIKLIKLAR, ANAHTAR ASKILARI, VB. İLE BİNALAR, MOBİLYALAR, TAŞITLAR, VB. İÇİN KÜÇÜK TEKERLEKLERİN İMALATI"
    },
    {
        "kod": "257302",
        "tanim": "EL ALETLERİ, TAKIM TEZGAHI UÇLARI, TESTERE AĞIZLARI, MENGENELER, KISKAÇLAR, SIKIŞTIRMA ANAHTARLARI VB. İMALATI"
    },
    {
        "kod": "257303",
        "tanim": "METALDEN KALIP VE DÖKÜM MODELİ İMALATI (KEK VE AYAKKABI KALIPLARI HARİÇ)"
    },
    {
        "kod": "257304",
        "tanim": "KUYUMCULUK ALETLERİ VE PARÇALARININ İMALATI (PENSE, KESKİ, ÇEKİÇ VB. ALETLER)"
    },
    {
        "kod": "257305",
        "tanim": "PLASTİKTEN KALIP VE DÖKÜM MODELİ İMALATI (KEK VE AYAKKABI KALIPLARI HARİÇ)"
    },
    {
        "kod": "257306",
        "tanim": "AHŞAP VE DİĞER MALZEMELERDEN KALIP VE DÖKÜM MODELİ İMALATI (KEK VE AYAKKABI KALIPLARI HARİÇ)"
    },
    {
        "kod": "259101",
        "tanim": "ÇELİK VARİL VE BENZER MUHAFAZALARIN İMALATI"
    },
    {
        "kod": "259201",
        "tanim": "DEMİR VEYA ÇELİKTEN YİYECEK, İÇECEK VE DİĞER ÜRÜNLER İÇİN KAPASİTESİ < 50 LİTRE OLAN KUTULARIN İMALATI (LEHİM VEYA KIVRILARAK KAPATILANLAR) (TENEKEDEN OLANLAR DAHİL) "
    },
    {
        "kod": "259202",
        "tanim": "ADİ METALDEN DİŞLİ KAPAKLAR (ŞİŞE KAPAĞI VB.) VE TIPALAR İLE TIKAÇLAR VE KAPAKLARIN İMALATI"
    },
    {
        "kod": "259203",
        "tanim": "KAPASİTESİ 300 LT.Yİ GEÇMEYEN ALÜMİNYUM VARİL FIÇI, KOVA, KUTU, VB. İMALATI (DİŞ MACUNU, KREM GİBİ KAPAKLI TÜPLER VE KATLANABİLİR KUTULAR İLE AEROSOL KUTULARI DAHİL)"
    },
    {
        "kod": "259301",
        "tanim": "METALDEN ZİNCİRLER (MAFSALLI BAĞLANTI ZİNCİRİ HARİÇ) VE PARÇALARI İLE YAY VE YAY YAPRAKLARI, KAPLANMIŞ VEYA NÜVELİ TELLER, ÇUBUKLAR, TÜPLER, LEVHALAR VE ELEKTROTLARIN İMALATI (ELEKTRİK İŞLERİNDE KULLANILANLAR İLE ELEKTRİK YALITIMI OLANLAR HARİÇ)"
    },
    {
        "kod": "259302",
        "tanim": "İĞNE, ÇENGELLİ İĞNE, ÇUVALDIZ, ÖRGÜ ŞİŞİ, TIĞ, RAPTİYE, ÇİVİ, VB. İMALATI"
    },
    {
        "kod": "259303",
        "tanim": "TELDEN YAPILAN DİĞER ÜRÜNLERİN İMALATI (ÖRGÜLÜ TEL, ÖRME ŞERİT, TAŞIMA ASKISI, DİKENLİ TEL (ELEKTRİK YALITIMI OLANLAR HARİÇ) VE DEMİR, ÇELİK VEYA BAKIR TELLERDEN MENSUCAT, IZGARA, AĞ, KAFESLİK VE ÇİTLER)"
    },
    {
        "kod": "259401",
        "tanim": "YİVSİZ BAĞLANTI MALZEMELERİ İMALATI, DEMİR, ÇELİK VEYA BAKIRDAN (RONDELALAR, PERÇİNLER, PERÇİN ÇİVİLERİ, KAMALI PİMLER, KOPİLYALAR VB. ÜRÜNLER)"
    },
    {
        "kod": "259402",
        "tanim": "YİVLİ BAĞLANTI MALZEMELERİ İMALATI, DEMİR, ÇELİK VEYA BAKIRDAN (VİDALAR, CIVATALAR, SOMUNLAR VB. YİVLİ ÜRÜNLER)"
    },
    {
        "kod": "259901",
        "tanim": "DEMİR, ÇELİK VE ALÜMİNYUMDAN SOFRA VE MUTFAK EŞYALARININ İMALATI (TENCERE, TAVA, ÇAYDANLIK, CEZVE, YEMEK KAPLARI, BULAŞIK TELLERİ VB.) (TEFLON, EMAYE VB. İLE KAPLANMIŞLAR DAHİL, BAKIRDAN OLANLAR HARİÇ)"
    },
    {
        "kod": "259902",
        "tanim": "METALDEN YAPILMIŞ EVİYE, LAVABO, KÜVET, DUŞ TEKNESİ, JAKUZİ (EMAYE OLSUN-OLMASIN) VE DİĞER SIHHİ ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "259903",
        "tanim": "ZIRHLI VEYA GÜÇLENDİRİLMİŞ KASALAR, KASA DAİRELERİ, KİLİTLİ PARA KASALARI, ZIRHLI KAPILAR VB. İMALATI (ADİ METALDEN)"
    },
    {
        "kod": "259904",
        "tanim": "ADİ METALDEN BÜRO MALZEMELERİ İMALATI (DOSYA KUTULARI, KAŞELER, ZIMBA TELLERİ, KAĞIT ATAÇLARI VB.)"
    },
    {
        "kod": "259905",
        "tanim": "METALDEN YAPILMIŞ ÇEŞİTLİ EŞYA İMALATI (KLİPS, TARAK, SAÇ FİRKETESİ, BİGUDİ, KOPÇA, ELB. ASKISI, ROZET, RÜTBE, KAPAN, TUZAK, ÇÖP SEPETİ, SİGARA TABAKASI, PALET, MAKARA, KANCA, KOZMETİK KUTULARI VB.) (TEKSTİL ÜRÜNLERİ İMALATINDA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "259906",
        "tanim": "BAKIRDAN SOFRA VE MUTFAK EŞYASI İMALATI (CEZVE, TENCERE, ÇANAK, TABAK, İBRİK VB.)"
    },
    {
        "kod": "259907",
        "tanim": "KALICI METALİK MIKNATISLARIN İMALATI"
    },
    {
        "kod": "259908",
        "tanim": "METALDEN GEMİ VE TEKNE PERVANELERİ VE BUNLARIN AKSAMLARI İLE ÇIPALAR, FİLİKA DEMİRLERİ VB. İMALATI"
    },
    {
        "kod": "259909",
        "tanim": "ALÜMİNYUM JALUZİ PERDE İMALATI"
    },
    {
        "kod": "259910",
        "tanim": "METAL MERDİVEN İMALATI"
    },
    {
        "kod": "259911",
        "tanim": "ZİL, ÇAN, GONG VB. EŞYALAR İLE ADİ METALLERDEN BİBLO, HEYKELCİK, ÇERÇEVE, AYNA VE DİĞER SÜS EŞYASI İMALATI (BİSİKLET ZİLLERİ DAHİL ANCAK KALIBA DÖKÜLEREK YAPILANLAR, BAKIRDAN OLANLAR İLE MUTFAK EŞYALARI HARİÇ)"
    },
    {
        "kod": "259912",
        "tanim": "KALIBA DÖKÜLEREK YAPILAN ZİL, ÇAN, GONG VB. EŞYALAR İLE ADİ METALLERDEN KALIBA DÖKÜLEREK YAPILAN BİBLO, HEYKELCİK VE DİĞER SÜS EŞYASI İMALATI (BİSİKLET ZİLLERİ DAHİL ANCAK BAKIRDAN OLANLAR İLE MUTFAK EŞYALARI HARİÇ)"
    },
    {
        "kod": "259913",
        "tanim": "METALDEN ÇATI OLUKLARI, ÇATI KAPLAMALARI VB. İMALATI"
    },
    {
        "kod": "259914",
        "tanim": "ADİ METALLERDEN İŞARET LEVHALARI VE TABELALAR İLE RAKAMLAR, HARFLER VE DİĞER SEMBOLLERİN İMALATI (OTO PLAKALARI DAHİL, IŞIKLI OLANLAR HARİÇ)"
    },
    {
        "kod": "259915",
        "tanim": "KURŞUN TÜP, BORU VE BUNLARIN BAĞLANTI PARÇALARI İLE KURŞUN BAR, ÇUBUK, PROFİL, TEL VB. İMALATI (ALAŞIMDAN OLANLAR DAHİL)"
    },
    {
        "kod": "259916",
        "tanim": "KALAY PLAKA, TABAKA, SAC, LEVHA, ŞERİT, FOLYO, TÜP, BORU VE KALAY TOZLARI İLE DİĞER ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "259917",
        "tanim": "ÇİNKO TÜP, BORU VE BAĞLANTI PARÇALARI İLE DİĞER ÜRÜNLERİN İMALATI"
    },
    {
        "kod": "259918",
        "tanim": "BAKIRDAN YAPILAN BİBLOLAR, ÇERÇEVELER, AYNALAR VE DİĞER SÜSLEME EŞYALARI İLE SÜSLEME İŞLERİ (MUTFAK EŞYALARI HARİÇ)"
    },
    {
        "kod": "259919",
        "tanim": "DEMİR YOLU VEYA TRAMVAY HATLARINDA KULLANILAN ADİ METALDEN SABİT MALZEMELER VE BAĞLANTI PARÇALARI İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "259920",
        "tanim": "ELEKTRİKSİZ SEBZE-MEYVE DİLME, DOĞRAMA VE SULARINI ÇIKARMA ALETLERİ, ET KIYMA ALETLERİ, KAHVE VE BAHARAT DEĞİRMENLERİ, EL HAVANI, RENDE VB. EL GÜCÜYLE ÇALIŞAN MUTFAK ALETLERİ VE AKSESUARLARI İMALATI"
    },
    {
        "kod": "259921",
        "tanim": "ELEKTRİKSİZ HAZNELİ DÖNER BACALARIN, HAVALANDIRMA KANALLARININ VB. İMALATI"
    },
    {
        "kod": "261104",
        "tanim": "DİYOTLARIN, TRANSİSTÖRLERİN, DİYAKLARIN, TRİYAKLAR, TRİSTÖR, REZİSTANS, LEDLER, KRİSTAL, RÖLE, MİKRO ANAHTAR, SABİT VEYA AYARLANABİLİR DİRENÇ VE KONDANSATÖRLER İLE ELEKTRONİK ENTEGRE DEVRELERİN İMALATI "
    },
    {
        "kod": "261105",
        "tanim": "KATOT IŞINLI GÖRÜNTÜ TÜPLERİ, TELEVİZYON KAMERASI TÜPLERİ VE  MAGNETRONLAR, KLİSTRONLAR, MİKRODALGA TÜPLERİ VE DİĞER VALF TÜPLERİNİN, LCD VE PLAZMA TV PANELLERİ VE GÖSTERGELERİN İMALATI"
    },
    {
        "kod": "261106",
        "tanim": "ÇIPLAK BASKILI DEVRE KARTLARININ İMALATI"
    },
    {
        "kod": "261190",
        "tanim": "BYS. DİĞER ELEKTRONİK BİLEŞENLERİN İMALATI"
    },
    {
        "kod": "261201",
        "tanim": "YÜKLÜ ELEKTRONİK KART İMALATI (YÜKLÜ BASKILI DEVRE KARTLARI, SES, GÖRÜNTÜ, DENETLEYİCİ, AĞ VE MODEM KARTLARI İLE AKILLI KARTLAR VB.)"
    },
    {
        "kod": "262001",
        "tanim": "BİLGİSAYAR VE BİLGİSAYAR ÇEVRE BİRİMLERİ İMALATI"
    },
    {
        "kod": "263002",
        "tanim": "RADYO VE TELEVİZYON STÜDYOLARI VE YAYIN TEÇHİZATLARI İLE RADYO VE TELEVİZYON İLETİM CİHAZLARININ İMALATI (TV KAMERALARI VE BAZ İSTASYONLARI DAHİL)"
    },
    {
        "kod": "263003",
        "tanim": "KIZIL ÖTESİ (ENFRARUJ) SİNYAL KULLANAN İLETİŞİM CİHAZLARININ İMALATI (ÖRN: UZAKTAN KUMANDA CİHAZLARI)"
    },
    {
        "kod": "263005",
        "tanim": "ALICI VE VERİCİ ANTENLERİN İMALATI (HARİCİ, TELESKOPİK, ÇUBUK, UYDU, ÇANAK VE HAVA VE DENİZ TAŞITLARININ ANTENLERİ)"
    },
    {
        "kod": "263006",
        "tanim": "KABLOLU VE KABLOSUZ TELEFON, CEP TELEFONU, KABLOLU GÖRÜNTÜLÜ TELEFON, ÇAĞRI CİHAZI VE FAKS CİHAZI İMALATI (TELESEKRETER İMALATI DAHİL)"
    },
    {
        "kod": "263008",
        "tanim": "MERKEZİ İLETİŞİM SANTRAL DONANIMLARI İLE SAYISAL VEYA ANALOG TELEFON-TELGRAF SANTRALLERİNİN VE AĞ GEÇİTLERİ, KÖPRÜLERİ, YÖNLENDİRİCİLERİ GİBİ VERİ İLETİM DONANIMLARININ İMALATI (MORS VEYA MORS TİPİ KAYDEDİCİ VE ANAHTARLAR DAHİL)"
    },
    {
        "kod": "263009",
        "tanim": "HIRSIZ VE YANGIN ALARM SİSTEMLERİ VE KAPI KONUŞMA SİSTEMLERİNİN (DİYAFON)  (GÖRÜNTÜLÜ OLANLAR DAHİL) İMALATI (MOTORLU KARA TAŞITLARI İÇİN ALARM SİSTEMLERİ HARİÇ)"
    },
    {
        "kod": "263010",
        "tanim": "SES, GÖRÜNTÜ VEYA DİĞER VERİLERİN ALINMASI, DÖNÜŞTÜRÜLMESİ, İLETİLMESİ/YENİDEN OLUŞTURULMASI İÇİN KULLANILAN DİĞER MAKİNELERİN İMALATI (ALICISI/VERİCİSİ BULUNAN TELGRAF, TELEKS CİHAZLARI İLE ANAHTARLAMA VE YÖNLENDİRME CİHAZLARI DAHİL)"
    },
    {
        "kod": "263090",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER İLETİŞİM EKİPMANLARININ İMALATI"
    },
    {
        "kod": "264008",
        "tanim": "SES VE GÖRÜNTÜ OYNATICI VE KAYDEDİCİLERİ, EV TİPİ VİDEO KAMERALAR VE DİĞER GÖRÜNTÜ KAYIT VEYA GÖRÜNTÜ ÇOĞALTMA CİHAZLARININ İMALATI"
    },
    {
        "kod": "264009",
        "tanim": "RADYO VE TELEVİZYON İMALATI (TAŞITLARDA KULLANILANLAR DAHİL)"
    },
    {
        "kod": "264010",
        "tanim": "MİKROFON, HOPARLÖR VE KULAKLIKLAR İLE ELEKTRİKLİ SES YÜKSELTEÇLERİNİN (AMPLİFİKATÖRLER) İMALATI"
    },
    {
        "kod": "264011",
        "tanim": "MONİTÖRLER VE PROJEKTÖRLERİN İMALATI (BİLGİSAYAR GİBİ BİR OTOMATİK VERİ İŞLEME SİSTEMİNDE KULLANILMAYANLAR)"
    },
    {
        "kod": "264012",
        "tanim": "VİDEO OYUN VE KONSOLLARININ (TELEVİZYONLA KULLANILANLAR VE KENDİ EKRANI OLANLAR) İMALATI"
    },
    {
        "kod": "264090",
        "tanim": "BYS. TÜKETİCİ ELEKTRONİĞİ ÜRÜNLERİNİN İMALATI"
    },
    {
        "kod": "265102",
        "tanim": "DEDEKTÖR İMALATI (YERALTI KAYNAKLARI, MADEN, MAYIN, GÜVENLİK KONTROL, RADYASYON VB. DEDEKTÖRLERİ)"
    },
    {
        "kod": "265103",
        "tanim": "ELEKTRİK MİKTARINI (VOLT, AKIM VB.) ÖLÇMEK VE KONTROL ETMEK İÇİN KULLANILAN ALET VE CİHAZLARIN İMALATI (AVOMETRE, VOLTMETRE, OSİLOSKOP İLE DİĞER VOLTAJ, AKIM, DİRENÇ VEYA ELEKTRİK GÜCÜNÜ ÖLÇÜM VEYA KONTROL İÇİN OLANLAR) (ELEKTRİK SAYAÇLARI HARİÇ)"
    },
    {
        "kod": "265104",
        "tanim": "HIZ VE MESAFE ÖLÇÜMÜNDE KULLANILAN ALET VE CİHAZLARIN İMALATI (TAŞIT HIZ GÖSTERGESİ, TAKOMETRE, TAKSİMETRE VB.)"
    },
    {
        "kod": "265105",
        "tanim": "ISI VE SICAKLIK ÖLÇÜMÜNDE KULLANILAN ALET VE CİHAZLARIN İMALATI (TERMOMETRE, TERMOSTAT, PİROMETRE VB.)"
    },
    {
        "kod": "265106",
        "tanim": "IŞIK, IŞIN VE RENK ÖLÇÜMÜNDE KULLANILAN ALET VE CİHAZLARIN İMALATI (POLARİMETRE, KOLORİMETRE, REFRAKTOMETRE VB.)"
    },
    {
        "kod": "265107",
        "tanim": "METEOROLOJİDE KULLANILAN ALET VE CİHAZLARIN İMALATI"
    },
    {
        "kod": "265108",
        "tanim": "YÖN BULMA PUSULALARI İLE DİĞER SEYRÜSEFER ALET VE CİHAZLARININ VE RADAR CİHAZLARININ İMALATI (HAVA, KARA VE DENİZ TAŞIMACILIĞINDA KULLANILANLAR DAHİL)"
    },
    {
        "kod": "265109",
        "tanim": "HAVA, SIVI VE GAZLARIN AKIŞ, SEVİYE, BASINÇ VEYA DİĞER DEĞİŞKENLERİNİ ÖLÇME VE KONTROL ETME İÇİN KULLANILAN ALETLERİN İMALATI (HİDROMETRE, DEBİMETRE, BAROMETRE, HİGROMETRE VB.)"
    },
    {
        "kod": "265110",
        "tanim": "GAZ, SIVI VEYA ELEKTRİK ÜRETİM VEYA TÜKETİM SAYAÇLARININ İMALATI"
    },
    {
        "kod": "265111",
        "tanim": "TEÇHİZATLI ÇİZİM MASALARI VE MAKİNELERİ İLE DİĞER ÇİZİM, İŞARETLEME VEYA MATEMATİKSEL HESAPLAMA ALETLERİNİN İMALATI (PERGEL TAKIMI, PANTOGRAF, RESİM, ÇİZİM, HESAP YAPMAYA MAHSUS ELEKTRİKLİ/ELEKTRONİK ÇİZİCİLER VB. DAHİL)"
    },
    {
        "kod": "265112",
        "tanim": "LABORATUVAR, KUYUMCULUK VB. YERLERDE KULLANILAN HASSAS TARTILARIN İMALATI"
    },
    {
        "kod": "265113",
        "tanim": "SANAYİDE KULLANILAN İŞLEM KONTROL AMAÇLI TEÇHİZATLARIN İMALATI"
    },
    {
        "kod": "265114",
        "tanim": "TELEMETRELER, TEODOLİTLER VE DİĞER ARAZİ ÖLÇÜMÜ, HİDROGRAFİK, OŞİNOGRAFİK, HİDROLOJİK VEYA JEOFİZİK ALET VE CİHAZLARININ İMALATI"
    },
    {
        "kod": "265115",
        "tanim": "SEYRÜSEFERE YARDIMCI TELSİZ CİHAZLARI İLE UZAKTAN KUMANDALI KONTROL CİHAZLARININ (ROKETLER, FÜZELER, MAKİNELER VB) İMALATI"
    },
    {
        "kod": "265190",
        "tanim": "BYS. ÖLÇME, TEST VE SEYRÜSEFER AMAÇLI ALET VE CİHAZLARIN İMALATI (HİDROLİK VEYA PNÖMATİK OTOMATİK AYAR VEYA KONTROL ALETLERİ İLE MİLOMETRELER, PEDOMETRELER, STROBOSKOPLAR, MONOSTATLAR, KUMPASLAR, SPEKTROMETRELER DAHİL)"
    },
    {
        "kod": "265203",
        "tanim": "DEVAM KAYIT CİHAZLARI, ZAMAN KAYIT CİHAZLARI, PARKMETRELER; DUVAR VE KOL SAATİ MAKİNELİ ZAMAN AYARLI ANAHTARLARIN İMALATI (VARDİYA SAATİ VB.)"
    },
    {
        "kod": "265204",
        "tanim": "KOL SAATİ, MASA SAATİ, DUVAR VE CEP SAATLERİNİN, BUNLARIN MAKİNELERİNİN, KASALARININ VE DİĞER PARÇALARININ İMALATI (KRONOMETRELER VE TAŞITLAR İÇİN GÖSTERGE PANELLERİNDE BULUNAN SAATLER VE BENZERİ TİPTEKİ SAATLER DAHİL)"
    },
    {
        "kod": "266001",
        "tanim": "IŞINLAMA, ELEKTROMEDİKAL VE ELEKTROTERAPİ İLE İLGİLİ CİHAZLARIN İMALATI (ELEKTRO-KARDİYOGRAF CİHAZI, İŞİTME CİHAZI, RADYOLOJİ CİHAZI, RÖNTGEN CİHAZLARI, X, ALFA, BETA, GAMA, MOR ÖTESİ VE KIZIL ÖTESİ IŞINLARIN KULLANIMINA DAYALI CİHAZLAR, VB.)"
    },
    {
        "kod": "267011",
        "tanim": "OBJEKTİF MERCEĞİ, LEVHA VE TABAKA HALİNDE POLARİZAN MADDE, RENK FİLTRESİ, OPTİK MERCEK, PRİZMA, AYNA VE DİĞER OPTİK ELEMANLAR İLE DÜRBÜN, OPTİK MİKROSKOP, OPTİK TELESKOP VE DİĞER ASTRONOMİK ALETLER İLE BUNLARIN AKSAM VE PARÇALARININ İMALATI"
    },
    {
        "kod": "267012",
        "tanim": "MİKROFİLM, MİKROFİŞ VE DİĞER MİKROFORM OKUYUCULARIN İMALATI"
    },
    {
        "kod": "267013",
        "tanim": "SİNEMATOGRAFİK KAMERALARIN VE PROJEKTÖRLERİN, DİYAPOZİTİF (SLAYT) VE DİĞER PROJEKTÖRLERİN İMALATI"
    },
    {
        "kod": "267016",
        "tanim": "FOTOĞRAF MAKİNESİ İMALATI (DİJİTAL, ANINDA GÖRÜNTÜ BASAN, DOKÜMANLARIN MİKROFİLM, VB. ÜZERİNE KAYDEDİLMESİNDE, DENİZ ALTINDA, HAVA FOTOĞRAFÇILIĞINDA, ADLİ TIP VEYA KRİMİNOLOJİK LABORATUVARLARDA, VB. KULLANILANLAR)"
    },
    {
        "kod": "267019",
        "tanim": "FLAŞ LAMBALARI, FOTOGRAFİK AGRANDİSÖRLER (BÜYÜTÜCÜLER), FOTOĞRAF LABORATUVARLARI İÇİN CİHAZLAR, NEGATOSKOPLAR (İNCE IŞIKLI PANEL), PROJEKSİYON EKRANLARI, LİKİT KRİSTAL CİHAZLAR İLE LAZERLERİN (LAZER DİYOTLAR HARİÇ) İMALATI"
    },
    {
        "kod": "268001",
        "tanim": "BOŞ MANYETİK SES VE GÖRÜNTÜ KASET BANTLARININ İMALATI (PLAK DAHİL)"
    },
    {
        "kod": "268002",
        "tanim": "MANYETİK ŞERİTLİ KARTLARIN İMALATI (BOŞ TELEFON KARTI DAHİL)"
    },
    {
        "kod": "268003",
        "tanim": "BOŞ CD, DVD, DİSKET, MAVİ IŞINLI (BLU-RAY) DİSK, VB. ÜRÜNLERİN İMALATI (DİSK ÜRETİMİ İÇİN KULLANILAN KALIP (MATRİS) VE MASTER DAHİL)"
    },
    {
        "kod": "268090",
        "tanim": "BYS. MANYETİK VE OPTİK ORTAMLARIN İMALATI"
    },
    {
        "kod": "271101",
        "tanim": "ELEKTRİK MOTORU, JENERATÖR VE TRANSFORMATÖRLERİN İMALATI (AKSAM VE PARÇALARI HARİÇ)"
    },
    {
        "kod": "271103",
        "tanim": "ELEKTRİK MOTORU, JENERATÖR VE TRANSFORMATÖRLERİN AKSAM VE PARÇALARININ İMALATI "
    },
    {
        "kod": "271201",
        "tanim": "ELEKTRİK DEVRELERİNİN ANAHTARLANMASI, KORUNMASI İLE ELEKTRİĞİN KONTROL VE DAĞITIMINA ÖZGÜ CİHAZLARIN İMALATI (SİGORTA, OTOMATİK DEVRE KESİCİ, RÖLE, YALITIM, DEVRE VE YÜK AYIRICI ANAHTARLAR, VOLTAJ SINIRLAYICI, DALGA BASTIRICI VB.) "
    },
    {
        "kod": "271202",
        "tanim": "ELEKTRİK DEVRELERİNİN ANAHTARLANMASI, KORUNMASI VE ELEKTRİĞİN KONTROL VE DAĞITIMINA ÖZGÜ CİHAZLARIN PARÇALARININ İMALATI (KUMANDA PANOSU İÇİN TABLO, KONSOL, KABİN VB. DİĞER MESNETLER DAHİL, ELEKTRİK DÜĞMESİ, FİŞİ VE PRİZİ HARİÇ)"
    },
    {
        "kod": "272001",
        "tanim": "ELEKTRİK AKÜMÜLATÖR PARÇALARININ İMALATI (AKÜMÜLATÖR PLAKALARI, SEPARATÖRLER, KURŞUN IZGARALAR) ( AKÜMÜLATÖR KUTU VE KAPAKLARININ İMALATI HARİÇ)"
    },
    {
        "kod": "272002",
        "tanim": "ŞARJ EDİLEMEYEN (BİRİNCİL) PİL VE BATARYALAR İLE BUNLARIN AKSAM VE PARÇALARININ İMALATI (MANGANEZ DİOKSİTLİ, CIVA OKSİTLİ, GÜMÜŞ OKSİTLİ, LİTYUM OKSİTLİ, ÇİNKO-HAVA REAKSİYONLU PİL VE BATARYALAR)"
    },
    {
        "kod": "272003",
        "tanim": "AKÜMÜLATÖR İMALATI (KURŞUN ASİTLİ, NİKEL KADMİYUM, NİKEL METAL HİDRİT, LİTYUM-İYON, LİTYUM POLİMER, NİKEL DEMİR VE DİĞER ELEKTRİK AKÜMÜLATÖRLERİ)"
    },
    {
        "kod": "272004",
        "tanim": "ŞARJ EDİLEBİLİR PİL VE BATARYA İLE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "272005",
        "tanim": "AKÜMÜLATÖR KUTU VE KAPAKLARININ İMALATI"
    },
    {
        "kod": "273104",
        "tanim": "FİBER OPTİK KABLOLARIN İMALATI"
    },
    {
        "kod": "273203",
        "tanim": "DİĞER ELEKTRONİK VE ELEKTRİK TELLERİ VE KABLOLARININ İMALATI (KOAKSİYEL KABLO VE DİĞER KOAKSİYEL ELEKTRİK İLETKENLERİ, YALITILMIŞ BOBİN TELLERİ, İZOLASYONLU TOPRAK SU ALTI İLETKENLER, ASETATLI VE SİLİKONLU BAKIR İLETKENLER, VB.) (FİBEROPTİK KABLO HARİÇ)"
    },
    {
        "kod": "273302",
        "tanim": "KABLOLAMADA KULLANILAN GEREÇLERİN İMALATI (FİŞ, SOKET, BASKILI, DÜĞMELİ VB. ANAHTAR, PRİZ, DUY, PLASTİKTEN ELEKTRİK BORU VE KABLO TABLALARI, MAKİNE VE CİHAZLARI İZOLE EDİCİ PLASTİK BAĞLANTI PARÇALARI, VB.) (ELEKTRONİK BİLEŞENLERDE KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "274001",
        "tanim": "DEŞARJ AMPULÜ, MOR ÖTESİ VEYA KIZIL ÖTESİ AMPUL, ARK AMPULÜ, TUNGSTEN HALOJEN FİLAMENTLİ AMPUL, DİĞER FİLAMENTLİ AMPUL İLE FOTOĞRAFÇILIKTA KULLANILAN FLAŞ AMPULÜ, FLAŞ KÜPÜ VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "274002",
        "tanim": "HAVA VE MOTORLU KARA TAŞITLARI İÇİN MONOBLOK FAR ÜNİTELERİ, KARA, HAVA VE DENİZ TAŞITLARI İÇİN ELEKTRİKLİ AYDINLATMA DONANIMLARI VEYA GÖRSEL SİNYALİZASYON EKİPMANLARI İMALATI (POLİS ARAÇLARI, AMBULANS VB. ARAÇLARIN DIŞ İKAZ LAMBALARI DAHİL)"
    },
    {
        "kod": "274003",
        "tanim": "AVİZE, APLİK VE DİĞER ELEKTRİKLİ AYDINLATMA ARMATÜRLERİ, SAHNE, FOTOĞRAF VEYA SİNEMA STÜDYOLARI İÇİN PROJEKTÖRLER VE SPOT IŞIKLARI, ELEKTRİKLİ MASA LAMBALARI, ÇALIŞMA LAMBALARI, ABAJUR VB. LAMBALARIN İMALATI (SÜSLEME İÇİN IŞIKLANDIRMA SETLERİ DAHİL)"
    },
    {
        "kod": "274004",
        "tanim": "SOKAK AYDINLATMA DONANIMLARININ İMALATI (TRAFİK IŞIKLARI HARİÇ)"
    },
    {
        "kod": "274005",
        "tanim": "PİL, AKÜMÜLATÖR VEYA MANYETO İLE ÇALIŞAN PORTATİF ELEKTRİK LAMBALARI VE ELEKTRİKSİZ LAMBALAR İLE EL FENERİ, GAZ VE LÜKS LAMBASI VB. AYDINLATMA ARMATÜRLERİNİN İMALATI  (TAŞITLAR İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "274006",
        "tanim": "IŞIKLI TABELA, IŞIKLI REKLAM PANOSU VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "274007",
        "tanim": "BYS DİĞER LAMBA VE AYDINLATMA ARMATÜRLERİ İLE LAMBALARIN, AYDINLATMA ARMATÜRÜ VE BENZERLERİNİN AKSAM VE PARÇALARININ İMALATI (CAM VEYA PLASTİKTEN OLANLAR HARİÇ)"
    },
    {
        "kod": "275102",
        "tanim": "EV TİPİ ELEKTRİKLİ SU ISITICILARI (DEPOLU SU ISITICILARI, ANINDA SU ISITICILARI, ŞOFBEN, TERMOSİFON DAHİL), ELEKTRİKLİ ISITMA CİHAZLARI (ELEKTRİKLİ SOBA, RADYATÖR, VB.) VE ELEKTRİKLİ TOPRAK ISITMA CİHAZLARININ İMALATI"
    },
    {
        "kod": "275103",
        "tanim": "EV TİPİ ELEKTRİKLİ SÜPÜRGE VE HALI TEMİZLEME/YIKAMA MAKİNELERİ İLE KURU VEYA ISLAK ELEKTRİKLİ SÜPÜRGELER, ŞARJLI VEYA PİLLİ EL SÜPÜRGELERİNİN İMALATI"
    },
    {
        "kod": "275104",
        "tanim": "MUTFAKTA KULLANILAN ELEKTRİKLİ KÜÇÜK EV ALETLERİNİN İMALATI (ÇAY VEYA KAHVE MAKİNESİ, SEMAVER, IZGARA, KIZARTMA CİHAZI, EKMEK KIZARTMA MAKİNESİ, MUTFAK ROBOTU, MİKSER, BLENDER, MEYVE SIKACAĞI, ET KIYMA MAKİNESİ, TOST MAKİNESİ, FRİTÖZ VB.)"
    },
    {
        "kod": "275105",
        "tanim": "ELEKTRİKLİ DİĞER KÜÇÜK EV ALETLERİ (ELEKTROTERMİK EL KURUTMA MAKİNESİ, ELEKTRİKLİ ÜTÜ, HAVLU DİSPENSERİ, HAVA NEMLENDİRİCİ) İLE ELEKTRİKLİ BATTANİYELERİN İMALATI"
    },
    {
        "kod": "275106",
        "tanim": "ELEKTRİKLİ KİŞİŞEL BAKIM EŞYALARININ İMALATI (ELEKTRİKLİ TIRAŞ MAKİNESİ, EPİLATÖR VE SAÇ KESME MAKİNESİ, ELEKTROTERMİK SAÇ ŞEKİLLENDİRME MAKİNESİ (SAÇ KURUTMA MAKİNESİ, BİGUDİ, TARAK, SAÇ MAŞASI), ELEKTRİKLİ DİŞ FIRÇASI, VB.)"
    },
    {
        "kod": "275107",
        "tanim": "ELEKTRİKLİ EV ALETLERİ AKSAM VE PARÇALARININ İMALATI"
    },
    {
        "kod": "275108",
        "tanim": "EV TİPİ BUZDOLABI, DONDURUCU, ÇAMAŞIR MAKİNESİ, ÇAMAŞIR KURUTMA MAKİNESİ, BULAŞIK MAKİNESİ, VANTİLATÖR, ASPİRATÖR, FAN, ASPİRATÖRLÜ DAVLUMBAZ, FIRIN, OCAK, MİKRODALGA FIRIN, ELEKTRİKLİ PİŞİRME SACI VB. İMALATI"
    },
    {
        "kod": "275190",
        "tanim": "BYS. DİĞER ELEKTRİKLİ EV ALETLERİNİN İMALATI"
    },
    {
        "kod": "275202",
        "tanim": "ELEKTRİKSİZ EV TİPİ GAZ, SIVI VEYA KATI YAKITLI SOBA, KUZİNE, IZGARA, ŞÖMİNE, MANGAL, SEMAVER, SU ISITICISI (TERMOSİFON, ŞOFBEN VB.) VB. ALETLERİN İMALATI"
    },
    {
        "kod": "275205",
        "tanim": "ELEKTRİKSİZ YEMEK PİŞİRME CİHAZLARININ İMALATI (GAZ YAKITLI SET ÜSTÜ OCAKLAR, GAZ VEYA SIVI YAKITLI FIRINLAR VE OCAKLAR VB.)"
    },
    {
        "kod": "275206",
        "tanim": "ELEKTRİKSİZ EV ALETLERİNİN AKSAM VE PARÇALARININ İMALATI"
    },
    {
        "kod": "279002",
        "tanim": "ELEKTRİK KONDANSATÖRLERİ, DİRENÇLERİ (ISITMA REZİSTANSLARI HARİÇ), REOSTALARI VE POTANSİYOMETRELERİN İMALATI"
    },
    {
        "kod": "279003",
        "tanim": "ELEKTRİKLİ SİNYALİZASYON, GÜVENLİK VEYA TRAFİK KONTROL EKİPMANLARININ İMALATI (DEMİR YOLLARI, KARA YOLLARI, İÇ SU YOLLARI, TAŞIT PARK ALANLARI, LİMANLAR VE HAVA MEYDANLARI İÇİN) (TRAFİK IŞIKLARI VE SİNYAL DONANIMLARI DAHİL)"
    },
    {
        "kod": "279004",
        "tanim": "KARBON ELEKTROTLAR VE ELEKTRİK İŞLERİNDE KULLANILAN GRAFİTTEN VEYA KARBONDAN DİĞER ÜRÜNLERİN İMALATI (ISITICI KÖMÜR REZİSTANSLAR, PİL KÖMÜRLERİ, ARK LAMBALARI VE DİĞER LAMBALAR İÇİN KÖMÜRLER VB. DAHİL)"
    },
    {
        "kod": "279005",
        "tanim": "ELEKTRİKLİ KAYNAK VE LEHİM TEÇHİZATI (LEHİM HAVYALARI, ARK KAYNAK MAKİNELERİ, ENDÜKSİYON KAYNAK MAKİNELERİ VB.) İLE METALLERİN VEYA SİNTERLENMİŞ METAL KARBÜRLERİN SICAK SPREYLENMESİ İÇİN ELEKTRİKLİ MAKİNE VE CİHAZLARININ İMALATI"
    },
    {
        "kod": "279006",
        "tanim": "SIVI KRİSTAL CİHAZLI (LCD) VEYA IŞIK YAYAN DİYOTLU (LED) GÖSTERGE PANELLERİ İLE BYS. ELEKTRİKLİ SESLİ VEYA GÖRSEL SİNYALİZASYON CİHAZLARININ İMALATI (ELEKTRONİK SAYI LEVHASI (SKORBORD) DAHİL)"
    },
    {
        "kod": "279008",
        "tanim": "KENDİNE ÖZEL FONKSİYONU OLAN ELEKTRİKLİ MAKİNE VE CİHAZLARIN İMALATI (ANTEN YÜKSELTEÇLERİ, ÇİTLERE ELEKTRİK VERİCİ CİHAZLAR, TERCÜME VEYA SÖZLÜK FONKSİYONLU ELEKTRİKLİ MAKİNELER, SES KAYIT CİHAZLARINDA KULLANILAN GÜRÜLTÜ AZALTMA ÜNİTELERİ VB.)"
    },
    {
        "kod": "279009",
        "tanim": "ELEKTRİK YALITKANLARININ (İZOLATÖRLERİNİN) İMALATI (CAM VE SERAMİKTEN OLANLAR HARİÇ)"
    },
    {
        "kod": "279010",
        "tanim": "SOLARYUM YATAKLARI, SOLARYUM LAMBALARI VB. BRONZLAŞMA EKİPMANLARININ İMALATI"
    },
    {
        "kod": "279090",
        "tanim": "BYS. ELEKTRİKLİ DİĞER EKİPMANLARIN İMALATI (ELEKTROMIKNATISLAR, ELEKTROMANYETİK KAPLİNLER, FRENLER VE VİNÇ BAŞLARI İLE ELEKTRİKLİ PARÇACIK HIZLANDIRICILAR, SİNYAL JENERATÖRLERİ VB.)"
    },
    {
        "kod": "281108",
        "tanim": "TÜRBİN VE TÜRBİN PARÇALARININ İMALATI (RÜZGAR, GAZ, SU VE BUHAR TÜRBİNLERİ İLE SU ÇARKLARI VE BUNLARIN PARÇALARI) (HAVA TAŞITLARI İÇİN TURBO JETLER VEYA TURBO PERVANELER HARİÇ)"
    },
    {
        "kod": "281109",
        "tanim": "DENİZ TAŞITLARINDA, DEMİR YOLU TAŞITLARINDA VE SANAYİDE KULLANILAN KIVILCIM ATEŞLEMELİ VEYA SIKIŞTIRMA ATEŞLEMELİ İÇTEN YANMALI MOTORLARIN VE BUNLARIN PARÇALARININ İMALATI (HAVA TAŞITI, MOTORLU KARA TAŞITI VE MOTOSİKLET MOTORLARI HARİÇ)"
    },
    {
        "kod": "281110",
        "tanim": "TÜM İÇTEN YANMALI MOTORLAR, DİZEL MOTORLAR VB.DE KULLANILAN PİSTONLAR, SİLİNDİRLER VE SİLİNDİR BLOKLARI, SİLİNDİR BAŞLARI, SİLİNDİR GÖMLEKLERİ, EMME VE EGZOS SUBAPLARI, SEGMANLAR, HAREKET KOLLARI, KARBÜRATÖRLER, YAKIT MEMELERİ VB.NİN İMALATI"
    },
    {
        "kod": "281205",
        "tanim": "AKIŞKAN GÜCÜ İLE ÇALIŞAN EKİPMANLARIN VE BUNLARIN PARÇALARININ İMALATI (HİDROLİK VE PNÖMATİK MOTORLAR, HİDROLİK POMPALAR, HİDROLİK VE PNÖMATİK VALFLER, HİDROLİK SİSTEMLER VE BUNLARIN PARÇALARI)"
    },
    {
        "kod": "281301",
        "tanim": "HAVA VEYA VAKUM POMPALARI İLE HAVA VEYA DİĞER GAZ KOMPRESÖRLERİNİN İMALATI (EL VE AYAKLA ÇALIŞAN HAVA POMPALARI İLE MOTORLU TAŞITLAR İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "281302",
        "tanim": "SIVI POMPALARI VE SIVI ELEVATÖRLERİ İMALATI (YAKIT, YAĞLAMA, SOĞUTMA VE DİĞER AMAÇLAR İÇİN) (DEPLASMANLI VE SANTRİFÜJLÜ POMPALAR İLE BENZİNLİKLERDE KULLANILAN AKARYAKIT POMPALARI DAHİL) (TULUMBA DAHİL, İÇTEN YANMALI MOTORLAR İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "281303",
        "tanim": "EL VE AYAKLA ÇALIŞAN HAVA POMPALARININ İMALATI"
    },
    {
        "kod": "281304",
        "tanim": "İÇTEN YANMALI MOTORLARA MONTE EDİLMEK ÜZERE TASARLANMIŞ POMPALARIN İMALATI (YAĞ POMPALARI, YAKIT POMPALARI (BENZİN, MAZOT VB. POMPALARI) VE SOĞUTMA POMPALARI)"
    },
    {
        "kod": "281401",
        "tanim": "DİĞER MUSLUK VE VALF/VANA İMALATI, DÖKME OLANLAR (SANAYİ MUSLUK, VALF VE VANALARI, SIHHİ TESİSAT VE ISITMADA KULLANILAN MUSLUK VE VANALAR İLE DOĞALGAZ VANALARI DAHİL)"
    },
    {
        "kod": "281402",
        "tanim": "DİĞER MUSLUK VE VALF/VANA İMALATI (SANAYİ MUSLUK, VALF VE VANALARI, SIHHİ TESİSAT VE ISITMADA KULLANILAN MUSLUK VE VANALAR İLE DOĞALGAZ VANALARI DAHİL, DÖKME OLANLAR HARİÇ)"
    },
    {
        "kod": "281501",
        "tanim": "RULMANLAR VE MEKANİK GÜÇ AKTARMA DONANIMLARI İMALATI (BİLYELİ VE MAKARALI RULMANLAR, AKTARMA MİLLERİ (ŞAFTLARI), KAM VE KRANK MİLLERİ, KRANKLAR VB. İLE RULMAN YATAKLARI, DÜZ MİL RULMANLARI, YATAK KOVANLARI VE MİL ŞAFT YATAKLARI VB.)"
    },
    {
        "kod": "281502",
        "tanim": "DEBRİYAJLAR (KAVRAMALAR), MİL (ŞAFT) KAPLİNLER VE ÜNİVERSAL MAFSALLARIN İMALATI (MOTORLU KARA TAŞITLARINDA KULLANILAN DEBRİYAJLAR HARİÇ)"
    },
    {
        "kod": "281503",
        "tanim": "DİŞLİLER/DİŞLİ TAKIMLARI, BİLYELİ VE MAKARALI VİDALAR, ŞANZIMANLAR, VİTES KUTULARI VE DİĞER HIZ DEĞİŞTİRİCİLERİN İMALATI (MOTORLU KARA TAŞITLARINDA KULLANILAN VİTES KUTULARI VE DİFERANSİYELLERİ HARİÇ)"
    },
    {
        "kod": "281504",
        "tanim": "VOLANLAR VE KASNAKLAR İLE MAFSALLI BAĞLANTI ZİNCİRLERİ VE GÜÇ AKTARIM ZİNCİRLERİNİNİN İMALATI"
    },
    {
        "kod": "282107",
        "tanim": "ELEKTRİKLİ VEYA ELEKTRİKSİZ LABORATUAR OCAKLARI, DÖKÜM OCAKLARI VB. ENDÜSTRİYEL OCAK VE FIRINLARININ İMALATI (ÇÖP YAKMA FIRINLARI İLE ELEKTRİKLİ EKMEK VE UNLU MAMUL FIRINLARI DAHİL)"
    },
    {
        "kod": "282108",
        "tanim": "OCAK BRÜLÖRLERİ (ATEŞLEYİCİLERİ) İMALATI"
    },
    {
        "kod": "282109",
        "tanim": "MEKANİK KÖMÜR TAŞIYICILARI, MEKANİK IZGARALAR, MEKANİK KÜL BOŞALTICILARI VE BENZERİ CİHAZLARIN İMALATI"
    },
    {
        "kod": "282110",
        "tanim": "GÜNEŞLE (GÜNEŞ KOLEKTÖRLERİ), BUHARLA  VE YAĞLA ISITMA SİSTEMLERİ İLE BENZERİ OCAK VE ISINMA DONANIMLARI GİBİ ELEKTRİKSİZ EV TİPİ ISITMA DONANIMLARININ İMALATI"
    },
    {
        "kod": "282111",
        "tanim": "ENDÜKSİYON VEYA DİELEKTRİK ISITMA EKİPMANLARININ İMALATI"
    },
    {
        "kod": "282190",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER FIRIN VE OCAKLARIN (SANAYİ OCAKLARI) İMALATI"
    },
    {
        "kod": "282210",
        "tanim": "EL VEYA MOTOR GÜCÜ İLE ÇALIŞAN KALDIRMA, TAŞIMA, YÜKLEME YA DA BOŞALTMA MAKİNELERİNİN İMALATI (VİNÇ PALANGASI, YÜK ASANSÖRÜ, BOCURGAT, DEMİR IRGAT, KRİKO, FORKLİFT, KALDIRMA VE TAŞIMA KULELERİ, VİNÇLER, HAREKETLİ KALDIRMA KAFESLERİ VB.)"
    },
    {
        "kod": "282211",
        "tanim": "ASANSÖR, YÜRÜYEN MERDİVEN VE YÜRÜYEN YOLLARIN İMALATI (YERALTINDA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "282212",
        "tanim": "PNÖMATİK VE DİĞER DEVAMLI HAREKETLİ ASANSÖR, ELAVATÖR VE KONVEYÖRLERİN İMALATI"
    },
    {
        "kod": "282213",
        "tanim": "DİĞER KALDIRMA, TAŞIMA, YÜKLEME VEYA BOŞALTMA MAKİNELERİNİN İMALATI (TELEFERİKLER, TELESİYEJLER VB. İÇİN ÇEKME MEKANİZMALARI, TARIMSAL KULLANIM İÇİN YÜKLEME MAKİNELERİ VE DİĞERLERİ)"
    },
    {
        "kod": "282301",
        "tanim": "HESAP MAKİNELERİ VE HESAPLAMA FONKSİYONU OLUP VERİLEN BİLGİLERİ KAYDEDEN, KAYDEDİLEN BİLGİLERİ YENİDEN VEREN VE GÖSTEREN CEP TİPİ MAKİNELERİN İMALATI (ELEKTRİKLİ, ELEKTRONİK, MEKANİK VB.)"
    },
    {
        "kod": "282302",
        "tanim": "DİKTE MAKİNELERİNİN İMALATI (TAŞINABİLİR VE KÜÇÜK SES KAYIT CİHAZLARI)"
    },
    {
        "kod": "282303",
        "tanim": "YAZARKASA İMALATI"
    },
    {
        "kod": "282304",
        "tanim": "PARA SAYMA VE PARA PAKETLEME MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "282305",
        "tanim": "DAKTİLO, STENOGRAFİ VE KELİME İŞLEM MAKİNELERİ İMALATI (ELEKTRİKLİ VEYA ELEKTRİKSİZ) (KABARTMA YAZI YAZANLAR DAHİL)"
    },
    {
        "kod": "282306",
        "tanim": "FOTOKOPİ VE TERMOKOPİ MAKİNELERİ İLE BÜRO TİPİ OFSET BASKI MAKİNELERİNİN (KAĞIT EBADI <=22X36 CM) İMALATI"
    },
    {
        "kod": "282307",
        "tanim": "TONER KARTUŞU, DELGİ ALETİ, ZIMBA MAKİNESİ, BANT KESİCİSİ, YAZI TAHTASI (AKILLI TAHTA DAHİL), KALEMTIRAŞ VB. BÜRO ALET VE DONANIMLARININ İMALATI"
    },
    {
        "kod": "282308",
        "tanim": "TEKSİR MAKİNELERİ, POSTA İŞLEME MAKİNELERİ, ADRES BASMA MAKİNELERİ İLE DİĞER BÜRO MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "282401",
        "tanim": "MOTORLU VEYA PNÖMATİK EL ALETLERİNİN İMALATI (ZIMPARALAMA, TAŞLAMA, PARLATMA VB. ELEKTRİKLİ ELLE KULLANILAN ALETLER İLE DAİRESEL VEYA ZİNCİRLİ TESTERE, MATKAP, ÇİVİLEME ALETİ, PERÇİN TABANCASI VB.)"
    },
    {
        "kod": "282501",
        "tanim": "SANAYİ TİPİ SOĞUTUCU VE DONDURUCU DONANIMLARI İLE ISI POMPALARININ İMALATI (CAMEKANLI, TEZGAHLI VEYA MOBİLYA TİPİ SOĞUTUCULAR, KONDENSERLERİ ISI DEĞİŞTİRİCİSİ FONKSİYONU GÖREN KOMPRESÖRLÜ ÜNİTELER VB.)"
    },
    {
        "kod": "282502",
        "tanim": "SANAYİ TİPİ FAN VE VANTİLATÖRLERİN İMALATI (ÇATI HAVALANDIRMA PERVANELERİ DAHİL)"
    },
    {
        "kod": "282503",
        "tanim": "İKLİMLENDİRME CİHAZLARININ (KLİMALAR) İMALATI (MOTORLU TAŞITLARDA KULLANILANLAR DAHİL)"
    },
    {
        "kod": "282504",
        "tanim": "ISI DEĞİŞTİRİCİ BİRİMLERİN (EŞANJÖRLER), HAVA VEYA DİĞER GAZLARIN SIVILAŞTIRILMASINDA KULLANILAN MAKİNELERİN VE HAVA/GAZLARIN FİLTRELENMESİ VE ARITILMASI İÇİN KULLANILAN MAKİNE VE CİHAZLARIN İMALATI"
    },
    {
        "kod": "282901",
        "tanim": "PETROL RAFİNERİLERİ, KİMYA SANAYİ, İÇECEK SANAYİ VB. İÇİN DAMITMA VE REKTİFİYE DONANIMLARI İMALATI"
    },
    {
        "kod": "282902",
        "tanim": "GAZ JENERATÖRLERİ, SU GAZI JENERATÖRLERİ, ASETİLEN GAZI JENERATÖRLERİ VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "282903",
        "tanim": "ŞİŞELERİ VEYA DİĞER MUHAFAZA KAPLARINI TEMİZLEME VE KURUTMA MAKİNELERİ İMALATI (KAVANOZ, BİDON, FIÇI, KUTU VB.)"
    },
    {
        "kod": "282904",
        "tanim": "SIVILAR İÇİN FİLTRELEME VEYA ARITMA MAKİNE VE CİHAZLARININ İMALATI (SUYUN FİLTRE EDİLMESİ/ARITILMASINA MAHSUS CİHAZLAR DAHİL)"
    },
    {
        "kod": "282905",
        "tanim": "DOLDURMA, PAKETLEME VE AMBALAJLAMA MAKİNELERİNİN İMALATI (DOLDURMA, KAPATMA, MÜHÜRLEME, KAPSÜLLEME VEYA ETİKETLEME VE İÇECEKLERİ GAZLANDIRMA VB. İÇİN MAKİNELER)"
    },
    {
        "kod": "282906",
        "tanim": "OTOMATİK ÜRÜN SATIŞ MAKİNELERİNİN İMALATI (YİYECEK, İÇECEK, VB. OTOMATİK SATIŞ MAKİNESİ) (PARA BOZMA MAKİNELERİ DAHİL)"
    },
    {
        "kod": "282907",
        "tanim": "METAL TABAKALARDAN CONTALARIN VE MEKANİK SALMASTRALARIN İMALATI (DİĞER MALZEMELERLE BİRLEŞTİRİLMİŞ METAL TABAKALARDAN VEYA İKİ YA DA DAHA FAZLA METAL TABAKASINDAN YAPILMIŞ OLANLAR)"
    },
    {
        "kod": "282908",
        "tanim": "TARTI ALETLERİ VE BASKÜL İMALATI (EV VE DÜKKANLARDA KULLANILAN TERAZİ VE KANTARLAR, SÜREKLİ ÖLÇÜM İÇİN TARTILAR, TAŞIT BASKÜLLERİ (KÖPRÜ TİPİ BASKÜLLER) VB.) (KUYUMCULUKTA VE LABORATUVARLARDA KULLANILAN HASSAS TARTILAR HARİÇ)"
    },
    {
        "kod": "282909",
        "tanim": "SANTRİFÜJ İMALATI (KREMA MAKİNESİ, ÇAMAŞIR KURUTMA MAKİNESİ, LABORATUVARLARDA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "282910",
        "tanim": "YANGIN SÖNDÜRÜCÜLER, PÜSKÜRTME TABANCALARI, BUHAR VEYA KUM PÜSKÜRTME MAKİNELERİ VB. SIVI VE TOZLARI ATAN, DAĞITAN YA DA PÜSKÜRTEN MEKANİK CİHAZLARIN İMALATI"
    },
    {
        "kod": "282911",
        "tanim": "ELEKTRİKLİ OLMAYAN KAYNAK VE LEHİM ALETLERİ İLE GAZLA ÇALIŞAN YÜZEY TEMPERLEME (MENEVİŞLEME) MAKİNE VE CİHAZLARININ İMALATI (PÜRMÜZ VE ŞALÜMOLAR DAHİL)"
    },
    {
        "kod": "282912",
        "tanim": "SANAYİ TİPİ BULAŞIK MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "282917",
        "tanim": "KALENDER VEYA DİĞER HADDE MAKİNELERİNİN İMALATI (METAL VE CAM İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "282918",
        "tanim": "İÇTEN YANMALI MOTORLAR İÇİN YAĞ FİLTRESİ, YAKIT FİLTRESİ, HAVA FİLTRESİ, GRES NİPELLERİ, YAĞ KEÇESİ VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "282919",
        "tanim": "SEVİYE TESPİT ALETLERİ (NİVOLAR), ÖLÇÜ ÇUBUKLARI, MEZURA, ÇELİK METRE VE CETVELLER İLE ELLE KULLANILAN DİĞER ÖLÇÜ ALETLERİNİN İMALATI"
    },
    {
        "kod": "282920",
        "tanim": "MADDELERİN ISI DEĞİŞİMİ YOLUYLA İŞLENMESİ İÇİN BYS. MAKİNELERİN İMALATI (SU SİRKÜLASYONU YOLUYLA DOĞRUDAN SOĞUTMA İÇİN SOĞUTMA KULELERİ VE BENZERLERİ İLE METALLERİN BUHAR BİRİKTİRME YOLUYLA KAPLANMASI İÇİN VAKUM-BUHAR TESİSLERİ VB.)"
    },
    {
        "kod": "283008",
        "tanim": "TARIMSAL AMAÇLI RÖMORK VEYA YARI-RÖMORK İMALATI"
    },
    {
        "kod": "283009",
        "tanim": "YUMURTA, MEYVE VE DİĞER TARIMSAL ÜRÜNLERİN TEMİZLENMESİ, TASNİF EDİLMESİ VEYA DERECELENDİRİLMESİ İÇİN KULLANILAN MAKİNE VE EKİPMANLARIN İMALATI"
    },
    {
        "kod": "283010",
        "tanim": "TRAKTÖRLERİN VE YAYA KONTROLLÜ TRAKTÖRLERİN (MOTOKÜLTÖRLER) İMALATI"
    },
    {
        "kod": "283011",
        "tanim": "KÜMES HAYVANI MAKİNELERİ, ARICILIK MAKİNELERİ VE HAYVAN YEMİ HAZIRLAMA MAKİNELERİNİN VE DONANIMLARININ İMALATI (KULUÇKA MAKİNELERİ DAHİL)"
    },
    {
        "kod": "283012",
        "tanim": "ÇİM BİÇME MAKİNELERİNİN İMALATI (TRAKTÖRLERE MONTE EDİLEN KESİCİ BARLAR DAHİL)"
    },
    {
        "kod": "283013",
        "tanim": "HASAT VE HARMAN MAKİNELERİNİN İMALATI (BİÇER-DÖVER, SAMAN YAPMA MAKİNESİ, OT VE SAMAN BALYALAMA MAKİNESİ, KÖK VE YUMRU HASAT MAKİNESİ, VB.)"
    },
    {
        "kod": "283014",
        "tanim": "PULLUK, SABAN, TIRMIK, DİSKARO, SKARİFİKATÖR, KÜLTİVATÖR, ÇAPA MAKİNESİ, MİBZER, FİDE VE FİDAN DİKİM MAKİNESİ VB. TOPRAĞIN HAZIRLANMASINDA, EKİMİNDE, DİKİMİNDE KULLANILAN ALETLER İLE GÜBRELEME MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "283015",
        "tanim": "SÜT SAĞMA MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "283016",
        "tanim": "TARIM VE BAHÇECİLİKTE KULLANILAN SIVI VEYA TOZ ATMA, DAĞITMA VEYA PÜSKÜRTME MAKİNELERİNİN İMALATI (SULAMA CİHAZLARI, PÜLVERİZATÖRLER, ZİRAİ MÜCADELEDE KULLANILAN PORTATİF SIVI VE TOZ PÜSKÜRTÜCÜLER VB.)"
    },
    {
        "kod": "283017",
        "tanim": "ORMANCILIĞA ÖZGÜ MAKİNELER İLE TARLA BAHÇE BAKIMINA MAHSUS DİĞER MAKİNE VE CİHAZLARIN İMALATI"
    },
    {
        "kod": "284101",
        "tanim": "TAKIM TEZGAHLARI (METAL İŞLEMEK İÇİN LAZER VE BENZERLERİYLE ÇALIŞANLAR) İLE METAL VE BENZERLERİNİ İŞLEMEK İÇİN İŞLEME MERKEZLERİNİN İMALATI"
    },
    {
        "kod": "284103",
        "tanim": "METAL TORNALAMA, DELME, FREZELEME VE PLANYALAMA TAKIM TEZGAHLARININ İMALATI"
    },
    {
        "kod": "284106",
        "tanim": "METAL İŞLEMEK İÇİN KULLANILAN DİĞER TAKIM TEZGAHLARININ İMALATI"
    },
    {
        "kod": "284107",
        "tanim": "METAL İŞLEYEN TAKIM TEZGAHLARININ PARÇA VE AKSESUARLARININ İMALATI (ALET TUTACAKLARI VE KENDİNDEN AÇILAN PAFTA KAFALARI, İŞ TUTACAKLARI, AYIRICI KAFALAR VE TAKIM TEZGAHLARI İÇİN DİĞER ÖZEL AKSESUARLAR HARİÇ)"
    },
    {
        "kod": "284902",
        "tanim": "ELEKTRO KAPLAMA MAKİNELERİNİN İMALATI (GALVANOPLASTİ, ELEKTRO KAPLAMA, ELEKTROLİZ VEYA ELEKTROFOREZ İÇİN)"
    },
    {
        "kod": "284903",
        "tanim": "TAŞ, SERAMİK, BETON VEYA BENZERİ MİNERAL MALZEMELERİ İŞLEMEK VEYA CAMI SOĞUK İŞLEMEK İÇİN OLAN TAKIM TEZGAHI İLE BUNLARIN PARÇALARININ İMALATI (TESTERE, TAŞLAMA, PARLATMA, VB.)"
    },
    {
        "kod": "284904",
        "tanim": "AHŞAP, MANTAR, KEMİK, SERT KAUÇUK, SERT PLASTİK VEYA BENZERİ SERT MALZEMELERİ İŞLEMEK İÇİN OLAN TAKIM TEZGAHI İLE BUNLARIN PARÇALARININ İMALATI (TRANSFER, TESTERE, PLANYA, FREZE, TAŞLAMA, ZIMPARALAMA, PARLATMA, BÜKME, DELME, DİLİMLEME, PRES, VB.)"
    },
    {
        "kod": "284905",
        "tanim": "TAKIM TEZGAHLARI VE EL ALETLERİ İÇİN TAKIM TUTUCULARI VE KENDİNDEN AÇILAN PAFTA KAFALARI, İŞLENECEK PARÇA TUTUCULARI, BÖLME BAŞLIKLARI VE DİĞER ÖZEL EK PARÇALAR, DİNGİLLER, YÜKSÜKLER VE RAKORLAR İLE FİKSTÜRLERİN İMALATI"
    },
    {
        "kod": "284990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER TAKIM TEZGAHLARININ İMALATI"
    },
    {
        "kod": "289101",
        "tanim": "KONVERTÖRLER (METALÜRJİ), KÜLÇE KALIPLARI (İNGOT KALIPLARI), DÖKÜM KEPÇELERİ, DÖKÜM MAKİNELERİ, VB. SICAK METALLERİN İŞLENMESİ İÇİN KULLANILAN MAKİNE VE TEÇHİZATIN İMALATI"
    },
    {
        "kod": "289102",
        "tanim": "SICAK VE SOĞUK METAL HADDELEME MAKİNESİ VE METAL BORU İMALİNE ÖZGÜ HADDE MAKİNESİ İLE HADDE VE METALÜRJİ MAKİNELERİ İÇİN SİLİNDİR VE DİĞER PARÇALARIN İMALATI"
    },
    {
        "kod": "289201",
        "tanim": "BETON VE HARÇ KARIŞTIRICILARIN İMALATI (MİKSERLER DAHİL, BETON KARIŞTIRICILI (MİKSERLİ) KAMYONLAR HARİÇ)"
    },
    {
        "kod": "289202",
        "tanim": "BULDOZER, ANGLEDOZER, GREYDER, SKREYPER, DÜZLEYİCİ, ÖNDEN KÜREYİCİ-YÜKLEYİCİ, KEPÇELİ YÜKLEYİCİ, MEKANİK KEPÇE, EKSKAVATÖR, KAZIK ÇAKMA (KAZIK VARYOSLARI) VE SÖKME MAKİNELERİ, HARÇ VE ASFALT YAYICILAR İLE BETON KAPLAMA MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "289203",
        "tanim": "TAŞ, TOPRAK, CEVHER, ALÇI, ÇİMENTO VE DİĞER MİNERAL MADDELERİ TASNİF ETME, ELEME, AYIRMA, YIKAMA, EZME, ÖĞÜTME, KARIŞTIRMA, YOĞURMA VB. İŞLEMDEN GEÇİRME İÇİN KULLANILAN MAKİNELERİN İMALATI (BETON VE HARÇ KARIŞTIRICILAR (MİKSERLER) HARİÇ)"
    },
    {
        "kod": "289205",
        "tanim": "KÖMÜR VEYA KAYA KESİCİLERİ (HAVÖZLER), TÜNEL VE KUYU AÇMA MAKİNELERİ İLE DELME VE SONDAJ MAKİNELERİNİN İMALATI (YERALTI VEYA YERÜSTÜ)"
    },
    {
        "kod": "289206",
        "tanim": "YER ALTI KULLANIMI İÇİN SÜREKLİ HAREKETLİ ELEVATÖR VE KONVEYÖRLERİN İMALATI"
    },
    {
        "kod": "289208",
        "tanim": "PALETLİ TRAKTÖRLERİN İMALATI (İNŞAAT VEYA MADENCİLİKTE KULLANILAN TRAKTÖRLER)"
    },
    {
        "kod": "289209",
        "tanim": "KARA YOLU DIŞINDA KULLANILAN DAMPERLİ KAMYONLARIN İMALATI (MEGA KAMYONLAR)"
    },
    {
        "kod": "289210",
        "tanim": "KAR KÜREYİCİ VE PÜSKÜRTÜCÜLERİ, TOPRAĞI SIKIŞTIRMAYA VEYA BASTIRIP SIKIŞTIRMAYA MAHSUS MAKİNELER İLE MADEN, TAŞOCAĞI, İNŞAAT, İMAR, PARK VB. İŞLER İÇİN KULLANILAN DİĞER MAKİNELERİN İMALATI"
    },
    {
        "kod": "289211",
        "tanim": "DELME, SONDAJ, HAFRİYAT VE KAZI MAKİNESİ PARÇALARININ, VİNÇ VE HAREKETLİ KALDIRMA KAFESLERİNİN VE TOPRAK, TAŞ VE BENZERİ MADDELERİ TASNİFLEME, ÖĞÜTME, KARIŞTIRMA VEYA DİĞER İŞLERDE KULLANILAN MAKİNE PARÇALARININ İMALATI (BULDOZER BIÇAKLARI DAHİL)"
    },
    {
        "kod": "289301",
        "tanim": "GIDA VE İÇECEKLERİN ENDÜSTRİYEL OLARAK HAZIRLANMASI VEYA İMALATI İÇİN BYS. MAKİNELERİN İMALATI (EKMEK, BİSKÜVİ, MAKARNA, ŞEKERLEME, ÇİKOLATA, ŞEKER, ET, MEYVE, SEBZE, SIVI VE KATI YAĞLAR VB.NİN HAZIRLANMASI VEYA İMALATI İÇİN SANAYİ MAKİNELERİ)"
    },
    {
        "kod": "289302",
        "tanim": "ŞARAP, MEYVE SUYU VE BENZERİ İÇECEKLERİN İMALATINDA KULLANILAN MAKİNELERİN İMALATI (PRESLER, EZİCİLER VE BENZERİ MAKİNELER)"
    },
    {
        "kod": "289303",
        "tanim": "SÜT ÜRÜNLERİ MAKİNELERİNİN VE SANTRİFÜJLÜ KREMA AYIRICILARININ İMALATI (HOMOJENİZELEŞTİRİCİLER, İRRADYATÖRLER (IŞINLAYICILAR), YAĞ YAPMA MAKİNELERİ, PEYNİR YAPMA MAKİNELERİ VB.)"
    },
    {
        "kod": "289304",
        "tanim": "TÜTÜNÜN HAZIRLANMASINDA VE İŞLENMESİNDE KULLANILAN MAKİNELERİN İMALATI (TÜTÜN YAPRAKLARINI DAMARLARINDAN AYIRAN MAKİNELER İLE ENFİYE, SİGARA, PURO, PİPO TÜTÜNÜ VEYA ÇİĞNEME TÜTÜNLERİ İMALİNDE KULLANILAN MAKİNELER)"
    },
    {
        "kod": "289306",
        "tanim": "DEĞİRMENCİLİK SANAYİİNDE, HUBUBAT VEYA KURUTULMUŞ SEBZELERİN İŞLENMESİ VEYA ÖĞÜTÜLMESİ İÇİN KULLANILAN MAKİNELERİN İMALATI (UN, KABA UN VB. ÜRETMEK İÇİN KULLANILAN MAKİNELER, ELEKLER, KEPEK TEMİZLEYİCİLER, ÇELTİK SOYMA MAKİNESİ VB.)"
    },
    {
        "kod": "289307",
        "tanim": "EKMEK VE DİĞER UNLU MAMULLER İÇİN ELEKTRİKLİ OLMAYAN FIRINLARIN İMALATI (GAZ, SIVI VE KATI YAKITLI OLANLAR)"
    },
    {
        "kod": "289308",
        "tanim": "EV TİPİ OLMAYAN PİŞİRME VEYA ISITMA CİHAZLARININ İMALATI (EV TİPİ OLMAYAN FİLTRELİ KAHVE MAKİNELERİ VB. DAHİL)"
    },
    {
        "kod": "289309",
        "tanim": "TARIMSAL ÜRÜNLER İÇİN KURUTUCULARIN İMALATI (KAHVE, KURUYEMİŞ VB. İÇİN KAVURMA MAKİNE VE CİHAZLARI DAHİL)"
    },
    {
        "kod": "289310",
        "tanim": "TOHUMLARIN, TANELERİN VEYA KURU BAKLAGİLLERİN TEMİZLENMESİ, TASNİF EDİLMESİ VEYA DERECELENDİRİLMESİ İÇİN KULLANILAN MAKİNELERİN İMALATI (TARIMSAL SELEKTÖRLER DAHİL)"
    },
    {
        "kod": "289401",
        "tanim": "POST, DERİ VE KÖSELELERİN İŞLENMESİ İLE AYAKKABI VE DİĞER DERİ EŞYALARIN ÜRETİMİ VEYA TAMİRİ İÇİN KULLANILAN MAKİNELERİN İMALATI"
    },
    {
        "kod": "289402",
        "tanim": "SANAYİ TİPİ ÇAMAŞIR MAKİNESİ, KURU TEMİZLEME MAKİNESİ, ÇAMAŞIR KURUTMA MAKİNESİ, ÜTÜ MAKİNESİ VE PRES ÜTÜ İMALATI"
    },
    {
        "kod": "289403",
        "tanim": "SANAYİ VE EV TİPİ DİKİŞ MAKİNELERİNİN İMALATI (DİKİŞ MAKİNELERİNİN İĞNELERİ, MOBİLYALARI, TABANLARI, KAPAKLARI VB. PARÇALARI DAHİL)"
    },
    {
        "kod": "289404",
        "tanim": "SUNİ VE SENTETİK TEKSTİL MALZEMESİNİN EKSTRÜZYONU, ÇEKİLMESİ, TEKSTÜRE EDİLMESİ VEYA KESİLMESİ İÇİN KULLANILAN MAKİNELER İLE DOĞAL TEKSTİL ELYAFI HAZIRLAMA MAKİNELERİ VE DOKUMA MAKİNELERİNİN İMALATI (ÇIRÇIR MAKİNESİ, TARAKLAMA MAKİNESİ VB. DAHİL)"
    },
    {
        "kod": "289405",
        "tanim": "TEKSTİL İPLİĞİ VE KUMAŞINI YIKAMA, AĞARTMA, BOYAMA, APRELEME, TEMİZLEME, SIKMA, SARMA, EMPRENYE ETME, BİTİRME, KESME, SURFİLE VE BENZERLERİ İÇİN MAKİNELER İLE KEÇE İMALATINDA VE BİTİRİLMESİNDE KULLANILAN MAKİNELERİN İMALATI"
    },
    {
        "kod": "289406",
        "tanim": "TEKSTİL BÜKÜM MAKİNELERİ İLE KATLAMA, BÜKME, BOBİNE SARMA VEYA ÇİLE YAPMA MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "289407",
        "tanim": "ÖRGÜ, TRİKOTAJ VE BENZERİ MAKİNELER İLE TAFTİNG MAKİNELERİNİN İMALATI (GİPE İPLİK, TÜL, DANTEL, NAKIŞ, SÜS, ÖRGÜ VEYA AĞ YAPMA MAKİNELERİ DAHİL)"
    },
    {
        "kod": "289408",
        "tanim": "TEKSTİL AMAÇLI MAKİNELERLE KULLANILAN YARDIMCI MAKİNELERİN VE TEKSTİL BASKI MAKİNELERİNİN İMALATI (RATİYERLER, JAKARDLAR, VB.) (OFSET BASKI MAKİNELERİ, TİPOGRAFİK, FLEKSOGRAFİK, GRAVÜR BASKI MAKİNELERİ HARİÇ)"
    },
    {
        "kod": "289409",
        "tanim": "TEKSTİL, GİYİM EŞYASI VE DERİ ÜRETİMİNDE KULLANILAN MAKİNELERİN PARÇALARININ İMALATI (DİKİŞ MAKİNELERİNDE KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "289501",
        "tanim": "KAĞIT VE MUKAVVA ÜRETİMİNDE KULLANILAN MAKİNELERİN VE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "289601",
        "tanim": "PLASTİK VE KAUÇUK MAKİNELERİNİN İMALATI (PLASTİK VE KAUÇUK İŞLEMEK İÇİN VEYA BU MALZEMELERDEN ÜRÜN İMALATI İÇİN KULLANILAN MAKİNELER)"
    },
    {
        "kod": "289901",
        "tanim": "BASIM VE CİLTLEME MAKİNELERİ İLE BASIMA YARDIMCI MAKİNELERİN VE BUNLARIN PARÇALARININ İMALATI (OFSET BASKI MAKİNESİ, TİPOGRAFİK BASKI MAKİNESİ, DİZGİ MAKİNESİ, BASKI KALIPLARI İÇİN MAKİNELER, CİLTLEME MAKİNESİ VB.) (BÜRO TİPİ BASKI MAKİNESİ HARİÇ)"
    },
    {
        "kod": "289902",
        "tanim": "CAM VE CAM EŞYA İMALATINDA VE CAM EŞYALARIN SICAK İŞLENMESİNDE KULLANILAN MAKİNELERİN VE ELEKTRİKLİ VEYA ELEKTRONİK LAMBA, TÜP, AMPUL MONTAJINDA KULLANILAN MAKİNELERİN İMALATI"
    },
    {
        "kod": "289904",
        "tanim": "KİREMİT, BRİKET, ŞEKİLLİ SERAMİK HAMURU, BORU, GRAFİK ELEKROTU, YAZI TAHTASI TEBEŞİRİ VB. ÜRÜNLERİN ÜRETİLMESİNDE KULLANILAN MAKİNELERİN İMALATI"
    },
    {
        "kod": "289905",
        "tanim": "OTOMATİK BOVLİNG SALONU DONANIMLARININ, DÖNME DOLAP, ATLI KARINCA, SALINCAK, POLİGON, VB. DİĞER PANAYIR ALANI EĞLENCE DONANIMLARI İLE KUMARHANE OYUN MASALARININ  İMALATI"
    },
    {
        "kod": "289906",
        "tanim": "HAVA TAŞITI FIRLATMA DONANIMLARININ, UÇAK GEMİLERİNDE KULLANILAN KATAPULTLARIN (KISA MESAFEDE HAVA TAŞITLARININ KALKMASINI SAĞLAYAN MEKANİZMA) VE İLGİLİ DONANIMLARIN İMALATI"
    },
    {
        "kod": "289907",
        "tanim": "YARI İLETKEN TEK KRİSTALLİ KÜLÇE (BOULES) VE YONGA PLAKALAR İLE YARI İLETKEN AYGITLARIN, ELEKTRONİK ENTEGRE DEVRE VEYA DÜZ PANEL EKRANLARIN İMALATI İÇİN KULLANILAN MAKİNE VE CİHAZLARIN İMALATI"
    },
    {
        "kod": "289908",
        "tanim": "SİCİM VE HALAT MAKİNELERİNİN İMALATI"
    },
    {
        "kod": "289909",
        "tanim": "LASTİK TEKERLERİN BALANSINDA VE HİZALANMASINDA KULLANILAN DONANIMLARIN İMALATI (JANT İÇİN KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "289910",
        "tanim": "ÖZEL AMAÇLAR İÇİN ÇOKLU GÖREVLERDE KULLANILABİLEN SANAYİ ROBOTLARININ İMALATI"
    },
    {
        "kod": "289911",
        "tanim": "KURUTUCULARIN İMALATI (ODUN, KAĞIT HAMURU, KAĞIT, MUKAVVA, SÜT TOZU VE DİĞER MALZEMELERİN İMALATINDA KULLANILANLAR) (EV TİPİ, TARIM ÜRÜNLERİ VE TEKSTİL İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "289912",
        "tanim": "İZOTOPİK AYIRMA MAKİNELERİ VE CİHAZLARININ İMALATI"
    },
    {
        "kod": "289990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER ÖZEL AMAÇLI MAKİNELERİN İMALATI"
    },
    {
        "kod": "291001",
        "tanim": "KAMYONET, KAMYON, YARI RÖMORKLAR İÇİN ÇEKİCİLER, TANKERLER, VB. KARAYOLU TAŞITLARININ İMALATI"
    },
    {
        "kod": "291002",
        "tanim": "OTOMOBİL VE BENZERİ ARAÇLARIN İMALATI"
    },
    {
        "kod": "291003",
        "tanim": "MOTORLU KARA TAŞITLARININ MOTORLARININ İMALATI (MOTORLARIN FABRİKADA YENİDEN YAPIMI DAHİL)"
    },
    {
        "kod": "291004",
        "tanim": "MİNİBÜS, MİDİBÜS, OTOBÜS, TROLEYBÜS, METROBÜS, VB. YOLCU NAKİL ARAÇLARININ İMALATI"
    },
    {
        "kod": "291005",
        "tanim": "KAR MOTOSİKLETİ, GOLF ARABASI, ATV MOTOSİKLETLER, GO-KART ARABALARI VB. TAŞITLARIN İMALATI"
    },
    {
        "kod": "291007",
        "tanim": "ÖZEL AMAÇLI MOTORLU KARA TAŞITLARININ İMALATI (AMFİBİ ARAÇLAR, ÇÖP KAMYONU, YOL TEMİZLEME ARAÇLARI, ZIRHLI NAKİL ARAÇLARI, MİKSERLİ KAMYON, VİNÇLİ KAMYON, İTFAİYE ARACI, AMBULANS, MOTORLU KARAVAN VB.)"
    },
    {
        "kod": "291008",
        "tanim": "MOTORLU KARA TAŞITLARI İÇİN ŞASİ İMALATI"
    },
    {
        "kod": "292001",
        "tanim": "TREYLER (RÖMORK), YARI TREYLER (YARI RÖMORK) VE MEKANİK HAREKET ETTİRİCİ TERTİBATI BULUNMAYAN DİĞER ARAÇLARIN PARÇALARININ İMALATI (BU ARAÇLARIN KAROSERLERİ, KASALARI, AKSLARI VE DİĞER PARÇALARI)"
    },
    {
        "kod": "292002",
        "tanim": "MOTORLU KARA TAŞITLARI İÇİN KAROSER, KABİN, KUPA, DORSE VE DAMPER İMALATI (OTOMOBİL, KAMYON, KAMYONET, OTOBÜS, MİNİBÜS, TRAKTÖR, DAMPERLİ KAMYON VE ÖZEL AMAÇLI MOTORLU KARA TAŞITLARININ KAROSERLERİ)"
    },
    {
        "kod": "292003",
        "tanim": "KONTEYNER İMALATI (BİR VEYA DAHA FAZLA TAŞIMA ŞEKLİNE GÖRE ÖZEL OLARAK TASARLANMIŞ OLANLAR)"
    },
    {
        "kod": "292004",
        "tanim": "TREYLER (RÖMORK) VE YARI TREYLER (YARI RÖMORK) İMALATI (KARAVAN TİPİNDE OLANLAR VE TARIMSAL AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "292005",
        "tanim": "KARAVAN TİPİNDE TREYLER (RÖMORK) VE YARI TREYLER (YARI RÖMORK) İMALATI - EV OLARAK VEYA KAMP İÇİN"
    },
    {
        "kod": "292006",
        "tanim": "MOTORLU KARA TAŞITLARININ MODİFİYE EDİLMESİ VE KAROSER HİZMETLERİ"
    },
    {
        "kod": "293104",
        "tanim": "MOTORLU TAŞITLAR İÇİN ATEŞLEME KABLO TAKIMLARI VE DİĞER KABLO SETLERİ İLE ATEŞLEME BUJİSİ VE MANYETOSU, DİNAMO, MANYETİK VOLAN, DİSTRİBÜTÖR, ATEŞLEME BOBİNİ, MARŞ MOTORU, ALTERNATÖR VB. İMALATI"
    },
    {
        "kod": "293105",
        "tanim": "MOTORLU KARA TAŞITLARI VE MOTOSİKLETLER İÇİN ELEKTRİKLİ SİNYALİZASYON DONANIMLARI,  KORNALAR, SİRENLER, CAM SİLECEKLERİ, BUĞU ÖNLEYİCİLER, ELEKTRİKLİ CAM/KAPI SİSTEMLERİ, VOLTAJ REGÜLATÖRLERİ VB. ELEKTRİKLİ EKİPMANLARIN İMALATI"
    },
    {
        "kod": "293106",
        "tanim": "OTO ALARM SİSTEMLERİNİN İMALATI"
    },
    {
        "kod": "293107",
        "tanim": "BİSİKLETLER İÇİN ELEKTRİKLİ VEYA PİLLE ÇALIŞAN AYDINLATMA VEYA İŞARET CİHAZLARININ İMALATI (BİSİKLET DİNAMOLARI DAHİL)"
    },
    {
        "kod": "293220",
        "tanim": "MOTORLU KARA TAŞITLARI İÇİN DİĞER PARÇA VE AKSESUARLARIN İMALATI (FREN, VİTES KUTUSU, JANT, SÜSPANSİYON SİSTEMLERİ, AMORTİSÖR, RADYATÖR, EGZOZ, DEBRİYAJ, DİREKSİYON KUTUSU, ROT, ROTBAŞI, ROTİL VB.) (TRAKTÖR, İTFAİYE ARAÇLARI, VB. İÇİN OLANLAR DAHİL)"
    },
    {
        "kod": "293221",
        "tanim": "MOTORLU KARA TAŞITLARI İÇİN KAROSER, KABİN VE KUPALARA AİT PARÇA VE AKSESUARLARIN İMALATI (TAMPONLAR, KOLTUK EMNİYET KEMERLERİ, HAVA YASTIKLARI, KAPILAR VB. DAHİL) "
    },
    {
        "kod": "293222",
        "tanim": "MOTORLU KARA TAŞITLARI İÇİN KOLTUK İMALATI (DEMİRYOLU VE HAVAYOLU İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "301101",
        "tanim": "YÜZEN VE SU ALTINDA KALABİLEN SONDAJ PLATFORMLARININ İNŞASI FAALİYETLERİ"
    },
    {
        "kod": "301102",
        "tanim": "YOLCU GEMİ VE TEKNELERİ, FERİBOTLAR, TANKERLER, FRİGORİFİK GEMİLER, KURU YÜK GEMİLERİ, ÇEKİCİ VE İTİCİ RÖMORKÖRLER, TARAK GEMİLERİ, AÇIK DENİZ GEMİLERİ, HOVER KRAFTLARIN VE DİĞER GEMİLERİN İNŞASI (SPOR VE EĞLENCE AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "301103",
        "tanim": "SAVAŞ GEMİLERİ VE DENİZALTILARIN İMALATI"
    },
    {
        "kod": "301104",
        "tanim": "BALIKÇI GEMİ VE TEKNELERİ İLE DENİZ ÜRÜNLERİNİN İŞLENMESİNE VE SAKLANMASINA YÖNELİK FABRİKA GEMİLERİNİN YAPIMI"
    },
    {
        "kod": "301105",
        "tanim": "YÜZEN RIHTIMLAR, DUBALAR, BATARDOLAR, KOFERDAMLAR, YÜZEN İSKELELER, ŞAMANDIRALAR, YÜZEN TANKLAR, MAVNALAR, SALAPURYALAR, YÜZEN VİNÇLER, EĞLENCE AMAÇLI OLMAYAN ŞİŞME BOTLAR VB. İMALATI"
    },
    {
        "kod": "301106",
        "tanim": "GEMİLER VE YÜZER YAPILAR İÇİN OTURULACAK YERLERİN İMALATI"
    },
    {
        "kod": "301107",
        "tanim": "GEMİLER VE YÜZER YAPILAR İÇİN İÇ BÖLMELERİN İMALATI"
    },
    {
        "kod": "301108",
        "tanim": "GEMİLERİN, YÜZER PLATFORMLARIN VE YÜZER YAPILARIN BÜYÜK ÇAPTA DEĞİŞTİRİLMESİ VE YENİDEN İNŞASI"
    },
    {
        "kod": "301201",
        "tanim": "JET SKİ VB. KİŞİSEL SU ARAÇLARININ İMALATI"
    },
    {
        "kod": "301203",
        "tanim": "ŞİŞİRİLEBİLİR MOTORLU/MOTORSUZ BOTLARIN İMALATI (EĞLENCE VE SPOR AMAÇLI OLANLAR)"
    },
    {
        "kod": "301204",
        "tanim": "EĞLENCE VE SPORTİF AMAÇLI MOTORLU/MOTORSUZ YELKENLİLERİN, MOTORLU TEKNE VE YATLARIN, SANDALLARIN, KAYIKLARIN, KANOLARIN, EĞLENCE AMAÇLI HOVER KRAFTLARIN VE BENZER ARAÇLARIN İMALATI (POLYESTER TEKNELER DAHİL)"
    },
    {
        "kod": "302001",
        "tanim": "DEMİR YOLU VE TRAMVAY LOKOMOTİFLERİ, VAGONLARI, BAGAJ VAGONLARI, LOKOMOTİF TENDERLERİ, DEMİR YOLU VEYA TRAMVAY BAKIM VEYA SERVİS ARAÇLARI İMALATI (LOKOMOTİFLERE VE VAGONLARA AİT PARÇALAR İLE KOLTUKLARININ İMALATI HARİÇ)"
    },
    {
        "kod": "302002",
        "tanim": "DEMİR YOLU VE TRAMVAY LOKOMOTİF VEYA VAGONLARININ PARÇALARININ İMALATI"
    },
    {
        "kod": "302003",
        "tanim": "RAYLI SİSTEM TAŞITLARI İÇİN KOLTUK İMALATI"
    },
    {
        "kod": "302004",
        "tanim": "MEKANİK VEYA ELEKTROMEKANİK SİNYALİZASYON, EMNİYET VEYA TRAFİK KONTROL CİHAZLARI VE BUNLARIN PARÇALARININ İMALATI (DEMİR YOLU, TRAMVAY HATLARI, KARA YOLLARI, DAHİLİ SU YOLLARI, PARK YERLERİ, LİMAN TESİSLERİ VEYA HAVA ALANLARI İÇİN OLANLAR)"
    },
    {
        "kod": "302005",
        "tanim": "DEMİR YOLU VEYA TRAMVAY LOKOMOTİFLERİNİN VE VAGONLARININ BÜYÜK ÇAPTA YENİLENMESİ VE DONANIM HİZMETLERİ (TAMAMLAMA)"
    },
    {
        "kod": "303001",
        "tanim": "HELİKOPTER İMALATI (HELİKOPTER VEYA HELİKOPTER MOTORLARININ FABRİKALARDA BÜYÜK ÇAPLI REVİZYONU VE DEĞİŞTİRİLMESİ DAHİL)"
    },
    {
        "kod": "303002",
        "tanim": "HAVA TAŞITI PARÇALARININ İMALATI (UÇAK GÖVDESİ, KANATLARI, KAPILARI, KUMANDA YÜZEYLERİ, İNİŞ TAKIMLARI GİBİ ANA MONTAJ PARÇALARI, PERVANELER, HELİKOPTER ROTORLARI, MOTORLAR, TURBO JETLER, TURBO PERVANELİ MOTORLAR VB. İLE BUNLARIN PARÇALARI)"
    },
    {
        "kod": "303003",
        "tanim": "SICAK HAVA BALONU, ZEPLİN, PLANÖR, DELTA KANATLI PLANÖR VE DİĞER MOTORSUZ HAVA ARAÇLARININ İMALATI"
    },
    {
        "kod": "303004",
        "tanim": "UÇAK VE BENZER HAVA TAŞITLARININ İMALATI (UÇAK VEYA UÇAK MOTORLARININ FABRİKALARDA BÜYÜK ÇAPLI REVİZYONU VE DEĞİŞTİRİLMESİ DAHİL)"
    },
    {
        "kod": "303005",
        "tanim": "YER UÇUŞ EĞİTİM CİHAZLARI VE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "303006",
        "tanim": "UZAY ARACI, UZAY ARACI FIRLATMA ARAÇLARI VE MEKANİZMALARI İLE UYDULAR, UZAY ROKETLERİ, YÖRÜNGE İSTASYONLARI VE UZAY MEKİKLERİNİN İMALATI"
    },
    {
        "kod": "303007",
        "tanim": "KITALAR ARASI BALİSTİK FÜZELERİN (ICBM) İMALATI"
    },
    {
        "kod": "303008",
        "tanim": "HAVA TAŞITLARI VE UZAY ARAÇLARINDA KULLANILAN KOLTUKLARIN İMALATI"
    },
    {
        "kod": "304001",
        "tanim": "ASKERİ SAVAŞ ARAÇLARININ İMALATI (TANK, ZIRHLI SAVAŞ ARAÇLARI VE BUNLARIN PARÇALARI)"
    },
    {
        "kod": "309101",
        "tanim": "MOTOSİKLET, MOPED VE MOTORLU BİSİKLET (BİR YARDIMCI MOTORU BULUNAN BİSİKLETLER) İMALATI"
    },
    {
        "kod": "309102",
        "tanim": "MOTOSİKLET PARÇA VE AKSESUARLARI İMALATI (SELE, MOTOSİKLET YAN SEPETİ, MOTOSİKLET VİTESİ VB.)"
    },
    {
        "kod": "309103",
        "tanim": "MOTOSİKLET MOTORLARI İMALATI"
    },
    {
        "kod": "309201",
        "tanim": "MOTORSUZ BİSİKLET İMALATI (ÜÇ TEKERLEKLİ SERVİS BİSİKLETİ, İKİ YA DA DAHA FAZLA KİŞİLİK BİSİKLET, YARIŞ BİSİKLETİ, VİTESLİ BİSİKLET) (ÇOCUKLAR İÇİN PLASTİK BİSİKLETLER HARİÇ)"
    },
    {
        "kod": "309202",
        "tanim": "BİSİKLET PARÇA VE AKSESUARLARININ İMALATI (JANTLAR, GİDONLAR, İSKELET, ÇATALLAR, PEDAL FREN GÖBEKLERİ/POYRALARI, GÖBEK/POYRA FRENLERİ, KRANK-DİŞLİLERİ, PEDALLAR VE SERBEST DİŞLİLERİN PARÇALARI, VB.)"
    },
    {
        "kod": "309203",
        "tanim": "ENGELLİ ARAÇLARININ İMALATI (MOTORLU, MOTORSUZ, AKÜLÜ, ŞARJLI, VB.)"
    },
    {
        "kod": "309204",
        "tanim": "ENGELLİ ARAÇLARININ PARÇA VE AKSESUARLARININ İMALATI"
    },
    {
        "kod": "309205",
        "tanim": "BEBEK ARABALARI, PUSETLER VE BUNLARIN PARÇALARININ İMALATI"
    },
    {
        "kod": "309901",
        "tanim": "MEKANİK HAREKET ETTİRİCİ TERTİBATI BULUNMAYAN ARAÇLARIN İMALATI (ALIŞVERİŞ ARABALARI, SANAYİ EL ARABALARI, İŞPORTACI ARABALARI, BAGAJ ARABALARI, ELLE ÇEKİLEN GOLF ARABALARI, HASTA NAKLİ İÇİN ARABALAR, KIZAKLAR DAHİL)"
    },
    {
        "kod": "309902",
        "tanim": "HAYVANLAR TARAFINDAN ÇEKİLEN ARAÇLARIN İMALATI (AT, EŞEK ARABASI, FAYTON, VB.)"
    },
    {
        "kod": "309990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER ULAŞIM EKİPMANLARININ İMALATI"
    },
    {
        "kod": "310101",
        "tanim": "BÜRO, OKUL, İBADETHANE, OTEL, LOKANTA, SİNEMA, TİYATRO VB. KAPALI ALANLAR İÇİN MOBİLYA İMALATI (TAŞ, BETON, SERAMİKTEN OLANLAR HARİÇ) (VESTİYER, DOSYA DOLAPLARI, MİHRAPLAR, MİNBERLER, KÜRSÜLER, ÖĞRENCİ SIRALARI, BÜRO TİPİ SANDALYE VE KOLTUKLAR, VB.)"
    },
    {
        "kod": "310102",
        "tanim": "LABORATUVARLAR VE TEKNİK BÜROLAR İÇİN TEZGAHLARIN VE MOBİLYALARIN İMALATI (MİKROSKOP MASALARI, LABORATUVAR MASALARI (VİTRİNLİ, GAZ MEMELİ, MUSLUK TERTİBATLI, VB. OLSUN OLMASIN), ÇEKER OCAKLAR, TEÇHİZATSIZ ÇİZİM MASALARI, VB.)"
    },
    {
        "kod": "310103",
        "tanim": "MAĞAZALAR İÇİN TEZGAH, BANKO, VİTRİN, RAF, ÇEKMECELİ DOLAP VB. ÖZEL MOBİLYA İMALATI (LABORATUVARLAR VE TEKNİK BÜROLAR İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "310104",
        "tanim": "BÜRO MOBİLYALARININ İSKELETLERİNİN İMALATI"
    },
    {
        "kod": "310201",
        "tanim": "MUTFAK MOBİLYALARININ İMALATI"
    },
    {
        "kod": "310301",
        "tanim": "YATAK İMALATI (YATAK DESTEKLERİ, KAUÇUK ŞİŞME YATAK VE SU YATAĞI HARİÇ)"
    },
    {
        "kod": "310302",
        "tanim": "YATAK DESTEKLERİNİN İMALATI (YAYLI VEYA ÇELİK TEL AĞLI AHŞAP VEYA METAL İSKELETLER, AHŞAP LATALI DÖŞENMİŞ SOMYA BAZALARI, SOMYA, KARYOLA, VB.)"
    },
    {
        "kod": "310901",
        "tanim": "MOBİLYALARIN BOYANMASI, VERNİKLENMESİ, CİLALANMASI VB. TAMAMLAYICI İŞLERİN YAPILMASI"
    },
    {
        "kod": "310902",
        "tanim": "SANDALYELERİN, KOLTUKLARIN VB. DÖŞENMESİ GİBİ TAMAMLAYICI İŞLERİN YAPILMASI (BÜRO VE EV MOBİLYALARININ YENİDEN KAPLANMASI HARİÇ)"
    },
    {
        "kod": "310903",
        "tanim": "DİKİŞ MAKİNESİ, TV, BİLGİSAYAR, VB. İÇİN DOLAP, SEHPA, VB. MOBİLYALARIN İMALATI"
    },
    {
        "kod": "310904",
        "tanim": "YATAK ODASI, YEMEK ODASI, BANYO DOLABI, GENÇ VE ÇOCUK ODASI TAKIMI, GARDIROP, VESTİYER, VB. İMALATI (GÖMME DOLAP, MASA, ZİGON, VB. DAHİL)"
    },
    {
        "kod": "310905",
        "tanim": "SANDALYE, KOLTUK, KANEPE, ÇEKYAT, DİVAN, VB İSKELETLERİNİN İMALATI (İSKELETÇİLER) (PLASTİK OLANLAR İLE BÜROLARDA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "310906",
        "tanim": "PARK VE BAHÇELERDE KULLANILAN BANK, MASA, TABURE, SANDALYE, KOLTUK, VB. MOBİLYALARIN İMALATI (PLASTİK OLANLAR HARİÇ)"
    },
    {
        "kod": "310907",
        "tanim": "SANDALYE, KOLTUK, KANEPE, OTURMA TAKIMI, ÇEKYAT, DİVAN, MARKİZ, VB. İMALATI (PLASTİK OLANLAR İLE BÜROLARDA VE PARK VE BAHÇELERDE KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "310908",
        "tanim": "PLASTİKTEN BANK, MASA, TABURE, SANDALYE VB. MOBİLYALARIN İMALATI"
    },
    {
        "kod": "321101",
        "tanim": "MADENİ PARA BASIMI"
    },
    {
        "kod": "321201",
        "tanim": "DEĞERLİ METALLERDEN TAKI VE MÜCEVHERLERİN İMALATI (DEĞERLİ METALLERLE BASKI, YAPIŞTIRMA VB. YÖNTEMLERLE GİYDİRİLMİŞ ADİ METALLERDEN OLANLAR DAHİL)"
    },
    {
        "kod": "321203",
        "tanim": "DEĞERLİ METALLERDEN YAPILAN TEKNİK VE LABORATUVAR MALZEMELERİ İMALATI (MADEN ERİTME KAPLARI, SPATULALAR, ELEKTROLİTİK KAPLAMA ANOTLARI, VB. DAHİL)"
    },
    {
        "kod": "321204",
        "tanim": "İNCİ VE DEĞERLİ DOĞAL TAŞLARIN İŞLENMESİ  VE DEĞERLİ TAŞLARDAN TAKI VE MÜCEVHER İLE BUNLARIN PARÇALARININ İMALATI (SENTETİK VEYA YENİDEN OLUŞTURULMUŞ OLANLAR DAHİL)"
    },
    {
        "kod": "321206",
        "tanim": "DEĞERLİ OLSUN OLMASIN METAL EŞYALAR ÜZERİNE OYMA VE KABARTMA YAPILMASI FAALİYETLERİ"
    },
    {
        "kod": "321207",
        "tanim": "SANAYİ ELMASLARININ İŞLENMESİ"
    },
    {
        "kod": "321208",
        "tanim": "DEĞERLİ METALLERDEN VEYA DEĞERLİ METALLERLE PRESLENEREK KAPLANMIŞ ADİ METALLERDEN YEMEK TAKIMI, ÇATAL BIÇAK TAKIMI, TUVALET MALZEMESİ, BÜRO MALZEMESİ, VB. MALZEMELERİN İMALATI"
    },
    {
        "kod": "321301",
        "tanim": "İMİTASYON (TAKLİT) TAKILAR VE İLGİLİ EŞYALARIN İMALATI"
    },
    {
        "kod": "322021",
        "tanim": "ELEKTRONİK MÜZİK ALETLERİ VEYA KLAVYELİ ÇALGILARIN İMALATI (ELEKTRİK GÜCÜYLE SES ÜRETEN VEYA SESİ GÜÇLENDİRİLEN ENSTRÜMANLAR) (DİJİTAL PİYANO, SİNTİZAYZIR, ELEKTROGİTAR, VB.)"
    },
    {
        "kod": "322022",
        "tanim": "DİĞER YAYLI/TELLİ MÜZİK ALETLERİNİN İMALATI (SAZ, GİTAR, KEMAN, VB.)"
    },
    {
        "kod": "322023",
        "tanim": "AĞIZLARI HUNİ GİBİ GENİŞLEYEN NEVİDEN OLAN BORU ESASLI MÜZİK ALETLERİ İLE DİĞER ÜFLEMELİ MÜZİK ALETLERİNİN İMALATI (SAKSAFON, FLÜT, TROMBON, BORAZAN, VB.)"
    },
    {
        "kod": "322024",
        "tanim": "VURMALI ÇALGILARIN İMALATI (TRAMPET, DAVUL, KSİLOFON, ZİL, KAS VS.)"
    },
    {
        "kod": "322025",
        "tanim": "PİYANOLAR VE DİĞER KLAVYELİ YAYLI/TELLİ ÇALGILARIN İMALATI"
    },
    {
        "kod": "322026",
        "tanim": "BORULU VE KLAVYELİ ORGLAR, ARMONYUMLAR, AKORDİYONLAR, AĞIZ MIZIKALARI (ARMONİKALAR), TULUM VB. ÇALGILARIN İMALATI"
    },
    {
        "kod": "322027",
        "tanim": "MÜZİK KUTULARI, ORKESTRİYONLAR, LATERNALAR, ÇINGIRAKLAR VB. İMALATI"
    },
    {
        "kod": "322028",
        "tanim": "METRONOMLAR, AKORT ÇATALLARI (DİYAPAZONLAR) VE AKORT DÜDÜKLERİ, MÜZİK KUTULARI İÇİN MEKANİZMALAR, MÜZİK ALETİ TELLERİ İLE MÜZİK ALETLERİNİN PARÇA VE AKSESUARLARININ İMALATI"
    },
    {
        "kod": "322090",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER MÜZİK ALETLERİNİN İMALATI"
    },
    {
        "kod": "323017",
        "tanim": "KAR KAYAKLARI, KAYAK AYAKKABILARI, KAYAK BOTLARI, KAYAK BATONLARI, BUZ PATENLERİ VE TEKERLEKLİ PATENLER İLE SU KAYAĞI ARAÇLARI, SÖRF TAHTALARI, RÜZGAR SÖRFLERİ VB. EKİPMANLAR İLE BUNLARIN PARÇALARININ İMALATI (KAYKAYLAR DAHİL)"
    },
    {
        "kod": "323018",
        "tanim": "JİMNASTİK VE ATLETİZM EŞYALARI İLE FORM TUTMA SALONLARINA AİT EŞYA VE EKİPMANLARIN İMALATI (ATLAMA BEYGİRİ, DAMBIL VE HALTERLER, KÜREK ÇEKME VE BİSİKLETE BİNME ALETLERİ, CİRİTLER, ÇEKİÇLER; BOKS ÇALIŞMA TOPLARI, BOKS VEYA GÜREŞ İÇİN RİNGLER VB.)"
    },
    {
        "kod": "323019",
        "tanim": "SPOR AMAÇLI DAĞCILIK, AVCILIK VEYA BALIKÇILIK EŞYALARININ İMALATI (KASKLAR, OLTA KAMIŞLARI, OLTA İĞNELERİ VE KANCALARI, OTOMATİK OLTA MAKARALARI, EL KEPÇELERİ, KELEBEK AĞLARI, YAPMA BALIKLAR, SİNEKLER GİBİ SUNİ YEMLER, KURŞUNLAR, YAPMA KUŞLAR VB.)"
    },
    {
        "kod": "323020",
        "tanim": "SPOR VEYA AÇIK HAVA OYUNLARI İÇİN DİĞER EŞYALARIN İMALATI (BOKS ELDİVENİ, SPOR ELDİVENİ, YAYLAR, BEYZBOL VE GOLF SOPALARI İLE TOP VE DİĞER EŞYALARI, TENİS MASASI, RAKET, AĞ VE TOPLARI, TOZLUKLAR, BACAK KORUYUCULAR, ŞİŞME VE DİĞER HAVUZLAR VB.)"
    },
    {
        "kod": "323021",
        "tanim": "TOP İMALATI (BEYZBOL, FUTBOL, BASKETBOL VE VOLEYBOL İÇİN)"
    },
    {
        "kod": "324001",
        "tanim": "OYUN KAĞIT VE KARTLARININ İMALATI (İSKAMBİL VB.)"
    },
    {
        "kod": "324002",
        "tanim": "BOZUK PARA VEYA JETONLA ÇALIŞAN OYUN MAKİNELERİ İLE BİLARDO İÇİN KULLANILAN EŞYA VE AKSESUARLARIN İMALATI (RULET VB. OYUN MAKİNELERİ İLE BİLARDO MASA VE İSTEKALARI, İSTEKA DAYANAKLARI, BİLARDO TOPLARI, TEBEŞİRLERİ, TOPLU VEYA SÜRGÜLÜ PUAN SAYAÇLARI VB.)"
    },
    {
        "kod": "324003",
        "tanim": "YAP BOZ, PUZZLE VE BENZERİ ÜRÜNLERİN İMALATI (LEGO VB. DAHİL)"
    },
    {
        "kod": "324004",
        "tanim": "İÇİ DOLDURULMUŞ OYUNCAK BEBEKLERİN VE OYUNCAK HAYVANLARIN İMALATI"
    },
    {
        "kod": "324005",
        "tanim": "OYUNCAK BEBEK, KUKLA VE HAYVANLAR İLE BUNLARIN GİYSİ, PARÇA VE AKSESUARLARININ İMALATI (İÇİ DOLDURULMUŞ OLANLAR HARİÇ)"
    },
    {
        "kod": "324006",
        "tanim": "LUNAPARK, MASA VE SALON OYUNLARI İÇİN GEREÇLERİN İMALATI"
    },
    {
        "kod": "324007",
        "tanim": "OYUNCAK MÜZİK ALETLERİ İMALATI"
    },
    {
        "kod": "324008",
        "tanim": "BİNMEK İÇİN TASARLANMIŞ TEKERLEKLİ OYUNCAKLARIN İMALATI (PLASTİK BİSİKLETLER VE ÜÇ TEKERLEKLİ BİSİKLETLER DAHİL)"
    },
    {
        "kod": "324009",
        "tanim": "OYUN TAHTALARI (SATRANÇ, DAMA, DART, TAVLA TAHTALARI, OKEY İSTEKASI, GO VB.) VE TABU, MONOPOL VB. OYUNLARIN İMALATI"
    },
    {
        "kod": "324010",
        "tanim": "TEKERLEKLİ OYUNCAKLAR, OYUNCAK BEBEK ARABALARI, OYUNCAK TRENLER VE DİĞER KÜÇÜLTÜLMÜŞ BOYUTLU MODELLER/MAKETLER VEYA İNŞAAT OYUN TAKIMLARI, YARIŞ SETLERİ İMALATI (MOTORLU OLANLAR, PRES DÖKÜM OYUNCAKLAR VE PLASTİK DİĞER OYUNCAKLAR DAHİL)"
    },
    {
        "kod": "324011",
        "tanim": "ELEKTRONİK OYUN İMALATI (ELEKTRONİK DAMALAR, SATRANÇ VB.) (TELEVİZYONLA BİRLİKTE KULLANILAN VİDEO OYUN KONSOLLARI HARİÇ)"
    },
    {
        "kod": "324090",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ OYUN VE OYUNCAKLARIN İMALATI"
    },
    {
        "kod": "325001",
        "tanim": "GÖZLÜK (GÖZ KUSURLARINI GİDERİCİ, DÜZELTİCİ, KORUYUCU VE DİĞER AMAÇLI), GÖZLÜK CAMI, KONTAK LENS İLE GÖZLÜK VE BENZERİ İÇİN ÇERÇEVE VE ÇERÇEVE PARÇALARININ İMALATI"
    },
    {
        "kod": "325002",
        "tanim": "SUNİ UZUVLAR, PROTEZ VE ORTOPEDİK ÜRÜNLER İLE BUNLARIN PARÇA VE AKSESUARLARININ İMALATI (SUNİ EKLEM, DİŞÇİLİKLE İLGİLİ BAĞLANTI PARÇALARI, ORTOPEDİK AYAKKABI VE KORSE, DİŞ TELİ, TIBBİ ÇİVİ, FITIK BAĞI VB.)"
    },
    {
        "kod": "325003",
        "tanim": "DİŞÇİLİKTE KULLANILAN ARAÇ-GEREÇ VE CİHAZLARIN İMALATI (DİŞÇİ AERATÖRLERİ DAHİL) (ŞIRINGA, İĞNE, KATATER, KANÜL VE BENZERLERİ HARİÇ)"
    },
    {
        "kod": "325004",
        "tanim": "TIBBİ, CERRAHİ, DİŞÇİLİK VEYA VETERİNERLİKLE İLGİLİ MOBİLYALARIN, BERBER KOLTUKLARI VE BENZERİ SANDALYELER İLE BUNLARIN PARÇALARININ İMALATI (AMELİYAT VE TETKİK MASASI, AYARLANABİLİR HASTANE YATAĞI, DİŞÇİ KOLTUĞU, VB.) (X IŞINI MASA VE KOLTUKLARI HARİÇ)"
    },
    {
        "kod": "325006",
        "tanim": "DİŞÇİ ÇİMENTOSU, DİŞÇİLİK MUMLARI, DOLGU MADDESİ, KEMİK TEDAVİSİNDE KULLANILAN ÇİMENTO, JEL PREPARAT, STERİL ADHEZYON BARİYERİ, DİKİŞ MALZEMESİ (KATGÜT HARİÇ), DOKU YAPIŞTIRICISI, LAMİNARYA, EMİLEBİLİR HEMOSTATİK, VB. İMALATI"
    },
    {
        "kod": "325007",
        "tanim": "TIPTA, CERRAHİDE, DİŞÇİLİKTE VEYA VETERİNERLİKTE KULLANILAN ŞIRINGA, İĞNE, KATATER, KANÜL VE BENZERLERİNİN İMALATI"
    },
    {
        "kod": "325008",
        "tanim": "GÖZ TEDAVİSİ İLE İLGİLİ CERRAHİ, TANI, TEST VE BENZERİ ALETLERİN İMALATI (KORNEAYA AİT YUVARLAK TESTERELER, OFTALMOSKOP, RETİNOSKOP, KERATOMETRELER, VB.)"
    },
    {
        "kod": "325009",
        "tanim": "MEKANO TERAPİ CİHAZLARI, MASAJ ALETLERİ, PSİKOLOJİK EĞİLİM-TESTİ ALETLERİ (TAMAMEN HAREKETSİZ MEKANO TERAPİ CİHAZLARI HARİÇ), OZON TERAPİ, OKSİJEN TERAPİ, AEROSOL TERAPİ VE SOLUNUM CİHAZLARI İMALATI"
    },
    {
        "kod": "325010",
        "tanim": "TIBBİ, CERRAHİ VEYA LABORATUVAR STERİLİZASYON ALETLERİNİN İMALATI"
    },
    {
        "kod": "325011",
        "tanim": "TANSİYON ALETLERİ, TANSİYOMETRELER, OSİLOMETRELER, TIBBİ ENDOSKOPLAR, KLİNİK VEYA VETERİNERLİK TERMOMETRELERİ, BÖBREK DİYALİZ CİHAZLARI, TRANSFÜZYON CİHAZLARI (KAN DEPOLAMA İÇİN ÖZEL CAM ŞİŞELER HARİÇ) İMALATI"
    },
    {
        "kod": "325012",
        "tanim": "ANESTEZİ CİHAZ VE ALETLERİ, DİYATERMİK CİHAZLAR (ULTRASONİKLER DAHİL), ULTRASONİK LİTOTRİPSİ ALETLERİ VE LABORATUVARLARDA KULLANILAN SANTRİFÜJLERİN İMALATI"
    },
    {
        "kod": "325013",
        "tanim": "DİŞ LABORATUVARLARININ FAALİYETLERİ (PROTEZ DİŞ, METAL KURON, VB. İMALATI)"
    },
    {
        "kod": "325090",
        "tanim": "TIPTA, CERRAHİDE, DİŞÇİLİKTE VEYA VETERİNERLİKTE KULLANILAN BYS. DİĞER ARAÇ VE GEREÇLERİN İMALATI"
    },
    {
        "kod": "329101",
        "tanim": "EV VEYA BÜRO TEMİZLİĞİ İÇİN OLAN SÜPÜRGE VE FIRÇALARIN İMALATI (ELEKTRİKLİ OLANLAR HARİÇ)"
    },
    {
        "kod": "329102",
        "tanim": "BOYAMA, BADANA, DUVAR KAĞIDI VE VERNİK FIRÇALARI İLE RULOLARININ İMALATI"
    },
    {
        "kod": "329103",
        "tanim": "DİŞ FIRÇALARI, SAÇ FIRÇALARI, TIRAŞ FIRÇALARI VE KİŞİSEL BAKIM İÇİN KULLANILAN DİĞER FIRÇALAR İLE RESİM FIRÇALARI, YAZI FIRÇALARI VE KOZMETİK FIRÇALARIN İMALATI"
    },
    {
        "kod": "329190",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER SÜPÜRGE VE FIRÇALARIN İMALATI (ELEKTRİKLİ OLANLAR HARİÇ)"
    },
    {
        "kod": "329901",
        "tanim": "TERZİ MANKENİ, EL KALBUR VE ELEĞİ, YAPMA ÇİÇEK, MEYVE VE BİTKİLER, ŞAKA VE SİHİRBAZLIK BENZERİ EŞYA, KOKU PÜSKÜRTÜCÜLERİ VE MEKANİZMALARI, TABUT VB. EŞYALARIN İMALATI (GELİN ÇİÇEĞİ DAHİL)"
    },
    {
        "kod": "329902",
        "tanim": "KOT VB. BASKI DÜĞMELERİ, ÇITÇITLAR, DÜĞMELER, FERMUARLAR VB. İMALATI (DÜĞME FORMLARI VE FERMUAR PARÇALARI DAHİL)"
    },
    {
        "kod": "329903",
        "tanim": "PİPO, SİGARA AĞIZLIKLARI, OLTU VEYA LÜLE TAŞINDAN TESPİH VB. İMALATI"
    },
    {
        "kod": "329904",
        "tanim": "MEKANİK OLSUN VEYA OLMASIN HER ÇEŞİT DOLMA KALEM, TÜKENMEZ VE KURŞUN KALEM İLE BOYA KALEMİ, PASTEL BOYA İMALATI (KALEM UCU VE KURŞUN KALEM İÇLERİ DAHİL)"
    },
    {
        "kod": "329906",
        "tanim": "PERUK, TAKMA SAÇ, TAKMA SAKAL, TAKMA KAŞ VB. İMALATI"
    },
    {
        "kod": "329907",
        "tanim": "ŞEMSİYELER, GÜNEŞ ŞEMSİYELERİ, BASTON VE KOLTUKLU BASTON, KOLTUK DEĞNEĞİ VB. İMALATI (PARÇALARI DAHİL)"
    },
    {
        "kod": "329908",
        "tanim": "TARİH VERME, DAMGA, MÜHÜR VEYA NUMARA VERME KAŞELERİ, NUMERATÖR, ELLE ÇALIŞAN BASIM ALETLERİ, KABARTMA ETİKETLERİ, EL BASKI SETLERİ, HAZIR DAKTİLO ŞERİTLERİ VE ISTAMPALARIN İMALATI"
    },
    {
        "kod": "329909",
        "tanim": "KORUYUCU AMAÇLI SOLUNUM EKİPMANLARI VE GAZ MASKELERİNİN İMALATI (TEDAVİ EDİCİ OLANLAR HARİÇ)"
    },
    {
        "kod": "329910",
        "tanim": "ATEŞE DAYANIKLI VE KORUYUCU GÜVENLİK KIYAFETLERİ VE BAŞLIKLARI İLE DİĞER GÜVENLİK ÜRÜNLERİNİN İMALATI (SOLUNUM EKİPMANLARI VE GAZ MASKELERİ HARİÇ)"
    },
    {
        "kod": "329911",
        "tanim": "MANTAR CAN SİMİTLERİNİN İMALATI"
    },
    {
        "kod": "329913",
        "tanim": "TERMOS VE VAKUMLU KAPLARIN İMALATI"
    },
    {
        "kod": "329914",
        "tanim": "TEBEŞİR İMALATI (YAZI, ÇİZİM VEYA TERZİ TEBEŞİRİ)"
    },
    {
        "kod": "329915",
        "tanim": "SUNİ BALMUMU İLE SUNİ MUMLARIN VE MÜSTAHZAR MUMLARIN İMALATI"
    },
    {
        "kod": "329916",
        "tanim": "YAZI VEYA ÇİZİM TAHTALARI İMALATI"
    },
    {
        "kod": "329917",
        "tanim": "SİGARA ÇAKMAKLARI VE DİĞER ÇAKMAKLAR İLE ÇABUK TUTUŞAN (PİROFORİK) ALAŞIMLARIN İMALATI (ÇAKMAKLAR İÇİN KAP HACMİ ≤ 300CM3 SIVI VEYA SIVILAŞTIRILMIŞ GAZ YAKITLARI DAHİL)"
    },
    {
        "kod": "329918",
        "tanim": "FİLDİŞİ, KEMİK, BOYNUZ, SEDEF GİBİ HAYVANSAL MALZEMELERDEN OYMA EŞYALARIN İMALATI"
    },
    {
        "kod": "329990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER İMALATLAR (BAĞIRSAK (İPEK BÖCEĞİ GUDDESİ HARİÇ), KURSAK VE MESANEDEN MAMUL EŞYALAR DAHİL, TIBBİ AMAÇLI STERİL OLANLAR HARİÇ)"
    },
    {
        "kod": "331101",
        "tanim": "METAL BORU VE BORU HATLARI İLE POMPA İSTASYONLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331102",
        "tanim": "ATEŞLİ SİLAHLARIN VE SAVAŞ GEREÇLERİNİN BAKIM VE ONARIMI (SPOR VE EĞLENCE AMAÇLI SİLAHLARIN ONARIMI DAHİL)"
    },
    {
        "kod": "331103",
        "tanim": "BUHAR KAZANLARI VEYA BUHAR JENERATÖRLERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331104",
        "tanim": "MERKEZİ ISITMA SICAK SU KAZANLARI (BOYLER) VE RADYATÖRLERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331110",
        "tanim": "METAL TANKLARIN, REZERVUARLARIN VE MUHAFAZA KAPLARININ (KONTEYNERLER DAHİL) ONARIMI"
    },
    {
        "kod": "331111",
        "tanim": "NÜKLEER REAKTÖRLERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331190",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ METAL ÜRÜNLERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331202",
        "tanim": "TARIM VE ORMANCILIK MAKİNELERİNİN BAKIM VE ONARIMI (TRAKTÖRLERİN BAKIM VE ONARIMI HARİÇ)"
    },
    {
        "kod": "331203",
        "tanim": "MOTOR VE TÜRBİNLERİN BAKIM VE ONARIMI (HİDROLİK, RÜZGAR, GAZ, SU, BUHAR TÜRBİNLERİ) (GEMİ VE TEKNE MOTORLARI DAHİL, MOTORLU KARA TAŞITI VE MOTOSİKLET MOTORLARI HARİÇ)"
    },
    {
        "kod": "331204",
        "tanim": "SANAYİ FIRINLARININ, OCAKLARININ VE OCAK BRÜLÖRLERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331205",
        "tanim": "KALDIRMA VE TAŞIMA EKİPMANLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331206",
        "tanim": "SANAYİ TİPİ SOĞUTMA VE HAVALANDIRMA EKİPMANLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331207",
        "tanim": "TARTI ALETLERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331208",
        "tanim": "MADENCİLİK, İNŞAAT, PETROL VE GAZ SAHALARINDA KULLANILAN MAKİNELERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331209",
        "tanim": "TARIM VE ORMANCILIKTA KULLANILAN MOTOKÜLTÖRLER VE TRAKTÖRLERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331210",
        "tanim": "AKIŞKAN GÜCÜ İLE ÇALIŞAN EKİPMANLAR, POMPALAR, KOMPRESÖRLER İLE VALFLERİN VE VANALARIN BAKIM VE ONARIMI (AKARYAKIT POMPALARININ TAMİRİ DAHİL)"
    },
    {
        "kod": "331211",
        "tanim": "METAL İŞLEME MAKİNELERİNİN VE TAKIM TEZGAHLARININ BAKIM VE ONARIMI (CNC OLANLAR DAHİL)"
    },
    {
        "kod": "331212",
        "tanim": "MOTORLU VEYA PNÖMATİK (HAVA BASINÇLI) EL ALETLERİNİN ONARIMI (YUVARLAK/VARGEL/ZİNCİR TESTERE, MATKAP, PNÖMATİK VEYA MOTORLU METAL KESME MAKASI, DARBELİ CIVATA ANAHTARI VB.)"
    },
    {
        "kod": "331213",
        "tanim": "ELEKTRİKLİ KAYNAK VE LEHİM ALETLERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331214",
        "tanim": "METALÜRJİ MAKİNELERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331215",
        "tanim": "GIDA, İÇECEK VE TÜTÜN İŞLEME MAKİNELERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331216",
        "tanim": "TEKSTİL, GİYİM EŞYASI VE DERİ ÜRETİM MAKİNELERİNİN BAKIM VE ONARIMI (TRİKO MAKİNELERİNİN ONARIMI DAHİL)"
    },
    {
        "kod": "331217",
        "tanim": "KAĞIT, KARTON VE MUKAVVA ÜRETİMİNDE KULLANILAN MAKİNELERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331218",
        "tanim": "BÜRO VE MUHASEBE MAKİNELERİN BAKIM VE ONARIMI (DAKTİLO, YAZAR KASA, FOTOKOPİ MAKİNELERİ, HESAP MAKİNELERİ, VB.)"
    },
    {
        "kod": "331219",
        "tanim": "AĞAÇ, MANTAR, TAŞ, SERT KAUÇUK VEYA BENZERİ SERT MALZEMELERİ İŞLEMEDE KULLANILAN TAKIM TEZGAHLARININ BAKIM VE ONARIMI (CNC OLANLAR DAHİL)"
    },
    {
        "kod": "331221",
        "tanim": "SIVILAR İÇİN FİLTRELEME YA DA TEMİZLEME MAKİNELERİ VE APARATLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331227",
        "tanim": "KESİCİ ALETLER İLE EL ALETLERİNİN BAKIM VE ONARIMI (MATBAA GİYOTİNİ, ŞERİT TESTERE, EL TESTERESİ, ÇAPA, ORAK VB. BİLEYLEME VE ÇARKÇILIK DAHİL) (MOTORLU VE PNÖMATİK OLANLAR HARİÇ)"
    },
    {
        "kod": "331228",
        "tanim": "PLASTİK VE KAUÇUK İMALATINDA VE İŞLENMESİNDE KULLANILAN MAKİNELERİN BAKIM VE ONARIMI"
    },
    {
        "kod": "331229",
        "tanim": "ENDÜSTRİYEL RULMANLARIN, DİŞLİLERİN, DİŞLİ TAKIMLARININ VE TAHRİK TERTİBATI ELEMANLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331230",
        "tanim": "TARIMSAL AMAÇLI KULLANILAN RÖMORKLARIN BAKIM VE ONARIMI"
    },
    {
        "kod": "331290",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER MAKİNELERİN BAKIM VE ONARIMI (YANGIN SÖNDÜRME TÜPLERİNİN DOLUMU VE TAMİRİ DAHİL)"
    },
    {
        "kod": "331301",
        "tanim": "ÖLÇME, TEST VE SEYRÜSEFER ALET VE CİHAZLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331302",
        "tanim": "IŞINLAMA, ELEKTROMEDİKAL VE ELEKTROTERAPİ EKİPMANLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331303",
        "tanim": "PROFESYONEL OPTİK ALETLERİN VE FOTOĞRAFÇILIK EKİPMANLARININ BAKIM VE ONARIMI (TÜKETİCİ ELEKTRONİK ÜRÜNLERİNİN ONARIMI HARİÇ)"
    },
    {
        "kod": "331304",
        "tanim": "DİĞER PROFESYONEL ELEKTRONİK EKİPMANLARIN BAKIM VE ONARIMI"
    },
    {
        "kod": "331401",
        "tanim": "GÜÇ TRANSFORMATÖRLERİ, DAĞITIM TRANSFORMATÖRLERİ VE ÖZEL TRANSFORMATÖRLERİN BAKIM VE ONARIMI (ELEKTRİK DAĞITIM VE KONTROL CİHAZLARI DAHİL)"
    },
    {
        "kod": "331402",
        "tanim": "ELEKTRİK MOTORLARI, JENERATÖRLER VE MOTOR JENERATÖR SETLERİNİN BAKIM VE ONARIMI (BOBİNLERİN TEKRAR SARIMI DAHİL)"
    },
    {
        "kod": "331403",
        "tanim": "DİĞER PROFESYONEL ELEKTRİKLİ EKİPMANLARIN BAKIM VE ONARIMI"
    },
    {
        "kod": "331501",
        "tanim": "GEMİLERİN VE TEKNELERİN BAKIM VE ONARIMI (YÜZEN YAPILAR, SANDAL, KAYIK, VB. BAKIM VE ONARIMI İLE BUNLARIN KALAFATLANMASI DAHİL)"
    },
    {
        "kod": "331601",
        "tanim": "HAVA TAŞITLARININ VE UZAY ARAÇLARININ BAKIM VE ONARIMI (FABRİKALARDA YAPILAN DÖNÜŞTÜRME, ELDEN GEÇİRME VE YENİDEN ÜRETME HARİÇ)"
    },
    {
        "kod": "331701",
        "tanim": "DEMİR YOLU LOKOMOTİFLERİNİN VE VAGONLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "331790",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER ULAŞIM EKİPMANLARININ BAKIM VE ONARIMI (AT ARABALARI VE DÖRT TEKERLEKLİ YÜK ARABALARININ BAKIM VE ONARIMI DAHİL)"
    },
    {
        "kod": "331901",
        "tanim": "TENTELERİN, KAMP EKİPMANLARININ, ÇUVALLARIN VE BALIKÇILIK AĞLARI GİBİ DİĞER HAZIR TEKSTİL MALZEMELERİNİN ONARIMI"
    },
    {
        "kod": "331902",
        "tanim": "HALATLAR, GEMİ ÇARMIK VE HALATLARI İLE YELKEN BEZLERİ VE BEZ ASTARLI MUŞAMBALARIN ONARIMI"
    },
    {
        "kod": "331990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER EKİPMANLARIN ONARIMI (AHŞAP KONTEYNER, GEMİ FIÇI VE VARİLLERİ, MADENİ PARA İLE ÇALIŞAN OYUN MAKİNELERİ, DEĞİRMENTAŞI, BİLEME TAŞI VS.)"
    },
    {
        "kod": "332033",
        "tanim": "TARIMSAL AMAÇLI SANAYİ MAKİNE VE EKİPMANLARININ KURULUMU"
    },
    {
        "kod": "332034",
        "tanim": "KALDIRMA VE TAŞIMA EKİPMANLARININ KURULUMU (ASANSÖRLER VE YÜRÜYEN MERDİVENLER HARİÇ)"
    },
    {
        "kod": "332035",
        "tanim": "MOTOR VE TÜRBİNLERİN (HAVA TAŞITI, MOTORLU KARA TAŞITI VE MOTOSİKLET MOTORLARI HARİÇ) VE POMPA VE KOMPRESÖRLERİN KURULUMU"
    },
    {
        "kod": "332036",
        "tanim": "METALLERİN İŞLENMESİNDE, KESİLMESİNDE VE ŞEKİLLENDİRİLMESİNDE KULLANILAN MAKİNELERİN KURULUM HİZMETLERİ"
    },
    {
        "kod": "332037",
        "tanim": "METALÜRJİ İÇİN SANAYİ MAKİNELERİNİN VE EKİPMANLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332038",
        "tanim": "MADEN, TAŞOCAĞI VE İNŞAATLARDA KULLANILAN MAKİNELERİN KURULUMU"
    },
    {
        "kod": "332039",
        "tanim": "GIDA, İÇECEK VE TÜTÜN İŞLEME İÇİN SANAYİ MAKİNELERİNİN VE EKİPMANLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332040",
        "tanim": "TEKSTİL, GİYİM EŞYASI VE DERİ ÜRETİMİ İÇİN SANAYİ MAKİNELERİNİN VE EKİPMANLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332041",
        "tanim": "KAĞIT VE MUKAVVA ÜRETİMİ İÇİN SANAYİ MAKİNELERİNİN VE EKİPMANLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332042",
        "tanim": "SANAYİ FABRİKALARINDA CAM VE SERAMİK BORULARIN VE HATLARIN KURULUMU"
    },
    {
        "kod": "332043",
        "tanim": "DEĞİRMENCİLİKTE KULLANILAN MAKİNELERİN KURULUMU"
    },
    {
        "kod": "332044",
        "tanim": "METAL MUHAFAZA TANKLARININ VE SARNIÇLARIN KURULUMU"
    },
    {
        "kod": "332045",
        "tanim": "SANAYİ TİPİ ISITMA, İKLİMLENDİRME VE SOĞUTMA CİHAZ VE EKİPMANLARININ KURULUMU"
    },
    {
        "kod": "332046",
        "tanim": "GENEL AMAÇLI MAKİNELERİN KURULUM HİZMETLERİ (TARTMA, FİLTRELEME, DAMITMA, PAKETLEME, ŞİŞELEME, PÜSKÜRTME, BUHAR/KUM PÜSKÜRTME, KALENDERLEME İÇİN OLANLAR İLE BÜRO VE MUHASEBE MAKİNELERİNİN KURULUM HİZMETLERİ DAHİL)"
    },
    {
        "kod": "332048",
        "tanim": "AĞAÇ, MANTAR, TAŞ, SERT KAUÇUK VEYA BENZERİ SERT MALZEMELERİ İŞLEMEDE KULLANILAN TAKIM TEZGAHLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332049",
        "tanim": "PLASTİK VE KAUÇUK ÜRETİMİ İÇİN SANAYİ MAKİNELERİNİN VE EKİPMANLARININ KURULUM HİZMETLERİ"
    },
    {
        "kod": "332050",
        "tanim": "PROFESYONEL TIBBİ MAKİNELER, HASSAS VE OPTİK ALETLER VE PROFESYONEL ELEKTRONİK EKİPMANLARIN KURULUM HİZMETLERİ"
    },
    {
        "kod": "332051",
        "tanim": "ELEKTRİKLİ EKİPMANLARIN KURULUM HİZMETLERİ (ELEKTRİK MOTORLARI, JENERATÖRLER VE TRANSFORMATÖRLERİN, ELEKTRİK DAĞITIM VE KONTROL CİHAZLARI İLE DİĞER ELEKTRİKLİ EKİPMANLARIN KURULUMU (YOLLAR, VB. İÇİN ELEKTRİKLİ SİNYALİZASYON EKİPMANLARI HARİÇ))"
    },
    {
        "kod": "332052",
        "tanim": "FABRİKASYON METAL ÜRÜNLERİN KURULUM HİZMETLERİ (BUHAR JENERATÖRLERİNİN KURULUM HİZMETLERİ VE SANAYİ TESİSLERİNDEKİ METAL BORU SİSTEMLERİNİN KURULUMU DAHİL, MERKEZİ ISITMA SICAK SU KAZANLARI (BOYLERLERİ) İLE MAKİNE VE EKİPMANLAR HARİÇ)"
    },
    {
        "kod": "332053",
        "tanim": "ENDÜSTRİYEL İŞLEM KONTROL EKİPMANLARININ KURULUM HİZMETLERİ (ENDÜSTRİYEL İŞLEM KONTROL EKİPMANLARININ VE OTOMATİK ÜRETİM TESİSLERİNİN TASARIMI VE MONTAJI, ENDÜSTRİYEL ZAMAN ÖLÇÜM ALET VE CİHAZLARININ KURULUMU) (OTOMASYON DESTEKLİLER DAHİL)"
    },
    {
        "kod": "332054",
        "tanim": "SANAYİ FIRINLARININ VE OCAK BRÜLÖRLERİNİN (OCAK ATEŞLEYİCİLERİNİN) KURULUMU"
    },
    {
        "kod": "332090",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER SANAYİ MAKİNE VE EKİPMANLARININ KURULUM HİZMETLERİ (MATBAA MAKİNELERİ VE ÇİMENTO İMALATINDA KULLANILAN MAKİLERİN KURULUMU DAHİL)"
    },
    {
        "kod": "351119",
        "tanim": "ELEKTRİK ENERJİSİ ÜRETİMİ"
    },
    {
        "kod": "351213",
        "tanim": "ELEKTRİK ENERJİSİNİN İLETİMİ (ELEKTRİK ÜRETİM KAYNAĞINDAN DAĞITIM SİSTEMİNE AKTARAN İLETİM SİSTEMLERİNİN İŞLETİLMESİ)"
    },
    {
        "kod": "351301",
        "tanim": "ELEKTRİK ENERJİSİNİN DAĞITIMI (ÜRETİM KAYNAĞINDAN VEYA İLETİM SİSTEMİNDEN SON KULLANICIYA İLETİM SİSTEMİYLE TAŞINAN ELEKTRİK ENERJİSİ DAĞITIM SİSTEMİNİN İŞLETİLMESİ)"
    },
    {
        "kod": "351302",
        "tanim": "ELEKTRİK SAYAÇLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "351401",
        "tanim": "DİĞER İŞLETMELER TARAFINDAN İŞLETİLEN GÜÇ DAĞITIM SİSTEMLERİ ARACILIĞI İLE ELEKTRİK SATIŞINI DÜZENLEYEN ELEKTRİK KOMİSYONCULARI VE ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "351402",
        "tanim": "KULLANICILARA YÖNELİK ELEKTRİK TİCARETİ (KOMİSYONCULAR VE ACENTELER HARİÇ)"
    },
    {
        "kod": "351403",
        "tanim": "ELEKTRİK İÇİN ELEKTRİK VE İLETİM KAPASİTESİ DEĞİŞTİRME FAALİYETLERİ"
    },
    {
        "kod": "352101",
        "tanim": "DOĞALGAZ DAHİL, ÇEŞİTLİ TÜRDEKİ GAZLARDAN ARINDIRMA, KARIŞTIRMA, VB. İŞLEMLERLE KALORİFİK DEĞERDE GAZLI YAKITLARIN ÜRETİMİ"
    },
    {
        "kod": "352102",
        "tanim": "KÖMÜRÜN KARBONLAŞTIRILMASI, TARIMSAL YAN ÜRÜN VEYA ATIKLARINDAN GAZ ÜRETİMİ"
    },
    {
        "kod": "352201",
        "tanim": "ANA ŞEBEKE ÜZERİNDEN GAZ YAKITLARIN DAĞITIMI (HER ÇEŞİT GAZLI YAKITIN, ANA BORU SİSTEMİYLE DAĞITIMI VE TEDARİKİ)"
    },
    {
        "kod": "352202",
        "tanim": "GAZ SAYAÇLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "352301",
        "tanim": "ANA ŞEBEKE ÜZERİNDEN KULLANICILARA YÖNELİK GAZ TİCARETİ (KOMİSYONCULAR VE ACENTELER HARİÇ)"
    },
    {
        "kod": "352302",
        "tanim": "DİĞER İŞLETMELER TARAFINDAN İŞLETİLEN GAZ DAĞITIM SİSTEMLERİ ARACILIĞIYLA, GAZ SATIŞINI DÜZENLEYEN GAZ KOMİSYONCULARI VEYA ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "353021",
        "tanim": "BUHAR VE SICAK SU ÜRETİMİ, TOPLANMASI VE DAĞITIMI"
    },
    {
        "kod": "353022",
        "tanim": "SOĞUTULMUŞ HAVA VE SOĞUTULMUŞ SU ÜRETİM VE DAĞITIMI (BUZ ÜRETİMİ DAHİL)"
    },
    {
        "kod": "360002",
        "tanim": "SUYUN TOPLANMASI, ARITILMASI VE DAĞITILMASI"
    },
    {
        "kod": "360003",
        "tanim": "SU SAYAÇLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "370001",
        "tanim": "KANALİZASYON (KANALİZASYON ATIKLARININ UZAKLAŞTIRILMASI VE ARITILMASI, KANALİZASYON SİSTEMLERİNİN VE ATIK SU ARITMA TESİSLERİNİN İŞLETİMİ, FOSEPTİK ÇUKURLARIN VE HAVUZLARIN BOŞALTILMASI VE TEMİZLENMESİ, SEYYAR TUVALET FAALİYETLERİ VB.)"
    },
    {
        "kod": "381101",
        "tanim": "TEHLİKESİZ ATIKLARIN TOPLANMASI (ÇÖPLER, GERİ DÖNÜŞTÜRÜLEBİLİR MADDELER, TEKSTİL ATIKLARI, VB.) (İNŞAAT VE YIKIM ATIKLARI, ÇALI, ÇIRPI, MOLOZ GİBİ ENKAZLAR HARİÇ)"
    },
    {
        "kod": "381102",
        "tanim": "İNŞAAT VE YIKIM ATIKLARININ, ÇALI, ÇIRPI, MOLOZ GİBİ ENKAZLARIN TOPLANMASI VE KALDIRILMASI"
    },
    {
        "kod": "381103",
        "tanim": "TEHLİKESİZ ATIK TRANSFER İSTASYONLARININ İŞLETİLMESİ"
    },
    {
        "kod": "381201",
        "tanim": "TEHLİKELİ ATIKLARIN TOPLANMASI (PATLAYICI, OKSİTLEYİCİ, YANICI, ZEHİRLİ, AŞINDIRICI, BULAŞICI VE İNSAN SAĞLIĞI İÇİN ZARARLI ATIKLARIN VE MADDELERİN TOPLANMASI FAALİYETLERİ) (NÜKLEER ATIKLAR, BİYOKİMYASAL ATIKLAR, KULLANILMIŞ PİLLER VB.)"
    },
    {
        "kod": "382101",
        "tanim": "TEHLİKESİZ ATIKLARIN ISLAHI VE BERTARAF EDİLMESİ VE BERTARAFI İÇİN DEPOLAMA ALANLARININ İŞLETİLMESİ"
    },
    {
        "kod": "382201",
        "tanim": "TEHLİKELİ ATIKLARIN ISLAHI VE BERTARAF EDİLMESİ (TEHLİKELİ ATIKLARIN ISLAHINI YAPAN TESİSLERİN İŞLETİLMESİ, ZARARLI ATIKLARIN YOK EDİLMESİ İÇİN KULLANILMIŞ MALLARIN BERTARAFI VB. FAALİYETLER) (RADYOAKTİF ATIKLAR HARİÇ)"
    },
    {
        "kod": "382202",
        "tanim": "RADYOAKTİF ATIKLARIN ISLAHI VE BERTARAF EDİLMESİ"
    },
    {
        "kod": "383101",
        "tanim": "GEMİ VE YÜZER YAPILARIN HURDALARININ MATERYALLERİNİN GERİ KAZANIMI AMACIYLA PARÇALARA AYRILMASI (SÖKÜLMESİ)"
    },
    {
        "kod": "383102",
        "tanim": "HURDALARIN GERİ KAZANIM AMACIYLA PARÇALARA AYRILMASI (OTOMOBİL, BİLGİSAYAR, TELEVİZYON VB. DONANIMLAR) (GEMİLER VE YÜZER YAPILAR İLE SATMAK İÇİN KULLANILABİLİR PARÇALAR OLUŞTURMAK AMACIYLA SÖKME HARİÇ)"
    },
    {
        "kod": "383201",
        "tanim": "TASNİF EDİLMİŞ METAL ATIKLAR, HURDALAR VE DİĞER PARÇALARIN GENELLİKLE MEKANİK VEYA KİMYASAL DEĞİŞİM İŞLEMLERİ İLE GERİ KAZANILMASI"
    },
    {
        "kod": "383202",
        "tanim": "TASNİF EDİLMİŞ METAL DIŞI ATIKLAR, HURDALAR VE DİĞER PARÇALARIN GENELLİKLE MEKANİK VEYA KİMYASAL DEĞİŞİM İŞLEMLERİ İLE GERİ KAZANILMASI"
    },
    {
        "kod": "390001",
        "tanim": "İYİLEŞTİRME FAALİYETLERİ VE DİĞER ATIK YÖNETİMİ HİZMETLERİ (KİRLETİLMİŞ TOPRAK VE YERALTI SULARININ TEMİZLENMESİ, KARA MAYINLARININ TEMİZLENMESİ, VB.)"
    },
    {
        "kod": "411001",
        "tanim": "BİNA PROJELERİNİN GELİŞTİRİLMESİ (SATIŞA YÖNELİK BİNA PROJELERİ İÇİN MALİ, TEKNİK VE FİZİKSEL ARAÇLARIN BİR ARAYA GETİRİLMESİ SURETİYLE KONUT VEYA DİĞER AMAÇLI KULLANIMA YÖNELİK BİNA PROJELERİNİN ORGANİZE EDİLMESİ) (YAPI KOOPERATİFLERİ HARİÇ)"
    },
    {
        "kod": "411002",
        "tanim": "KONUT YAPI KOOPERATİFLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "411003",
        "tanim": "İŞYERİ YAPI KOOPERATİFLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "412001",
        "tanim": "İKAMET AMAÇLI OLMAYAN BİNALARIN İNŞAATI (FABRİKA, ATÖLYE VB. SANAYİ ÜRETİMİNİ AMAÇLAYAN BİNALAR İLE HASTANE, OKUL, OTEL, İŞYERİ, MAĞAZA, ALIŞVERİŞ MERKEZİ, LOKANTA, KAPALI SPOR TESİSİ, CAMİ, KAPALI OTOPARK, TUVALET, VB. İNŞAATI)"
    },
    {
        "kod": "412002",
        "tanim": "İKAMET AMAÇLI BİNALARIN İNŞAATI (MÜSTAKİL KONUTLAR, BİRDEN ÇOK AİLENİN OTURDUĞU BİNALAR, GÖKDELENLER VB.NİN İNŞAATI) (AHŞAP BİNALARIN İNŞAATI HARİÇ)"
    },
    {
        "kod": "412003",
        "tanim": "PREFABRİK BİNALAR İÇİN BİLEŞENLERİN ALANDA BİRLEŞTİRİLMESİ VE KURULMASI"
    },
    {
        "kod": "412004",
        "tanim": "İKAMET AMAÇLI AHŞAP BİNALARIN İNŞAATI"
    },
    {
        "kod": "412005",
        "tanim": "MEVCUT İKAMET AMAÇLI OLAN VEYA İKAMET AMAÇLI OLMAYAN BİNALARIN YENİDEN DÜZENLENMESİ VEYA YENİLENMESİ (BÜYÜK ÇAPLI REVİZYON)"
    },
    {
        "kod": "421101",
        "tanim": "OTO YOLLAR, KARA YOLLARI, ŞEHİR İÇİ YOLLAR VE DİĞER ARAÇ VEYA YAYA YOLLARININ İNŞAATI"
    },
    {
        "kod": "421102",
        "tanim": "YOL YÜZEYLERİNİN ASFALTLANMASI VE ONARIMI, KALDIRIM, KASİS, BİSİKLET YOLU VB.LERİN İNŞAATI, YOLLARIN VB. YÜZEYLERİN BOYAYLA İŞARETLENMESİ, YOL BARİYERİ, TRAFİK İŞARET VE LEVHALARI VB.NİN KURULUMU GİBİ YOL, TÜNEL VB. YERLERDEKİ YÜZEY İŞLERİ"
    },
    {
        "kod": "421103",
        "tanim": "HAVAALANI PİSTİ İNŞAATI"
    },
    {
        "kod": "421201",
        "tanim": "DEMİR YOLLARI VE METROLARIN İNŞAATI (BAKIM VE ONARIMI DAHİL)"
    },
    {
        "kod": "421301",
        "tanim": "KÖPRÜLERİN İNŞAATI (YÜKSELTİLMİŞ KARA YOLLARI-VİYADÜKLER DAHİL)"
    },
    {
        "kod": "421302",
        "tanim": "TÜNEL İNŞAATI"
    },
    {
        "kod": "422101",
        "tanim": "AKIŞKANLAR İÇİN UZUN MESAFE BORU HATLARININ İNŞAATI (PETROL ÜRÜNLERİ VE GAZ TAŞIMACILIĞI İLE SU VE DİĞER ÜRÜNLERİN TAŞIMACILIĞINA YÖNELİK KARADA VE DENİZ ALTINDA UZUN MESAFE BORU HATTI)"
    },
    {
        "kod": "422102",
        "tanim": "SU KUYUSU AÇMA VE SEPTİK SİSTEM KURULUM FAALİYETLERİ (KUYU, ARTEZYEN VB.)"
    },
    {
        "kod": "422103",
        "tanim": "ANA SU ŞEBEKELERİ VE SU HATLARI İLE SU ARITMA TESİSLERİ, KANALİZASYON BERTARAF TESİSLERİ VE POMPA İSTASYONLARI İNŞAATI (SULAMA SİSTEMLERİ (KANALLAR) DAHİL)"
    },
    {
        "kod": "422105",
        "tanim": "AKIŞKANLAR İÇİN KISA MESAFE (YEREL) BORU HATLARININ İNŞAATI (PETROL ÜRÜNLERİ VE GAZ TAŞIMACILIĞI İLE SU, KANALİZASYON, SICAK SU, BUHAR VE DİĞER ÜRÜNLERİN TAŞIMACILIĞINA YÖNELİK KISA MESAFE BORU HATTI)"
    },
    {
        "kod": "422201",
        "tanim": "UZUN MESAFE ELEKTRİK HATLARININ İNŞAATI (UZUN MESAFE YÜKSEK GERİLİM ELEKTRİK İLETİM HATLARI İLE UZUN MESAFE YER ÜSTÜ/ALTI VEYA DENİZ ALTI İLETİM HATLARI)"
    },
    {
        "kod": "422202",
        "tanim": "ENERJİ SANTRALLERİ İNŞAATI (HİDROELEKTRİK SANTRALİ, TERMİK SANTRAL, NÜKLEER ENERJİ ÜRETİM SANTRALLERİ VB.)"
    },
    {
        "kod": "422204",
        "tanim": "KENTSEL (KISA MESAFE) ELEKTRİK HATLARININ İNŞAATI (TRAFO İSTASYONLARI VE YEREL SINIRLAR İÇERİSİNDEKİ DAĞITIM ALT İSTASYONLARI VB.)"
    },
    {
        "kod": "422205",
        "tanim": "TELEKOMÜNİKASYON ŞEBEKE VE AĞLARININ BAKIM VE ONARIMI"
    },
    {
        "kod": "422206",
        "tanim": "UZUN MESAFE TELEKOMÜNİKASYON (İLETİŞİM) HATLARININ İNŞAATI (UZUN MESAFE YER ÜSTÜ/ALTI VEYA DENİZ ALTI TELEKOMÜNİKASYON İLETİM HATLARI)"
    },
    {
        "kod": "422207",
        "tanim": "KENTSEL (KISA MESAFE) TELEKOMÜNİKASYON (İLETİŞİM) HATLARININ İNŞAATI (ANTEN DAHİL İLETİM KULELERİ VE TRAFO İSTASYONLARI VE YEREL SINIRLAR İÇERİSİNDEKİ DAĞITIM ALT İSTASYONLARI VB.)"
    },
    {
        "kod": "429101",
        "tanim": "KIYI VE LİMAN İNŞAATLARI VE İLGİLİ HİDROMEKANİK YAPILARIN İNŞAATI (SU YOLLARI, LİMAN VE YAT LİMANLARI, KIYI DÜZENLEMELERİ, İSKELE VE RIHTIMLAR, DALGAKIRANLAR, KANALLAR VB. YAPILAR)"
    },
    {
        "kod": "429102",
        "tanim": "SU VE SU ZEMİNİNİN TARANMASI VE TEMİZLENMESİ (DENİZ, NEHİR, GÖL VB.)"
    },
    {
        "kod": "429103",
        "tanim": "TERSANE, DOK VE KANAL HAVUZU İNŞAATI (GEMİ İNŞAATI VE TAMİRİ İÇİN)"
    },
    {
        "kod": "429104",
        "tanim": "BARAJ VE BENTLERİN İNŞAATI"
    },
    {
        "kod": "429901",
        "tanim": "AÇIK HAVADA YAPILAN SPORLARA UYGUN TESİSLERİN VE EĞLENCE ALANLARI YAPILARININ İNŞAATI (GOLF SAHALARI, AÇIK STADYUMLAR, TENİS KORTLARI, ATLETİZM SAHALARI, PLAJ TESİSİ, DAĞ BARINAKLARI, EĞLENCE PARKLARI VB.)"
    },
    {
        "kod": "429902",
        "tanim": "MADENCİLİK VE İMALAT SANAYİSİ YAPILARININ İNŞAATI (SARIM MİLİ VE KULELERİ, MADEN YÜKLEME VE BOŞALTMA İSTASYONLARI, RAFİNERİLER, KİMYASAL TESİSLER VB.)"
    },
    {
        "kod": "429903",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ BİNA DIŞI DİĞER YAPILARIN İNŞAATI (ARAZİ İYİLEŞTİRİLMESİ İLE BİRLİKTE ARAZİNİN PARSELLEMESİ DAHİL, İYİLEŞTİRME YAPILMAKSIZIN PARSELLEME HARİÇ)"
    },
    {
        "kod": "429904",
        "tanim": "DOĞALGAZ İŞLEME TESİSLERİ İNŞAATI"
    },
    {
        "kod": "431101",
        "tanim": "YIKIM İŞLERİ (BİNALARIN VE DİĞER YAPILARIN YIKILMASI VE SÖKÜLMESİ)"
    },
    {
        "kod": "431201",
        "tanim": "ZEMİN VE ARAZİ HAZIRLAMA, ALANIN TEMİZLENMESİ İLE KAZI VE HAFRİYAT İŞLERİ (TARIMSAL ARAZİNİN HAZIRLANMASI, DİNAMİTLEME VE KAYALARIN KALDIRILMASI, İNŞAAT, TARIM VB. ALANLARIN DRENAJI, HAFRİYAT, KAZI, DOLGU VB. İŞLER) (MADENCİLİK İÇİN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "431202",
        "tanim": "MADEN SAHALARININ HAZIRLANMASI (TÜNEL AÇMA DAHİL, PETROL VE GAZ SAHALARI İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "431301",
        "tanim": "TEST SONDAJI VE DELME (İNŞAAT, JEOFİZİK, JEOLOJİK VB. AMAÇLAR İÇİN TEST SONDAJI VE DELME İŞLERİ İLE ÖRNEKLEME SONDAJI) (MADENCİLİKLE BAĞLANTILI OLARAK GERÇEKLEŞTİRİLEN TEST SONDAJI HARİÇ)"
    },
    {
        "kod": "432101",
        "tanim": "BİNA VE BİNA DIŞI YAPILARIN(ULAŞIM İÇİN AYDINLTM. VE SİNYALZSYN SİS. HARİÇ)ELKT.TESİSATI, KABLOLU TV VE BLG.SAYAR AĞI TESİSATI İLE KONUT TİPİ ANT. (UYDU ANT. DAHİL), ELKT.Lİ GÜNEŞ ENRJ. KOLLEKTÖRÜ, ELKT.SAYACI,YANGIN VE HIRSIZ.ALARM SİST.VB. KURULUMU"
    },
    {
        "kod": "432103",
        "tanim": "KARAYOLLARI, DEMİRYOLLARI VE DİĞER RAYLI YOLLARIN, LİMAN VE HAVAALANLARININ AYDINLATMA VE SİNYALİZASYON SİSTEMLERİNİN TESİSATI (HAVAALANI PİSTİ AYDINLATMASININ TESİSATI DAHİL)"
    },
    {
        "kod": "432201",
        "tanim": "BİNA VEYA DİĞER İNŞAAT PROJELERİNDE ISITMA, HAVALANDIRMA, SOĞUTMA VE İKLİMLENDİRME SİSTEMLERİNİN TESİSATI (EV TİPİ BOYLER (KOMBİ, KAZAN VB.) VE BRÜLÖRLERİN BAKIM, ONARIM VE KURULUMU İLE ELEKTRİKSİZ GÜNEŞ ENERJİSİ KOLEKTÖRLERİNİN KURULUMU DAHİL)"
    },
    {
        "kod": "432203",
        "tanim": "BİNA VE DİĞER İNŞAAT PROJELERİNDE SU VE KANALİZASYON TESİSATI VE ONARIMI (YAĞMURLAMA SİSTEMLERİNİN KURULUMU DAHİL SIHHİ TESİSAT İŞLERİ, YANGIN SÖNDÜRME SİSTEMLERİNİN KURULUMU, KANALİZASYON TESİSATI DÖŞEME İŞLERİ VB.)"
    },
    {
        "kod": "432205",
        "tanim": "GAZ TESİSATI FAALİYETLERİ (HASTANELERDEKİ OKSİJEN GAZI TEMİNİ İÇİN KURULUM İŞLERİ DAHİL)"
    },
    {
        "kod": "432901",
        "tanim": "ASANSÖRLERİN, YÜRÜYEN MERDİVENLERİN, YÜRÜYEN YOLLARIN, OTOMATİK VE DÖNER KAPILARIN BAKIM VE ONARIMI DAHİL KURULUM İŞLERİ"
    },
    {
        "kod": "432902",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER TESİSAT İŞLERİ (PARATONERLERİN, TABELALARIN (IŞIKLI OLSUN VEYA OLMASIN), STOR VE GÜNEŞLİKLERİN MONTAJ İŞLERİ VB.)"
    },
    {
        "kod": "432903",
        "tanim": "ISI, SES VEYA TİTREŞİM YALITIMI İLE DİĞER İNŞAAT TESİSATI İŞLERİ (MANTOLAMA VE VAKUMLU TEMİZLEME SİSTEMLERİNİN KURULUMU DAHİL)"
    },
    {
        "kod": "432905",
        "tanim": "PARMAKLIK VE KORKULUK TESİSATI İŞLERİ (METAL YANGIN MERDİVENLERİNİN KURULUMU DAHİL)"
    },
    {
        "kod": "433101",
        "tanim": "SIVA İŞLERİ (BİNALARDA VEYA DİĞER İNŞAATLARDA İÇ VE DIŞ SIVA VEYA ALÇI SIVA İŞLERİ İLE ALÇIPAN İŞLERİ VB.)"
    },
    {
        "kod": "433201",
        "tanim": "HAZIR MUTFAKLAR, MUTFAK TEZGAHLARI, GÖMME DOLAPLAR, İÇ MERDİVENLER İLE İNCE TAHTA, LAMBRİ VE BENZERLERİNİN MONTAJI İŞLERİ"
    },
    {
        "kod": "433202",
        "tanim": "HERHANGİ BİR MALZEMEDEN YAPILAN KAPI VE PENCERE KASALARI, KAPILAR (ZIRHLI KAPILAR DAHİL, OTOMATİK VE DÖNER KAPILAR HARİÇ), PENCERELER, KEPENKLER, PANJURLAR, GARAJ KAPILARI VE BENZERLERİNİN MONTAJI"
    },
    {
        "kod": "433203",
        "tanim": "SEYYAR BÖLME VE METAL YAPI ÜZERİNE ASMA TAVAN MONTAJ İŞLERİ İLE DİĞER DOĞRAMA TESİSATI İŞLERİ"
    },
    {
        "kod": "433301",
        "tanim": "BİNA VE DİĞER YAPILARIN İÇİ VEYA DIŞINDA YER VE DUVAR KAPLAMA FAALİYETLERİ (MERMER, MOZAİK, GRANİT, KARO VE KALDIRIM TAŞLARININ, PARKE DAHİL AHŞAP YER VE DUVAR KAPLAMALARININ DÖŞENMESİ VB.) (HALI, TABAN MUŞAMBASI VE KAĞIT KAPLAMA HARİÇ)"
    },
    {
        "kod": "433302",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER YER DÖŞEME VE KAPLAMA İLE DUVAR KAPLAMA İŞLERİ (HALI, TABAN MUŞAMBASI VE DİĞER ESNEK YER KAPLAMALARI İLE DUVAR KAPLAMA İŞLERİ)"
    },
    {
        "kod": "433401",
        "tanim": "BİNALARIN İÇ VE DIŞ BOYAMA İŞLERİ"
    },
    {
        "kod": "433402",
        "tanim": "CAM TAKMA İŞLERİ"
    },
    {
        "kod": "433403",
        "tanim": "BİNA DIŞI YAPILARIN BOYAMA İŞLERİ"
    },
    {
        "kod": "433901",
        "tanim": "DEKORATİF MALZEMENİN, BEZEMELERİN VE SÜSLERİN MONTAJI İLE İNŞAATLARDAKİ BYS. DİĞER BÜTÜNLEYİCİ VE TAMAMLAYICI İŞLER (RADYATÖRLERİ KAPLAYAN IZGARALARIN MONTAJI İLE AKUSTİK PANEL, KARO VEYA DİĞER MALZEMELERİ İÇEREN AKUSTİK İŞLER DAHİL)"
    },
    {
        "kod": "433902",
        "tanim": "YENİ BİNALARIN İNŞAAT SONRASI TEMİZLİĞİ"
    },
    {
        "kod": "439101",
        "tanim": "ÇATI İŞLERİ (ÇATI İSKELETİ KURULUMUNU İÇEREN İNŞAAT İŞLERİ, ÇATI YAPIMI, ÇATI OLUĞU VE OLUK AĞZI MONTAJ İŞLERİ İLE METAL VE DİĞER MALZEMEDEN ÇATI KAPLAMA İŞLERİ) (DÜLGERLİK İŞLERİ DAHİL)"
    },
    {
        "kod": "439901",
        "tanim": "YAPISAL ÇELİK BİLEŞENLERİN KURULMASI İŞLERİ (BİNA, KÖPRÜ, GEZER VİNÇ VEYA ELEKTRİK İLETİM KULESİ GİBİ DİĞER YAPILAR İÇİN PREFABRİK YAPISAL ÇELİK BİLEŞENLERİN KURULMASI VB.)"
    },
    {
        "kod": "439902",
        "tanim": "YERALTI ÇALIŞMALARI (MADENCİLİK, DEPOLAMA, VB. İÇİN DÜŞEY GALERİ VE KUYU AÇMA FAALİYETİ DAHİL, SU KUYUSU AÇMA HARİÇ)"
    },
    {
        "kod": "439903",
        "tanim": "AÇIK YÜZME HAVUZLARININ İNŞAATI"
    },
    {
        "kod": "439904",
        "tanim": "VİNÇ VE BENZERİ DİĞER İNŞAAT EKİPMANLARININ OPERATÖRÜ İLE BİRLİKTE KİRALANMASI (ÖZEL BİR İNŞAAT ÇEŞİDİNDE YER ALMAYAN)"
    },
    {
        "kod": "439905",
        "tanim": "İNŞAATLARDA BETON İŞLERİ (KALIP İÇERİSİNE BETON DÖKÜLMESİ VB.)"
    },
    {
        "kod": "439906",
        "tanim": "DUVARCILIK VE TUĞLA ÖRME İŞLERİ"
    },
    {
        "kod": "439907",
        "tanim": "İNŞAAT İSKELESİ VE ÇALIŞMA PLATFORMUNU KURMA VE SÖKME İŞLERİ"
    },
    {
        "kod": "439908",
        "tanim": "SU YALITIM İŞLERİ (DÜZ ÇATI VE TERASLARDAKİ SU YALITIM İŞLERİ, İNŞAAT VE DİĞER YER ALTI YAPILARIN DIŞ CEPHESİNDEKİ SU YALITIM İŞLERİ, NEM YALITIMI VB.)"
    },
    {
        "kod": "439910",
        "tanim": "BACA VE SANAYİ FIRINLARININ İNŞAATI VE KURULMASI (FIRINLAR İÇİN YANMA ODASINA ATEŞ TUĞLASI DÖŞENMESİ İŞLERİ DAHİL)"
    },
    {
        "kod": "439911",
        "tanim": "İNŞAAT AMAÇLI KAZIK ÇAKMA VE TEMEL İNŞAATI İŞLERİ (FOREKAZIK ÇAKMA DAHİL)"
    },
    {
        "kod": "439912",
        "tanim": "YAPILARIN DIŞ CEPHELERİ İÇİN BUHARLI TEMİZLEME, KUM PÜSKÜRTME VE BENZERİ UZMANLAŞMIŞ İNŞAAT FAALİYETLERİ"
    },
    {
        "kod": "439913",
        "tanim": "İNŞAAT DEMİRCİLİĞİ (İNŞAAT DEMİRİNİN BÜKÜLMESİ VE BAĞLANMASI)"
    },
    {
        "kod": "439914",
        "tanim": "PREFABRİK YAPILARIN MONTAJI VE KURULMASI (PREFABRİK BİNALAR HARİÇ HER ÇEŞİT PREFABRİK SOKAK DÜZENEKLERİNİN (OTOBÜS DURAĞI, TELEFON KULÜBESİ, BANK VB.) KURULUMU VB.)"
    },
    {
        "kod": "439915",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER UZMANLAŞMIŞ İNŞAAT İŞLERİ (ŞÖMİNE, BARBEKÜ DAHİL)"
    },
    {
        "kod": "451110",
        "tanim": "OTOMOBİLLERİN VE HAFİF MOTORLU KARA TAŞITLARININ TOPTAN TİCARETİ (AMBULANS VE MİNİBÜS BENZERİ MOTORLU YOLCU TAŞITLARI DAHİL (3,5 TONDAN DAHA AZ))"
    },
    {
        "kod": "451111",
        "tanim": "OTOMOBİLLERİN VE HAFİF MOTORLU KARA TAŞITLARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (AMBULANS VE MİNİBÜS BENZERİ MOTORLU YOLCU TAŞITLARI DAHİL (3,5 TONDAN DAHA AZ)) (GALERİCİLER DAHİL)"
    },
    {
        "kod": "451112",
        "tanim": "OTOMOBİL VE HAFİF MOTORLU KARA TAŞITLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK (ARACILAR) TOPTAN TİCARETİ (AMBULANS VE MİNİBÜS BENZERİ MOTORLU YOLCU TAŞITLARI (3,5 TONDAN DAHA AZ) DAHİL)"
    },
    {
        "kod": "451113",
        "tanim": "OTOMOBİL VE HAFİF MOTORLU KARA TAŞITLARININ DİĞER PERAKENDE TİCARETİ (AMBULANS VE MİNİBÜS BENZERİ MOTORLU YOLCU TAŞITLARI DAHİL (3,5 TONDAN DAHA AZ)) (ARACILAR İLE İNTERNET, TV. VB. ÜZERİNDEN TİCARET DAHİL)"
    },
    {
        "kod": "451901",
        "tanim": "DİĞER MOTORLU KARA TAŞITLARININ TOPTAN TİCARETİ (KAMYONLAR, ÇEKİCİLER, OTOBÜSLER, RÖMORKLAR, YARI RÖMORKLAR, KARAVANLAR VE MOTORLU KARAVANLAR)"
    },
    {
        "kod": "451902",
        "tanim": "DİĞER MOTORLU KARA TAŞITLARININ PERAKENDE TİCARETİ (KAMYONLAR, ÇEKİCİLER, OTOBÜSLER, RÖMORKLAR, YARI RÖMORKLAR, KARAVANLAR VE MOTORLU KARAVANLAR)"
    },
    {
        "kod": "452001",
        "tanim": "MOTORLU KARA TAŞITLARININ ELEKTRİK SİSTEMLERİNİN ONARIMI"
    },
    {
        "kod": "452002",
        "tanim": "MOTORLU KARA TAŞITLARININ LASTİK ONARIMI (TEKERLEK AYAR VE BALANSI DAHİL)"
    },
    {
        "kod": "452003",
        "tanim": "ARABA YAĞLAMA, YIKAMA, CİLALAMA VE BENZERİ FAALİYETLER"
    },
    {
        "kod": "452004",
        "tanim": "MOTORLU TAŞITLARIN KOLTUK VE DÖŞEMELERİNİN BAKIM VE ONARIMI"
    },
    {
        "kod": "452005",
        "tanim": "MOTORLU KARA TAŞITLARININ KAROSER VE KAPORTA ONARIMI VB. FAALİYETLER (KAPI, KİLİT, CAM, BOYAMA, ÇARPMA ONARIMI VB.)"
    },
    {
        "kod": "452006",
        "tanim": "MOTORLU KARA TAŞITLARININ GENEL BAKIM VE ONARIMI (RADYATÖR, KLİMA VE EGZOZ BAKIM VE ONARIMI DAHİL, AYNI İŞLETMEDE YAPILANLAR İLE ELEKTRİK SİSTEMİ, TEKERLEK VE KAROSER ONARIM HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "452007",
        "tanim": "MOTORLU KARA TAŞITLARININ GENEL BAKIM VE ONARIM HİZMETLERİ (AYNI İŞLETMEDE MEKANİK, ELEKTRİK SİSTEMİ, KAPORTA, BOYA, FREN SİSTEMİ, CAM, PENCERE VB. BAKIM VE ONARIMININ YAPILMASI)"
    },
    {
        "kod": "452008",
        "tanim": "MOTORLU KARA TAŞITLARINA LPG SİSTEMİ MONTAJI VE BAKIMI HİZMETLERİ"
    },
    {
        "kod": "453110",
        "tanim": "MOTORLU KARA TAŞITLARININ AKSESUARLARININ TOPTAN TİCARETİ (OTO ALARM SİSTEMLERİ DAHİL, MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453111",
        "tanim": "MOTORLU KARA TAŞITLARININ PARÇALARININ TOPTAN TİCARETİ (DORSE, DAMPER, AKÜ DAHİL, MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453112",
        "tanim": "MOTORLU KARA TAŞITI LASTİKLERİNİN VE JANTLARININ TOPTAN TİCARETİ (MOTOSİKLET VE BİSİKLET LASTİĞİ VE JANTLARI HARİÇ)"
    },
    {
        "kod": "453113",
        "tanim": "MOTORLU KARA TAŞITLARININ CAMLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "453114",
        "tanim": "MOTORLU KARA TAŞITLARININ PARÇA VE AKSESUARLARININ BİR ÜCRET YA DA SÖZLEŞMEYE DAYALI OLARAK TOPTAN TİCARETİ"
    },
    {
        "kod": "453202",
        "tanim": "MOTORLU KARA TAŞITLARININ PARÇALARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (DORSE, DAMPER, AKÜ DAHİL, LASTİK VE CAMLAR İLE MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453203",
        "tanim": "MOTORLU KARA TAŞITI LASTİKLERİNİN VE JANTLARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453204",
        "tanim": "MOTORLU KARA TAŞITLARININ AKSESUARLARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453205",
        "tanim": "MOTORLU KARA TAŞITI CAMLARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453206",
        "tanim": "MOTORLU KARA TAŞITLARININ İKİNCİ EL (KULLANILMIŞ) PARÇALARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ (MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "453290",
        "tanim": "MOTORLU KARA TAŞITLARININ PARÇA VE AKSESUARLARININ DİĞER PERAKENDE TİCARETİ (UZMANLAŞMAMIŞ OLANLAR İLE İNTERNET, POSTA, TEZGAH, PAZAR VB. YOLUYLA YAPILANLAR) (MOTOSİKLET PARÇA VE AKSESUARLARI HARİÇ)"
    },
    {
        "kod": "454001",
        "tanim": "MOTOSİKLET VE MOTORLU BİSİKLETLERİN BAKIM VE ONARIM HİZMETLERİ"
    },
    {
        "kod": "454002",
        "tanim": "MOTOSİKLETLER VE MOTORLU BİSİKLETLERİN BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ"
    },
    {
        "kod": "454003",
        "tanim": "MOTOSİKLETLER VE MOTORLU BİSİKLETLERİN PARÇA VE AKSESUARLARININ BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERAKENDE TİCARETİ"
    },
    {
        "kod": "454004",
        "tanim": "MOTOSİKLETLER VE MOTORLU BİSİKLETLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "454005",
        "tanim": "MOTOSİKLETLER VE MOTORLU BİSİKLETLERİN PARÇA VE AKSESUARLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "454006",
        "tanim": "MOTOSİKLETLER, MOTORLU BİSİKLETLER VE BUNLARIN PARÇA VE AKSESUARLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN TİCARETİ"
    },
    {
        "kod": "454007",
        "tanim": "MOTOSİKLETLER, MOTORLU BİSİKLETLER VE BUNLARIN PARÇA VE AKSESUARLARININ DİĞER PERAKENDE TİCARETİ (UZMANLAŞMAMIŞ OLANLAR İLE İNTERNET, POSTA, TEZGAH, PAZAR VB. YOLUYLA YAPILANLAR)"
    },
    {
        "kod": "461101",
        "tanim": "ÇİÇEKLERİN, BİTKİLERİN, DİĞER TARIMSAL HAMMADDELERİN, TEKSTİL HAMMADDELERİNİN VE YARI MAMUL MALLARIN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461102",
        "tanim": "CANLI HAYVANLARIN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461201",
        "tanim": "KATI, SIVI VE GAZ HALDEKİ YAKITLARIN VE İLGİLİ ÜRÜNLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (MOTORLU TAŞIT YAKITLARI DAHİL)"
    },
    {
        "kod": "461202",
        "tanim": "ENDÜSTRİYEL KİMYASALLAR, GÜBRELER VE ZİRAİ KİMYASAL ÜRÜNLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461203",
        "tanim": "BİRİNCİL FORMDAKİ METALLER VE METAL CEVHERLERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (İNŞAAT DEMİRİ DAHİL)"
    },
    {
        "kod": "461301",
        "tanim": "İNŞAAT MALZEMESİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (İNŞAAT DEMİRİ VE KERESTESİ HARİÇ)"
    },
    {
        "kod": "461302",
        "tanim": "KERESTE VE KERESTE ÜRÜNLERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461401",
        "tanim": "BİLGİSAYAR, YAZILIM, ELEKTRONİK VE TELEKOMÜNİKASYON DONANIMLARININ VE DİĞER BÜRO EKİPMANLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461402",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ TARIM, MAKİNE VE SANAYİ EKİPMANLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461403",
        "tanim": "GEMİLERİN, HAVA TAŞITLARININ VE BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER ULAŞIM ARAÇLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461501",
        "tanim": "MOBİLYALARIN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461502",
        "tanim": "HIRDAVATÇI (NALBURİYE) EŞYALARININ VE EL ALETLERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461503",
        "tanim": "RADYO, TELEVİZYON VE VİDEO CİHAZLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461504",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ÇATAL-BIÇAK TAKIMI, DİĞER KESİCİ ALETLER VE EV EŞYALARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461601",
        "tanim": "DERİ GİYİM EŞYASI, KÜRK VE AYAKKABININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461602",
        "tanim": "DERİ EŞYALAR VE SEYAHAT AKSESUARLARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461603",
        "tanim": "GİYİM EŞYALARININ BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (DERİ GİYİM EŞYALARI HARİÇ)"
    },
    {
        "kod": "461604",
        "tanim": "TEKSTİL ÜRÜNLERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (İPLİK, KUMAŞ, EV TEKSTİLİ, PERDE VB. ÜRÜNLER) (GİYİM EŞYALARI HARİÇ)"
    },
    {
        "kod": "461701",
        "tanim": "GIDA MADDELERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (ARACI ÜRETİCİ BİRLİKLERİ DAHİL, YAŞ SEBZE VE MEYVE HARİÇ)"
    },
    {
        "kod": "461702",
        "tanim": "YAŞ SEBZE VE MEYVELERİN BİR ÜCRET VE SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (KABZIMALLIK VE ARACI ÜRETİCİ BİRLİKLERİ DAHİL)"
    },
    {
        "kod": "461703",
        "tanim": "TÜTÜN VE TÜTÜN ÜRÜNLERİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR (ARACI ÜRETİCİ BİRLİKLERİ DAHİL)"
    },
    {
        "kod": "461704",
        "tanim": "İÇECEKLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461801",
        "tanim": "OYUN VE OYUNCAK, SPOR MALZEMESİ, BİSİKLET, KİTAP, GAZETE, DERGİ, KIRTASİYE ÜRÜNLERİ, MÜZİK ALETİ, SAAT VE MÜCEVHER İLE FOTOĞRAFÇILIKLA İLGİLİ VE OPTİK ALETLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461802",
        "tanim": "KOZMETİK, PARFÜM VE BAKIM ÜRÜNLERİ İLE TEMİZLİK MALZEMESİNİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461803",
        "tanim": "TIBBİ ÜRÜNLERİN, ARAÇ VE MALZEMELERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461804",
        "tanim": "KAĞIT VE KARTON (MUKAVVA) İLE İLGİLİ BELİRLİ ÜRÜNLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461805",
        "tanim": "ECZACILIKLA İLGİLİ ÜRÜNLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461806",
        "tanim": "İŞLENMEMİŞ AĞAÇ, ATIK, HURDA VE GERİ DÖNÜŞTÜRÜLEBİLİR MALZEMELER, VB. BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER BELİRLİ ÜRÜNLERİN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461901",
        "tanim": "ÇEŞİTLİ MALLARIN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "461902",
        "tanim": "ÇEŞİTLİ MALLARIN MÜZAYEDE, MEZAT, AÇIK ARTTIRMA YOLUYLA TOPTAN SATIŞINI YAPAN ARACILAR"
    },
    {
        "kod": "462101",
        "tanim": "HAYVAN YEMİ TOPTAN TİCARETİ (KUŞ YEMİ, YEMLİK KÖKLERİ, YEMLİK KIVIRCIK LAHANA, DARI, KAPLICA, YONCA, YEMLİK MISIR VB. İLE KEPEK, KIRMA, KÜSPE, VB.)"
    },
    {
        "kod": "462102",
        "tanim": "TAHIL TOPTAN TİCARETİ (BUĞDAY, ARPA, ÇAVDAR, YULAF, MISIR, ÇELTİK VB.)"
    },
    {
        "kod": "462103",
        "tanim": "YAĞLI TOHUM VE YAĞLI MEYVELERİN TOPTAN TİCARETİ (SOYA FASULYESİ, YER FISTIĞI, PAMUK ÇEKİRDEĞİ, KETEN TOHUMU, KOLZA, AYÇİÇEĞİ TOHUMU, PAMUK ÇEKİRDEĞİ VB.)"
    },
    {
        "kod": "462104",
        "tanim": "İŞLENMEMİŞ TÜTÜN TOPTAN TİCARETİ"
    },
    {
        "kod": "462105",
        "tanim": "İPEK BÖCEĞİ KOZASI TOPTAN TİCARETİ"
    },
    {
        "kod": "462106",
        "tanim": "PAMUK TOPTAN TİCARETİ"
    },
    {
        "kod": "462107",
        "tanim": "YÜN VE TİFTİK TOPTAN TİCARETİ"
    },
    {
        "kod": "462108",
        "tanim": "TOHUM (YAĞLI TOHUMLAR HARİÇ) TOPTAN TİCARETİ (SEBZE TOHUMLARI, ÇİÇEK TOHUMLARI VE ORMAN AĞACI TOHUMLARI DAHİL)"
    },
    {
        "kod": "462190",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER TARIMSAL HAM MADDELERİN TOPTAN TİCARETİ (İŞLENMEMİŞ YENİLEMEYEN SAKATATLAR, KUŞTÜYÜ VE DERİLERİ, LAKA, KINA ÇİÇEĞİ, DOĞAL SÜNGERLER, DOĞAL MANTAR (YENİLENLER HARİÇ), KARABİBER, DOĞAL KAUÇUK VB.)"
    },
    {
        "kod": "462201",
        "tanim": "ÇİÇEK, BİTKİ VE ÇİÇEK SOĞANI TOPTAN TİCARETİ"
    },
    {
        "kod": "462301",
        "tanim": "CANLI HAYVANLARIN TOPTAN TİCARETİ (CELEPÇİLİK) (KÜMES HAYVANLARI HARİÇ)"
    },
    {
        "kod": "462302",
        "tanim": "CANLI KÜMES HAYVANLARI (TAVUK, HİNDİ, VB.) TOPTAN TİCARETİ"
    },
    {
        "kod": "462401",
        "tanim": "HAM DERİ, POST VE KÜRKLÜ DERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "462402",
        "tanim": "TABAKLANMIŞ DERİ, GÜDERİ VE KÖSELE TOPTAN TİCARETİ"
    },
    {
        "kod": "463101",
        "tanim": "FINDIK, ANTEP FISTIĞI, YER FISTIĞI VE CEVİZ TOPTAN TİCARETİ (KAVRULMUŞ OLANLAR HARİÇ)"
    },
    {
        "kod": "463102",
        "tanim": "TAZE İNCİR VE ÜZÜM TOPTAN TİCARETİ"
    },
    {
        "kod": "463103",
        "tanim": "NARENCİYE TOPTAN TİCARETİ"
    },
    {
        "kod": "463104",
        "tanim": "DİĞER TAZE MEYVE SEBZE TOPTAN TİCARETİ (PATATES DAHİL)"
    },
    {
        "kod": "463105",
        "tanim": "ZEYTİN (İŞLENMİŞ) TOPTAN TİCARETİ"
    },
    {
        "kod": "463106",
        "tanim": "KÜLTÜR MANTARI TOPTAN TİCARETİ"
    },
    {
        "kod": "463108",
        "tanim": "KURU BAKLİYAT ÜRÜNLERİ TOPTAN TİCARETİ (FASULYE, MERCİMEK, NOHUT, VB.)"
    },
    {
        "kod": "463109",
        "tanim": "KAVRULMUŞ VEYA İŞLENMİŞ KURUYEMİŞ TOPTAN TİCARETİ (LEBLEBİ, KAVRULMUŞ FINDIK, FISTIK, ÇEKİRDEK VB.)"
    },
    {
        "kod": "463110",
        "tanim": "KURU ÜZÜM TOPTAN TİCARETİ"
    },
    {
        "kod": "463111",
        "tanim": "KURU İNCİR TOPTAN TİCARETİ"
    },
    {
        "kod": "463112",
        "tanim": "KURU KAYISI TOPTAN TİCARETİ"
    },
    {
        "kod": "463190",
        "tanim": "DİĞER İŞLENMİŞ VEYA KORUNMUŞ SEBZE VE MEYVE TOPTAN TİCARETİ (REÇEL, PEKMEZ, PESTİL, SALAMURA VEYA TURŞUSU YAPILMIŞ OLANLAR DAHİL) (FINDIK, İNCİR, ÜZÜM, NARENCİYE, ZEYTİN, KÜLTÜR MANTARI VE KURUYEMİŞ HARİÇ)"
    },
    {
        "kod": "463201",
        "tanim": "KÜMES HAYVANLARI VE AV HAYVANLARI ETLERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "463202",
        "tanim": "ET TOPTAN TİCARETİ (AV HAYVANLARI VE KÜMES HAYVANLARI ETLERİ HARİÇ)"
    },
    {
        "kod": "463203",
        "tanim": "YENİLEBİLİR SAKATAT (CİĞER, İŞKEMBE, BÖBREK, TAŞLIK VB.) TOPTAN TİCARETİ"
    },
    {
        "kod": "463204",
        "tanim": "ET ÜRÜNLERİNİN TOPTAN TİCARETİ (SALAM, SOSİS, SUCUK, PASTIRMA VB.)"
    },
    {
        "kod": "463301",
        "tanim": "SÜT ÜRÜNLERİ TOPTAN TİCARETİ (İŞLENMİŞ SÜT, SÜT TOZU, YOĞURT, PEYNİR, KAYMAK, TEREYAĞ VB.)"
    },
    {
        "kod": "463302",
        "tanim": "YUMURTA VE YUMURTA ÜRÜNLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "463303",
        "tanim": "HAYVAN VEYA BİTKİSEL KAYNAKLI YENİLEBİLİR SIVI VE KATI YAĞLARIN TOPTAN TİCARETİ (TEREYAĞ HARİÇ)"
    },
    {
        "kod": "463401",
        "tanim": "ALKOLLÜ İÇECEKLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "463402",
        "tanim": "MEYVE VE SEBZE SULARI, MADEN SUYU, MEŞRUBAT VE DİĞER ALKOLSÜZ İÇECEKLERİN TOPTAN TİCARETİ (SU HARİÇ)"
    },
    {
        "kod": "463403",
        "tanim": "SU TOPTAN TİCARETİ (SU İSTASYONLARI DAHİL, ŞEBEKE SUYU HARİÇ)"
    },
    {
        "kod": "463501",
        "tanim": "TÜTÜN ÜRÜNLERİNİN TOPTAN TİCARETİ (PİPO TÜTÜNÜ, SİGARA, PURO VB.) (İŞLENMEMİŞ TÜTÜN HARİÇ)"
    },
    {
        "kod": "463601",
        "tanim": "ÇİKOLATA VE ŞEKERLEME TOPTAN TİCARETİ (HELVA, LOKUM, AKİDE ŞEKERİ, BONBON ŞEKERİ VB. DAHİL)"
    },
    {
        "kod": "463602",
        "tanim": "FIRINCILIK MAMULLERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "463603",
        "tanim": "ŞEKER TOPTAN TİCARETİ (TOZ ŞEKER, KESME ŞEKER, KRİSTAL ŞEKER VB.)"
    },
    {
        "kod": "463604",
        "tanim": "DONDURMA VE DİĞER YENİLEBİLİR BUZLARIN TOPTAN SATIŞI"
    },
    {
        "kod": "463701",
        "tanim": "ÇAY TOPTAN TİCARETİ"
    },
    {
        "kod": "463702",
        "tanim": "KAHVE, KAKAO VE BAHARAT TOPTAN TİCARETİ"
    },
    {
        "kod": "463703",
        "tanim": "İÇECEK AMAÇLI KULLANILAN AROMATİK BİTKİLERİN TOPTAN TİCARETİ "
    },
    {
        "kod": "463801",
        "tanim": "BALIK, KABUKLULAR, YUMUŞAKÇALAR VE DİĞER SU ÜRÜNLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "463802",
        "tanim": "EV HAYVANLARI İÇİN YEMLERİN VEYA YİYECEKLERİN TOPTAN TİCARETİ (ÇİFTLİK HAYVANLARI İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "463803",
        "tanim": "GIDA TUZU (SOFRA TUZU) TOPTAN TİCARETİ"
    },
    {
        "kod": "463804",
        "tanim": "UN, NİŞASTA, MAKARNA, ŞEHRİYE VB. ÜRÜNLER İLE HAZIR GIDALARIN (ET/SEBZE SULARI, HAZIR ÇORBALAR VB.) TOPTAN TİCARETİ (EKMEK MAYASI, KURU MAYA VB. DAHİL)"
    },
    {
        "kod": "463805",
        "tanim": "HAZIR HOMOJENİZE GIDA İLE DİYETETİK GIDA ÜRÜNLERİ TOPTAN TİCARETİ (BEBEK MAMASI, DİYET GIDALARI, SPORCU GIDALARI VB.)"
    },
    {
        "kod": "463806",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER GIDA ÜRÜNLERİNİN TOPTAN TİCARETİ (DOĞAL BAL, MALT, HAZIR YEMEK, SİRKE VB.)"
    },
    {
        "kod": "463901",
        "tanim": "BELLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA DONDURULMUŞ GIDA TOPTAN TİCARETİ"
    },
    {
        "kod": "463902",
        "tanim": "BELLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA GIDA (DONDURULMAMIŞ), İÇECEK VE TÜTÜN TOPTAN TİCARETİ"
    },
    {
        "kod": "464101",
        "tanim": "EVDE KULLANILAN TEKSTİL TAKIMLARI, PERDELER VE ÇEŞİTLİ TEKSTİL MALZEMESİNDEN EV EŞYALARI TOPTAN TİCARETİ (ÇARŞAF, YATAK TAKIMI, YASTIK KILIFI, MASA ÖRTÜSÜ, HAVLU, BATTANİYE, YORGAN, DİĞER MEFRUŞATLAR VB. DAHİL)"
    },
    {
        "kod": "464102",
        "tanim": "TUHAFİYE ÜRÜNLERİ TOPTAN TİCARETİ (İĞNE, DİKİŞ İPLİĞİ, DÜĞME, FERMUAR, ÇITÇIT, FİSTO, DANTEL, GİPÜR VB.)"
    },
    {
        "kod": "464103",
        "tanim": "KUMAŞ TOPTAN TİCARETİ (MANİFATURA ÜRÜNLERİ DAHİL)"
    },
    {
        "kod": "464104",
        "tanim": "İPLİK TOPTAN TİCARETİ (TUHAFİYE ÜRÜNLERİ İLE DİKİŞ İPLİĞİ HARİÇ)"
    },
    {
        "kod": "464105",
        "tanim": "DİĞER TEKSTİL ÜRÜNLERİ TOPTAN TİCARETİ (BALIK AĞI, ÇUVAL, ÇUL, HALAT, URGAN DAHİL)"
    },
    {
        "kod": "464201",
        "tanim": "BEBEK GİYSİLERİ, SPORCU GİYSİLERİ VE DİĞER GİYİM EŞYALARININ TOPTAN TİCARETİ (KAYAK KIYAFETLERİ, YÜZME KIYAFETLERİ, MAYO VB. DAHİL)"
    },
    {
        "kod": "464202",
        "tanim": "AYAKKABI TOPTAN TİCARETİ (TERLİK, ÇARIK, MES, VB. DAHİL, SPOR AYAKKABILARI HARİÇ)"
    },
    {
        "kod": "464203",
        "tanim": "ÇORAP VE GİYSİ AKSESUARLARININ TOPTAN TİCARETİ (ŞAPKA, ELDİVEN, ŞAL, PAPYON, KRAVAT VB.)"
    },
    {
        "kod": "464204",
        "tanim": "KÜRK VE DERİDEN GİYİM EŞYALARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "464205",
        "tanim": "DIŞ GİYİM EŞYALARININ TOPTAN TİCARETİ (İŞ GİYSİLERİ İLE TRİKO OLANLAR DAHİL, KÜRK VE DERİDEN OLANLAR HARİÇ)"
    },
    {
        "kod": "464206",
        "tanim": "İÇ GİYİM EŞYALARININ TOPTAN TİCARETİ (SLİP, KÜLOT, GÖMLEK, TİŞÖRT, SABAHLIK, PİJAMA, SÜTYEN, KORSE, BORNOZ, VB.)"
    },
    {
        "kod": "464207",
        "tanim": "ŞEMSİYE TOPTAN TİCARETİ (GÜNEŞ VE BAHÇE ŞEMSİYELERİ HARİÇ)"
    },
    {
        "kod": "464208",
        "tanim": "AYAKKABI MALZEMELERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "464301",
        "tanim": "BEYAZ EŞYA TOPTAN TİCARETİ (BUZDOLABI, ÇAMAŞIR MAKİNESİ, BULAŞIK MAKİNESİ, FIRIN, ÇAMAŞIR KURUTMA MAKİNESİ VB.)"
    },
    {
        "kod": "464304",
        "tanim": "PLAK, SES VE GÖRÜNTÜ KASETLERİ, CD VE DVD TOPTAN TİCARETİ (BOŞ OLANLAR HARİÇ)"
    },
    {
        "kod": "464305",
        "tanim": "ELEKTRİK MALZEMELERİ TOPTAN TİCARETİ (KABLO, SİGORTA, DUY, FİŞ, PRİZ, AMPUL, ELEKTRİK ANAHTARI, DEVRE KESİCİ, ŞALTER, RÖLE, PİL, BATARYA, VB.) (ENDÜSTRİYEL OLANLAR İLE ELEKTRİKLİ MAKİNE, CİHAZ VE ALETLERDE KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "464308",
        "tanim": "HIRSIZ VE YANGIN ALARMLARI İLE BENZERİ CİHAZLARIN TOPTAN TİCARETİ - EVLERDE KULLANIM AMAÇLI"
    },
    {
        "kod": "464309",
        "tanim": "RADYO, TELEVİZYON, VİDEO VE DVD CİHAZLARININ TOPTAN TİCARETİ (ANTENLER İLE ARABALAR İÇİN RADYO VE TV EKİPMANLARI DAHİL)"
    },
    {
        "kod": "464310",
        "tanim": "FOTOĞRAFÇILIKLA İLGİLİ ÜRÜNLERİN TOPTAN TİCARETİ (FLAŞ LAMBALARI, FOTOĞRAFÇILIK EMÜLSİYONLARI, POLARİZAN MADDELER, FOTOGRAFİK LEVHA VE FİLMLER VB.)"
    },
    {
        "kod": "464311",
        "tanim": "OPTİK ÜRÜNLERİN TOPTAN TİCARETİ (GÖZLÜKLER, SAAT VE GÖZLÜK CAMLARI, DÜRBÜN, VB.)"
    },
    {
        "kod": "464312",
        "tanim": "KONUTLARDA, BÜROLARDA VE MAĞAZALARDA KULLANILAN (SANAYİ TİPİ OLMAYAN) KLİMALARIN TOPTAN TİCARETİ"
    },
    {
        "kod": "464390",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ELEKTRİKLİ EV ALETLERİ TOPTAN TİCARETİ (ÜTÜ, ELEKTRİK SÜPÜRGESİ, ASPİRATÖR, VANTİLATÖR,TIRAŞ MAKİNESİ, SAÇ KURUTMA MAKİNESİ, SU ARITMA CİHAZI, DİKİŞ MAKİNESİ, ŞOFBEN, ELEKTRİKLİ SOBA, ELEKTRİKLİ RADYATÖR VB.)"
    },
    {
        "kod": "464401",
        "tanim": "PORSELEN VE CAM EŞYALAR İLE TOPRAK VE SERAMİKTEN YAPILAN ÜRÜNLERİN TOPTAN TİCARETİ (ÇİNİ, BİLLURİYE, CAM VEYA PORSELENDEN ÇANAK, TABAK, BARDAK, VAZO, TEPSİ, SÜS EŞYASI, VB.)"
    },
    {
        "kod": "464402",
        "tanim": "TEMİZLİK MALZEMESİ TOPTAN TİCARETİ (DETERJAN, OVMA KREM VE TOZLARI, YUMUŞATICILAR, ARAP SABUNU, VB. DAHİL, KİŞİSEL TEMİZLİK SABUNLARI HARİÇ)"
    },
    {
        "kod": "464404",
        "tanim": "CİLA VE KREM (AYAKKABI, MOBİLYA, YER DÖŞEMESİ, KAPORTA, CAM VEYA METAL İÇİN) TOPTAN TİCARETİ"
    },
    {
        "kod": "464501",
        "tanim": "PARFÜM, KOZMETİK ÜRÜNLERİ VE KOLONYA TOPTAN TİCARETİ (ITRİYAT DAHİL)"
    },
    {
        "kod": "464502",
        "tanim": "SABUN TOPTAN TİCARETİ (KİŞİSEL TEMİZLİK İÇİN)"
    },
    {
        "kod": "464601",
        "tanim": "CERRAHİ, TIBBİ VE ORTOPEDİK ALET VE CİHAZLARIN TOPTAN TİCARETİ"
    },
    {
        "kod": "464602",
        "tanim": "TEMEL ECZACILIK ÜRÜNLERİ İLE ECZACILIK MÜSTAHZARLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "464603",
        "tanim": "DİŞÇİLİKTE KULLANILAN ALET VE CİHAZLARIN TOPTAN TİCARETİ (PROTEZLER, BAĞLANTI PARÇALARI DAHİL) "
    },
    {
        "kod": "464604",
        "tanim": "HAYVAN SAĞLIĞI İLE İLGİLİ İLAÇLARIN TOPTAN TİCARETİ (SERUM, AŞI, VB.)"
    },
    {
        "kod": "464701",
        "tanim": "MOBİLYA VE MOBİLYA AKSESUARLARI TOPTAN TİCARETİ (YATAK DAHİL)"
    },
    {
        "kod": "464702",
        "tanim": "HALI, KİLİM, VB. YER KAPLAMALARI TOPTAN TİCARETİ"
    },
    {
        "kod": "464703",
        "tanim": "AYDINLATMA EKİPMANLARININ TOPTAN TİCARETİ (AVİZE, ABAJUR, VB.)"
    },
    {
        "kod": "464801",
        "tanim": "MÜCEVHER VE TAKI TOPTAN TİCARETİ (ALTIN, GÜMÜŞ, VB. OLANLAR) (İMİTASYON OLANLAR HARİÇ)"
    },
    {
        "kod": "464802",
        "tanim": "SAAT TOPTAN TİCARETİ (KOL, MASA, DUVAR, VB. SAATLER İLE KRONOMETRELER)"
    },
    {
        "kod": "464901",
        "tanim": "DERİ EŞYALAR VE SEYAHAT AKSESUARLARI TOPTAN TİCARETİ (ÇANTA, VALİZ, CÜZDAN, KEMER, VB. DAHİL)"
    },
    {
        "kod": "464902",
        "tanim": "SPOR MALZEMESİ TOPTAN TİCARETİ (BASKETBOL, FUTBOL, VB. SPOR AYAKKABILARI, KAYAK BOTLARI GİBİ ÖZEL SPOR AYAKKABILARI, BİSİKLETLER VE BİSİKLET PARÇALARI İLE AKSESUARLARI, ÇADIR VE KAMP MALZEMELERİ DAHİL)"
    },
    {
        "kod": "464903",
        "tanim": "KIRTASİYE ÜRÜNLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "464904",
        "tanim": "OYUN VE OYUNCAK TOPTAN TİCARETİ (YAP-BOZLAR, OYUN KAĞITLARI, JETONLA ÇALIŞAN OYUN MAKİNELERİ VB. DAHİL)"
    },
    {
        "kod": "464905",
        "tanim": "HASIR EŞYALAR, MANTAR EŞYALAR VE DİĞER AHŞAP ÜRÜNLERİN TOPTAN TİCARETİ (İP VB. İÇİN MAKARALAR DAHİL)"
    },
    {
        "kod": "464906",
        "tanim": "MÜZİK ALETLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "464907",
        "tanim": "ÇATAL-BIÇAK TAKIMI VE DİĞER KESİCİ ALETLER İLE METAL SOFRA VE MUTFAK EŞYALARININ TOPTAN TİCARETİ (BAKIR MUTFAK EŞYALARI DAHİL)"
    },
    {
        "kod": "464908",
        "tanim": "TUVALET KAĞIDI, PEÇETE, KAĞIT HAVLU İLE KAĞIT TEPSİ, TABAK, BARDAK, ÇOCUK BEZİ VB. TOPTAN TİCARETİ (PLASTİKTEN OLANLAR HARİÇ)"
    },
    {
        "kod": "464909",
        "tanim": "SPORTİF AMAÇLI AVCILIK VE BALIKÇILIK MALZEMELERİ TOPTAN TİCARETİ (TABANCA, AV TÜFEĞİ VE BALIK AĞLARI HARİÇ)"
    },
    {
        "kod": "464911",
        "tanim": "KİTAP, DERGİ VE GAZETE TOPTAN TİCARETİ"
    },
    {
        "kod": "464912",
        "tanim": "HEDİYELİK EŞYA TOPTAN TİCARETİ (PİPO, TESPİH, BAKIR SÜS EŞYALARI, İMİTASYON TAKILAR DAHİL)"
    },
    {
        "kod": "464916",
        "tanim": "KİŞİSEL VEYA EV TİPİ TARTI ALETLERİ VE BASKÜLLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "464917",
        "tanim": "PLASTİK SOFRA, MUTFAK VE DİĞER EV EŞYASI İLE TUVALET EŞYASI TOPTAN TİCARETİ (PLASTİK TEPSİ, BARDAK, TABAK, POŞET, SÜNGER VB.)"
    },
    {
        "kod": "464921",
        "tanim": "SANAT ESERLERİ TOPTAN TİCARETİ (BÜST VE HEYKELLER DAHİL)"
    },
    {
        "kod": "464922",
        "tanim": "TIRAŞ BIÇAKLARI, USTURALAR VE JİLETLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "464923",
        "tanim": "SANATSAL REPRODÜKSİYON ÜRÜNLERİNİN TOPTAN TİCARETİ (RESİM, FOTOĞRAF VB.)"
    },
    {
        "kod": "464924",
        "tanim": "RESİM, FOTOĞRAF VB. İÇİN ÇERÇEVE TOPTAN TİCARETİ"
    },
    {
        "kod": "464925",
        "tanim": "ARI KOVANI TOPTAN TİCARETİ"
    },
    {
        "kod": "464926",
        "tanim": "SPOR VE EĞLENCE AMAÇLI TEKNELERİN, KAYIKLARIN VE KANOLARIN TOPTAN TİCARETİ (DENİZ TAŞITLARI İÇİN DIŞTAN TAKMALI MOTORLAR DAHİL)"
    },
    {
        "kod": "464927",
        "tanim": "PUL VE JETON TOPTAN TİCARETİ"
    },
    {
        "kod": "464990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ EV EŞYALARI VE EV GEREÇLERİNİN TOPTAN TİCARETİ (GÜNEŞ VE BAHÇE ŞEMSİYESİ, ÇAKMAK, KİBRİT, SÜPÜRGE FIRÇASI, DİŞ FIRÇASI, SAÇ FIRÇASI, YAPMA ÇİÇEK, MUM, BEBEK ARABASI, ŞİŞME YATAK, ELEKTRİKSİZ SOBA, KUZİNE, GAZ OCAĞI VB.)"
    },
    {
        "kod": "465101",
        "tanim": "BİLGİSAYAR, BİLGİSAYAR ÇEVRE BİRİMLERİ VE YAZILIMLARININ TOPTAN TİCARETİ (BİLGİSAYAR DONANIMLARI, POS CİHAZLARI, ATM CİHAZLARI VB. DAHİL)"
    },
    {
        "kod": "465201",
        "tanim": "TELEKOMÜNİKASYON EKİPMAN VE PARÇALARININ TOPTAN TİCARETİ (TELEFON VE İLETİŞİM EKİPMANLARI DAHİL)"
    },
    {
        "kod": "465202",
        "tanim": "ELEKTRONİK CİHAZ VE PARÇALARININ TOPTAN TİCARETİ (ELEKTRONİK VALFLER, TÜPLER, YARI İLETKEN CİHAZLAR, MİKROÇİPLER, ENTEGRE DEVRELER, BASKILI DEVRELER, VB.) (SEYRÜSEFER CİHAZLARI HARİÇ)"
    },
    {
        "kod": "465204",
        "tanim": "BOŞ SES VE GÖRÜNTÜ KASET VE DİSKETLERİ İLE MANYETİK VE OPTİK DİSK, CD VE DVD TOPTAN TİCARETİ"
    },
    {
        "kod": "465205",
        "tanim": "YÖN BULMA PUSULALARI VE DİĞER SEYRÜSEFER ALET VE CİHAZLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "466102",
        "tanim": "TARIM, HAYVANCILIK VE ORMANCILIK MAKİNE VE EKİPMANLARI İLE AKSAM VE PARÇALARININ TOPTAN TİCARETİ (TRAKTÖR, TARIMSAL RÖMORK, PULLUK, GÜBRE YAYMA MAKİNESİ, MİBZER, BİÇER DÖVER, SÜT SAĞMA MAKİNESİ, KÜMES HAYVANLARI MAKİNELERİ, ARICILIK MAKİNELERİ, VB.)"
    },
    {
        "kod": "466103",
        "tanim": "ÇİM BİÇME VE BAHÇE MAKİNE VE EKİPMANLARI İLE AKSAM VE PARÇALARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "466201",
        "tanim": "AĞAÇ İŞLEME TAKIM TEZGAHLARI VE PARÇALARININ TOPTAN TİCARETİ (PARÇA TUTUCULARI DAHİL)"
    },
    {
        "kod": "466202",
        "tanim": "METAL İŞLEME TAKIM TEZGAHLARININ VE PARÇALARININ TOPTAN TİCARETİ (PARÇA TUTUCULARI DAHİL)"
    },
    {
        "kod": "466204",
        "tanim": "LEHİMLEME VEYA KAYNAK YAPMA İÇİN KULLANILAN MAKİNELER İLE METALLERİN VEYA SİNTERLENMİŞ METAL KARBÜRLERİN SICAK SPREYLENMESİ İÇİN KULLANILAN ELEKTRİKLİ MAKİNE VE CİHAZLAR İLE PARÇALARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "466290",
        "tanim": "DİĞER MALZEMELERİ İŞLEME İÇİN TAKIM TEZGAHLARI VE PARÇALARININ TOPTAN TİCARETİ (PARÇA TUTUCULARI DAHİL) (AĞAÇ VE METAL İŞLEMEDE KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "466301",
        "tanim": "BİNA VE BİNA DIŞI İNŞAAT İŞ MAKİNELERİNİN TOPTAN TİCARETİ (İNŞAAT POMPALARI, DOZER, GREYDER, KEPÇE VB. DAHİL)"
    },
    {
        "kod": "466302",
        "tanim": "MADENCİLİK MAKİNELERİNİN TOPTAN TİCARETİ (MADENLER İÇİN BOCURGATLAR, SÜREKLİ HAREKETLİ ELAVATÖRLER VE KONVEYÖRLER DAHİL)"
    },
    {
        "kod": "466401",
        "tanim": "TEKSTİL ENDÜSTRİSİ MAKİNELERİ İLE DİKİŞ VE ÖRGÜ MAKİNELERİNİN TOPTAN TİCARETİ (EV TİPİ OLANLAR HARİÇ)"
    },
    {
        "kod": "466402",
        "tanim": "TEKSTİL ENDÜSTRİSİ MAKİNELERİNİN, DİKİŞ VE ÖRGÜ MAKİNELERİNİNİN PARÇA VE AKSESUARLARININ TOPTAN TİCARETİ (EV TİPİ OLANLARA AİT PARÇA VE AKSESUARLAR HARİÇ)"
    },
    {
        "kod": "466501",
        "tanim": "BÜRO MOBİLYALARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "466601",
        "tanim": "DİĞER BÜRO MAKİNE VE EKİPMANLARININ TOPTAN TİCARETİ (BİLGİSAYAR VE BİLGİSAYAR ÇEVRE DONANIMLARI HARİÇ) (HESAP MAKİNESİ, DAKTİLO, YAZARKASA, FOTOKOPİ MAKİNESİ, STENOGRAFİ MAKİNESİ, KALEMTIRAŞ, BÜRO TİPİ ZIMBA, DELGİ ALETİ VB.)"
    },
    {
        "kod": "466901",
        "tanim": "ULAŞIM ARAÇLARI TOPTAN TİCARETİ (GEMİ, RÖMORKÖR, LOKOMOTİF, HAVA TAŞITLARI VB. İLE BUNLARIN PARÇALARI VE KONTEYNERLER DAHİL, MOTORLU KARA TAŞITLARI, MOTOSİKLET VE BİSİKLETLER HARİÇ)"
    },
    {
        "kod": "466903",
        "tanim": "AKÜMÜLATÖR, BATARYA, PİL VE BULARIN PARÇALARININ TOPTAN TİCARETİ (EVLERDE, MOTOSİKLETLERDE VE MOTORLU KARA TAŞITLARINDA KULLANILANLAR HARİÇ)"
    },
    {
        "kod": "466904",
        "tanim": "KOMPRESÖR VE PARÇALARININ TOPTAN TİCARETİ (SOĞUTMA, HAVA VE DİĞER AMAÇLAR İÇİN)"
    },
    {
        "kod": "466905",
        "tanim": "SİLAH VE MÜHİMMAT TOPTAN TİCARETİ (TABANCA, AV TÜFEĞİ VB. DAHİL)"
    },
    {
        "kod": "466906",
        "tanim": "MAKİNE VE EKİPMANLARLA İLGİLİ AKSAM VE PARÇALARIN TOPTAN TİCARETİ (DEĞİRMENTAŞI, BİLEĞİ TAŞI, ZIMPARA VE AŞINDIRMA ÜRÜNLERİ, KONVEYÖR BANTLARI, TEKNİK KULLANIM İÇİN CAM VE SERAMİK ÜRÜNLER, RULMANLAR, VB.) (MOTORLU KARA TAŞITLARI İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "466907",
        "tanim": "KALDIRMA VE TAŞIMA EKİPMANLARININ TOPTAN TİCARETİ (FORKLİFTLER, ARAÇ LİFTLERİ, ASANSÖRLER, YÜRÜYEN MERDİVENLER, KONVEYÖRLER, VİNÇLER, VB.)"
    },
    {
        "kod": "466908",
        "tanim": "GIDA, İÇECEK VE TÜTÜN SANAYİSİNDE KULLANILAN MAKİNELER İLE PARÇALARININ TOPTAN TİCARETİ (ŞİŞE VB. KAPLARI TEMİZLEME VE DOLDURMA MAKİNELERİ, SÜT ÜRÜNLERİ MAKİNELERİ, İÇECEK VE TÜTÜN İŞLEME MAKİNELERİ, TARIMSAL ÜRÜN KURUTUCULARI VB.)"
    },
    {
        "kod": "466909",
        "tanim": "RÜZGAR TÜRBİNLERİ, KONDANSATÖRLER, ELEKTRİK YALITKANLARI (İZOLATÖR), AC/AD/DC MOTORLAR, JENERATÖRLER, YALITILMIŞ BOBİN TELLERİ VB. ELEKTRİKLİ MAKİNE, CİHAZ VE ALETLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "466910",
        "tanim": "HIRSIZ VE YANGIN ALARMLARI İLE SİNYALİZASYON VE TRAFİK KONTROL EKİPMANLARI TOPTAN TİCARETİ (EV VE ARABALAR İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "466911",
        "tanim": "GAZ, SIVI VEYA ELEKTRİK TEMİN VEYA ÜRETİM SAYAÇLARI TOPTAN TİCARETİ"
    },
    {
        "kod": "466912",
        "tanim": "SU BUHARI, HİDROLİK VE GAZ TÜRBİNLERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "466913",
        "tanim": "BASKÜL, KANTAR VE DİĞER TARTI VE ÖLÇÜM MAKİNELERİ TOPTAN TİCARETİ (EV TİPİ OLANLAR HARİÇ)"
    },
    {
        "kod": "466914",
        "tanim": "X IŞINININ VEYA ALFA, BETA YA DA GAMA IŞINLARININ KULLANIMINA DAYALI CİHAZLARIN TOPTAN TİCARETİ"
    },
    {
        "kod": "466915",
        "tanim": "BUHAR ÜRETİM KAZANLARI VE KIZGIN SU KAZANLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "466916",
        "tanim": "ZIRHLI VEYA GÜÇLENDİRİLMİŞ KASALAR VE KUTULAR İLE KASA DAİRELERİ İÇİN ZIRHLI VEYA GÜÇLENDİRİLMİŞ KAPILAR VE KİLİTLİ KUTULAR İLE PARA VEYA EVRAK KUTULARI, VB. (ADİ METALDEN) TOPTAN TİCARETİ"
    },
    {
        "kod": "466917",
        "tanim": "YANGIN SÖNDÜRÜCÜLER, PÜSKÜRTME TABANCALARI, BUHAR VEYA KUM PÜSKÜRTME MAKİNELERİ İLE BENZERİ MEKANİK CİHAZLARIN TOPTAN TİCARETİ (TARIMSAL AMAÇLI KULLANILANLAR İLE TAŞITLAR İÇİN YANGIN SÖNDÜRÜCÜLER HARİÇ)"
    },
    {
        "kod": "466918",
        "tanim": "İŞ GÜVENLİĞİ AMAÇLI KİŞİSEL KORUYUCU DONANIMLARIN TOPTAN TİCARETİ"
    },
    {
        "kod": "466990",
        "tanim": "GENEL VE ÖZEL AMAÇLI DİĞER MAKİNE, CİHAZ VE ALETLERİN TOPTAN TİCARETİ (METAL DÖKÜM İÇİN KALIPLAR, DEMİR VEYA ÇELİKTEN TANKLAR, VARİLLER, FIÇILAR, KUTULAR İLE TIPALAR, ŞİŞE KAPAKLARI, VB. DAHİL)"
    },
    {
        "kod": "467101",
        "tanim": "SIVI YAKITLAR VE BUNLARLA İLGİLİ ÜRÜNLERİN TOPTAN TİCARETİ (HAM PETROL, HAM YAĞ, MAZOT, BENZİN, BİODİZEL, FUEL OİL, GAZ YAĞI, MADENİ YAĞLAR, GRES YAĞLARI VB.)"
    },
    {
        "kod": "467102",
        "tanim": "KATI YAKITLAR VE BUNLARLA İLGİLİ ÜRÜNLERİN TOPTAN TİCARETİ (LİNYİT, TAŞ KÖMÜRÜ, ODUN KÖMÜRÜ, KOK KÖMÜRÜ, YAKACAK ODUN VB.)"
    },
    {
        "kod": "467103",
        "tanim": "GAZLI YAKITLAR VE BUNLARLA İLGİLİ ÜRÜNLERİN TOPTAN TİCARETİ (LPG (BÜTAN VE PROPAN), TÜPGAZ, DOĞALGAZ (LNG, CNG) VB. DAHİL, ŞEBEKE ÜZERİNDEN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "467201",
        "tanim": "DEMİR DIŞI METAL CEVHERLERİ VE KONSANTRELERİNİN TOPTAN TİCARETİ (ALÜMİNYUM, BAKIR, NİKEL, KURŞUN, ÇİNKO, KALAY, VB. CEVHERLERİ DAHİL, URANYUM VE TORYUM CEVHERLERİ İLE DEĞERLİ METAL CEVHERLERİ HARİÇ)"
    },
    {
        "kod": "467202",
        "tanim": "BİRİNCİL FORMDAKİ DEMİR DIŞI METALLERİN TOPTAN TİCARETİ - KÜTÜK, BLOK, GRANÜL, TOZ, PELET, LEVHA, BAR, ÇUBUK, PROFİL VB. FORMLARDA (ALÜMİNYUM, BAKIR, NİKEL, KURŞUN, ÇİNKO, KALAY, VB. DAHİL, ALTIN, GÜMÜŞ VE PLATİN HARİÇ)"
    },
    {
        "kod": "467203",
        "tanim": "DEĞERLİ METAL CEVHERLERİ VE KONSANTRELERİNİN TOPTAN TİCARETİ (ALTIN, GÜMÜŞ, PLATİN VB.)"
    },
    {
        "kod": "467204",
        "tanim": "DEMİR CEVHERLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "467205",
        "tanim": "BİRİNCİL FORMDAKİ DEMİR VE ÇELİK TOPTAN TİCARETİ - KÜTÜK (İNGOT), BLOK, GRANÜL, TOZ, PELET, PARÇA VB. FORMLARDA (PİK DEMİR, MANGANEZLİ DÖKME DEMİR, DEMİR, ÇELİK VE ÇELİK ALAŞIMLARI VB.)"
    },
    {
        "kod": "467206",
        "tanim": "BİRİNCİL FORMDAKİ DEĞERLİ METALLERİN TOPTAN TİCARETİ - KÜTÜK, BLOK, GRANÜL, TOZ, PELET, LEVHA, BAR, ÇUBUK, PROFİL VB. FORMLARDA (ALTIN, GÜMÜŞ, PLATİN VB.)"
    },
    {
        "kod": "467207",
        "tanim": "URANYUM VE TORYUM CEVHERLERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "467208",
        "tanim": "DEMİR/ÇELİKTEN HADDELENMİŞ/SOĞUK ÇEKİLMİŞ YASSI ÜRÜNLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "467209",
        "tanim": "DEMİR/ÇELİKTEN BAR VE ÇUBUKLARIN, PROFİLLERİN, LEVHA KAZIKLARIN (PALPLANŞ), TÜP VE BORULARIN TOPTAN TİCARETİ (FİLMAŞİN, İNŞAAT DEMİRİ, SONDAJ BORUSU, PETROL, GAZ VB. HATLAR İÇİN BORULAR, VB. İLE TEL DAHİL)"
    },
    {
        "kod": "467210",
        "tanim": "DEMİR/ÇELİKTEN DİĞER BİRİNCİL FORMDAKİ ÜRÜNLERİN TOPTAN TİCARETİ (NERVÜRLÜ LEVHALAR, SANDVİÇ PANELLER VE DEMİR YOLU VEYA TRAMVAY YOLU YAPIM MALZEMESİ DAHİL)"
    },
    {
        "kod": "467301",
        "tanim": "AĞACIN İLK İŞLENMESİNDEN ELDE EDİLEN ÜRÜNLERİN TOPTAN TİCARETİ (KERESTE, AĞAÇ YÜNÜ, TALAŞ VE YONGASI, DEMİR YOLU VE TRAMVAY TRAVERSLERİ, KONTRPLAK, YONGA VE LİFLİ LEVHALAR (MDF, SUNTA VB.), PARKE PANEL, AHŞAP VARİL, FIÇI VE DİĞER MUHAFAZALAR, VB.)"
    },
    {
        "kod": "467302",
        "tanim": "BOYA, VERNİK VE LAK TOPTAN TİCARETİ"
    },
    {
        "kod": "467303",
        "tanim": "DÜZ CAM TOPTAN TİCARETİ (PENCERE CAMI, CAM AYNA, EMNİYET CAMI, TEMPERLİ DÜZ CAM, ÇOK KATLI YALITIM CAMLARI, CAMDAN DÖŞEME BLOKLARI, TUĞLALAR VB.)"
    },
    {
        "kod": "467305",
        "tanim": "BANYO KÜVETLERİ, LAVABOLAR, EVİYELER, KLOZET KAPAKLARI, TUVALET TAŞI VE REZERVUARLARI İLE SERAMİKTEN KARO VE FAYANS VB. SIHHİ ÜRÜNLERİN TOPTAN TİCARETİ (SERAMİK, CAM, MERMER, PLASTİK, MERMERİT, DEMİR, ÇELİK, BAKIR VEYA ALÜMİNYUM VB.)"
    },
    {
        "kod": "467306",
        "tanim": "METALDEN PREFABRİK YAPILARIN, KÖPRÜLERİN, KÖPRÜ PARÇALARININ, KULELERİN, KAFES DİREKLERİN, KONSTRÜKSİYON ELEMANLARININ, DİĞER YAPILARIN VE YAPI ELEMANLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "467307",
        "tanim": "ÇİMENTO, ALÇI, HARÇ, KİREÇ, MOZAİK VB. İNŞAAT MALZEMELERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "467308",
        "tanim": "TUĞLA, KİREMİT, BRİKET, KALDIRIM TAŞI VB. İNŞAAT MALZEMELERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "467309",
        "tanim": "TAŞ, KUM, ÇAKIL, MICIR, KİL, KAOLİN VB. İNŞAAT MALZEMELERİ TOPTAN TİCARETİ"
    },
    {
        "kod": "467310",
        "tanim": "İŞLENMİŞ MERMER, TRAVERTEN, KAYMAKTAŞI (SU MERMERİ) VE BUNLARDAN YAPILMIŞ ÜRÜNLERİN TOPTAN TİCARETİ (LEVHA HALİNDE OLANLAR İLE LAVABO VB. SIHHİ ÜRÜNLER DAHİL)"
    },
    {
        "kod": "467311",
        "tanim": "MERMER, GRANİT, KAYAĞAN TAŞI, KUM TAŞI VB. TOPTAN TİCARETİ (İŞLENMEMİŞ VEYA BLOK HALDE OLANLAR)"
    },
    {
        "kod": "467312",
        "tanim": "İŞLENMEMİŞ AĞAÇ (TOMRUK-HAM HALDEKİ) TOPTAN TİCARETİ (ORMAN AĞAÇLARI, ENDÜSTRİYEL ODUNLAR VB.)"
    },
    {
        "kod": "467313",
        "tanim": "METALDEN KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "467314",
        "tanim": "AHŞAP KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "467315",
        "tanim": "PLASTİK KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİ, PANJURLAR, JALUZİLER, STORLAR VE BENZERİ EŞYALARIN TOPTAN TİCARETİ"
    },
    {
        "kod": "467316",
        "tanim": "BETONDAN, ÇİMENTODAN VE SUNİ TAŞTAN PREFABRİK YAPILARIN, YAPI ELEMANLARININ VE DİĞER ÜRÜNLERİN TOPTAN TİCARETİ"
    },
    {
        "kod": "467317",
        "tanim": "PLASTİKTEN PREFABRİK YAPILAR VE YAPI ELEMANLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "467318",
        "tanim": "AHŞAPTAN PREFABRİK YAPILARIN VE YAPI ELEMANLARININ TOPTAN TİCARETİ"
    },
    {
        "kod": "467319",
        "tanim": "ALÇI VE ALÇI ESASLI BİLEŞENLERDEN İNŞAAT AMAÇLI ÜRÜNLERİN TOPTAN TİCARETİ (KARTONPİYER, PANEL, LEVHA VB.)"
    },
    {
        "kod": "467320",
        "tanim": "PLASTİKTEN İNŞAAT AMAÇLI TABAKALAR, LEVHALAR, FİLMLER, FOLYOLAR, ŞERİTLER VE BORULAR İLE ASFALT VB. MALZEMEDEN ÇATI KAPLAMA ÜRÜNLERİNİN TOPTAN TİCARETİ (İNŞAAT, SERA VB. İÇİN NAYLON ÖRTÜ, SHİNGLE, MANTOLAMA AMAÇLI STRAFOR, VB. DAHİL)"
    },
    {
        "kod": "467321",
        "tanim": "DUVAR KAĞIDI, TEKSTİL DUVAR KAPLAMALARI, PLASTİKTEN ZEMİN, DUVAR VEYA TAVAN KAPLAMALARININ TOPTAN TİCARETİ (PASPAS, KAUÇUK PASPAS, YER MUŞAMBASI, MARLEY VB. YER KAPLAMALARI DAHİL)"
    },
    {
        "kod": "467322",
        "tanim": "İNŞAATLARDA İZOLASYON AMAÇLI KULLANILAN MALZEMELERİN TOPTAN TİCARETİ (RULOLAR HALİNDE CAM YÜNÜ, TAŞ YÜNÜ, BİTÜM ESASLI MALZEMELER, VB.)"
    },
    {
        "kod": "467323",
        "tanim": "MASİF, LAMİNE VE LAMİNANT PARKE TOPTAN TİCARETİ"
    },
    {
        "kod": "467390",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER İNŞAAT MALZEMESİ TOPTAN TİCARETİ (MERDİVEN, KORKULUK, PLASTİK DEPOLAR, SERAMİK BORULAR VB. DAHİL)"
    },
    {
        "kod": "467401",
        "tanim": "HIRDAVAT (NALBURİYE) MALZEMESİ VE EL ALETLERİ TOPTAN TİCARETİ (ÇİVİ, RAPTİYE, VİDA, ADİ METALDEN KİLİT, MENTEŞE, BAĞLANTI PARÇASI, ÇEKİÇ, TESTERE, PENSE, TORNAVİDA, TAKIM TEZGAHI UÇLARI, ÇENGEL, HALKA, PERÇİN, VB.)"
    },
    {
        "kod": "467403",
        "tanim": "SIHHİ TESİSAT VE ISITMA TESİSATI MALZEMESİ TOPTAN TİCARETİ (LAVABO MUSLUĞU, VANA, VALF, TIKAÇ, T-PARÇALARI, BAĞLANTILAR, VB.) (KOMBİLER VE RADYATÖRLER HARİÇ)"
    },
    {
        "kod": "467404",
        "tanim": "DEMİRDEN VEYA ÇELİKTEN MERKEZİ ISITMA RADYATÖRLERİ, MERKEZİ ISITMA KAZANLARI (KOMBİLER DAHİL) İLE BUNLARIN PARÇALARININ TOPTAN TİCARETİ (BUHAR JENERATÖRLERİ VE KIZGIN SU ÜRETEN KAZANLAR HARİÇ)"
    },
    {
        "kod": "467405",
        "tanim": "DEMİR VEYA ÇELİKTEN DİKENLİ TEL, BAKIR VEYA ALÜMİNYUMDAN ÖRGÜLÜ TEL, KABLO, ÖRME ŞERİT VE BENZERLERİ (ELEKTRİK YALITIMI OLANLAR HARİÇ), DEMİR, ÇELİK VEYA BAKIR TELLERDEN MENSUCAT, IZGARA, AĞ, KAFESLİK VE ÇİT TOPTAN TİCARETİ"
    },
    {
        "kod": "467406",
        "tanim": "METAL REZERVUAR, TANK, FIÇI VE BENZERİ KONTEYNER TOPTAN TİCARETİ, KAPASİTESİ > 300 LİTRE OLANLAR (MERKEZİ ISITMA AMAÇLI OLANLAR İLE MEKANİK VEYA TERMAL EKİPMANLI OLANLAR HARİÇ)"
    },
    {
        "kod": "467407",
        "tanim": "TARIM VE ORMANCILIK ALET VE MALZEMELERİ TOPTAN TİCARETİ (BALTA, KAZMA, ORAK, TIRPAN, VB. DAHİL, TARIMSAL AMAÇLI MAKİNE VE EKİPMANLAR HARİÇ)"
    },
    {
        "kod": "467501",
        "tanim": "ENDÜSTRİYEL KİMYASALLARIN TOPTAN TİCARETİ (ANİLİN, MATBAA MÜREKKEBİ, KİMYASAL YAPIŞTIRICI, HAVAİ FİŞEK, BOYAMA MADDELERİ, SENTETİK REÇİNE, METİL ALKOL, PARAFİN, ESANS VE TATLANDIRICI, SODA, SANAYİ TUZU, PARAFİN, NİTRİK ASİT, AMONYAK, SANAYİ GAZLARI VB.)"
    },
    {
        "kod": "467502",
        "tanim": "SUNİ GÜBRELERİN TOPTAN TİCARETİ (GÜBRE MİNERALLERİ, GÜBRE VE AZOT BİLEŞİKLERİ VE TURBA İLE AMONYUM SÜLFAT, AMONYUM NİTRAT, SODYUM NİTRAT, POTASYUM NİTRAT VB. DAHİL, NİTRİK ASİT, SÜLFONİTRİK ASİT VE AMONYAK HARİÇ)"
    },
    {
        "kod": "467503",
        "tanim": "HAYVANSAL VEYA BİTKİSEL GÜBRELERİN TOPTAN TİCARETİ (KAPALI ALANDA YAPILAN TİCARET)"
    },
    {
        "kod": "467504",
        "tanim": "ZİRAİ KİMYASAL ÜRÜNLERİN TOPTAN TİCARETİ (HAŞERE İLAÇLARI, YABANCI OT İLAÇLARI, DEZENFEKTANLAR, MANTAR İLAÇLARI, ÇİMLENMEYİ ÖNLEYİCİ ÜRÜNLER, BİTKİ GELİŞİMİNİ DÜZENLEYİCİLER VE DİĞER ZİRAİ KİMYASAL ÜRÜNLER)"
    },
    {
        "kod": "467505",
        "tanim": "HAYVANSAL VEYA BİTKİSEL GÜBRELERİN TOPTAN TİCARETİ (AÇIK ALANDA YAPILAN TİCARET)"
    },
    {
        "kod": "467601",
        "tanim": "TEKSTİL ELYAFI TOPTAN TİCARETİ (BÜKÜLMEMİŞ HAM İPEK, YÜN, HAYVAN KILI, KARDELENMİŞ VEYA TARANMIŞ PAMUK, VB.)"
    },
    {
        "kod": "467602",
        "tanim": "DÖKME HALDE KAĞIT VE MUKAVVA TOPTAN TİCARETİ (DÖKME GAZETE KAĞIDI, SİGARA KAĞIDI, MUKAVVA, KARBON KAĞIDI, TUVALET KAĞIDI, PEÇETE, VB.)"
    },
    {
        "kod": "467603",
        "tanim": "İŞLENMEMİŞ İNCİ, DEĞERLİ VE YARI DEĞERLİ TAŞLARIN TOPTAN TİCARETİ (SANAYİ TİPİ ELMASLAR HARİÇ)"
    },
    {
        "kod": "467604",
        "tanim": "BİRİNCİL FORMDAKİ PLASTİK VE KAUÇUK TOPTAN TİCARETİ (ETİLEN, STİREN, VİNİL KLORÜR, AKRİLİK, VB. POLİMERLER İLE BİRİNCİL FORMDA SENTETİK VE REJENERE KAUÇUKLAR)"
    },
    {
        "kod": "467605",
        "tanim": "SANAYİDE KULLANIM AMAÇLI PLASTİK POŞET, ÇANTA, TORBA, ÇUVAL, VB. AMBALAJ MALZEMELERİNİN TOPTAN TİCARETİ"
    },
    {
        "kod": "467690",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ARA ÜRÜN (TARIM HARİÇ) TOPTAN TİCARETİ (KORİNDON, LASTİK KORD BEZİ, TEKNİK KULLANIM AMAÇLI TEKSTİL ÜRÜNLERİ (HORTUM, KONVEYÖR BANDI, ELEK BEZİ), PLASTİK VEYA KAUÇUK LEVHA VE BORU, SANAYİ ELMASI, GIDA DIŞI BUZ, VB.)"
    },
    {
        "kod": "467701",
        "tanim": "ATIK VE HURDA TOPTAN TİCARETİ (METAL OLANLAR) (KAĞIT, CAM, PLASTİK VB. İKİNCİL HAMMADDELER HARİÇ)"
    },
    {
        "kod": "467702",
        "tanim": "ATIK VE HURDA TOPTAN TİCARETİ (KAĞIT, CAM, PLASTİK VB. OLANLAR) (METAL OLANLAR HARİÇ)"
    },
    {
        "kod": "469001",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDAKİ TOPTAN TİCARET (ÇEŞİTLİ MALLARIN TOPTAN SATIŞI) (BİR BAŞKA ÜLKEYLE YAPILAN TOPTAN TİCARET HARİÇ)"
    },
    {
        "kod": "469004",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDAKİ BİR BAŞKA ÜLKEYLE YAPILAN TOPTAN TİCARET (ÇEŞİTLİ MALLARIN TOPTAN TİCARETİ)"
    },
    {
        "kod": "471101",
        "tanim": "BAKKAL VE MARKETLERDE YAPILAN PERAKENDE TİCARET (BELİRLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA GIDA, İÇECEK VEYA TÜTÜN AĞIRLIKLI PERAKENDE TİCARET)"
    },
    {
        "kod": "471102",
        "tanim": "SÜPERMARKET VE HİPERMARKETLERDE YAPILAN PERAKENDE TİCARET (BELİRLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA GIDA, İÇECEK VEYA TÜTÜN AĞIRLIKLI PERAKENDE TİCARET)"
    },
    {
        "kod": "471103",
        "tanim": "BYS. BELLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA GIDA, İÇECEK VEYA TÜTÜN AĞIRLIKLI PERAKENDE TİCARET (TANZİM SATIŞ VE GIDA TÜKETİM KOOPERATİFLERİ DAHİL)"
    },
    {
        "kod": "471901",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMEMİŞ MAĞAZALARDA YAPILAN DİĞER PERAKENDE TİCARET (GİYİM EŞYASI, MOBİLYA, BİLGİSAYAR, HIRDAVAT, KOZMETİK, MÜCEVHER, OYUNCAK VB. REYONLARI OLAN MAĞAZALAR (GIDA, İÇECEK VE TÜTÜN AĞIRLIKLI OLMAYANLAR))"
    },
    {
        "kod": "472101",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TAZE SEBZE VE MEYVE PERAKENDE TİCARETİ (MANAV ÜRÜNLERİ İLE KÜLTÜR MANTARI DAHİL)"
    },
    {
        "kod": "472102",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İŞLENMİŞ VE KORUNMUŞ MEYVE VE SEBZELERİN PERAKENDE TİCARETİ (TURŞULAR İLE DONDURULMUŞ, SALAMURA EDİLMİŞ, KONSERVE VE KURUTULMUŞ SEBZE VE MEYVELER VB. DAHİL, BAKLAGİL, ZEYTİN VE KURUYEMİŞ HARİÇ)"
    },
    {
        "kod": "472103",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ZEYTİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "472104",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KURU BAKLİYAT ÜRÜNLERİ PERAKENDE TİCARETİ (FASULYE, MERCİMEK, NOHUT, VB.)"
    },
    {
        "kod": "472105",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KURUYEMİŞ PERAKENDE TİCARETİ"
    },
    {
        "kod": "472201",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ET PERAKENDE TİCARETİ (SAKATATLAR, AV VE KÜMES HAYVANI ETLERİ İLE KASAPLAR DAHİL)"
    },
    {
        "kod": "472202",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ET ÜRÜNLERİ PERAKENDE TİCARETİ (SOSİS, SALAM, SUCUK, PASTIRMA VB.)"
    },
    {
        "kod": "472301",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BALIK, KABUKLU HAYVANLAR VE YUMUŞAKÇALARIN PERAKENDE TİCARETİ (CANLI, TAZE, SOĞUTULMUŞ VE DONDURULMUŞ OLANLAR İLE BALIK FİLETOSU GİBİ BUNLARDAN YAPILAN ÜRÜNLER DAHİL)"
    },
    {
        "kod": "472401",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EKMEK, PASTA VE UNLU MAMULLERİN PERAKENDE TİCARETİ (EKMEK, BİSKÜVİ, PASTA, ÇÖREK, DONDURMA KÜLAHI VB.)"
    },
    {
        "kod": "472402",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ÇİKOLATA VE ŞEKERLEME PERAKENDE TİCARETİ (BONBON ŞEKERİ, AKİDE ŞEKERİ, LOKUM, HELVA VB. DAHİL, DONDURMA HARİÇ)"
    },
    {
        "kod": "472403",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DONDURMA, AROMALI YENİLEBİLİR BUZLAR VB. PERAKENDE TİCARETİ (PASTANELERDE VERİLEN HİZMETLER HARİÇ)"
    },
    {
        "kod": "472501",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ALKOLLÜ VE ALKOLSÜZ İÇECEKLERİN PERAKENDE TİCARETİ (RAKI, BİRA GİBİ ALKOLLÜ İÇKİLER İLE MEYVE SUYU, ŞIRA, ŞALGAM SUYU, GAZLI İÇECEKLER VB. DAHİL, İÇME SUYU HARİÇ)"
    },
    {
        "kod": "472503",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İÇME SUYU PERAKENDE TİCARETİ (ŞİŞELENDİRİLMİŞ VEYA DAMACANAYA KONULMUŞ OLANLAR DAHİL, ŞEBEKE SUYU HARİÇ)"
    },
    {
        "kod": "472601",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TÜTÜN VE TÜTÜN ÜRÜNLERİ PERAKENDE TİCARETİ (NARGİLE TÜTÜNÜ, PİPO TÜTÜNÜ, SİGARA, PURO, VB.)"
    },
    {
        "kod": "472602",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PİPO, NARGİLE, SİGARA AĞIZLIĞI, VB. PERAKENDE TİCARETİ"
    },
    {
        "kod": "472901",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SÜT VE SÜT ÜRÜNLERİ PERAKENDE TİCARETİ"
    },
    {
        "kod": "472902",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TOZ, KESME VE KRİSTAL ŞEKER PERAKENDE TİCARETİ"
    },
    {
        "kod": "472903",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ÇAY, KAHVE, KAKAO VE BAHARAT PERAKENDE TİCARETİ (BİTKİ ÇAYLARI DAHİL)"
    },
    {
        "kod": "472904",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YENİLEBİLİR KATI VE SIVI YAĞLARIN PERAKENDE TİCARETİ (YEMEKLİK YAĞ DAHİL)"
    },
    {
        "kod": "472906",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HUBUBAT, UN VE ZAHİRE ÜRÜNLERİ PERAKENDE TİCARETİ (BULGUR, PİRİNÇ, MISIR, VB.)"
    },
    {
        "kod": "472911",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YUMURTA PERAKENDE TİCARETİ"
    },
    {
        "kod": "472912",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HOMOJENİZE GIDA MÜSTAHZARLARI VE DİYETETİK ÜRÜNLERİN PERAKENDE TİCARETİ (GLÜTEN İÇERMEYEN GIDA MADDELERİ, SODYUM İÇERMEYEN TUZLAR VB. İLE BESİN YÖNÜNDEN ZENGİNLEŞTİRİLMİŞ SPORCU GIDALARI VB.)"
    },
    {
        "kod": "472990",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER GIDA ÜRÜNLERİNİN PERAKENDE TİCARETİ (HAZIR YEMEK, GIDA TUZU, SOS, MAYA, ÇORBA, PEKMEZ, REÇEL, FINDIK EZMESİ, MAKARNA, BAL, EV HAYVANI YEMLERİ VB.)"
    },
    {
        "kod": "473001",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MOTORLU KARA TAŞITI VE MOTOSİKLET YAKITININ (BENZİN, MAZOT, DİZEL, BİODİZEL, LPG, CNG VB.) PERAKENDE TİCARETİ"
    },
    {
        "kod": "473002",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MOTORLU KARA TAŞITLARI İÇİN YAĞLAMA VE SOĞUTMA ÜRÜNLERİNİN PERAKENDE TİCARETİ (MADENİ YAĞ, ANTİFRİZ VB.)"
    },
    {
        "kod": "474101",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BİLGİSAYARLARIN, ÇEVRE DONANIMLARININ VE YAZILIMLARIN PERAKENDE TİCARETİ (VİDEO OYUN KONSOLLARI DAHİL)"
    },
    {
        "kod": "474201",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TELEKOMÜNİKASYON TEÇHİZATININ PERAKENDE TİCARETİ (TELEFON, CEP TELEFONU, FAKS VB.)"
    },
    {
        "kod": "474301",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SES VE GÖRÜNTÜ CİHAZLARININ VE BUNLARIN PARÇALARININ PERAKENDE TİCARETİ (RADYO, TELEVİZYON, MÜZİK SETİ, TEYP, DVD OYNATICI, MP3 ÇALAR, VB.)"
    },
    {
        "kod": "475102",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KUMAŞ PERAKENDE TİCARETİ (MANİFATURA ÜRÜNLERİ DAHİL)"
    },
    {
        "kod": "475103",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TUHAFİYE ÜRÜNLERİ PERAKENDE TİCARETİ (İĞNE, DİKİŞ İPLİĞİ, ORLON, DÜĞME, FERMUAR, ÇITÇIT, FİSTO, DANTEL, GİPÜR VB.)"
    },
    {
        "kod": "475104",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GOBLEN VEYA NAKIŞ YAPIMI İÇİN TEMEL MATERYALLERİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "475105",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EVDE KULLANILAN TEKSTİL TAKIMLARI VE ÇEŞİTLİ TEKSTİL MALZEMESİNDEN EV EŞYALARI PERAKENDE TİCARETİ (ÇARŞAF, YATAK TAKIMI, YASTIK KILIFI, MASA ÖRTÜSÜ, HAVLU, BATTANİYE, YORGAN, DİĞER MEFRUŞATLAR VB.)"
    },
    {
        "kod": "475190",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DİĞER TEKSTİL ÜRÜNLERİ PERAKENDE TİCARETİ (TUHAFİYE ÜRÜNLERİ VE DİKİŞ İPLİĞİ HARİÇ DİĞER İPLİKLER, GAZLI DOKUMALAR, GAZ LAMBASI FİTİLİ, ARABA ÖRTÜLERİ VB.)"
    },
    {
        "kod": "475201",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ÇİMENTO, ALÇI, HARÇ, KİREÇ, TUĞLA, KİREMİT, BRİKET, TAŞ, KUM, ÇAKIL VB. İNŞAAT MALZEMELERİ PERAKENDE TİCARETİ"
    },
    {
        "kod": "475202",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HIRDAVAT (NALBURİYE) MALZEMESİ VE EL ALETLERİ PERAKENDE TİCARETİ (ÇİVİ, VİDA, KİLİT, MENTEŞE, ÇEKİÇ, TESTERE, PENSE, TORNAVİDA, TAKIM TEZGAHI UÇLARI, PERÇİN, VB.) (TARIM VE BAHÇECİLİK EL ALETLERİ DAHİL)"
    },
    {
        "kod": "475203",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BOYA, VERNİK VE LAK PERAKENDE TİCARETİ"
    },
    {
        "kod": "475204",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DÜZ CAM PERAKENDE TİCARETİ (PENCERE CAMI, CAM AYNA, EMNİYET CAMI, TEMPERLİ DÜZ CAM, ÇOK KATLI YALITIM CAMLARI, CAMDAN DÖŞEME BLOKLARI, CAM TUĞLALAR VB.)"
    },
    {
        "kod": "475205",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA METALDEN KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİNİN PERAKENDE TİCARETİ (ÇELİK KAPI DAHİL)"
    },
    {
        "kod": "475206",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SIHHİ TESİSAT VE ISITMA TESİSATI MALZEMESİ PERAKENDE TİCARETİ (LAVABO MUSLUĞU, VANA, VALF, TIKAÇ, T-PARÇALARI, BAĞLANTILAR, VB. DAHİL) (KOMBİLER VE RADYATÖRLER HARİÇ)"
    },
    {
        "kod": "475209",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PLASTİK KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİ, PANJURLAR, JALUZİLER, STORLAR VE BENZERİ EŞYALARIN PERAKENDE TİCARETİ (PVC OLANLAR DAHİL)"
    },
    {
        "kod": "475210",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AĞACIN İLK İŞLENMESİNDEN ELDE EDİLEN ÜRÜNLERİN PERAKENDE TİCARETİ (KERESTE, AĞAÇ TALAŞI VE YONGASI, KONTRPLAK, YONGA VE LİFLİ LEVHALAR (MDF, SUNTA VB.), PARKE, AHŞAP VARİL, FIÇI VE DİĞER MUHAFAZALAR, VB.)"
    },
    {
        "kod": "475211",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BANYO KÜVETİ, LAVABO, KLOZET KAPAĞI, TUVALET TAŞI VE REZERVUARI İLE SERAMİKTEN KARO VE FAYANS VB. SIHHİ ÜRÜNLERİN PERAKENDE TİCARETİ (SERAMİK, CAM, MERMERİT, PLASTİK, DEMİR, ÇELİK, BAKIR VB. DAHİL, MERMER HARİÇ)"
    },
    {
        "kod": "475213",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DEMİR/ÇELİKTEN BAR VE ÇUBUKLARIN, PROFİLLERİN, TÜP VE BORULARIN PERAKENDE TİCARETİ"
    },
    {
        "kod": "475215",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DEMİRDEN VEYA ÇELİKTEN MERKEZİ ISITMA RADYATÖRLERİ, MERKEZİ ISITMA KAZANLARI (KOMBİLER DAHİL) İLE BUNLARIN PARÇALARININ PERAKENDE TİCARETİ (BUHAR JENERATÖRLERİ VE KIZGIN SU ÜRETEN KAZANLAR HARİÇ)"
    },
    {
        "kod": "475216",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ÇİM BİÇME VE BAHÇE EKİPMANLARI PERAKENDE TİCARETİ (KAR KÜREYİCİLER DAHİL) (TARIM VE BAHÇECİLİKTE KULLANILAN EL ALETLERİ HARİÇ)"
    },
    {
        "kod": "475217",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AHŞAP KAPI, PENCERE VE BUNLARIN KASALARI İLE KAPI EŞİKLERİNİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "475218",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PREFABRİK YAPILAR VE YAPI ELEMANLARININ PERAKENDE TİCARETİ (METALDEN, BETONDAN, PLASTİKTEN, AHŞAPTAN VB.)"
    },
    {
        "kod": "475219",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İŞLENMİŞ MERMER, TRAVERTEN, KAYMAKTAŞI (SU MERMERİ) VE BUNLARDAN YAPILMIŞ ÜRÜNLERİN PERAKENDE TİCARETİ (LEVHA HALİNDE OLANLAR İLE MERMER LAVABO VB. SIHHİ ÜRÜNLER DAHİL)"
    },
    {
        "kod": "475220",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ALÇI VE ALÇI ESASLI BİLEŞENLERDEN İNŞAAT AMAÇLI ÜRÜNLERİN PERAKENDE TİCARETİ (KARTONPİYER, PANEL, LEVHA VB.)"
    },
    {
        "kod": "475221",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PLASTİKTEN İNŞAAT AMAÇLI LEVHALAR, FOLYOLAR, ŞERİTLER VE BORULAR İLE ASFALT VB. MALZEMEDEN ÇATI KAPLAMA ÜRÜNLERİNİN PERAKENDE TİCARETİ (İNŞAAT İÇİN NAYLON ÖRTÜ, SHINGLE, MANTOLAMA AMAÇLI STRAFOR VB. DAHİL)"
    },
    {
        "kod": "475222",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MASİF, LAMİNE VE LAMİNANT PARKE PERAKENDE TİCARETİ"
    },
    {
        "kod": "475290",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAŞKA YERDE SINIFLANDIRILMAMIŞ İNŞAAT MALZEMESİ PERAKENDE TİCARETİ (EV TİPİ LEHİM VE KAYNAK MAKİNESİ, MERDİVEN, KORKULUK, METAL VEYA PLASTİK DEPO, SERAMİK BORU VB. DAHİL)"
    },
    {
        "kod": "475301",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PERDE, İÇ STOR, PERDE VEYA YATAK SAÇAĞI VE FARBELASI PERAKENDE TİCARETİ"
    },
    {
        "kod": "475302",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HALI, KİLİM VE DİĞER TEKSTİL YER DÖŞEMELERİ PERAKENDE TİCARETİ (KEÇEDEN OLANLAR DAHİL)"
    },
    {
        "kod": "475303",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DUVAR KAĞIDI, TEKSTİL DUVAR KAPLAMALARI, KAUÇUK YER DÖŞEMELERİ VE PASPASLAR İLE PLASTİK ZEMİN, DUVAR VEYA TAVAN KAPLAMALARI PERAKENDE TİCARETİ (LİNOLYUM GİBİ ELASTİKİ ZEMİN KAPLAMALARI, MARLEY, VB. DAHİL)"
    },
    {
        "kod": "475401",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BEYAZ EŞYA VE ELEKTRİKLİ KÜÇÜK EV ALETİ PERAKENDE TİCARETİ (BUZDOLABI, ÇAMAŞIR MAKİNESİ, SU ISITICI, VANTİLATÖR, DAVLUMBAZ, TOST MAKİNESİ, MUTFAK ROBOTU, VB.) (RADYO, TELEVİZYON VE FOTOĞRAFÇILIK ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "475403",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EVDE KULLANIM AMAÇLI ELEKTRİK TESİSAT MALZEMESİ PERAKENDE TİCARETİ (TRANSFORMATÖR, SİGORTA, RÖLE, PİL VE BATARYA, ELEKTRİK AKÜMÜLATÖRÜ, KOAKSİYEL KABLO, ELEKTRİK İLETKENLERİ, ANAHTAR, DUY, BYS. FİŞ, PRİZ, VB.)"
    },
    {
        "kod": "475490",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BYS. ELEKTRİKLİ EV ALETLERİ PERAKENDE TİCARETİ (EV TİPİ HIRSIZ VE YANGIN ALARMI, TIRAŞ, DİKİŞ, DOKUMA VE ÖRGÜ MAKİNESİ, FIRIN, SOBA, RADYATÖR, VB.) (RADYO, TV VE FOTOĞRAFÇILIK ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "475901",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ELEKTRİKLİ OLMAYAN EV ALETLERİ İLE ÇATAL BIÇAK TAKIMI, TABAK-ÇANAK, CAM EŞYA, PORSELEN VE ÇÖMLEK ÜRÜNLERİ GİBİ ZÜCCACİYE ÜRÜNLERİNİN PERAKENDE TİCARETİ (METAL TABAK-ÇANAK HARİÇ)"
    },
    {
        "kod": "475902",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AYDINLATMA TEÇHİZATI PERAKENDE TİCARETİ (LAMBALAR, AYDINLATMA ARMATÜRLERİ, AVİZE, ABAJUR, IŞIKLI TABELA, PORTATİF ELEKTRİK LAMBALARI VB.) (ELEKTRİK MALZEMELERİ HARİÇ)"
    },
    {
        "kod": "475903",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EV MOBİLYALARININ VE AKSESUARLARININ PERAKENDE TİCARETİ (BAZA, SOMYA, KARYOLA DAHİL, HASIR VE SEPETÇİ SÖĞÜDÜ GİBİ MALZEMELERDEN OLANLAR HARİÇ)"
    },
    {
        "kod": "475904",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AHŞAP, MANTAR VE HASIR EŞYALARIN PERAKENDE TİCARETİ (AHŞAP SOFRA VE MUTFAK EŞYALARI, AHŞAP ÇERÇEVELER, SEPETÇİ ÜRÜNLERİ, MÜCEVHER VB. İÇİN AHŞAP KUTULAR, AHŞAP BİBLOLAR, MANTAR ÜRÜNLER, HASIR VB.)"
    },
    {
        "kod": "475905",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MÜZİK ALETLERİ VE MÜZİK PARTİSYONU (NOTA KAĞIDI) PERAKENDE TİCARETİ"
    },
    {
        "kod": "475906",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA METAL SOFRA VE MUTFAK EŞYASI PERAKENDE TİCARETİ (DÜDÜKLÜ TENCERE, TENCERE, CEZVE, ÇANAK VB. DAHİL, BAKIR OLANLAR İLE ÇATAL-BIÇAK TAKIMI HARİÇ)"
    },
    {
        "kod": "475907",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PLASTİKTEN SOFRA, MUTFAK, TUVALET VE DİĞER EV EŞYALARININ PERAKENDE TİCARETİ (PLASTİKTEN TABAK, BARDAK, TORBA, KUTU, ŞİŞE, MATARA, MAKARA, BOBİN, MOBİLYA PARÇALARI, VB. DAHİL)"
    },
    {
        "kod": "475908",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BÜRO MOBİLYALARI VE AKSESUARLARININ PERAKENDE TİCARETİ"
    },
    {
        "kod": "475909",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAKIR EŞYA, BAKIR SOFRA VE MUTFAK EŞYASI PERAKENDE TİCARETİ"
    },
    {
        "kod": "475910",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAHÇE MOBİLYALARININ PERAKENDE TİCARETİ"
    },
    {
        "kod": "475911",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YATAK PERAKENDE TİCARETİ"
    },
    {
        "kod": "475912",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KAĞIT VEYA MUKAVVADAN TUVALET KAĞIDI, KAĞIT MENDİL, KAĞIT HAVLULAR, KAĞIT MASA ÖRTÜSÜ VE PEÇETELER İLE KAĞIT VEYA MUKAVVADAN TEPSİ, TABAK, KASE, BARDAK VE BENZERLERİNİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "475913",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ELEKTRİKSİZ HAVA ISITICILARI VEYA SICAK HAVA DAĞITICILARININ PERAKENDE TİCARETİ (SOBA, KUZİNE VB. İLE PARÇALARI)"
    },
    {
        "kod": "475914",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ELEKTRİKSİZ FIRIN VE OCAKLAR İLE ŞOFBEN VE TERMOSİFON GİBİ SU ISITICILARI VB.LERİNİN PERAKENDE TİCARETİ (GAZ, SIVI VEYA KATI YAKITLI)"
    },
    {
        "kod": "475990",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BYS. DİĞER EV EŞYALARININ PERAKENDE TİCARETİ (EV TİPİ TARTI VE BASKÜLLER, GÜNEŞ VE BAHÇE ŞEMSİYELERİ, EV TİPİ ÇAKMAKLAR İLE EV TEMİZLİĞİ İÇİN SÜPÜRGE VE FIRÇALAR, EV TİPİ METAL KUTU, KASA VE ÇERÇEVELER, VB.)"
    },
    {
        "kod": "476101",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KİTAP PERAKENDE TİCARETİ (KİTAP, ANSİKLOPEDİ, REHBER VB. İLE CD VE DVD ORTAMINDAKİ KİTAPLAR VB.)"
    },
    {
        "kod": "476201",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KIRTASİYE ÜRÜNLERİNİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "476203",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GAZETE VE DERGİLERİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "476301",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MÜZİK VE VİDEO KAYITLARININ PERAKENDE TİCARETİ (DOLU SES, MÜZİK VE VİDEO KASETLERİ, CD/DVD VB. ÜRÜNLER İLE BOŞ OLANLAR DAHİL)"
    },
    {
        "kod": "476401",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BYS. AVCILIK VE BALIKÇILIK TEÇHİZATI İLE MALZEMELERİNİN PERAKENDE TİCARETİ (SPORTİF/AVCILIK AMAÇLI TÜFEKLER VE MÜHİMMATLARI İLE OLTA ÇUBUĞU, İĞNESİ VE MANTARLARI İLE YAPMA BALIKLAR, YAPMA KUŞLAR, VB.)"
    },
    {
        "kod": "476402",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA MOTORLU TAŞITLAR DIŞINDAKİ EĞLENCE VE SPOR AMAÇLI TAŞITLARIN PERAKENDE TİCARETİ (TEKNE, YELKENLİ, KANO, KAYIK, BOT, BALON, ZEPLİN, VB. İLE DENİZ TAŞITLARI İÇİN DIŞTAN TAKMALI MOTORLAR DAHİL)"
    },
    {
        "kod": "476403",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KAMP MALZEMELERİ PERAKENDE TİCARETİ (ÇADIR VE UYKU TULUMLARI DAHİL)"
    },
    {
        "kod": "476405",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BİSİKLET PERAKENDE TİCARETİ"
    },
    {
        "kod": "476406",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SPOR AYAKKABISI PERAKENDE TİCARETİ (KAYAK BOTLARI DAHİL)"
    },
    {
        "kod": "476407",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA JİMNASTİK VE ATLETİZM EŞYA VE EKİPMANLARI İLE FORM TUTMA MERKEZLERİNE AİT EŞYA VE EKİPMANLARIN PERAKENDE TİCARETİ (HALTER, YÜRÜME BANTLARI, VB.)"
    },
    {
        "kod": "476490",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DİĞER SPOR MALZEMELERİNİN PERAKENDE TİCARETİ (PARAŞÜTLER, ROTOŞÜTLER, CANKURTARAN YELEKLERİ, CANKURTARAN SİMİTLERİ, SPOR AMAÇLI İP VE URGANLAR, BİNİCİLİK KAMÇILARI, KAYAK VE PATENLER VB.)"
    },
    {
        "kod": "476501",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA OYUN VE OYUNCAKLARIN PERAKENDE TİCARETİ (HER TÜRLÜ MATERYALDEN YAPILMIŞ BEBEK, OYUN KAĞIDI, HAVAİ FİŞEK, JETONLA ÇALIŞAN DİĞER OYUN MAKİNELERİ, SİHİRBAZLIK VEYA ŞAKA MALZEMELERİ, VB.)"
    },
    {
        "kod": "477101",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BEBEK VE ÇOCUK GİYİM EŞYASI PERAKENDE TİCARETİ (BEBEK İÇ GİYİM EŞYALARI DAHİL)"
    },
    {
        "kod": "477102",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GİYSİ AKSESUARLARI PERAKENDE TİCARETİ (ELDİVEN, KRAVAT, ŞAPKA, EŞARP, ŞAL, MENDİL, KEMER, PANTOLON ASKISI, ŞEMSİYE, BASTON, VB. (GÜNEŞ ŞEMSİYELERİ HARİÇ))"
    },
    {
        "kod": "477103",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KÜRKLÜ DERİDEN GİYİM EŞYALARININ PERAKENDE TİCARETİ (İŞLENMİŞ KÜRKLÜ DERİLER DAHİL)"
    },
    {
        "kod": "477104",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DİĞER DIŞ GİYİM PERAKENDE SATIŞI (PALTO, KABAN, ANORAK, TAKIM ELBİSE, CEKET, PANTOLON, ŞORT (TEKSTİL KUMAŞINDAN VEYA ÖRGÜ VE TIĞ İŞİ))"
    },
    {
        "kod": "477105",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İÇ GİYİM VE ÇORAP PERAKENDE TİCARETİ (GÖMLEK, KÜLOT, SLİP, GECELİK, PİJAMA, BORNOZ, ROPDÖŞAMBIR, KOMBİNEZON, İÇ ETEK, JÜPON, SABAHLIK, ATLET, FANİLA, SÜTYEN, KORSE, TİŞÖRT, KÜLOTLU ÇORAP, TAYT, VB.)"
    },
    {
        "kod": "477107",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DERİ VEYA DERİ BİLEŞİMLİ GİYİM EŞYASI PERAKENDE TİCARETİ"
    },
    {
        "kod": "477108",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SÜVETER, KAZAK, HIRKA, YELEK VE BENZERİ EŞYALARIN PERAKENDE TİCARETİ"
    },
    {
        "kod": "477109",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İŞ GİYSİSİ PERAKENDE TİCARETİ (ENDÜSTRİYEL VE MESLEKİ PANTOLONLAR, BAHÇIVAN TİPİ İŞ TULUMLARI, BİNİCİ/KÜLOT PANTOLONLARI, ŞORTLAR, TAKIMLAR, CEKETLER VE BLAZERLER, VB.)"
    },
    {
        "kod": "477110",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KULLANILMIŞ GİYSİLER VE AKSESUARLARININ PERAKENDE TİCARETİ"
    },
    {
        "kod": "477111",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SPOR GİYSİSİ PERAKENDE TİCARETİ (EŞOFMAN, MAYO, KAYAK GİYSİSİ, DAĞCILIK KIYAFETLERİ, VB)"
    },
    {
        "kod": "477112",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GELİNLİK PERAKENDE TİCARETİ"
    },
    {
        "kod": "477190",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BYS. GİYİM EŞYASI PERAKENDE TİCARETİ (PLASTİKTEN, VULKANİZE KAUÇUKTAN, KAĞITTAN, DOKUSUZ KUMAŞTAN YA DA EMDİRİLMİŞ VEYA KAPLANMIŞ TEKSTİL KUMAŞINDAN GİYSİLER)"
    },
    {
        "kod": "477201",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AYAKKABI, TERLİK VB. PERAKENDE TİCARETİ (KAVAFİYE DAHİL, SPOR AYAKKABILARI İLE TAMAMI TEKSTİLDEN OLANLAR HARİÇ)"
    },
    {
        "kod": "477202",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAVUL, EL ÇANTASI VE DİĞER SEYAHAT AKSESUARLARININ PERAKENDE TİCARETİ (DERİDEN, DERİ BİLEŞİMLERİNDEN, PLASTİK LEVHADAN, TEKSTİL MALZEMESİNDEN, VULKANİZE (EBONİT) ELYAF VEYA MUKAVVADAN)"
    },
    {
        "kod": "477205",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SARACİYE ÜRÜNLERİ VE KOŞUM TAKIMI PERAKENDE TİCARETİ (EYER, SEMER, VB.)"
    },
    {
        "kod": "477206",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA AYAKKABI PARÇALARI PERAKENDE TİCARETİ (DERİ, AYAKKABI SAYASI, TOPUK, TOPUK YASTIĞI, AYAKKABI BAĞLARI VB.)"
    },
    {
        "kod": "477290",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BAŞKA YERDE SINIFLANDIRILMAMIŞ DERİDEN VEYA DERİ BİLEŞİMLERİNDEN DİĞER ÜRÜNLERİN PERAKENDE TİCARETİ (DERİ VEYA DERİ BİLEŞİMLİ GİYİM EŞYASI HARİÇ)"
    },
    {
        "kod": "477301",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İNSAN SAĞLIĞINA YÖNELİK ECZACILIK ÜRÜNLERİNİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "477302",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HAYVAN SAĞLIĞINA YÖNELİK İLAÇ, AŞI, VB. ÜRÜNLERİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "477401",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TIBBİ VE ORTOPEDİK ÜRÜNLERİN PERAKENDE TİCARETİ (GÖZLÜK HARİÇ DİĞER MEDİKAL ÜRÜNLER DAHİL)"
    },
    {
        "kod": "477501",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KOZMETİK VE KİŞİSEL BAKIM MALZEMELERİNİN PERAKENDE TİCARETİ (DİŞ FIRÇALARI, SAÇ FIRÇALARI, ELEKTRİKSİZ TIRAŞ MAKİNELERİ, JİLET, USTURA, PARFÜMERİ ÜRÜNLERİ VE KOLONYA, DOĞAL SÜNGER, SABUN DAHİL)"
    },
    {
        "kod": "477601",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EV HAYVANLARI İLE BUNLARIN MAMA VE GIDALARININ PERAKENDE TİCARETİ (SÜS BALIKLARI, KÖPEK, KUŞ, HAMSTER, KABLUMBAĞA VB., AKVARYUM, KAFES VE KEDİ VE KÖPEKLER İÇİN TASMALAR VB. DAHİL)"
    },
    {
        "kod": "477602",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ÇİÇEK, BİTKİ VE TOHUM PERAKENDE TİCARETİ (MEYVE, SEBZE VE ÇİÇEK TOHUMLARI, KESME ÇİÇEK, DİKİM BİTKİLERİ, CANLI BİTKİLER, YUMRULAR VE KÖKLER, AŞI KALEMLERİ, MANTAR MİSELİ, AĞAÇ FİDANLARI VB.)"
    },
    {
        "kod": "477603",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GÜBRE VE ZİRAİ KİMYASAL ÜRÜNLERİN PERAKENDE TİCARETİ (TURBA, KİMYASAL GÜBRELER, HAYVANSAL VEYA BİTKİSEL GÜBRELER, HAŞERE İLAÇLARI, YABANCI OT İLAÇLARI VB.)"
    },
    {
        "kod": "477701",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ALTIN VE DİĞER DEĞERLİ METALLERDEN TAKI, EŞYA VE MÜCEVHERAT PERAKENDE TİCARETİ (KUYUMCULUK ÜRÜNLERİ PERAKENDE TİCARETİ DAHİL, GÜMÜŞTEN OLANLAR HARİÇ)"
    },
    {
        "kod": "477702",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GÜMÜŞ TAKI, EŞYA VE MÜCEVHERAT PERAKENDE TİCARETİ (GÜMÜŞÇÜ ÜRÜNLERİ PERAKENDE TİCARETİ)"
    },
    {
        "kod": "477703",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SAAT (KOL, MASA, DUVAR VB. SAATLER İLE KRONOMETRELER) PERAKENDE TİCARETİ"
    },
    {
        "kod": "477705",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DOĞAL İNCİDEN VEYA KÜLTÜR İNCİSİNDEN ÜRÜNLER İLE DEĞERLİ YA DA YARI DEĞERLİ TAŞLARDAN ÜRÜNLERİN PERAKENDE TİCARETİ (PIRLANTA, YAKUT, ZÜMRÜT, SAFİR VB.DEN YAPILAN ÜRÜNLER)"
    },
    {
        "kod": "477801",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA PUL VE JETON PERAKENDE TİCARETİ (ÖZEL GÜNLERDE ÇIKARILAN PUL VE PARALARIN PERAKENDE TİCARETİ DAHİL)"
    },
    {
        "kod": "477802",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KÖMÜR VE YAKACAK ODUN PERAKENDE TİCARETİ"
    },
    {
        "kod": "477803",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA GÖZLÜK, KONTAK LENS, GÖZLÜK CAMI VB. PERAKENDE TİCARETİ (GÖZLÜKÇÜLERİN HİZMETLERİ)"
    },
    {
        "kod": "477804",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA HEDİYELİK EŞYALARIN, ELİŞİ ÜRÜNLERİN VE İMİTASYON TAKILARIN PERAKENDE TİCARETİ (SANAT ESERLERİ HARİÇ)"
    },
    {
        "kod": "477805",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SİLAH VE MÜHİMMAT PERAKENDE TİCARETİ (SPORTİF VE AVCILIK AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "477806",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA SANAT ESERLERİNİN PERAKENDE TİCARETİ (TİCARİ SANAT GALERİLERİNİN HİZMETLERİ İLE RESSAMLARIN, GRAVÜRCÜLERİN, HEYKELTIRAŞLARIN, BESTEKARLARIN VE DİĞER SANATÇILARIN ORİJİNAL ÇALIŞMALARI) (ANTİKA EŞYALAR HARİÇ)"
    },
    {
        "kod": "477807",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA OPTİK VE HASSAS ALETLERİN PERAKENDE TİCARETİ (MİKROSKOP, DÜRBÜN VE PUSULA DAHİL, GÖZLÜK CAMI, FOTOGRAFİK ÜRÜNLER HARİÇ)"
    },
    {
        "kod": "477808",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BÜRO MAKİNE VE EKİPMANLARININ PERAKENDE TİCARETİ (HESAPLAMA MAKİNELERİ, DAKTİLOLAR, FOTOKOPİ MAKİNELERİ, TARAMA VE FAKS CİHAZLARI, ÇİZİM MASALARI VB.)"
    },
    {
        "kod": "477809",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EVLERDE KULLANILAN FUEL OİL PERAKENDE TİCARETİ (DÖKME OLANLAR İLE MÜŞTERİNİN İSTEDİĞİ YERE ULAŞTIRILARAK YAPILAN DOĞRUDAN FUEL OİL SATIŞI HARİÇ)"
    },
    {
        "kod": "477810",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA EVLERDE KULLANILAN TÜPGAZ PERAKENDE TİCARETİ"
    },
    {
        "kod": "477815",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TEMİZLİK MALZEMESİ PERAKENDE TİCARETİ (ARAP SABUNU, DETERJAN, YUMUŞATICILAR, ŞAMPUANLAR VB. DAHİL, KİŞİSEL HİJYEN İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "477816",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YÜN, TİFTİK VB. PERAKENDE TİCARETİ"
    },
    {
        "kod": "477822",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA FOTOĞRAFÇILIK MALZEMELERİ VE ALETLERİNİN PERAKENDE TİCARETİ"
    },
    {
        "kod": "477823",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YANGIN SÖNDÜRÜCÜLER VE EKİPMANLARININ PERAKENDE TİCARETİ (ARABALAR İÇİN OLANLAR VE YÜKSEK BASINÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "477826",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA YAPMA ÇİÇEK, YAPRAK VE MEYVELER İLE MUM PERAKENDE TİCARETİ"
    },
    {
        "kod": "477827",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BEBEK ARABALARI VE BUNLARIN PARÇALARININ PERAKENDE TİCARETİ"
    },
    {
        "kod": "477828",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA CANLI BÜYÜKBAŞ VE KÜÇÜKBAŞ HAYVANLARIN PERAKENDE TİCARETİ (EV HAYVANLARI HARİÇ)"
    },
    {
        "kod": "477829",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA CANLI KÜMES HAYVANLARININ PERAKENDE TİCARETİ"
    },
    {
        "kod": "477830",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA TEKSTİLDEN ÇUVAL, TORBA, VB. PERAKENDE TİCARETİ (EŞYA PAKETLEME AMACIYLA KULLANILANLAR)"
    },
    {
        "kod": "477890",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA BYS. DİĞER YENİ (KULLANILMAMIŞ) MALLARIN PERAKENDE TİCARETİ (SENTETİK SÜNGER DAHİL)"
    },
    {
        "kod": "477901",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA ANTİKA PERAKENDE TİCARETİ"
    },
    {
        "kod": "477903",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA İKİNCİ EL KİTAPLARIN PERAKENDE TİCARETİ (SAHAFLARIN FAALİYETLERİ)"
    },
    {
        "kod": "477904",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA KULLANILMIŞ MOBİLYA, ELEKTRİKLİ VE ELEKTRONİK EV EŞYASI PERAKENDE TİCARETİ"
    },
    {
        "kod": "477905",
        "tanim": "KULLANILMIŞ MALLARIN MÜZAYEDE SALONLARI VASITASIYLA PERAKENDE TİCARETİ"
    },
    {
        "kod": "477990",
        "tanim": "BELİRLİ BİR MALA TAHSİS EDİLMİŞ MAĞAZALARDA DİĞER İKİNCİ EL EŞYA PERAKENDE TİCARETİ (İKİNCİ EL MOTORLU KARA TAŞITLARI VE MOTOSİKLET PARÇALARI İLE GİYSİ HARİÇ)"
    },
    {
        "kod": "478101",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ALKOLLÜ VE ALKOLSÜZ İÇECEK PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478102",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA SEBZE VE MEYVE (TAZE VEYA İŞLENMİŞ) PERAKENDE TİCARETİ (ZEYTİN DAHİL, SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478103",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ET VE ET ÜRÜNLERİ (SUCUK, SALAM, PASTIRMA, KÜMES HAYVANI ETİ, VB.) PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478104",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA YUMURTA PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478105",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA YENİLEBİLİR KATI VE SIVI YAĞ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478106",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA SİGARA, TÜTÜN VB. PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478107",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA SÜT VE SÜT ÜRÜNLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478108",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA BALIK VE DİĞER SU ÜRÜNLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478109",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ÇAY, KAHVE, KAKAO, BAHARAT PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478110",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA FIRIN ÜRÜNLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478111",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ŞEKERLEME PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478190",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA DİĞER GIDA ÜRÜNLERİ PERAKENDE TİCARETİ (BAL, UN, TAHIL, PİRİNÇ, BAKLİYAT, VB. DAHİL, SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478201",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA İÇ GİYİM EŞYASI, DIŞ GİYİM EŞYASI, ÇORAP, GİYSİ AKSESUARI VE AYAKKABI PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478202",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA TUHAFİYE, MANİFATURA VE MEFRUŞAT ÜRÜNLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478901",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA EV VE BÜRO MOBİLYALARI (AĞAÇ, METAL, VB.) PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478902",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA CAM, AYNA PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478903",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA CANLI BÜYÜK VE KÜÇÜK BAŞ HAYVAN PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478904",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ÇİÇEK, BİTKİ VE BİTKİ TOHUMU (ÇİÇEK TOPRAĞI VE SAKSILARI DAHİL) PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478905",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA ELEKTRİKLİ ALET, CİHAZ VE ELEKTRİK MALZEMELERİ İLE EL ALETLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478906",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA EV HAYVANLARI VE YEMLERİ PERAKENDE TİCARETİ (MUHABBET KUŞU, KEDİ, KÖPEK VB.) (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478907",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA FOTOĞRAFÇILIK MALZEMELERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478908",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA İMİTASYON TAKI, SÜS EŞYASI, TURİSTİK VE HEDİYELİK EŞYA PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478909",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA KİŞİSEL BAKIM ÜRÜNLERİ VE KOZMETİK ÜRÜNLERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478910",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA MUTFAK EŞYALARI İLE BANYO VE TUVALETTE KULLANILAN EŞYALARIN PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478911",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA SPOR MALZEMELERİ, AV VE KAMP MALZEMELERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478912",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA TEMİZLİK ÜRÜNLERİ VE MALZEMELERİ PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478914",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA CANLI KÜMES HAYVANI PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478915",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA KİTAP PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478916",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA OYUN VE OYUNCAK PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478917",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA MÜZİK VE VİDEO KASET, CD VE DVD'LERİN PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478918",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA HALI, KİLİM, VB. PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "478990",
        "tanim": "TEZGAHLAR VE PAZAR YERLERİ VASITASIYLA BYS. DİĞER MALLARIN PERAKENDE TİCARETİ (SEYYAR SATICILAR HARİÇ)"
    },
    {
        "kod": "479114",
        "tanim": "RADYO, TV, POSTA YOLUYLA VEYA İNTERNET ÜZERİNDEN YAPILAN PERAKENDE TİCARET"
    },
    {
        "kod": "479910",
        "tanim": "OTOMATİK SATIŞ MAKİNELERİ İLE YAPILAN PERAKENDE TİCARET"
    },
    {
        "kod": "479911",
        "tanim": "MAĞAZA, TEZGAH, PAZAR YERİ DIŞINDA YAPILAN PERAKENDE TİCARET (EV EV DOLAŞARAK VEYA KOMİSYONCULAR TARAFINDAN PERAKENDE OLARAK YAPILANLAR) (SEYYAR SATICILAR DAHİL, MOTORLU ARAÇLARLA YAPILANLAR HARİÇ)"
    },
    {
        "kod": "479912",
        "tanim": "MAĞAZA, TEZGAH, PAZAR YERİ DIŞINDA MÜŞTERİNİN İSTEDİĞİ YERE ULAŞTIRILARAK YAPILAN DOĞRUDAN YAKIT SATIŞI (KALORİFER YAKITI, YAKACAK ODUN, VB.)"
    },
    {
        "kod": "479913",
        "tanim": "MAĞAZA, TEZGAH, PAZAR YERİ DIŞINDA MOTORLU ARAÇLARLA ÇEŞİTLİ MALLARIN PERAKENDE TİCARETİ"
    },
    {
        "kod": "491001",
        "tanim": "DEMİR YOLU İLE ŞEHİRLER ARASI YOLCU TAŞIMACILIĞI"
    },
    {
        "kod": "492001",
        "tanim": "DEMİR YOLU İLE ŞEHİRLER ARASI VE ŞEHİRİÇİ YÜK TAŞIMACILIĞI (DONMUŞ ÜRÜNLERİN, PETROL ÜRÜNLERİNİN, DÖKME SIVI VE GAZLARIN, KURU YÜKLERİN, VB. TAŞINMASI)"
    },
    {
        "kod": "493101",
        "tanim": "DEMİR YOLU, METRO, TRAMVAY, VB. İLE ŞEHİR İÇİ VE BANLİYÖ YOLCU TAŞIMACILIĞI (ŞEHİR İÇİ VE BANLİYÖ TAŞIMACILIĞININ PARÇASI OLAN FÜNİKÜLER, TELEFERİK, VB. DAHİL)"
    },
    {
        "kod": "493104",
        "tanim": "HALK OTOBÜSÜ İLE YAPILAN ŞEHİR İÇİ VE BANLİYÖ YOLCU TAŞIMACILIĞI"
    },
    {
        "kod": "493105",
        "tanim": "BELEDİYE OTOBÜSÜ İLE YAPILAN ŞEHİR İÇİ VE BANLİYÖ YOLCU TAŞIMACILIĞI (BELEDİYENİN SAĞLADIĞI HAVAALANI OTOBÜSÜ DAHİL)"
    },
    {
        "kod": "493106",
        "tanim": "MİNİBÜS VE DOLMUŞ İLE YAPILAN ŞEHİR İÇİ VE BANLİYÖ YOLCU TAŞIMACILIĞI (BELİRLENMİŞ GÜZERGAHLARDA)"
    },
    {
        "kod": "493190",
        "tanim": "KARA YOLU TAŞIMACILIĞI İLE YAPILAN DİĞER ŞEHİR İÇİ VE BANLİYÖ YOLCU TAŞIMACILIĞI (TROLEYBÜS, VB. DAHİL, HALK OTOBÜSÜ, MİNİBÜS, BELEDİYE OTOBÜSÜ İLE HAVAALANI OTOBÜSÜ HARİÇ) (BELİRLENMİŞ GÜZERGAHLARDA)"
    },
    {
        "kod": "493201",
        "tanim": "TAKSİ İLE YOLCU TAŞIMACILIĞI (DOLMUŞLAR HARİÇ)"
    },
    {
        "kod": "493202",
        "tanim": "SÜRÜCÜSÜ İLE BİRLİKTE DİĞER ÖZEL ARAÇ (OTOMOBİL, LİMUZİN, VB. DAHİL, MİNİBÜS, OTOBÜS VB. HARİÇ) KİRALAMA FAALİYETİ"
    },
    {
        "kod": "493901",
        "tanim": "ŞEHİRLER ARASI TARİFELİ KARA YOLU YOLCU TAŞIMACILIĞI (ŞEHİRLER ARASI OTOBÜS VE TRAMVAY, ŞEHİRLER ARASI PERSONEL VE OKUL SERVİSLERİ, VB. DAHİL, ŞEHİR İÇİ VE ŞEHİRLER ARASI HAVAALANI SERVİSLERİ İLE ŞEHİR İÇİ HAVAALANI OTOBÜSLERİ HARİÇ)"
    },
    {
        "kod": "493902",
        "tanim": "KARA YOLU ŞEHİR İÇİ VE ŞEHİRLER ARASI HAVAALANI SERVİSLERİ İLE YOLCU TAŞIMACILIĞI"
    },
    {
        "kod": "493903",
        "tanim": "ŞEHİR İÇİ, BANLİYÖ VE KIRSAL ALANLARDA KARA YOLU İLE PERSONEL, ÖĞRENCİ, VB. GRUP TAŞIMACILIĞI (ŞEHİR İÇİ PERSONEL VE OKUL SERVİSLERİ, VB.)"
    },
    {
        "kod": "493904",
        "tanim": "KARA YOLU (OTOBÜS, VB.) İLE ULUSLARARASI YOLCU TAŞIMACILIĞI"
    },
    {
        "kod": "493906",
        "tanim": "SÜRÜCÜSÜ İLE BİRLİKTE OTOBÜS, MİNİBÜS VB. KİRALAMA (BELİRLENMEMİŞ GÜZERGAHLAR İÇİN) İLE GEZİLER İÇİN KARA YOLU YOLCU TAŞIMACILIĞI (ŞEHİR TURU OTOBÜSLERİ, GEZİ OTOBÜSÜ, VB. DAHİL)"
    },
    {
        "kod": "493908",
        "tanim": "İNSANLAR VEYA HAYVANLAR TARAFINDAN ÇEKİLEN TAŞITLARLA VEYA YÜK HAYVANLARI İLE YOLCU TAŞIMACILIĞI (FAYTON, BİSİKLET, VB. İLE YOLCU TAŞIMACILIĞI)"
    },
    {
        "kod": "493990",
        "tanim": "KABLOLU TRENLER (FÜNİKÜLER), TELEFERİKLER VE TELESİYEJLER (ŞEHİR İÇİ, BANLİYÖ VEYA METROPOL TRANSİT SİSTEMLERİN PARÇASI OLANLAR HARİÇ) VE DİĞER ŞOFÖRLÜ TAŞITLARLA BAŞKA YERDE SINIFLANDIRILMAMIŞ KARA YOLU YOLCU TAŞIMACILIĞI"
    },
    {
        "kod": "494101",
        "tanim": "KARAYOLU İLE ŞEHİR İÇİ YÜK TAŞIMACILIĞI (GIDA, SIVI, KURU YÜK, VB.) (GAZ VE PETROL ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "494102",
        "tanim": "KARA YOLU İLE ŞEHİRLER ARASI YÜK TAŞIMACILIĞI (GIDA, SIVI, KURU YÜK, VB.) (GAZ VE PETROL ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "494103",
        "tanim": "KARA YOLU İLE ULUSLARARASI YÜK TAŞIMACILIĞI (GIDA, SIVI, KURU YÜK, VB.) (GAZ VE PETROL ÜRÜNLERİ HARİÇ)"
    },
    {
        "kod": "494105",
        "tanim": "KARA YOLU İLE CANLI HAYVAN TAŞIMACILIĞI (ÇİFTLİK HAYVANLARI, KÜMES HAYVANLARI, VAHŞİ HAYVANLAR VB.)"
    },
    {
        "kod": "494106",
        "tanim": "ŞOFÖRÜ İLE BİRLİKTE KAMYON VE DİĞER MOTORLU YÜK TAŞIMA ARAÇLARININ KİRALANMASI"
    },
    {
        "kod": "494107",
        "tanim": "KARAYOLU İLE İNSAN VEYA HAYVAN TARAFINDAN ÇEKİLEN TAŞITLARLA YÜK TAŞIMACILIĞI (TORNET, AT ARABASI VB. İLE YÜK TAŞIMACILIĞI)"
    },
    {
        "kod": "494108",
        "tanim": "KARA YOLU İLE ŞEHİR İÇİ YÜK TAŞIMACILIĞI (GAZ VE PETROL ÜRÜNLERİ, KİMYASAL ÜRÜNLER VB.)"
    },
    {
        "kod": "494109",
        "tanim": "KARA YOLU İLE ŞEHİRLER ARASI YÜK TAŞIMACILIĞI (GAZ VE PETROL ÜRÜNLERİ, KİMYASAL ÜRÜNLER VB.)"
    },
    {
        "kod": "494110",
        "tanim": "KARA YOLU İLE ULUSLARARASI YÜK TAŞIMACILIĞI (GAZ VE PETROL ÜRÜNLERİ, KİMYASAL ÜRÜNLER VB.)"
    },
    {
        "kod": "494190",
        "tanim": "KARA YOLU İLE ÇEŞİTLİ TAŞIMA TÜRÜNE UYGUN KONTEYNER VE DİĞER YÜK TAŞIMACILIĞI HİZMETLERİ (EVDEN EVE NAKLİYAT, VB. HARİÇ)"
    },
    {
        "kod": "494201",
        "tanim": "KARA YOLU TAŞIMACILIĞI İLE EV VE İŞ YERLERİNİN TAŞINMASI (EVDEN EVE NAKLİYAT, VB.)"
    },
    {
        "kod": "495001",
        "tanim": "BORU HATTI İLE HAM PETROL, RAFİNE PETROL VE PETROL ÜRÜNLERİ TAŞIMACILIĞI"
    },
    {
        "kod": "495003",
        "tanim": "BORU HATTI POMPA İSTASYONLARINI İŞLETME HİZMETLERİ"
    },
    {
        "kod": "495004",
        "tanim": "BORU HATTI İLE DOĞALGAZ TAŞIMACILIĞI"
    },
    {
        "kod": "495090",
        "tanim": "BORU HATTI İLE DİĞER MALLARIN TAŞIMACILIĞI (KÖMÜR ÇAMURU, KİMYASAL ÜRÜNLER, VB.)"
    },
    {
        "kod": "501012",
        "tanim": "DENİZ VE KIYI SULARINDA YOLCU GEMİLERİNİN VE TEKNELERİNİN MÜRETTEBATIYLA BİRLİKTE KİRALANMASI (GEZİNTİ TEKNELERİ DAHİL)"
    },
    {
        "kod": "501013",
        "tanim": "KIYI SULARINDA YOLCULARIN FERİBOTLARLA, KRUVAZİYER GEMİLERLE VE TEKNELERLE TAŞINMASI (DENİZ OTOBÜSLERİ İŞLETMECİLİĞİ DAHİL, ULUSLARARASI DENİZLER İLE GÖL VE NEHİRLERDE YAPILANLAR HARİÇ)"
    },
    {
        "kod": "501014",
        "tanim": "DENİZ VE KIYI SULARINDA YAT İŞLETMECİLİĞİ"
    },
    {
        "kod": "501015",
        "tanim": "DENİZ VE KIYI SULARINDA GEZİ VEYA TUR BOT VE TEKNELERİNİN İŞLETİLMESİ (YAT İŞLETMECİLİĞİ HARİÇ)"
    },
    {
        "kod": "501016",
        "tanim": "ULUSLARARASI DENİZLERDE YOLCULARIN GEMİLERLE TAŞINMASI"
    },
    {
        "kod": "501090",
        "tanim": "DENİZ VE KIYI SULARINDA DİĞER YOLCU TAŞIMACILIĞI (DENİZ TAKSİ VB. DAHİL)"
    },
    {
        "kod": "502017",
        "tanim": "ULUSLARARASI SULARDA HAM PETROLÜN, PETROL ÜRÜNLERİNİN VE KİMYASALLARIN TANKER GEMİLERLE TAŞINMASI (GAZLAR HARİÇ)"
    },
    {
        "kod": "502018",
        "tanim": "ULUSLARARASI SULARDA DÖKME KURU YÜK TAŞINMASI (KİMYASALLARIN TAŞINMASI HARİÇ)"
    },
    {
        "kod": "502019",
        "tanim": "ULUSLARARASI SULARDA VE KABOTAJ HATTINDA ÇEKME VE İTME HİZMETLERİ (RÖMORKAJ) (MAVNALARIN, PETROL KULELERİNİN VB.NİN TAŞINMASI) (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502020",
        "tanim": "ULUSLARARASI SULARDA FRİGORİFİK GEMİLERLE DONDURULMUŞ VEYA SOĞUTULMUŞ MALLARIN TAŞINMASI"
    },
    {
        "kod": "502021",
        "tanim": "ULUSLARARASI SULARDA ÇOKLU TAŞIMA TÜRÜNE UYGUN KONTEYNERLERİN KONTEYNER GEMİLERİYLE TAŞINMASI"
    },
    {
        "kod": "502022",
        "tanim": "ULUSLARARASI SULARDA VE KABOTAJ HATTINDA YÜK TAŞIMACILIĞI GEMİLERİNİN MÜRETTEBATIYLA BİRLİKTE KİRALANMASI (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502023",
        "tanim": "ULUSLARARASI SULARDA DİĞER DÖKME SIVILARIN TANKER GEMİLERLE TAŞINMASI (HAM PETROLÜN, PETROL ÜRÜNLERİNİN, GAZLARIN VE KİMYASALLARIN TAŞINMASI HARİÇ)"
    },
    {
        "kod": "502024",
        "tanim": "ULUSLARARASI SULARDA GAZLARIN TANKER GEMİLERLE TAŞINMASI"
    },
    {
        "kod": "502025",
        "tanim": "KABOTAJ HATTINDA HAM PETROLÜN, PETROL ÜRÜNLERİNİN VE KİMYASALLARIN TANKER GEMİLERLE TAŞINMASI (GAZLAR HARİÇ) (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502026",
        "tanim": "KABOTAJ HATTINDA DÖKME KURU YÜK TAŞINMASI (KİMYASALLARIN TAŞINMASI HARİÇ) (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502027",
        "tanim": "KABOTAJ HATTINDA FRİGORİFİK GEMİLERLE DONDURULMUŞ VEYA SOĞUTULMUŞ MALLARIN TAŞINMASI (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502028",
        "tanim": "KABOTAJ HATTINDA ÇOKLU TAŞIMA TÜRÜNE UYGUN KONTEYNERLERİN KONTEYNER GEMİLERİYLE TAŞINMASI (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502029",
        "tanim": "KABOTAJ HATTINDA DİĞER SIVILARIN TANKER GEMİLERLE TAŞINMASI (HAM PETROLÜN, PETROL ÜRÜNLERİNİN, GAZLARIN VE KİMYASALLARIN TAŞINMASI HARİÇ) (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502030",
        "tanim": "KABOTAJ HATTINDA GAZLARIN TANKER GEMİLERLE TAŞINMASI (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "502090",
        "tanim": "ULUSLARARASI SULARDA YAPILAN DİĞER YÜK TAŞIMACILIĞI"
    },
    {
        "kod": "502091",
        "tanim": "KABOTAJ HATTINDA YAPILAN DİĞER YÜK TAŞIMACILIĞI (İÇ SULAR HARİÇ)"
    },
    {
        "kod": "503008",
        "tanim": "İÇ SULARDA YOLCU TAŞIMACILIĞI (NEHİR, KANAL VE GÖLLERDE YAPILANLAR, VB.) (GEZİNTİ AMAÇLI OLANLAR DAHİL)"
    },
    {
        "kod": "503009",
        "tanim": "İÇ SULARDA YOLCU TAŞIMA GEMİLERİNİN VE TEKNELERİNİN MÜRETTEBATIYLA BİRLİKTE KİRALANMASI"
    },
    {
        "kod": "504005",
        "tanim": "İÇ SULARDA YÜK TAŞIMACILIĞI (NEHİR, KANAL VE GÖLLERDE YAPILANLAR, VB.)"
    },
    {
        "kod": "504007",
        "tanim": "İÇ SULARDA YÜK TAŞIMA GEMİ VE TEKNELERİNİN MÜRETTEBATIYLA BİRLİKTE KİRALANMASI HİZMETLERİ (NEHİR, KANAL VE GÖLLERDE, VB.)"
    },
    {
        "kod": "504008",
        "tanim": "İÇ SULARDA ÇEKME VE İTME HİZMETLERİ (RÖMORKAJ) (MAVNALARIN, ŞAMANDIRALARIN VB.NİN TAŞINMASI) (NEHİR, KANAL VE GÖLLERDE, VB.)"
    },
    {
        "kod": "511001",
        "tanim": "HAVA YOLU YOLCU TAŞIMACILIĞI (TARİFELİ OLANLAR)"
    },
    {
        "kod": "511002",
        "tanim": "HAVA YOLU YOLCU TAŞIMACILIĞI (TURİSTİK VE GEZİ AMAÇLI OLANLAR İLE TARİFESİZ OLANLAR) (HAVA TAKSİ TAŞIMACILIĞI DAHİL)"
    },
    {
        "kod": "511003",
        "tanim": "HAVA YOLU YOLCU TAŞIMA ARAÇLARININ MÜRETTEBATIYLA BİRLİKTE KİRALANMASI"
    },
    {
        "kod": "512117",
        "tanim": "HAVA YOLU İLE YÜK TAŞIMACILIĞI"
    },
    {
        "kod": "512202",
        "tanim": "UZAY TAŞIMACILIĞI (UYDULARIN VE UZAY TAŞITLARININ FIRLATILMASI, YÜK VE YOLCULARIN UZAYA TAŞINMASI)"
    },
    {
        "kod": "521002",
        "tanim": "FRİGORİFİK DEPOLAMA VE ANTREPOCULUK FAALİYETLERİ (BOZULABİLİR GIDA ÜRÜNLERİ DAHİL DONDURULMUŞ VEYA SOĞUTULMUŞ MALLAR İÇİN DEPOLAMA)"
    },
    {
        "kod": "521003",
        "tanim": "HUBUBAT DEPOLAMA VE ANTREPOCULUK FAALİYETLERİ (HUBUBAT SİLOLARININ İŞLETİLMESİ VB.)"
    },
    {
        "kod": "521004",
        "tanim": "PETROL, PETROL ÜRÜNLERİ, KİMYASALLAR, GAZ, VB. DEPOLAMA VE ANTREPOCULUK FAALİYETLERİ"
    },
    {
        "kod": "521005",
        "tanim": "DÖKME SIVI DEPOLAMA VE ANTREPOCULUK FAALİYETLERİ (YAĞ, ŞARAP, VB. DAHİL, PETROL, PETROL ÜRÜNLERİ, KİMYASALLAR, GAZ, VB. HARİÇ)"
    },
    {
        "kod": "521090",
        "tanim": "DİĞER DEPOLAMA VE ANTREPOCULUK FAALİYETLERİ (FRİGORİFİK DEPOLAR İLE HUBUBAT, KİMYASALLAR, DÖKME SIVI VE GAZ DEPOLAMA FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "522104",
        "tanim": "KARA YOLU TAŞIMACILIĞI İLE İLGİLİ ÖZEL VE TİCARİ ARAÇLAR İÇİN ÇEKME VE YOL YARDIMI FAALİYETLERİ"
    },
    {
        "kod": "522105",
        "tanim": "DEMİR YOLU TAŞIMACILIĞINI DESTEKLEYİCİ FAALİYETLER (DEMİR YOLU ÇEKME VE İTME HİZMETLERİ, MANEVRA VE MAKAS DEĞİŞTİRME HİZMETLERİ, DEMİR YOLU TERMİNAL HİZMETLERİ VB. DAHİL, EMANETÇİLİK HARİÇ)"
    },
    {
        "kod": "522106",
        "tanim": "KARA TAŞIMACILIĞINA YÖNELİK EMANET BÜROLARI İŞLETMECİLİĞİ (DEMİR YOLLARINDA YAPILANLAR DAHİL)"
    },
    {
        "kod": "522107",
        "tanim": "OTOPARK VE GARAJ İŞLETMECİLİĞİ (BİSİKLET PARKLARI VE KARAVANLARIN KIŞIN SAKLANMASI DAHİL)"
    },
    {
        "kod": "522108",
        "tanim": "OTOYOL, TÜNEL VE KÖPRÜ İŞLETMECİLİĞİ"
    },
    {
        "kod": "522109",
        "tanim": "KARA YOLU YOLCU TAŞIMACILIĞINA YÖNELİK OTOBÜS TERMİNAL HİZMETLERİ"
    },
    {
        "kod": "522110",
        "tanim": "KARA YOLU YOLCU TAŞIMACILIĞINA YÖNELİK OTOBÜS, MİNİBÜS VE TAKSİ DURAKLARININ İŞLETİLMESİ (OTOBÜS TERMİNAL HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "522112",
        "tanim": "KARA TAŞIMACILIĞINI DESTEKLEYİCİ OLARAK GAZLARIN SIVILAŞTIRILMASI"
    },
    {
        "kod": "522113",
        "tanim": "YOLCU TAŞIMACILIĞI KOOPERATİFLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522190",
        "tanim": "KARA TAŞIMACILIĞINI DESTEKLEYİCİ DİĞER HİZMETLER (KAMYON TERMİNAL İŞLETMECİLİĞİ DAHİL)"
    },
    {
        "kod": "522206",
        "tanim": "SU YOLU TAŞIMACILIĞINI DESTEKLEYİCİ OLARAK LİMAN VE SU YOLLARININ İŞLETİLMESİ (LİMANLARIN, İSKELELERİN, RIHTIMLARIN, SU YOLU HAVUZLARININ, DENİZ TERMİNALLERİNİN VB. İŞLETİLMESİ) (DENİZ FENERİ, FENER DUBASI VB. İŞLETİLMESİ HARİÇ)"
    },
    {
        "kod": "522207",
        "tanim": "SU YOLU TAŞIMACILIĞINI DESTEKLEYİCİ OLARAK DENİZ FENERİ, FENER DUBASI, FENER GEMİSİ, ŞAMANDIRA, KANAL İŞARETLERİ VB. SEYİR YARDIMCILARI İLE VERİLEN HİZMET FAALİYETLERİ"
    },
    {
        "kod": "522208",
        "tanim": "DENİZ VE KIYI SULARI İLE İÇ SULARDA KILAVUZLUK VE RIHTIMA YANAŞTIRMA FAALİYETLERİ (GEMİNİN HAVUZLANMASI VE HAVUZDAN ÇIKARILMASI DAHİL)"
    },
    {
        "kod": "522210",
        "tanim": "DENİZ VE KIYI SULARI İLE İÇ SULARDA GEMİ KURTARMA VE TEKRAR YÜZDÜRME FAALİYETLERİ (ZOR DURUMDAKİ GEMİLERİN ÇEKİLMESİ, BU GEMİLERİN VE KARGOLARININ KURTARILMASI VB.)"
    },
    {
        "kod": "522290",
        "tanim": "SU TAŞIMACILIĞINI DESTEKLEYİCİ DİĞER HİZMETLER"
    },
    {
        "kod": "522303",
        "tanim": "HAVAALANI YER HİZMET FAALİYETLERİ (KARGO VE BAGAJ YÜKLEME BOŞALTMA HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "522304",
        "tanim": "HAVAALANI İŞLETMECİLİĞİ (UÇAK PİSTİ İŞLETME HİZMETLERİ VE HAVA YOLU YOLCU TERMİNALİ HİZMETLERİ DAHİL, HAVAALANI YER HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "522306",
        "tanim": "HAVA TRAFİK KONTROL HİZMETLERİ (HAVAALANINDA YER ALAN KULE VE RADAR İSTASYONLARI TARAFINDAN SAĞLANAN HİZMETLER DAHİL)"
    },
    {
        "kod": "522307",
        "tanim": "UZAY TAŞIMACILIĞINI DESTEKLEYİCİ HİZMETLER"
    },
    {
        "kod": "522390",
        "tanim": "HAVA TAŞIMACILIĞINI DESTEKLEYİCİ DİĞER FAALİYETLER (HAVAALANLARINDA YANGIN SÖNDÜRME VE YANGIN ÖNLEME FAALİYETLERİ, HAVA TAŞITLARININ ÇEKİLMESİ, VB.)"
    },
    {
        "kod": "522408",
        "tanim": "SU YOLU TAŞIMACILIĞIYLA İLGİLİ KARGO VE BAGAJ YÜKLEME BOŞALTMA HİZMETLERİ (KONTEYNER YÜKLEME BOŞALTMA HİZMETLERİ DAHİL)"
    },
    {
        "kod": "522409",
        "tanim": "HAVA YOLU TAŞIMACILIĞIYLA İLGİLİ KARGO VE BAGAJ YÜKLEME BOŞALTMA HİZMETLERİ"
    },
    {
        "kod": "522410",
        "tanim": "KARA YOLU TAŞIMACILIĞIYLA İLGİLİ KARGO YÜKLEME BOŞALTMA HİZMETLERİ"
    },
    {
        "kod": "522411",
        "tanim": "DEMİR YOLU TAŞIMACILIĞIYLA İLGİLİ KARGO YÜKLEME BOŞALTMA HİZMETLERİ"
    },
    {
        "kod": "522901",
        "tanim": "DENİZ YOLU YÜK NAKLİYAT KOMİSYONCULARININ FAALİYETLERİ"
    },
    {
        "kod": "522902",
        "tanim": "ULUSLARARASI DENİZ YOLU YÜK NAKLİYAT ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522903",
        "tanim": "HAVA YOLU YÜK NAKLİYAT ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522904",
        "tanim": "GÜMRÜK KOMİSYONCULARININ FAALİYETLERİ"
    },
    {
        "kod": "522905",
        "tanim": "KANTAR HİZMETLERİ (YÜKLÜ ARAÇLARIN TARTILMASI, VB.)"
    },
    {
        "kod": "522906",
        "tanim": "KARA YOLU YÜK NAKLİYAT ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522907",
        "tanim": "KARA YOLU YÜK NAKLİYAT KOMİSYONCULARININ FAALİYETLERİ"
    },
    {
        "kod": "522909",
        "tanim": "YETKİLİ GÜMRÜK MÜŞAVİRLERİ VEYA GÜMRÜK MÜŞAVİRLERİ"
    },
    {
        "kod": "522911",
        "tanim": "TAŞIMA BELGELERİNİN VE İRSALİYELERİN DÜZENLENMESİ VE TEDARİK EDİLMESİ"
    },
    {
        "kod": "522913",
        "tanim": "HAVA YOLU YÜK NAKLİYAT KOMİSYONCULARININ FAALİYETLERİ"
    },
    {
        "kod": "522914",
        "tanim": "DEMİR YOLU YÜK NAKLİYAT ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522915",
        "tanim": "DEMİR YOLU YÜK NAKLİYAT KOMİSYONCULARININ FAALİYETLERİ"
    },
    {
        "kod": "522916",
        "tanim": "TAŞINAN MALLARIN KASALARDAN, SANDIKLARDAN VB.LERİNDEN ÇIKARILMASI, NUMUNE ALINMASI, İNCELENMESİ VB. FAALİYETLER"
    },
    {
        "kod": "522917",
        "tanim": "YÜK TAŞIMACILIĞI KOOPERATİFLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522918",
        "tanim": "KABOTAJ HATTI DENİZ YOLU YÜK NAKLİYAT ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "522990",
        "tanim": "BYS. TAŞIMACILIĞI DESTEKLEYİCİ DİĞER FAALİYETLER (GRUP SEVKİYATININ ORGANİZASYONU, MALLARIN TAŞINMASI SIRASINDA KORUNMASI İÇİN GEÇİCİ OLARAK KASALARA VB. YERLEŞTİRİLMESİ, YÜKLERİN BİRLEŞTİRİLMESİ, GRUPLANMASI VE PARÇALARA AYIRILMASI, VB. DAHİL)"
    },
    {
        "kod": "531001",
        "tanim": "EVRENSEL HİZMET YÜKÜMLÜLÜĞÜ ALTINDA POSTACILIK FAALİYETLERİ (KARGO VE KURYE ŞİRKETLERİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "532008",
        "tanim": "GIDA, MOBİLYA VB. SATIN ALINAN ŞEYLERE İLİŞKİN EVLERE DAĞITIM FAALİYETLERİ (ŞEHİR İÇİ YÜK TAŞIMACILIĞI VE EVDEN EVE NAKLİYAT VB. HARİÇ)"
    },
    {
        "kod": "532009",
        "tanim": "KURYE FAALİYETLERİ (KARA, DENİZ VE HAVA YOLU İLE YAPILANLAR DAHİL, EVRENSEL HİZMET YÜKÜMLÜLÜĞÜ ALTINDA POSTACILIK FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "532010",
        "tanim": "PAKET VE KOLİ GİBİ KARGOLARIN TOPLANMASI, SINIFLANDIRILMASI, TAŞINMASI VE DAĞITIMI FAALİYETLERİ (DÖKME YÜKLER VE EVRENSEL HİZMET YÜKÜMLÜLÜĞÜ ALTINDA POSTACILIK FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "551002",
        "tanim": "OTEL VB. KONAKLAMA YERLERİNİN FAALİYETLERİ (GÜNLÜK TEMİZLİK VE YATAK YAPMA HİZMETİ SAĞLANAN YERLERİN FAALİYETLERİ) (KENDİ MÜŞTERİLERİNE RESTORAN HİZMETİ VERMEYENLER İLE DEVRE MÜLKLER HARİÇ)"
    },
    {
        "kod": "551005",
        "tanim": "OTEL VB. KONAKLAMA YERLERİNİN FAALİYETLERİ (GÜNLÜK TEMİZLİK VE YATAK YAPMA HİZMETİ SAĞLANAN YERLERİN FAALİYETLERİ) (KENDİ MÜŞTERİLERİNE RESTORAN HİZMETİ VERENLER İLE DEVRE MÜLKLER HARİÇ)"
    },
    {
        "kod": "552001",
        "tanim": "TATİL VE DİĞER KISA SÜRELİ KONAKLAMA FAALİYETLERİ (HOSTELLER, DEVRE MÜLKLER, TATİL EVLERİ, APART OTELLER, BUNGALOV, DAĞ EVLERİ, VB.NDE) (GÜNLÜK TEMİZLİK VE YATAK YAPMA HİZMETİ SAĞLANAN ODA VEYA SÜİT KONAKLAMA FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "552003",
        "tanim": "KENDİNE AİT VEYA KİRALANMIŞ MOBİLYALI EVLERDE BİR AYDAN DAHA KISA SÜRELİ OLARAK KONAKLAMA FAALİYETLERİ"
    },
    {
        "kod": "552004",
        "tanim": "TATİL AMAÇLI PANSİYONLARIN FAALİYETLERİ"
    },
    {
        "kod": "553036",
        "tanim": "KAMP ALANLARININ, MOTORLU KARAVAN, VB. İÇİN PARK YERLERİNİN FAALİYETLERİ (ÇADIR VEYA KARAVAN İLE KAMP KURMA AMAÇLI AÇIK HAVA TESİSİ SAĞLANMASI, YETİŞKİNLER VEYA ÇOCUKLAR İÇİN KAMP PROGRAMLARINDA VE AVCILIK KAMPLARINDA KONAKLAMA HİZMETİNİN SAĞLANMASI, VB.)"
    },
    {
        "kod": "559001",
        "tanim": "ÖĞRENCİ VE İŞÇİ YURTLARI, PANSİYONLAR VE ODASI KİRALANAN EVLERDE YAPILAN KONAKLAMA FAALİYETLERİ (TATİL AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "559002",
        "tanim": "MİSAFİRHANELER, ORDU EVİ, POLİS EVİ VE ÖĞRETMEN EVLERİ İLE EĞİTİM VE DİNLENME TESİSLERİ GİBİ KONAKLAMA YERLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "559003",
        "tanim": "DİĞER KONAKLAMA YERLERİNİN FAALİYETLERİ (BAŞKA BİR BİRİM TARAFINDAN İŞLETİLDİĞİNDE YATAKLI VAGONLAR, VB. DAHİL, MİSAFİRHANELER, ÖĞRETMEN EVİ, VB. HARİÇ)"
    },
    {
        "kod": "561001",
        "tanim": "SEYYAR YEMEK SERVİSİ FAALİYETLERİ (SİMİT, POĞAÇA, BÖREK, KOKOREÇ, NOHUT-PİLAV, PİYAZ, DONDURMA, VB. İLE KAYIKLARDA YAPILANLAR DAHİL)"
    },
    {
        "kod": "561002",
        "tanim": "BÖREKÇİLERİN FAALİYETLERİ (İMALATÇILARIN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561003",
        "tanim": "ÇORBACILARIN VE İŞKEMBECİLERİN FAALİYETLERİ (İMALATÇILARIN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561004",
        "tanim": "DONDURMACILARIN FAALİYETLERİ (İMALATÇILARIN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561005",
        "tanim": "OTURACAK YERİ OLMAYAN FAST-FOOD SATIŞ YERLERİ (HAMBURGER, SANDVİÇ, TOST, VB.), AL GÖTÜR TESİSLERİ VE BENZERLERİ TARAFINDAN SAĞLANAN DİĞER YEMEK HAZIRLAMA VE SUNUM FAALİYETLERİ"
    },
    {
        "kod": "561006",
        "tanim": "DÖNER, LAHMACUN VE PİDECİLİK FAALİYETİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561007",
        "tanim": "CİĞER, KOKOREÇ, KÖFTE VE KEBAPÇILARIN FAALİYETİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561008",
        "tanim": "DİĞER LOKANTA VE RESTORANLARIN (İÇKİLİ VE İÇKİSİZ) FAALİYETLERİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561009",
        "tanim": "PASTANELERİN VE TATLICILARIN FAALİYETİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561010",
        "tanim": "PİZZACILARIN FAALİYETİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561014",
        "tanim": "BAŞKA BİRİMLER TARAFINDAN İŞLETİLDİĞİNDE GEMİ VE TRENLERDE RESTORAN İŞLETMECİLİĞİ (YEMEKLİ VAGON, VB.)"
    },
    {
        "kod": "561017",
        "tanim": "MANTICI VE GÖZLEMECİLERİN FAALİYETİ (GARSON SERVİSİ SUNANLAR İLE SELF SERVİS SUNANLAR DAHİL, İMALATÇILARIN VE AL GÖTÜR TESİSLERİN FAALİYETLERİ İLE SEYYAR OLANLAR HARİÇ)"
    },
    {
        "kod": "561018",
        "tanim": "OTURACAK YERİ OLAN FAST-FOOD SATIŞ YERLERİ (HAMBURGER, SANDVİÇ, TOST, VB.) TARAFINDAN SAĞLANAN YEMEK HAZIRLAMA VE SUNUM FAALİYETLERİ"
    },
    {
        "kod": "561019",
        "tanim": "YİYECEK AĞIRLIKLI HİZMET VEREN KAFETERYALARIN FAALİYETLERİ"
    },
    {
        "kod": "562101",
        "tanim": "ÖZEL GÜNLERDE DIŞARIYA YEMEK SUNAN İŞLETMELERİN FAALİYETLERİ"
    },
    {
        "kod": "562901",
        "tanim": "KANTİNLERİN FAALİYETLERİ (SPOR, FABRİKA, OKUL VEYA İŞYERİ KANTİNLERİ, VB.)"
    },
    {
        "kod": "562903",
        "tanim": "HAVA YOLU ŞİRKETLERİ VE DİĞER ULAŞTIRMA ŞİRKETLERİ İÇİN SÖZLEŞMEYE BAĞLI DÜZENLEMELERE DAYALI OLARAK YİYECEK HAZIRLANMASI VE TEMİNİ HİZMETLERİ"
    },
    {
        "kod": "562990",
        "tanim": "DIŞARIYA YEMEK SUNAN DİĞER İŞLETMELERİN FAALİYETLERİ (SPOR, FABRİKA, İŞYERİ, ÜNİVERSİTE, VB. MENSUPLARI İÇİN TABLDOT SERVİSİ, VB. DAHİL, ÖZEL GÜNLERDE HİZMET VERENLER HARİÇ)"
    },
    {
        "kod": "563002",
        "tanim": "ÇAY OCAKLARI, KIRAATHANELER, KAHVEHANELER, KAFELER, MEYVE SUYU SALONLARI VE ÇAY BAHÇELERİNDE İÇECEK SUNUM FAALİYETİ"
    },
    {
        "kod": "563003",
        "tanim": "LOKALLERDE İÇECEK SUNUM FAALİYETİ (ALKOLLÜ-ALKOLSÜZ)"
    },
    {
        "kod": "563004",
        "tanim": "BAR, MEYHANE VE BİRAHANELERDE İÇECEK SUNUM FAALİYETLERİ (ALKOLLÜ-ALKOLSÜZ)"
    },
    {
        "kod": "563005",
        "tanim": "GAZİNO, GECE KULÜBÜ, TAVERNA, DİSKOTEK, KOKTEYL SALONLARI, VB. YERLERDE İÇECEK SUNUM FAALİYETLERİ (ALKOLLÜ-ALKOLSÜZ)"
    },
    {
        "kod": "563006",
        "tanim": "TRENLERDE VE GEMİLERDE İŞLETİLEN BARLARIN FAALİYETLERİ (ALKOLLÜ-ALKOLSÜZ)"
    },
    {
        "kod": "563008",
        "tanim": "BOZA, ŞALGAM VE SAHLEP SUNUM FAALİYETİ"
    },
    {
        "kod": "563090",
        "tanim": "SEYYAR İÇECEK SATANLAR İLE DİĞER İÇECEK SUNUM FAALİYETLERİ"
    },
    {
        "kod": "581101",
        "tanim": "KİTAP YAYIMI (BROŞÜR, RİSALE, ANSİKLOPEDİ, VB. DAHİL, ÇOCUK KİTAPLARININ, DERS KİTAPLARININ VE YARDIMCI DERS KİTAPLARININ YAYIMLANMASI HARİÇ)"
    },
    {
        "kod": "581103",
        "tanim": "ÇOCUK KİTAPLARININ YAYIMLANMASI"
    },
    {
        "kod": "581104",
        "tanim": "DERS KİTAPLARININ VE YARDIMCI DERS KİTAPLARININ YAYIMLANMASI (SÖZLÜK, ATLAS, GRAFİKLER, HARİTALAR VB. DAHİL)"
    },
    {
        "kod": "581201",
        "tanim": "REHBERLERİN VE POSTA ADRES LİSTELERİNİN YAYIMLANMASI (TELEFON REHBERLERİ, İŞ VE TİCARET REHBERLERİ, BELEDİYE VE ŞEHİR REHBERLERİ, VB.)"
    },
    {
        "kod": "581301",
        "tanim": "GAZETELERİN YAYIMLANMASI (HAFTADA EN AZ DÖRT KEZ YAYIMLANANLAR) (REKLAM GAZETELERİ DAHİL)"
    },
    {
        "kod": "581402",
        "tanim": "EĞİTİME DESTEK AMAÇLI DERGİ VE SÜRELİ YAYINLARIN YAYIMLANMASI (HAFTADA DÖRTTEN AZ YAYIMLANANLAR)"
    },
    {
        "kod": "581403",
        "tanim": "BİLİMSEL, TEKNİK, KÜLTÜREL VB. DERGİ VE SÜRELİ YAYINLARIN YAYIMLANMASI (HAFTADA DÖRTTEN AZ YAYIMLANANLAR)"
    },
    {
        "kod": "581490",
        "tanim": "DİĞER DERGİ VE SÜRELİ YAYINLARIN YAYIMLANMASI (HAFTADA DÖRTTEN AZ YAYIMLANANLAR) (ÇİZGİ ROMAN, MAGAZİN DERGİLERİ VB.)"
    },
    {
        "kod": "581904",
        "tanim": "DEĞERLİ KAĞITLARIN YAYIMLANMASI FAALİYETLERİ (PUL, TAHVİL, HİSSE SENEDİ, BONO VEYA SENET VB. DEĞERLİ KAĞITLAR)"
    },
    {
        "kod": "581990",
        "tanim": "BYS. DİĞER YAYINCILIK FAALİYETLERİ (KARTPOSTAL, TEBRİK KARTLARI VB. İLE KATALOG, POSTER, REKLAM MATERYALİ VB.)"
    },
    {
        "kod": "582101",
        "tanim": "BİLGİSAYAR OYUNLARININ YAYIMLANMASI"
    },
    {
        "kod": "582901",
        "tanim": "DİĞER YAZILIM PROGRAMLARININ YAYIMLANMASI"
    },
    {
        "kod": "591103",
        "tanim": "SİNEMA FİLMİ, VİDEO VE TELEVİZYON PROGRAMLARI YAPIM FAALİYETLERİ (BELGESEL YAPIMCILIĞI DAHİL)"
    },
    {
        "kod": "591201",
        "tanim": "SİNEMA FİLMİ, VİDEO VE TELEVİZYON PROGRAMLARI ÇEKİM SONRASI FAALİYETLERİ (SES-GÖRÜNTÜ REDAKSİYONU, ASIL KOPYALARIN AKTARIMI, RENK DÜZELTME, SAYISAL İYİLEŞTİRME, GÖRSEL EFEKT, ANİMASYON, ALT YAZI, BAŞLIKLANDIRMA, GRAFİK, VB. İŞLER)"
    },
    {
        "kod": "591302",
        "tanim": "SİNEMA FİLMİ, VİDEO VE TELEVİZYON PROGRAMLARI DAĞITIM FAALİYETLERİ (FİLM HAKLARI VE GELİRLERİ İÇİN LİSANSLAMA HİZMETLERİ, ÇALIŞMALARIN GÖSTERİMİ, YAYIMLANMASI VE KİRALANMASI İÇİN İZİN VERİLMESİ, ELDE EDİLEN GELİRLERİN DAĞITILMASI VB.)"
    },
    {
        "kod": "591402",
        "tanim": "SİNEMA FİLMİ GÖSTERİM FAALİYETLERİ"
    },
    {
        "kod": "592001",
        "tanim": "MÜZİK YAYIMCILIĞI FAALİYETLERİ (BASILI MÜZİK NOTALARI, ELEKTRONİK FORMDAKİ MÜZİKAL BESTELER, MÜZİKAL SES DİSKLERİ, İNDİRİLEBİLİR MÜZİKLER VB.)"
    },
    {
        "kod": "592002",
        "tanim": "SES KAYIT VE CANLI KAYIT FAALİYETLERİ (SESLERİN, SÖZLERİN VE MÜZİĞİN SES KAYIT STÜDYOSUNUN ÖZEL TEKNİK EKİPMANLARI KULLANILARAK KAYDEDİLMESİ İLE KONFERANS, SEMİNER, KONSER VB. CANLI ETKİNLİKLERDE YAPILAN KAYIT HİZMETLERİ VB.)"
    },
    {
        "kod": "592003",
        "tanim": "ORİJİNAL SES KAYITLARINI KULLANIM HAKKI İÇİN LİSANSLAMA FAALİYETLERİ"
    },
    {
        "kod": "592006",
        "tanim": "RADYO PROGRAMI YAPIMCILIK FAALİYETLERİ"
    },
    {
        "kod": "601009",
        "tanim": "RADYO YAYINCILIĞI (RADYO YAYIN STÜDYOLARI VB.)"
    },
    {
        "kod": "602001",
        "tanim": "TELEVİZYON PROGRAMCILIĞI VE YAYINCILIĞI FAALİYETLERİ"
    },
    {
        "kod": "611015",
        "tanim": "KABLOLU TELEKOMÜNİKASYON FAALİYETLERİ (KABLOLU AĞLAR ÜZERİNDEN İNTERNET ERİŞİMİNİN SAĞLANMASI HARİÇ)"
    },
    {
        "kod": "611017",
        "tanim": "KABLOLU AĞLAR ÜZERİNDEN İNTERNET ERİŞİMİNİN SAĞLANMASI"
    },
    {
        "kod": "612002",
        "tanim": "KABLOSUZ TELEKOMÜNİKASYON FAALİYETLERİ (KABLOSUZ AĞLAR ÜZERİNDEN İNTERNET ERİŞİMİNİN SAĞLANMASI VE UYDU ÜZERİNDEN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "612003",
        "tanim": "KABLOSUZ AĞLAR ÜZERİNDEN İNTERNET ERİŞİMİNİN SAĞLANMASI"
    },
    {
        "kod": "613001",
        "tanim": "UYDU ÜZERİNDEN TELEKOMÜNİKASYON FAALİYETLERİ"
    },
    {
        "kod": "619004",
        "tanim": "TELEKOMÜNİKASYON UYGULAMALARINA YÖNELİK RADAR İSTASYONLARININ İŞLETİLMESİ"
    },
    {
        "kod": "619005",
        "tanim": "İNTERNET KAFELERİN FAALİYETLERİ"
    },
    {
        "kod": "619007",
        "tanim": "TELEKOMÜNİKASYON HİZMETİ YENİDEN SATICILARININ FAALİYETLERİ"
    },
    {
        "kod": "619090",
        "tanim": "BYS. DİĞER TELEKOMÜNİKASYON FAALİYETLERİ (UYDUDAN İZLEME, İLETİŞİM TELEMETRESİ VB. UZMANLIK GEREKTİREN TELEKOMÜNİKASYON UYGULAMALARININ SAĞLANMASI, ÇEVRİM İÇİ İNTERNET ERİŞİMİ SAĞLANMASI, VOIP SAĞLANMASI, VB.)"
    },
    {
        "kod": "620101",
        "tanim": "BİLGİSAYAR PROGRAMLAMA FAALİYETLERİ (SİSTEM, VERİ TABANI, NETWORK, WEB SAYFASI VB. YAZILIMLARI İLE MÜŞTERİYE ÖZEL YAZILIMLARIN KODLANMASI VB)"
    },
    {
        "kod": "620201",
        "tanim": "BİLGİSAYAR DANIŞMANLIK FAALİYETLERİ (DONANIM GEREKSİNİMLERİ GİBİ DONANIMLA İLGİLİ BİLİŞİM KONULARINDA UZMAN GÖRÜŞÜ SAĞLANMASI, BİLGİSAYAR GEREKSİNİMLERİNİN BELİRLENMESİ, BİLGİSAYAR SİSTEMLERİNİN PLANLANMASI VE TASARLANMASI VB.)"
    },
    {
        "kod": "620301",
        "tanim": "BİLGİSAYAR TESİSLERİ YÖNETİM FAALİYETLERİ"
    },
    {
        "kod": "620901",
        "tanim": "BİLGİSAYARLARI FELAKETTEN KURTARMA VE VERİ KURTARMA FAALİYETLERİ"
    },
    {
        "kod": "620902",
        "tanim": "DİĞER BİLGİ TEKNOLOJİSİ VE BİLGİSAYAR HİZMET FAALİYETLERİ (KİŞİSEL BİLGİSAYARLARIN VE ÇEVRE BİRİMLERİNİN KURULUMU, YAZILIM KURMA VB.)"
    },
    {
        "kod": "631108",
        "tanim": "VERİ İŞLEME, BARINDIRMA VE İLGİLİ FAALİYETLER (VERİ GİRİŞİ, VERİNİN İŞLENMESİ, ÖZEL RAPORLARIN OLUŞTURULMASI, DEPOLANMASI, VB.)"
    },
    {
        "kod": "631201",
        "tanim": "WEB PORTALI FAALİYETLERİ"
    },
    {
        "kod": "639101",
        "tanim": "HABER AJANSLARININ FAALİYETLERİ (MEDYA İÇİN HABER, RESİM VE RÖPORTAJ TEDARİK EDEN HABER BÜROSU VE HABER AJANSLARININ FAALİYETLERİ)"
    },
    {
        "kod": "639901",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER BİLGİ HİZMET FAALİYETLERİ (BİLGİ ARAŞTIRMA HİZMETLERİ, GAZETE KUPÜRLERİ HİZMETLERİ VB.)"
    },
    {
        "kod": "641106",
        "tanim": "MERKEZ BANKASI FAALİYETLERİ"
    },
    {
        "kod": "641901",
        "tanim": "BANKALARIN FAALİYETLERİ (KATILIM BANKALARI, MEVDUAT BANKALARI, KREDİ BİRLİKLERİ VB. DAHİL, MERKEZ BANKASI VE YATIRIM BANKALARI HARİÇ)"
    },
    {
        "kod": "642019",
        "tanim": "HOLDİNG ŞİRKETLERİNİN FAALİYETLERİ (BAĞLI İŞTİRAKLERİNİ YÖNETENLER HARİÇ)"
    },
    {
        "kod": "643001",
        "tanim": "TRUSTLAR, FONLAR VE BENZERİ MALİ VARLIKLAR"
    },
    {
        "kod": "649101",
        "tanim": "FİNANSAL KİRALAMA (FİNANSAL LEASİNG)"
    },
    {
        "kod": "649201",
        "tanim": "DİĞER KREDİ VERME FAALİYETLERİ (BANKACILIK SİSTEMİ DIŞINDA BORÇ PARA VERİLMESİ, ULUSLARARASI TİCARİ FİNANSMAN, MEVDUAT KABUL ETMEYEN UZMANLAŞMIŞ KURULUŞLARCA KONUT KREDİSİ VERİLMESİ, REHİN KARŞILIĞINDA BORÇ PARA VERİLMESİ VB.) (İKRAZATÇILAR HARİÇ)"
    },
    {
        "kod": "649204",
        "tanim": "TARIM KREDİ KOOPERATİFLERİNİN KREDİ VERME FAALİYETLERİ"
    },
    {
        "kod": "649207",
        "tanim": "İKRAZATÇILARIN FAALİYETLERİ"
    },
    {
        "kod": "649208",
        "tanim": "TÜKETİCİ FİNANSMAN ŞİRKETLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "649901",
        "tanim": "FAKTORİNG FAALİYETLERİ"
    },
    {
        "kod": "649903",
        "tanim": "GAYRİMENKUL YATIRIM ORTAKLIĞI"
    },
    {
        "kod": "649908",
        "tanim": "YATIRIM BANKACILIĞI FAALİYETLERİ"
    },
    {
        "kod": "649909",
        "tanim": "VARLIK YÖNETİM ŞİRKETLERİNİN FAALİYETLERİ (MÜLKİYET DEVRİ YOLUYLA YAPILANLAR)"
    },
    {
        "kod": "649910",
        "tanim": "MENKUL KIYMET YATIRIM ORTAKLIĞI"
    },
    {
        "kod": "649990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER FİNANSAL HİZMET FAALİYETLERİ (SWAP, OPSİYON VE DİĞER RİSKTEN KORUNMA SÖZLEŞMELERİNİN YAZILMASI, VB. DAHİL)"
    },
    {
        "kod": "651102",
        "tanim": "HAYAT SİGORTASI FAALİYETLERİ"
    },
    {
        "kod": "651213",
        "tanim": "HAYAT SİGORTASI DIŞINDAKİ SİGORTACILIK FAALİYETLERİ (SAĞLIK, YANGIN, MOTORLU TAŞIT, KONUT, TARIM, DENİZCİLİK, HAVACILIK, KAZA, DOĞAL AFET, ULAŞTIRMA, NAKLİYAT, PARA KAYBI, BORÇLANMA, MALİ SORUMLULUK, VB.)"
    },
    {
        "kod": "652001",
        "tanim": "REASÜRANS FAALİYETLERİ (SİGORTA ŞİRKETLERİ TARAFINDAN TAAHHÜT EDİLEN SİGORTA POLİÇELERİNE İLİŞKİN RİSKİN ÜSTLENİLMESİ)"
    },
    {
        "kod": "653001",
        "tanim": "EMEKLİLİK FONU FAALİYETLERİ"
    },
    {
        "kod": "661102",
        "tanim": "FİNANSAL PİYASALARIN YÖNETİMİ (EMTİA SÖZLEŞMELERİ BORSASI, MENKUL KIYMETLER BORSASI, HİSSE SENEDİ BORSASI, VB. YÖNETİMİ DAHİL, KAMU OTORİTELERİ TARAFINDAN YAPILANLAR HARİÇ)"
    },
    {
        "kod": "661201",
        "tanim": "MENKUL KIYMETLER ARACILIK FAALİYETLERİ (BORSA ARACILIĞI VE VADELİ İŞLEMLER DAHİL)"
    },
    {
        "kod": "661204",
        "tanim": "DÖVİZ BÜROLARININ FAALİYETLERİ"
    },
    {
        "kod": "661206",
        "tanim": "KAMBİYO HİZMETLERİ (DÖVİZ BÜROLARININ FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "661208",
        "tanim": "EMTİA SÖZLEŞMELERİ ARACILIK FAALİYETLERİ"
    },
    {
        "kod": "661301",
        "tanim": "KENDİ ADINA MENKUL SERMAYE İRADI FAALİYETLERİ (TEMETTÜ, BANKA FAİZİ, İŞTİRAK KAZANÇLARI, HUZUR HAKKI,  VB.)"
    },
    {
        "kod": "661902",
        "tanim": "İPOTEKLİ SATIŞ İLE KREDİ SİMSARLIĞI VE DANIŞMANLIĞI FAALİYETLERİ (SİGORTA VE EMEKLİLİK FONLARI İLE ESNAF VE SANATKARLAR KREDİ KEFALET KOOPERATİFLERİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "661903",
        "tanim": "FİNANSAL DANIŞMANLIK FAALİYETLERİ"
    },
    {
        "kod": "661904",
        "tanim": "MENKUL KIYMETLERİN OPERASYON VE TAKAS İŞLEMİ FAALİYETLERİ"
    },
    {
        "kod": "661905",
        "tanim": "YATIRIM BANKACILIĞINA İLİŞKİN YARDIMCI FAALİYETLER (BİRLEŞME VE DEVİR FAALİYETİ, İŞLETME FİNANSMANI VE RİSK SERMAYESİ FİNANSMAN FAALİYETİ, VB.)"
    },
    {
        "kod": "661906",
        "tanim": "ESNAF VE SANATKARLAR KREDİ KEFALET KOOPERATİFLERİNİN KREDİ ARACILIK FAALİYETLERİ İLE KREDİ GARANTİ FONUNUN FAALİYETLERİ "
    },
    {
        "kod": "661907",
        "tanim": "YEDİEMİN FAALİYETLERİ"
    },
    {
        "kod": "661990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ FİNANSAL HİZMETLERE YARDIMCI DİĞER FAALİYETLER (FİNANSAL İŞLEMLERİN OPERASYONU VE TAKAS MERKEZİ FAALİYETLERİ, SERVET YÖNETİMİ VE SAKLAMA HİZMETLERİ, VB.)"
    },
    {
        "kod": "662101",
        "tanim": "RİSK VE HASAR DEĞERLEMESİ FAALİYETLERİ (SİGORTA EKSPERLİĞİ DAHİL)"
    },
    {
        "kod": "662201",
        "tanim": "SİGORTA ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "662202",
        "tanim": "SİGORTA BROKERLARININ FAALİYETLERİ"
    },
    {
        "kod": "662901",
        "tanim": "AKTÜERYA FAALİYETLERİ"
    },
    {
        "kod": "662990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ SİGORTA VE EMEKLİLİK FONUNA YARDIMCI DİĞER FAALİYETLER (KURTARILAN SİGORTALI EŞYANIN İDARESİ, VB.)"
    },
    {
        "kod": "663002",
        "tanim": "BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK FON YÖNETİMİ FAALİYETLERİ (PORTFÖY YÖNETİMİ, MÜŞTEREK FONLARIN YÖNETİMİ, EMEKLİLİK FONLARININ YÖNETİMİ, VB.)"
    },
    {
        "kod": "681001",
        "tanim": "KENDİNE AİT GAYRİMENKULÜN ALINIP SATILMASI (KENDİNE AİT BİNALAR, DEVRE MÜLKLER, ARAZİLER, MÜSTAKİL EVLER, VB.)"
    },
    {
        "kod": "682002",
        "tanim": "KENDİNE AİT VEYA KİRALANAN GAYRİMENKULLERİN KİRAYA VERİLMESİ VEYA LEASİNGİ (KENDİNE AİT BİNALAR, DEVRE MÜLKLER, ARAZİLER, MÜSTAKİL EVLER, VB.)"
    },
    {
        "kod": "683101",
        "tanim": "GAYRİMENKUL ACENTELERİNİN FAALİYETLERİ (GAYRİMENKULÜN ÜCRET VEYA SÖZLEŞME\r\nTEMELİNE DAYALI OLARAK SATIN ALINMASI, SATILMASI VE\r\nKİRALANMASINDA ARACILIK, VB.)"
    },
    {
        "kod": "683102",
        "tanim": "BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK YAPILAN GAYRİMENKUL DANIŞMANLIK VE EKSPERTİZ FAALİYETLERİ"
    },
    {
        "kod": "683202",
        "tanim": "BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK YAPILAN GAYRİMENKUL YÖNETİMİ FAALİYETLERİ (SİTE YÖNETİCİLİĞİ, MOBİL EV ALANLARININ, MÜŞTEREK MÜLKİYETLİ KONUTLARIN, DEVRE MÜLKLERİN, İKAMET AMAÇLI OLMAYAN MÜLKLERİN, VB. YÖNETİMİ)"
    },
    {
        "kod": "683203",
        "tanim": "BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK YAPILAN KİRA TOPLAMA FAALİYETLERİ"
    },
    {
        "kod": "683204",
        "tanim": "BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK YAPILAN APARTMAN YÖNETİCİLİĞİ"
    },
    {
        "kod": "691001",
        "tanim": "BİLİRKİŞİ FAALİYETLERİ (HUKUKİ KONULARDA)"
    },
    {
        "kod": "691002",
        "tanim": "HUKUK MÜŞAVİRLİĞİ"
    },
    {
        "kod": "691003",
        "tanim": "HUKUK DANIŞMANLIĞI VE TEMSİL FAALİYETLERİ (AVUKATLIK FAALİYETLERİ)"
    },
    {
        "kod": "691004",
        "tanim": "DİĞER HUKUKİ HİZMET FAALİYETLERİ (PATENT, TELİF HAKKI VE DİĞER FİKRİ MÜLKİYET HAKLARI, VARLIKLARIN TASVİYESİ VB. DANIŞMANLIK VE DİĞER YASAL HİZMETLER)"
    },
    {
        "kod": "691007",
        "tanim": "NOTERLİK FAALİYETLERİ"
    },
    {
        "kod": "691008",
        "tanim": "SOSYAL GÜVENLİK MÜŞAVİRLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "691009",
        "tanim": "HUKUKİ ARABULUCULUK VE UZLAŞTIRMA FAALİYETLERİ (İŞGÜCÜ VE YÖNETİM ARASINDA, İŞLETMELER ARASINDA VEYA ŞAHISLAR ARASINDA ORTAYA ÇIKAN ANLAŞMAZLIĞIN ÇÖZÜMÜ İÇİN TAHKİM VEYA ARABULUCULUK HİZMETLERİ)"
    },
    {
        "kod": "692001",
        "tanim": "MALİ MÜŞAVİRLİK HİZMETLERİ"
    },
    {
        "kod": "692002",
        "tanim": "MUHASEBE VE DEFTER TUTMA FAALİYETLERİ"
    },
    {
        "kod": "692003",
        "tanim": "VERGİ DANIŞMANLIĞI VE VERGİ BEYANNAMESİNİN HAZIRLANMASI FAALİYETLERİ"
    },
    {
        "kod": "692004",
        "tanim": "YEMİNLİ MALİ MÜŞAVİRLİK FAALİYETLERİ"
    },
    {
        "kod": "692005",
        "tanim": "MALİ DENETİM FAALİYETLERİ"
    },
    {
        "kod": "701001",
        "tanim": "İDARE MERKEZİ FAALİYETLERİ (İDARE MERKEZİ TARAFINDAN AYNI ŞİRKET VEYA GİRİŞİMİN DİĞER BİRİMLERİNE SAĞLANAN YÖNETİM HİZMETLERİ İLE BAĞLI İŞTİRAKLERİNİ YÖNETEN HOLDİNGLER DAHİL)"
    },
    {
        "kod": "702101",
        "tanim": "HALKLA İLİŞKİLER VE İLETİŞİM FAALİYETLERİ"
    },
    {
        "kod": "702202",
        "tanim": "İŞLETME VE DİĞER İDARİ DANIŞMANLIK FAALİYETLERİ (BİR ORGANİZASYONUN STRATEJİK, MALİ, PAZARLAMA, ÜRETİM, İŞ SÜREÇLERİ, PROJE VB. YÖNETİM HİZMETLERİ İLE TİCARİ MARKA VE İMTİYAZ KONULARINDA DANIŞMANLIK)"
    },
    {
        "kod": "702203",
        "tanim": "İNSAN KAYNAKLARI YÖNETİM DANIŞMANLIĞI FAALİYETLERİ"
    },
    {
        "kod": "711101",
        "tanim": "MİMARLIK FAALİYETLERİ VE MİMARİ DANIŞMANLIK FAALİYETLERİ"
    },
    {
        "kod": "711102",
        "tanim": "ŞEHİR VE BÖLGE PLANLAMA FAALİYETLERİ (NAZIM İMAR PLANI, VAZİYET PLANI VB. DAHİL)"
    },
    {
        "kod": "711104",
        "tanim": "PEYZAJ MİMARİSİ FAALİYETLERİ VE PEYZAJ KONUSUNDA MİMARİ DANIŞMANLIK FAALİYETLERİ"
    },
    {
        "kod": "711201",
        "tanim": "YER YÜZEYİNİN ARAŞTIRILMASI VE HARİTA YAPIMINA YÖNELİK MÜHENDİSLİK FAALİYETLERİ (JEODEZİK, FOTOGRAMETRİK VE HİDROGRAFİK ÖLÇÜM YAPMA, TOPOGRAFYA HİZMETLERİ İLE YOL, KADASTRO, TOPOĞRAFİK, VB. HARİTALARIN HAZIRLANMASI)"
    },
    {
        "kod": "711203",
        "tanim": "BİNA PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ"
    },
    {
        "kod": "711204",
        "tanim": "JEOLOJİK, JEOFİZİK VE İLGİLİ ARAŞTIRMA VE DANIŞMANLIK HİZMETLERİNE YÖNELİK MÜHENDİSLİK FAALİYETLERİ (MADEN YATAĞI, YER ALTI TOPRAK OLUŞUMU, VB. HİZMETLER) (PETROL VE DOĞALGAZ İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "711205",
        "tanim": "PETROL VE DOĞALGAZ ÇIKARIM PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ"
    },
    {
        "kod": "711206",
        "tanim": "ULAŞTIRMA PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ (KARAYOLU, KÖPRÜ, TÜNEL, DEMİR YOLLARI, HAVAALANI, PETROL VE GAZ TAŞIMACILIK PROJELERİ, LİMAN VB.)"
    },
    {
        "kod": "711207",
        "tanim": "SU, KANALİZASYON VE DRENAJ PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ (İÇME SUYU DAĞITIM SİSTEMLERİ, POMPA İSTASYONLARI, YAĞMUR SUYU YÖNETİM SİSTEMLERİ, ATIK SULARIN TOPLANMASI VB. PROJELER)"
    },
    {
        "kod": "711208",
        "tanim": "SANAYİ VE İMALAT PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ (HADDEHANELER, FARİNERİLER, ULAŞIM ARAÇLARI, SANAYİ MAKİNELERİ, VB.)"
    },
    {
        "kod": "711209",
        "tanim": "ENERJİ PROJELERİNE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ (KÖMÜR, PETROL VE GAZ GİBİ ENERJİ YAKITLARI KULLANANLAR İLE NÜKLEER, SU, GÜNEŞ, RÜZGAR VE DİĞER ENERJİLER İÇİN SANTRALLERE VE ENERJİ İLETİM VE DAĞITIM HATLARINA YÖNELİK HİZMETLER)"
    },
    {
        "kod": "711210",
        "tanim": "MÜHENDİSLİK DANIŞMANLIK HİZMETLERİ (BİR PROJEYLE BAĞLANTILI OLARAK YAPILANLAR HARİÇ)"
    },
    {
        "kod": "711211",
        "tanim": "YAPI DENETİM KURULUŞLARI (A SINIFI)"
    },
    {
        "kod": "711212",
        "tanim": "YAPI DENETİM KURULUŞLARI (B SINIFI)"
    },
    {
        "kod": "711213",
        "tanim": "YAPI DENETİM KURULUŞLARI (C SINIFI)"
    },
    {
        "kod": "711290",
        "tanim": "DİĞER PROJELERE YÖNELİK MÜHENDİSLİK VE DANIŞMANLIK FAALİYETLERİ (TELEKOMÜNİKASYON VE YAYINCILIK PROJELERİ, DOĞALGAZ VE BUHAR DAĞITIM PROJELERİ VE DİĞERLERİ İLE İNŞAAT PROJELERİNİN YÖNETİMİ DAHİL))"
    },
    {
        "kod": "712005",
        "tanim": "KARA YOLU TAŞIMA ARAÇLARININ TEKNİK MUAYENE FAALİYETLERİ (OTOMOBİL, MOTOSİKLET, OTOBÜS, PİKAP, KAMYON VE DİĞER KARA YOLU ARAÇLARININ PERİYODİK TEKNİK MUAYENE HİZMETLERİ)"
    },
    {
        "kod": "712007",
        "tanim": "BİLEŞİM VE SAFLIK KONULARINDA TEKNİK TEST VE ANALİZ FAALİYETLERİ (ATIK, YAKIT, METAL, MİNERAL VE KİMYASALLAR GİBİ MADDELERİN BİYOLOJİK VE KİMYASAL ÖZELLİKLERİ İLE MİKROBİYOLOJİ, BİYOKİMYA VB. İLGİLİ ALANLARDA TEST VE ANALİZ FAALİYETLERİ)"
    },
    {
        "kod": "712008",
        "tanim": "SU, HAVA VB. KİRLİLİĞİ KONULARINDA TEKNİK TEST VE ANALİZ FAALİYETLERİ"
    },
    {
        "kod": "712009",
        "tanim": "FİZİKSEL ÖZELLİKLER KONUSUNDA TEKNİK TEST VE ANALİZ FAALİYETLERİ (METAL, PLASTİK, TEKSTİL, BETON VE DİĞER MADDELERİN MUKAVEMETİ, ESNEKLİĞİ, İLETKENLİĞİ GİBİ FİZİKSEL ÖZELLİKLERİ İLE GERİLİM, SERTLİK, DARBE DİRENCİ VB. TEST VE ANALİZ FAALİYETLERİ)"
    },
    {
        "kod": "712010",
        "tanim": "ÜRÜNLERİN RUHSATLANDIRILMASI FAALİYETLERİ (TÜKETİM MALLARI, MOTORLU KARA TAŞITLARI, UÇAKLAR, İLAÇLAR VB.)"
    },
    {
        "kod": "712011",
        "tanim": "GIDA KONUSUNDA TEKNİK TEST VE ANALİZ FAALİYETLERİ (VETERİNER DENETİMİ DE DAHİL OLMAK ÜZERE GIDA HİJYENİ ALANINDA TEKNİK TEST FAALİYETLERİ)"
    },
    {
        "kod": "712012",
        "tanim": "ENTEGRE MEKANİK VE ELEKTRİK SİSTEMLERİ KONUSUNDA TEKNİK TEST VE ANALİZ FAALİYETLERİ (MEKANİK VE ELEKTRİK BİLEŞENLİ MAKİNE, MOTOR, OTOMOBİL, ALET, CİHAZ, İLETİŞİM EKİPMANI VB. EKİPMANLARIN TEST VE ANALİZ FAALİYETLERİ)"
    },
    {
        "kod": "712013",
        "tanim": "POLİS LABORATUVARLARININ ANALİZ FAALİYETLERİ"
    },
    {
        "kod": "712090",
        "tanim": "DİĞER TEKNİK TEST VE ANALİZ FAALİYETLERİ (MAKİNE PARÇA VE YAPILARIN KUSURLARINI BELİRLEMEK İÇİN RADYOGRAFİK, MANYETİK VE ULTRASONİK TESTLERİ, SANATSAL ÇALIŞMALARIN DOĞRULUĞUNUN KANITLANMASI, KAYNAKLARIN RADYOLOJİK MUAYENESİ VE DİĞERLERİ)"
    },
    {
        "kod": "721101",
        "tanim": "BİYOTEKNOLOJİYLE İLGİLİ ARAŞTIRMA VE DENEYSEL GELİŞTİRME FAALİYETLERİ"
    },
    {
        "kod": "721901",
        "tanim": "DOĞAL BİLİMLER VE MÜHENDİSLİKLE İLGİLİ DİĞER ARAŞTIRMA VE DENEYSEL GELİŞTİRME FAALİYETLERİ (TARIMSAL ARAŞTIRMALAR DAHİL)"
    },
    {
        "kod": "722001",
        "tanim": "SOSYAL BİLİMLERLE VE BEŞERİ BİLİMLERLE İLGİLİ ARAŞTIRMA VE DENEYSEL GELİŞTİRME FAALİYETLERİ"
    },
    {
        "kod": "731101",
        "tanim": "REKLAM AJANSLARININ FAALİYETLERİ (KULLANILACAK MEDYANIN SEÇİMİ, REKLAMIN TASARIMI, SÖZLERİN YAZILMASI, REKLAM FİLMLERİ İÇİN SENARYONUN YAZIMI, SATIŞ NOKTALARINDA REKLAM ÜRÜNLERİNİN GÖSTERİMİ VE SUNUMU VB.)"
    },
    {
        "kod": "731103",
        "tanim": "REKLAM ARAÇ VE EŞANTİYONLARIN DAĞITIMI VE TESLİMİ FAALİYETLERİ"
    },
    {
        "kod": "731202",
        "tanim": "ÇEŞİTLİ MEDYA REKLAMLARI İÇİN ALAN VE ZAMANIN BİR ÜCRET VEYA SÖZLEŞMEYE DAYALI OLARAK SATIŞI (İLAN TAHTASI, BİLLBOARD, BİNA, ARAÇ VB. ÜZERİNDEN REKLAM ALANLARININ VE ZAMANLARININ SATIŞI DAHİL)"
    },
    {
        "kod": "732003",
        "tanim": "PİYASA VE KAMUOYU ARAŞTIRMA FAALİYETLERİ (ANKET YAPMA, KAMUOYU YOKLAMALARI VB.)"
    },
    {
        "kod": "741001",
        "tanim": "İÇ MİMARLARIN FAALİYETLERİ (İÇ DEKORASYON DAHİL)"
    },
    {
        "kod": "741002",
        "tanim": "DİĞER UZMANLAŞMIŞ TASARIM FAALİYETLERİ (TEKSTİL, GİYİM, AYAKKABI GİBİ KİŞİSEL EŞYALAR VE EV EŞYALARI TASARIMI İLE ENDÜSTRİYEL TASARIM DAHİL, İÇ MİMARLARIN VE UZMANLAŞMIŞ GRAFİK TASARIMCILARIN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "741003",
        "tanim": "UZMANLAŞMIŞ GRAFİK TASARIMCILARININ FAALİYETLERİ (MARKA VE ALAMETİFARİKA TASARIMI DAHİL)"
    },
    {
        "kod": "742022",
        "tanim": "TÜKETİCİLERE YÖNELİK FOTOĞRAFÇILIK FAALİYETLERİ (PASAPORT, OKUL, DÜĞÜN VB. İÇİN VESİKALIK VE PORTRE FOTOĞRAFÇILIĞI VB.)"
    },
    {
        "kod": "742025",
        "tanim": "HAVA VE SU ALTI FOTOĞRAFÇILIĞI FAALİYETLERİ"
    },
    {
        "kod": "742026",
        "tanim": "REKLAMCILIK İLE İLGİLİ FOTOĞRAFÇILIK FAALİYETLERİ (REKLAM GÖRSELLERİ, BROŞÜR, GAZETE İLANI, KATALOG VB. İÇİN TİCARİ ÜRÜNLERİN, MODA KIYAFETLERİNİN, MAKİNELERİN, BİNALARIN, KİŞİLERİN, VB.NİN FOTOĞRAFLARININ ÇEKİLMESİ)"
    },
    {
        "kod": "742027",
        "tanim": "ETKİNLİK FOTOĞRAFÇILIĞI VE ETKİNLİKLERİN VİDEOYA ÇEKİLMESİ FAALİYETLERİ (DÜĞÜN, MEZUNİYET, KONFERANS, RESEPSİYON, MODA GÖSTERİLERİ, SPOR VE DİĞER İLGİ ÇEKİCİ OLAYLARIN FOTOĞRAFLANMASI VEYA VİDEOYA ÇEKİLMESİ)"
    },
    {
        "kod": "742028",
        "tanim": "BAĞIMSIZ FOTO MUHABİRLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "742029",
        "tanim": "FOTOĞRAF İŞLEME FAALİYETLERİ (NEGATİFLERİN TAB EDİLMESİ VE RESİMLERİN BASILMASI, NEGATİFLERİN VEYA SLAYTLARIN ÇOĞALTILMASI, FOTOGRAFİK SLAYTLARIN HAZIRLANMASI, FİLMLERİN KOPYALANMASI VB.)"
    },
    {
        "kod": "742090",
        "tanim": "DİĞER FOTOĞRAFÇILIK FAALİYETLERİ (FOTOMİKROGRAFİ, MİKROFİLM HİZMETLERİ, FOTOĞRAFLARIN RESTORASYONU VE RÖTUŞLAMA, VB.)"
    },
    {
        "kod": "743012",
        "tanim": "TERCÜME VE SÖZLÜ TERCÜME FAALİYETLERİ (YEMİNLİ TERCÜME BÜROLARI, MÜTERCİMLİK VE TERCÜMANLIK FAALİYETLERİ VB. DAHİL)"
    },
    {
        "kod": "749001",
        "tanim": "EKSPERTİZ FAALİYETLERİ (ANTİKA EŞYALAR, MÜCEVHERLER VB. İÇİN EKSPERTİZ HİZMETLERİ) (DENİZ, GAYRİMENKUL VE SİGORTA İÇİN OLAN EKSPERTİZ FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "749002",
        "tanim": "İŞYERİ KOMİSYONCULUĞU FAALİYETLERİ (KÜÇÜK VE ORTA ÖLÇEKLİ İŞLETMELERİN ALIM VE SATIMININ DÜZENLENMESİ VB.)"
    },
    {
        "kod": "749003",
        "tanim": "FATURA DENETİMİ VE NAVLUN ORANI BİLGİ FAALİYETLERİ"
    },
    {
        "kod": "749004",
        "tanim": "HAVA TAHMİNİ VE METEOROLOJİK FAALİYETLER"
    },
    {
        "kod": "749005",
        "tanim": "SANATÇI, SPORCU, ŞOVMEN, MANKEN VE DİĞERLERİ İÇİN AJANSLARIN VE MENAJERLERİN FAALİYETLERİ"
    },
    {
        "kod": "749006",
        "tanim": "GEMİ KLAS MÜESSESELERİ, DENİZ EKSPERTİZ VE DENİZ SÜRVEYÖR FAALİYETLERİ"
    },
    {
        "kod": "749090",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER MESLEKİ, BİLİMSEL VE TEKNİK FAALİYETLER (ÇEVRE DANIŞMANLIĞI, GÜVENLİK DANIŞMANLIĞI, MATEMATİKÇİLER, İSTATİSTİKÇİLER, AGRONOMLAR VB. TARAFINDAN VERİLEN DANIŞMANLIK HİZMETLERİ, PATENT ARACILIĞI VB.)"
    },
    {
        "kod": "750002",
        "tanim": "HAYVAN HASTANELERİNİN FAALİYETLERİ (EVCİL HAYVANLAR İÇİN AMBULANS FAALİYETLERİ DAHİL)"
    },
    {
        "kod": "750004",
        "tanim": "VETERİNERLİK HİZMETLERİ (HAYVAN HASTANELERİNDE VERİLEN HİZMETLER HARİÇ)"
    },
    {
        "kod": "771101",
        "tanim": "MOTORLU HAFİF KARA TAŞITLARININ VE ARABALARIN SÜRÜCÜSÜZ OLARAK KİRALANMASI VE LEASİNGİ (3.5 TONDAN DAHA AZ OLAN OTOMOBİL, KAMYONET, VB. DAHİL, MOTOSİKLET HARİÇ)"
    },
    {
        "kod": "771201",
        "tanim": "MOTORLU AĞIR KARA TAŞITLARININ SÜRÜCÜSÜZ OLARAK KİRALANMASI VE LEASİNGİ (3.5 TONDAN DAHA FAZLA OLAN KAMYON, TREYLER (RÖMORK), VB.) (KARAVAN VE TARIMSAL MAKİNE VE EKİPMANLAR İLE İNŞAAT MAKİNE VE EKİPMANLARININ KİRALANMASI VE LEASİNGİ HARİÇ)"
    },
    {
        "kod": "772101",
        "tanim": "EĞLENCE VE SPOR AMAÇLI OLARAK AT, MİDİLLİ, DEVE VB. KİRALANMASI VE LEASİNGİ"
    },
    {
        "kod": "772102",
        "tanim": "BİSİKLETLERİN KİRALANMASI VE LEASİNGİ"
    },
    {
        "kod": "772104",
        "tanim": "EĞLENCE VE SPOR AMAÇLI SANDAL, TEKNE, KANO, YELKENLİ, VB.NİN MÜRETTEBATSIZ OLARAK KİRALANMASI VE LEASİNGİ"
    },
    {
        "kod": "772190",
        "tanim": "DİĞER EĞLENCE VE SPOR EŞYALARININ KİRALANMASI VE LEASİNGİ (KAR KAYAĞI, BUZ PATENİ, PLANÖR, DELTA KANAT, SÖRF TAHTASI, SU KAYAĞI, GOLF SOPASI, KAMP MALZEMESİ, PLAJ SANDALYESİ VE ŞEMSİYESİ, SAHA OYUNLARI İÇİN MALZEME, OYUNCAK VB.)"
    },
    {
        "kod": "772201",
        "tanim": "VİDEO KASETLERİNİN, PLAKLARIN VE DİSKLERİN KİRALANMASI"
    },
    {
        "kod": "772901",
        "tanim": "GELİNLİK, KOSTÜM, TEKSTİL, GİYİM EŞYASI, AYAKKABI VE MÜCEVHERLERİN KİRALANMASI"
    },
    {
        "kod": "772902",
        "tanim": "BYS. DİĞER KİŞİSEL VE EV EŞYALARININ KİRALANMASI VE LEASİNGİ (MOBİLYA, ELEKTRİKLİ VE ELEKTRONİK ALET, KİTAP, TV, KAMERA, BİTKİ, VB. DAHİL, MÜZİK ALETİ, GİYİM EŞYASI, MÜCEVHER, VB. İLE VİDEO KASETLER, BÜRO MOBİLYALARI, EĞLENCE VE SPOR EKİPMANLARI HARİÇ)"
    },
    {
        "kod": "772903",
        "tanim": "MÜZİK ALETLERİNİN KİRALANMASI VE LEASİNGİ"
    },
    {
        "kod": "773101",
        "tanim": "TARIMSAL MAKİNE VE EKİPMANLARIN OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (TARIMSAL TRAKTÖR, PULLUK, BİÇERDÖVER, SÜT SAĞMA MAKİNESİ, ARICILIK MAKİNESİ, VB. DAHİL, ÇİM BİÇME MAKİNELERİ HARİÇ)"
    },
    {
        "kod": "773201",
        "tanim": "BİNA VE BİNA DIŞI İNŞAATLARDA KULLANILAN MAKİNE VE EKİPMANLARIN OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (VİNÇ KAMYONU, İNŞAAT VE TOPRAK TAŞIMAK İÇİN TRAKTÖR, YOL GREYDERİ VE SİLİNDİRİ, BULDOZER, YAPI İSKELESİ, ŞANTİYE KULÜBESİ, VB.) (KURMA/SÖKME HARİÇ)"
    },
    {
        "kod": "773301",
        "tanim": "BÜRO MAKİNE VE EKİPMANLARININ OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (KASA, FOTOKOPİ MAKİNESİ, DAKTİLO, YAZAR KASA, VB. DAHİL, BİLGİSAYARLAR VE ÇEVRE BİRİMLERİ, TELEFON VE FAKS MAKİNELERİ VE BÜRO MOBİLYALARI HARİÇ)"
    },
    {
        "kod": "773302",
        "tanim": "BÜRO MOBİLYALARININ KİRALANMASI VE LEASİNGİ (BÜRO SANDALYESİ VE MASASININ KİRALANMASI DAHİL)"
    },
    {
        "kod": "773303",
        "tanim": "BİLGİSAYAR VE ÇEVRE BİRİMLERİNİN OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (ELEKTRONİK VERİ İŞLEMCİ, MERKEZİ İŞLEM BİRİMİ, ÇEVRE BİRİMLERİ, MANYETİK VEYA OPTİK OKUYUCULAR, VB.)"
    },
    {
        "kod": "773401",
        "tanim": "SU YOLU TAŞIMACILIĞI EKİPMANLARININ OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (YOLCU VE YÜK TAŞIMACILIĞI İÇİN TİCARİ TEKNE VE GEMİLER DAHİL, GEZİNTİ TEKNELERİ HARİÇ)"
    },
    {
        "kod": "773501",
        "tanim": "HAVA TAŞIMACILIĞI ARAÇLARININ OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (UÇAK, HELİKOPTER, BALON, VB.)"
    },
    {
        "kod": "773901",
        "tanim": "DEMİR YOLU ULAŞIM EKİPMANLARININ OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (LOKOMOTİF VE DİĞER VAGONLAR, METRO VAGONLARI, HAFİF DEMİR YOLU EKİPMANLARI, TRAMVAY, VB.)"
    },
    {
        "kod": "773902",
        "tanim": "KONTEYNERLERİN KİRALANMASI VEYA LEASİNGİ (KONAKLAMA VE BÜRO AMAÇLI OLANLAR, BİRDEN ÇOK TAŞIMA TÜRLERİNE UYGUN OLANLAR VE DİĞERLERİ)"
    },
    {
        "kod": "773903",
        "tanim": "MOTOSİKLET, KARAVAN VE KAMP GEREÇLERİNİN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ"
    },
    {
        "kod": "773904",
        "tanim": "MADEN VE PETROL SAHASINDA KULLANILAN EKİPMANLARIN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ"
    },
    {
        "kod": "773905",
        "tanim": "MOTORLAR VE TÜRBİNLERİN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ"
    },
    {
        "kod": "773906",
        "tanim": "MESLEKİ VE BİLİMSEL AMAÇLI ÖLÇÜM VE KONTROL EKİPMANLARININ OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ (TIBBİ CİHAZ VE EKİPMANLARIN KİRALANMASI DAHİL)"
    },
    {
        "kod": "773907",
        "tanim": "TİCARİ RADYO, TELEVİZYON VE TELEKOMÜNİKASYON EKİPMANLARI, SİNEMA FİLMİ YAPIM EKİPMANLARI, TELEFON, FAKS MAKİNESİ, ÇAĞRI CİHAZI VE HÜCRESEL TELEFONLARIN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ (KİŞİSEL VE EV EŞYASI OLAN TV, RADYO, KAMERALAR HARİÇ)"
    },
    {
        "kod": "773908",
        "tanim": "MADENİ PARA İLE ÇALIŞAN KUMAR MAKİNELERİNİN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ"
    },
    {
        "kod": "773910",
        "tanim": "TAKIM TEZGAHLARININ VE DİĞER TİCARİ VE ENDÜSTRİYEL MAKİNELERİN OPERATÖRSÜZ OLARAK KİRALANMASI VEYA LEASİNGİ"
    },
    {
        "kod": "773911",
        "tanim": "TİYATRO DEKOR VE MALZEMELERİNİN KİRALANMASI (KOSTÜMLER HARİÇ)"
    },
    {
        "kod": "773913",
        "tanim": "HAYVANLARIN KİRALANMASI FAALİYETLERİ (HAYVAN SÜRÜLERİ, YARIŞ ATLARI VB.) (EĞLENCE VE SPOR AMAÇLI OLANLAR HARİÇ)"
    },
    {
        "kod": "773990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ GENELLİKLE ENDÜSTRİDE SERMAYE MALI OLARAK KULLANILAN DİĞER MAKİNE, EKİPMAN VE EŞYALARIN OPERATÖRSÜZ OLARAK KİRALANMASI VE LEASİNGİ (SERGİ MALZEMESİ, PALET, VB. DAHİL, KİŞİSEL EŞYALAR VE EV EŞYALARI HARİÇ)"
    },
    {
        "kod": "774001",
        "tanim": "FİKRİ MÜLKİYET HAKLARININ VE BENZER ÜRÜNLERİN LEASİNGİ (PATENTLİ VARLIKLAR, MARKALAR, İMTİYAZ SÖZLEŞMELERİ, VB. DAHİL, TELİF HAKKI ALINMIŞ OLAN ÇALIŞMALAR HARİÇ)"
    },
    {
        "kod": "781001",
        "tanim": "İŞ BULMA ACENTELERİNİN FAALİYETLERİ (İŞE GİRECEK KİŞİLERİN SEÇİMİ VE YERLEŞTİRİLMESİ FAALİYETLERİ DAHİL)"
    },
    {
        "kod": "781004",
        "tanim": "OYUNCU SEÇME AJANSLARI VE BÜROLARININ FAALİYETLERİ (TİYATRO ROL DAĞITIM AJANSLARI VB.)"
    },
    {
        "kod": "782001",
        "tanim": "GEÇİCİ İŞ BULMA ACENTELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "783003",
        "tanim": "DİĞER İNSAN KAYNAKLARININ SAĞLANMASI (UZUN SÜRELİ ÇALIŞMA DÖNEMLERİ İÇİN PERSONEL SAĞLANMASI HİZMETLERİ)"
    },
    {
        "kod": "791101",
        "tanim": "SEYAHAT ACENTESİ FAALİYETLERİ (HAVA YOLU, DENİZ YOLU, KARA YOLU, DEMİR YOLU ULAŞIMI İÇİN BİLET REZERVASYON İŞLEMLERİ VE BİLET SATIŞI, SEYAHAT, TUR, ULAŞIM VE KONAKLAMA HİZMETLERİNİN TOPTAN VEYA PERAKENDE SATIŞI, VB.)"
    },
    {
        "kod": "791201",
        "tanim": "TUR OPERATÖRÜ FAALİYETLERİ (TURLARIN DÜZENLENMESİ)"
    },
    {
        "kod": "799001",
        "tanim": "TURİST REHBERLİĞİ VE ZİYARETÇİLER İÇİN DANIŞMANLIK FAALİYETLERİ (GEZİLERLE İLGİLİ BİLGİ SAĞLANMASI)"
    },
    {
        "kod": "799002",
        "tanim": "SPOR, MÜZİK, TİYATRO VE DİĞER EĞLENCE ETKİNLİKLERİ İÇİN YER AYIRMA (REZERVASYON) VE BİLET SATILMASI FAALİYETİ"
    },
    {
        "kod": "799090",
        "tanim": "BYS. DİĞER REZERVASYON HİZMETLERİ VE İLGİLİ FAALİYETLER (DEVRE MÜLK TAKAS FAALİYETLERİ, TURİZMİ ARTTIRMA FAALİYETLERİ, VB. DAHİL, SEYAHAT ACENTELERİNİN VE TUR OPERATÖRLERİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "801005",
        "tanim": "ÖZEL GÜVENLİK FAALİYETLERİ (ŞİRKETLERCE ZIRHLI ARAÇ SAĞLAMA, PARA, VB. DEĞERLİ ŞEYLERİN TOPLANMASI VE DAĞITIMI, KORUMA VE DEVRİYE, ARAÇ PARK KONTROL, KORUMA KÖPEĞİ EĞİTİMİ, PARMAK İZİ TESPİTİ VB. DAHİL, KAMU GÜVENLİĞİ HARİÇ)"
    },
    {
        "kod": "802001",
        "tanim": "GÜVENLİK SİSTEMLERİ HİZMET FAALİYETLERİ (HIRSIZ VE YANGIN ALARMI, ELEKTRONİK KASA GİBİ GÜVENLİK SİSTEMLERİNİN KONTROLÜ, KURULUMU, BAKIMI, ALINAN ALARM SİNYALİ İLE SİSTEMİN DOĞRULANMASI VE POLİS, İTFAİYE GİBİ BİRİMLERİN HAREKETE GEÇİRİLMESİ)"
    },
    {
        "kod": "803004",
        "tanim": "SORUŞTURMA FAALİYETLERİ (ÖZEL DEDEKTİFLİK FAALİYETLERİ DAHİL)"
    },
    {
        "kod": "803005",
        "tanim": "İMZA VE EL YAZISI TESPİT FAALİYETLERİ"
    },
    {
        "kod": "811001",
        "tanim": "TESİS BÜNYESİNDEKİ KOMBİNE DESTEK HİZMETLERİ (İŞLETME VEYA TESİS BÜNYESİNDE TEMİZLİK, BAKIM, ÇÖPLERİN BERTARAFI, KORUMA VE GÜVENLİK, POSTA DAĞITIMI, ÇAMAŞIRHANE, RESEPSİYON VB. YARDIMCI HİZMET VE GÖREVLERİN BİRDEN FAZLASININ SAĞLANMASI)"
    },
    {
        "kod": "812101",
        "tanim": "BİNALARIN GENEL TEMİZLİĞİ (DAİRE, APARTMAN, BÜRO, FABRİKA, KURUM, MAĞAZA VB. HER TÜRLÜ BİNANIN GENEL TEMİZLİĞİ DAHİL, PENCERE, BACA, SANAYİ MAKİNESİ, VB. UZMANLAŞMIŞ TEMİZLİK FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "812201",
        "tanim": "DİĞER BİNA VE ENDÜSTRİYEL TEMİZLİK FAALİYETLERİ (BİNALARIN DIŞI, PENCERE, BACA, FIRIN, KALORİFER KAZANI, HAVALANDIRMA KANALI, EGZOZ ÜNİTESİ, SANAYİ MAKİNESİ TEMİZLİĞİ VB. UZMANLAŞMIŞ TEMİZLİK FAALİYETLERİ)"
    },
    {
        "kod": "812902",
        "tanim": "YOL VE PİSTLERDEKİ KAR VE BUZUN KALDIRILMASI (KUM, TUZ DÖKÜLMESİ DAHİL)"
    },
    {
        "kod": "812903",
        "tanim": "PARK VE CADDELERİN SÜPÜRÜLEREK YIKANMASI, TEMİZLENMESİ FAALİYETLERİ"
    },
    {
        "kod": "812904",
        "tanim": "BÖCEKLERİN, KEMİRGENLERİN VE DİĞER ZARARLILARIN İMHASI VE HAŞERE KONTROL FAALİYETLERİ (TARIMSAL ZARARLILARLA MÜCADELE HARİÇ)"
    },
    {
        "kod": "812990",
        "tanim": "DİĞER TEMİZLİK FAALİYETLERİ (YÜZME HAVUZLARI, TREN, OTOBÜS, UÇAK, TANKER, PLAJ VE ŞİŞELERİN TEMİZLENMESİ İLE DEZENFEKTE FAALİYETLERİ DAHİL, OTO YIKAMA HARİÇ)"
    },
    {
        "kod": "813001",
        "tanim": "GÜRÜLTÜ, RÜZGAR, EROZYON, YANSIMA, VB.NE KARŞI KORUYUCU BİTKİLERİN DİKİMİ VE BAKIMI"
    },
    {
        "kod": "813005",
        "tanim": "SPOR ALANLARI (FUTBOL SAHALARI, GOLF ALANLARI GİBİ), OYUN ALANLARI, GÜNEŞ BANYOSU İÇİN ÇİMENLER VE DİĞER EĞLENCE PARKLARI İÇİN YEŞİL ALANLARIN DİKİMİ VE BAKIMI FAALİYETLERİ"
    },
    {
        "kod": "813090",
        "tanim": "DİĞER ÇEVRE DÜZENLEMESİ VE BAKIMI İLE PEYZAJ PROJELERİNİN UYGULANMASI FAALİYETLERİ (PARK, BAHÇE VE YEŞİL ALANLARIN DİKİMİ, BAKIM VE ONARIMI)"
    },
    {
        "kod": "821101",
        "tanim": "KOMBİNE BÜRO YÖNETİM HİZMETİ FAALİYETLERİ (BİR ÜCRET VEYA SÖZLEŞME TEMELİNDE SEKRETERLİK, FİNANSAL PLANLAMA, FATURALAMA VE KAYIT TUTULMASI, PERSONEL VE POSTA VB. HİZMETLERİN KOMBİNASYONUNUN SAĞLANMASI)"
    },
    {
        "kod": "821901",
        "tanim": "FOTOKOPİ, DOKÜMAN HAZIRLAMA VE DİĞER UZMANLAŞMIŞ BÜRO DESTEK HİZMETLERİ (DOKÜMAN HAZIRLAMA, DAKTİLO, SEKRETERYA, FOTOKOPİ, OZALİT ÇEKİMİ, MEKTUP, ÇOĞALTMA VB.) (ADRES DERLEME VE POSTALAMA HİZMETLERİ DAHİL, TEZ YAZIMI HARİÇ)"
    },
    {
        "kod": "821903",
        "tanim": "TEZ VB. YAZIM BÜROLARININ FAALİYETLERİ"
    },
    {
        "kod": "822001",
        "tanim": "ÇAĞRI MERKEZLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "823002",
        "tanim": "GÖSTERİ, KONGRE, KONFERANS, TİCARİ FUAR, VB. ETKİNLİKLERİN ORGANİZASYONU FAALİYETLERİ"
    },
    {
        "kod": "829101",
        "tanim": "TAHSİLAT BÜROLARI VE KREDİ KAYIT BÜROLARININ FAALİYETLERİ (TELEFON, ELEKTRİK, SU, VB. FATURA VE BORÇ TOPLAMA, KİŞİLERİN VEYA İŞLETMELERİN KREDİ VEYA ÇALIŞMA GEÇMİŞLERİ HAKKINDA BİLGİ TOPLAMA, VB.)"
    },
    {
        "kod": "829201",
        "tanim": "TEHLİKESİZ ÜRÜNLERİ PAKETLEME FAALİYETLERİ (BİR ÜCRET VEYA SÖZLEŞME TEMELİNDE YİYECEK, İÇECEK DAHİL SIVILARIN ŞİŞELENMESİ, KATI MADDELERİN PAKETLENMESİ, ETİKETLEME, DAMGALAMA, MARKA BASMA, PAKET AMBALAJLAMA, VB.)"
    },
    {
        "kod": "829205",
        "tanim": "TEHLİKELİ ÜRÜNLERİ PAKETLEME FAALİYETLERİ (BİR ÜCRET VEYA SÖZLEŞME TEMELİNDE SIVILARIN ŞİŞELENMESİ, KATI MADDELERİN PAKETLENMESİ, ETİKETLEME, DAMGALAMA, MARKA BASMA, PAKET AMBALAJLAMA, VB.)"
    },
    {
        "kod": "829902",
        "tanim": "ELEKTRİK, GAZ, SU VE ISINMA SAYAÇLARINI OKUMA VE FATURALAMA FAALİYETLERİ"
    },
    {
        "kod": "829904",
        "tanim": "TRAFİK MÜŞAVİRLİĞİ"
    },
    {
        "kod": "829905",
        "tanim": "HARFİ HARFİNE RAPORLAMA VE STENOGRAFİ KAYIT FAALİYETLERİ"
    },
    {
        "kod": "829906",
        "tanim": "TELEFONA DAYALI DESTEK FAALİYETLERİ (TELEFON CEVAPLAMA VE UYANDIRMA HİZMETLERİ)"
    },
    {
        "kod": "829907",
        "tanim": "BARKODLAMA FAALİYETLERİ (PAKETLEME FAALİYETİ DIŞINDAKİLER)"
    },
    {
        "kod": "829908",
        "tanim": "İŞ TAKİPÇİLİĞİ FAALİYETİ"
    },
    {
        "kod": "829990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER İŞ DESTEK HİZMET FAALİYETLERİ (BORCU ÖDENMEYEN MALLARIN GERİ ALINMASI, İNDİRİM KUPONU DAĞITIM HİZMETLERİ, PARK SAYACINDAN PARALARIN TOPLANMASI VE DİĞER İŞ DESTEK HİZMETLERİ)"
    },
    {
        "kod": "841141",
        "tanim": "BELEDİYELERİN KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841142",
        "tanim": "EKONOMİK VE SOSYAL PLANLAMA İLE İSTATİSTİK İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841143",
        "tanim": "FİNANSAL, MALİ VE DENETİM İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ (DEFTERDARLIK, MAL MÜDÜRLÜKLERİ, VERGİ DAİRELERİ, SAYIŞTAY, KAMU BORÇ VE FONLARININ YÖNETİMİ DAHİL)"
    },
    {
        "kod": "841144",
        "tanim": "GENEL PERSONEL İŞLERİ İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841145",
        "tanim": "GÜMRÜKLERLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841146",
        "tanim": "MUHTARLARIN FAALİYETLERİ"
    },
    {
        "kod": "841147",
        "tanim": "VALİLİKLERİN VE KAYMAKAMLIKLARIN KAMU YÖNETİMİ HİZMETLERİ (İL VE İLÇE ÖZEL İDARELERİNİN FAALİYETLERİ DAHİL)"
    },
    {
        "kod": "841148",
        "tanim": "YASAMA VE YÜRÜTME HİZMETLERİ"
    },
    {
        "kod": "841190",
        "tanim": "KAMU İÇİN DİĞER DESTEKLEYİCİ KAMU YÖNETİMİ HİZMETLERİ (MERKEZİ KAMU İHALE VE TEDARİK HİZMETLERİ İLE HARİTACILIK VB.)"
    },
    {
        "kod": "841211",
        "tanim": "EĞİTİME İLİŞKİN KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841212",
        "tanim": "İSKAN VE TOPLUM REFAHINA İLİŞKİN KAMU YÖNETİMİ HİZMETLERİ (SU TEMİNİ VE ÇEVRE KORUMA PROGRAMLARI DAHİL)"
    },
    {
        "kod": "841213",
        "tanim": "SAĞLIĞA VE SOSYAL HİZMETLERE İLİŞKİN KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841214",
        "tanim": "SPOR, DİNLENCE, KÜLTÜR VE DİNE İLİŞKİN KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841311",
        "tanim": "ÇOK AMAÇLI GELİŞTİRME PROJELERİ İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ (BÖLGESEL KALKINMA PROJELERİ DAHİL)"
    },
    {
        "kod": "841312",
        "tanim": "GENEL EKONOMİK, TİCARİ VE İŞGÜCÜ İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ (GENEL EKONOMİ POLİTİKALARININ OLUŞTURULMASI, TEŞVİK FAALİYETLERİ, PATENT İŞLERİ, GENEL İSTİHDAM POLİTİKALARI, METEOROLOJİ İŞLERİ, İSTİHDAM VB.)"
    },
    {
        "kod": "841313",
        "tanim": "MADENCİLİK, DOĞAL KAYNAKLAR, İMALAT VE İNŞAAT İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841314",
        "tanim": "TARIM, ORMANCILIK, BALIKÇILIK VE AVCILIKLA İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841315",
        "tanim": "TİCARET, OTELCİLİK VE LOKANTACILIK İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841316",
        "tanim": "TURİZM İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841317",
        "tanim": "ULAŞTIRMA VE İLETİŞİM İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ"
    },
    {
        "kod": "841318",
        "tanim": "YAKIT VE ENERJİ İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ (ENERJİ BAKANLIĞI, VB.)"
    },
    {
        "kod": "842105",
        "tanim": "DIŞ İŞLERİ İLE İLGİLİ KAMU YÖNETİMİ HİZMETLERİ (YURT DIŞI DİPLOMATİK HİZMETLER VE KONSOLOSLUK HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "842106",
        "tanim": "YURT DIŞI DİPLOMATİK HİZMETLER VE KONSOLOSLUK HİZMETLERİ (YABANCI KONSOLOSLUKLAR HARİÇ)"
    },
    {
        "kod": "842205",
        "tanim": "ASKERİ SAVUNMA HİZMETLERİ (SİLAHLI KUVVETLER VE SAVUNMA İLE İLGİLİ İDARİ HİZMETLER)"
    },
    {
        "kod": "842206",
        "tanim": "SİVİL SAVUNMA HİZMETLERİ"
    },
    {
        "kod": "842304",
        "tanim": "ADALET VE YARGI ORGANLARININ FAALİYETLERİ (İCRA MÜDÜRLÜKLERİ VB. DAHİL, CEZA İNFAZ KURUMLARININ VE MAHKEMELERİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "842305",
        "tanim": "CEZA İNFAZ VE TUTUK EVLERİNİN FAALİYETLERİ (EĞİTİM VE REHABİLİTASYON FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "842306",
        "tanim": "MAHKEMELERİN FAALİYETLERİ (YÜKSEK YARGI ORGANLARI DAHİL)"
    },
    {
        "kod": "842401",
        "tanim": "KAMU DÜZENİ VE GÜVENLİĞİ İLE İLGİLİ FAALİYETLER (POLİS HİZMETLERİ, SAHİL GÜVENLİK VB.)"
    },
    {
        "kod": "842501",
        "tanim": "İTFAİYE HİZMETLERİ (HAVA TAŞITLARIYLA YAPILANLAR İLE ORMAN YANGINLARIYLA MÜCADELE VE KORUMA FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "842502",
        "tanim": "HAVA TAŞITLARI YOLUYLA YAPILAN İTFAİYE HİZMETLERİ (ORMAN YANGINLARIYLA MÜCADELE VE KORUMA FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "843001",
        "tanim": "ZORUNLU SOSYAL GÜVENLİK FAALİYETLERİ"
    },
    {
        "kod": "851001",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN OKUL ÖNCESİ EĞİTİM FAALİYETİ (OKULA YÖNELİK EĞİTİM VERİLMEYEN GÜNDÜZ BAKIM (KREŞ) FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "851002",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN OKUL ÖNCESİ EĞİTİM FAALİYETİ (OKULA YÖNELİK EĞİTİM VERİLMEYEN GÜNDÜZ BAKIM (KREŞ) FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "852006",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK İLKÖĞRETİM FAALİYETİ"
    },
    {
        "kod": "852007",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN İLKÖĞRETİM FAALİYETİ (YETİŞKİNLERE YÖNELİK OKUMA YAZMA PROGRAMLARININ VERİLMESİ DAHİL, ENGELLİLER İÇİN VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "852008",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK İLKÖĞRETİM FAALİYETİ"
    },
    {
        "kod": "852009",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN İLKÖĞRETİM FAALİYETİ (YETİŞKİNLERE YÖNELİK OKUMA YAZMA PROGRAMLARININ VERİLMESİ DAHİL, ENGELLİLER İÇİN VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "853112",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN GENEL ORTAÖĞRETİM (LİSE) FAALİYETİ (ENGELLİLERE YÖNELİK VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "853113",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK GENEL ORTAÖĞRETİM (LİSE) FAALİYETİ"
    },
    {
        "kod": "853114",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN GENEL ORTAÖĞRETİM (LİSE) FAALİYETİ (ENGELLİLERE YÖNELİK VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "853116",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK GENEL ORTAÖĞRETİM (LİSE) FAALİYETİ"
    },
    {
        "kod": "853210",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK TEKNİK VE MESLEKİ ORTAÖĞRETİM (LİSE) FAALİYETİ"
    },
    {
        "kod": "853211",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN TEKNİK VE MESLEKİ ORTAÖĞRETİM (LİSE) FAALİYETİ (ENGELLİLERE YÖNELİK VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "853212",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN FİZİKSEL VEYA ZİHİNSEL ENGELLİLERE YÖNELİK TEKNİK VE MESLEKİ ORTAÖĞRETİM (LİSE) FAALİYETİ"
    },
    {
        "kod": "853213",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN TEKNİK VE MESLEKİ ORTAÖĞRETİM (LİSE) FAALİYETİ (ENGELLİLERE YÖNELİK VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "853214",
        "tanim": "ÇIRAKLIK EĞİTİMİ"
    },
    {
        "kod": "853215",
        "tanim": "TİCARİ SERTİFİKA VEREN HAVACILIK, YELKENCİLİK, GEMİCİLİK, VB. KURSLARIN FAALİYETLERİ"
    },
    {
        "kod": "853216",
        "tanim": "TİCARİ TAŞIT KULLANMA BELGESİ VEREN SÜRÜCÜ KURSLARININ FAALİYETLERİ"
    },
    {
        "kod": "853290",
        "tanim": "MESLEKİ AMAÇLI EĞİTİM VEREN DİĞER KURSLARIN FAALİYETLERİ"
    },
    {
        "kod": "854101",
        "tanim": "ORTAÖĞRETİM SONRASI YÜKSEKÖĞRETİM DERECESİNDE OLMAYAN EĞİTİM FAALİYETİ"
    },
    {
        "kod": "854201",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN YÜKSEKÖĞRETİM FAALİYETİ (YÜKSEKÖĞRETİM DÜZEYİNDE EĞİTİM SAĞLAYAN KONSERVATUARLAR DAHİL)"
    },
    {
        "kod": "854203",
        "tanim": "ÖZEL ÖĞRETİM KURUMLARI TARAFINDAN VERİLEN YÜKSEKÖĞRETİM FAALİYETİ (YÜKSEKÖĞRETİM DÜZEYİNDE EĞİTİM SAĞLAYAN KONSERVATUARLAR DAHİL)"
    },
    {
        "kod": "855103",
        "tanim": "SPOR VE EĞLENCE EĞİTİM KURSLARI (FUTBOL, DÖVÜŞ SANATLARI, JİMNASTİK, BİNİCİLİK, YÜZME, DALGIÇLIK, PARAŞÜT, BRİÇ, YOGA, VB. EĞİTİMİ İLE PROFESYONEL SPOR EĞİTİMCİLERİNİN FAALİYETLERİ DAHİL, TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "855205",
        "tanim": "KÜLTÜREL EĞİTİM VEREN KURSLARIN FAALİYETİ (BALE, DANS, MÜZİK, FOTOĞRAF, HALK OYUNU, RESİM, DRAMA, VB. EĞİTİMİ DAHİL, TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "855301",
        "tanim": "SÜRÜCÜ KURSU FAALİYETLERİ (TİCARİ SERTİFİKA VEREN SÜRÜCÜLÜK, HAVACILIK, YELKENCİLİK, GEMİCİLİK EĞİTİMİ HARİÇ)"
    },
    {
        "kod": "855901",
        "tanim": "HALK EĞİTİM MERKEZLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "855903",
        "tanim": "BİLGİSAYAR, YAZILIM, VERİTABANI, VB. EĞİTİMİ VEREN KURSLARIN FAALİYETLERİ (TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "855905",
        "tanim": "ORTA ÖĞRETİME, YÜKSEK ÖĞRETİME, KAMU PERSONELİ, VB. SINAVLARA YÖNELİK YARDIMCI DERSLER VEREN DERSHANELERİN FAALİYETLERİ"
    },
    {
        "kod": "855906",
        "tanim": "BİÇKİ, DİKİŞ, NAKIŞ, HALICILIK, GÜZELLİK, BERBERLİK, KUAFÖRLÜK KURSLARININ FAALİYETLERİ"
    },
    {
        "kod": "855908",
        "tanim": "KURAN KURSLARI VE DİĞER DİNİ EĞİTİM VEREN YERLERİN FAALİYETLERİ (TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "855909",
        "tanim": "DİL VE KONUŞMA BECERİLERİ EĞİTİMİ VEREN KURSLARIN FAALİYETLERİ (TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "855910",
        "tanim": "MANKENLİK, MODELİSTLİK, STİLİSTLİK KURSLARININ FAALİYETLERİ"
    },
    {
        "kod": "855912",
        "tanim": "MUHASEBE EĞİTİMİ KURSLARININ FAALİYETİ"
    },
    {
        "kod": "855915",
        "tanim": "AKADEMİK ÖZEL DERS VERME FAALİYETİ (TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE BİRE BİR EĞİTİM)"
    },
    {
        "kod": "855990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER EĞİTİM KURSU FAALİYETLERİ (CANKURTARANLIK, HAYATTA KALMA, TOPLULUĞA KONUŞMA, HIZLI OKUMA, VB. EĞİTİMİ DAHİL, YETİŞKİN OKUMA YAZMA PROGRAMLARI İLE TEMEL, ORTA VE YÜKSEKÖĞRETİM DÜZEYİNDE VERİLEN EĞİTİM HARİÇ)"
    },
    {
        "kod": "856002",
        "tanim": "EĞİTİMİ DESTEKLEYİCİ FAALİYETLER (EĞİTİM REHBERLİK, DANIŞMANLIK, TEST DEĞERLENDİRME, ÖĞRENCİ DEĞİŞİM PROGRAMLARININ ORGANİZASYONU, YAPRAK TEST VE SORU BANKASI HAZIRLAMA GİBİ EĞİTİMİ DESTEKLEYEN ÖĞRENİM DIŞI FAALİYETLER)"
    },
    {
        "kod": "861004",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN İNSAN SAĞLIĞINA YÖNELİK ÖZEL İHTİSAS GEREKTİREN YATAKLI HASTANE HİZMETLERİ (KADIN DOĞUM, ONKOLOJİ, KEMİK, RUH VE SİNİR HASTALIKLARI HASTANELERİ, VB.)"
    },
    {
        "kod": "861005",
        "tanim": "KAMU KURUMLARI TARAFINDAN VERİLEN İNSAN SAĞLIĞINA YÖNELİK YATAKLI HASTANE HİZMETLERİ (DEVLET ÜNİVERSİTE HASTANELERİ DAHİL, ÖZEL İHTİSAS HASTANELERİ İLE DİŞÇİLİK, AMBULANSLA TAŞIMA, TIBBİ LABORATUVAR TEST FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "861012",
        "tanim": "ÖZEL SAĞLIK KURUMLARI TARAFINDAN VERİLEN İNSAN SAĞLIĞINA YÖNELİK ÖZEL İHTİSAS GEREKTİREN YATAKLI HASTANE HİZMETLERİ (KADIN DOĞUM, ONKOLOJİ, KEMİK, RUH VE SİNİR HASTALIKLARI HASTANELERİ, VB.)"
    },
    {
        "kod": "861013",
        "tanim": "ÖZEL SAĞLIK KURUMLARI TARAFINDAN VERİLEN İNSAN SAĞLIĞINA YÖNELİK YATAKLI HASTANE HİZMETLERİ (ÖZEL VEYA VAKIF ÜNİVERSİTE HASTANELERİ DAHİL, DİŞÇİLİK, AMBULANSLA TAŞIMA, TIBBİ LABORATUVAR TESTLERİ FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862102",
        "tanim": "AİLE VE TOPLUM SAĞLIĞI MERKEZLERİ TARAFINDAN SAĞLANAN YATILI OLMAYAN GENEL HEKİMLİK UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE EBELER, HEMŞİRELER VE FİZYOTERAPİSTLERCE GERÇEKLEŞTİRİLEN PARAMEDİKAL FAALİYETLER HARİÇ)"
    },
    {
        "kod": "862103",
        "tanim": "ÖZEL SAĞLIK KURUMLARI TARAFINDAN POLİKLİNİKLERDE SAĞLANAN YATILI OLMAYAN GENEL HEKİMLİK UYGULAMA FAALİYETLERİ (ÖZEL MUAYENE VE YATILI HASTANE FAALİYETLERİ İLE EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862104",
        "tanim": "ÖZEL MUAYENEHANELERDE SAĞLANAN YATILI OLMAYAN GENEL HEKİMLİK UYGULAMA FAALİYETLERİ (HASTANE VE POLİKLİNİK FAALİYETLERİ İLE EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862190",
        "tanim": "DİĞER YATILI OLMAYAN GENEL HEKİMLİK UYGULAMA FAALİYETLERİ (EV, İŞ YERİ, OKUL VB. YERLERDE SAĞLANANLAR DAHİL, EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862202",
        "tanim": "ÖZEL MUAYENEHANELERDE SAĞLANAN UZMAN HEKİMLİK İLE İLGİLİ YATILI OLMAYAN UYGULAMA FAALİYETLERİ (HASTANE VE POLİKLİNİK FAALİYETLERİ İLE EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862205",
        "tanim": "ÖZEL SAĞLIK KURUMLARI TARAFINDAN POLİKLİNİK VE YATILI OLMAYAN TIP MERKEZLERİNDE SAĞLANAN UZMAN HEKİMLİK İLE İLGİLİ UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862206",
        "tanim": "AİLE VE TOPLUM SAĞLIĞI MERKEZLERİ TARAFINDAN SAĞLANAN YATILI OLMAYAN UZMAN HEKİMLİK UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862207",
        "tanim": "DİYALİZ MERKEZLERİ (HASTANE DIŞI)"
    },
    {
        "kod": "862290",
        "tanim": "DİĞER YATILI OLMAYAN UZMAN HEKİMLİK UYGULAMA FAALİYETLERİ (EV, İŞ YERİ, OKUL VB. YERLERDE SAĞLANANLAR DAHİL, EBE, HEMŞİRE VE FİZYOTERAPİSTLERİN PARAMEDİKAL FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862301",
        "tanim": "ÖZEL SAĞLIK KURUMLARI TARAFINDAN SAĞLANAN DİŞ HEKİMLİĞİ UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE DİŞ HİJYENİSTLERİ GİBİ PARAMEDİKAL DİŞ SAĞLIĞI PERSONELİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862303",
        "tanim": "ÖZEL MUAYENEHANELERDE SAĞLANAN DİŞ HEKİMLİĞİ UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE DİŞ HİJYENİSTLERİ GİBİ PARAMEDİKAL DİŞ SAĞLIĞI PERSONELİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "862305",
        "tanim": "KAMU KURUMLARI TARAFINDAN SAĞLANAN DİŞ HEKİMLİĞİ UYGULAMA FAALİYETLERİ (YATILI HASTANE FAALİYETLERİ İLE DİŞ HİJYENİSTLERİ GİBİ PARAMEDİKAL DİŞ SAĞLIĞI PERSONELİNİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "869001",
        "tanim": "HEMŞİRELİK HİZMETLERİ (EVDEKİ HASTALAR İÇİN BAKIM, KORUMA, ANNE BAKIMI, ÇOCUK SAĞLIĞI VE HEMŞİRELİK BAKIMI ALANINDAKİ BENZERİ HİZMETLER DAHİL, HEMŞİRELİ YATILI BAKIM TESİSLERİNİN FAALİYETLERİ İLE TIP DOKTORLARININ HİZMETLERİ HARİÇ) (HASTANE DIŞI)"
    },
    {
        "kod": "869003",
        "tanim": "TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN AKUPUNKTURLA TEDAVİ FAALİYETİ (HASTANE DIŞI)"
    },
    {
        "kod": "869004",
        "tanim": "AMBULANSLA HASTA TAŞIMA FAALİYETİ (HASTANE DIŞI)"
    },
    {
        "kod": "869005",
        "tanim": "EBE, SAĞLIK MEMURU, SÜNNETÇİ, İĞNECİ, PANSUMANCI VB.LERİ TARAFINDAN VERİLEN HİZMETLER (TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN GEBELİK SÜRESİNCE VE DOĞUM SONRASI İZLEME VE TIBBİ İŞLEMLERİ KAPSAYAN AİLE PLANLAMASI HİZMETLERİ DAHİL) (HASTANE DIŞI)"
    },
    {
        "kod": "869006",
        "tanim": "FİZYOTERAPİ HİZMETLERİ (TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN FİZYOTERAPİ, ERGOTERAPİ VB. ALANLARDAKİ HİZMETLER) (HASTANE DIŞI)"
    },
    {
        "kod": "869007",
        "tanim": "ANALİZ VEYA RAPORLAMA OLMAKSIZIN TEŞHİS AMAÇLI GÖRÜNTÜLEME HİZMETLERİ (TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN RÖNTGEN, ULTRASON, MANYETİK REZONANS (MR) VB. GÖRÜNTÜLEME HİZMETLERİ) (HASTANE DIŞI)"
    },
    {
        "kod": "869009",
        "tanim": "KAN, SPERM VE ORGAN BANKALARININ FAALİYETLERİ (HASTANE DIŞI)"
    },
    {
        "kod": "869010",
        "tanim": "TIBBİ LABORATUVARLARIN HİZMETLERİ (ADLİ TIP VE DİŞ LABORATUVARLARININ FAALİYETLERİ HARİÇ) (HASTANE DIŞI)"
    },
    {
        "kod": "869014",
        "tanim": "TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN AKIL SAĞLIĞI HİZMETLERİ (PSİKOANALİSTLER, PSİKOLOGLAR VE PSİKOTERAPİSTLER TARAFINDAN SAĞLANAN HİZMETLER) (HASTANE DIŞI)"
    },
    {
        "kod": "869016",
        "tanim": "ADLİ TIP LABORATUVARLARININ FAALİYETLERİ"
    },
    {
        "kod": "869090",
        "tanim": "BYS. DİĞER PARAMEDİKAL İNSAN SAĞLIĞI HİZMETLERİ (TIP DOKTORLARI DIŞINDA YETKİLİ KİŞİLERCE SAĞLANAN MESLEKİ TERAPİ, AROMA TERAPİ, KONUŞMA TERAPİSİ, HOMEOPATİ, BESİN TEDAVİSİ, AYAK BAKIMI, DİŞ HİJYENİ VB. HİZMETLER) (HASTANE DIŞI)"
    },
    {
        "kod": "871001",
        "tanim": "HEMŞİRELİ YATILI BAKIM FAALİYETLERİ (HEMŞİRELİ BAKIM EVLERİNİN, HEMŞİRELİ HUZUR EVLERİNİN FAALİYETLERİ DAHİL, SADECE ASGARİ DÜZEYDE HEMŞİRE BAKIMI SAĞLANAN YAŞLI EVLERİNİN, YETİMHANELERİN, YURTLARIN FAALİYETLERİ İLE EVLERDE SAĞLANAN HİZMETLER HARİÇ)"
    },
    {
        "kod": "872002",
        "tanim": "ZİHİNSEL ENGELLİLİK, RUH SAĞLIĞI VE MADDE BAĞIMLILIĞINA YÖNELİK YATILI BAKIM FAALİYETLERİ (HASTANELERİN FAALİYETLERİ İLE YATILI SOSYAL HİZMET FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "873002",
        "tanim": "YAŞLILARA VE BEDENSEL ENGELLİLERE YÖNELİK YATILI BAKIM FAALİYETLERİ (DESTEKLİ YAŞAM TESİSLERİ, HEMŞİRE BAKIMI OLMAYAN HUZUREVLERİ VE ASGARİ DÜZEYDE HEMŞİRE BAKIMI OLAN EVLERİN FAALİYETLERİ DAHİL, YAŞLILAR İÇİN HEMŞİRE BAKIMLI EVLERİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "879003",
        "tanim": "ÇOCUKLARA VE GENÇLERE YÖNELİK DİĞER YATILI BAKIM FAALİYETLERİ (KİMSESİZ ÇOCUKLAR İÇİN SOSYAL HİZMETLER, ÇOCUK BAKIM EVLERİ DAHİL, ÇOCUK ISLAH EVLERİNİN VE HEMŞİRELİ BAKIM TESİSLERİNİN FAALİYETLERİ İLE BEDENSEL ENGELLİLER İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "879004",
        "tanim": "ÇOCUKLARA VE GENÇLERE YÖNELİK ISLAH EVLERİ İLE ÇOCUK SUÇLU VE SABIKALILAR İÇİN BAKIM EVLERİNCE SAĞLANAN DİĞER YATILI BAKIM FAALİYETLERİ"
    },
    {
        "kod": "879090",
        "tanim": "YETİŞKİNLERE YÖNELİK BYS DİĞER YATILI BAKIM FAALİYETLERİ (SIĞINMA EVLERİ, GEÇİCİ EVSİZ BARINAKLARI, SUÇLU VE SABIKALILAR İÇİN BAKIM EVLERİ DAHİL, HEMŞİRELİ BAKIM TESİSLERİNİN FAALİYETLERİ İLE YAŞLILAR VE BEDENSEL ENGELLİLER İÇİN OLANLAR HARİÇ)"
    },
    {
        "kod": "881002",
        "tanim": "YAŞLILAR VE BEDENSEL ENGELLİLER İÇİN BARINACAK YER SAĞLANMAKSIZIN VERİLEN SOSYAL HİZMETLER (YATILI BAKIM FAALİYETLERİ İLE ENGELLİ ÇOCUKLARA YÖNELİK GÜNDÜZ BAKIM (KREŞ) FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "889101",
        "tanim": "ÇOCUK GÜNDÜZ BAKIM (KREŞ) FAALİYETLERİ (ENGELLİ ÇOCUKLAR İÇİN OLANLAR İLE BEBEK BAKICILIĞI DAHİL, OKUL ÖNCESİ EĞİTİM FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "889907",
        "tanim": "BARINACAK YER SAĞLANMAKSIZIN MESLEKİ REHABİLİTASYON HİZMETLERİ (BEDENSEL ENGELLİLER İÇİN REHABİLİTASYON HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "889908",
        "tanim": "BYS. BARINACAK YER SAĞLANMAKSIZIN VERİLEN DİĞER SOSYAL YARDIM HİZMETLERİ (AİLE REHBERLİĞİ, BORÇ DANIŞMANLIĞI, SOSYAL HİZMET İÇİN PARA TOPLAMA, EVLAT EDİNDİRME, EVSİZ, AFETZEDE VE MÜLTECİLERE GEÇİCİ BARINAK SAĞLAMA, YARDIM İÇİN UYGUN KİŞİ BELİRLEME, VB.)"
    },
    {
        "kod": "889909",
        "tanim": "BARINACAK YER SAĞLANMAKSIZIN ÇOCUK VE GENÇLERE YÖNELİK REHABİLİTASYON HİZMETLERİ (ZİHİNSEL ENGELLİLER İÇİN OLANLAR DAHİL, BEDENSEL ENGELLİLERE YÖNELİK OLANLAR HARİÇ)"
    },
    {
        "kod": "900114",
        "tanim": "CANLI TİYATRO, OPERA, BALE, MÜZİKAL, KONSER VB. YAPIMLARIN SAHNEYE KONULMASI FAALİYETLERİ (İLLÜZYON GÖSTERİLERİ, KUKLA GÖSTERİLERİ VE KUMPANYALAR DAHİL)"
    },
    {
        "kod": "900115",
        "tanim": "ORKESTRA VE BANDOLARIN FAALİYETLERİ"
    },
    {
        "kod": "900116",
        "tanim": "BAĞIMSIZ MÜZİSYEN, SES SANATÇISI, KONUŞMACI, SUNUCU VB.LERİN FAALİYETLERİ (MÜZİK GRUPLARI DAHİL)"
    },
    {
        "kod": "900117",
        "tanim": "BAĞIMSIZ MANKEN VE MODELLERİN FAALİYETLERİ"
    },
    {
        "kod": "900118",
        "tanim": "BAĞIMSIZ AKTÖR, AKTRİST VE DUBLÖRLERİN FAALİYETLERİ"
    },
    {
        "kod": "900120",
        "tanim": "SİRKLERİN FAALİYETLERİ"
    },
    {
        "kod": "900190",
        "tanim": "BYS. DİĞER GÖSTERİ SANATLARI"
    },
    {
        "kod": "900211",
        "tanim": "GÖSTERİ SANATLARINA YÖNELİK YÖNETMENLERİN VE YAPIMCILARIN FAALİYETLERİ"
    },
    {
        "kod": "900212",
        "tanim": "GÖSTERİ SANATLARINA YÖNELİK DİĞER DESTEKLEYİCİ FAALİYETLER (SAHNE TASARIMCILARI, DEKORATÖRLERİ VE KOSTÜM TASARIMCILARININ FAALİYETLERİ İLE GÖSTERİ İÇİN DEKOR VE ARKA PERDENİN, IŞIKLANDIRMA VE SES EKİPMANLARININ İŞLETİLMESİ)"
    },
    {
        "kod": "900309",
        "tanim": "YAZAR, BESTEKAR, HEYKELTIRAŞ, RESSAM, KARİKATÜRCÜ, GRAVÜRCÜ, EBRU SANATÇISI, VB. BİREYSEL SANATÇILARIN FAALİYETLERİ (HAKKAKÇILIK, HATTATÇILIK, EŞYA VE MOTİF SÜSLEMECİLİĞİ (TEZYİNATÇILIK) DAHİL)"
    },
    {
        "kod": "900311",
        "tanim": "BAĞIMSIZ GAZETECİLERİN FAALİYETLERİ"
    },
    {
        "kod": "900312",
        "tanim": "TABLO, GRAVÜR VB. SANAT ESERLERİNİN RESTORASYONU (MÜZELERDE VE ÖZEL KOLEKSİYONLARDA YER ALAN ESERLERİN RESTORASYONU DAHİL)"
    },
    {
        "kod": "900401",
        "tanim": "SANAT TESİSLERİNİN İŞLETİLMESİ (SANAT GALERİLERİ, KONSER VE TİYATRO SALONLARI VE DİĞER SANAT TESİSLERİ)"
    },
    {
        "kod": "910102",
        "tanim": "KÜTÜPHANE VE ARŞİVLERİN FAALİYETLERİ (DEVLET ARŞİVLERİ DAHİL)"
    },
    {
        "kod": "910201",
        "tanim": "MÜZELERİN FAALİYETLERİ"
    },
    {
        "kod": "910302",
        "tanim": "TARİHİ ALANLAR VE YAPILAR İLE BENZERİ TURİSTİK YERLERİN İŞLETİLMESİ (TARİHİ ALANLARIN VE YAPILARIN KORUNMASI DAHİL)"
    },
    {
        "kod": "910402",
        "tanim": "BOTANİK BAHÇELERİ, HAYVANAT BAHÇELERİ VE TABİATI KORUMA ALANLARIYLA İLGİLİ FAALİYETLER (MİLLİ PARKLAR DAHİL)"
    },
    {
        "kod": "920001",
        "tanim": "MÜŞTEREK BAHİS FAALİYETLERİ (AT YARIŞI, KÖPEK YARIŞI, FUTBOL VE DİĞER SPOR YARIŞMALARI KONUSUNDA BAHİS HİZMETLERİ)"
    },
    {
        "kod": "920002",
        "tanim": "LOTO, VB. SAYISAL ŞANS OYUNLARINA İLİŞKİN FAALİYETLER (PİYANGO BİLETLERİNİN SATIŞI DAHİL)"
    },
    {
        "kod": "920003",
        "tanim": "KUMARHANELERİN FAALİYETLERİ (ÇEVRİM İÇİ OLANLAR DAHİL)"
    },
    {
        "kod": "931101",
        "tanim": "SPOR TESİSLERİNİN İŞLETİLMESİ (FUTBOL, HOKEY, PATEN, GOLF, VB. SAHALARI, YARIŞ PİSTLERİ, STADYUMLAR, YÜZME HAVUZLARI, TENİS KORTLARI, BOVLİNG ALANLARI, BOKS ARENALARI, VB. TESİSLER)"
    },
    {
        "kod": "931102",
        "tanim": "HİPODROMLARIN İŞLETİLMESİ"
    },
    {
        "kod": "931201",
        "tanim": "ATICILIK VE OKÇULUK KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931203",
        "tanim": "FUTBOL, VOLEYBOL, BASKETBOL VB. KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931204",
        "tanim": "GÜREŞ KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931205",
        "tanim": "JOKEY KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931206",
        "tanim": "TENİS KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931207",
        "tanim": "YÜZME KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931209",
        "tanim": "ATLETİZM KULÜPLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "931290",
        "tanim": "DİĞER SPOR KULÜPLERİNİN FAALİYETLERİ (GOLF, BOVLİNG, SATRANÇ, KAYAK, BUZ PATENİ, VB. KULÜPLERİ)"
    },
    {
        "kod": "931301",
        "tanim": "FORM TUTMA VE VÜCUT GELİŞTİRME SALONLARININ FAALİYETLERİ"
    },
    {
        "kod": "931901",
        "tanim": "KENDİ HESABINA BİREYSEL ÇALIŞAN ATLET, HAKEM, ZAMAN TUTUCU, ANTRENÖR, SPOR EĞİTMENİ VB. SPORCULARIN FAALİYETLERİ"
    },
    {
        "kod": "931902",
        "tanim": "SPOR VE EĞLENCE AMAÇLI SPOR ETKİNLİĞİ VE KARŞILAŞMASI DÜZENLEYİCİLERİNİN VE ORGANİZATÖRLERİN FAALİYETLERİ"
    },
    {
        "kod": "931903",
        "tanim": "SPOR VE EĞLENCE AMAÇLI SPORLARA İLİŞKİN DESTEK HİZMETLER (BALIKÇILIK VE AVCILIK SPOR ALANLARININ İŞLETİLMESİ, AVCILIK, BALIKÇILIK VE DAĞCILIK REHBERLİĞİ, YARIŞ ATI AHIRI VE YARIŞ ARACI GARAJLARININ HİZMETLERİ, SPOR VE EĞLENCE HAYVANLARININ EĞİTİMİ, VB.)"
    },
    {
        "kod": "931904",
        "tanim": "SPOR LİGLERİ VE DÜZENLEYİCİ BİRİMLERİN FAALİYETLERİ"
    },
    {
        "kod": "931905",
        "tanim": "BİLARDO SALONLARININ FAALİYETLERİ"
    },
    {
        "kod": "931906",
        "tanim": "ATIŞ POLİGONLARININ FAALİYETLERİ"
    },
    {
        "kod": "931990",
        "tanim": "DİĞER SPOR VE EĞLENCE AMAÇLI SPOR HİZMETLERİ (PARAŞÜT HİZMETLERİ, DELTA-KANAT HİZMETLERİ, DALGIÇLIK HİZMETLERİ VE BYS. DİĞER SPOR VE EĞLENCE HİZMETLERİ)"
    },
    {
        "kod": "932101",
        "tanim": "EĞLENCE PARKLARI VE LUNAPARKLARIN FAALİYETLERİ"
    },
    {
        "kod": "932901",
        "tanim": "PLAJ ALANLARININ İŞLETİLMESİ (BU TESİSLERİN BÜTÜNLEYİCİ BİR PARÇASI OLAN SOYUNMA ODASI, DOLAP, SANDALYE, KANO, DENİZ MOTOSİKLETİ VB. KİRALANMASI DAHİL)"
    },
    {
        "kod": "932902",
        "tanim": "DÜĞÜN, BALO VE KOKTEYL SALONLARININ İŞLETİLMESİ (YİYECEK VE İÇECEK SUNUM HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "932903",
        "tanim": "BOZUK PARA VEYA JETONLA ÇALIŞAN OYUN MAKİNELERİNİN İŞLETİLMESİ (LANGIRT, TİLT, ATARİ SALONLARI, VB.)"
    },
    {
        "kod": "932905",
        "tanim": "DANS PİSTLERİNİN, DİSKOTEKLERİN İŞLETİLMESİ (İÇECEK SUNUM HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "932907",
        "tanim": "MARİNA, VB. DİNLENCE AMAÇLI ULAŞTIRMA TESİSLERİNİN İŞLETİLMESİ"
    },
    {
        "kod": "932908",
        "tanim": "HAVAİ FİŞEK İLE SES VE IŞIK GÖSTERİSİ FAALİYETLERİ"
    },
    {
        "kod": "932909",
        "tanim": "KAYAK PİSTLERİNİN İŞLETİLMESİ"
    },
    {
        "kod": "932910",
        "tanim": "DİNLENCE (REKREASYON) PARKLARININ FAALİYETLERİ (KONAKLAMALI OLANLAR İLE EĞLENCE PARKLARI VE LUNAPARKLARIN İŞLETİLMESİ HARİÇ)"
    },
    {
        "kod": "932990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ ÇEŞİTLİ EĞLENCE HİZMETLERİ (BOĞA GÜREŞİ, RODEO VB.)"
    },
    {
        "kod": "941103",
        "tanim": "ESNAF VE SANATKAR ODALARI, BİRLİKLERİ VE ÜST KURULUŞLARININ FAALİYETLERİ"
    },
    {
        "kod": "941104",
        "tanim": "ÇİFTÇİ VE ZİRAAT ODALARI, BİRLİKLERİ VE ÜST KURULUŞLARININ FAALİYETLERİ"
    },
    {
        "kod": "941105",
        "tanim": "TİCARET VE SANAYİ ODALARI VE ÜST KURULUŞLARININ FAALİYETLERİ"
    },
    {
        "kod": "941106",
        "tanim": "İŞVEREN SENDİKALARININ FAALİYETLERİ"
    },
    {
        "kod": "941190",
        "tanim": "DİĞER İŞ VE İŞVEREN ODALARI, BİRLİKLERİ VE ÜST KURULUŞLARININ FAALİYETLERİ (İŞÇİ, İŞVEREN VE MEMUR SENDİKALARI HARİÇ)"
    },
    {
        "kod": "941201",
        "tanim": "BAROLARIN FAALİYETLERİ"
    },
    {
        "kod": "941205",
        "tanim": "MESLEKİ BİRLİKLER, DERNEKLER VE ODALARIN FAALİYETLERİ (MİMAR, MÜHENDİS, TABİP, MUHASEBECİ, YAZAR VB. DERNEK VE ODALARI) (BAROLAR HARİÇ)"
    },
    {
        "kod": "941290",
        "tanim": "DİĞER PROFESYONEL MESLEK KURULUŞLARININ FAALİYETLERİ"
    },
    {
        "kod": "942001",
        "tanim": "İŞÇİ VE MEMUR SENDİKALARININ FAALİYETLERİ (ÜST KURULUŞLARI DAHİL)"
    },
    {
        "kod": "949102",
        "tanim": "ÜYELİK GEREKTİREN DİNİ KURULUŞLARIN FAALİYETLERİ (CAMİ, KİLİSE, SİNAGOG VB. YERLERDE İBADET EDENLERE DOĞRUDAN HİZMET SAĞLAYAN DİNİ ORGANİZASYONLARIN VEYA KİŞİLERİN FAALİYETLERİ VB.)"
    },
    {
        "kod": "949202",
        "tanim": "SİYASİ KURULUŞLARIN FAALİYETLERİ (SİYASİ PARTİLER, VB.)"
    },
    {
        "kod": "949901",
        "tanim": "ÜYELİK GEREKTİREN ÇEVRE VE DOĞAL HAYATIN KORUNMASINA YÖNELİK DERNEK VE BİRLİKLERİN FAALİYETLERİ (VAHŞİ YAŞAMI KORUMA KURULUŞLARI DAHİL)"
    },
    {
        "kod": "949902",
        "tanim": "ÜYELİK GEREKTİREN GENÇLİK DERNEK VE BİRLİKLERİNİN FAALİYETLERİ (ÖĞRENCİ BİRLİKLERİ İLE İZCİ BİRLİK VE KULÜPLERİ DAHİL)"
    },
    {
        "kod": "949903",
        "tanim": "ÜYELİK GEREKTİREN YURTSEVER DERNEK VE BİRLİKLERİNİN FAALİYETLERİ (SAVAŞ GAZİSİ BİRLİKLERİ VB.)"
    },
    {
        "kod": "949904",
        "tanim": "ÜYELİK GEREKTİREN HAYVANLARI KORUMA DERNEK VE BİRLİKLERİNİN FAALİYETLERİ (HAYVANLARI KORUMA DERNEĞİ, VB.)"
    },
    {
        "kod": "949905",
        "tanim": "ÜYELİK GEREKTİREN KADIN HAKLARI KORUMA DERNEK VE BİRLİKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949908",
        "tanim": "OKUL AİLE BİRLİKLERİ"
    },
    {
        "kod": "949909",
        "tanim": "ÜYELİK GEREKTİREN KÜLTÜR, DAYANIŞMA VE EĞLENCE DERNEK VE BİRLİKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949912",
        "tanim": "ÜYELİK GEREKTİREN İDEOLOJİ VE DÜŞÜNCE KURULUŞLARININ VE DERNEKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949913",
        "tanim": "ÜYELİK GEREKTİREN SİVİL ARAMA VE KURTARMA DERNEK VE BİRLİKLERİNİN FAALİYETLERİ (SİVİL SAVUNMA FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "949914",
        "tanim": "ÜYELİK GEREKTİREN BİREYSEL ÖZGÜRLÜK VE İNSAN HAKLARI DERNEK VE BİRLİKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949915",
        "tanim": "ÜYELİK GEREKTİREN GÖNÜLLÜ SAĞLIK DERNEK VE BİRLİKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949916",
        "tanim": "ENGELLİLERE, ETNİK GRUPLARA VE AZINLIKLARA YÖNELİK ÜYELİK GEREKTİREN BİRLİK VE KURULUŞLARIN FAALİYETLERİ"
    },
    {
        "kod": "949917",
        "tanim": "ÜYELİK GEREKTİREN TOPLUMSAL HAYATI GELİŞTİRME VE İYİLEŞTİRMEYE YÖNELİK OLUŞTURULAN BİRLİK VE KURULUŞLARIN FAALİYETLERİ"
    },
    {
        "kod": "949918",
        "tanim": "ÜYELİK GEREKTİREN TÜKETİCİ HAKLARINI SAVUNAN BİRLİKLER VE KURULUŞLARIN FAALİYETLERİ"
    },
    {
        "kod": "949919",
        "tanim": "HAVACILIĞIN GELİŞTİRİLMESİNE YÖNELİK ÜYELİK GEREKTİREN KURULUŞ VE DERNEKLERİN FAALİYETLERİ"
    },
    {
        "kod": "949920",
        "tanim": "ÜYE OLUNAN DERNEKLERİN ÜST KURULUŞLARI VE ÜST BİRLİKLERİ (İŞ, İŞVEREN VE MESLEKİ BİRLİK VE DERNEKLERİN ÜST KURULUŞLARI HARİÇ)"
    },
    {
        "kod": "949921",
        "tanim": "ÜYELİK GEREKTİREN YARDIM KURULUŞLARININ VE DERNEKLERİNİN FAALİYETLERİ (DOĞAL AFETLERDE ZARAR GÖRENLER, EVSİZLER, FAKİRLER İÇİN ORGANİZASYONLAR, VB.) (ARAMA VE KURTARMA HARİÇ)"
    },
    {
        "kod": "949922",
        "tanim": "ÜYELİK GEREKTİREN EĞİTİM VE ARAŞTIRMA BİRLİK VE DERNEKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949923",
        "tanim": "ÜYELİK GEREKTİREN KONUT VE KALKINMA BİRLİK VE DERNEKLERİNİN FAALİYETLERİ"
    },
    {
        "kod": "949924",
        "tanim": "ÜYELİK GEREKTİREN MEZUN DERNEK VE BİRLİKLERİNİN FAALİYETLERİ (PROFESYONEL MESLEK KURULUŞLARI HARİÇ)"
    },
    {
        "kod": "949990",
        "tanim": "ÜYELİK GEREKTİREN BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER ÜYE OLUNAN KURULUŞLARIN FAALİYETLERİ (KLASİK ARABA BİRLİKLERİ, KİRACI BİRLİKLERİ, VB. DAHİL)"
    },
    {
        "kod": "951101",
        "tanim": "BİLGİSAYARLARIN VE BİLGİSAYAR ÇEVRE BİRİMLERİNİN ONARIMI (ATM'LER VE POS CİHAZLARI DAHİL)"
    },
    {
        "kod": "951201",
        "tanim": "İLETİŞİM ARAÇ VE GEREÇLERİNİN ONARIMI (KABLOSUZ TELEFONLAR, TELSİZLER, CEP TELEFONLARI, ÇAĞRI CİHAZLARI, TİCARİ KAMERALAR VB.)"
    },
    {
        "kod": "952101",
        "tanim": "TÜKETİCİ ELEKTRONİĞİ ÜRÜNLERİNİN BAKIM VE ONARIMI (TELEVİZYON, RADYO, CD/DVD OYNATICILARI, EV TİPİ VİDEO KAMERALARI VB.)"
    },
    {
        "kod": "952201",
        "tanim": "EVDE KULLANILAN ELEKTRİKLİ CİHAZLARIN ONARIMI (BUZDOLABI, FIRIN, ÇAMAŞIR MAKİNESİ, BULAŞIK MAKİNESİ, ODA KLİMASI, ELEKTRİKLİ KÜÇÜK EV ALETLERİ, VB.)"
    },
    {
        "kod": "952202",
        "tanim": "EV VE BAHÇE GEREÇLERİNİN ONARIMI (MUTFAK EŞYASI, MAKAS, ÇİM BİÇME MAKİNESİ, BUDAMA MAKASLARI, VB.)"
    },
    {
        "kod": "952203",
        "tanim": "TERMOSİFON, ŞOFBEN, BANYO KAZANI VB. BAKIM VE ONARIMI (MERKEZİ ISITMA KAZANLARININ (BOYLERLER) ONARIMI HARİÇ)"
    },
    {
        "kod": "952301",
        "tanim": "AYAKKABI VE DERİ EŞYALARIN ONARIMI (AYAKKABI, VALİZ, EL ÇANTASI, VB.) (DERİ GİYİM EŞYASI HARİÇ)"
    },
    {
        "kod": "952401",
        "tanim": "MOBİLYALARIN VE EV DÖŞEMELERİNİN ONARIMI (BÜRO VE EV MOBİLYALARININ YENİDEN DÖŞENMESİ, KAPLANMASI, ONARIMI VE YENİLENMESİ DAHİL, HALI VE KİLİM ONARIMI HARİÇ)"
    },
    {
        "kod": "952501",
        "tanim": "SAATLERİN ONARIMI (KRONOMETRELER DAHİL, DEVAM KAYIT CİHAZLARI HARİÇ)"
    },
    {
        "kod": "952502",
        "tanim": "MÜCEVHERLERİN ONARIMI"
    },
    {
        "kod": "952902",
        "tanim": "GİYİM EŞYASI VE EV TEKSTİL ÜRÜNLERİNİN ONARIMI VE TADİLATI (DERİ GİYİM EŞYALARI HARİÇ)"
    },
    {
        "kod": "952903",
        "tanim": "SPOR ARAÇ VE GEREÇLERİ İLE KAMP MALZEMELERİNİN BAKIMI VE ONARIMI (KAYAK, SÖRF TAHTASI, PATEN, RAKET, DİĞER SPOR VE AÇIK HAVA OYUNLARINA AİT EŞYA VE EKİPMANLAR) (SPOR VE EĞLENCE AMAÇLI SİLAHLARIN ONARIMI HARİÇ)"
    },
    {
        "kod": "952904",
        "tanim": "ÇİLİNGİRLİK VE ANAHTAR ÇOĞALTMA HİZMETLERİ"
    },
    {
        "kod": "952905",
        "tanim": "BİSİKLET ONARIMI"
    },
    {
        "kod": "952906",
        "tanim": "MÜZİK ALETLERİNİN BAKIM VE ONARIMI (PİYANO AKORDU DAHİL)"
    },
    {
        "kod": "952907",
        "tanim": "DERİ VE DERİ BİLEŞİMLİ GİYİM EŞYALARI İLE KÜRK GİYİM EŞYALARININ ONARIMI"
    },
    {
        "kod": "952990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER KİŞİSEL VE EV EŞYALARININ BAKIM VE ONARIMI (KİTAP, AYDINLATMA EŞYALARI, OYUNCAK, VB. ONARIMI İLE KİMLİK KARTLARININ PLASTİKLE KAPLANMASI DAHİL)"
    },
    {
        "kod": "960101",
        "tanim": "ÇAMAŞIRHANE HİZMETLERİ (PARA VEYA JETONLA ÇALIŞAN MAKİNELERLE YAPILANLAR DAHİL)"
    },
    {
        "kod": "960102",
        "tanim": "HALI VE KİLİM YIKAMA HİZMETLERİ"
    },
    {
        "kod": "960103",
        "tanim": "GİYİM EŞYASI VE DİĞER TEKSTİL ÜRÜNLERİNİ BOYAMA VE RENKLENDİRME HİZMETLERİ (İMALAT AŞAMASINDA YAPILANLAR HARİÇ)"
    },
    {
        "kod": "960104",
        "tanim": "KURU TEMİZLEME HİZMETLERİ (GİYSİ VE DİĞER TEKSTİL ÜRÜNLERİNİN, KÜRK VE DERİ ÜRÜNLERİNİN KURU TEMİZLENMESİ)"
    },
    {
        "kod": "960105",
        "tanim": "GİYİM EŞYASI VE DİĞER TEKSTİL ÜRÜNLERİNİ ÜTÜLEME HİZMETLERİ (PRES VE SİLİNDİR ÜTÜLEME HİZMETLERİ DAHİL)"
    },
    {
        "kod": "960201",
        "tanim": "GÜZELLİK SALONLARININ FAALİYETLERİ (CİLT BAKIMI, KAŞ ALMA, AĞDA, MANİKÜR, PEDİKÜR VB.NİN BİR ARADA SUNULDUĞU SALONLAR) (SAĞLIK BAKIM HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "960202",
        "tanim": "ERKEKLER İÇİN KUAFÖR VE BERBER İŞLETMELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "960203",
        "tanim": "KADINLAR İÇİN KUAFÖR İŞLETMELERİNİN FAALİYETLERİ"
    },
    {
        "kod": "960204",
        "tanim": "SADECE MANİKÜR VE PEDİKÜR HİZMETİ SUNAN SALONLARIN FAALİYETLERİ"
    },
    {
        "kod": "960205",
        "tanim": "SADECE AĞDACILIK HİZMETİ SUNAN SALONLARIN FAALİYETLERİ"
    },
    {
        "kod": "960301",
        "tanim": "CENAZE İŞLERİ İLE İLGİLİ FAALİYETLER (CENAZE YIKAMA YERLERİNİN İŞLETİLMESİ, CENAZENİN NAKLİ, YIKAMA HİZMETLERİ, MEZAR YERİ SATIŞI, DEFİN HİZMETLERİ, CENAZE LEVAZIMATÇILIĞI VB.)"
    },
    {
        "kod": "960401",
        "tanim": "HAMAM, SAUNA, VB. YERLERİN FAALİYETLERİ"
    },
    {
        "kod": "960402",
        "tanim": "KAPLICA, ILICA, İÇMELER, SPA MERKEZLERİ, VB. YERLERİN FAALİYETLERİ (KONAKLAMA HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "960403",
        "tanim": "ZAYIFLAMA SALONU, MASAJ SALONU, SOLARYUM, VB. YERLERİN İŞLETİLMESİ FAALİYETLERİ (FORM TUTMA SALONLARININ VE DİYETİSYENLERİN FAALİYETLERİ HARİÇ)"
    },
    {
        "kod": "960901",
        "tanim": "AYAKKABI BOYAMA HİZMETLERİ"
    },
    {
        "kod": "960902",
        "tanim": "NİKAH SALONLARININ HİZMETLERİ"
    },
    {
        "kod": "960903",
        "tanim": "FAL , ASTROLOJİ VE SPİRİTUALİST HİZMETLERİ"
    },
    {
        "kod": "960904",
        "tanim": "GENEL TUVALETLERİN İŞLETİLMESİ FAALİYETİ"
    },
    {
        "kod": "960905",
        "tanim": "HAMALLIK HİZMETLERİ"
    },
    {
        "kod": "960907",
        "tanim": "KENDİ HESABINA ÇALIŞAN VALELERİN HİZMETLERİ"
    },
    {
        "kod": "960908",
        "tanim": "ESKORT VE REFAKAT HİZMETLERİ (GÜVENLİK HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "960909",
        "tanim": "TANIŞTIRMA BÜROLARININ VE EVLENDİRME AJANSLARININ HİZMETLERİ"
    },
    {
        "kod": "960910",
        "tanim": "KENDİ HESABINA ÇALIŞAN YAMAK, GARSON, VB. HİZMET SUNANLARIN FAALİYETLERİ"
    },
    {
        "kod": "960912",
        "tanim": "GENELEV HİZMETLERİ"
    },
    {
        "kod": "960914",
        "tanim": "EV HAYVANLARI BAKIM HİZMETLERİ (EV HAYVANLARINA VERİLEN BESLEME, BAKIM, BARINDIRMA, KUAFÖRLÜK, EĞİTİM, VB. HİZMETLER)"
    },
    {
        "kod": "960915",
        "tanim": "ŞECERE BULMA FAALİYETLERİ"
    },
    {
        "kod": "960916",
        "tanim": "JETON İLE ÇALIŞAN KİŞİSEL HİZMET MAKİNELERİNİN İŞLETİLMESİ FAALİYETLERİ (JETONLU MAKİNELERLE VESİKALIK FOTOĞRAF, EMANET DOLAPLARI, TARTI, TANSİYON ÖLÇÜMÜ VB. HİZMETLER DAHİL, OYUN VE KUMAR MAKİNELERİ İLE ÇAMAŞIRHANE HİZMETLERİ HARİÇ)"
    },
    {
        "kod": "960918",
        "tanim": "ARZUHALCİLERİN FAALİYETLERİ"
    },
    {
        "kod": "960990",
        "tanim": "BAŞKA YERDE SINIFLANDIRILMAMIŞ DİĞER HİZMET FAALİYETLERİ (DÖVME VE PİERCİNG HİZMETLERİ, VB.)"
    },
    {
        "kod": "970010",
        "tanim": "EV İÇİ ÇALIŞAN PERSONELİN İŞVERENLERİ OLARAK HANEHALKLARININ HİZMETLERİ"
    },
    {
        "kod": "981001",
        "tanim": "HANEHALKLARI TARAFINDAN KENDİ KULLANIMLARINA YÖNELİK OLARAK ÜRETİLEN AYRIM YAPILMAMIŞ MALLAR"
    },
    {
        "kod": "982001",
        "tanim": "HANEHALKLARI TARAFINDAN KENDİ KULLANIMLARINA YÖNELİK OLARAK ÜRETİLEN AYRIM YAPILMAMIŞ HİZMETLER"
    },
    {
        "kod": "990015",
        "tanim": "ULUSLARARASI ÖRGÜTLER VE TEMSİLCİLİKLERİNİN FAALİYETLERİ (YABANCI ÜLKE ELÇİLİKLERİ, MİLLETLERARASI İŞBİRLİĞİ ÖRGÜTLERİ, VB. DAHİL)"
    }
]
import React from "react";

import { Row, Col, Button } from "reactstrap";



const ShareSale = ({ nextPage, handleChange }) => {

  const nextStep = async (num) => {
    handleChange(num);
    nextPage()
    // await base.syncStep({ newStep: 4, currentCompany, history });
  };
  return (
    <>
      <Row className="text-center mt-5">
        <Col>
          <h3>Hisse Satışı</h3>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <p>
            İlerleyen dönemde firma hisselerini tamamen veya kısmen satmayı
            planlıyor musunuz?
          </p>
        </Col>
      </Row>
      <div className="text-center mt-5 mx-auto">

          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(1)}
          >
            Hayır Hisse Satışı Olmayacak
          </Button>


          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(3)}
          >
            Evet
          </Button>

      </div>
    </>
  );
};

export default ShareSale;

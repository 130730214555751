import React from "react";

import { Col, Badge } from "reactstrap";
// import { AuthContext } from "../../base/auth";

const PartnerPraview = ({
  setCurrentPartner,
  currentPartner,
  partners,
  numberOfPartners,
  numberOfNonPartnerManager,
}) => {
  // const { currentCompany } = React.useContext(AuthContext);

  if (!partners) {
    return (
      <>
        <div>Henüz Ortak Eklenmemiş</div>
      </>
    );
  }

  return (
    <Col>
      <p>
        <Badge>Ortak sayısı {numberOfPartners}</Badge>
      </p>

      <p>
        <Badge>Ortak olmayan yönetici sayısı {numberOfNonPartnerManager}</Badge>
      </p>
      {partners.map((partner, index) => (
        <ul
          className="my-1 list-group pointer"
          key={partner.id}
          onClick={() => setCurrentPartner(partner.id)}
        >
          <li
            className={
              partner.id === currentPartner
                ? "list-group-item list-group-item-action  list-group-item-primary py-2"
                : "list-group-item list-group-item-action py-1"
            }
          >
            {partner.partner
              ? `${index + 1}. Ortak `
              : `${index + 1}. Ortak Olmayan Yönetici `}
            {partner.name} {partner.surName}
            <span
              className={
                partner.manager
                  ? "badge bg-light text-dark ml-2"
                  : "badge bg-warning text-dark ml-2"
              }
            >
              {" "}
              {partner.manager ? "Yönetici" : "Yalnızca Ortak"}
            </span>
          </li>
        </ul>
      ))}
    </Col>
  );
};

export default PartnerPraview;

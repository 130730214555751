import React from "react";
import logo from "../img/logo.svg";

const Logo = ({ className, height }) => {
  return (
    <img
      src={logo}
      className={className}
      style={{ height }}
      alt="YeniSirketKur Logo"
    />
  );
};

export default Logo;

import React, { useState, useEffect } from "react";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";

const ProductList = ({data}) => {
    console.log(data)
  return (
    <div className="row">
     {data.map(item => (<ProductItem item={item} />))}
    </div>
  );
};

export default ProductList;

const ProductItem = ({item}) => {
  return (
    <div className="col-md-4">
      <Card className="my-2">
        {/* <CardImg top width="100%" src="../../img/logo.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">{item.productName}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
           {item.description}
          </CardSubtitle>
          <CardText>{item.detail}</CardText>
          <CardText>{item.price} TL</CardText>
          <Button>+</Button>
        </CardBody>
      </Card>
    </div>
  );
};

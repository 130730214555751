import React, { useContext } from "react";
import { AuthContext } from "../../base/auth";


const Documents = ({ files }) => {
  const { base, superAdmin, currentCompany } = useContext(AuthContext);

  const handleDeleteFiles = async (attachmentID) => {
    await base.deleteFileFromDB({
      companyID: currentCompany.id,
      attachmentID,
    });
  };

  return (
    <>
      Tüm Dosyalar
      <ul>
        {files.map((file) => {
          return (
            <li>
              <span
                className="pointer text-primary"
                onClick={() => window.open(file.fileUrl)}
              >
                {file.category}
              </span>
              {superAdmin && (
                <span
                  key={file.id}
                  onClick={() => handleDeleteFiles(file.id)}
                  className="pointer text-danger"
                >
                  {" "}
                  Sil
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Documents;

import React from "react";
import { Button, Table, Breadcrumb, BreadcrumbItem } from "reactstrap";

//import { AuthContext } from "../../../base/auth";

import * as ROUTES from "../../../base/routes";

const UserList = ({
  history,
  userListArray,
  invitationArray,
  handleOpenEditUser,
  handleOpenEditInvitation,
}) => {
  //const { currentCompany, userData, base } = React.useContext(AuthContext);

  return (
    <>
      <Breadcrumb className="my-3" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Kulanıcılar
        </BreadcrumbItem>
      </Breadcrumb>

      <Button className="mb-3" onClick={() => history.push(ROUTES.NEW_USER)}>
        Kullanıcı Davet Et
      </Button>

      <Table hover responsive>
        <thead>
          <tr>
            <th>Adı Soyadı</th>
            <th>ePosta Adresi</th>
            <th>Hesap Türü</th>
          </tr>
        </thead>

        <tbody className="bg-white pointer">
          {userListArray.map((item) => (
            <tr key={item.uid} onClick={(e) => handleOpenEditUser(item.uid)}>
              <td>{item.displayName}</td>
              <td>{item.email}</td>
              <td>{item.roleDisplay}</td>
            </tr>
          ))}
          {invitationArray.map((item) => (
            <tr
              className="text-muted"
              key={item.toUid}
              onClick={(e) => handleOpenEditInvitation(item.id)}
            >
              <td>{item.toDisplayName}</td>
              <td>{item.toEmail}</td>
              <td>onay bekleniyor</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default UserList;

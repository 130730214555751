import React, {  useContext } from "react";
import { AuthContext } from "../../base/auth";
import { Progress } from "reactstrap";

import { Switch, Route } from "react-router-dom";
import * as ROUTES from "../../base/routes";

import CompRegInfo from "./compRegInfo";
import AdminEstablish from './adminEstablish/adminEstablish';

const Admin = ({ history }) => {
  //let { path } = useRouteMatch();
  const { currentCompany } = useContext(AuthContext);
  // const [selectedItem, setSelectedItem] = useState(null);


  

  return (
    <>
      <h4 className="mt-3">
        {currentCompany.companyType} Kuruluş İşlemleri - Yönetici
      </h4>
     
      <Switch>
        <Route exact path={ROUTES.ADMIN_ESTABLISH}>
          <AdminEstablish history={history} />
        </Route>
        <Route path={ROUTES.ADMIN_COMPREGINFO}>
          <CompRegInfo history={history} />
        </Route>
      </Switch>
    </>
  );
};

export default Admin;

import React, { useContext } from "react";
import { Button, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

import * as ROUTES from "../../../base/routes";
import { AuthContext } from "../../../base/auth";

import InputA from "../../../common/inputA";

/*
invites = companyid:{

inviteID:ID, 
time: date(), 
companyID:ID ,

fromUid:davetedenuid, 
fromEmail:davetedilen@eposta ,
fromDisplayName: 

toEmail:davetedilen@eposta, 
toDisplayName:
toUid:””
isValid:true


*/
const NewUser = ({ history, margeUserData }) => {
  const { currentCompany, userData, base } = useContext(AuthContext);

  const initualFormValue = {
    eMail: { value: "", error: "" },
    displayName: { value: "", error: "" },
  };

  const [formData, setFormdata] = React.useState(initualFormValue);
  const [validation, setValidation] = React.useState(false);

  React.useEffect(() => {
    const formdata = Object.values(formData).every(
      (item) => (item.value !== "" && item.error === "") || !item.required
    );
    setValidation(formdata);
  }, [formData]);

  const handleSaveToDB = async () => {
    if (formData.eMail.value === userData.email) return;
    setValidation(false);
    const data = {
      email: formData.eMail.value,
      displayName: formData.displayName.value,
    };
    const result = await base.addInviteToDB({ currentCompany, userData, data });
    margeUserData();
    setValidation(true);
    history.push(ROUTES.USERS);
    return result;
  };

  return (
    <>
      <Breadcrumb className="my-3" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem
          className="pointer"
          tag="span"
          onClick={() => history.push(ROUTES.USERS)}
        >
          Kulanıcılar
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Yeni Kullanıcı
        </BreadcrumbItem>
      </Breadcrumb>
      <Col md="6">
        <InputA
          type="text"
          inputType="text"
          label="Kullanıcı Adı Soyadı"
          name="displayName"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={11}
          minLength={5}
        />
        <InputA
          type="email"
          inputType="text"
          label="e-Posta"
          name="eMail"
          formValue={formData}
          setFormValue={setFormdata}
          //maxLength={150}
          minLength={1}
        />

        <Button
          className="mb-3"
          disabled={!validation}
          onClick={() => handleSaveToDB()}
        >
          Kaydet
        </Button>
      </Col>
    </>
  );
};

export default NewUser;

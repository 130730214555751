import React, { useState, useEffect, useContext } from "react";

import { Row, Col, Button, FormGroup, Label, Input, Alert, Breadcrumb, BreadcrumbItem} from "reactstrap";
//import { RiDeleteBin6Line } from "react-icons/ri";
import * as ROUTES from "../../base/routes";
import { AuthContext } from "../../base/auth";

//import { TiDelete } from "react-icons/ti";
import { RiDeleteBin6Line } from "react-icons/ri";

import titleTemplate from "./unvanKonulari";
import SearchBar from '../../common/searchBar/searchBar';

const Title = ({ history,aplicationData }) => {
  const { userData, currentCompany, base  } = useContext(AuthContext);

  const [changeUnvan, setChangeUnvan] = useState(true);
  const [unvan, setUnvan] = useState("");
  const [unvanDB, setUnvanDB] = useState("");
  const [onEk, setOnEk] = useState("");
  const [konu, setKonu] = useState([]);
  const [ozelKelime, setOzelKelime] = useState([]);
  const [tur, setTur] = useState("");

  

  const turkishUpperCase = (input) => {
    let letters = { i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
    let string = input.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    string = string.toUpperCase();
    return string;
  };

  // searchBar Select
  const handleChangeSearch = (selectedItem) => {
    const newArray = [...konu];
    setKonu([...newArray, selectedItem]);
  }
    
  React.useEffect(() => {
    if (!currentCompany?.companyType) return;
    if (currentCompany?.companyType.includes("Şahıs")) {

       
       base.syncStep({ newStep:14, currentCompany, history })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, history]);



  useEffect(() => {
    if (!aplicationData) return;
    if (!aplicationData?.companyName) return;
    const data = aplicationData?.companyName;
    setUnvanDB(data);
    setChangeUnvan(false);
  }, [aplicationData]);

  useEffect(() => {
    setUnvan(`${onEk} ${konu.join(" ")} ${ozelKelime.join(" ")} ${tur}İ  `);
    //setUnvan(onEk + " " + tur)
  }, [onEk, konu, ozelKelime, tur, changeUnvan]);

  useEffect(() => {
    if (!aplicationData) return;
    const data = aplicationData?.companyType;
    if (typeof data === "undefined") return;
    setTur(turkishUpperCase(aplicationData.companyType));
    //setUnvan(data)
  }, [aplicationData]);

  const handleSaveToDb = async () => {
    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyName: unvan },
    });
    await base.updateCompanyToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyName: unvan },
    });
    
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "konu") {
      const newArray = [...konu];
      setKonu([...newArray, value]);
    }
    if (name === "ozel") {
      const newArray = [...ozelKelime];
      setOzelKelime([...newArray, value]);
    }
    if (name === "baglac") {
      if (ozelKelime.length === 0) {
        const newArray = [...konu];
        setKonu([...newArray, value]);
      } else {
        const newArray = [...ozelKelime];
        setOzelKelime([...newArray, value]);
      }
    }
  };

  const handleDelete = (arrayName, kelime, index) => {
    if (arrayName === "konu") {
      const newArray = [...konu];
      const filteredArray = newArray.filter((x, y) => y !== index);
      setKonu(filteredArray);
    }
    if (arrayName === "ozel") {
      const newArray = [...ozelKelime];
      const filteredArray = newArray.filter((x, y) => y !== index);
      setOzelKelime(filteredArray);
    }
  };

  const handleSave = async() => {
    //history.push(ROUTES.SUMMARY);
    await base.syncStep({ newStep:14, currentCompany, history })
  };

  if (!changeUnvan)
    return (
      <>
       <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Ünvan Belirleme
            </BreadcrumbItem>
          </Breadcrumb>
        <Alert color="primary">{unvanDB}</Alert>
        <Button
          color="primary"
          className="m-1"
          size="sm"
          onClick={() => setChangeUnvan(!changeUnvan)}
          disabled={currentCompany?.companyType.includes("Şahıs")}
        >
          Düzenle
        </Button>
        <Button
          color="primary"
          className="m-1"
          size="sm"
          onClick={() => handleSave()}
        >
          Kaydet
        </Button>
      </>
    );

  return (
    <Row>
      <Col md={6}>
      <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Ünvan Belirleme
            </BreadcrumbItem>
          </Breadcrumb>
        {!onEk ? null : (
          <>
            <Alert color="primary">{unvan}</Alert>

            <Button color="primary" className="m-1" size="sm">
              {onEk}
            </Button>
            {konu.map((kelime, index) => (
              <Button
                className="m-1"
                size="sm"
                key={index}
                color="warning"
                onClick={() => handleDelete("konu", kelime, index)}
              >
                {kelime} <RiDeleteBin6Line />
              </Button>
            ))}
            {ozelKelime.map((kelime, index) => (
              <Button
                className="m-1"
                size="sm"
                key={index}
                color="info"
                onClick={() => handleDelete("ozel", kelime, index)}
              >
                {kelime} <RiDeleteBin6Line />
              </Button>
            ))}
            <Button color="danger" className="m-1" size="sm">
              {tur}İ
            </Button>
          </>
        )}

        <FormGroup>
   
          <Row>
            <Col md={6}>
            <Label for="onEk">Şirket Adı</Label>
              <Input
                className="my-2"
                type="text"
                name="onEk"
                id="onEk"
                value={onEk}
                onChange={(e) => {
                  setOnEk(turkishUpperCase(e.target.value));
                }}
                placeholder=""
                label="Şirket Adı"
              />
            </Col>
            <Col md={6} >
     
            <SearchBar
              data={titleTemplate.unvanKonu}
              // property={"tanim"}
              // propertyArray={["kod", "tanim"]}
              newRecord={false}
              handleChange={handleChangeSearch}
  
              label="Konu"
            /> 
   
     
 
              {/* <InputDataList
                handleChange={handleChange}
                dataArray={titleTemplate.unvanKonu}
                title={"Konu"}
                name={"konu"}
                turkishUpperCase={turkishUpperCase}
              /> */}
            </Col>
          </Row>
          <Label for="onEk">Zorunlu olmayan ekler</Label>
          <Row>
            <Col md={3}>
              <InputDataList
                handleChange={handleChange}
                dataArray={titleTemplate.unvanBaglac}
                title={"Bağlaç"}
                name={"baglac"}
                turkishUpperCase={turkishUpperCase}
              />
            </Col>
            <Col md={6}>
              <InputDataList
                handleChange={handleChange}
                dataArray={titleTemplate.unvanOzelKelime}
                title={"Özel Kelime"}
                name={"ozel"}
                turkishUpperCase={turkishUpperCase}
              />
            </Col>
          </Row>
        </FormGroup>

        <br />
        <Button
          className="my-3"
          onClick={() => history.push(ROUTES.COMSUBJECT)}
          color="secondary"
        >
          {" "}
          Geri{" "}
        </Button>
        <Button
          className="m-3"
          disabled={!onEk.length || !konu.length}
          onClick={() => handleSaveToDb()}
          color="primary"
        >
          {" "}
          Kaydet{" "}
        </Button>
      </Col>
    </Row>
  );
};

export default Title;

const InputDataList = ({
  handleChange,
  dataArray,
  title,
  name,
  turkishUpperCase,
}) => {
  const [value, setValue] = React.useState("");

  return (
    <>
      {/* <Label for={name}>Select</Label> */}
     
      <Input
        type="select"
        className="form-control p-1 my-2"
        name={name}
        id={name}
        value={value}
        onChange={(e) => handleChange(e)}
      >
        <option>{title} Seçin </option>
        {dataArray.map((data, index) => (
          <option
            key={index}
            //onInput={this.value = this.value.toUpperCase()}
            value={data}
          >
            {data}
          </option>
        ))}
      </Input>
    </>
  );
};

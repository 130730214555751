import React, { useState } from "react";

import { FaWalking, FaFileSignature, FaFileAlt } from "react-icons/fa";
import { GiMoneyStack, GiCargoCrate } from "react-icons/gi";
import { MdVerifiedUser } from "react-icons/md";

import { Tooltip } from "reactstrap";

const ActionIcon = ({ icon, text, index, size = 20 }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      {icon === "git" && (
        <>
          <FaWalking className="mx-1" size={size} id={`FaWalking${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`FaWalking${index}`}
            toggle={toggle}
            className="mx-2"
          >
            kuruma gidilecek
          </Tooltip>
        </>
      )}
      {icon === "imza" && (
        <>
          <FaFileSignature className="mx-1" size={size} id={`FaFileSignature${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`FaFileSignature${index}`}
            toggle={toggle}
            className="mx-2"
          >
            imzanız gerekli
          </Tooltip>
        </>
      )}
      {icon === "belge" && (
        <>
          <FaFileAlt className="mx-1" size={size} id={`FaFileAlt${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`FaFileAlt${index}`}
            toggle={toggle}
          >
            belge gerekli
          </Tooltip>
        </>
      )}
      {icon === "odeme" && (
        <>
          <GiMoneyStack className="mx-1" size={size} id={`GiMoneyStack${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`GiMoneyStack${index}`}
            toggle={toggle}
          >
            ödeme gerekecek
          </Tooltip>
        </>
      )}
      {icon === "kargo" && (
        <>
          <GiCargoCrate className="mx-1" size={size} id={`GiCargoCrate${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`GiCargoCrate${index}`}
            toggle={toggle}
          >
            kargo beklenecek
          </Tooltip>
        </>
      )}

      {icon === "onay" && (
        <>
          <MdVerifiedUser className="mx-1" size={size} id={`MdVerifiedUser${index}`} />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`MdVerifiedUser${index}`}
            toggle={toggle}
          >
            e-Devlet onayı gerekiyor
          </Tooltip>
        </>
      )}
    </>
  );
};

export default ActionIcon;

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../base/auth";
import { Button } from "reactstrap";

const ListFiles = ({
  companyID,
  attachments,
  setSavedFiles,
  savedFiles,
  files,
  hiddenDelButton = false,
  establishTasks,
  selectedItem,
}) => {
  const { base } = useContext(AuthContext);

  const [listFiles, setListFiles] = useState([]);
  const [waitForFiles, setWaitForFiles] = useState([]);

  useEffect(() => {
    const attachments = establishTasks?.[selectedItem]?.attachments;
    if (!attachments) {
      setWaitForFiles([]);
      setListFiles([]);
      return;
    }

    const filteredFiles = files.filter((file) =>
      attachments.some((att) => att === file.category)
    );

    const savedFileNames = [];
    filteredFiles.forEach((file) => savedFileNames.push(file.category));
    const waitfor = attachments.filter(
      (att) => !savedFileNames.some((x) => x === att)
    );
    setWaitForFiles(waitfor);
  }, [establishTasks, files, savedFiles, selectedItem]);

  useEffect(() => {
    if (!files || !attachments) return;
    const filtered = files.filter((file) =>
      attachments.some((att) => att === file.category)
    );
    setListFiles(filtered);
  }, [attachments, files]);

  const handleDeleteFiles = async (attachmentID) => {
    if (!attachments) {
      setSavedFiles([]);
      return;
    }
    await base.deleteFileFromDB({
      companyID,
      attachmentID,
    });

    // const array = [...savedFiles];
    // const filtered = array.filter((x) => x.id !== attachmentID);
    // setSavedFiles(filtered);

    //getFiles(attachments, companyID);
  };

  if (!savedFiles) return null;
  // if (savedFiles.length === 0) return <>Belge yüklenmemiş</>;
  // üzerine tıklayınca görüntüle
  // sil tuşu ile sil

  return (
    <>
      {listFiles.length !== 0 ? (
        <>
          {" "}
          Kaydedilen Belgeler:
          <ul size="sm">
            {listFiles.map((file) => (
              <li color="success" key={file.id}>
                {/* <a  href={file.fileUrl} download>
                    {" "}
                    {file?.category}{" "}
                  </a> * */}
                <span
                  className="pointer text-primary"
                  onClick={() => window.open(file.fileUrl)}
                >
                  {file?.category}{" "}
                </span>

                <Button
                  color="danger"
                  className={hiddenDelButton ? "ml-3 d-none" : "ml-3"}
                  size="sm"
                  key={file.id}
                  onClick={() => handleDeleteFiles(file.id)}
                >
                  sil
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {waitForFiles.length !== 0 ? (
        <>
          Beklenen Belgeler:
          <ul>
            {waitForFiles.map((attach, index) => (
              <li className="text-danger" key={index}>
                {attach}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
};

export default ListFiles;

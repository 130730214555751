import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import * as ROUTES from "../base/routes";

const config = {
  apiKey: "AIzaSyACoNObNdVoBhuYH5_3EPjO1sGKCYIYtA8",
  authDomain: "yenisirketkur.firebaseapp.com",
  projectId: "yenisirketkur",
  storageBucket: "yenisirketkur.appspot.com",
  messagingSenderId: "354069322022",
  appId: "1:354069322022:web:08dffbdb2d59170b74c9e7",
  measurementId: "G-F5RKBQRBJV",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

const emailAuthProvider = firebase.auth.EmailAuthProvider;
const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

const emulatorPass = true;

if (window.location.hostname.includes("localhost") && !emulatorPass) {
  console.log("localhost");
  auth.useEmulator("http://localhost:9099");
  firestore.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
  //npx firebase emulators:start
}

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();

class Base {
  constructor() {
    this.emailAuthProvider = emailAuthProvider;
    this.auth = auth;
    this.firestore = firestore;
    this.functions = functions;
    this.storage = storage;

    this.googleProvider = googleProvider;
    this.facebookProvider = facebookProvider;
    this.twitterProvider = twitterProvider;
    this.ROUTES = ROUTES;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = async (email, password, displayName) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    user.updateProfile({
      displayName,
    });
    user.sendEmailVerification();
    return user.uid;
  };

  doCreateDB =  ({ displayName }) => {
    const createUserDb = this.functions.httpsCallable("createUserDocCall");
    createUserDb({ displayName });
    return;
  };

  doSignInWithEmailAndPassword = async (email, password) => {
    return await this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "http://localhost:3000",
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  userAPI(uid) {
    return this.firestore.doc(`users/${uid}`);
  }

  getUser = async () => {
    return this.auth.onAuthStateChanged((user) => {
      return user;
    });
  };

  createUsername = async (userAuth) => {
    if (!userAuth) return;
    const userRef = this.firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();

    if (!snapShot.displayName) {
      const { displayName } = userAuth;
      try {
        await userRef.update({ displayName });
      } catch (error) {}
    }

    return userRef;
  };

  getUserData = async (callback) => {
    this.auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser) return;
      //await this.createUsername(currentUser);
      const userRef = this.firestore.doc(`users/${currentUser.uid}`);
      // Kullanıcı Bilgileri
      userRef.onSnapshot((snapShot) => {
        const userData = {
          id: snapShot.id,
          uid: currentUser.uid,
          email: currentUser.email,
          emailVerified: currentUser.emailVerified,
          providerData: currentUser.providerData,
          ...snapShot.data(),
          currentUser: { ...currentUser },
        };
        callback(userData);
      });
    });
  };

  sendInvitationMail = async (objData) => {
    const sendMailInvitation =
      this.functions.httpsCallable("sendMailInvitation");
    await sendMailInvitation(objData).then((result) => {
      // Read result of the Cloud Function.
      console.log(result);
      return result;
    });
  };

  checkEmailExist = async (objData) => {
    const checkEmail = this.functions.httpsCallable("checkEmailExist");
    const result = await checkEmail(objData);
    return result.data;
  };

  getInvitationData = async (objData) => {
    const invitationData = this.functions.httpsCallable("getInvitation");
    const result = await invitationData(objData);
    return result.data;
  };

  acceptInv = async (objData) => {
    const ref = this.functions.httpsCallable("acceptInv");
    const result = await ref(objData);
    return result.data;
  };

  setInitialEstablishTask = async (objData) => {
    const ref = this.functions.httpsCallable("setInitialEstablishTask");
    const result = await ref(objData);
    return result.data;
  };

  callFunction = async ({ objData, functionName }) => {
    const ref = this.functions.httpsCallable(functionName);
    const result = await ref(objData);
    console.log("callFunction", result.data);
    return result.data;
  };

  setCurrentCompanyDB = async ({ userData, companyID, uid = userData.id }) => {
    const userRef = this.firestore.collection("users").doc(uid);
    await userRef.update({ currentCompany: companyID });
  };

  deleteCompany = async (objData) => {
    const ref = this.functions.httpsCallable("deleteCompany");
    const result = await ref(objData);
    console.log(result.data);
    return result.data;
  };

  dbListenerCollection = ({ ref, query }, callback) => {
    let referance = ref;

    if (query) {
      const queryRef = referance.where(
        query.proberty,
        query.operator,
        query.value
      );
      referance = queryRef;
    }

    referance.onSnapshot(
      (querySnapshot) => {
        const array = [];
        querySnapshot.forEach((doc) => {
          if (!doc.exists) {
            console.log("No such document!");
          } else {
            array.push({ id: doc.id, ...doc.data() });
          }
        });
        callback(array);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  dbListenerDoc = ({ ref }, callback) => {
    let referance = ref;
    referance.onSnapshot((doc) => {
      if (!doc.exists) console.log("No such document!");
      else callback({ id: doc.id, ...doc.data() });
    });
  };

  getCompanies = async ({ userData, superAdmin }) => {
    if (!userData.id) return;

    const companyRef = this.firestore.collection("companies");
    const queryRef2 = companyRef.where(`users.${userData?.id}.role`, "in", [
      "owner",
      "admin",
      "user",
    ]);

    //const queryRef = companyRef.where('adminUser', 'array-contains', userData?.id);

    const allCompanies = [];
    // ---- firma bilgilerini bir kez al
    let snapshot = await queryRef2.get();
    // kullanıcı admin ise tüm şirketleri çek
    if (superAdmin) {
      snapshot = await companyRef.get();
    }

    snapshot.forEach((doc) => {
      if (doc.id !== "docCounter")
        allCompanies.push({ id: doc.id, ...doc.data() });
    });
    // ----
    console.log("companies alındı");
    return allCompanies;
  };

  getCurrentCompanyData = async (userData, callback) => {
    if (!userData.id) return;
    if (!userData.currentCompany) return;
    // seçili Firma Bilgisini dinle
    const selectedCompany = this.firestore
      .collection("companies")
      .doc(userData.currentCompany);
    selectedCompany.onSnapshot((doc) => {
      callback({ id: doc.id, ...doc.data() });
    });
  };

  getEstablishTaskData = async ({ companyID }, callback) => {
    if (!companyID) return;
    // seçili Firma Bilgisini dinle
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("establishTasks");

    this.dbListenerDoc({ ref }, (data) => {
      callback(data);
    });
  };
  getRegisterData = async ({ companyID }) => {
    if (!companyID) return;
    // seçili Firma Bilgisini dinle
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("registeredData");

    const doc = await ref.get();
    if (!doc.exists) {
      console.log("No such document!");
      return {};
    } else {
      return doc.data();
    }
  };

  getAplicationPartnerData = async ({ companyID }, callback) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("aplicationData")
      .collection("partners");

    this.dbListenerCollection({ ref }, (data) => {
      callback(data);
    });
  };

  getAplicationData = async ({ companyID }, callback) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("aplicationData");

    this.dbListenerDoc({ ref }, (data) => {
      callback(data);
    });
  };

  getFileData = async ({ companyID, query }, callback) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("files");

    this.dbListenerCollection({ ref, query }, (data) => {
      callback(data);
    });
  };

  addNewPartner = async ({ companyID, data }) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("aplicationData")
      .collection("partners")
      .doc(data.id);

    await ref.set(data);
    return;
  };

  deletePartner = async ({ companyID, dataID }) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("aplicationData")
      .collection("partners")
      .doc(dataID);

    await ref.delete();
    return;
  };

  createNewCompany = async (obj) => {
    const ref = this.functions.httpsCallable("createCompany");
    const result = await ref(obj);
    console.log(result.data);
    return result.data;
  };

  changeCurrentComp = async ({ companyID }) => {
    const ref = this.functions.httpsCallable("setCurrentCompany");
    const result = await ref({ companyID });
    console.log(result.data);
    return result.data;
  };

  updateCompanyToDb = async ({ companyID, updateKeys }) => {
    if (!companyID) {
      console.log("companyID hatalı");
      return;
    }
    if (!updateKeys) {
      console.log("updateKeys hatalı");
      return;
    }
    // updateKeys company içerisindeki güncellenecek alanları array olarak alır.
    const companyRef = this.firestore.collection("companies").doc(companyID);
    for (const property in updateKeys) {
      if (typeof property === "undefined") {
        console.log("company için güncellenmesi istenen değer tanımsız.");
      }
      await companyRef.update({ [property]: updateKeys[property] });
    }
  };

  updateAplicationDataToDb = async ({ companyID, updateKeys }) => {
    if (!companyID || !updateKeys) {
      console.log("companyID veya updateKeys hatalı");
      return;
    }
    // updateKeys company içerisindeki güncellenecek alanları array olarak alır.
    const companyRef = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("aplicationData");
    for (const property in updateKeys) {
      if (typeof property === "undefined")
        console.log("company için güncellenmesi istenen değer tanımsız.");
      await companyRef.update({ [property]: updateKeys[property] });
    }
  };

  updateEstablishTaskToDb = async ({ companyID, updateKeys }) => {
    if (!companyID || !updateKeys) {
      console.log("companyID veya updateKeys hatalı");
      return;
    }
    // updateKeys company içerisindeki güncellenecek alanları array olarak alır.
    const companyRef = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("establishTasks");
    for (const property in updateKeys) {
      if (typeof property === "undefined")
        console.log("company için güncellenmesi istenen değer tanımsız.");
      await companyRef.update({ [property]: updateKeys[property] });
    }
  };

  setRegisteredDataToDb = async ({ companyID, data }) => {
    if (!companyID || !data) {
      console.log("companyID veya updateKeys hatalı");
      return;
    }
    // updateKeys company içerisindeki güncellenecek alanları array olarak alır.
    const companyRef = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("data")
      .doc("registeredData");
    await companyRef.set(data);
  };

  syncStep = async ({ newStep, currentCompany, history }) => {
    const link = {
      1: this.ROUTES.DECIDETYPE,
      2: this.ROUTES.INVESTMENT,
      3: this.ROUTES.SHARESALE,
      4: this.ROUTES.MANAGEMENT,
      5: this.ROUTES.TYPERESULT,
      6: this.ROUTES.TYPERESULT,
      7: this.ROUTES.COLPARTNERS,
      8: this.ROUTES.AUTHORITIES,
      9: this.ROUTES.REPRESENTATION,
      10: this.ROUTES.COMCAPITAL,
      11: this.ROUTES.COMSUBJECT,
      12: this.ROUTES.ADDRESS,
      13: this.ROUTES.TITLE,
      14: this.ROUTES.SUMMARY,
      15: this.ROUTES.ESTABLISH,
      16: this.ROUTES.ESTABLISH, // kuruluş başlama ve kilit
      17: this.ROUTES.ESTABLISH, // şirket bilgileri tescili
      20: this.ROUTES.COMPREGINFO, // Tüm İşlemler bitti
    };
    history.push(link[newStep]);

    if (currentCompany.status < newStep) {
      this.updateCompanyToDb({
        companyID: currentCompany.id,
        updateKeys: { status: newStep, lastPage: link[newStep] },
      });
    }
    return;
  };

  addInviteToDB = async ({ currentCompany, userData, data }) => {
    if (!currentCompany) {
      console.log("company hatalı");
      return;
    }
    if (!currentCompany?.id) {
      console.log("company ID hatalı");
      return;
    }

    // data.email daha önce aynı company de kayıtlı mı kontrol et.
    const invitationRef = this.firestore.collection("invitation");
    const invitationQuery1 = invitationRef.where(
      `companyID`,
      "==",
      `${currentCompany.id}`
    );
    const invitationQuery2 = invitationQuery1.where(
      `toEmail`,
      "==",
      `${data.email}`
    );
    const snapshot = await invitationQuery2.get();
    if (snapshot.empty) {
      console.log("e posta geçerli");
    } else {
      console.log("bu eposta daha önce eklenmiş!");
      return "bu eposta daha önce eklenmiş!";
    }
    // davetiyeyi db ye ekle
    //const ref = this.firestore.collection('invitation')
    const invData = {
      time: new Date(),
      companyID: currentCompany?.id,

      fromUid: userData.id,
      fromEmail: userData.email,
      fromDisplayName: userData.displayName,

      toUid: "",
      toEmail: data.email,
      toDisplayName: data.displayName,

      isValid: true,
    };
    const res = await this.firestore.collection("invitation").add(invData);
    invData.id = res.id;
    console.log("mailTetikleniyor.");
    await this.sendInvitationMail(invData);
    console.log(`davetiye eklendi ${res.id}`);
    // davetiye e postası gönder
    // e posta açılınca ;
    // eğer e posta kayıtlıysa daveti kabul et ve companyye ilgili toUid ekle current company güncelle.
    // kullanıcı yoksa şifre belirleme ekranı çıkar. üyeliği oluştur ardından ilgili uid company e ekle.
    return res.id;
  };

  collectIsDone = async (objData) => {
    const ref = this.functions.httpsCallable("sendMailNewCompany");
    const result = await ref(objData);
    console.log(result.data);
    return result.data;
  };

  getInvitation = async ({ currentCompany }) => {
    // invitation verilerinin alınması
    const invitationRef = this.firestore.collection("invitation");
    const invitationQuery1 = invitationRef.where(
      `companyID`,
      "==",
      `${currentCompany.id}`
    );
    //const invitationQuery2 = invitationQuery1.where(`isValid`, '==', `true`);
    const snapshot = await invitationQuery1.get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    const array = [];
    snapshot.forEach((doc) => {
      array.push({ id: doc.id, ...doc.data() });
    });

    return array;
  };

  saveFileToDb = async ({ companyID, base64Code, fileType }) => {
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("files");
    await ref.add({ base64Code, fileType });
    return { base64Code, fileType, id: ref.id };
  };

  getFileFromDb = async ({ companyID, fileType }) => {
    const allData = [];
    if (!fileType) return allData;
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("files");

    const queryRef2 = ref.where(`fileType`, "in", fileType);

    //const queryRef2 = ref.where('fileType', 'array-contains', fileType);
    // ---- firma bilgilerini bir kez al
    let snapshot = await queryRef2.get();

    snapshot.forEach((doc) => {
      allData.push({ id: doc.id, ...doc.data() });
    });
    return allData;
  };

  deleteFileFromDB = async ({ companyID, attachmentID }) => {
    if (!attachmentID) return;
    const ref = this.firestore
      .collection("companies")
      .doc(companyID)
      .collection("files")
      .doc(attachmentID);
    // ---- ilgili dosyayı sil
    let doc = await ref.get();
    let path = doc.data().meta.fullPath;
    const storageRef = this.storage.ref();
    let fileRef = storageRef.child(path);
    try {
      await fileRef.delete();
    } catch (error) {
      console.log(error);
    }
    let res = await ref.delete();

    return res;
  };

  updateDataToDB = async ({ ref, data }) => {
    const res = await ref.update(data);
    return res;
  };

  deleteDataFromDB = async ({ ref }) => {
    const res = await ref.delete();
    return res;
  };
}

export default Base;

import React, { useState, useEffect } from "react";

import {
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Card,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import Partnership from "./partnership";
import Investment from "./investment";
import ShareSale from "./shareSale";
import Management from "./management";
import TypeResult from "./typeResult";
// import { AuthContext } from "../../base/auth";
import Cost from "../../common/cost";

const TypeDec = ({ history, currentCompany, userData }) => {
  // statlerde 1 gerçek kişi 2 limited 3 Anomim Şirketi temsil eder
  const [partnership, setPartnership] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [shareSale, setShareSale] = useState(0);
  const [management, setManagement] = useState(0);
  const [type, setType] = useState("");
  const [typeUserChoose, setTypeUserChoose] = React.useState("");

  useEffect(() => {
    const findTipe = Math.max(partnership, investment, shareSale, management);
    if (findTipe === 1) {
      setType("Şahıs Firması");
    } else if (findTipe === 2) {
      setType("Limited Şirket");
    } else if (findTipe === 3) {
      setType("Anonim Şirket");
    }
  }, [partnership, investment, shareSale, management]);

  const [activeTask, setActiveTask] = useState(0);
  const handleActiveTask = (i) => {
    if (activeTask === i) {
      setActiveTask(null);
      return;
    }
    setActiveTask(i);
  };
  const nextPage = () => {
    const acTask = activeTask || 0;
    setActiveTask(acTask + 1);
  };

  const typePages = [
    {
      title: "Ortaklık Yapısı",
      page: (
        <Partnership
          handleChange={setPartnership}
          nextPage={nextPage}
          history={history}
        />
      ),
    },
    {
      title: "Yatırım",
      page: (
        <Investment
          handleChange={setInvestment}
          nextPage={nextPage}
          history={history}
        />
      ),
    },
    {
      title: "Hisse Satışı",
      page: (
        <ShareSale
          handleChange={setShareSale}
          nextPage={nextPage}
          history={history}
        />
      ),
    },
    {
      title: "Yönetim Yapısı",
      page: (
        <Management
          handleChange={setManagement}
          nextPage={nextPage}
          history={history}
        />
      ),
    },
    {
      title: "Sonuç",
      page: (
        <TypeResult
          type={type}
          partnership={partnership}
          investment={investment}
          shareSale={shareSale}
          management={management}
          currentCompany={currentCompany}
          userData={userData}
          history={history}
          setTypeUserChoose={setTypeUserChoose}
          typeUserChoose={typeUserChoose}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={8} className="mt-3">
          <Breadcrumb className="my-3" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Firma Türü Belirleme
            </BreadcrumbItem>
          </Breadcrumb>
          <ListGroup>
            {typePages.map((page, index) => (
              <Card className=" border-light">
                <ListGroupItem
                  color={index >= activeTask ? "info" : "success"}
                  onClick={() => handleActiveTask(index)}
                >
                  {page.title}
                </ListGroupItem>
                {activeTask === index && page.page}
              </Card>
            ))}
          </ListGroup>
        </Col>

        <Col md={4} className="mt-3">
          <Cost companyType={typeUserChoose || type} />
        </Col>
      </Row>
    </>
  );
};

export default TypeDec;

import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import useForm from "../../common/hooks/useForm";
import FormInput from "../../common/input";
import { Button, Label, Input, FormGroup } from "reactstrap";
import CheckBox from '../../common/checkBox';

const ProductNew = ({ history, addData }) => {
  const schema = {
    productName: Joi.string()
      .required()
      .min(6)
      .max(25)
      .label("Ürün Adı")
      .error((er) => costumMessage(er)),

    description: Joi.string()
      .required()
      .min(6)
      .label("Ürün Adı")
      .error((er) => costumMessage(er)),
    detail: Joi.string()
      .required()
      .min(6)
      .label("Ürün Adı")
      .error((er) => costumMessage(er)),
    price: Joi.number()
      .required()
      .label("Fiyat")
      .error((er) => costumMessage(er)),
    limited: Joi.boolean()
      .label("Fiyat")
      .error((er) => costumMessage(er)),
  };

  const doSubmit = () => {
    addData({ data: formData });
  };

  const {
    formData,
    setFormData,
    errorsData,
    setErrorsData,
    handleChange,
    handleSwitch,
    handleSubmit,
    costumMessage,
  } = useForm({
    schema,
    doSubmit,
  });

  console.log(formData);

  return (
    <>
      <FormInput
        type="text"
        name="productName"
        value={formData["productName"]}
        error={errorsData["productName"]}
        label="Ürün Adı"
        onChange={handleChange}
        placeholder=""
      />
      <FormInput
        type="text"
        name="description"
        value={formData["description"]}
        error={errorsData["description"]}
        label="Tanım"
        onChange={handleChange}
        placeholder=""
      />
      <FormInput
        type="text"
        name="detail"
        value={formData["detail"]}
        error={errorsData["detail"]}
        label="Detay"
        onChange={handleChange}
        placeholder=""
      />
      <FormInput
        type="number"
        name="price"
        value={formData["price"]}
        error={errorsData["price"]}
        label="Fiyat"
        onChange={handleChange}
        placeholder=""
      />

      {/* <FormGroup>
        <Label check>
          <Input
            type="checkbox"
            name="limited"
            value={formData["limited"]}
            error={errorsData["limited"]}
            defaultChecked={false}
            onChange={handleSwitch}
          />{" "}
          Check me out
        </Label>
      </FormGroup> */}
      <CheckBox
       type="checkbox"
       name="limited"
       value={formData["limited"]}
       error={errorsData["limited"]}
       defaultChecked={false}
       label="Limited Şirket"
       onChange={handleSwitch}
      
      
      />

      <Button onCheck={handleSubmit}>Kaydet</Button>
    </>
  );
};

export default ProductNew;

import React, { useState, useContext } from "react";
import { AuthContext } from "../base/auth";
import { Button } from "reactstrap";

const UploadFile = ({
  collectionName = "companies",
  sizeLimitMB = 10,
  userFileName = "file",
  category = "Ana Sözleşme",
  handleCancel,
}) => {
  const { base, userType, currentUser } = useContext(AuthContext);

  const [files, setFiles] = useState(null);
  const [fileList, setFileList] = useState([]);

  const companyID = userType?.companyID;
  const uid = currentUser?.uid;

  const handleOnChange = (e) => {
    setFiles(e.target.files);
    const listArray = [];
    const listObject = e.target.files;
    for (const item of listObject) {
      //console.log(item.File)
      listArray.push(item);
    }

    setFileList(listArray);
  };

  const handleSetFileDataToFirestore = async ({ file, meta, fileUrl }) => {
    const ref = base.firestore
      .collection(collectionName)
      .doc(companyID)
      .collection("files");
    const { contentType, fullPath, name, size, timeCreated, type, updated } =
      meta;

    const res = await ref.add({
      fileUrl,
      meta: { contentType, fullPath, name, size, timeCreated, type, updated },
      userFileName,
      orijinalFileName: file.name,
      uid,
      category: category ? category : null,
    });
    return res.id;
  };

  const cleanString = (str) => {
    if (typeof str !== "string") return "";
    const charMap = {
      Ç: "C",
      Ö: "O",
      Ş: "S",
      İ: "I",
      I: "i",
      Ü: "U",
      Ğ: "G",
      ç: "c",
      ö: "o",
      ş: "s",
      ı: "i",
      ü: "u",
      ğ: "g",
    };
    const str_array = str.split("");
    for (var i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }
    const string = str_array.join("");
    var clearStr = string.replace(/[^a-z0-9-.çöşüğı_]/gi, "").trim();
    return clearStr;
  };

  const handleSaveFile = async (e) => {
    const result = [];

    for await (let file of files) {
      const fileName = cleanString(file.name.toLowerCase());
      /// const fileSize = file.size;
      let sizeInMb = file.size / (1024 * 1024);

      if (sizeInMb <= sizeLimitMB) {
        // const fileType = file.type;
        const storageRef = base.storage.ref();

        const time = new Date().getTime();
        const name = fileName;

        let fileRef = storageRef.child(
          `${collectionName}/${companyID}/${time + "_" + name}`
        );

        if (category) {
          const categoryName = cleanString(category);
          fileRef = storageRef.child(
            `${collectionName}/${companyID}/${categoryName}/${
              time + "_" + name
            }`
          );
        }

        await fileRef.put(file);
        const meta = await fileRef.getMetadata();
        const fileUrl = await fileRef.getDownloadURL();
        const dbId = await handleSetFileDataToFirestore({
          file,
          meta,
          fileUrl,
        });
        result.push({ dbId });
      }
    }
    setFiles(null);
    setFileList([]);
    handleCancel();
    return result;
  };

  return (
    <div className="my-2">
      <div className="card">
        <div className="custom-file">
          <input
            type="file"
            name="test"
            id="inputFile"
            onChange={(e) => handleOnChange(e)}
            className="custom-file-input"
            aria-describedby="inputGroupFileAddon04"
            multiple={true}
          />
          <label className="custom-file-label" htmlFor="inputFile">
            {fileList.length === 0
              ? "Dosya seçmek için buraya tıklayın"
              : `${fileList.length} adet dosya seçildi`}
          </label>
        </div>
      </div>

      {fileList.length === 0 ? null : (
        <ul>
          {fileList.map((file, index) => {
            let sizeInMb = file.size / (1024 * 1024);
            return (
              <li key={index}>
                {file.name} {sizeInMb.toFixed(2)}- Mb{" "}
                {sizeInMb >= sizeLimitMB
                  ? "Dosya çok büyük yüklenemez"
                  : "Hazır"}
              </li>
            );
          })}
        </ul>
      )}

      <Button
        color="primary"
        className="my-2"
        onClick={(e) => handleSaveFile(e)}
      >
        Kaydet
      </Button>
      <Button color="secondary" className="m-2" onClick={() => handleCancel()}>
        Vazgeç
      </Button>
    </div>
  );
};

export default UploadFile;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../base/auth";
import { Button, Badge, Breadcrumb, BreadcrumbItem } from "reactstrap";
import currency from "currency.js";
import Loading from "../../common/loading";

// import * as ROUTES from "../../base/routes";
const Summary = ({ history, setStep, aplicationData, partners }) => {
  const { currentCompany, base } = useContext(AuthContext);
  // const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });

  // useEffect(() => {

  //   obj.partner.map((part) => {
  //     const array = [];
  //     const object = part.manageWith;
  //     for (const key in object) {
  //       array.push(object[key].name);
  //     }
  //     part.manageWithText = array.join(",");
  //   });

  // }, [aplicationData]);

  const handleSave = async () => {
    setLoading(true);

    await base.collectIsDone({
      companyName: currentCompany.companyName,
      companyID: currentCompany.id,
    });
    await base.syncStep({ newStep: 15, currentCompany, history });
    //history.push(ROUTES.ESTABLISH);
    setLoading(false);
  };

  if (!aplicationData.companyName || !partners || partners.length === 0)
    return <Loading />;

  if (loading) return <Loading />;

  return (
    <>
      <Breadcrumb className="" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Özet - Onay
        </BreadcrumbItem>
      </Breadcrumb>
      <h4>Şirket Bilgileri</h4>
      <ul>
        <li>Şirket Türü : {aplicationData?.companyType}</li>
        <li>Şirket Ünvanı : {aplicationData?.companyName}</li>
        <li>Sermaye : {tl(aplicationData?.companyCapital).format()}</li>
        <li>Adres : {aplicationData?.adress.adress}</li>
        <li>AdresNo : {aplicationData?.adress?.adressNo}</li>
      </ul>
      <hr />
      <p>Yetkili ve Ortaklar</p>
      <small>
        {partners.map((part) => (
          <ul key={part.id}>
            <li>
              {part.name} {part.surName} - {part.partner ? <>Ortak</> : null}{" "}
              <ul>
                <li>
                  {part.eMail} - {part.cellPhone} {part.cellPhoneOperator}
                </li>

                <li>
                  TC: {part.idNumberTC} - Kimlik Seri: {part.idSerial}{" "}
                </li>
                <li>
                  Doğum Tarihi: {part.dateOfBirth} - yaşı: {part?.age}{" "}
                </li>
                <li>Doğum Yeri: {part.placeOfBirth} </li>

                {part.capital ? (
                  <li>
                    sermaye : {part.capital.value}-TL %
                    {tl(part.capital.share).format()}{" "}
                  </li>
                ) : null}

                {part.manager ? (
                  <li>
                    Yetki : {part.authType} - {part.authTime}
                    <br />
                    {part.manageWithText ? (
                      <span> {part.manageWithText} ile birlikte</span>
                    ) : null}
                  </li>
                ) : null}
              </ul>
            </li>
          </ul>
        ))}
      </small>
      <hr />
      <p>Nace :</p>
      <small>
        <ul>
          {aplicationData?.nace.map((n) =>
            n.main ? (
              <li key={n.kod}>
                <Badge color="success">Ana Faaliyet</Badge>{" "}
                <strong>
                  {n.kod} - {n.tanim}
                </strong>
              </li>
            ) : (
              <li key={n.kod}>
                {n.kod} - {n.tanim}
              </li>
            )
          )}
        </ul>
      </small>

      <div className="user-select-all">
        <p>Amaç ve Konu :</p>
        <small>
          <ol type="a">
            {aplicationData?.purposeSubject.map((sub, index) => (
              <li key={index}>{sub}</li>
            ))}
            <li>
              Konusu ile ilgili sahalarda mümessillikler, distribütörlükler,
              bayilikler alabilir, verebilir, yurt içinde ve yurt dışında
              ajanslıklar ve bürolar tesis edebilir. Serbest bölgeler ve yurt
              dışında konusu ile ilgili şirket kurabilir şube açabilir. Yabancı
              gerçek ve tüzel kişilerden lisans, patent, know-how ihtira
              beratları alameti farika, marka , fikir ve benzeri hakları tesis
              etmek devir ve ferağ etmek , ikraz ve istikrarsız sözleşmeleri
              yapabilir.
            </li>
            <li>
              Türk Ticaret Kanunu, gerek 4875 Sayılı Doğrudan Yabancı Yatırımlar
              Kanunu gerek sonradan çıkarılan kanun ve bu kanunlarla ilgili
              olarak çıkarılmış muhtelif kararname, yönetmelik ve tebliğ
              hükümleri ile, yürürlükteki sair tüm ilgili mevzuat hükümleri
              uyarınca ve bunlarla gerekli kılınan izinleri almak şartıyla,
              Şirketin faaliyet konularına giren işleri yapan yerli ve yabancı
              gerçek ve tüzel kişilerce ortaklıklar kurmak; ortaklık
              girişimlerinde, mevcut ticari işletmelere ve şirketlere iştirak
              etmek, bunları şirkete ortak etmek, bunlarla birleşmek, ticari
              işletmeleri ve şirketleri kısmen veya tamamen devir almak,
              bunların Türkiye dahilinde distribütörlüğünü, mümessilliğini, baş
              bayiliğini, bayiliğini , acenteliğini yapmak ve diğer şahıs ve
              kuruluşlara bayilikler vermesi işlerini yapabilir, bunlardaki
              kendisine ait hisseler üzerinde her nevi tasarrufta bulunmak
              işlerini yapar.
            </li>
            <li>
              Türkiye Cumhuriyeti devleti bakanlıklarının ve bakanlığa bağlı
              resmi kuruluşların, kamu iktisadi teşebbüsleri, iktisadi devlet
              teşekkülleri, kamu iktisadi kuruluşları, kamu bütçeli daireler,
              mahalli idareler, belediyeler, yapı kooperatifleri, özel sektör
              kuruluşları tarafından yurt içinde ve yurt dışında ihaleye
              çıkarılan şirketin konusuna giren her türlü alanda ihalelerine
              katılmak, teklif vermek, ihale şirkette kalırsa ihale sözleşmesi
              gereği işi yapmak ve teslim etmek.{" "}
            </li>
            <li>
              Yukarıda gösterilen muamelelerden başka ileride şirket için
              faydalı ve lüzumu görülecek başka işlere girişilmek istendiğinde
              işletme Türk Ticaret Kanunu’nun ilgili hükümlerine göre hareket
              eder.
            </li>
          </ol>
        </small>
      </div>
      <br />
      <Button className="my-3" onClick={() => handleSave()}>
        Onayla
      </Button>
    </>
  );
};

export default Summary;

import React, { useState, useContext, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import Joi from "joi-browser";

import ivdImg1 from "../img/ivdPassword/gIvdPass1.png";
import ivdImg2 from "../img/ivdPassword/gIvdPass2.png";
import ivdImg3 from "../img/ivdPassword/gIvdPass3.png";
import ivdImg4 from "../img/ivdPassword/gIvdPass4.png";
import ivdImg5 from "../img/ivdPassword/gIvdPass5.png";
import ivdImg6 from "../img/ivdPassword/gIvdPass6.png";
import useForm from "./hooks/useForm";
import FormInput from "./input";
import { AuthContext } from "../base/auth";
import useDbData from "./hooks/useDbData";
import * as ROUTES from "../base/routes"

const IvdPasswordInfo = ({ sahis = false ,history}) => {
  const { base, currentCompany, userData } = useContext(AuthContext);

  const [ivdStep, setIvdStep] = useState(0);
  const [password, setPassword] = useState("");
  const [vergiNo, setVergiNo] = useState("");
  const [kullaniciKodu, setKullaniciKodu] = useState("");

  const { data, updateData } = useDbData({
    collectionName: "data",
    docID: "aplicationData",
    query: "",
    listener: false,
  });

  const [aplicationData, setAplicationData] = React.useState([]);
  useEffect(() => {
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getAplicationData({ companyID: userData.currentCompany }, (data) => {
        setAplicationData(data);
      });
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
  }, [currentCompany, userData.currentCompany]);

  const vergiORTC = {
    minValue: sahis ? 11 : 10,
    maxValue: sahis ? 11 : 10,
    label: sahis ? "T.C. Kimlik No" : "Vergi No",
  };

  const schema = {
    password: Joi.string()
      .required()
      .min(6)
      .max(6)
      .label("Şifre")
      .error((er) => costumMessage(er)),
    vergiNo: Joi.string()
      .required()
      .min(vergiORTC.minValue)
      .max(vergiORTC.maxValue)
      .label(vergiORTC.label)
      .error((er) => costumMessage(er)),
    kullaniciKodu: Joi.string()
      .empty("")
      .min(8)
      .max(8)
      .label("Kullanıcı Kodu")
      .error((er) => costumMessage(er)),
  };

  const doSubmit = () => {
    console.log({ formData });
    console.log({ errorsData });
    updateData({ data: { ivd: formData }, id: "aplicationData" });
  };


  const {
    formData,
    setFormData,
    errorsData,
    setErrorsData,
    handleChange,
    handleSubmit,
    costumMessage
  } = useForm({
    schema,
    doSubmit,
  });

  useEffect(() => {
    if (!aplicationData && !aplicationData?.ivd) return;
    const data = { ...aplicationData.ivd };
    setFormData(data)
  }, [aplicationData]);
console.log(formData);

  return (
    <div>
      {ivdStep === 0 ? (
        <IvdPass
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 1 ? (
        <Giris
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 2 ? (
        <Step2
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 3 ? (
        <Step3
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 4 ? (
        <Step4
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 5 ? (
        <Step5
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 6 ? (
        <Step6
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {ivdStep === 7 ? (
        <Step7
          sahis={sahis}
          password={password}
          formData={formData}
          handleChange={handleChange}
          errorsData={errorsData}
          setIvdStep={setIvdStep}
          doSubmit={doSubmit}
          handleSubmit={handleSubmit}
          history={history}
        />
      ) : null}
    </div>
  );
};

export default IvdPasswordInfo;

const IvdPass = ({
  setIvdStep,
  sahis,
  formData,
  errorsData,
  handleChange,
  doSubmit,
  handleSubmit,
}) => {
  return (
    <div>
      <Form>
        {sahis ? (
          <FormInput
            type="text"
            name="vergiNo"
            value={formData["vergiNo"]}
            error={errorsData["vergiNo"]}
            label="TC Kimlik Numarası"
            onChange={handleChange}
            placeholder="11 haneli TC Kimlik numaranızı giriniz"
            onKeyDown={(e) => {
              if (e.keyCode === 8) return;
              !/[0-9]/.test(e.key) && e.preventDefault();
            }}
          />
        ) : (
          <FormInput
            type="text"
            name="vergiNo"
            value={formData["vergiNo"]}
            error={errorsData["vergiNo"]}
            label="Vergi Numarası"
            onChange={handleChange}
            placeholder="10 haneli Vergi numaranızı giriniz"
            onKeyDown={(e) => {
              if (e.keyCode === 8) return;
              !/[0-9]/.test(e.key) && e.preventDefault();
            }}
          />
        )}
        <FormInput
          type="text"
          name="password"
          value={formData["password"]}
          error={errorsData["password"]}
          label="ivd Şifre"
          onChange={handleChange}
          placeholder="6 haneli interaktif vergi dairesi şifreniz"
          onKeyDown={(e) => {
            if (e.keyCode === 8) return;
            !/[0-9]/.test(e.key) && e.preventDefault();
          }}
        />
      </Form>
      <Button
        color="primary"
        className="m-3"
        onClick={(e) => {
          handleSubmit(e);
          //   setIvdStep(1)
        }}
      >
        Kaydet
      </Button>

      <Button
        className="mr-3 my-3"
        color="warning"
        onClick={() => setIvdStep(1)}
      >
        Şifrem Yok Almama Yardımcı Ol
      </Button>
    </div>
  );
};

const Giris = ({ setIvdStep }) => {
  return (
    <div>
      <h3>Aşağıdaki linkten interaktif vergi dairesine giriş yapın</h3>
      <br />
      <Button
        color="info"
        onClick={() => window.open("https://ivd.gib.gov.tr/", "_blank")}
      >
        İnteraktif Vergi Dairesine Giriş Yapın{" "}
      </Button>
      <br />
      <br />
      <p>
        Link açılmazsa açılır pencere engelleyicisinin aktif olmadığından emin
        olun
      </p>

      <p>veya tarayıcınıza aşağıdaki adresi elle yapıştırın.</p>

      <p>
        <strong>https://ivd.gib.gov.tr/ </strong>
      </p>

      <Button onClick={() => setIvdStep(2)} color="primary">
        {" "}
        Giriş Yaptım Devam Et
      </Button>
    </div>
  );
};

const Step2 = ({ setIvdStep }) => {
  return (
    <div>
      <h3>e-Devlet ile kaydol seçeneğini tıklayın</h3>
      <br />
      <img src={ivdImg1} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>Açılan pencereden e-Devlet şifreniz ile giriş yapın</p>

      <Button onClick={() => setIvdStep(3)} color="primary">
        {" "}
        e-Devlet ile Giriş Yaptım Devam Et
      </Button>
    </div>
  );
};

const Step3 = ({ setIvdStep, sahis }) => {
  return (
    <div>
      <h3>Kullanıcı Tipi Seçimi</h3>
      <br />
      <img src={ivdImg2} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>Kullanıcı tipini {sahis ? "Gerçek" : "Tüzel"} Kişi olarak seçin</p>

      <Button onClick={() => setIvdStep(4)} color="primary">
        {" "}
        {sahis ? "Gerçek Kişi Seçtim Devam Et" : "Tüzel Kişi Seçtim Devam Et"}
      </Button>
    </div>
  );
};

const Step4 = ({ setIvdStep }) => {
  return (
    <div>
      <h3>Şifre Taahhütnamesini Onaylama</h3>
      <br />
      <img src={ivdImg3} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>İnteraktif Vergi Dairesi Şifre Taahhütnamesini Onaylayın</p>

      <Button onClick={() => setIvdStep(5)} color="primary">
        {" "}
        Onayladım Devam Et
      </Button>
    </div>
  );
};

const Step5 = ({
  setIvdStep,
  sahis,
  formData,
  errorsData,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div>
      <h3>Şifre Oluşturma</h3>
      <br />
      <img src={ivdImg4} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>
        <strong>6 haneli ve rakamlardan oluşan </strong> Şifrenizi oluşturun
      </p>

      <Form>
        {sahis ? (
          <FormInput
            type="text"
            name="vergiNo"
            value={formData["vergiNo"]}
            error={errorsData["vergiNo"]}
            label="TC Kimlik Numarası"
            onChange={handleChange}
            placeholder="11 haneli TC Kimlik numaranızı giriniz"
            onKeyDown={(e) => {
              if (e.keyCode === 8) return;
              !/[0-9]/.test(e.key) && e.preventDefault();
            }}
          />
        ) : (
          <FormInput
            type="text"
            name="vergiNo"
            value={formData["vergiNo"]}
            error={errorsData["vergiNo"]}
            label="Vergi Numarası"
            onChange={handleChange}
            placeholder="10 haneli Vergi numaranızı giriniz"
            onKeyDown={(e) => {
              if (e.keyCode === 8) return;
              !/[0-9]/.test(e.key) && e.preventDefault();
            }}
          />
        )}
        <FormInput
          type="text"
          name="password"
          value={formData["password"]}
          error={errorsData["password"]}
          label="ivd Şifre"
          onChange={handleChange}
          placeholder="6 haneli interaktif vergi dairesi şifreniz"
          onKeyDown={(e) => {
            if (e.keyCode === 8) return;
            !/[0-9]/.test(e.key) && e.preventDefault();
          }}
        />
      </Form>

      <Button
        onClick={(e) => {
          handleSubmit(e);

          if (!errorsData?.["password"] && !errorsData?.["vergiNo"]) {
            if (formData?.["password"] && formData?.["vergiNo"]) {
              console.log("çalıştı");
              setIvdStep(6);
            }
          }
        }}
        color="primary"
      >
        {" "}
        Şifre Oluşturdum Devam Et
      </Button>
    </div>
  );
};

const Step6 = ({ setIvdStep }) => {
  return (
    <div>
      <h3>Sms Onayı</h3>
      <br />
      <img src={ivdImg5} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>Cep telefonunuza gelen sms onay kodunu girin</p>

      <Button onClick={() => setIvdStep(7)} color="primary">
        {" "}
        SMS onay kodunu girdim Devam Et
      </Button>
    </div>
  );
};

const Step7 = ({
  setIvdStep,
  handleSubmit,
  handleChange,
  formData,
  errorsData,history
}) => {
  return (
    <div>
      <h3>Onay Ekranı</h3>
      <br />
      <img src={ivdImg6} style={{ width: "35em" }} alt="YeniSirketKur Logo" />
      <p>Yukarıdaki ekranın aynısı görüyorsanız. İşlemler Tamamlandı. </p>
      <p>Kullanıcı Kodunu not alın ve Tamam ı tıklayın</p>
      <Form>
        <FormInput
          type="text"
          name="kullaniciKodu"
          value={formData["kullaniciKodu"]}
          error={errorsData["kullaniciKodu"]}
          label="Kullanıcı Kodu"
          onChange={handleChange}
          placeholder="Kullanıcı Kodunu Girniz..."
          onKeyDown={(e) => {
            if (e.keyCode === 8) return;
            !/[0-9]/.test(e.key) && e.preventDefault();
          }}
        />
      </Form>
      <br />

      <Button
        onClick={(e) => {
          handleSubmit(e);
          if (errorsData?.["kullaniciKodu"]) return;
          setIvdStep(8);
          history.push(ROUTES.ESTABLISH)
        }}
        color="primary"
      >
        {" "}
        Tamamladım Devam Et
      </Button>
    </div>
  );
};

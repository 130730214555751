import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.sass";
// import { FaTools } from "react-icons/fa";
import { MdCallSplit } from "react-icons/md";
import { BsFillCollectionFill } from "react-icons/bs";
import { BiTask } from "react-icons/bi";
import {
  AiTwotoneSetting,
  AiFillCloseCircle,
  AiOutlineCaretRight,
  AiOutlineCaretLeft,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import * as ROUTES from "../../base/routes";

import Logo from "../../common/Logo";
import { Button } from "reactstrap";
import { AuthContext } from "../../base/auth";

// sideBar position-absolute
// sideBar d-none

const SideBar = ({
  collapsed,
  history,
  step,
  closeMenu,
  setCloseMenu,
  isPhone,
  setIsPhone,
  setCollapsed,
}) => {
  let location = useLocation();

  const changeMenu = () => {
    if (isPhone) {
      setCollapsed(false);
      setCloseMenu(!closeMenu);
      return;
    }
    setCollapsed(!collapsed);
  };

  const { currentCompany, userData, superAdmin } =
    React.useContext(AuthContext);
  const [collectLock, setCollectLock] = React.useState(false);
  const changeCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  React.useEffect(() => {
    if (!currentCompany?.collectLock) return;
    if (userData?.userType === "admin") return;
    const result = currentCompany?.collectLock;
    setCollectLock(result);
  }, [currentCompany.collectLock, userData]);

  const sideBarDataAdmin = [
    {
      title: "Yönetim",
      icon: <AiTwotoneSetting />,
      defaultOpen: false,
      conditions: superAdmin,
      menuItem: [
        {
          title: "Kuruluş Aşamaları",
          page: ROUTES.ADMIN_ESTABLISH,
          conditions: superAdmin,
        },
        {
          title: "Başvuru Bilgileri",
          page: ROUTES.SUMMARY,
          conditions: superAdmin,
        },
        {
          title: "Tescil Bilgileri",
          page: ROUTES.ADMIN_COMPREGINFO,
          conditions: superAdmin,
        },
        {
          title: "Belgeler",
          page: ROUTES.DOCUMENTS,
          conditions: superAdmin,
        },
        {
          title: "Ürünler",
          page: ROUTES.PRODUCT,
          conditions: superAdmin,
        },
      ],
    },
  ];

  const sideBarData = [
    {
      title: "Bilgi Girişi",
      icon: <BsFillCollectionFill />,
      defaultOpen: true,
      conditions: !collectLock || superAdmin,
      menuItem: [
        {
          title: "Tür Seçimi",
          page: ROUTES.HOME,
          conditions: !collectLock || superAdmin,
          prefix: "",
          suffix: "",
        },
        {
          title: "Ortaklar",
          page: ROUTES.COLPARTNERS,
          conditions: step >= 7 && (!collectLock || superAdmin),
        },
        {
          title: "Yöneticiler",
          page: ROUTES.AUTHORITIES,
          conditions: step >= 8 && (!collectLock || superAdmin),
        },
        {
          title: "Yetki Şekli",
          page: ROUTES.REPRESENTATION,
          conditions: step >= 9 && (!collectLock || superAdmin),
        },
        {
          title: "Sermaye",
          page: ROUTES.COMCAPITAL,
          conditions: step >= 10 && (!collectLock || superAdmin),
        },
        {
          title: "Firma Konusu",
          page: ROUTES.COMSUBJECT,
          conditions: step >= 11 && (!collectLock || superAdmin),
        },
        {
          title: "Adres",
          page: ROUTES.ADDRESS,
          conditions: step >= 12 && (!collectLock || superAdmin),
        },
        {
          title: "Ünvan",
          page: ROUTES.TITLE,
          conditions: step >= 13 && (!collectLock || superAdmin),
        },
        {
          title: "Özet",
          page: ROUTES.SUMMARY,
          conditions: step >= 14 && (!collectLock || superAdmin),
        },
        // {
        //   title: "Market",
        //   page: ROUTES.PRODUCT,
        //   conditions: superAdmin,
        // },
      ],
    },
    {
      title: "Kuruluş",
      icon: <BiTask />,
      defaultOpen: true,
      conditions: step >= 15,
      menuItem: [
        {
          title: "Kuruluş Aşamaları",
          page: ROUTES.ESTABLISH,
          conditions: step >= 15,
        },
        {
          title: "İnteraktif VD",
          page: ROUTES.IVDPASSWD,
          conditions: step >= 15,
        },
        {
          title: "Belgeler",
          page: ROUTES.DOCUMENTS,
          conditions: step >= 15,
        },
        {
          title: "Firma bilgileri",
          page: ROUTES.COMPREGINFO,
          conditions: step >= 15,
        },
      ],
    },
    {
      title: "Ayarlar",
      icon: <AiTwotoneSetting />,
      defaultOpen: false,
      conditions: true,
      menuItem: [
        {
          title: "Kullanıcılar",
          page: ROUTES.USERS,
          conditions: true,
        },
        {
          title: "Firmalar",
          page: ROUTES.COMPANIES,
          conditions: true,
        },
      ],
    },
  ];

  return (
    <div className="sidebar fill">
      <ProSidebar
        collapsed={collapsed}
        //toggled="false"
        //onToggle = {(value)=> console.log(value)}
        // breakPoint="sm"
        // width="200px"
        // collapsedWidth="80px"
        className={
          isPhone ? (closeMenu ? " d-none" : " position-absolute") : null
        }
      >
        <SidebarHeader>
          {isPhone ? (
            <Button
              //className="position-fixed"
              onClick={() => changeCloseMenu()}
            >
              <AiFillCloseCircle />
            </Button>
          ) : null}

          <Link to={ROUTES.HOME}>
            <Logo
              className=" mx-auto d-block my-4 pointer "
              height={collapsed ? "10px" : "35px "}
            />
          </Link>
        </SidebarHeader>
        <SidebarContent className="overflow-auto">
          {superAdmin ? (
            <SideBarComponent
              sideBarData={sideBarDataAdmin}
              location={location}
            />
          ) : null}
          <SideBarComponent sideBarData={sideBarData} location={location} />
        </SidebarContent>
        <SidebarFooter>
          {!isPhone && (
            <span className=" pointer" onClick={() => changeMenu()}>
              {collapsed ? (
                <div className="m-4">
                  <AiOutlineCaretRight />
                </div>
              ) : (
                <div className="m-4">
                  <AiOutlineCaretLeft /> Daralt
                </div>
              )}
            </span>
          )}
          <p className="mt-5 mb-3 text-muted text-center">&copy; 2020</p>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default SideBar;

// const DisabledMenu = ({ text, icon, conditions = true, collapsed }) => {
//   return (
//     <p className={conditions ? "d-none" : "text-secondary ml-4"}>
//       {icon} <span className={collapsed ? "d-none" : "ml-3"}>{text}</span>
//     </p>
//   );
// };

const SideBarComponent = ({ sideBarData, location }) => {
  return (
    <Menu
      iconShape="square"
      //popperArrow={true}
    >
      {sideBarData.map((sub, index) => (
        <SubMenu
          key={index}
          className={sub.conditions ? null : "text-secondary"}
          title={sub.title}
          icon={sub.icon}
          //open={true}
          prefix={sub.prefix}
          suffix={sub.suffix}
          defaultOpen={sub.defaultOpen}
        >
          {sub.menuItem.map((menu, index) => (
            <MenuItem
              key={index}
              active={location.pathname === menu.page}
              prefix={menu.prefix}
              suffix={menu.suffix}
              className={menu.conditions ? null : "text-secondary"}
            >
              {menu.title}
              <Link to={menu.conditions ? menu.page : location.pathname} />
            </MenuItem>
          ))}
        </SubMenu>
      ))}
    </Menu>
  );
};

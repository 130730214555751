import React, { useState, useEffect, useContext } from "react";
import { Row, ButtonGroup, Button, Form, Col, Alert, Badge,Breadcrumb, BreadcrumbItem} from "reactstrap";
import InputA from "../../common/inputA";
import InputDropdownA from "../../common/inputDropdownA";
import * as ROUTES from "../../base/routes";
// import PartnerPraview from "./partnerPreview";
import { AuthContext } from "../../base/auth";

const getAge = (dateString) => {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const PartnerEdit = ({
  history,
  currentPartner,
  setCurrentPartner,
  numberOfPartners,
  numberOfNonPartnerManager,
  partners,
  setPartners,
}) => {
  const { currentCompany, base } = useContext(AuthContext);
  const [editMode, setEditMode] = useState(false);
  const [isPratner, setIsPartner] = useState(false);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (!currentCompany?.companyType) return;
    if (currentCompany?.companyType.includes("Şahıs")) {
      if (partners) {
        setCurrentPartner(partners[0]);
      }
      setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany]);

  useEffect(() => {
    if (currentPartner === 0) return;
    if (editMode) return;
    setEditMode(true);
  }, [currentPartner, editMode]);

  const handleChange = (value) => {
    if (value === "partner") {
      setCurrentPartner(0);
      setIsPartner(true);
      setIsManager(false);
      setEditMode(true);
    }
    if (value === "nonPartnerManager") {
      setCurrentPartner(0);
      setIsPartner(false);
      setIsManager(true);
      setEditMode(true);
    }
  };

  const handleNext = () => {
    base.syncStep({ newStep: 8, currentCompany, history });
  };

  return (
    <>
      {!editMode ? (
        <>
           <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Ortak Bilgileri
            </BreadcrumbItem>
          </Breadcrumb>

          <Button
            color="success"
            className="m-2 btn-block"
            size="sm"
            onClick={() => handleChange("partner")}
          >
            Yeni ortak ekle
          </Button>

          <Button
            color="secondary"
            className="m-2 btn-block"
            size="sm"
            onClick={() => handleChange("nonPartnerManager")}
          >
            Ortak olmayan yönetici ekle
          </Button>

          <hr />
          {numberOfPartners === 0 && <Alert color="warning">En az bir ortak eklemelisiniz</Alert>}
          <Button
            className="my-3"
            onClick={() => history.push(ROUTES.TYPERESULT)}
            color="secondary"
          >
            Geri{" "}
          </Button>
          <Button
            color="primary"
            className="m-3"
            onClick={() => handleNext()}
            disabled={numberOfPartners === 0}
          >
            Sonraki adıma geç
          </Button>
        </>
      ) : (
        <IdInfo
          currentPartner={currentPartner}
          setCurrentPartner={setCurrentPartner}
          setEditMode={setEditMode}
          isPratner={isPratner}
          isManager={isManager}
          history={history}
          partners={partners}
          setPartners={setPartners}
        />
      )}
    </>
  );
};

export default PartnerEdit;

const IdInfo = ({
  history,
  currentPartner,
  setCurrentPartner,
  setEditMode,
  isPratner,
  isManager,
  partners,
  setPartners,
}) => {
  const { currentCompany, userData, base } = useContext(AuthContext);
  const initualFormValue = {
    name: { value: "", error: "" },
    surName: { value: "", error: "" },
    idNumberTC: { value: "", error: "" },
    idSerial: { value: "", error: "" },
    idSerial1: { value: "", error: "" },
    idSerial2: { value: "", error: "" },
    placeOfBirth: { value: "", error: "" },
    dateOfBirth: { value: "", error: "" },
    eMail: { value: "", error: "" },
    cellPhone: { value: "", error: "" },
    cellPhoneOperator: { value: "", error: "" },
  };

  const [idType, setidType] = useState(1);
  const [validation, setValidation] = useState(false);
  const [formData, setFormdata] = useState(initualFormValue);
  const [age, setAge] = useState(0);
  const [ageCheck, setAgeCheck] = useState(false);

  useEffect(() => {
    const formdata = Object.values(formData).every(
      (item) => (item.value !== "" && item.error === "") || !item.required
    );
    setValidation(formdata && ageCheck);
  }, [formData, ageCheck]);

  useEffect(() => {
    const check = age >= 18 && age <= 105;
    if (!check) {
      formData.dateOfBirth.error = "Geçerli bir doğum tarihi giriniz";
    }
    if (check) {
      formData.dateOfBirth.error = "";
    }
    setAgeCheck(check);
  }, [age, formData.dateOfBirth]);

  const companyNameSet = (unvan) => {
    base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyName: unvan },
    });
    base.updateCompanyToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyName: unvan },
    });
  };

  useEffect(() => {
    if (currentPartner === 0) return;
    if (!partners) return;
    if (partners.length === 0) return;

    let filtered = partners.filter((x) => x.id === currentPartner);
    if (currentCompany?.companyType.includes("Şahıs")) {
      filtered = partners;
    }
    const getDataFromRecord = (formPath, statePath) => {
      const formValueObj = { ...formData };
      formValueObj[formPath].value = statePath;
      setFormdata(formValueObj);
    };
    getDataFromRecord("name", filtered[0].name);
    getDataFromRecord("surName", filtered[0].surName);
    getDataFromRecord("idNumberTC", filtered[0].idNumberTC);
    getDataFromRecord("idSerial", filtered[0].idSerial);
    getDataFromRecord("placeOfBirth", filtered[0].placeOfBirth);
    getDataFromRecord("dateOfBirth", filtered[0].dateOfBirth);
    getDataFromRecord("eMail", filtered[0].eMail);
    getDataFromRecord("cellPhone", filtered[0].cellPhone);
    getDataFromRecord("cellPhoneOperator", filtered[0].cellPhoneOperator);
    const date = filtered[0].dateOfBirth;

    setAge(getAge(date));

    setValidation(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartner, currentCompany]);

  const handleSave = async () => {
    if (!currentPartner) {
      const newPartner = {
        id: formData.idNumberTC.value,
        name: formData.name.value,
        surName: formData.surName.value,
        idNumberTC: formData.idNumberTC.value,
        idSerial: formData.idSerial.value,
        placeOfBirth: formData.placeOfBirth.value,
        age: age,
        dateOfBirth: formData.dateOfBirth.value,
        eMail: formData.eMail.value,
        cellPhone: formData.cellPhone.value,
        cellPhoneOperator: formData.cellPhoneOperator.value,
        partner: isPratner,
        manager: isManager,
        manageWith: [],
        authType: "",
        authTime: "",
      };

      await base.addNewPartner({
        companyID: userData.currentCompany,
        data: newPartner,
      });

      if (currentCompany?.companyType.includes("Şahıs")) {
        base.syncStep({ newStep: 8, currentCompany, history });
        const name = `${formData?.name?.value} ${formData?.surName?.value}`;
        companyNameSet(name);
        return;
      }
      setCurrentPartner(0);
      setEditMode(false);
      return;
    }
    const filtered = partners.filter((x) => x.id === currentPartner);

    filtered[0].name = formData.name.value;
    filtered[0].surName = formData.surName.value;
    filtered[0].idNumberTC = formData.idNumberTC.value;
    filtered[0].idSerial = formData.idSerial.value;
    filtered[0].placeOfBirth = formData.placeOfBirth.value;
    filtered[0].dateOfBirth = formData.dateOfBirth.value;
    filtered[0].age = age || 0;
    filtered[0].eMail = formData.eMail.value;
    filtered[0].cellPhone = formData.cellPhone.value;
    filtered[0].cellPhoneOperator = formData.cellPhoneOperator.value;

    await base.addNewPartner({
      companyID: userData.currentCompany,
      data: filtered[0],
    });
    // base.updateCompanyToDb({
    //   companyID: userData.currentCompany,
    //   updateKeys: { partner: partners },
    // });
    if (currentCompany?.companyType.includes("Şahıs")) {
      base.syncStep({ newStep: 8, currentCompany, history });
      return;
    }
    setCurrentPartner(0);
    setEditMode(false);
  };

  const handleDelete = async () => {
    if (currentPartner === 0) {
      setEditMode(false);
      return;
    }
    await base.deletePartner({
      companyID: userData.currentCompany,
      dataID: currentPartner,
    });
    setEditMode(false);
    setCurrentPartner(0);
  };
  const handleCancel = () => {
    setCurrentPartner(0);
    setEditMode(false);
  };

  useEffect(() => {
    const date = formData.dateOfBirth.value;
    setAge(getAge(date));
  }, [formData.dateOfBirth.value]);

  return (
    <div>
      <h5> Kimlik Bilgileri</h5>
      <Form>
        <ButtonGroup className="my-3">
          <Button
            className="btn-sm"
            color="primary"
            onClick={() => setidType(1)}
          >
            Yeni Kimlik Kartı
          </Button>
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setidType(2)}
          >
            Eski Kimlik
          </Button>
        </ButtonGroup>
        <Row>
          <Col>
            <InputA
              type="text"
              inputType="text"
              label="Ad"
              name="name"
              formValue={formData}
              setFormValue={setFormdata}
              maxLength={150}
              minLength={3}
            />
          </Col>
          <Col>
            <InputA
              type="text"
              inputType="text"
              label="Soyad"
              name="surName"
              formValue={formData}
              setFormValue={setFormdata}
              //placeHolder="Adınızı Giriniz"
              maxLength={150}
              minLength={2}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputA
              type="text"
              inputType="number"
              label="TC Kimlik No"
              name="idNumberTC"
              formValue={formData}
              setFormValue={setFormdata}
              //placeHolder="Adınızı Giriniz"
              maxLength={11}
              minLength={11}
            />
          </Col>
          <Col className={idType === 1 ? null : "d-none"}>
            <InputA
              type="text"
              inputType="text"
              label="Seri No"
              name="idSerial"
              formValue={formData}
              setFormValue={setFormdata}
              //placeHolder="Adınızı Giriniz"
              maxLength={9}
              minLength={9}
              required={idType === 1}
            />
          </Col>
          <Col className={idType === 2 ? null : "d-none"}>
            <Row>
              <Col md={4}>
                <InputA
                  type="text"
                  inputType="text"
                  label="Seri No"
                  name="idSerial1"
                  formValue={formData}
                  setFormValue={setFormdata}
                  //placeHolder="Adınızı Giriniz"
                  maxLength={3}
                  minLength={3}
                  required={idType === 2}
                />
              </Col>
              <Col>
                <InputA
                  type="text"
                  inputType="text"
                  label="Seri No"
                  name="idSerial2"
                  formValue={formData}
                  setFormValue={setFormdata}
                  //placeHolder="Adınızı Giriniz"
                  maxLength={6}
                  minLength={6}
                  required={idType === 2}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputA
              type="text"
              inputType="text"
              label="Doğum Yeri"
              name="placeOfBirth"
              formValue={formData}
              setFormValue={setFormdata}
              //placeHolder="Adınızı Giriniz"
              //maxLength={2}
              minLength={3}
            />
          </Col>
          <Col>
            <InputA
              type="date"
              inputType="text"
              label={age ? `Doğum Tarihi - Yaş ${age} ` : "Doğum Tarihi"}
              name="dateOfBirth"
              formValue={formData}
              setFormValue={setFormdata}
              //placeHolder="Adınızı Giriniz"
              //maxLength={2}
              minLength={10}
            />
          </Col>
        </Row>

        <h5 className="mt-4"> İletişim Bilgileri</h5>

        <InputA
          type="email"
          inputType="text"
          label="e-Posta"
          name="eMail"
          formValue={formData}
          setFormValue={setFormdata}
          maxLength={150}
          minLength={1}
        />
        <InputA
          type="text"
          inputType="number"
          label="Cep Telefonu"
          name="cellPhone"
          formValue={formData}
          setFormValue={setFormdata}
          maxLength={11}
          minLength={11}
        />

        <InputDropdownA
          label="GSM Operatörü"
          name="cellPhoneOperator"
          formValue={formData}
          setFormValue={setFormdata}
          className="btn-sm"
          options={["Turkcell", "Vodafone", "Türk Telekom"]}
        />

        {!validation ? (
          <Alert className="my-3" color="warning">
            Tüm Alanları Doldurunuz
          </Alert>
        ) : null}
      </Form>
      <Button className="my-3" onClick={() => handleCancel()} color="secondary">
        {" "}
        Vazgeç{" "}
      </Button>
      <Button
        className="m-3"
        disabled={!validation}
        onClick={() => handleDelete()}
        color="danger"
      >
        {" "}
        Sil{" "}
      </Button>
      <Button
        className="m-3"
        disabled={!validation}
        onClick={() => handleSave()}
        color="primary"
      >
        {" "}
        Kaydet{" "}
      </Button>
    </div>
  );
};

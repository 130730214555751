import React from "react";
import { Label, Input, FormFeedback, Col, FormText } from "reactstrap";

const FormSelect = ({ name, label, error, options, helpMessage,col="", ...rest }) => {
  return (
    <Col md={col}>
      <Label className="" for={name}>
        {label}
      </Label>
      <Input type="select" {...rest} name={name} id={name} invalid={error}>
        {options.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </Input>
      {helpMessage && <FormText>{helpMessage}</FormText>}
      {error && <FormFeedback tooltip> {error}</FormFeedback>}
    </Col>
  );
};

export default FormSelect;

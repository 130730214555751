import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../base/auth";

// to-do 

// pagination son sayfa geldiğinde tekrar çalıştırma

const useDbData = ({
  collectionName,
  docID,
  query,
  listener = false,
  queryArray,
  limit,
}) => {
  const { base, currentCompany, superAdmin, userData } =
    useContext(AuthContext);

  const [collections, setCollections] = useState(null);
  const [referance, setReferance] = useState(null);

  const [dbData, setDBData] = useState([]);

  const [lastDoc, setLastDoc] = useState(null);
  const [errorDBMessage, setErrorDbMesage] = useState(null);
  const [triger, setTriger] = useState(false);
  const [nextDataTriger, setNextDataTriger] = useState(false);



  useEffect(() => {
    // queryArray değiştiğinde setData sıfırlanıyor.
    // yoksa pagination hatalı gelir. eski datayı da getirir.
    setDBData([]);
  }, [queryArray]);


  const reFresh = () => {
    if (!listener) setTriger(!triger);
  };

  const getMoreData = () => {
    setNextDataTriger(!nextDataTriger)
  };

  // get methots
  const dbGetDoc = async (referance) => {
    const doc = await referance.doc(docID).get();
    console.log("dbGetDoc");
    if (!doc.exists) {
      setErrorDbMesage("Böyle bir döküman yok!");
    } else {
      setDBData([{ id: doc.id, ...doc.data() }]);
    }
  };

  const dbGetCollection = async (referance) => {
    console.log("dbGetCollection");
    const snapshot = await referance.get();
    let array = [];
    if (limit && dbData.length > 0) array = [...dbData];
    snapshot.forEach((doc) => {
      array.push({ id: doc.id, ...doc.data() });
      console.log(doc.data())
      
      // gelen son dökümanı saklıyorum. pagination
      // için bir sonraki aramada lazım olacak.
      setLastDoc(doc);
    });
    setDBData(array);
    return;
  };

  //Listener methots
  const dbListenerCollection = (referance) => {
    return referance.onSnapshot(
      (querySnapshot) => {
        console.log("dbListenerCollection");
        const array = [];
        querySnapshot.forEach((doc) => {
          if (!doc.exists) {
            setErrorDbMesage("Böyle bir döküman yok!");
          } else {
            array.push({ id: doc.id, ...doc.data() });
            // gelen son dökümanı saklıyorum. pagination
            // için bir sonraki aramada lazım olacak.
            setLastDoc(doc);
          }
        });
        setDBData(array);
      },
      (err) => {
        setErrorDbMesage(`Hata: ${err}`);
      }
    );
  };

  const dbListenerDoc = (referance) => {
    return referance.doc(docID).onSnapshot((doc) => {
      console.log("dbListenerDoc");
      if (!doc.exists) setErrorDbMesage("Böyle bir döküman yok!");
      else setDBData({ id: doc.id, ...doc.data() });
    });
  };

  // setData

  const idCheck = (id) => {
    if (!id) {
      console.log("id Boş Olamaz");
      return false;
    }
    return true;
  };

  const dataCheck = (data) => {
    if (!data) {
      console.log("data Boş Olamaz");
      return false;
    }
    return true;
  };

  const setDataToDb = async ({ data, id }) => {
    if (!idCheck(id) || !dataCheck(data)) return;
    const res = await referance.doc(id).set(data);
    reFresh();
    return res;
  };

  const addDataToDb = async ({ data }) => {
    if (!dataCheck(data)) return;
    const res = await referance.add(data);
    reFresh();
    console.log("Eklendi")
    return res.id;
  };

  const updateDataToDb = async ({ data, id }) => {
    if (!idCheck(id) || !dataCheck(data)) return;
    const ref = await referance.doc(id).update(data);
    reFresh();
    return ref;
  };

  const deleteDataFromDb = async ({ id }) => {
    if (!idCheck(id)) return;
    const ref = await referance.doc(id).delete();
    reFresh();
    return ref;
  };

  useEffect(() => {
    if (!collectionName) {
      setErrorDbMesage("collecionName Boş Olamaz");
      return;
    }

    if (!currentCompany?.id || !userData?.id) return;
    const companyID = currentCompany?.id;
    const companies = superAdmin
      ? base.firestore.collection("companies")
      : base.firestore
          .collection("companies")
          .where(`users.${userData?.id}.role`, "in", [
            "owner",
            "admin",
            "user",
          ]);

    const collections = {
      companies: companies,
      products:base.firestore.collection("products"),
      files: base.firestore
        .collection("companies")
        .doc(companyID)
        .collection("files"),
      data: base.firestore
        .collection("companies")
        .doc(companyID)
        .collection("data"),
      partners: base.firestore
        .collection("companies")
        .doc(companyID)
        .collection("data")
        .doc("aplicationData")
        .collection("partners"),
    };

    const keys = Object.keys(collections);
    if (!keys.some((key) => key === collectionName)) {
      setErrorDbMesage(
        `collectionName:${collectionName} geçerli değil bunlardan biri olmalı : ${keys}`
      );
      return;
    }
    setCollections(collections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, currentCompany?.id, superAdmin, userData?.id]);

  useEffect(() => {
    if (!collections) return;
    let ref = collections[collectionName];
    if (!ref) return;
    if (queryArray) {
      queryArray.forEach((item) => {
        const queryRef = ref.where(item.property, item.operator, item.value);
        ref = queryRef;
      });
    }
    if (lastDoc) ref = ref.startAfter(lastDoc);
    if (limit) ref = ref.limit(limit);

    setReferance(ref);
  }, [collections, queryArray, limit,nextDataTriger]);

  useEffect(() => {
    if (!referance) return;

    const unsubscribe = async () => {
      if (docID) {
        if (listener) {
          dbListenerDoc(referance);
        } else {
          await dbGetDoc(referance);
        }
      } else {
        if (listener) {
          dbListenerCollection(referance);
        } else {
          await dbGetCollection(referance);
        }
      }
    };
    unsubscribe();
    return () => {
      if (!listener) return;
      unsubscribe();
      console.log("unmount");
      return;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docID, triger, referance]);

  useEffect(() => {
    if (dbData.length !== 0) setErrorDbMesage(null);
  }, [dbData]);

  const capitalize = (s) => {
    if (!s) return null;
    return s[0].toUpperCase() + s.slice(1);
  };

  return {
    [collectionName]: dbData,
    errorDBMessage,
    reFresh,
    getMoreData, // for pagination
    [`set${capitalize(collectionName)}`]: setDataToDb,
    [`add${capitalize(collectionName)}`]: addDataToDb,
    [`update${capitalize(collectionName)}`]: updateDataToDb,
    [`delete${capitalize(collectionName)}`]: deleteDataFromDb,
  };
};

export default useDbData;

import React from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";

const TableHead = ({ columns, sortColumns, setTableData, handleOnSort }) => {
  const renderSortIcon = (column) => {
    if (column?.key !== sortColumns?.path) return null;
    if (sortColumns?.order === "asc") return <AiOutlineSortAscending />;
    return <AiOutlineSortDescending />;
  };

  return (
    <thead className="">
      <tr>
        {columns.map((column) => (
          <th
            className="pointer"
            key={column.path || column.key}
            onClick={() => handleOnSort(column.path || column.key)}
          >
            {column.label} {renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import React from "react";
import { BrowserRouter as  useLocation } from "react-router-dom";

import { withRouter } from "react-router";

import { AuthContext } from "../../base/auth";


//import { capSentence } from "../../common/functions";

import {  Button } from "reactstrap";
import Sign from "../../components/signIn/sign";
import Loading from "../../common/loading";
const Invites = ({ history }) => {
  const { currentUser, base } = React.useContext(AuthContext);

  const useQuery = (name) => {
    const query = new URLSearchParams(useLocation().search);
    const result = query.get(name);
    return result;
  };

  const [inviteID] = React.useState(useQuery("inv"));
  // const [isSameUser, setIsSameUser] = React.useState(false);
  const [invData, setInvData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [disabledAcceptBtn, setDisabledAcceptBtn] = React.useState(false);
  // const [isAccept, setIsAccept] = React.useState(false);

  // http://localhost:3000/invites?inv=vEzbUoO3YDELjpDBRynR


  const getInvDataFromDB = async () => {
    const data = await base.getInvitationData({ invitationID: inviteID });
    setInvData(data);
    setLoading(false);
    return;
  };

  React.useEffect(() => {
    getInvDataFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccept = React.useCallback(async () => {
    try {
      const data = await base.acceptInv({
        inviteID: inviteID,
        // companyID: invData.companyID,
        //userData: JSON.stringify(userData),
      });
      console.log(data);
      if (data === true) history.push("/");
    } catch (error) {
      console.log(error);
    }
  }, [base, history, inviteID]);

  const acceptInvitation = () => {
    setDisabledAcceptBtn(true);
    if (currentUser) {
      // davetli e postası mevcut kullanıcı ile aynı ise yetkilendirme yap
      if (currentUser?.email === invData?.toEmail) {
        handleAccept();
        //console.log("aynı kullanıcı", currentUser.uid, inviteID);
        //console.log("userData", userData);
        // daveti onayla
      }
      // davetli e postası mevcut kullanıcı ile farklı ise çıkış yaptır
      if (currentUser?.email !== invData?.toEmail) {
        base.doSignOut();
        console.log("Farklı kullanıcı oturum açmış mevcut ourum kapatıldı");
      }
    }
  };

  if (loading) return <Loading />;
  if (invData === "No such document!" )
    return (
      <div className="text-center col-md-3 container mt-5 ">
        <strong>Hatalı davetiye</strong>
        <p> Davetiye linki hatalı</p>
        <hr />
        <Button
          className="m-3"
          color="primary"
          onClick={() => history.push("/")}
        >
          {" "}
          Uygulaymaya Dön
        </Button>
      </div>
    );

  if (!invData.isValid)
    return (
      <div className="text-center col-md-3 container mt-5 ">
        <strong>Hatalı davetiye</strong>
        <p> Bu davetiye daha önce kullanılmış.</p>
        <hr />
        <Button
          className="m-3"
          color="primary"
          onClick={() => history.push("/")}
        >
          {" "}
          Uygulaymaya Dön
        </Button>
      </div>
    );

  return (
    <>
      <div className="text-center col-md-3 container mt-5 beyaz">
        <strong>{invData?.fromDisplayName}</strong> sizi Yeni Şirketkur
        uygulamasına davet etti.
        <hr />
        {currentUser?.email === invData?.toEmail ? (
          <Button
            className="m-3"
            onClick={() => acceptInvitation()}
            color="primary"
          >
            {" "}
            Daveti Kabul Et
          </Button>
        ) : null}
      </div>
      {currentUser?.email !== invData?.toEmail ? (
        <Sign eMail={invData?.toEmail} userName={invData?.toDisplayName} />
      ) : null}
    </>
  );
};

export default withRouter(Invites);

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../base/auth";

import {
  Badge,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Button,
  Alert,
} from "reactstrap";

import EditEstablishTask from "./edit";
import UploadFile from "../../../common/uploadFile";

/*
  const mailOptions = {
    from: 'email1@gmail.com', // sender address
    to: 'email2@gmail.com', // list of receivers
    subject: "Hey this is a subject example", //subject
    attachments: [
      {
        filename: `myfile.pdf`,
        content: 'THISISAB64STRING',
        encoding: 'base64',
      },
    ],
  };
*/

const AdminEstablish = ({ history }) => {
  const { currentCompany, base, userData } = useContext(AuthContext);

  const [selectedItem, setSelectedItem] = useState(null);

  const [establishTasks, setEstablishTasks] = useState([]);

  useEffect(() => {
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getEstablishTaskData(
        { companyID: userData.currentCompany },
        (data) => {
          if (!data) return;
          let array = [];
          for (const item in data) {
            if (item !== "id") array.push(data[item]);
          }
          const sort = array.sort((a, b) => a.id - b.id);
          setEstablishTasks(sort);
        }
      );
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, userData.currentCompany]);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getFileData(
        {
          companyID: userData.currentCompany,
          //query:{proberty:"category",operator:"in" ,value:["AnaSözleşme"]}
        },
        (data) => {
          if (!data) return;
          // let array = [];
          // for (const item in data) {
          //   if (item !== "id") array.push(data[item]);
          // }
          // const sort = array.sort((a, b) => a.id - b.id);
          setFiles(data);
        }
      );
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, userData.currentCompany]);

  const handleStartEstablish = async () => {
    await base.callFunction({
      objData: { companyID: userData.currentCompany },
      functionName: "setInitialEstablishTask",
    });

    await base.syncStep({ newStep: 16, currentCompany, history });
    await base.updateCompanyToDb({
      companyID: userData.currentCompany,
      updateKeys: { collectLock: true },
    });
  };

  const handleChangeLockStatus = async () => {
    const lock = !currentCompany.collectLock;
    await base.updateCompanyToDb({
      companyID: userData.currentCompany,
      updateKeys: { collectLock: lock },
    });
  };

  const handleComplete = async () => {
    await base.syncStep({ newStep: 20, currentCompany, history });
  }
  const handleCancelComplete = async () => {
    await base.syncStep({ newStep: 17, currentCompany, history });
  }

  return (
    <>
      <Button onClick={() => handleStartEstablish()} color="primary">
        Kuruluşu Başlat
      </Button>


      <Button className="mx-3" onClick={() => handleChangeLockStatus()}>{currentCompany.collectLock ? "Kilidi Aç" : "Kilitle"}</Button>
  
      <Button className="mx-3" onClick={() => handleComplete()} color="primary">
        Tamamlandı Olarak İşaretle
      </Button>
      <Button className="mx-3" onClick={() => handleCancelComplete()} color="primary">
        Tamamlandı Geri al
      </Button>
      <br />
 
      <Row>
        <Col md={8} className="mt-3">
          {establishTasks.length > 0
            ? establishTasks.map((x, i) => (
                <ListGroup key={i} onClick={() => setSelectedItem(i)}>
                  <ListGroupItem color={x.color}>
                    <Badge color={x.color}>{x.status}</Badge> {x.task}
                  </ListGroupItem>
                </ListGroup>
              ))
            : null}
        </Col>
        <EditEstablishTask
          establishTasks={establishTasks}
          selectedItem={selectedItem}
          base={base}
          userData={userData}
          files={files}
        />
      </Row>
    </>
  );
};

export default AdminEstablish;

import React from 'react';

import { Switch, Route, useRouteMatch } from "react-router-dom";

import * as ROUTES from "../../../base/routes"
import { AuthContext } from '../../../base/auth';
import UserList from './userList';
import NewUser from './newUser';
import UserEdit from './userEdit';
import InvitationEdit from './userInvitationEdit';




const Users = ({ history }) => {
    let { path } = useRouteMatch();
    const { currentCompany, base } = React.useContext(AuthContext);

    const [userListArray, setUserListArray] = React.useState([])
    const [invitationArray, setInvitationArray] = React.useState([])

    const [selectedItem, setSelectedItem] = React.useState([])

    const handleOpenEditUser = (uid) => {
        const array = [...userListArray]
        const filteredArray = array.filter(x => x.uid === uid);

        setSelectedItem(...filteredArray)
        history.push(ROUTES.USER_EDIT)
    }

    const handleOpenEditInvitation = (id) => {
        const array = [...invitationArray]
        const filteredArray = array.filter(x => x.id === id);

        setSelectedItem(...filteredArray)
        history.push(ROUTES.USER_INVITATION_EDIT)
    }

    const margeUserData = async () => {
        if (!currentCompany.users) return
        // invitation kolleksiyonundan veri alınması
        const result = await base.getInvitation({ currentCompany })
        if (typeof result !== "undefined") {
            const filteredResult = result.filter(x => x.isValid)
            setInvitationArray(filteredResult)
        }


        // currentCompany den veri alınması
        const obj = currentCompany.users;
        const array = [];
        for (const property in obj) {
            const uid = property;
            const displayName = obj[property]?.displayName
            const email = obj[property]?.email
            const modules = obj[property]?.modules
            const role = obj[property]?.role
            let roleDisplay = "";
            if (role === "owner") roleDisplay = "Hesap Sahibi";
            if (role === "admin") roleDisplay = "Yönetici";
            if (role === "user") roleDisplay = "Kullanıcı";
            array.push({ uid, displayName, email, modules, role, roleDisplay })
        }
        setUserListArray(array)
    }

    React.useEffect(() => {
        margeUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompany, currentCompany.users])


    return (<>

        <Switch>
            <Route exact path={`${path}`}>
                <UserList
                    history={history}
                    userListArray={userListArray}
                    invitationArray={invitationArray}
                    handleOpenEditUser={handleOpenEditUser}
                    handleOpenEditInvitation={handleOpenEditInvitation}
                />
            </Route>
            <Route path={ROUTES.NEW_USER}>
                <NewUser
                    history={history}
                    margeUserData={margeUserData}
                />
            </Route>
            <Route path={ROUTES.USER_EDIT}>
                <UserEdit
                    history={history}
                    selectedItem={selectedItem}
                />
            </Route>
            <Route path={ROUTES.USER_INVITATION_EDIT}>
                <InvitationEdit
                    history={history}
                    selectedItem={selectedItem}
                    margeUserData={margeUserData}
                />
            </Route>
        </Switch>

    </>);
}

export default Users;

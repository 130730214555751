import React from "react";

import { Button } from "reactstrap";
import { AiOutlineMenu } from "react-icons/ai";
import ProfileMenu from "../profileMenu/profileMenu";

const Header = ({
  history,
  setCollapsed,
  closeMenu,
  setCloseMenu,
  isPhone,
}) => {
  const changeMenu = () => {
    if (isPhone) {
      setCollapsed(false);
      setCloseMenu(!closeMenu);
      return;
    }
    //setCollapsed(!collapsed);
  };

  return (
    <div className="sticky-top row header justify-content-between ">
      <div className="col-1 p-0 text-white">
        {isPhone && (
          <Button color="primary" onClick={() => changeMenu()}>
            <AiOutlineMenu />
          </Button>
        )}
      </div>
      {/* <div className="col"></div> */}

      <div className="col text-white d-inline-flex justify-content-end">
        <ProfileMenu history={history} />
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// TO-DO
// partner preview mobilde kafa karıştırıyor.

import * as ROUTES from "../../base/routes";
// import IdInformation from "./idInformation";
// import ContactInfo from "./contactInfo";
// import NumberOfPartner from "./numberOfPartners";
import PartnerPraview from "./partnerPreview";

import Authorities from "./authorities";
import Representation from "./representation";
import CompanyCapital from "./companyCapital";

// import { AuthContext } from "../../base/auth";
import PartnerEdit from "./partnerEdit";

const Partners = ({
  history,
  userData,
  setStep,
  partners,
  setPartners,
  numberOfPartners,
  numberOfNonPartnerManager,
  currentPartner,
  setCurrentPartner,
  aplicationData,
}) => {
  let { path } = useRouteMatch();


  // const { base, currentCompany } = React.useContext(AuthContext);

  const initialPartners = {
    name: "",
    surName: "",
    idNumberTC: "",
    idSerial: "",
    placeOfBirth: "",
    dateOfBirth: "",
    eMail: "",
    cellPhone: "",
    cellPhoneOperator: "",
    partner: false,
    manager: false,
    manageWith: [],
    authType: "",
    authTime: "",
  };

  // const handleSaveToDB = () => {
  //   const sumOfPartner = partners.length;
  //   if (currentPartner < sumOfPartner) {
  //     setCurrentPartner(currentPartner + 1);
  //     history.push(ROUTES.IDINFO);
  //   }
  //   if (currentPartner === sumOfPartner) {
  //     base.updateCompanyToDb({
  //       companyID: userData.currentCompany,
  //       updateKeys: { partner: partners },
  //     });

  //     base.syncStep({ newStep: 8, currentCompany, history });
  //   }
  // };

  const handleUpdateCurrentPartner = (key, value) => {
    const array = [...partners];
    array[currentPartner - 1][key] = value;
    setPartners([...array]);
  };

  // const ustbilgi =
  //   typeof partners !== "undefined" ? (
  //     <>
  //       <h5>
  //         {partners[currentPartner - 1]?.partner
  //           ? "Ortak"
  //           : "Ortak Olmayan Yönetici"}
  //       </h5>
  //       <h5>
  //         {currentPartner} / {partners.length}{" "}
  //         {partners[currentPartner - 1]?.name} -{" "}
  //         {partners[currentPartner - 1]?.surName}
  //       </h5>
  //     </>
  //   ) : null;

  return (
    <>
      <Row>
        <Col md="6">
       
          <Switch>
            <Route exact path={`${path}`}>
              <PartnerEdit
                partners={partners}
                initialPartners={initialPartners}
                setPartners={setPartners}
                numberOfPartners={numberOfPartners}
                currentPartner={currentPartner}
                handleUpdateCurrentPartner={handleUpdateCurrentPartner}
                history={history}
                numberOfNonPartnerManager={numberOfNonPartnerManager}
                setCurrentPartner={setCurrentPartner}
              />
            </Route>
            <Route path={ROUTES.AUTHORITIES}>
              <br />
              <Authorities
                partners={partners}
                setStep={setStep}
                history={history}
                numberOfPartners={numberOfPartners}
                numberOfNonPartnerManager={numberOfNonPartnerManager}
                setCurrentPartner={setCurrentPartner}
                currentPartner={currentPartner}
              />
            </Route>
            <Route path={ROUTES.REPRESENTATION}>
              <br />
              <Representation
                partners={partners}
                setStep={setStep}
                history={history}
                numberOfPartners={numberOfPartners}
                numberOfNonPartnerManager={numberOfNonPartnerManager}
                setCurrentPartner={setCurrentPartner}
                currentPartner={currentPartner}
              />
            </Route>

            <Route path={ROUTES.COMCAPITAL}>
              <br />
              <CompanyCapital
                partners={partners}
                setStep={setStep}
                history={history}
                numberOfPartners={numberOfPartners}
                numberOfNonPartnerManager={numberOfNonPartnerManager}
                setCurrentPartner={setCurrentPartner}
                currentPartner={currentPartner}
                aplicationData={aplicationData}
              />
            </Route>
          </Switch>
        </Col>
       <Col md={6}>
          <PartnerPraview
            partners={partners}
            setPartners={setPartners}
            currentPartner={currentPartner}
            setCurrentPartner={setCurrentPartner}
            numberOfPartners={numberOfPartners}
            numberOfNonPartnerManager={numberOfNonPartnerManager}
          />
        </Col>
      </Row>
    </>
  );
};

export default Partners;

import React from "react";

import { Row, Col, Button } from "reactstrap";



const Management = ({ nextPage, handleChange }) => {
 
  const nextStep = async (num) => {
    handleChange(num);
    nextPage()
    // await base.syncStep({ newStep: 5, currentCompany, history });
  };
  return (
    <>
      <Row className="text-center mt-5">
        <Col>
          <h3>Yönetim</h3>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <p>Firmada ortaklar dışında yönetici olacak mı ?</p>

          <p>
            yoksa firma ortakları aynı zamanda yönetici olarak çalışacak mı?
          </p>
        </Col>
      </Row>
      <div className="text-center mt-5 mx-auto">
    
          <Button
            color="primary"
            className="mx-2 my-1"
            onClick={() => nextStep(1)}
          >
            Ortaklar arasından müdür/yönetici olacak
          </Button>
   
    
          <Button
            color="secondary"
            className="mx-2 my-1"
            onClick={() => nextStep(3)}
          >
            Ortaklar müdür/yönetim kurulunda yer almayacak.
          </Button>
   
      </div>
    </>
  );
};

export default Management;

import React from "react";

import ListFiles from "../../common/listFile";
import { Link } from "react-router-dom";
import * as ROUTES from "../../base/routes";
import currency from "currency.js";
import { Card, CardText } from "reactstrap";

const TaskDetail = ({
  item,
  userData,
  savedFiles,
  setSavedFiles,
  files,
  establishTasks,
}) => {
  const tl = (value) =>
    currency(value, { symbol: "", separator: ".", decimal: "," });
  return (
    <Card body>
      {item.status === "İşlemde" && item.task.includes("İnteraktif vergi") && (
        <Link to={ROUTES.IVDPASSWD}>
          İnteraktif Vergi Dairesi Şifre İşlemleri
        </Link>
      )}


        {item?.descripsion && <CardText className="text-primary"> {item.descripsion}</CardText>}
        {/* {meailMessage ? <CardText>İşlem Mesajı: {meailMessage}</CardText> :null} */}
        {item?.cost ? (
          <CardText className="text-primary">Ödeme: {tl(item.cost).format()}TL</CardText>
        ) : null}
     

      <ListFiles
        companyID={userData.currentCompany}
        attachments={item.attachments}
        savedFiles={savedFiles}
        setSavedFiles={setSavedFiles}
        files={files}
        establishTasks={establishTasks}
        selectedItem={Number(item.id) - 1}
        hiddenDelButton={true}
      />

      {item?.nextTaskInfo && (
        <CardText className="text-secondary mt-2">
          Sonraki Adım: {item.nextTaskInfo}
        </CardText>
      )}
    </Card>
  );
};

export default TaskDetail;

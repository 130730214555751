import React from "react";

import { Button, Alert,Breadcrumb, BreadcrumbItem} from "reactstrap";

import * as ROUTES from "../../base/routes";
import { AuthContext } from "../../base/auth";
import Loading from "../../common/loading";

const Authorities = ({ history, partners ,currentPartner}) => {
  const { currentCompany, userData, base } = React.useContext(AuthContext);
  const [validation, setValidation] = React.useState(false);

  const handleChangeManager = async (id) => {

    const filtered = partners.filter(x => x.id === id)[0]
    if(!filtered.partner) return
    filtered.manager = !filtered.manager
    console.log(filtered)
    await base.addNewPartner({companyID:userData.currentCompany,data:filtered})
  };

  React.useEffect(() => {
    if (!partners) return;
    const isValid = partners.some((x) => x.manager);
    setValidation(isValid);
  }, [partners]);

  const handleSave = () => {
    if (partners.legth === 0) return;
    base.syncStep({ newStep: 9, currentCompany, history });
  };

  React.useEffect(() => {
    if(!currentCompany?.companyType) return
    if (currentCompany?.companyType.includes("Şahıs")) {
      console.log("şahıs");
      base.syncStep({ newStep: 9, currentCompany, history });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, history]);

  if (!partners) return <Loading />;
  if (!currentCompany?.companyType) return <Loading />;
  if (currentCompany?.companyType.includes("Şahıs")) return <Loading />;

  return (
    <div>
        <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Yöneticilerin Seçilmesi
            </BreadcrumbItem>
          </Breadcrumb>
      <p>Yönetici olmasını istediklerinize tıklayınız</p>
      {partners.map((partner, index) => (
        <ul
          key={partner.id}
          className="my-1 list-group pointer"
          onClick={() => handleChangeManager(partner.id)}
        >
          <li
            className={
              partner.manager
                ? "list-group-item list-group-item-action list-group-item-primary"
                : "list-group-item list-group-item-action"
            }
          >
            {partner.partner
              ? `${index +1}. Ortak `
              : `${index +1}. Ortak Olmayan Yönetici `}
            <strong>
              {partner.name} {partner.surName}
            </strong>{" "}
            <span
              className={
                partner.manager
                  ? "badge bg-light text-dark"
                  : "badge bg-warning text-dark"
              }
            >
              {" "}
              {partner.manager ? "Yönetici" : "Yalnızca Ortak"}
            </span>{" "}
          </li>
        </ul>
      ))}
      {validation ? null : (
        <Alert color="warning">
          En az bir yönetici seçmelisiniz. Bu yönetici şirketinizi temsil
          etmeye, şirket adına işlem yapmaya yetkili olacaktır.
        </Alert>
      )}

      <Button
        className="my-3"
        onClick={() => history.push(ROUTES.COLPARTNERS)}
        color="secondary"
      >
        {" "}
        Geri{" "}
      </Button>
      <Button
        disabled={!validation}
        className="m-3"
        onClick={() => handleSave()}
        color="primary"
      >
        {" "}
        Kaydet{" "}
      </Button>
    </div>
  );
};
export default Authorities;

import React from "react";

import { Row, Col, Button, ButtonGroup } from "reactstrap";
import { AuthContext } from "../../base/auth";

const TypeResult = ({
  history,
  type,
  partnership,
  investment,
  shareSale,
  management,
  typeUserChoose,
  setTypeUserChoose,
  // step,
  // moveNextStep,
}) => {
  const { base, userData, currentCompany } = React.useContext(AuthContext);
  const [disabled, setDisabled] = React.useState(true);
  React.useEffect(() => {
    if (!currentCompany && !currentCompany?.companyType) return;
    setTypeUserChoose(currentCompany.companyType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany]);

  const updateType = (type) => {
    setTypeUserChoose(type);
    setDisabled(false);
    // base.syncStep({ newStep: 6, currentCompany, history });
  };

  const handleSave = async () => {
    // silinecek
    await base.updateCompanyToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyType: typeUserChoose },
    });
    // silinecek
    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { companyType: typeUserChoose },
    });
    await base.syncStep({ newStep: 7, currentCompany, history });
  };

  return (
    <>
      <Row className="text-center mt-5">
        <Col>
          <h3>Sonuç</h3>
        </Col>
      </Row>

      <Row className=" mt-2">
        <div>
          <ol>
            {partnership === 1 ? (
              <li>
                Tek ortaklı olarak Anomiş Şirket, Limited Şirket, Şahıs Firması
                kurulabilir
              </li>
            ) : null}
            {partnership === 2 ? (
              <li>
                Birden fazla ortağınız olacağından Limited Şirket veya Anonim
                Şirket kurmalısınız.
              </li>
            ) : null}
            {partnership === 3 ? (
              <li>
                51 ve daha fazla ortakla şirket kurabilmek için Anonim şirket
                kurmak zorundasınız
              </li>
            ) : null}

            {investment === 1 ? (
              <li>
                ileride yatırım almayı düşünmediğinizden şirket türünüz Anonim
                olmak zorunda değil
              </li>
            ) : null}
            {investment === 3 ? (
              <li>
                Girişiminiz için yatırım almayı planlıyorsanız Anonim şirket
                kurmanız daha doğru bir karar olacaktır
              </li>
            ) : null}

            {shareSale === 1 ? (
              <li>
                Firmanızı veya hisselerini satmayı planlamadığınız için Limited
                veya Anonim şirket kurmanıza gerek yok.
              </li>
            ) : null}
            {shareSale === 3 ? (
              <li>
                Anonim Şirket kurmak, Nama yazılı hisse senedi bastırmak ve Bu
                hisseleri 2 yıl geçtikten sonra satmanız durumunda ilgili
                satıştan vergi ödemeyeceksiniz. Bu istisnadan faydalanabilmek
                için Anonim Şirket Kurmalısınız
              </li>
            ) : null}

            {management === 1 ? (
              <li>
                Bizzat şirket yönetimine katılacağınız için Limited veya Şahıs
                Firması ile ilerleyebilirisiniz.
              </li>
            ) : null}
            {management === 3 ? (
              <li>
                Şirket ortakları yalnızca sermaye koyup kar ortaklığı yapacak,
                Şirket yönetim ve sorumluluğu ortaklar dışından birine
                verilecekse Anonim Şirket Kurmalısınız.
              </li>
            ) : null}
          </ol>
        </div>
      </Row>
      <Row className="mt-2">
        <Col>
          {type === "Şahıs Firması" ? (
            <p>
              Bu nedenle firmanızı <strong>{type}</strong> olarak kurmanızı
              öneriyoruz. Dilerseniz yine de 10 bin TL veya daha fazla sermaye
              ile tek ortaklı <strong> Limited Şirket</strong> veya 50 Bin TL
              veya daha fazla sermaye ile <strong>Anonim şirket</strong>{" "}
              kurabilirsiniz.
            </p>
          ) : null}
          {type === "Limited Şirket" ? (
            <p>
              Bu nedenle firmanızı <strong>{type}</strong> olarak kurmanızı
              öneriyoruz. Yine de dilerseniz Anonim Şirket de kurabilirsinz.{" "}
            </p>
          ) : null}
          {type === "Anonim Şirket" ? (
            <p>
              Bu nedenle firmanızı <strong>{type}</strong> olarak kurmanızı
              öneririz.
            </p>
          ) : null}
        </Col>
      </Row>

      <div>
        <label htmlFor="">Şirket Türü Seçin: </label>
        <br />
        <ButtonGroup>
          <Button onClick={() => updateType("Şahıs Firması")}>
            Şahıs Firması
          </Button>
          <Button onClick={() => updateType("Limited Şirket")}>
            Limited Şirket
          </Button>
          <Button onClick={() => updateType("Anonim Şirket")}>
            Anonim Şirket
          </Button>
        </ButtonGroup>
      </div>
      <br />
      {typeUserChoose === "Şahıs Firması" ? (
        <Row>
          <Col>
            <h4>Avantajları</h4>
            <ul>
              <li>Kuruluş maliyeti yoktur.</li>
              <li>Aylık giderler daha düşüktür (Muhasebe Ücreti)</li>
              <li>Kuruluş Süresi: Bir saatte tüm başvurular biter.</li>
              <li>Sermayesiz kurulabilir</li>
              <li>
                İşletmeinizi kapatmak isterseniz birkaç günde tasfiye
                edebilirsiniz.
              </li>
            </ul>
          </Col>
          <Col>
            <h4>Dezavantajları</h4>
            <ul>
              <li>
                Sermaye şirketi değildir. Tüm mal varlığınız ile ticari
                işlemlerden sorumlusunuz.
              </li>
              <li>Şirket olmadığı için resmi ortaklık yapılamaz</li>
              <li>Limited ve Anonim şirket gibi kurumsal görünmez</li>
              <li>İşletmenizi hisse devri yoluyla satamazsınız</li>
              <li>Tüm kamu borçlarına karşı tek sorumlu gerçek kişidir.</li>
              <li>Dışarıdan müdür atanamaz</li>
            </ul>
          </Col>
        </Row>
      ) : null}
      {typeUserChoose === "Limited Şirket" ? (
        <Row>
          <Col>
            <h4>Avantajları</h4>
            <ul>
              <li>1 - 50 ortak ile kurulabilir</li>
              <li>
                Sermaye şirketidir, Kuruluşta sermaye ödemesi yapmanız{" "}
                <strong>gerekmez</strong>
              </li>
              <li>
                Ortakların alacaklılara karşı sorumluluğu sermaye ile
                limitlidir.{" "}
              </li>
              <li>Kamu borçlarında ortaklar payları oranında sorumludur</li>
              <li>Hisse/Pay devri yapılabilir</li>
              <li>Yeni ortak eklenip çıkarılabilir</li>
              <li>Dışarıdan müdür atanabilir</li>
              <li>Şahıs firmasına göre daha kurumsal algılanır</li>
              <li>Dilerseniz Anonim Şirkete Çevrilebilir</li>
            </ul>
          </Col>
          <Col>
            <h4>Dezavantaajları</h4>
            <ul>
              <li>Kuruluş Maliyeti 2.500 TL civarındadır</li>
              <li>Muhasebe ücretleri Şahıs firmasında göre yüksektir</li>
              <li>Tasfiye süresi en az 6 aydır</li>
              <li>
                En az bir ortak müdür olarak seçilmelidir (Anonim Şirkette bu
                mecburiyet yoktur.)
              </li>
              <li>
                Anonim Şirketlerde 2 yıl sonra yapılan hisse satışları Gelir
                Vergisine tabi değildir. Limited Şirkette bu avantaj yoktur.
              </li>
              <li>Pay devri noterde yapılır, Ticaret siciline bildirilir.</li>
              <li>En fazla 50 ortak pay sahibi olabilir</li>
              <li>Hisse senedi basılamaz</li>
              <li>Halka arz edilemez</li>
            </ul>
          </Col>
        </Row>
      ) : null}
      {typeUserChoose === "Anonim Şirket" ? (
        <Row>
          <Col>
            <h4>Avantajları</h4>
            <ul>
              <li>1 veya daha fazla ortak ile kurulabilir</li>
              <li>Sermaye şirketidir</li>
              <li>
                Ortakların alacaklılara karşı sorumluluğu sermaye ile
                limitlidir.{" "}
              </li>
              <li>
                Kamu borçlarında ortaklar değil yönetim kurulu başkan ve üyeleri
                sorumludur
              </li>
              <li>
                Hisse/Pay devri yapılabilir Ticaret siciline ilanı zorunlu
                değildir.
              </li>
              <li>
                Anonim Şirketlerde 2 yıl sonra yapılan hisse satışları Gelir
                Vergisine tabi değildir.
              </li>
              <li>Yeni ortak eklenip çıkarılabilir</li>
              <li>Dışarıdan müdür atanabilir</li>
              <li>Limited Şirkete göre daha kurumsal algılanır</li>
              <li>Hisse Senedi Basılabilir</li>
              <li>Halka Arz Edilebilir</li>
            </ul>
          </Col>
          <Col>
            <h4>Dezavantaajları</h4>
            <ul>
              <li>Kuruluş Maliyeti 2.500 TL civarındadır</li>
              <li>Muhasebe ücretleri Şahıs firmasında göre yüksektir</li>
              <li>Tasfiye süresi en az 6 aydır</li>
              <li>En az 50.000 TL sermaye ile kurulabilir</li>
              <li>Kuruluşta sermayenin 1/4'ü ödenmelidir.</li>
              <li>
                250.000 TL ve üzeri sermaye ile kurulan Anonim Şirketlerde
                Avukat ile çalışma zorunluluğu vardır.
              </li>
            </ul>
          </Col>
        </Row>
      ) : null}

      {disabled ? null : (
        <Button color="primary" onClick={() => handleSave()}>
          Şimdi {typeUserChoose} kur
        </Button>
      )}
    </>
  );
};

export default TypeResult;

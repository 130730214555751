import React from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import * as ROUTES from "../../base/routes";
import Users from "./users/users";

//import { AuthContext } from "../../base/auth";
import Companies from "./companies/companies";

const Settings = ({ history, setStep, step }) => {
  let { path } = useRouteMatch();

  //const { currentCompany, userData, base } = React.useContext(AuthContext);

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}></Route>
        <Route path={ROUTES.USERS}>
          <Users history={history} />
        </Route>
        <Route path={ROUTES.COMPANIES}>
          <Companies history={history} />
        </Route>
      </Switch>
    </>
  );
};

export default Settings;

import React from "react";

import { Route, Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import * as ROUTES from "./base/routes";
import SideBar from "./components/sidebar/SideBar";
import Header from "./components/header/header";
import { AiFillWarning } from "react-icons/ai";

import { AuthContext } from "./base/auth";

import DataCollection from "./components/datacollection/datacollection";
import Settings from "./components/settings/settings";
import Establish from "./components/establish/establish";
import Admin from "./components/admin/admin";
import TypeDec from "./components/decideType/type";
import Product from "./components/product/index";

const Home = () => {
  let history = useHistory();
  const { currentUser, userData, companies, currentCompany,superAdmin } =
    React.useContext(AuthContext);

  const [collapsed, setCollapsed] = React.useState(false);
  const [closeMenu, setCloseMenu] = React.useState(true);
  const [isPhone, setIsPhone] = React.useState(false);

  React.useEffect(() => {
    const isPhone = window.innerWidth <= 760;
    setIsPhone(isPhone);
  }, []);
  const [step, setStep] = React.useState(0);
  const [redirctTo, setRedirctTo] = React.useState(ROUTES.HOME);

  React.useEffect(() => {
    // eğer step 0 ise kullanıcının son kaldığı yere yönlendirilmesi
    // if(currentCompany.length === 0) { history.push(ROUTES.COMPANIES)}
    if (currentCompany?.lastPage) {
      if(superAdmin) {
        setRedirctTo(ROUTES.COMPANIES);
        return}
      setRedirctTo(currentCompany.lastPage);
    }
  }, [currentCompany, step, superAdmin]);

  return (
    <Container fluid={true} className="main fill">
      {!currentUser.emailVerified ? (
        <div className="row">
          <div
            className="alert alert-warning m-0 text-center w-100 rounded-0"
            role="alert"
          >
            <AiFillWarning size={30} />{" "}
            <span>e Posta adresiniz doğrulanmamış.</span>
            <u
              className=" pointer"
              onClick={(e) => currentUser.sendEmailVerification()}
            >
              e posta ulaşmadıysa buraya tıklayınız.
            </u>
          </div>
        </div>
      ) : null}
      <Row>
        <SideBar
          closeMenu={closeMenu}
          setCloseMenu={setCloseMenu}
          collapsed={collapsed}
          history={history}
          step={currentCompany.status}
          isPhone={isPhone}
          setIsPhone={setIsPhone}
          setCollapsed={setCollapsed}
        />
        <Col className="overflow-auto fill">
          <Header
            closeMenu={closeMenu}
            setCloseMenu={setCloseMenu}
            isPhone={isPhone}
            setIsPhone={setIsPhone}
            history={history}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            step={currentCompany.status}
            userData={userData}
            companies={companies}
            currentCompany={currentCompany}
          />
          <div className="">
            <Route>
              <Redirect to={redirctTo} />
            </Route>

            <Route exact path={ROUTES.HOME}>
              <TypeDec
                history={history}
                currentCompany={currentCompany}
                userData={userData}
              />
            </Route>
            <Route path={ROUTES.DATACOLLECTION}>
              <DataCollection
                history={history}
                step={currentCompany.status}
                setStep={setStep}
                currentCompany={currentCompany}
                userData={userData}
              />
            </Route>
            <Route path={ROUTES.ESTABLISH}>
              <Establish
                history={history}
                step={currentCompany.status}
                setStep={setStep}
                currentCompany={currentCompany}
                userData={userData}
              />
            </Route>
            <Route path={ROUTES.SETTINGS}>
              <Settings
                history={history}
                step={currentCompany.status}
                setStep={setStep}
              />
            </Route>
            <Route path={ROUTES.ADMIN}>
              <Admin
                history={history}
                step={currentCompany.status}
                setStep={setStep}
              />
            </Route>
            <Route path={ROUTES.PRODUCT}>
              <Product history={history} />
            </Route>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;

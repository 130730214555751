import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../base/auth";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import Loading from "../../../common/loading";
import useDbData from "../../../common/hooks/useDbData";
import ModalA from "../../../common/modal";
import { sortAZ } from "../../../common/functions";
import Table from "../../../common/table/table";

const filterQuery = [
  {
    property: `status`,
    operator: "<",
    value: 17,
  },
];

const Companies = ({ history }) => {
  const { base, userData, superAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(20);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const { companies, reFresh, getMoreData } = useDbData({
    collectionName: "companies",
    docID: "",
    //queryArray: query,
    listener: superAdmin ? true : false,
    //limit: superAdmin ? null : 5,
  });

  useEffect(() => {
    setLoading(false);
  }, [userData]);

  const handleChangeCurrentCompany = async ({ userData, id }) => {
    if (userData.currentCompany === id) return;
    setLoading(true);
    await base.changeCurrentComp({ companyID: id });
    //await base.setCurrentCompanyDB({ userData, companyID: id });
    setLoading(false);
    // history.push(ROUTES.HOME);
  };

  const handleDeleteCompany = async ({ companyID }) => {
    setLoading(true);
    await base.deleteCompany({ companyID });
    reFresh();
    setLoading(false);
  };

  const agree = async ({ companyID }) => {
    await handleDeleteCompany({ companyID });
  };

  const handleCreateCompany = async () => {
    setLoading(true);
    await base.createNewCompany({ displayName: userData.displayName });
    reFresh();
    setLoading(false);
  };

  const initualComuns = [
    { path: "status", label: "Durum" },
    { path: "companyName", label: "Firma Adı" },
    { path: "createdUser", label: "Kullanıcı" },

    {
      key: "id",
      label: "Tarih",
      content: (item) => {
        const time = item.createdAt.toDate().toLocaleString();
        return <span>{time}</span>;
      },
    },
    // {
    //   key: "id",
    //   label: "Tarih",
    //   content: (item) => {
    //     const time = item.createdAt.toDate().getTime();
    //     return <span>{time}</span>;
    //   },
    // },
    {
      key: "id",
      content: (item) => (
        <Button
          className="btn-sm"
          disabled={userData.currentCompany === item.id}
          onClick={() => handleChangeCurrentCompany({ userData, id: item.id })}
        >
          Firma Seç
        </Button>
      ),
    },
    {
      key: "id",
      content: (item) => (
        <ModalA
          buttonLabel="Sil"
          selectedItem={{ companyID: item.id }}
          className="btn-sm"
          title="Firma Silinecek"
          text={`Bu işlem geri alınamaz ${
            item.companyName ? item.companyName + " firmasını" : ""
          } silmek istediğinize emin misiniz ?`}
          buttonCancel="Vazgeç"
          buttonContinue="Sil"
          agree={agree}
        />
      ),
    },
  ];

  useEffect(() => {
    const onlyComp = companies.filter((company) => company.id !== "docCounter");
    const sortedList = sortAZ(onlyComp, "companyName");

    const filterByCreated = sortedList.sort(
      (a, b) => a.createdAt.toDate().getTime() < b.createdAt.toDate().getTime()
    );

    const filteredCompanies = filterByCreated.filter((company) => {
      return company.status !== filter;
    });

    setFilteredCompanies(filteredCompanies);
  }, [companies, filter]);

  if (loading) return <Loading />;

  return (
    <>
      <br />

      {/* <Button
        className="m-2"
        color="primary"
        onClick={() => {
          getMoreData()
        }}
      >
       getMoreData
      </Button> */}
      <Breadcrumb className="my-3" tag="nav" listTag="div">
        <BreadcrumbItem tag="span">Ayarlar</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Firmalar
        </BreadcrumbItem>
      </Breadcrumb>

      <Button
        className="m-2"
        color="primary"
        onClick={() => {
          setFilter(null);
        }}
      >
        Tümünü Göster
      </Button>
      <Button
        className="m-2"
        color="primary"
        onClick={() => {
          setFilter(20);
        }}
      >
        Tamamlananları Gizle
      </Button>
      <Button
        className="m-2"
        color="primary"
        onClick={() => {
          handleCreateCompany();
        }}
      >
        Yeni Firma Ekle
      </Button>

      <Table
        data={filteredCompanies}
        initualComuns={initualComuns}
        className="table table-hover table-sm"
      />
    </>
  );
};

export default Companies;

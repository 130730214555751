import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

import { withRouter, Redirect } from "react-router";

import { AuthContext } from "../../base/auth";

import Logo from "../../common/Logo";
import * as ROUTES from "../../base/routes";
import "./signIn.css";
const test = window.location.hostname.includes("localhost");

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({
    text: "",
    style: "d-none",
    role: "",
    click: false,
  });
  const { currentUser, base ,currentCompany} = useContext(AuthContext);
  const firstRender = useRef(true);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // it's no longer the first render
      return; // skip the code below
    }
    if (email.length > 5 && password.length > 5) {
      setDisabled(false);
    }
  }, [email, password]);

  useEffect(() => {
    if (test) {
      setEmail("deneme@me.com");

      setPassword("111111");
    }
  }, []);

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await base.doSignInWithEmailAndPassword(email.value, password.value);

        //history.push(currentCompany?.lastPage || ROUTES.DECIDETYPE);
      } catch (error) {
        setMessage({
          text: "Hatalı e posta veya parola. Parolanızı mi unuttunuz ?",
          style: "alert alert-warning pointer",
          role: "alert",
          click: true,
        });
      }
    },
    [history]
  );

  if (currentUser) {
    return <Redirect to={currentCompany?.lastPage || ROUTES.DECIDETYPE} />;
  }

  const forgetPassword = () => {
    base
      .doPasswordReset(email)

      .then(function () {
        setMessage({
          text: "e Posta Gönderildi. Gelen ve Önemsiz e posta klasörünüzü ediniz.",
          style: "alert alert-warning",
          role: "alert",
          click: false,
        });
      })
      .catch(function (error) {
        setMessage({
          text: error.message,
          style: "alert alert-warning",
          role: "alert",
          click: false,
        });
      });
  };

  return (
    <div className="text-center col-md-3 container mt-5 beyaz">
      <form className="form-signin" onSubmit={handleLogin}>
        <Logo className="App-logo rounded mx-auto d-block" height="75px" />
        <h1 className="h3 mb-3 font-weight-normal">Giriş Yapın</h1>
        <p className="mt-2">
          Yeni Şirket Kur'a e-posta ve parolanız ile giriş yapabilirsiniz.
        </p>
        <label htmlFor="inputEmail" className="sr-only">
          Email address
        </label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className="my-2 form-control"
          name="email"
          type="email"
          placeholder="e-Posta"
          value={email}
          autoFocus
          required
        />
        <label htmlFor="inputPassword" className="sr-only">
          Password
        </label>
        <input
          onChange={(e) => setPassword(e.target.value)}
          className="my-2 form-control"
          name="password"
          type="password"
          placeholder="Parola"
          value={password}
          required
        />
        <div
          onClick={message.click ? (e) => forgetPassword() : null}
          className={message.style}
          role={message.role}
        >
          <u>{message.text}</u>
        </div>

        <button
          disabled={disabled}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
        >
          Giriş
        </button>
        {/* <button
          className="btn btn-lg btn-primary btn-block"
          onClick={base.doSignInWithGoogle}
        >
          Google ile giriş yap
        </button> */}
        <p
          onClick={(e) => history.push(ROUTES.SIGNUP)}
          className="my-2 pointer"
        >
          <u>Hesabınız yoksa yeni bir hesap açın</u>
        </p>

        <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
      </form>
    </div>
  );
};

export default withRouter(Login);

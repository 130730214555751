import { useState, useEffect } from "react";
import Joi from "joi-browser";

const useForm = ({
  schema,
  doSubmit,
  formName = "formData",
  dbData,
  paths = {},
}) => {
  const initualFormValue = {};
  for (const keys of Object.keys(schema)) {
    initualFormValue[keys] = "";
  }
  const [formData, setFormData] = useState(initualFormValue);
  const [errorsData, setErrorsData] = useState({});

  const getProp = (object, keys, defaultVal) => {
    keys = Array.isArray(keys) ? keys : keys.split(".");
    object = object[keys[0]];
    if (object && keys.length > 1) {
      return getProp(object, keys.slice(1));
    }
    return object === undefined ? defaultVal : object;
  };

  const validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(formData, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  useEffect(() => {
    //db datayı formda gösterir
    // scema da bulunan tüm keyleri
    // db verisi ile eşler.
    if (!dbData || dbData.length === 0) return;
    const dbObj = dbData[0];
    const form = { ...formData };
    const keys = Object.keys(schema);
    // const paths = { unvan: "unvan.deneme" };
    keys.forEach((key) => {
      form[key] = paths[key] ? getProp(dbObj, paths[key], "") : dbObj[key];
    });
    setFormData(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbData]);

  const resetForm = () => setFormData(initualFormValue);

  const costumMessage = (errors) => {
    errors.forEach((err) => {
      const message = {
        "any.empty": `${err.flags.label} boş olamaz!`,
        "any.required": `${err.flags.label} boş olamaz!`,
        "number.base": `${err.flags.label} için sadece rakam girin!`,
        "string.min": `${err.flags.label} en az ${err.context?.limit} karakter olmalı!`,
        "string.max": `${err.flags.label}  ${err.context?.limit} karakterden fazla olamaz!`,
        "string.email": `Geçerli bir e posta giriniz`,
      };
      if (!message[err.type])
        console.log("mesajı türkçeleştir err.type:", err.type);
      // console.log({ err });
      err.message = message[err.type];
    });
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };

    const schemaObj = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaObj);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();
    setErrorsData(errors || {});
    if (errors) return;
    doSubmit();
  };

  const handleChange = ({ currentTarget: input }) => {
    const errors = { ...errorsData };
    const errorMessage = validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...formData };
    data[input.name] = input.value;
    setErrorsData(errors);
    setFormData(data);
  };

  const handleSwitch = ({ currentTarget: input }) => {
    console.log(input)
    const errors = { ...errorsData };
   const errorMessage = validateProperty(input);
   if (errorMessage) errors[input.name] = errorMessage;
   else delete errors[input.name];

    const data = { ...formData };
    if (data[input.name] === "") data[input.name] = true;
    else {
      data[input.name] = !data[input.name];
    }

    setErrorsData(errors);
    setFormData(data);
  };
  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  return {
    [formName]: formData,
    [`set${capitalize(formName)}`]: setFormData,
    errorsData,
    setErrorsData,
    handleChange,handleSwitch,
    handleSubmit,
    costumMessage,
    resetForm,
  };
};

export default useForm;

export const HOME = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const INVITES = "/invites";
export const CHANGECOMP = "/changeCompany";

export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const COMPANIES = "/settings/companies";
export const COMPANIESALL = "/settings/companiesall";
export const SETTINGS = "/settings";
export const USERS = "/settings/users";
export const NEW_USER = "/settings/users/new";
export const USER_EDIT = "/settings/users/edit";
export const USER_INVITATION_EDIT = "/settings/users/editInvitation";
export const PROFILE = "/profile";

export const DECIDETYPE = "/type";
export const PARTNERSHIP = "/type/partnership";
export const INVESTMENT = "/type/investment";
export const SHARESALE = "/type/sharesale";
export const MANAGEMENT = "/type/management";
export const TYPERESULT = "/type/result";

export const DATACOLLECTION = "/collect";
export const COLPARTNERS = "/collect/partners";
export const NUMBEROFPARTNERS = "/collect/partners/numberPartners";
export const IDINFO = "/collect/partners/idinfo";
export const PARTNERCONTACT = "/collect/partners/partnerContact";
export const AUTHORITIES = "/collect/partners/authorities";
export const REPRESENTATION = "/collect/partners/representation";
export const COMCAPITAL = "/collect/partners/capital";
export const COMSUBJECT = "/collect/subject";
export const NACE = "/collect/nace";
export const ADDRESS = "/collect/address";
export const TITLE = "/collect/title";
export const SUMMARY = "/collect/summary";

export const ESTABLISH = "/establish";
export const DOCUMENTS = "/establish/documents";
export const COMPREGINFO = "/establish/compreginfo";
export const IVDPASSWD = "/establish/ivdpasswd";

export const PRODUCT = "/product";
export const PRODUCT_LIST = "/product/list";
export const PRODUCT_EDIT = "/product/edit";
export const PRODUCT_NEW = "/product/new";

export const ADMIN = "/admin";
export const ADMIN_ESTABLISH = "/admin/establish";
export const ADMIN_COMPREGINFO = "/admin/compreginfo";

export const FIRMALAR = "/firmalar";

import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
//import './custom.scss';
import { AuthProvider } from "./base/auth";
import PrivateRoute from "./PrivateRoute";
import * as ROUTES from "./base/routes";

import Home from "./home";
import Login from "./components/signIn/SignIn";
import SignUp from "./components/signIn/singUp";
import Invites from "./components/signIn/Invites";
import ChangeCompanyWithLink from "./components/settings/companies/changeCompanyWithLink";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path={ROUTES.INVITES} component={Invites} />
          <Route path={ROUTES.CHANGECOMP} component={ChangeCompanyWithLink} />
          <Route path={ROUTES.LOGIN} component={Login} />
          <Route path={ROUTES.SIGNUP} component={SignUp} />
          <PrivateRoute path={ROUTES.HOME} component={Home} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;

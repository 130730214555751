import React, { useState, useEffect } from "react";

import { Row, Col, Button, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem} from "reactstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as ROUTES from "../../base/routes";
import { AuthContext } from "../../base/auth";
import Loading from '../../common/loading';

const CompanySubject = ({ history, setStep,aplicationData }) => {
  const { userData, base } = React.useContext(AuthContext);
  const [text, setText] = useState("");
  const [subjectArray, setSubjectArray] = useState([]);

  useEffect(() => {
    if (!userData) return;
    const data = aplicationData.purposeSubject;
    setSubjectArray(data);
  }, [aplicationData, userData]);

  const handleAdd = () => {
    const array = [...subjectArray];
    array.push(text);
    setSubjectArray(array);
    setText("");
  };

  const handleDelete = (index) => {
    const array = [...subjectArray];
    const filtered = array.filter((x, i) => i !== index);
    setSubjectArray(filtered);
  };

  const handleEdit = (e) => {
    setText(subjectArray[e.target.value]);
  };

  const handleSave = async () => {
    const data = [...subjectArray];

    await base.updateAplicationDataToDb({
      companyID: userData.currentCompany,
      updateKeys: { purposeSubject: data },
    });

    setStep(11);
    history.push(ROUTES.NACE);
  };

  if(!subjectArray) return <Loading />;

  return (
    <Row>
      <Col md={6}>
      <Breadcrumb className="" tag="nav" listTag="div">
            <BreadcrumbItem tag="span">Bilgi Girişi</BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Şirketin Konusu
            </BreadcrumbItem>
          </Breadcrumb>
        {
          <ul>
            {subjectArray.map((sub, index) => (
              <Row>
                <li
                  className=" mx-2 pointer"
                  onClick={(e) => handleEdit(e)}
                  key={index}
                  value={index}
                >
                  {sub}
                </li>
                <RiDeleteBin6Line
                  className=" mx-2 pointer"
                  onClick={() => handleDelete(index)}
                />
              </Row>
            ))}
          </ul>
        }
        <FormGroup>
          <Label for="companySubject">
            Firmanın faaliyet konusunu açıklayınız.
          </Label>
          <Input
            type="textarea"
            name="text"
            id="companySubject"
            placeholder="Yapacağınız işi özetleyiniz. Örnek: web üzerinden perakende satış yapacağım."
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </FormGroup>
        <Button onClick={handleAdd} color="primary">
          Ekle
        </Button>
        <br />
        <Button
          className="my-3"
          onClick={() => history.push(ROUTES.COMCAPITAL)}
          color="secondary"
        >
          {" "}
          Geri{" "}
        </Button>
        <Button
          className="m-3"
          disabled={subjectArray.length === 0}
          onClick={() => handleSave()}
          color="primary"
        >
          {" "}
          Kaydet{" "}
        </Button>
      </Col>
    </Row>
  );
};

export default CompanySubject;

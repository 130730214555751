import React, { useState, useEffect } from "react";

import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Row,
  Badge,
  Label,
  Col,
} from "reactstrap";

const SearchBar = ({

  placeholder,
  data,
  propertyArray,
  property,
  newRecord = true,
  handleChange,
  label,
}) => {
  const turkishUpperCase = (input) => {
    let letters = { i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
    let string = input.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    string = string.toUpperCase();
    return string;
  };
  const [selectedItem, setSelectedItem] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [findData, setFindData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (!propertyArray) {
      setSearchData(data);
      return;
    }
    const searchData = [...data];
    const newSearchData = searchData.map((item) => {
      propertyArray.forEach((prop) => {
        if (item?.[prop]) {
          item.searchKey = item.searchKey + " " + item[prop];
        }
      });
      return item;
    });
    setSearchData(newSearchData);
  }, [data, propertyArray]);

  const handleFilter = (event) => {
    setShowList(true);
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = searchData.filter((value) => {
      return turkishUpperCase(value?.searchKey || value).includes(
        turkishUpperCase(searchWord)
      );
    });

    const find = searchData.find((value) => {
      return (
        turkishUpperCase(value?.searchKey || value) ===
        turkishUpperCase(searchWord)
      );
    });

    if (searchWord === "") {
      setFilteredData([]);
      setFindData([]);
      setShowList(false);
    } else if (!newRecord && newFilter.length === 0) {
      setShowList(false);
      setFilteredData(newFilter);
      setFindData(find);
    } else {
      setFilteredData(newFilter);
      setFindData(find);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setShowList(false);
  };

  const handleSelect = (value) => {
    setSelectedItem(value);
    setWordEntered(value[property] || value);
    setFilteredData([]);
    //setWordEntered("");
    setShowList(false);
    handleChange(value);
  };
  const handleNew = () => {
    if (newRecord) return;
    setShowList(false);
  };

  useEffect(() => {
    if (selectedItem.length === 0) {
      clearInput();
    }
  }, [selectedItem]);

  return (
    <>
      <Row>
        {label && <Label>{label}</Label>}
        <InputGroup className="mt-2">
          <Input
            type="text"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
          />
          {/* <Badge color="secondary">New</Badge> */}

          <InputGroupAddon addonType="append">
            <InputGroupText>
              {!showList ? (
                <AiOutlineSearch />
              ) : (
                <AiOutlineClose className="pointer" onClick={clearInput} />
              )}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Row>

      <Row>
        {showList && (
          <Col
            className=" searchList position-absolute  p-1 hover overflow-auto shadow-sm bg-white"
            style={{ height: "200px" }}
          >
            {!findData && newRecord && (
              <p className="pointer m-1" onClick={() => handleNew()}>
                {" "}
                Yeni kayıt ekle: {wordEntered}{" "}
              </p>
            )}
            {filteredData.slice(0, 15).map((value, key) => {
              return (
                <p className="hover pointer m-2" onClick={() => handleSelect(value)}>
                  {value[property] || value}{" "}
                </p>
              );
            })}
          </Col>
        )}
      </Row>
    </>
  );
};

export default SearchBar;

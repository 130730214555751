import React, { useState, useEffect, useContext } from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import * as ROUTES from "../../base/routes";
// import IvdPasswordInfo from "../../common/ivdPassword";
import Partners from "./partners";
import CompanySubject from "./companySubject";
import Address from "./address";
import Title from "./title";
import Summary from "./summary";
import Nace from "./nace";
import Loading from "../../common/loading";
import { Progress } from "reactstrap";
import { AuthContext } from "../../base/auth";

const DataCollection = ({ history, step, setStep }) => {
  let { path } = useRouteMatch();
  const { base, currentCompany, userData } = useContext(AuthContext);

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getAplicationPartnerData(
        { companyID: userData.currentCompany },
        (data) => {
          setPartners(data);
        }
      );
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, userData.currentCompany]);

  const [numberOfPartners, setNumberOfPartners] = React.useState(0);
  const [numberOfNonPartnerManager, setNumberOfNonPartnerManager] =
    React.useState(0);

  const [currentPartner, setCurrentPartner] = React.useState(0);

  const [aplicationData, setAplicationData] = React.useState([]);
  useEffect(() => {
    const unsubscribe = () => {
      // currentCompanyyi dinle
      base.getAplicationData(
        { companyID: userData.currentCompany },
        (data) => {
          setAplicationData(data);
        }
      );
    };
    unsubscribe();
    return () => {
      //unsubscribe the listener here
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, userData.currentCompany]);

  // ortak sayısı bu compenentin nasıl çalışacağını belirliyor.
  // ortak sayısı girildikte n sonra setPartners ile bilgi güncellenir.

  React.useEffect(() => {
    if (!partners) return;
    if (partners.length ===0) return;
    const partnersArray = [...partners];
    //setPartners(partnersArray);
    const onlyPartners = partnersArray.filter((x) => x.partner === true).length;
    const onlyNonPartners = partnersArray.filter(
      (x) => x.partner === false
    ).length;
    setNumberOfPartners(onlyPartners);
    setNumberOfNonPartnerManager(onlyNonPartners);
    //history.push(ROUTES.IDINFO);
  }, [partners]);

  if (!currentCompany) return <Loading />;
  return (
    <>
      <h4 className="mt-3">
        {currentCompany.companyType} Kuruluşu Yapıyorsunuz
      </h4>
      <Progress className="my-3" value={step - 7} max={15 - 7} />
      {/* <IvdPasswordInfo /> */}
      <Switch>
        <Route exact path={`${path}`}>
          {/* <Partnership handleChange={setPartnership} /> */}
        </Route>
        <Route path={ROUTES.COLPARTNERS}>
          <Partners
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            numberOfPartners={numberOfPartners}
            numberOfNonPartnerManager={numberOfNonPartnerManager}
            currentPartner={currentPartner}
            setCurrentPartner={setCurrentPartner}
            aplicationData={aplicationData}
          />
        </Route>
        <Route path={ROUTES.COMSUBJECT}>
          <CompanySubject
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            numberOfPartners={numberOfPartners}
            numberOfNonPartnerManager={numberOfNonPartnerManager}
            currentPartner={currentPartner}
            setCurrentPartner={setCurrentPartner}
            aplicationData={aplicationData}
          />
        </Route>
        <Route path={ROUTES.NACE}>
          <Nace
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            aplicationData={aplicationData}
          />
        </Route>
        <Route path={ROUTES.ADDRESS}>
          <Address
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            aplicationData={aplicationData}
          />
        </Route>
        <Route path={ROUTES.TITLE}>
          <Title
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            aplicationData={aplicationData}
          />
        </Route>
        <Route path={ROUTES.SUMMARY}>
          <Summary
            partners={partners}
            currentCompany={currentCompany}
            userData={userData}
            history={history}
            setStep={setStep}
            aplicationData={aplicationData}
          />
        </Route>
      </Switch>
    </>
  );
};

export default DataCollection;
